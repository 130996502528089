import { IDs } from './schema-ids';
import { SCHEMA_TYPES } from './schema-types';
var requiredSchemaVariables = [IDs.FAQ];
export default {
    type: SCHEMA_TYPES.BLOG_POST_FAQ,
    schema: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: "{{".concat(IDs.FAQ, "}}"),
    },
    requiredSchemaVariables: requiredSchemaVariables,
};
