import isUndefined from 'lodash/isUndefined';
import { ERRORS_CONSTANTS_MAP, ERRORS_MAP } from '../errors-map';
import { KEYS, CANONICAL_SIMPLIFIED_LABEL } from './maps';

function convertSimplifiedCanonicalLabelToCanonicalKey(key) {
  return key === CANONICAL_SIMPLIFIED_LABEL ? KEYS.CANONICAL : key;
}

const getErrorMessage = (error, key, i18n, errorMapFlags = {}) =>
  getInvalidScriptError(error, i18n) ||
  getErrorAccordingToValidatorIndex(error, key, i18n, errorMapFlags) ||
  getErrorWithDuplicates(error, key, i18n) ||
  getMaxLengthError(error, key, i18n) ||
  getPlainErrorData(error, key, i18n) ||
  getUriError(error, key, i18n);

function getInvalidScriptError(error, i18n) {
  if (!error) {
    return i18n.t(
      'adv-seo.adv-panel.structured-data.input-error.invalid-script-tag',
    );
  }
}

function getErrorAccordingToValidatorIndex(error, key, i18n, errorMapFlags) {
  if (!isUndefined(error.validatorIndex)) {
    key = convertSimplifiedCanonicalLabelToCanonicalKey(key);
    const errorData = ERRORS_MAP(errorMapFlags)[key][error.name];
    return i18n.t(errorData[error.validatorIndex]);
  }
}

function getErrorWithDuplicates(error, key, i18n) {
  if (!isUndefined(error.duplicates)) {
    const errorData = ERRORS_MAP()[key][error.name];
    return i18n.t(errorData, { duplicates: error.duplicates.join(', ') });
  }
}

function getMaxLengthError(error, key, i18n) {
  if (error.maxLength) {
    const errorData = ERRORS_MAP()[key][error.name];
    return i18n.t(errorData, { maxLength: error.maxLength });
  }
}

function getPlainErrorData(error, key, i18n) {
  key = convertSimplifiedCanonicalLabelToCanonicalKey(key);
  const errorData = ERRORS_MAP()[key][error.name];
  const errorConstsData = ERRORS_CONSTANTS_MAP()[key]?.[error.name] || {};
  return i18n.t(errorData, errorConstsData);
}

function getUriError(error, key, i18n) {
  if (error && key === KEYS.URI) {
    return i18n.t('adv-seo.side-panel.page-seo.page-url.input-error.duplicate');
  }
}

export default getErrorMessage;
