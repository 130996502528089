var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildOgMetaRefs, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-groups-page.js';
var IDs = adapter.IDs;
export default {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.GROUP_TAB_TITLE, "}} - {{group.name}} | {{site.name}}")),
        buildStandardMeta('description', "{{group.description}}"),
        buildStandardMeta('robots', "{{".concat(IDs.GROUP_ROBOTS, "}}"))
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:image', '{{group.image.url}}'),
        buildOgMeta('og:image:width', '{{group.image.width}}'),
        buildOgMeta('og:image:height', '{{group.image.height}}'),
        buildOgMeta('og:site_name', '{{site.name}}'),
        buildOgMeta('og:url', '{{group.tab.url}}'),
        buildOgMeta('og:type', 'website')
    ], false), buildTwitterMetaRefs(), true), [
        buildLink({ rel: 'canonical', href: '{{group.tab.url}}' }),
    ], false),
};
