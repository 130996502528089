"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var get_1 = require("../../utils/get");
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
var utils_2 = require("../utils");
var consts_2 = require("../consts");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
exports.IDs = {
    ID: 'blog-tag.id',
    TITLE: 'blog-tag.name',
    PAGE_URL: 'blog-tag.fullUrl',
    POST_COUNT: 'blog-tag.postCount',
    CREATED_DATE: 'blog-tag.created.date',
    DESCRIPTION: 'blog-tag.description',
    PREV_LINK: 'blog-tag.prevLink',
    NEXT_LINK: 'blog-tag.nextLink',
    CURRENT_PAGE: 'blog-tag.currentPage',
    TOTAL_PAGE_COUNT: 'blog-tag.totalPageCount',
    SD_DISABLED: 'blog-tag.disable-schema',
    SD_ITEM_LIST_ELEMENT: 'blog-tag.structured-data.item-list-element',
    BLOG_FEED_ROUTE: 'blog-tag.blogFeedRoute',
};
var userVisible = [exports.IDs.TITLE, exports.IDs.POST_COUNT, exports.IDs.PAGE_URL];
var userVisibleSdOnly = [exports.IDs.SD_ITEM_LIST_ELEMENT];
var requiredSchemaVariables = [];
var SchemaTypes = {};
var getListItems = function (item) { return (0, get_1.getRawValue)(item, 'tag.listItems') || []; };
var getSdStatus = function (item, itemData) {
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables, item, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    if (getListItems(item).length <= 1) {
        return consts_1.SD_STATUS.DISABLED_BY_VERTICAL_ITEM_DATA;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getFullUrl = function (itemData) {
    return (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.BLOG_TAGS, itemData, (0, get_1.get)(itemData, 'tag.fullUrl'));
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var _c;
        var data = (_a = {},
            _a[exports.IDs.ID] = (0, get_1.get)(item, 'tag.name'),
            _a[exports.IDs.TITLE] = (0, get_1.get)(item, 'tag.name'),
            _a[exports.IDs.PAGE_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.BLOG_TAGS, item, (0, get_1.get)(item, 'tag.fullUrl')),
            _a[exports.IDs.POST_COUNT] = (0, get_1.get)(item, 'tag.postCount'),
            _a[exports.IDs.CREATED_DATE] = (0, get_1.get)(item, 'tag.created'),
            _a[exports.IDs.DESCRIPTION] = (0, get_1.get)(item, 'tag.description'),
            _a[exports.IDs.PREV_LINK] = (0, get_1.get)(item, 'tag.pagination.prevUrl'),
            _a[exports.IDs.NEXT_LINK] = (0, get_1.get)(item, 'tag.pagination.nextUrl'),
            _a[exports.IDs.CURRENT_PAGE] = (0, get_1.get)(item, 'tag.pagination.currentPage'),
            _a[exports.IDs.TOTAL_PAGE_COUNT] = (0, get_1.get)(item, 'tag.pagination.totalPages'),
            _a[exports.IDs.SD_ITEM_LIST_ELEMENT] = (_c = getListItems(item)) === null || _c === void 0 ? void 0 : _c.map(function (url, position) { return ({
                '@type': 'ListItem',
                position: position + 1,
                url: url,
            }); }),
            _a[exports.IDs.BLOG_FEED_ROUTE] = (0, get_1.get)(item, 'tag.blogFeedRoute') ||
                (0, utils_2.getRssFullURL)({
                    siteUrl: (0, get_1.get)(item, 'context.siteUrl'),
                    rssRoute: consts_2.RSS_ROUTES[consts_2.RSS_ROUTE_TYPES.BLOG],
                }),
            _a[utils_1.siteIDs.CURRENT_PAGE_NUMBER] = (0, get_1.get)(item, 'tag.pagination.currentPage'),
            _a[utils_1.siteIDs.TOTAL_PAGE_COUNT] = (0, get_1.get)(item, 'tag.pagination.totalPages'),
            _a[utils_1.siteIDs.PREV] = (0, get_1.get)(item, 'tag.pagination.prevUrl'),
            _a[utils_1.siteIDs.NEXT] = (0, get_1.get)(item, 'tag.pagination.nextUrl'),
            _a);
        var sdStatus = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.SD_DISABLED] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { return (0, get_1.get)(item, 'tag.slug'); },
    getFullUrl: function (itemData) { return getFullUrl(itemData); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.tag.fullUrl = url;
        return itemData;
    },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisibleSdOnly); },
};
