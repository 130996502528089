var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildMultilingualLink } from '../../types/builders';
import { X_DEFAULT } from '../../consts';
import PROPS from '../../types/Props';
import { safelyParseJsonData } from '../utils/safely-parse-json-data';
import { getValueByIdentifier } from '../../tags/values/get-by-identifier';
import { isRobotsDirectiveExists, ROBOTS_DIRECTIVES, } from '../../tags/robots-tag/robots-tag';
import { IDENTIFIERS } from '../../types/Identifiers';
var REL = PROPS.REL, HREF = PROPS.HREF, HREFLANG = PROPS.HREFLANG;
export function getMultilingualSchemaFromContext(data, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.currentSchemas, currentSchemas = _c === void 0 ? [] : _c, _d = _b.translationsData, translationsData = _d === void 0 ? {} : _d;
    var schemesHasMultilingualTags = currentSchemas.some(function (schema) {
        return hasMultilingualTags(schema === null || schema === void 0 ? void 0 : schema.tags);
    });
    if (schemesHasMultilingualTags) {
        return { tags: [] };
    }
    var _e = (data || {}).context, _f = _e === void 0 ? {} : _e, siteLanguages = _f.siteLanguages, siteUrl = _f.siteUrl;
    if (!(siteLanguages === null || siteLanguages === void 0 ? void 0 : siteLanguages.length)) {
        return { tags: [] };
    }
    var languages = __spreadArray([
        { url: siteUrl, languageCode: X_DEFAULT }
    ], siteLanguages, true);
    var tags = getMultilingualTags(languages, translationsData);
    return { tags: tags };
}
/**
 *
 * @param {Array<{
 * url: string,
 * langCode: string,
 * languageCode: string
 * seoLang: string,
 * }>} siteLanguages
 */
export function getMultilingualTags(siteLanguages, translationsData) {
    if (translationsData === void 0) { translationsData = {}; }
    var tagsMap = new Map();
    var shouldAddTag = function (hreflang, href) {
        return !tagsMap.has(hreflang) &&
            shouldRenderLanguage({ translationsData: translationsData, hreflang: hreflang, href: href });
    };
    return (siteLanguages || [])
        .map(function (_a) {
        var url = _a.url, linkToPage = _a.linkToPage, langCode = _a.langCode, languageCode = _a.languageCode, language = _a.language, seoLang = _a.seoLang;
        var href = url || linkToPage;
        var hreflang = seoLang || langCode || languageCode || language;
        if (languageCode === X_DEFAULT || langCode === X_DEFAULT) {
            hreflang = X_DEFAULT;
        }
        var tag = href && hreflang
            ? buildMultilingualLink(href, (hreflang || '').toLowerCase())
            : undefined;
        return tag;
    })
        .filter(Boolean)
        .reverse()
        .reduce(function (tags, tag) {
        var _a = (tag === null || tag === void 0 ? void 0 : tag.props) || {}, _b = HREFLANG, hreflang = _a[_b], _c = HREF, href = _a[_c];
        if (shouldAddTag(hreflang, href)) {
            tagsMap.set(hreflang);
            return __spreadArray([tag], tags, true);
        }
        else {
            return tags;
        }
    }, []);
}
export function hasMultilingualTags(tags) {
    if (!Array.isArray(tags)) {
        return false;
    }
    return tags.some(function (tag) {
        var _a = (tag === null || tag === void 0 ? void 0 : tag.props) || {}, _b = REL, rel = _a[_b], _c = HREF, href = _a[_c], _d = HREFLANG, hreflang = _a[_d];
        return Boolean(rel && href && hreflang);
    });
}
function shouldRenderLanguage(_a) {
    var translationsData = _a.translationsData, href = _a.href, hreflang = _a.hreflang;
    var languagesTagsJson = translationsData[hreflang];
    if (!languagesTagsJson) {
        return true;
    }
    var tags = safelyParseJsonData(languagesTagsJson).tags;
    var _b = [
        IDENTIFIERS.ROBOTS,
        IDENTIFIERS.CANONICAL,
    ].map(function (identifier) { return getValueByIdentifier(tags, identifier); }), robotsValue = _b[0], canonicalValue = _b[1];
    var shouldIndex = !robotsValue ||
        !isRobotsDirectiveExists(robotsValue, ROBOTS_DIRECTIVES.NOINDEX);
    var isCanonicalEqual = !canonicalValue || canonicalValue === href;
    return shouldIndex && isCanonicalEqual;
}
