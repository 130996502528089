import { get, getRawValue } from '../../utils/get';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
var IDs = {
    ID: 'item.id',
    SITE_NAME: 'site.name',
    PAGE_NAME: 'page.name',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    GALLERY_TYPE: 'item.type',
    TITLE: 'item.title',
    DESCRIPTION: 'item.description',
    PAGE_URL: 'page.url',
    FULLSCREEN_URL: 'page.fullScreenUrl',
    MAIN_IMAGE: 'item.image.url',
    IMAGE_WIDTH: 'item.image.width',
    IMAGE_HEIGHT: 'item.image.height',
    VIDEO_URL: 'item.videoUrl',
    THUMBNAIL_URL: 'item.thumbnail.url',
    THUMBNAIL_WIDTH: 'item.thumbnail.width',
    THUMBNAIL_HEIGHT: 'item.thumbnail.height',
};
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.MAIN_IMAGE,
    IDs.PAGE_NAME,
    IDs.GALLERY_TYPE,
    IDs.PAGE_URL,
    IDs.FULLSCREEN_URL,
    IDs.SITE_NAME,
];
export default {
    IDs: IDs,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.ID] = get(item, 'item.id'),
            _a[IDs.SITE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.PAGE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.PAGE_NAME)),
            _a[IDs.INDEX_SITE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_SITE)),
            _a[IDs.INDEX_PAGE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_PAGE)),
            _a[IDs.GALLERY_TYPE] = ['image', 'video'].includes(get(item, 'item.type'))
                ? get(item, 'item.type')
                : 'website',
            _a[IDs.TITLE] = get(item, 'item.title'),
            _a[IDs.DESCRIPTION] = get(item, 'item.description'),
            _a[IDs.PAGE_URL] = get(item, 'item.page_url'),
            _a[IDs.FULLSCREEN_URL] = get(item, 'item.fullscreen_url'),
            _a[IDs.MAIN_IMAGE] = get(item, 'item.image.url'),
            _a[IDs.IMAGE_WIDTH] = get(item, 'item.image.width'),
            _a[IDs.IMAGE_HEIGHT] = get(item, 'item.image.height'),
            _a[IDs.VIDEO_URL] = get(item, 'item.video_url'),
            _a[IDs.THUMBNAIL_URL] = get(item, 'item.thumbnail.url'),
            _a[IDs.THUMBNAIL_WIDTH] = get(item, 'item.thumbnail.width'),
            _a[IDs.THUMBNAIL_HEIGHT] = get(item, 'item.thumbnail.height'),
            _a;
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getSdKeys: function () { return []; },
};
