import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';

import { Warning } from '../../../core/components/icons/warning';
import * as BI_TYPES from '../../../core/bi/action-types';

import InfoIcon from '../ui/info-icon';

class SocialPanelImageWarning extends PureComponent {
  static propTypes = {
    openHelpCenter: func.isRequired,
    logBiEvent: func.isRequired,
    baseTranslationKey: string.isRequired,
  };

  render() {
    const { baseTranslationKey } = this.props;
    return (
      <InfoIcon
        error={true}
        customSymbol={<Warning color="#ffc233" />}
        text={`${baseTranslationKey}.preview.size.warning.tooltip`}
        linkText="adv-seo.common.learn-more"
        onLinkClick={this._onLearnMore}
      />
    );
  }

  _onLearnMore = () => {
    const { openHelpCenter, logBiEvent, baseTranslationKey } = this.props;
    openHelpCenter('d8405fb1-c787-4566-8f97-c43d920cc615');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: `${baseTranslationKey}.preview.size.warning`,
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default SocialPanelImageWarning;
