var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { renderToStaticMarkup } from './private/renderer/render-to-static-markup';
import { resolve } from './private/renderer/resolve';
import { merge } from './private/renderer/merge';
import { resolveWithPatterns } from './private/renderer/resolve-with-patterns';
import { resolveIsIndexable, getIndexableValue, } from './private/renderer/utils/resolve-is-indexable';
import { applyDefaultTags } from './private/tags/defaults/apply-default-tags';
import { parse } from './private/renderer/utils/parse';
import { substractSchemas } from './private/tags/filters/substract-schemas';
import { filterUnidentifiedHtmlTags } from './private/renderer/utils/filter-unidentified-html-tags';
import { filterBasicSeoTags } from './private/tags/filters/filter-basic-seo-tags';
import { sanitizeUrl } from './private/utils/sanitize-url';
import { getSiteStructure } from './private/utils/site-structure';
import { getAutoRedirectRequests } from './private/auto-redirects/get-auto-redirect-requests';
import { getAutoRedirectsResults } from './private/auto-redirects/get-auto-redirects-results';
import { CREATE_REDIRECT_RESULT_STATUS } from './private/auto-redirects/consts';
import { createTranslateByItemType } from './private/types/itemTypesTranslation';
import { getDefaultValueByIdentifier as getDefaultValueByIdentifierUtil, getValueByIdentifier as getValueByIdentifierUtil, getSchemasArray as getSchemasArrayUtil, getCustomTagsString as getCustomTagsStringUtil, getCustomTagsArray as getCustomTagsArrayUtil, updateValueByIdentifier as updateValueByIdentifierUtil, updateCustomTags as updateCustomTagsUtil, getMetaByIdentifier as getMetaByIdentifierUtil, updateStructuredData as updateStructuredDataUtil, deleteStructuredData as deleteStructuredDataUtil, updateVariant, resolveLegacyCustomSd as resolveLegacyCustomSdUtil, getValueFromHtmlTag, getValueFromHtmlTagLegacy, validateCustomTags, validateTagValueByIdentifier, getValueByIdentifierFromContext, getAdvancedSeoTags, getSimplifiedTagsArray, updateSimplifiedSeoTag, getSimplifiedTag, validateCustomTag, updateCustomTagsArray, compareCustomTags, getEmptyTags, } from './private/tags';
import { ROBOTS_DIRECTIVES, MAX_IMAGE_PREVIEW_VALUES, MAX_SNIPPET_DEFAULT_VALUE, MAX_VIDEO_PREVIEW_DEFAULT_VALUE, REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE, isRobotsDirectiveExists, mergeRobotsTags, mergeRobotsValues, getDirectiveValue, getRobotsTagValueWithDirective, getRobotsTagValueWithoutDirective, isRobotsTagValueSupported, } from './private/tags/robots-tag/robots-tag';
import { convertDynamicPageModel, convertPlatformMetaTagsModel, convertSeoModel, convertTpaModel, convertDescriptionTag, } from './private/converters';
import { ERRORS, ERROR_NAMES } from './private/types/Errors';
import { SETTING_IDS } from './private/settings/setting-ids';
import * as VALIDATORS from './private/types/Validators';
import { CONTEXT_PROPS } from './private/types/ContextProps';
import { IDENTIFIERS, TWITTER_IDENTIFIERS, GENERAL_IDENTIFIERS, } from './private/types/Identifiers';
import { buildOgImageFullUrl } from './private/utils/build-og-image';
import { buildOgImagePreview } from './private/utils/build-og-image-preview';
import { getPatternBlob, getDynamicPattern, fillInPatternBlob, fillPatternValue, } from './private/patterns';
import { ITEM_TYPES } from './private/types/ItemTypes';
import { SCHEMA_PRESET_TYPES } from './private/types/SchemaPresetTypes';
import { SD_STATUS } from './private/consts';
import { getAdapter } from './private/adapters';
import { transformCustomTagsToIdentified } from './private/renderer/transform-custom-tags-to-identified';
import { TWITTER_CARD_SUPPORTED_TYPES } from './private/types/twitterTagSchemas';
import { getSettingValueById as getSettingValueByIdUtil } from './private/settings/get-by-setting-id';
import { updateSettingById as updateSettingByIdUtil } from './private/settings/update-by-setting-id';
import { getTags } from './private/renderer/get-tags';
var getMetaByIdentifier = function (_a, identifier) {
    var _b = _a === void 0 ? {} : _a, tags = _b.tags;
    return getMetaByIdentifierUtil(tags, identifier);
};
var getDefaultValueByIdentifier = function (_a, identifier, context) {
    var _b = _a === void 0 ? {} : _a, tags = _b.tags;
    return getDefaultValueByIdentifierUtil(tags, identifier, context);
};
var getValueByIdentifier = function (_a, identifier) {
    var _b = _a === void 0 ? {} : _a, tags = _b.tags;
    return getValueByIdentifierUtil(tags, identifier);
};
var getSchemasArray = function (_a, options) {
    var _b = _a === void 0 ? {} : _a, tags = _b.tags;
    return getSchemasArrayUtil(tags, options);
};
var getCustomTagsString = function (_a) {
    var _b = _a === void 0 ? {} : _a, tags = _b.tags;
    return getCustomTagsStringUtil(tags);
};
var getCustomTagsArray = function (_a) {
    var _b = _a === void 0 ? {} : _a, tags = _b.tags;
    return getCustomTagsArrayUtil(tags);
};
var getSettingValueById = function (_a, settingId) {
    var _b = _a === void 0 ? {} : _a, settings = _b.settings;
    return getSettingValueByIdUtil(settings, settingId);
};
var updateValueByIdentifier = function (data, identifier, value, meta, enableValidation, config) {
    if (data === void 0) { data = {}; }
    var updatedTags = updateValueByIdentifierUtil((data && data.tags) || [], identifier, value, meta, enableValidation, config);
    return __assign(__assign({}, data), { tags: updatedTags });
};
var updateCustomTags = function (data, value) {
    if (data === void 0) { data = {}; }
    var updatedTags = updateCustomTagsUtil((data && data.tags) || [], value);
    return __assign(__assign({}, data), { tags: updatedTags });
};
var updateStructuredData = function (data, structuredData, options) {
    if (data === void 0) { data = {}; }
    var updatedTags = updateStructuredDataUtil(data.tags, structuredData, options);
    return __assign(__assign({}, data), { tags: updatedTags });
};
var deleteStructuredData = function (data, structuredData) {
    if (data === void 0) { data = {}; }
    var updatedTags = deleteStructuredDataUtil(data.tags, structuredData);
    return __assign(__assign({}, data), { tags: updatedTags });
};
var updateSettingById = function (data, settingId, value) {
    if (data === void 0) { data = {}; }
    var updatedSettings = updateSettingByIdUtil(data.settings, settingId, value);
    return __assign(__assign({}, data), { settings: updatedSettings });
};
var resolveLegacyCustomSd = function (data, generator) {
    if (data === void 0) { data = {}; }
    var updatedTags = resolveLegacyCustomSdUtil(data.tags, generator);
    return __assign(__assign({}, data), { tags: updatedTags });
};
var updateStructuredDataVariant = function (data, sourceSchemaType, selectedVariant) {
    if (data === void 0) { data = {}; }
    var updatedTags = updateVariant(data.tags, sourceSchemaType, selectedVariant);
    return __assign(__assign({}, data), { tags: updatedTags });
};
export { ERRORS, ERROR_NAMES, VALIDATORS, CONTEXT_PROPS, IDENTIFIERS, TWITTER_IDENTIFIERS, GENERAL_IDENTIFIERS, SD_STATUS, SETTING_IDS, getEmptyTags, getValueByIdentifier, getSchemasArray, getMetaByIdentifier, getValueFromHtmlTag, getValueFromHtmlTagLegacy, getDefaultValueByIdentifier, getCustomTagsString, getCustomTagsArray, validateTagValueByIdentifier, validateCustomTags, updateValueByIdentifier, updateCustomTags, compareCustomTags, applyDefaultTags, renderToStaticMarkup, filterBasicSeoTags, parse, substractSchemas, resolve, merge, buildOgImagePreview, buildOgImageFullUrl, filterUnidentifiedHtmlTags, convertDynamicPageModel, convertPlatformMetaTagsModel, convertSeoModel, convertTpaModel, convertDescriptionTag, sanitizeUrl, ITEM_TYPES, SCHEMA_PRESET_TYPES, getPatternBlob, fillInPatternBlob, getDynamicPattern, fillPatternValue, getValueByIdentifierFromContext, resolveWithPatterns, getAdapter, getTags, getAdvancedSeoTags, getSimplifiedTagsArray, updateSimplifiedSeoTag, getSimplifiedTag, validateCustomTag, updateCustomTagsArray, resolveIsIndexable, createTranslateByItemType, transformCustomTagsToIdentified, TWITTER_CARD_SUPPORTED_TYPES, updateStructuredData, deleteStructuredData, updateStructuredDataVariant, resolveLegacyCustomSd, ROBOTS_DIRECTIVES, MAX_IMAGE_PREVIEW_VALUES, MAX_SNIPPET_DEFAULT_VALUE, MAX_VIDEO_PREVIEW_DEFAULT_VALUE, REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE, isRobotsDirectiveExists, mergeRobotsTags, mergeRobotsValues, getDirectiveValue, getRobotsTagValueWithDirective, getRobotsTagValueWithoutDirective, isRobotsTagValueSupported, getSettingValueById, updateSettingById, getIndexableValue, getSiteStructure, getAutoRedirectRequests, getAutoRedirectsResults, CREATE_REDIRECT_RESULT_STATUS, };
