import { buildStructuredData } from '../../types/builders';
import servicesSchema from './schema-presets/services-component';
import adapter from './adapter-services-component';
var IDs = adapter.IDs;
export var SERVICES_COMPONENT = {
    tags: [
        buildStructuredData({
            schema: servicesSchema.schema,
            schemaType: servicesSchema.type,
            disabled: [
                "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ],
};
