import { isEmpty } from '../../utils/is-empty';
import { parse } from '../../renderer/utils/parse';
import { getCustomTagsIndexArray } from './get-custom-tags-index-array';
export var updateValueByIndex = function (tags, htmlValue, customIndex) {
    if (tags === void 0) { tags = []; }
    var parsedTag = parse(htmlValue);
    if (isEmpty(parsedTag) || !Array.isArray(parsedTag) || htmlValue === '') {
        return tags;
    }
    var tag = parsedTag[0];
    tag.custom = true;
    var customTagsIndexArray = getCustomTagsIndexArray(tags);
    var updatedTags = tags.slice();
    var index = customIndex >= 0 && customIndex < customTagsIndexArray.length
        ? customTagsIndexArray[customIndex]
        : -1;
    if (index !== -1) {
        updatedTags[index] = tag;
    }
    else {
        updatedTags.push(tag);
    }
    return updatedTags;
};
