var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { IDENTIFIERS } from '../../types/Identifiers';
import { updateValueByIdentifier } from '../../tags/values/update-by-identifier';
import { get, getRawValue } from '../../utils/get';
import { buildOgImagePreviewUrl } from '../../utils/build-og-image-preview';
import { buildDefaultUrl } from '../../utils/build-default-url';
import { getKeys, siteIDs, hasMissingSchemaVariables, } from '../../adapters/utils';
import { getPostFullUrl } from './calculated/get-post-full-url';
import { getCurrentLanguage, getCurrentSeoLang, } from './calculated/getCurrentLanguage';
import { getMultilingualTags } from '../../renderer/get-multilingual-tags';
import { SD_STATUS } from '../../consts';
import { getRssFullURL } from '../utils';
import { RSS_ROUTES, RSS_ROUTE_TYPES } from '../consts';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { BuildFAQ } from './schema-presets/schema-utils';
import BLOG_POST_FAQ from './schema-presets/blog-post-faq';
import BLOG_POST_ARTICLE from './schema-presets/blog-post-article';
import BLOG_POST_NEWS_ARTICLE from './schema-presets/blog-post-news-article';
import BLOG_POST_BLOG_POSTING from './schema-presets/blog-post-blog-posting';
import { IDs } from './schema-presets/schema-ids';
var userVisible = [
    IDs.TITLE,
    IDs.POST_PAGE_URL,
    IDs.COVER_IMAGE,
    IDs.AUTHOR,
    IDs.LAST_MODIFIED_DATE,
    IDs.PUBLISH_DATE,
    IDs.EXCERPT,
    IDs.CATEGORIES,
    IDs.MAIN_CATEGORY,
];
var userVisibleSdOnly = [
    IDs.CATEGORIES,
    IDs.HEADLINE,
    IDs.DESCRIPTION,
    IDs.COVER_IMAGE_WIDTH,
    IDs.COVER_IMAGE_HEIGHT,
    IDs.AUTHOR_IMAGE_URL,
    IDs.AUTHOR_IMAGE_WIDTH,
    IDs.AUTHOR_IMAGE_HEIGHT,
    IDs.AUTHOR_URL,
];
var defaultRequiredSchemaVariables = [
    siteIDs.BUSINESS_LOGO,
    siteIDs.BUSINESS_NAME,
];
var SchemaTypes = {
    BLOG_POST_BLOG_POSTING: BLOG_POST_BLOG_POSTING.type,
    BLOG_POST_NEWS_ARTICLE: BLOG_POST_NEWS_ARTICLE.type,
    BLOG_POST_ARTICLE: BLOG_POST_ARTICLE.type,
    BLOG_POST_FAQ: BLOG_POST_FAQ.type,
};
var getSdStatus = function (item, itemData, schemaType) {
    var requiredSchemaVariables;
    switch (schemaType) {
        case SchemaTypes.BLOG_POST_FAQ:
            requiredSchemaVariables = BLOG_POST_FAQ.requiredSchemaVariables;
            break;
        default:
            requiredSchemaVariables = defaultRequiredSchemaVariables;
            break;
    }
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: defaultRequiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        var _c;
        var data = (_a = {},
            _a[IDs.INDEX_POST] = getRawValue(item, "post.noIndex") ? 'noindex' : '',
            _a[IDs.ID] = get(item, 'post.id'),
            _a[IDs.TITLE] = get(item, 'post.title'),
            _a[IDs.HEADLINE] = get(item, 'post.title').substring(0, 110),
            _a[IDs.DESCRIPTION] = get(item, 'post.description'),
            _a[IDs.POST_PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BLOG_POST, item, getPostFullUrl(item)),
            _a[IDs.COVER_IMAGE] = buildOgImagePreviewUrl({
                url: get(item, 'post.image.url'),
                width: get(item, 'post.image.width'),
                height: get(item, 'post.image.height'),
            }),
            _a[IDs.COVER_IMAGE_HEIGHT] = get(item, 'post.image.height'),
            _a[IDs.COVER_IMAGE_WIDTH] = get(item, 'post.image.width'),
            _a[IDs.MAIN_IMAGE] = buildOgImagePreviewUrl({
                url: get(item, 'post.image.url'),
                width: get(item, 'post.image.width'),
                height: get(item, 'post.image.height'),
            }),
            _a[IDs.AUTHOR] = get(item, 'post.owner.name'),
            _a[IDs.AUTHOR_URL] = get(item, 'post.owner.url'),
            _a[IDs.AUTHOR_IMAGE_URL] = buildOgImagePreviewUrl({
                url: get(item, 'post.owner.image.url'),
                height: get(item, 'post.owner.image.height'),
                width: get(item, 'post.owner.image.width'),
            }),
            _a[IDs.AUTHOR_IMAGE_HEIGHT] = get(item, 'post.owner.image.height'),
            _a[IDs.AUTHOR_IMAGE_WIDTH] = get(item, 'post.owner.image.width'),
            _a[IDs.PUBLISHER_ID] = get(item, 'post.publisher.id'),
            _a[IDs.PUBLISHER_NAME] = get(item, 'post.publisher.name'),
            _a[IDs.PUBLISH_DATE] = get(item, 'post.firstPublishedDate'),
            _a[IDs.LAST_MODIFIED_DATE] = get(item, 'post.lastPublishedDate'),
            _a[IDs.EXCERPT] = get(item, 'post.excerpt'),
            _a[IDs.AMP_URL] = get(item, 'post.amphtml'),
            _a[IDs.DISABLE_SCHEMA] = !getRawValue(item, 'postsSettings.isSchemaEnabled'),
            _a[IDs.DISABLE_AMP] = !getRawValue(item, 'postsSettings.isAmpEnabled'),
            _a[IDs.CATEGORIES] = get(item, 'post.categories'),
            _a[IDs.FAQ] = BuildFAQ(item),
            _a[IDs.MAIN_CATEGORY] = get(item, 'post.mainCategory'),
            _a[IDs.BLOG_FEED_ROUTE] = get(item, 'post.blogFeedRoute') ||
                getRssFullURL({
                    siteUrl: get(item, 'context.siteUrl'),
                    rssRoute: RSS_ROUTES[RSS_ROUTE_TYPES.BLOG],
                }),
            _a[IDs.RICH_CONTENT] = JSON.stringify((_c = item === null || item === void 0 ? void 0 : item.post) === null || _c === void 0 ? void 0 : _c.richContent) || '',
            _a);
        var faqSdStatus = getSdStatus(item, data, SchemaTypes.BLOG_POST_FAQ) !== SD_STATUS.ENABLED;
        var sdStatus = getSdStatus(item, data) !== SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.SD_DISABLE_SCHEMA] = sdStatus, _b[IDs.DISABLE_FAQ_SCHEMA] = faqSdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getLegacySeoBlob: function (item) {
        var tags = updateValueByIdentifier([], IDENTIFIERS.TITLE, get(item, 'legacySeoData.title'));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION, get(item, 'legacySeoData.description'));
        return { tags: tags };
    },
    getDynamicPatternsSchema: function (item) {
        var translatedPages = getRawValue(item, 'translatedPages') || [];
        var hasTranslations = Boolean(translatedPages.length);
        var currentLanguageCode = getCurrentLanguage(item);
        var currentSeoLang = getCurrentSeoLang(item);
        var currentUrl = getPostFullUrl(item);
        var isIndexed = !getRawValue(item, 'post.noIndex');
        if (!item || !hasTranslations || !isIndexed) {
            return { tags: [] };
        }
        var defaultUrl = buildDefaultUrl(item);
        var tags = getMultilingualTags(__spreadArray(__spreadArray([
            {
                url: currentUrl,
                langCode: currentLanguageCode,
                seoLang: currentSeoLang,
            }
        ], translatedPages, true), [
            { url: defaultUrl, langCode: 'x-default' },
        ], false));
        return { tags: tags };
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
    getSlug: function (item) { return get(item, 'post.slug'); },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        item.post.path = url;
        item.post.canonicalUrl = url;
        return item;
    },
    getFullUrl: function (item) { return getPostFullUrl(item); },
    updateItemDataWithSlug: function (item) { return item; },
    hasMultilingual: function (item) {
        return Array.isArray(getRawValue(item, 'translatedPages'));
    },
    getDefaultSchemaType: function () {
        return SchemaTypes.BLOG_POST_BLOG_POSTING;
    },
};
