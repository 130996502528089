"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var consts_1 = require("../../consts");
var IDs = {};
exports.default = {
    IDs: IDs,
    getData: function (item) { return item; },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return []; },
    getSdKeys: function () { return []; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
};
