import { TAG_SCHEMAS } from '../../types/TagSchemas';
import { ERRORS } from '../../types/Errors';
import { buildTagByValueAndIdentifier } from '../utils/build-tag-by-value';
export var validateTagValueByIdentifier = function (identifier, value, userValidators, tagLabel, enableSchemaValidation) {
    if (tagLabel === void 0) { tagLabel = null; }
    if (enableSchemaValidation === void 0) { enableSchemaValidation = true; }
    var tag = buildTagByValueAndIdentifier(value, identifier, {
        enableValidation: enableSchemaValidation,
        tagLabel: tagLabel,
    });
    var tagSchemaValidator = function () {
        return tagLabel
            ? TAG_SCHEMAS[identifier](tagLabel).setValue(tag, value, enableSchemaValidation)
            : TAG_SCHEMAS[identifier].setValue(tag, value, enableSchemaValidation);
    };
    var validators = [].concat(userValidators || [], tagSchemaValidator);
    var invalidValidatorIndex = validators.findIndex(function (validator) {
        if (typeof validator === 'function') {
            var currentValidatorValue = validator(value);
            return (!currentValidatorValue ||
                (typeof currentValidatorValue === 'object' &&
                    !currentValidatorValue.isValid));
        }
        return true;
    });
    var invalidValidator = validators[invalidValidatorIndex];
    return invalidValidator
        ? {
            isValid: false,
            error: (typeof invalidValidator === 'function' &&
                invalidValidator(value).error) ||
                new ERRORS.CUSTOM_VALIDATOR(invalidValidatorIndex),
        }
        : { isValid: true };
};
