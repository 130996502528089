import { BI_TYPES } from '../../../../core';
import { SEO_PANEL_TYPES, ERROR_TYPES } from './consts';
import { getRedirect } from './utils';

export const logBiResults = ({
  classifiedResults: {
    successfulRedirectResults,
    failedRedirectResults: failedRedirectResultsForBi,
    conflictedRedirectResults: conflictedRedirectResultsForBi,
  },
  logBiEvent,
}) => {
  failedRedirectResultsForBi.forEach((failedRedirectResult) => {
    const { from, to } = getRedirect({
      redirectResult: failedRedirectResult,
    });
    logBiEvent(BI_TYPES.SEO_PANEL_AUTO_REDIRECT_FAILED, {
      context: SEO_PANEL_TYPES.EDITOR,
      originUrl: from,
      destinationUrl: to,
      errorType: ERROR_TYPES.SERVER_ERROR,
    });
  });
  successfulRedirectResults.forEach((successfulRedirectResult) => {
    const { from, to } = getRedirect({
      redirectResult: successfulRedirectResult,
    });
    logBiEvent(BI_TYPES.SEO_PANEL_AUTO_REDIRECT_SUCCESS, {
      context: SEO_PANEL_TYPES.EDITOR,
      originUrl: from,
      destinationUrl: to,
    });
  });
  conflictedRedirectResultsForBi.forEach((conflictedRedirectResult) => {
    const { from, to } = getRedirect({
      redirectResult: conflictedRedirectResult,
    });
    logBiEvent(BI_TYPES.SEO_PANEL_AUTO_REDIRECT_FAILED, {
      context: SEO_PANEL_TYPES.EDITOR,
      originUrl: from,
      destinationUrl: to,
      errorType: ERROR_TYPES.CONFLICT,
    });
  });
};
