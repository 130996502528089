(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("advancedSeoClient", ["react", "lodash", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["advancedSeoClient"] = factory(require("react"), require("lodash"), require("react-dom"));
	else
		root["advancedSeoClient"] = factory(root["React"], root["_"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__83899__, __WEBPACK_EXTERNAL_MODULE__99004__, __WEBPACK_EXTERNAL_MODULE__80994__) => {
return 