import { get } from '../../utils/get';
import { getKeys, siteIDs } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { getRssFullURL } from '../utils';
import { RSS_ROUTES, RSS_ROUTE_TYPES } from '../consts';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { ITEM_TYPES } from '../../types/ItemTypes';
export var IDs = {
    MONTH_NUMBER: 'blog-archive.month-number',
    MONTH_NAME: 'blog-archive.month-name',
    YEAR: 'blog-archive.year',
    URL: 'blog-archive.url',
    POST_COUNT: 'blog-archive.post-count',
    CURRENT_PAGE: 'blog-archive.current-page',
    PREV_URL: 'blog-archive.prev-url',
    NEXT_URL: 'blog-archive.next-url',
    TOTAL_PAGES: 'blog-archive.total-pages',
    BLOG_FEED_ROUTE: 'blog-archive.blogFeedRoute',
};
var userVisible = [
    IDs.POST_COUNT,
    IDs.URL,
    IDs.MONTH_NAME,
    IDs.MONTH_NUMBER,
    IDs.YEAR,
];
var SchemaTypes = {
    BLOG_ARCHIVE_ITEM_LIST: 'blog-archive-item-list-preset',
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var numberOfItems = get(item, 'archive.items.numberOfItems');
        return _a = {},
            _a[IDs.MONTH_NUMBER] = get(item, 'archive.monthNumber'),
            _a[IDs.MONTH_NAME] = get(item, 'archive.monthName'),
            _a[IDs.YEAR] = get(item, 'archive.year'),
            _a[IDs.URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BLOG_ARCHIVE, item, get(item, 'archive.url')),
            _a[IDs.POST_COUNT] = numberOfItems,
            _a[IDs.CURRENT_PAGE] = get(item, 'archive.pagination.currentPage'),
            _a[IDs.PREV_URL] = get(item, 'archive.pagination.prevUrl'),
            _a[IDs.NEXT_URL] = get(item, 'archive.pagination.nextUrl'),
            _a[IDs.TOTAL_PAGES] = get(item, 'archive.pagination.totalPages'),
            _a[IDs.BLOG_FEED_ROUTE] = get(item, 'archive.blogFeedRoute') ||
                getRssFullURL({
                    siteUrl: get(item, 'context.siteUrl'),
                    rssRoute: RSS_ROUTES[RSS_ROUTE_TYPES.BLOG],
                }),
            _a[siteIDs.CURRENT_PAGE_NUMBER] = get(item, 'archive.pagination.currentPage'),
            _a[siteIDs.PREV] = get(item, 'archive.pagination.prevUrl'),
            _a[siteIDs.NEXT] = get(item, 'archive.pagination.nextUrl'),
            _a[siteIDs.TOTAL_PAGE_COUNT] = get(item, 'archive.pagination.totalPages'),
            _a;
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
