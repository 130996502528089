"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolvePanelSchemas = void 0;
var is_empty_1 = require("../../../utils/is-empty");
var get_selected_variant_1 = require("./get-selected-variant");
var merge_schemas_1 = require("./merge-schemas");
var merge_parent_child_schemas_1 = require("./merge-parent-child-schemas");
var resolvePanelSchemas = function (schemas, newSchema) {
    var _a, _b, _c, _d;
    var _e;
    if (schemas === void 0) { schemas = {}; }
    if (!newSchema || (0, is_empty_1.isEmpty)(newSchema) || !((_e = newSchema === null || newSchema === void 0 ? void 0 : newSchema.meta) === null || _e === void 0 ? void 0 : _e.schemaType)) {
        return schemas;
    }
    var _f = newSchema.meta, schemaType = _f.schemaType, parentSchemaType = _f.parentSchemaType;
    var _g = schemas, _h = schemaType, existingSchema = _g[_h], _j = parentSchemaType, parentSchema = _g[_j], otherSchemas = __rest(_g, [typeof _h === "symbol" ? _h : _h + "", typeof _j === "symbol" ? _j : _j + ""]);
    if (parentSchemaType && parentSchema) {
        return __assign(__assign({}, otherSchemas), (_a = {}, _a[parentSchemaType] = (0, merge_parent_child_schemas_1.mergeParentChildSchemas)(schemas[parentSchemaType], newSchema), _a));
    }
    if (!existingSchema) {
        return __assign(__assign({}, otherSchemas), (_b = {}, _b[schemaType] = newSchema, _b));
    }
    var variant = (0, get_selected_variant_1.getSelectedVariant)(newSchema, existingSchema);
    if (variant) {
        return __assign(__assign({}, otherSchemas), (_c = {}, _c[schemaType] = variant, _c));
    }
    else {
        return __assign(__assign({}, otherSchemas), (_d = {}, _d[schemaType] = (0, merge_schemas_1.mergeSchemas)(newSchema, existingSchema), _d));
    }
};
exports.resolvePanelSchemas = resolvePanelSchemas;
