"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isQuestionPostType = exports.shouldRenderQuestionsAndAnswersForumPost = exports.getSuggestedAnswerArray = exports.getAcceptedAnswerObject = exports.getStructuredData = void 0;
var get_1 = require("../../../utils/get");
function getStructuredData(IDs, item) {
    var _a;
    return _a = {},
        _a[IDs.IS_QUESTION_AND_ANSWERS_TYPE] = isQuestionPostType(item),
        _a[IDs.QUESTIONS_AND_ANSWERS_SD_DISABLE_SCHEMA] = !shouldRenderQuestionsAndAnswersForumPost(item),
        _a[IDs.QUESTIONS_AND_ANSWERS_SD_SUGGESTED_ANSWER] = getSuggestedAnswerArray(item),
        _a[IDs.QUESTIONS_AND_ANSWERS_SD_ACCEPTED_ANSWER] = getAcceptedAnswerObject(item),
        _a;
}
exports.getStructuredData = getStructuredData;
function getAcceptedAnswerObject(item) {
    var acceptedAnswers = (0, get_1.getRawValue)(item, 'comments.acceptedAnswer');
    if (!(acceptedAnswers === null || acceptedAnswers === void 0 ? void 0 : acceptedAnswers[0])) {
        return;
    }
    var _a = acceptedAnswers[0], contentText = _a.contentText, createdDate = _a.createdDate, upvoteCount = _a.upvoteCount, owner = _a.owner, url = _a.url;
    return {
        '@type': 'Answer',
        text: contentText,
        dateCreated: createdDate,
        upvoteCount: upvoteCount,
        url: url,
        author: {
            '@type': 'Person',
            name: owner,
        },
    };
}
exports.getAcceptedAnswerObject = getAcceptedAnswerObject;
function getSuggestedAnswerArray(item) {
    var suggestedAnswer = (0, get_1.getRawValue)(item, 'comments.suggestedAnswer');
    if (!(suggestedAnswer === null || suggestedAnswer === void 0 ? void 0 : suggestedAnswer[0])) {
        return;
    }
    return suggestedAnswer.map(function (_a) {
        var contentText = _a.contentText, createdDate = _a.createdDate, upvoteCount = _a.upvoteCount, owner = _a.owner, url = _a.url;
        return ({
            '@type': 'Answer',
            text: contentText,
            dateCreated: createdDate,
            upvoteCount: upvoteCount,
            url: url,
            author: {
                '@type': 'Person',
                name: owner,
            },
        });
    });
}
exports.getSuggestedAnswerArray = getSuggestedAnswerArray;
function shouldRenderQuestionsAndAnswersForumPost(item) {
    var hasAcceptedAnswer = Boolean((0, get_1.getRawValue)(item, 'comments.acceptedAnswer.0'));
    var hasSuggestedAnswer = Boolean((0, get_1.getRawValue)(item, 'comments.suggestedAnswer.0'));
    var isQuestionAndAnswerPostType = isQuestionPostType(item);
    return [
        isQuestionAndAnswerPostType,
        hasAcceptedAnswer,
        hasSuggestedAnswer,
    ].every(Boolean);
}
exports.shouldRenderQuestionsAndAnswersForumPost = shouldRenderQuestionsAndAnswersForumPost;
function isQuestionPostType(item) {
    return (0, get_1.getRawValue)(item, 'post.commentingType') === 'vote';
}
exports.isQuestionPostType = isQuestionPostType;
