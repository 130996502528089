import { getKeys } from '../../adapters/utils';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { get } from '../../utils/get';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
export var IDs = {
    PAGE_NAME: 'page.name',
    ORDER_PAGE_URL: 'restaurants-order-page.page-url',
};
var SchemaTypes = {};
var userVisible = [];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        if (!get(item, 'menu')) {
            return {};
        }
        return _a = {},
            _a[IDs.ORDER_PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.RESTAURANTS_ORDER_PAGE, item, get(item, 'menu')),
            _a[IDs.PAGE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.PAGE_NAME)),
            _a;
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
