"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSimplifiedTagLabel = void 0;
var identify_tag_1 = require("./identify-tag");
var Identifiers_1 = require("../../types/Identifiers");
var getSimplifiedTagLabel = function (seoTag) {
    var _a, _b;
    var type = (0, identify_tag_1.identifyTag)(seoTag);
    switch (type) {
        case Identifiers_1.GENERAL_IDENTIFIERS.LINK: {
            if (!(seoTag === null || seoTag === void 0 ? void 0 : seoTag.props)) {
                return;
            }
            var _c = seoTag.props, rel = _c.rel, hreflang = _c.hreflang;
            return hreflang ? "".concat(rel, "-").concat(hreflang) : rel;
        }
        case Identifiers_1.GENERAL_IDENTIFIERS.OG_TAG: {
            return (_a = seoTag === null || seoTag === void 0 ? void 0 : seoTag.props) === null || _a === void 0 ? void 0 : _a.property;
        }
        case Identifiers_1.GENERAL_IDENTIFIERS.SATANDARD_META: {
            return (_b = seoTag === null || seoTag === void 0 ? void 0 : seoTag.props) === null || _b === void 0 ? void 0 : _b.name;
        }
        default: {
            return null;
        }
    }
};
exports.getSimplifiedTagLabel = getSimplifiedTagLabel;
