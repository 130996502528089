var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEmpty } from '../utils/is-empty';
import { trimTrailingSlash } from '../utils/trim-trailing-slash';
import { INITIAL_REQUEST_OPTIONS } from './consts';
export var convertRedirectToRequest = function (_a) {
    var redirect = _a.redirect, _b = _a.requestOptions, requestOptions = _b === void 0 ? INITIAL_REQUEST_OPTIONS : _b;
    var from = redirect.from, to = redirect.to, _c = redirect.options, options = _c === void 0 ? {} : _c;
    var groupRedirect = options.groupRedirect;
    return {
        redirect: __assign({ from: groupRedirect ? "".concat(trimTrailingSlash(from), "/") : from, to: groupRedirect ? "".concat(trimTrailingSlash(to), "/") : to }, (!isEmpty(options) && { options: options })),
        options: requestOptions,
    };
};
