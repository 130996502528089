import ProxyApp from '../app/ProxyApp';

const BASIC_TAB_NAME = 'BASIC';
const SOCIAL_TAB_NAME = 'SOCIAL';
const ADVANCED_TAB_NAME = 'ADVANCED';

export { TAB_NAMES, LEGACY_TAB_NAMES } from './tab-names';
export { BasicTab } from './basic-tab';
export { SocialTab } from './social-tab';
export { AdvancedTab } from './advanced-tab';

export const TABS = [
  {
    label_key: 'Pages_SEO_Tab_Title',
    PanelClass: ProxyApp,
    name: BASIC_TAB_NAME,
    helpArticleId: '256fed0b-b5a0-46ae-8bf2-cb39c0449c54',
  },
  {
    label_key: 'Pages_Social_Tab_Title',
    PanelClass: ProxyApp,
    name: SOCIAL_TAB_NAME,
    helpArticleId: '71965d29-5be2-4bc5-9509-6a25cc4bdd6c',
  },
  {
    label_key: 'Pages_AdvancedSEO_Tab_Title',
    PanelClass: ProxyApp,
    name: ADVANCED_TAB_NAME,
    helpArticleId: '011b779e-57bb-4084-ac54-efcf7c951181',
  },
];

export const SEO_TABS = [
  {
    label_key: 'Pages_SEO_Tab_Title',
    PanelClass: ProxyApp,
    name: 'seo',
    helpArticleId: '256fed0b-b5a0-46ae-8bf2-cb39c0449c54',
  },
  {
    label_key: 'Pages_Social_Tab_Title',
    PanelClass: ProxyApp,
    name: 'social',
    helpArticleId: '71965d29-5be2-4bc5-9509-6a25cc4bdd6c',
  },
  {
    label_key: 'Pages_AdvancedSEO_Tab_Title',
    PanelClass: ProxyApp,
    name: 'advanced',
    helpArticleId: '011b779e-57bb-4084-ac54-efcf7c951181',
  },
];
export const NEW_ORDER_SEO_TABS = [
  {
    label_key: 'Pages_SEO_Tab_Title',
    PanelClass: ProxyApp,
    name: 'seo',
    helpArticleId: '256fed0b-b5a0-46ae-8bf2-cb39c0449c54',
  },
  {
    label_key: 'Pages_AdvancedSEO_Tab_Title',
    PanelClass: ProxyApp,
    name: 'advanced',
    helpArticleId: '011b779e-57bb-4084-ac54-efcf7c951181',
  },
  {
    label_key: 'Pages_Social_Tab_Title',
    PanelClass: ProxyApp,
    name: 'social',
    helpArticleId: '71965d29-5be2-4bc5-9509-6a25cc4bdd6c',
  },
];
