import React, { useState } from 'react';
import { func, object, string, array } from 'prop-types';
import { translate } from 'react-i18next';
import { nanoid } from 'nanoid';
import { throttle } from 'lodash';
import { DataHooks } from '../test-selectors';

import {
  CustomModal,
  TextInput,
  Text,
  InfoIcon,
  TextInputMultiline,
} from '@wix/wix-base-ui';
import * as BI_TYPES from '../../../../../core/bi/action-types';
import {
  PROMOTE_SEO_CLOSE_TAG_SETTINGS,
  PROMOTE_SEO_MODAL_CTA_CLICK,
} from '../../../../../core/bi/action-types';
import {
  addEnclosingScriptTag,
  removeEnclosingScriptTag,
  removeWhiteSpace,
} from '../../../../../core/utils/structured-data';
import TooltipContent from '../../../ui/tooltip-content';
import { MODAL_TYPES, BI_DATA } from '../constants';
import { validateName, validateSchema, validateForm } from './validators';
import styles from './edit-schema-modal.scss';
import { TAB_NAMES } from '../../../../tabs';

const EditSchemaModal = ({
  initialSchema = {},
  logBiEvent,
  onClose,
  onApply,
  otherSchemas = [],
  sourcePreset,
  t,
}) => {
  const [name, setName] = useState({
    value: initialSchema.displayName || '',
    isValid: true,
  });
  const [code, setCode] = useState({
    value: addEnclosingScriptTag(initialSchema.value) || '',
    isValid: true,
  });

  const handleNameChange = (value) => {
    const { isValid, error } = validateName(value, otherSchemas);

    setName({
      value,
      isDirty: true,
      isValid,
      error: t(error),
    });
  };

  const handleCodeChange = (value) => {
    const { isValid, warning, error } = validateSchema(value, otherSchemas);

    setCode({
      value,
      isDirty: true,
      isValid,
      error,
      warning,
    });
  };

  const handleApply = () => {
    if (!validateForm(name, code, otherSchemas).isValid) {
      handleCodeChange(code.value);
      handleNameChange(name.value);
      return;
    }
    onApply({
      schema: removeWhiteSpace(removeEnclosingScriptTag(code.value)).trim(),
      schemaType: initialSchema.schemaType || nanoid(8),
      displayName: name.value.trim(),
      sourcePreset,
      sourceSchemaType: initialSchema.schemaType,
    });
    onClose();
  };

  const modalType = initialSchema.schemaType
    ? MODAL_TYPES.EDIT
    : MODAL_TYPES.ADD;

  const shouldEnableApply = () => {
    const isFormEmpty = !name.value && !code.value;
    const isFormDirtyAndValid =
      (name.isDirty || code.isDirty) &&
      validateForm(name, code, otherSchemas).isValid;

    return isFormEmpty || isFormDirtyAndValid;
  };

  const reportTooltipCtaClickToBi = (tooltipName, ctaName) =>
    logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_CTA_CLICK, {
      tab: TAB_NAMES.ADVANCED_TAB_NAME,
      tooltipName,
      ctaName,
    });

  const onErrorOrWarningClicked = (errorMessage = '') => {
    window.open(
      t(
        'adv-seo.adv-panel.structured-data.edit-schema-modal.code.error.learn-more.link',
      ),
      '_blank',
    );
    reportTooltipCtaClickToBi(
      errorMessage || 'structured_data_schema_validation_error_message',
      'learn_more',
    );
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName:
        'adv-seo.adv-panel.structured-data.edit-schema-modal.code.error.learn-more.link',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };

  const reportErrorToBi = (errorName, errorType) =>
    logBiEvent(BI_TYPES.PROMOTE_SEO_ERROR_VIEW, {
      tabName: TAB_NAMES.ADVANCED_TAB_NAME,
      errorName,
      errorType,
    });

  return (
    <CustomModal
      dataHook="edit-schema-modal"
      className={styles.root}
      title={t(
        `adv-seo.adv-panel.structured-data.edit-schema-modal.${modalType}.header.label`,
      )}
      primaryButtonText={t(
        'adv-seo.adv-panel.structured-data.edit-schema-modal.footer.cta',
      )}
      secondaryButtonText={t(
        'adv-seo.adv-panel.structured-data.edit-schema-modal.footer.cancel',
      )}
      primaryButtonOnClick={() => {
        logBiEvent(PROMOTE_SEO_MODAL_CTA_CLICK, {
          modalName: BI_DATA.FIELD_NAME,
          ctaType: BI_DATA.APPLY_ACTION,
        });
        handleApply();
      }}
      primaryButtonProps={{
        disabled: !shouldEnableApply(),
      }}
      secondaryButtonOnClick={() => {
        logBiEvent(PROMOTE_SEO_CLOSE_TAG_SETTINGS, {
          fieldName: BI_DATA.FIELD_NAME,
          closeType: BI_DATA.CANCEL_ACTION,
        });

        onClose();
      }}
      onCloseButtonClick={() => {
        logBiEvent(PROMOTE_SEO_CLOSE_TAG_SETTINGS, {
          fieldName: BI_DATA.FIELD_NAME,
          closeType: BI_DATA.CLOSE_ACTION,
        });

        onClose();
      }}
      removeContentPadding
    >
      <div className={styles.modalContent}>
        <div className={styles.nameContainer}>
          <Text
            size="small"
            weight="thin"
            skin="secondary"
            shouldTranslate={false}
          >
            {t(
              'adv-seo.adv-panel.structured-data.edit-schema-modal.name.label',
            )}
          </Text>
          <Text size="small" disabled>
            {name.length}
          </Text>

          <TextInput
            dataHook={DataHooks.EditSchemaModalNameInput}
            placeholder={t(
              'adv-seo.adv-panel.structured-data.edit-schema-modal.name.placeholder',
            )}
            value={name.value}
            onChange={handleNameChange}
            onBlur={() =>
              !name.isValid && reportErrorToBi('sd_title', name.error)
            }
            className={styles.nameInput}
            isValid={!name.error?.length}
            invalidMessage={name.error}
            hideSuccessIndication
            shouldTranslate={false}
          />
        </div>
        <div className={styles.codeContainer}>
          <div className={styles.codeLabel}>
            <Text
              size="small"
              weight="thin"
              skin="secondary"
              shouldTranslate={false}
            >
              {t(
                'adv-seo.adv-panel.structured-data.edit-schema-modal.code.label',
              )}
            </Text>
            <InfoIcon
              className={styles.infoIcon}
              text={t(
                'adv-seo.adv-panel.structured-data.edit-schema-modal.code.label.tooltip.schema-org',
              )}
              linkText={t(
                'adv-seo.adv-panel.structured-data.edit-schema-modal.code.tooltip.schema-org.link.label',
              )}
              onLinkClick={() => {
                reportTooltipCtaClickToBi(
                  'need_a_markup',
                  'schema_generator_link',
                );
                window.open(
                  t(
                    'adv-seo.adv-panel.structured-data.edit-schema-modal.code.tooltip.link',
                  ),
                  '_blank',
                );
              }}
              onTooltipOpen={throttle(
                () =>
                  logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW, {
                    tooltipName: 'need_a_markup',
                    tabName: TAB_NAMES.ADVANCED_TAB_NAME,
                  }),
                3000,
              )}
              shouldTranslate={false}
            />
          </div>
          <TextInputMultiline
            dataHook="edit-schema-modal-code-input"
            placeholder={t(
              'adv-seo.adv-panel.structured-data.edit-schema-modal.code.placeholder',
            )}
            className={styles.codeInput}
            onChange={handleCodeChange}
            onBlur={() =>
              !code.isValid && reportErrorToBi('sd_markup', code.error)
            }
            value={code.value}
            isTooltipInteractive={true}
            isValid={!code.error && !code.warning}
            invalidMessage={
              <TooltipContent
                shouldTranslate={false}
                text={t(code?.error || code?.warning?.message, {
                  type: code?.warning?.type,
                })}
                linkText={t('adv-seo.common.learn-more')}
                onLinkClick={onErrorOrWarningClicked}
              />
            }
            hideSuccessIndication
            shouldTranslate={false}
          />
        </div>
      </div>
    </CustomModal>
  );
};

EditSchemaModal.propTypes = {
  initialSchema: object,
  logBiEvent: func.isRequired,
  onApply: func.isRequired,
  onClose: func.isRequired,
  otherSchemas: array.isRequired,
  sourcePreset: string,
  t: func.isRequired,
  experiments: object.isRequired,
};

export default translate(null, { wait: true })(EditSchemaModal);
