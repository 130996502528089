import {
  GetSiteStructureResponse,
  SiteStructureGraph,
  createSiteStructureGraph,
  hasParent,
} from '@wix/seo-page-hierarchy-library';
import { useAtomValue } from 'jotai';
import { noop } from 'lodash';
import React, { createContext, useContext, useCallback, useMemo } from 'react';
import {
  isEditorXAtom,
  isUrlHierarchyFlattenedAtom,
  pageIdAtom,
} from '../app/atoms';

type PageHierarchyContextValue = {
  staticSiteStructure: GetSiteStructureResponse;
  siteStructureGraph: SiteStructureGraph;
  setParentPageId: SetParentPageId;
  clearParentPageId: ClearParentPageId;
};

const defaultStaticSiteStructure: GetSiteStructureResponse = {
  siteStructure: {},
};

export const defaultPageHierarchyContextValue: PageHierarchyContextValue = {
  staticSiteStructure: defaultStaticSiteStructure,
  siteStructureGraph: undefined,
  setParentPageId: noop,
  clearParentPageId: noop,
};

export const PageHierarchyContext = createContext<PageHierarchyContextValue>(
  defaultPageHierarchyContextValue,
);

export const usePageHierarchyContext = () => {
  const pageHierarchyContextValue = useContext(PageHierarchyContext);
  if (pageHierarchyContextValue === undefined) {
    throw new Error(
      'usePageHierarchyContext must be used within a PageHierarchyContextProvider',
    );
  }

  return pageHierarchyContextValue;
};

type SetParentPageId = (parentPageId: string | undefined) => void;

type ClearParentPageId = () => void;

const useCreatePageHierarchyContextValue = (
  staticSiteStructure: GetSiteStructureResponse,
  setParentPageId: SetParentPageId,
  isEditorX: boolean,
): PageHierarchyContextValue => {
  const siteStructureGraph = useMemo(
    () =>
      createSiteStructureGraph(
        !isEditorX ? staticSiteStructure : defaultStaticSiteStructure,
      ),
    [staticSiteStructure, isEditorX],
  );
  const clearParentPageId = useCallback(
    () => setParentPageId(undefined),
    [setParentPageId],
  );

  return {
    staticSiteStructure,
    siteStructureGraph,
    setParentPageId,
    clearParentPageId,
  };
};

type PageHierarchyContextProviderProps = {
  staticSiteStructure: GetSiteStructureResponse;
  setParentPageId: SetParentPageId;
  isEditorX: boolean;
  children: React.ReactNode;
};

export const PageHierarchyContextProvider: React.FC<
  PageHierarchyContextProviderProps
> = ({ staticSiteStructure, setParentPageId, isEditorX, children }) => {
  const pageHierarchyContextValue = useCreatePageHierarchyContextValue(
    staticSiteStructure,
    setParentPageId,
    isEditorX,
  );

  return (
    <PageHierarchyContext.Provider value={pageHierarchyContextValue}>
      {children}
    </PageHierarchyContext.Provider>
  );
};

export const getHasUrllHierarchyAndHasParent = (
  isEditorX: boolean,
  siteStructureGraph: SiteStructureGraph,
  pageId: string,
  isUrlHierarchyFlattened: boolean,
): boolean => {
  return (
    !isEditorX &&
    hasParent(siteStructureGraph, pageId) &&
    !isUrlHierarchyFlattened
  );
};

export const useHasUrlHierarchyAndHasParent = () => {
  const { siteStructureGraph } = usePageHierarchyContext();
  const isEditorX = useAtomValue(isEditorXAtom);
  const isUrlHierarchyFlattened = useAtomValue(isUrlHierarchyFlattenedAtom);

  const pageId = useAtomValue(pageIdAtom);

  return useMemo(
    () =>
      getHasUrllHierarchyAndHasParent(
        isEditorX,
        siteStructureGraph,
        pageId,
        isUrlHierarchyFlattened,
      ),
    [isEditorX, siteStructureGraph, pageId, isUrlHierarchyFlattened],
  );
};
