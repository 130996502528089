import React, { useCallback, useContext } from 'react';
import { TextButton } from '@wix/wix-base-ui';
import styles from './robots-tag-reset-to-pattern.scss';
import { RobotsTagContext } from '../../../../core/components/robots-tag/robots-tag-hooks';
import { KEYS } from '../../../../core/utils/maps';
import { Revert } from '@wix/wix-ui-icons-common';
import { translate } from 'react-i18next';

export const ResetToPattern = translate()(({ t }) => {
  const { onChange, onSave } = useContext(RobotsTagContext);

  const resetToPattern = useCallback(async () => {
    await onChange(KEYS.ROBOTS_TAG)('');
    onSave(KEYS.ROBOTS_TAG)();
  }, [onChange, onSave]);

  return (
    <TextButton
      dataHook="adv-seo.editor.advanced-panel.robots-tag.reset-to-pattern"
      onClick={resetToPattern}
      size="small"
      prefixIcon={
        <div className={styles.resetToPatternIconButton}>
          <Revert />
        </div>
      }
      shouldTranslate={false}
    >
      {t('adv-seo.common.reset-to-pattern.title.seo-settings')}
    </TextButton>
  );
});
