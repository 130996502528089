import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import {
  Composites,
  TextLabel,
  RichText,
  NotificationBanner,
} from '@wix/wix-base-ui';

import InfoIcon from '../../ui/info-icon';
import * as BI_TYPES from '../../../../core/bi/action-types';
import { ParentPageDropDown } from './parent-page-drop-down';
import { useAtomValue } from 'jotai';
import {
  hasBreadcrumbsComponentOnPageAtom,
  isEditorXAtom,
  isUrlHierarchyFlattenedAtom,
  pageIdAtom,
  routerUrlPrefixAtom,
  seoSettingsMainPagesWithPageUrlCardOpenedUrlAtom,
} from '../../../app/atoms';
import { useExperiments } from '@wix/wix-experiments-react';
import { hasParent, isPageExists } from '@wix/seo-page-hierarchy-library';
import { usePageHierarchyContext } from '../../../hooks/page-hierarchy';
import { useGetIsCurrentPageTpaSectionPage } from './url-hierarchy';

type ParentPageSectionProps = {
  t: any;
  logBiEvent: any;
  openHelpCenter: any;
  language: { current?: string; original?: string };
};

export const useShouldShowParentPageSection = () => {
  const isEditorX = useAtomValue(isEditorXAtom);
  const pageId = useAtomValue(pageIdAtom);
  const routerUrlPrefix = useAtomValue(routerUrlPrefixAtom);
  const { siteStructureGraph } = usePageHierarchyContext();

  const isCurrentPageTpaSectionPage =
    useGetIsCurrentPageTpaSectionPage(isEditorX);
  const shouldShowParentPageSection =
    !isEditorX &&
    !isCurrentPageTpaSectionPage &&
    isPageExists(siteStructureGraph, pageId) &&
    !routerUrlPrefix;
  return shouldShowParentPageSection;
};

export const ParentPageSection: React.FC<ParentPageSectionProps> = ({
  t,
  logBiEvent,
  openHelpCenter,
  language,
}) => {
  const { experiments } = useExperiments();
  const enableHierarchyUrlFlatten = experiments.enabled(
    'specs.promote-seo.enableHierarchyUrlFlatten',
  );

  const onPageHierarchyInfoLinkClick = useCallback(() => {
    window.open(
      t(
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.info-tooltip-link.url',
      ),
      '_blank',
    );
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName:
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.info-tooltip-link',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  }, [logBiEvent, t]);

  const { siteStructureGraph } = usePageHierarchyContext();

  const pageId = useAtomValue(pageIdAtom);
  const isUrlHierarchyFlattened = useAtomValue(isUrlHierarchyFlattenedAtom);

  const hasBreadcrumbsComponentOnPage = useAtomValue(
    hasBreadcrumbsComponentOnPageAtom,
  );

  return (
    <Composites.DropDownLabeled>
      <TextLabel
        value={t(
          'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.label',
        )}
        shouldTranslate={false}
        automationId="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.label"
      />
      <InfoIcon
        tooltipContent={
          <RichText type="T02" shouldTranslate={false}>
            {enableHierarchyUrlFlatten && hasBreadcrumbsComponentOnPage ? (
              <Trans
                i18nKey="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.with-breadcrumbs.info-tooltip-text"
                components={[
                  <strong>bold</strong>,
                  <strong>bold</strong>,
                  <a target="_blank" onClick={onPageHierarchyInfoLinkClick}>
                    Learn more
                  </a>,
                ]}
              />
            ) : (
              <Trans
                i18nKey="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.info-tooltip-text"
                components={[
                  <strong>bold</strong>,
                  <a target="_blank" onClick={onPageHierarchyInfoLinkClick}>
                    Learn more
                  </a>,
                ]}
              />
            )}
          </RichText>
        }
        shouldTranslate={false}
      />
      <ParentPageDropDown
        t={t}
        logBiEvent={logBiEvent}
        language={language}
        openHelpCenter={openHelpCenter}
      />
      {enableHierarchyUrlFlatten &&
        isUrlHierarchyFlattened &&
        hasParent(siteStructureGraph, pageId) &&
        hasBreadcrumbsComponentOnPage && (
          <div style={{ marginTop: '6px' }}>
            <SiteHierarchyOnlyBreadcrumbsNotification
              t={t}
              logBiEvent={logBiEvent}
            />
          </div>
        )}
      {enableHierarchyUrlFlatten &&
        isUrlHierarchyFlattened &&
        hasParent(siteStructureGraph, pageId) &&
        !hasBreadcrumbsComponentOnPage && (
          <div style={{ marginTop: '6px' }}>
            <SiteHierarchyNotReflectedNotification
              t={t}
              logBiEvent={logBiEvent}
            />
          </div>
        )}
    </Composites.DropDownLabeled>
  );
};

const SiteHierarchyOnlyBreadcrumbsNotification: React.FC<{
  t: any;
  logBiEvent: any;
}> = ({ t, logBiEvent }) => {
  const seoSettingsUrl = useAtomValue(
    seoSettingsMainPagesWithPageUrlCardOpenedUrlAtom,
  );

  return (
    <NotificationBanner
      skin="standard"
      multiline
      shouldTranslate={false}
      text={
        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginRight: '6px' }}>
            {t(
              'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.only-breadcrumbs-notification.description',
            )}
          </div>
          <InfoIcon
            shouldTranslate={false}
            tooltipContent={
              <Trans
                i18nKey="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.only-breadcrumbs-notification.tooltip"
                components={[
                  <a target="_blank" href={seoSettingsUrl} rel="noreferrer">
                    Go to SEO Settings
                  </a>,
                ]}
              />
            }
          />
        </div>
      }
    />
  );
};

const SiteHierarchyNotReflectedNotification: React.FC<{
  t: any;
  logBiEvent: any;
}> = ({ t, logBiEvent }) => {
  const seoSettingsUrl = useAtomValue(
    seoSettingsMainPagesWithPageUrlCardOpenedUrlAtom,
  );

  return (
    <NotificationBanner
      skin="warning"
      multiline
      shouldTranslate={false}
      text={
        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Trans
              i18nKey="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.not-reflected-notificaiton.description"
              components={[
                <a target="_blank" href={seoSettingsUrl} rel="noreferrer">
                  Go to SEO Settings
                </a>,
              ]}
            />
          </div>
        </div>
      }
    />
  );
};
