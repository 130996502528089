import { SCHEMA_TYPES } from './schema-types';
import { siteIDs } from '../../../adapters/utils';
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'Restaurant',
    name: "{{".concat(siteIDs.BUSINESS_NAME, "}}"),
    image: "{{".concat(siteIDs.RESTAURANT_IMAGES, "}}"),
    address: {
        '@type': 'PostalAddress',
        addressCountry: "{{".concat(siteIDs.BUSINESS_LOCATION_COUNTRY, "}}"),
        addressLocality: "{{".concat(siteIDs.BUSINESS_LOCATION_CITY, "}}"),
        addressRegion: "{{".concat(siteIDs.BUSINESS_LOCATION_STATE, "}}"),
        postalCode: "{{".concat(siteIDs.BUSINESS_POSTAL_CODE, "}}"),
        streetAddress: "{{".concat(siteIDs.BUSINESS_LOCATION_STREET_ADDRESS, "}}"),
    },
    geo: {
        '@type': 'GeoCoordinates',
        latitude: "{{".concat(siteIDs.BUSINESS_LOCATION_LATITUDE, "}}"),
        longitude: "{{".concat(siteIDs.BUSINESS_LOCATION_LONGITUDE, "}}"),
    },
    url: "{{".concat(siteIDs.SITE_URL, "}}"),
    telephone: "{{".concat(siteIDs.BUSINESS_PHONE, "}}"),
    openingHoursSpecification: "{{".concat(siteIDs.OPENING_HOURS_SPECIFICATION, "}}"),
    acceptReservations: 'True',
};
var requiredSchemaVariables = [
    siteIDs.RESTAURANT_IMAGES,
    siteIDs.BUSINESS_NAME,
    siteIDs.BUSINESS_LOCATION_COUNTRY,
    siteIDs.BUSINESS_LOCATION_CITY,
    siteIDs.BUSINESS_LOCATION_STREET_ADDRESS,
    siteIDs.RESTAURANT_IMAGES,
];
export default {
    type: SCHEMA_TYPES.RESTAURANT_SCHEMA,
    schema: schema,
    requiredSchemaVariables: requiredSchemaVariables,
};
