import { getImageObject } from '../../adapters/structure-data-utils';
export var getMenuImagesStructuredData = function (items) {
    var imagesData = items === null || items === void 0 ? void 0 : items.map(function (_a) {
        var image = _a.image;
        return image;
    }).filter(function (image) { return !!image; });
    if (!imagesData || !imagesData.length) {
        return null;
    }
    var images = getImageObject('images', imagesData, false, true).images;
    return images;
};
