import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { translate } from 'react-i18next';
import {
  TextButton,
  Text,
  RichText,
  NotificationBanner,
  InfoIcon,
} from '@wix/wix-base-ui';
import {
  PROMOTE_SEO_CTA_CLICK,
  PROMOTE_SEO_PANEL_COMPONENT_VIEW,
  PROMOTE_SEO_TOOLTIP_CTA_CLICK,
} from '../../../core/bi/action-types';
import { usePageHierarchyContext } from '../../hooks/page-hierarchy';
import { hasChildren, isPageExists } from '@wix/seo-page-hierarchy-library';
import { useAtomValue } from 'jotai';
import { pageIdAtom, isUrlHierarchyFlattenedAtom } from '../../app/atoms';

const BasicPanelUrlAutoRedirect = ({
  t,
  isDomainConnected,
  premiumDomainsUrl,
  preventAutoRedirectValue,
  onSave,
  logBiEvent,
}) => {
  useEffect(() => {
    logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
      component: isDomainConnected
        ? 'auto_redirect_toggle'
        : 'connect_a_domain',
      field: 'URL slug',
    });
  }, [logBiEvent, isDomainConnected]);

  const handleCheckboxChange = useCallback(
    (checkboxValue) => {
      onSave({ value: !checkboxValue });
    },
    [onSave],
  );
  const handleConnectDomain = () => {
    logBiEvent(PROMOTE_SEO_CTA_CLICK, {
      linkName: 'connect_a_domain',
      linkType: 'general',
      linkSource: 'panel',
    });
    window.open(premiumDomainsUrl, '_blank');
  };

  useEffect(() => {
    if (isDomainConnected && preventAutoRedirectValue) {
      handleCheckboxChange(true);
    }
  }, [isDomainConnected, preventAutoRedirectValue, handleCheckboxChange]);

  useEffect(() => {
    if (isDomainConnected) {
      logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
        component: 'Visitors will be redirected to the new URL automatically',
        field: 'URL slug',
      });
    }
  }, [isDomainConnected, logBiEvent]);

  const pageId = useAtomValue(pageIdAtom);
  const { siteStructureGraph } = usePageHierarchyContext();
  const isUrlHierarchyFlattened = useAtomValue(isUrlHierarchyFlattenedAtom);
  const pageHasChildren = useMemo(
    () =>
      isPageExists(siteStructureGraph, pageId) &&
      !isUrlHierarchyFlattened &&
      hasChildren(siteStructureGraph, pageId),
    [siteStructureGraph, pageId, isUrlHierarchyFlattened],
  );

  return (
    <>
      {isDomainConnected ? (
        <NotificationBanner
          skin="standard"
          multiline
          shouldTranslate={false}
          text={
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ marginRight: '6px' }}>
                {pageHasChildren
                  ? t(
                      'adv-seo.side-panel.page-seo.page-url.auto-redirects-no-toggle.with-children.label',
                    )
                  : t(
                      'adv-seo.side-panel.page-seo.page-url.auto-redirects-no-toggle.label',
                    )}
              </div>
              <InfoIcon
                shouldTranslate={false}
                text={t(
                  'adv-seo.side-panel.page-seo.page-url.auto-redirects-no-toggle.tooltip',
                )}
                linkText={t(
                  'adv-seo.side-panel.page-seo.page-url.auto-redirects-no-toggle.tooltip.learn-more',
                )}
                onLinkClick={() => {
                  logBiEvent(PROMOTE_SEO_TOOLTIP_CTA_CLICK, {
                    tooltipName:
                      'Visitors will be redirected to the new URL automatically',
                    ctaName: 'Learn more',
                  });
                  window.open(
                    t(
                      'adv-seo.side-panel.page-seo.page-url.auto-redirects-no-toggle.tooltip.learn-more-url',
                    ),
                    '_blank',
                  );
                }}
              />
            </div>
          }
        />
      ) : (
        <NotificationBanner
          skin="premium"
          multiline
          shouldTranslate={false}
          text={
            <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <RichText shouldTranslate={false}>
                <Text weight="bold" size="small" shouldTranslate={false}>
                  {t(
                    'adv-seo.basic-panel.page-url.create-auto-redirect.connect-a-domain.title',
                  )}
                </Text>
                <br />
                {t(
                  'adv-seo.basic-panel.page-url.create-auto-redirect.connect-a-domain.description',
                )}
              </RichText>
              <RichText>
                <TextButton
                  onClick={handleConnectDomain}
                  skin="premium"
                  size="small"
                  shouldTranslate={false}
                >
                  {t(
                    'adv-seo.basic-panel.page-url.create-auto-redirect.connect-a-domain.cta.label',
                  )}
                </TextButton>
              </RichText>
            </div>
          }
        />
      )}
    </>
  );
};

export default translate()(BasicPanelUrlAutoRedirect);
