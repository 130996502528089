"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var consts_1 = require("../../consts");
var adapter_utils_1 = require("./adapter-utils");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
exports.IDs = {
    ID: 'portfolio-project.id',
    TITLE: 'portfolio-project.title',
    DESCRIPTION: 'portfolio-project.description',
    PROJECT_PAGE_URL: 'portfolio-project.url',
    TOTAL_MEDIA_COUNT: 'portfolio-project.total-media',
    MAIN_IMAGE: 'portfolio-project.main-image.url',
    COVER_IMAGE: 'portfolio-project.cover-image.url',
    COVER_IMAGE_HEIGHT: 'portfolio-project.cover-image.height',
    COVER_IMAGE_WIDTH: 'portfolio-project.cover-image.width',
    COVER_IMAGE_ALT: 'portfolio-project.cover-image.alt',
    DISABLE_IMAGES_SCHEMA: 'portfolio-project.images-schema.disable',
    DISABLE_VIDEOS_SCHEMA: 'portfolio-project.videos-search-schema.disable',
    SD_JSON_IMAGES: 'portfolio-project.structured-data.images',
    SD_JSON_VIDEOS: 'portfolio-project.structured-data.videos',
    FULL_DESCRIPTION: 'portfolio-project.full-description',
};
var SchemaTypes = {
    PROJECT_IMAGES: 'portfolio-project-images',
    // PROJECT_VIDEOS: 'portfolio-project-videos',
};
var userVisible = [
    exports.IDs.TITLE,
    exports.IDs.DESCRIPTION,
    exports.IDs.PROJECT_PAGE_URL,
    exports.IDs.TOTAL_MEDIA_COUNT,
    exports.IDs.COVER_IMAGE,
];
var userVisibleSdOnly = [
    exports.IDs.SD_JSON_IMAGES,
    // IDs.SD_JSON_VIDEOS
];
var getCoverImageData = function (item) {
    var _a;
    var hasImageUrl = (0, get_1.getRawValue)(item, 'project.coverImage.imageInfo.url');
    return _a = {},
        _a[exports.IDs.COVER_IMAGE] = hasImageUrl
            ? (0, get_1.get)(item, 'project.coverImage.imageInfo.url')
            : '',
        _a[exports.IDs.COVER_IMAGE_HEIGHT] = hasImageUrl
            ? (0, get_1.get)(item, 'project.coverImage.imageInfo.height')
            : '',
        _a[exports.IDs.COVER_IMAGE_WIDTH] = hasImageUrl
            ? (0, get_1.get)(item, 'project.coverImage.imageInfo.width')
            : '',
        _a[exports.IDs.COVER_IMAGE_ALT] = hasImageUrl
            ? (0, get_1.get)(item, 'project.coverImage.imageInfo.altText')
            : '',
        _a;
};
var getSdStatus = function (item, data, schemaType) {
    // eslint-disable-next-line default-case
    switch (schemaType) {
        case SchemaTypes.PROJECT_IMAGES:
            if (data[exports.IDs.SD_JSON_IMAGES] === '') {
                return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
            }
            break;
        case SchemaTypes.PROJECT_VIDEOS:
            if (data[exports.IDs.SD_JSON_VIDEOS] === '') {
                return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
            }
            break;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getFullUrl = function (itemData) {
    return (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.PORTFOLIO_PROJECTS, itemData, (0, get_1.get)(itemData, 'project.url.url'));
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b, _c;
        var _d, _e;
        var projectItems = (_d = (0, get_1.getRawValue)(item, 'projectItems')) !== null && _d !== void 0 ? _d : [];
        var coverImageData = getCoverImageData(item);
        var data = __assign(__assign(__assign((_a = {}, _a[exports.IDs.ID] = (0, get_1.get)(item, 'project.id'), _a[exports.IDs.TITLE] = (0, get_1.get)(item, 'project.title'), _a[exports.IDs.DESCRIPTION] = (0, get_1.get)(item, 'project.description'), _a[exports.IDs.TOTAL_MEDIA_COUNT] = projectItems.length, _a[exports.IDs.PROJECT_PAGE_URL] = getFullUrl(item), _a[exports.IDs.FULL_DESCRIPTION] = (0, get_1.get)(item, 'project.description'), _a), coverImageData), (_b = {}, _b[exports.IDs.MAIN_IMAGE] = (_e = (0, get_1.get)(item, 'project.coverImage.imageInfo.url')) !== null && _e !== void 0 ? _e : '', _b)), (0, adapter_utils_1.getStructuredData)(exports.IDs, projectItems, coverImageData));
        var imagesSdStatus = getSdStatus(item, data, SchemaTypes.PROJECT_IMAGES) !== consts_1.SD_STATUS.ENABLED;
        var videosStatus = getSdStatus(item, data, SchemaTypes.PROJECT_VIDEOS) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_c = {}, _c[exports.IDs.DISABLE_IMAGES_SCHEMA] = imagesSdStatus, _c[exports.IDs.DISABLE_VIDEOS_SCHEMA] = videosStatus, _c));
    },
    getSdStatus: getSdStatus,
    getFullUrl: function (itemData) { return getFullUrl(itemData); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.project.url.url = url;
        return itemData;
    },
    getSlug: function (item) { return (0, get_1.get)(item, 'project.slug'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisibleSdOnly); },
};
