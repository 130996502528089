import React, { PureComponent } from 'react';
import { func, node } from 'prop-types';
import { translate } from 'react-i18next';
import { Tooltip } from '@wix/wix-base-ui';

import * as BI_TYPES from '../../../core/bi/action-types';
import TooltipContent from '../ui/tooltip-content';

class SocialPanelImageDescription extends PureComponent {
  static propTypes = {
    logBiEvent: func.isRequired,
    openHelpCenter: func.isRequired,
    children: node,
    t: func.isRequired,
  };

  render() {
    const { t, children, baseTranslationKey } = this.props;
    return (
      <p className="seo-advanced-client-social-description">
        <span>
          <span>{t(`${baseTranslationKey}.preview`)}</span>
          <span>&nbsp;</span>
          <Tooltip
            shouldTranslate={false}
            interactive
            closeOnContentMouseClick={true}
            onOpen={this._onTooltipOpened}
            openDelay={350}
            content={
              <TooltipContent
                text={`${baseTranslationKey}.preview.learn.tooltip.description`}
                linkText={`${baseTranslationKey}.preview.learn.tooltip.find-out`}
                onLinkClick={this._onLearnMore}
              />
            }
          >
            <a>{t(`${baseTranslationKey}.learn`)}</a>
          </Tooltip>
        </span>
        {children}
      </p>
    );
  }

  _onTooltipOpened = () => {
    const { logBiEvent, baseTranslationKey } = this.props;
    logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW, {
      tooltipName: `${baseTranslationKey}.preview.learn`,
    });
  };

  _onLearnMore = () => {
    const { logBiEvent, openHelpCenter, baseTranslationKey } = this.props;
    openHelpCenter();
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: `${baseTranslationKey}.preview.learn`,
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default translate()(SocialPanelImageDescription);
