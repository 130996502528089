"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIA_PREFIX = exports.IMAGES_PROPERTY = exports.FIELD_TYPE = void 0;
exports.FIELD_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    IMAGE: 'image',
    VIDEO: 'video',
};
exports.IMAGES_PROPERTY = 'images';
exports.MEDIA_PREFIX = 'media/';
