export const MESSAGES = {
  GET_AUTO_REDIRECT_RESULTS: 'GET_AUTO_REDIRECT_RESULTS',
  AUTO_REDIRECT_RESULTS: 'AUTO_REDIRECT_RESULTS',
};

export const SEO_PANEL_TYPES = {
  EDITOR: 'editor',
};

export const ERROR_TYPES = {
  SERVER_ERROR: 'server-error',
  CONFLICT: 'conflict',
};
