import React, { useCallback } from 'react';
import {
  PageHierarchyContextProvider,
  usePageHierarchyContext,
} from '../hooks/page-hierarchy';
import { useGetPublicURL } from './url-hooks';
import App from './app';
import {
  businessManagerDashboardUrlAtom,
  isUrlHierarchyFlattenedAtom,
  isEditorXAtom,
  pageIdAtom,
  routerUrlPrefixAtom,
} from './atoms';
import { Provider, useAtomValue } from 'jotai';

import { getSeoUserConfig } from '@wix/ambassador-promote-seo-v1-seo-user-config/http';
import { getHttpClient } from '../utils/http-client-utils';
import { useEffectFetchAndSetAtom, useEffectSetAtom } from './jotai-utils';

const InternalWrapper: React.FC<any> = (props) => {
  const {
    publicURL,
    isDomainConnected,
    siteName,
    language,
    isHomePage,
    uri,
    mlResolutionMethod,
    routerUrlPrefix,
  } = props;

  useEffectSetAtom(pageIdAtom, props.pageId);
  useEffectSetAtom(businessManagerDashboardUrlAtom, props.siteDashboardURL);
  useEffectSetAtom(isEditorXAtom, props.isEditorX);
  useEffectSetAtom(routerUrlPrefixAtom, routerUrlPrefix);

  const fetchIsUrlHierarchyFlattened = useCallback(async () => {
    const httpClient = getHttpClient(props.metasiteInstance);
    const {
      data: { seoUserConfig },
    } = await httpClient.request(getSeoUserConfig({}));

    return seoUserConfig.shouldFlattenUrlHierarchy;
  }, [props.metasiteInstance]);

  useEffectFetchAndSetAtom(
    isUrlHierarchyFlattenedAtom,
    fetchIsUrlHierarchyFlattened,
  );

  const isUrlHierarchyFlattened = useAtomValue(isUrlHierarchyFlattenedAtom);
  const resolvedPublicURL = useGetPublicURL({
    publicURL,
    isDomainConnected,
    siteName,
    language,
    isHomePage,
    uri,
    mlResolutionMethod,
  });
  const { siteStructureGraph } = usePageHierarchyContext();
  return (
    <App
      {...{
        ...props,
        isUrlHierarchyFlattened,
        siteStructureGraph,
        routerUrlPrefix,
      }}
      publicURL={resolvedPublicURL}
    />
  );
};

export const AppWrapper: React.FC<any> = (props) => (
  <Provider>
    <PageHierarchyContextProvider
      staticSiteStructure={props.staticSiteStructure}
      setParentPageId={props.setParentPageId}
      isEditorX={props.isEditorX}
    >
      <InternalWrapper {...props} />
    </PageHierarchyContextProvider>
  </Provider>
);
