var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { getKeys, hasMissingSchemaVariables } from '../../adapters/utils';
import { getMainKeysData } from './get-main-keys-data';
import { getDynamicKeysData } from './get-dynamic-keys-data';
import { getStructuredData } from './get-structured-data';
import { SD_STATUS } from '../../consts';
import { SCHEMA_TYPES } from './schema-presets/schema-types';
import IMAGES_SCHEMA from './schema-presets/wix-data-item-images';
import VIDEOS_SCHEMA from './schema-presets/wix-data-item-videos';
import { resolveDynamicPattern } from './resolve-dynamic-pattern';
export var IDs = (_a = {
        MAIN_COLLECTION_PRIMARY_FIELD: 'wix-data-page-item.main-collection-primary-field',
        PAGE_URL: 'wix-data-page-item.page-url',
        MAIN_COLLECTION_NAME: 'wix-data-page-item.main-collection-name',
        TOTAL_ITEMS_IN_MAIN_COLLECTION: 'wix-data-page-item.total-count-in-main-collection',
        COLLECTIONS: 'wix-data-page-item.collections',
        IMAGE_TITLE: 'wix-data-page-item.image-title',
        MAIN_IMAGE: 'wix-data-page-item.image-url',
        IMAGE_HEIGHT: 'wix-data-page-item.image-height',
        IMAGE_WIDTH: 'wix-data-page-item.image-width',
        VIDEO_NAME: 'wix-data-page-item.video-name',
        VIDEO_DESCRIPTION: 'wix-data-page-item.video-description',
        VIDEO_UPLOAD_DATE: 'wix-data-page-item.upload-date',
        VIDEO_THUMBNAIL_URL: 'wix-data-page-item.video-thumbnail-url'
    },
    _a[IMAGES_SCHEMA.type] = 'wix-data-page-item.structured-data.images',
    _a[VIDEOS_SCHEMA.type] = 'wix-data-page-item.structured-data.videos',
    _a.DISABLE_IMAGES_SCHEMA = 'wix-data-page-item.images-schema.disable',
    _a.DISABLE_VIDEOS_SCHEMA = 'wix-data-page-item.videos-search-schema.disable',
    _a);
var SchemaTypes = {
    WIX_DATA_PAGE_ITEM_IMAGE: IMAGES_SCHEMA.type,
    WIX_DATA_PAGE_ITEM_VIDEO: VIDEOS_SCHEMA.type,
};
var userVisible = [
    IDs.MAIN_COLLECTION_PRIMARY_FIELD,
    IDs.MAIN_COLLECTION_NAME,
    IDs.MAIN_IMAGE,
    IDs.TOTAL_ITEMS_IN_MAIN_COLLECTION,
    IDs.PAGE_URL,
];
var defaultRequiredSchemaVariables = [];
var getSdStatus = function (item, itemData, schemaType) {
    var requiredSchemaVariables;
    var schemaString;
    switch (schemaType) {
        case SCHEMA_TYPES.WIX_DATA_PAGE_ITEM_IMAGES:
            requiredSchemaVariables = IMAGES_SCHEMA.requiredSchemaVariables;
            schemaString = itemData[IDs.SD_JSON_IMAGES];
            break;
        case SCHEMA_TYPES.WIX_DATA_PAGE_ITEM_VIDEOS:
            requiredSchemaVariables = VIDEOS_SCHEMA.requiredSchemaVariables;
            schemaString = itemData[IDs.SD_JSON_VIDEOS];
            break;
        default:
            requiredSchemaVariables = defaultRequiredSchemaVariables;
            break;
    }
    if (!schemaString) {
        return SD_STATUS.EMPTY;
    }
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (itemData) {
        var _a, _b;
        if (!itemData) {
            return {};
        }
        var collections = itemData.collections, title = itemData.title, pageUrl = itemData.pageUrl;
        var data = __assign(__assign(__assign((_a = {}, _a[IDs.MAIN_COLLECTION_PRIMARY_FIELD] = title, _a[IDs.PAGE_URL] = pageUrl, _a[IDs.COLLECTIONS] = collections, _a), getMainKeysData(IDs, collections)), getDynamicKeysData(collections)), getStructuredData(collections));
        return __assign(__assign({}, data), (_b = {}, _b[IDs.DISABLE_IMAGES_SCHEMA] = getSdStatus(itemData, data, SCHEMA_TYPES.WIX_DATA_PAGE_ITEM_IMAGES), _b[IDs.DISABLE_VIDEOS_SCHEMA] = getSdStatus(itemData, data, SCHEMA_TYPES.WIX_DATA_PAGE_ITEM_VIDEOS), _b));
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (itemData) { return itemData; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
    getDynamicPatternsSchema: function (staticPatternBlob, dynamicData) {
        var dynamicKeys = getStructuredData(dynamicData === null || dynamicData === void 0 ? void 0 : dynamicData.collections, {
            isSdPattern: true,
        });
        return resolveDynamicPattern(staticPatternBlob, dynamicKeys);
    },
};
