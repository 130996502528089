import { RESOLUTION_METHOD, WIX_DOMAINLESS_IDS } from '../consts/consts';
import { trimTrailingSlash } from '../utils/trim-trailing-slash';
/**
 * Sanitizes the given URL by leaving only supported query params.
 *
 * @param {String} url original url
 * @param {String} lang value to add to url
 * @param {String} mlResolutionMethod method for resolving multilingual url (sub-directory / sub-domain / query-param)
 */
export function convertUrlMultilingual(_a) {
    var urlString = _a.urlString, lang = _a.lang, mlResolutionMethod = _a.mlResolutionMethod;
    if (!lang || !mlResolutionMethod) {
        return typeof urlString === 'string' ? urlString : '';
    }
    try {
        var url = new URL(urlString);
        switch (mlResolutionMethod) {
            case RESOLUTION_METHOD.SUBDIRECTORY:
                return addLangPrefixToUrl(url, lang);
            case RESOLUTION_METHOD.SUBDOMAIN:
                return addLangSubdomain(url, lang);
            case RESOLUTION_METHOD.QUERY_PARAM:
                return addLangQueryParam(url, lang);
            default:
                return url;
        }
    }
    catch (_b) {
        return typeof urlString === 'string' ? urlString : '';
    }
}
function addLangPrefixToUrl(url, lang) {
    var hasDomain = WIX_DOMAINLESS_IDS.every(function (domainId) { return !url.hostname.includes(domainId); });
    var sitename = hasDomain ? '' : '/' + url.pathname.split('/')[1];
    var pathname = hasDomain
        ? url.pathname
        : url.pathname.replace(sitename, '');
    var newPath = trimTrailingSlash("".concat(sitename ? sitename + '/' : '').concat(lang).concat(pathname));
    var newUrl = new URL(newPath, url.origin);
    newUrl.search = url.search;
    return newUrl.toString();
}
function addLangQueryParam(url, lang) {
    url.searchParams.set('lang', lang);
    return url.href;
}
function addLangSubdomain(url, lang) {
    var protocol = url.protocol, host = url.host, pathname = url.pathname, search = url.search, href = url.href;
    return host.includes('www')
        ? href.replace('www', lang)
        : "".concat(protocol, "//").concat(lang, ".").concat(host).concat(pathname).concat(search);
}
