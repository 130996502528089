"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateValueByIdentifier = void 0;
var TagSchemas_1 = require("../../types/TagSchemas");
var is_empty_1 = require("../../utils/is-empty");
var build_tag_by_value_1 = require("../utils/build-tag-by-value");
var update_by_schema_1 = require("./update-by-schema");
var updateValueByIdentifier = function (tags, identifier, value, meta, enableValidation, _a) {
    if (tags === void 0) { tags = []; }
    if (enableValidation === void 0) { enableValidation = true; }
    var _b = _a === void 0 ? {} : _a, _c = _b.allowEmptyForValidation, allowEmptyForValidation = _c === void 0 ? false : _c;
    var tag = (0, build_tag_by_value_1.buildTagByValueAndIdentifier)(value, identifier, {
        enableValidation: enableValidation,
    });
    if ((0, is_empty_1.isEmpty)(tag)) {
        return tags;
    }
    if (meta) {
        tag.meta = meta;
    }
    var tagSchema = TagSchemas_1.TAG_SCHEMAS[identifier];
    return (0, update_by_schema_1.updateValueBySchema)({
        tags: tags,
        tagSchema: tagSchema,
        value: value,
        meta: meta,
        allowEmptyForValidation: allowEmptyForValidation,
    });
};
exports.updateValueByIdentifier = updateValueByIdentifier;
