"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var get_menu_images_structured_data_1 = require("./get-menu-images-structured-data");
var schema_types_1 = require("./schema-presets/schema-types");
var consts_1 = require("../../consts");
var get_menu_items_1 = require("./calculated/get-menu-items");
var get_menu_multilinigual_tags_1 = require("./calculated/get-menu-multilinigual-tags");
exports.IDs = {
    TITLE: 'menu.name',
    DESCRIPTION: 'menu.description',
    MENU_URL: 'menu.url',
    IS_MAIN_MENU: 'menu.isMainMenu',
    IMAGES_SD: schema_types_1.SCHEMA_TYPES.RESTAURANTS_MENU_PAGE_IMAGES,
    SD_JSON_IMAGES: 'menu.structured-data.images',
    DISABLE_IMAGES_SCHEMA: 'menu.images-schema.disable',
};
var SchemaTypes = __assign({}, schema_types_1.SCHEMA_TYPES);
var userVisible = [
    exports.IDs.TITLE,
    exports.IDs.DESCRIPTION,
    exports.IDs.MENU_URL,
    exports.IDs.SD_JSON_IMAGES,
];
var userVisibleSdOnly = [exports.IDs.IMAGES_SD];
var getSdStatus = function (item, data) {
    var menuItems = (0, get_menu_items_1.getMenuItems)(item);
    if (!menuItems || menuItems.length === 0 || !data[exports.IDs.SD_JSON_IMAGES]) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var menuItems = (0, get_menu_items_1.getMenuItems)(item);
        var data = (_a = {},
            _a[exports.IDs.TITLE] = (0, get_1.get)(item, 'menu.name'),
            _a[exports.IDs.DESCRIPTION] = (0, get_1.get)(item, 'menu.description'),
            _a[exports.IDs.MENU_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.RESTAURANTS_MENU_PAGE, item, (0, get_1.getRawValue)(item, 'menu.url')),
            _a[exports.IDs.IS_MAIN_MENU] = (0, get_1.getRawValue)(item, 'menu.isMainMenu'),
            _a[exports.IDs.SD_JSON_IMAGES] = (0, get_menu_images_structured_data_1.getMenuImagesStructuredData)(menuItems),
            _a);
        var imagesSdStatus = getSdStatus(item, data, SchemaTypes.COLLECTION_IMAGES) !==
            consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.DISABLE_IMAGES_SCHEMA] = imagesSdStatus, _b));
    },
    getDynamicPatternsSchema: function (item) {
        var multilingualTags = (0, get_menu_multilinigual_tags_1.getMenuMultilingualTags)(item);
        return { tags: __spreadArray([], multilingualTags, true) };
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { return (0, get_1.get)(item, 'menu.id'); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        return (__assign(__assign({}, itemData), { menu: __assign(__assign({}, itemData.menu), { url: url }) }));
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        return __assign(__assign({}, itemData), { menu: __assign(__assign({}, itemData.menu), { id: slugValue }) });
    },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisibleSdOnly); },
};
