"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var get_1 = require("../../utils/get");
var utils_1 = require("../../adapters/utils");
var update_by_identifier_1 = require("../../tags/values/update-by-identifier");
var Identifiers_1 = require("../../types/Identifiers");
var getCurrentLanguage_1 = require("../blog-post/calculated/getCurrentLanguage");
var getPageUrl_1 = require("./calculated/getPageUrl");
var get_multilingual_tags_1 = require("../../renderer/get-multilingual-tags");
var consts_1 = require("../../consts");
var utils_2 = require("../utils");
var consts_2 = require("../consts");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var IDs = {
    TITLE: 'blog-category.name',
    DESCRIPTION: 'blog-category.description',
    MAIN_IMAGE: 'blog-category.image',
    IMAGE_HEIGHT: 'blog-category.image.height',
    IMAGE_WIDTH: 'blog-category.image.width',
    PAGE_URL: 'blog-category.url',
    PREV_PAGE_URL: 'blog-category.prevUrl',
    NEXT_PAGE_URL: 'blog-category.nextUrl',
    CURRENT_PAGE: 'blog-category.currentPage',
    TOTAL_PAGE_COUNT: 'blog-category.totalPageCount',
    BLOG_FEED_ROUTE: 'blog-category.blogFeedRoute',
};
var userVisible = [
    IDs.TITLE,
    IDs.TOTAL_PAGE_COUNT,
    IDs.CURRENT_PAGE,
    IDs.DESCRIPTION,
    IDs.PAGE_URL,
    IDs.MAIN_IMAGE,
];
var requiredSchemaVariables = [];
var SchemaTypes = {
    BLOG_CATEGORY_COLLECTION: 'blog-category-collection-preset',
};
var getImageData = function (item) {
    var _a;
    var hasImage = (0, get_1.getRawValue)(item, 'category.mainImage.hasImage');
    return _a = {},
        _a[IDs.MAIN_IMAGE] = hasImage ? (0, get_1.get)(item, 'category.mainImage.url') : '',
        _a[IDs.IMAGE_HEIGHT] = hasImage ? (0, get_1.get)(item, 'category.mainImage.height') : '',
        _a[IDs.IMAGE_WIDTH] = hasImage ? (0, get_1.get)(item, 'category.mainImage.width') : '',
        _a;
};
exports.default = {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        return __assign(__assign((_a = {}, _a[IDs.TITLE] = (0, get_1.get)(item, 'category.label'), _a[IDs.DESCRIPTION] = (0, get_1.get)(item, 'category.description'), _a), getImageData(item)), (_b = {}, _b[IDs.PAGE_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.BLOG_CATEGORY, item, (0, getPageUrl_1.getPageUrl)(item)), _b[IDs.PREV_PAGE_URL] = (0, get_1.get)(item, 'category.prevUrl'), _b[IDs.NEXT_PAGE_URL] = (0, get_1.get)(item, 'category.nextUrl'), _b[IDs.CURRENT_PAGE] = (0, get_1.get)(item, 'category.pagination.currentPage'), _b[IDs.TOTAL_PAGE_COUNT] = (0, get_1.get)(item, 'category.pagination.totalPages'), _b[IDs.BLOG_FEED_ROUTE] = (0, get_1.get)(item, 'category.blogFeedRoute') ||
            (0, utils_2.getRssFullURL)({
                siteUrl: (0, get_1.get)(item, 'context.siteUrl'),
                rssRoute: consts_2.RSS_ROUTES[consts_2.RSS_ROUTE_TYPES.BLOG],
            }), _b[utils_1.siteIDs.PREV] = (0, get_1.get)(item, 'category.prevUrl'), _b[utils_1.siteIDs.NEXT] = (0, get_1.get)(item, 'category.nextUrl'), _b[utils_1.siteIDs.CURRENT_PAGE_NUMBER] = (0, get_1.get)(item, 'category.pagination.currentPage'), _b[utils_1.siteIDs.TOTAL_PAGE_COUNT] = (0, get_1.get)(item, 'category.pagination.totalPages'), _b));
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getLegacySeoBlob: function (item) {
        var tags = (0, update_by_identifier_1.updateValueByIdentifier)([], Identifiers_1.IDENTIFIERS.TITLE, (0, get_1.get)(item, 'category.title'));
        tags = (0, update_by_identifier_1.updateValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.DESCRIPTION, (0, get_1.get)(item, 'category.description'));
        return { tags: tags };
    },
    getDynamicPatternsSchema: function (item) {
        var translatedPages = (0, get_1.getRawValue)(item, 'category.translatedPages') || [];
        var hasTranslations = Boolean(translatedPages.length);
        var currentLanguageCode = (0, getCurrentLanguage_1.getCurrentLanguage)(item);
        var currentSeoLang = (0, getCurrentLanguage_1.getCurrentSeoLang)(item);
        var currentUrl = (0, getPageUrl_1.getPageUrl)(item);
        if (!item || !hasTranslations) {
            return { tags: [] };
        }
        var siteUrl = (0, get_1.get)(item, 'context.siteUrl');
        var tags = (0, get_multilingual_tags_1.getMultilingualTags)(__spreadArray(__spreadArray([
            {
                url: currentUrl,
                langCode: currentLanguageCode,
                seoLang: currentSeoLang,
            }
        ], translatedPages, true), [
            { url: siteUrl, langCode: 'x-default' },
        ], false));
        return { tags: tags };
    },
    getSlug: function (item) { return (0, get_1.get)(item, 'category.slug'); },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        item.category.path = url;
        item.category.canonicalUrl = url;
        return item;
    },
    getFullUrl: function (item) { return (0, get_1.get)(item, 'category.canonicalUrl'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(IDs, userVisible); },
    getSdKeys: function () { return []; },
    hasMultilingual: function (item) {
        return Array.isArray((0, get_1.getRawValue)(item, 'category.translatedPages'));
    },
};
