export const getCustomValidators = (props) => {
  return {
    isUriForbidden: props.isUriForbidden,
    isUriDuplicate: props.isUriDuplicate,
    noEmoji: props.validators.noEmoji,
    noHTMLTags: props.validators.noHTMLTags,
    notEmptyString: props.validators.notEmptyString,
    invalidUrlCharacters: props.validators.invalidUrlCharacters,
    startsOrEndsWithDash: props.validators.startsOrEndsWithDash,
  };
};
