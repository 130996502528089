import React from 'react';
import { ROBOTS_DIRECTIVES } from '@wix/advanced-seo-utils';
import {
  useApplyOrRemoveDirective,
  useIsDirectiveExists,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

export const dataHook = 'no-archive-directive-checkbox';

export const NoArchiveDirective = translate()(({ t }) => {
  const isNoArchiveDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.NOARCHIVE,
  );
  const onNoArchiveCheckboxClicked = useApplyOrRemoveDirective(
    ROBOTS_DIRECTIVES.NOARCHIVE,
  );

  return (
    <CheckboxWithInfoIcon
      dataHook={dataHook}
      isChecked={isNoArchiveDirectiveExists}
      onChange={onNoArchiveCheckboxClicked}
      label={t('adv-seo.common.robots-tag.directives.no-archive.title')}
      infoText={t('adv-seo.common.robots-tag.directives.no-archive.tooltip')}
      infoTextBiName="adv-seo.common.robots-tag.directives.no-archive.tooltip"
      helpCenterId={t(
        'adv-seo.common.robots-tag.directives.no-archive.tooltip.editor-help-center-id',
      )}
    />
  );
});

NoArchiveDirective.propTypes = {};
