"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildOgImagePreview = exports.buildOgImagePreviewUrl = void 0;
var is_absolute_url_1 = require("./is-absolute-url");
var extract_image_name_from_wix_media_url_1 = require("./extract-image-name-from-wix-media-url");
var buildImageName = function (_a) {
    var name = _a.name, extension = _a.extension;
    return typeof name === 'string'
        ? encodeURIComponent(__spreadArray([
            name
        ], (extension && !name.includes('.') ? ['.', extension] : []), true).join(''))
        : '';
};
var extractExtension = function (url) {
    return typeof url === 'string' ? url.split('.').pop().toLocaleLowerCase() : '';
};
var getQualityParam = function (_a) {
    var width = _a.width, height = _a.height, extension = _a.extension;
    if (['jpg', 'jpeg', 'jpe'].includes(extension)) {
        var HIGH_QUALITY_RESOLUTION = 1400 * 1400;
        var MEDIUM_QUALITY_RESOLUTION = 600 * 600;
        var dimension = width * height;
        var quality = dimension > HIGH_QUALITY_RESOLUTION
            ? 90
            : dimension > MEDIUM_QUALITY_RESOLUTION
                ? 85
                : 80;
        return ",q_".concat(quality);
    }
    return '';
};
/**
 * Following logic from ImageClientSDK https://github.com/wix-private/santa-core/tree/master/image-client-api
 */
function buildOgImagePreviewUrl(_a) {
    var url = _a.url, width = _a.width, height = _a.height, _b = _a.method, method = _b === void 0 ? 'fit' : _b, name = _a.name;
    var urlOrName = url;
    if (!urlOrName) {
        return url || '';
    }
    if ((0, is_absolute_url_1.isAbsoluteUrl)(urlOrName)) {
        urlOrName = (0, extract_image_name_from_wix_media_url_1.extractImageNameFromWixMediaUrl)(urlOrName);
    }
    if (!urlOrName) {
        return url;
    }
    var extension = extractExtension(urlOrName);
    var imageName = buildImageName({ name: name, extension: extension }) || urlOrName;
    var quality = getQualityParam({ width: width, height: height, extension: extension });
    var suffix = ['jpg', 'jpeg', 'jpe', 'png'].includes(extension) &&
        method &&
        width &&
        height
        ? "/v1/".concat(method, "/w_").concat(width, ",h_").concat(height, ",al_c").concat(quality, "/").concat(imageName)
        : '';
    return "https://static.wixstatic.com/media/".concat(urlOrName).concat(suffix);
}
exports.buildOgImagePreviewUrl = buildOgImagePreviewUrl;
function buildOgImagePreview(url, width, height, method) {
    return buildOgImagePreviewUrl({ url: url, width: width, height: height, method: method });
}
exports.buildOgImagePreview = buildOgImagePreview;
