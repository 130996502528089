"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldRenderPanelSchema = void 0;
var experimentEnabled_1 = require("../../../adapters/experimentEnabled");
var adapter_static_page_v2_1 = require("../../../item-types/static-page-v2/adapter-static-page-v2");
var shouldRenderPanelSchema = function (tag, context) {
    if (context === void 0) { context = {}; }
    var _a = tag.meta || {}, enabledByExperiment = _a.enabledByExperiment, _b = _a.removeSchemaIfEmpty, removeSchemaIfEmpty = _b === void 0 ? [] : _b, _c = _a.removeSchemaIfAppsNotInstalled, removeSchemaIfAppsNotInstalled = _c === void 0 ? [] : _c, _d = _a.removeSchemaIfAppsInstalled, removeSchemaIfAppsInstalled = _d === void 0 ? [] : _d;
    var isEnabledBySpec = !enabledByExperiment || (0, experimentEnabled_1.experimentEnabled)(enabledByExperiment, context);
    var isMissingData = removeSchemaIfEmpty.some(function (variable) { return !context[variable]; });
    var findInstalledApp = function (appId) {
        var _a;
        return (_a = context[adapter_static_page_v2_1.IDs.INSTALLED_APPS]) === null || _a === void 0 ? void 0 : _a.find(function (installedApp) {
            return installedApp.tpaPageId === appId ||
                installedApp.appDefinitionId === appId ||
                installedApp.managingAppDefId === appId;
        });
    };
    var isMissingApp = removeSchemaIfAppsNotInstalled.some(function (appId) { return !findInstalledApp(appId); });
    var isDisabledByApp = removeSchemaIfAppsInstalled.some(function (appId) {
        return findInstalledApp(appId);
    });
    return isEnabledBySpec && !isMissingData && !isMissingApp && !isDisabledByApp;
};
exports.shouldRenderPanelSchema = shouldRenderPanelSchema;
