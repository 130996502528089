import { safelyParseJsonData } from '../../renderer/utils/safely-parse-json-data';
export var filterCustomTags = function (advancedSeoData, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.keepDefaultTags, keepDefaultTags = _c === void 0 ? false : _c;
    var filteredTags;
    if (typeof advancedSeoData === 'string') {
        filteredTags = safelyParseJsonData(advancedSeoData);
    }
    filteredTags = ((filteredTags || advancedSeoData).tags || []).filter(function (tag) {
        return keepDefaultTags ? !tag.custom || tag.isDefault : !tag.custom;
    });
    return { tags: filteredTags };
};
