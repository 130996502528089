var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { siteIDs } from '../../adapters/utils';
import { buildTitle, buildStandardMeta, buildLink, buildOgMeta, buildOgMetaRefs, buildTwitterMetaRefs, } from '../../types/builders';
import { IDs } from './adapter-bookings-form';
var SITE_NAME = siteIDs.SITE_NAME;
var FORM_NAME = IDs.FORM_NAME, FORM_DESCRIPTION = IDs.FORM_DESCRIPTION, PAGE_URL = IDs.PAGE_URL;
export var BOOKINGS_FORM = {
    tags: __spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(FORM_NAME, "}} - {{").concat(SITE_NAME, "}}")),
        buildStandardMeta('description', "{{".concat(FORM_DESCRIPTION, "}}")),
        buildStandardMeta('robots', 'noindex'),
        buildLink({ rel: 'canonical', href: "{{".concat(PAGE_URL, "}}") })
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:url', "{{".concat(PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website')
    ], false), buildTwitterMetaRefs(), true),
};
