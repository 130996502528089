"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeUrl = void 0;
var consts_1 = require("../consts/consts");
/**
 * Sanitizes the given URL by leaving only supported query params.
 *
 * @param {String} url to sanitize
 */
function sanitizeUrl(url, allowList) {
    if (allowList === void 0) { allowList = consts_1.QUERY_DEFAULT_ALLOWLIST; }
    if (typeof url === 'string') {
        var queryIndex = url.indexOf(consts_1.QUERY_SEPARATOR);
        if (queryIndex !== -1) {
            var _a = url.split(consts_1.QUERY_SEPARATOR), urlWithoutQuery = _a[0], query = _a[1];
            var sanitizedQuery = query
                .split(consts_1.QUERY_PARAM_SEPARATOR)
                .filter(function (pair) { return allowList.some(function (key) { return pair.startsWith(key); }); })
                .join(consts_1.QUERY_PARAM_SEPARATOR);
            if (sanitizedQuery) {
                return "".concat(urlWithoutQuery).concat(consts_1.QUERY_SEPARATOR).concat(sanitizedQuery);
            }
            return urlWithoutQuery;
        }
        return url;
    }
    return undefined;
}
exports.sanitizeUrl = sanitizeUrl;
