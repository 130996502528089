var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEmpty } from './is-empty';
var fetchSiteStructureData = function (httpClient) { return function (siteStructureRequest) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, filter, pageVersion, sorting, data, itemTypeIdentifier, usePagination, isLastPage, cursor, request, _b, pageData, results, _c, nextCursor, error_1;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (isEmpty(siteStructureRequest)) {
                    return [2 /*return*/];
                }
                _a = siteStructureRequest.filter, filter = _a === void 0 ? {} : _a, pageVersion = siteStructureRequest.pageVersion, sorting = siteStructureRequest.sorting;
                data = [];
                _e.label = 1;
            case 1:
                _e.trys.push([1, 6, , 7]);
                itemTypeIdentifier = filter.itemTypeIdentifier;
                usePagination = itemTypeIdentifier.includes('sub_pages');
                isLastPage = false, cursor = null;
                _e.label = 2;
            case 2:
                request = __assign({ filter: filter, pageVersion: pageVersion, sorting: sorting }, (usePagination && {
                    paging: __assign({}, (cursor !== null && { cursor: cursor })),
                }));
                return [4 /*yield*/, httpClient.post('/_api/site-structure-server/v1/site-structure', request)];
            case 3:
                _b = (_e.sent()).data, pageData = _b.pageData, results = _b.results;
                data = data.concat(results);
                _c = (pageData || {}).cursor, nextCursor = _c === void 0 ? null : _c;
                cursor = nextCursor;
                isLastPage = cursor === null || cursor === '';
                _e.label = 4;
            case 4:
                if (!isLastPage) return [3 /*break*/, 2];
                _e.label = 5;
            case 5: return [2 /*return*/, data.length ? (_d = {}, _d[itemTypeIdentifier] = data, _d) : {}];
            case 6:
                error_1 = _e.sent();
                // todo: handle error
                return [2 /*return*/, {}];
            case 7: return [2 /*return*/];
        }
    });
}); }; };
var getFilters = function (_a) {
    var filters = _a.filters, httpClient = _a.httpClient;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, pageTypes;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!!filters) return [3 /*break*/, 2];
                    return [4 /*yield*/, httpClient.post('/_api/site-structure-server/v1/site-structure-metadata')];
                case 1:
                    _b = (_c.sent()).data.pageTypes, pageTypes = _b === void 0 ? [] : _b;
                    return [2 /*return*/, pageTypes];
                case 2: return [2 /*return*/, filters];
            }
        });
    });
};
export var getSiteStructure = function (_a) {
    var httpClient = _a.httpClient, pageVersion = _a.pageVersion, sortOrder = _a.sortOrder, filters = _a.filters;
    return __awaiter(void 0, void 0, void 0, function () {
        var calculatedFilters, requests, fetchRequest, responses, results;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getFilters({ filters: filters, httpClient: httpClient })];
                case 1:
                    calculatedFilters = _b.sent();
                    requests = calculatedFilters.map(function (filter) { return ({
                        filter: filter,
                        pageVersion: pageVersion,
                        sorting: {
                            fieldName: 'itemId',
                            order: sortOrder,
                        },
                    }); });
                    fetchRequest = fetchSiteStructureData(httpClient);
                    return [4 /*yield*/, Promise.all(requests.map(fetchRequest))];
                case 2:
                    responses = (_b.sent()).filter(Boolean);
                    results = Object.assign.apply(Object, __spreadArray([{}], responses, false));
                    return [2 /*return*/, results];
            }
        });
    });
};
