"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBasicVideosObject = exports.getImageWithThumbnailObject = exports.getBasicImageObject = exports.getImageObject = void 0;
var build_og_image_preview_1 = require("../utils/build-og-image-preview");
var getImageObject = function (containerKey, images, previewUrl, includeContext) {
    var _a;
    if (images === void 0) { images = []; }
    if (previewUrl === void 0) { previewUrl = true; }
    if (includeContext === void 0) { includeContext = false; }
    var imageObjects = images
        .filter(function (_a) {
        var url = _a.url;
        return !!url;
    })
        .map(function (_a) {
        var url = _a.url, width = _a.width, height = _a.height, alt = _a.alt, name = _a.name;
        return (__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (includeContext ? { '@context': 'https://schema.org/' } : {})), { '@type': 'ImageObject' }), (height ? { height: height } : {})), (width ? { width: width } : {})), (alt ? { alt: alt } : {})), (name ? { name: name } : {})), { url: previewUrl ? (0, build_og_image_preview_1.buildOgImagePreviewUrl)({ url: url, width: width, height: height }) : url }));
    });
    return imageObjects.length
        ? (_a = {},
            _a[containerKey] = imageObjects.length === 1
                ? __assign({}, imageObjects[0]) : imageObjects,
            _a) : {};
};
exports.getImageObject = getImageObject;
var getBasicVideosObject = function (videos, _a) {
    if (videos === void 0) { videos = []; }
    var _b = _a === void 0 ? {} : _a, includeContext = _b.includeContext;
    var videoObjects = videos.map(function (_a) {
        var thumbnailUrl = _a.thumbnailUrl, name = _a.name, description = _a.description, uploadDate = _a.uploadDate, duration = _a.duration, contentUrl = _a.contentUrl, embedUrl = _a.embedUrl, expires = _a.expires;
        return (__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (includeContext ? { '@context': 'https://schema.org/' } : {})), { '@type': 'VideoObject' }), (thumbnailUrl && {
            thumbnailUrl: (0, build_og_image_preview_1.buildOgImagePreviewUrl)({ url: thumbnailUrl }),
        })), (name && { name: name })), (description && { description: description })), (uploadDate && { uploadDate: uploadDate })), (duration && { duration: duration })), (contentUrl && { contentUrl: contentUrl })), (embedUrl && { embedUrl: embedUrl })), (expires && { expires: expires })));
    });
    return videoObjects.length
        ? {
            videos: videoObjects,
        }
        : {};
};
exports.getBasicVideosObject = getBasicVideosObject;
var getBasicImageObject = function (_a, representativeOfPage) {
    var height = _a.height, width = _a.width, alt = _a.alt, url = _a.url;
    if (representativeOfPage === void 0) { representativeOfPage = true; }
    return (__assign(__assign(__assign(__assign(__assign({ '@type': 'ImageObject' }, (height ? { height: String(height) } : {})), (width ? { width: String(width) } : {})), (alt ? { name: alt } : {})), (url ? { contentUrl: url } : {})), (representativeOfPage ? { representativeOfPage: true } : {})));
};
exports.getBasicImageObject = getBasicImageObject;
var getImageWithThumbnailObject = function (image, thumbnail) {
    if (image === void 0) { image = {}; }
    if (thumbnail === void 0) { thumbnail = {}; }
    return (__assign(__assign({}, getBasicImageObject(image)), (thumbnail ? { thumbnail: getBasicImageObject(thumbnail, false) } : {})));
};
exports.getImageWithThumbnailObject = getImageWithThumbnailObject;
