export const PROMOTE_SEO_PANEL_VIEW = 'promote-seo-panel.panel-view';
export const PROMOTE_SEO_FIELD_UPDATE = 'promote-seo-panel.field-update';
export const PROMOTE_SEO_CTA_CLICK = 'promote-seo-panel.cta-click';
export const PROMOTE_SEO_TOOLTIP_VIEW = 'promote-seo-panel.tooltip-view';
export const PROMOTE_SEO_TOOLTIP_CTA_CLICK =
  'promote-seo-panel.tooltip-cta-click';
export const PROMOTE_SEO_ERROR_VIEW = 'promote-seo-panel.error-view';
export const PROMOTE_SEO_ACTION_CLICK = 'promote-seo-panel.action-click';
export const PROMOTE_SEO_CLOSE_TAG_SETTINGS =
  'promote-seo-panel.close-tag-settings';
export const PROMOTE_SEO_MODAL_VIEW = 'promote-seo-panel.modal-view';
export const PROMOTE_SEO_MODAL_CTA_CLICK = 'promote-seo-panel.modal-cta-click';
export const PROMOTE_SEO_PANEL_API_FAILED_CALL =
  'promote-seo-panel.api-failed-call';
export const PROMOTE_SEO_PANEL_SECTION_EXPAND_COLLAPSE =
  'promote-seo-panel.section-expand-collapse';
export const PROMOTE_SEO_PANEL_COMPONENT_VIEW =
  'promote-seo-panel.component-view';
export const PROMOTE_SEO_PANEL_NOTIFICATION_VIEW =
  'promote-seo-panel.notification-view';
export const PROMOTE_SEO_PANEL_NOTIFICATION_CTA_CLICK =
  'promote-seo-panel.notification-cta-click';
export const SEO_PANEL_AUTO_REDIRECT_REQUEST =
  'promote-seo-panel.auto-redirect-request';
export const SEO_PANEL_AUTO_REDIRECT_SUCCESS =
  'promote-seo-panel.auto-redirect-success';
export const SEO_PANEL_AUTO_REDIRECT_FAILED =
  'promote-seo-panel.auto-redirect-failed';
export const SEO_PANEL_COLLAPSE = 'promote-seo-panel.collapse';
export const SEO_PANEL_SAVE_SUCCESS = 'promote-seo-panel.save-success';
export const SEO_PANEL_HIERARCHY_DROP_DOWN_EXPAND_COLLAPSE =
  'promote-seo-panel.hierarchy-drop-down-expand-collapse';
