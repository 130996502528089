import { identify } from '../../renderer/utils/identify';
import { parse } from '../../renderer/utils/parse';
import { ERRORS } from '../../types/Errors';
import { validateBrokenTags, validateIsInWhiteList, validateAttributeIsInBlacklist, } from '../../types/Validators';
import { TWITTER_IDENTIFIERS } from '../../types/Identifiers';
export function validateCustomTags(htmlString, excludedIdentifiers) {
    if (excludedIdentifiers === void 0) { excludedIdentifiers = Object.keys(TWITTER_IDENTIFIERS); }
    if (htmlString === '') {
        return { isValid: true };
    }
    var errors = [];
    var tags = parse(htmlString);
    if (!tags.length || !validateBrokenTags(htmlString).isValid) {
        errors.push(new ERRORS.INVALID_TAG_STRUCTURE());
    }
    var invalidAttributes = [];
    tags.forEach(function (tag) {
        var validationResult = validateIsInWhiteList(tag);
        if (!validationResult.isValid) {
            errors.push(validationResult.error);
        }
        var attrValidationResult = validateAttributeIsInBlacklist(tag);
        if (!attrValidationResult.isValid) {
            invalidAttributes = invalidAttributes.concat(attrValidationResult.error.duplicates);
        }
    });
    if (invalidAttributes.length) {
        errors.push(new ERRORS.TAG_CONTAINS_BLACKLISTED_ATTRIBUTE(invalidAttributes));
    }
    var identifiedTags = getIdentifiedTags(tags, excludedIdentifiers);
    if (identifiedTags.length) {
        var types = identifiedTags
            .map(function (tag) {
            return tag.props &&
                (tag.props.property || tag.props.content || tag.props.href);
        })
            .filter(function (prop) { return prop; });
        if (types.length) {
            errors.push(new ERRORS.TAG_ALREADY_EXISTS(types));
        }
    }
    if (errors.length) {
        return { isValid: false, errors: errors };
    }
    return { isValid: true };
}
function getIdentifiedTags(tags, ignoreList) {
    if (ignoreList === void 0) { ignoreList = []; }
    return tags.filter(function (tag) { return identify(tag, ignoreList); });
}
