import { createBiEventLogger } from '../../core/bi';
import { newToLegacy } from '../tabs/tab-names';

export const initBiLogger = (props) => {
  const {
    msid,
    tabName,
    language: { current: language, original: mainLanguage } = {},
    pageId,
    isHomePage,
    numPages,
  } = props;

  const getCommonBiData = () => ({
    tabName: newToLegacy[tabName] || tabName,
    language,
    mainLanguage,
    pageId,
    isHomePage,
    numPages,
  });

  return createBiEventLogger({ msid }, getCommonBiData);
};
