import { parseKeywordsContent } from '../utils';
import { PROMOTE_SEO_FIELD_UPDATE } from '../../core/bi/action-types';

/*
  1. Converts legacy keywords data into custom tags
  2. Saves custom tags to page data
  3. Deletes legacy seo keywords from page data
*/

export const handleLegacySeoKeywords = async (
  props,
  { key, customTagsFromState, logger, validateAndSetState, updateType },
) => {
  const { setPageKeywordsSEO, pageKeywordsSEO } = props;
  const currentCustomTags = customTagsFromState || '';
  const keywordsToAdd = parseKeywordsContent(pageKeywordsSEO);
  const customTagsWithKeywords = `${currentCustomTags}<meta name="keywords" content="${keywordsToAdd}"/>`;
  await validateAndSetState(key)(customTagsWithKeywords);
  setPageKeywordsSEO('');
  logger(PROMOTE_SEO_FIELD_UPDATE, {
    fieldName: 'custom-keywords',
    updatedValue: customTagsWithKeywords,
    updateType,
  });

  return customTagsWithKeywords;
};
