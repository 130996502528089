import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import get from 'lodash/get';
import { getResolvedUrlByRouterUrlPrefixOrHierarchy } from '../../editor/app/url-hooks';
import { getHasUrllHierarchyAndHasParent } from '../../editor/hooks/page-hierarchy';

const getBusinessData = (siteProperties, CONTEXT_PROPS) => {
  const { businessName, description, logo, locale, address, phone } =
    siteProperties;
  return pickBy(
    {
      [CONTEXT_PROPS.BUSINESS_NAME]: businessName,
      [CONTEXT_PROPS.BUSINESS_DESCRIPTION]: description,
      [CONTEXT_PROPS.BUSINESS_LOGO]: logo,
      [CONTEXT_PROPS.BUSINESS_LOCALE]: locale,
      [CONTEXT_PROPS.BUSINESS_LOCATION_COUNTRY]: get(address, 'country'),
      [CONTEXT_PROPS.BUSINESS_LOCATION_FORMATTED]: get(
        address,
        'googleFormattedAddress',
      ),
      [CONTEXT_PROPS.BUSINESS_LOCATION_STATE]: get(address, 'state'),
      [CONTEXT_PROPS.BUSINESS_LOCATION_CITY]: get(address, 'city'),
      [CONTEXT_PROPS.BUSINESS_LOCATION_STREET]: get(address, 'street'),
      [CONTEXT_PROPS.BUSINESS_LOCATION_STREET_NUMBER]: get(
        address,
        'streetNumber',
      ),
      [CONTEXT_PROPS.BUSINESS_LOCATION_DESCRIPTION]: get(address, 'hint.text'),
      [CONTEXT_PROPS.BUSINESS_POSTAL_CODE]: get(address, 'zip'),
      [CONTEXT_PROPS.BUSINESS_PHONE]: phone,
    },
    identity,
  );
};

export const initContext = (props, CONTEXT_PROPS) => {
  const {
    experiments,
    description,
    homePageTitle,
    isHomePage,
    isIndexEnabled,
    isIndexEnabledFromSite,
    language = {},
    ogImage: { uri = '', width = '', height = '' } = {},
    pageData: { title = '', pageTitleSEO = '' } = {},
    publicURL,
    appPages,
    siteData: { properties },
    siteDisplayName,
    siteName,
    routerUrlPrefix,
    isDomainConnected,
    siteStructureGraph,
    pageId,
    isEditorX,
    uri: pageUri,
    isUrlHierarchyFlattened,
  } = props;

  const isOriginalLanguage = language.current === language.original;

  const enablePublicUrlFix = experiments?.enabled(
    'specs.seo.EnablePublicUrlFix',
  );

  let resolvedUrl;
  if (enablePublicUrlFix) {
    const hasUrlHierarchyAndHasParent = getHasUrllHierarchyAndHasParent(
      isEditorX,
      siteStructureGraph,
      pageId,
      isUrlHierarchyFlattened,
    );
    resolvedUrl = getResolvedUrlByRouterUrlPrefixOrHierarchy({
      publicURL,
      siteName,
      routerUrlPrefix,
      isDomainConnected,
      hasUrlHierarchyAndHasParent,
      uri: pageUri,
      siteStructureGraph,
      pageId,
    });
  }

  return {
    ...(properties && getBusinessData(properties, CONTEXT_PROPS)),
    [CONTEXT_PROPS.INDEX_SITE]: isIndexEnabledFromSite,
    [CONTEXT_PROPS.INDEX_PAGE]: isIndexEnabled,
    [CONTEXT_PROPS.SITE_NAME]: siteDisplayName || siteName,
    [CONTEXT_PROPS.HOME_PAGE_TITLE]: homePageTitle,
    [CONTEXT_PROPS.PAGE_NAME]: title,
    [CONTEXT_PROPS.TITLE]: pageTitleSEO,
    [CONTEXT_PROPS.DESCRIPTION]: description,
    [CONTEXT_PROPS.DEFAULT_URL]: enablePublicUrlFix ? resolvedUrl : publicURL,
    [CONTEXT_PROPS.OG_IMAGE]: uri,
    [CONTEXT_PROPS.OG_IMAGE_WIDTH]: width,
    [CONTEXT_PROPS.OG_IMAGE_HEIGHT]: height,
    [CONTEXT_PROPS.IS_HOME_PAGE]: isHomePage,
    [CONTEXT_PROPS.CURR_LANG_CODE]: language.current,
    [CONTEXT_PROPS.CURR_LANG_IS_ORIGINAL]: isOriginalLanguage,
    ...(Boolean(publicURL) && {
      [CONTEXT_PROPS.SITE_URL]: getProtocolAndHostName(publicURL),
    }),
    [CONTEXT_PROPS.INSTALLED_APPS]: appPages,
  };
};

function getProtocolAndHostName(url) {
  return new RegExp(
    /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/gim,
  ).exec(url)[0];
}
