// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fen7D2{margin-left:12px;padding-top:12px}.qivDVL{margin:42px 0}.ex2VXk{margin:14px 0;display:inline-flex;align-items:center}.ex2VXk .Zvj4uF{margin-left:6px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `fen7D2`,
	"empty-state-container": `qivDVL`,
	"emptyStateContainer": `qivDVL`,
	"add-schema-button-container": `ex2VXk`,
	"addSchemaButtonContainer": `ex2VXk`,
	"add-schema-button-label": `Zvj4uF`,
	"addSchemaButtonLabel": `Zvj4uF`
};
export default ___CSS_LOADER_EXPORT___;
