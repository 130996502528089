import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import { translate } from 'react-i18next';
import { Composites, TextLabel, RichText } from '@wix/wix-base-ui';

import { Multilingual } from '../../../core/components/icons/multilingual';
import * as BI_TYPES from '../../../core/bi/action-types';

class AdvancedPanelMultilingual extends PureComponent {
  static propTypes = {
    t: func.isRequired,
    openHelpCenter: func.isRequired,
    logBiEvent: func.isRequired,
  };

  render() {
    const { t, logBiEvent, openHelpCenter } = this.props;
    return (
      <div
        className="seo-advanced-client-multilingual"
        data-aid="seo-multilingual"
      >
        <div className="seo-advanced-client-multilingual-icon">
          <Multilingual />
        </div>
        <div>
          <Composites.RichTextLabeled>
            <TextLabel
              type="T02"
              value={t('adv-seo.adv-panel.multilingual.title')}
              enableEllipsis={false}
              shouldTranslate={false}
            />
            <RichText shouldTranslate={false}>
              <span className="seo-advanced-client-multilingual-description">
                {t('adv-seo.adv-panel.multilingual.description')}
              </span>
              <span>&nbsp;</span>
              <a
                data-aid="seo-multilingual-open-help"
                onClick={() => {
                  openHelpCenter('6be62a0c-6230-406b-9924-f59b0964970b');
                  logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
                    linkName: 'adv-seo.adv-panel.multilingual',
                    linkType: 'learn_more',
                    linkSource: 'panel',
                  });
                }}
              >
                {t('adv-seo.common.learn-more')}
              </a>
            </RichText>
          </Composites.RichTextLabeled>
        </div>
      </div>
    );
  }
}

export default translate()(AdvancedPanelMultilingual);
