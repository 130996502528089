import { getValueByIdentifier } from '../tags/values/get-by-identifier';
import { updateValueByIdentifier } from '../tags/values/update-by-identifier';
import { IDENTIFIERS } from '../types/Identifiers';
export var resolveEmptyTitlePrefix = function (tags) {
    if (!tags || tags === {}) {
        return tags;
    }
    var resolvedTags = resolveTitleTag(tags, IDENTIFIERS.TITLE);
    resolvedTags = resolveTitleTag(resolvedTags, IDENTIFIERS.OG_TITLE);
    resolvedTags = resolveTitleTag(resolvedTags, IDENTIFIERS.TWITTER_TITLE);
    return resolvedTags;
};
var resolveTitleTag = function (tags, identifier) {
    var title = getValueByIdentifier(tags, identifier);
    var regex = /^[\s|-]+/;
    var prefix = title === null || title === void 0 ? void 0 : title.match(regex);
    var newTitle = title === null || title === void 0 ? void 0 : title.replace(regex, '');
    if (!prefix || !title || !newTitle) {
        return tags;
    }
    return updateValueByIdentifier(tags, identifier, newTitle);
};
