import { isAccessible } from './is-accessible';
import { get } from '../../../../utils/get';
export var getName = function (item) {
    if (item === void 0) { item = {}; }
    if (!(item === null || item === void 0 ? void 0 : item.program)) {
        return '';
    }
    var name = get(item, 'program.settings.description.title');
    return isAccessible(item) ? name : 'Access Forbidden';
};
