import {
  TWITTER_IDENTIFIERS,
  TWITTER_CARD_SUPPORTED_TYPES,
} from '@wix/advanced-seo-utils';
import {
  getFlagFromLocalStorage,
  setFlagInLocalStorage,
} from './local-storage-utils';

export const TWITTER_IMAGE_SMALL_WIDTH = 90;

export const TWITTER_IMAGE_SMALL_WIDTH_PX = `${TWITTER_IMAGE_SMALL_WIDTH}px`;

export const TWITTER_IMAGE_SMALL_HEIGHT = 90;

export const TWITTER_IMAGE_SMALL_HEIGHT_PX = `${TWITTER_IMAGE_SMALL_HEIGHT}px`;

export const MIN_TWITTER_IMAGE_SMALL_WIDTH = 144;

export const MIN_TWITTER_IMAGE_SMALL_HEIGHT = 144;

export const isTwitterCardValueSupportedOrMissing = (twitterCardValue) =>
  twitterCardValue === undefined ||
  twitterCardValue === null ||
  twitterCardValue === '' ||
  Object.values(TWITTER_CARD_SUPPORTED_TYPES).includes(twitterCardValue);

export const getTwitterIdentifiers = () => Object.keys(TWITTER_IDENTIFIERS);

export const getExcludedIdentifiers = (twitterCardValue) =>
  isTwitterCardValueSupportedOrMissing(twitterCardValue)
    ? []
    : getTwitterIdentifiers();

const wasTwitterTagsMigratedKey = 'seoClient-wasTwitterTagsMigrated';

export const wasTwitterTagsMigrated = () =>
  getFlagFromLocalStorage(wasTwitterTagsMigratedKey);

export const setWasTwitterTagsMigrated = (wasTwitterTagsMigratedValue) => {
  setFlagInLocalStorage(wasTwitterTagsMigratedKey, wasTwitterTagsMigratedValue);
};

const shouldHideTwitterTagsMigratedHelperKey =
  'seoClient-shouldHideTwitterTagsMigratedHelper';

export const shouldHideTwitterTagsMigratedHelper = () =>
  getFlagFromLocalStorage(shouldHideTwitterTagsMigratedHelperKey);

export const setShouldHideTwitterTagsMigratedHelper = (
  shouldHideTwitterTagsMigratedHelperValue,
) => {
  setFlagInLocalStorage(
    shouldHideTwitterTagsMigratedHelperKey,
    shouldHideTwitterTagsMigratedHelperValue,
  );
};
