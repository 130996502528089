import { TAG_TYPES } from '../types/TagTypes';
import PROPS from '../types/Props';
// http://wixplorer.wixpress.com/out-of-iframe/guides/Reference/APIs
export function convertPlatformMetaTagsModel(payload) {
    var result = {
        tags: [],
    };
    if (payload && Array.isArray(payload)) {
        result.tags = payload.reduce(function (acc, curr) {
            var _a;
            if (curr && (curr.property || curr.name) && curr.content) {
                return acc.concat({
                    type: TAG_TYPES.META,
                    props: (_a = {},
                        _a[curr.property && curr.property.startsWith('og:')
                            ? PROPS.PROPERTY
                            : PROPS.NAME] = curr.property || curr.name,
                        _a.content = curr.content,
                        _a),
                });
            }
            return acc;
        }, result.tags);
    }
    return result;
}
