import React, { PureComponent } from 'react';
import { func, string, bool } from 'prop-types';
import { translate } from 'react-i18next';
import { TextLabel, RichText } from '@wix/wix-base-ui';
import _ from 'lodash';

class TooltipContent extends PureComponent {
  static propTypes = {
    title: string,
    text: string,
    linkText: string,
    onLinkClick: func,
    shouldTranslate: bool,
    t: func.isRequired,
  };

  static defaultProps = {
    shouldTranslate: true,
  };

  render() {
    const { shouldTranslate, linkText, onLinkClick, t } = this.props;
    return (
      <RichText>
        <div>
          {this._renderTitle()}
          {this._renderBody()}
          {linkText && (
            <span style={{ whiteSpace: 'nowrap' }}>
              <a onMouseDown={onLinkClick}>
                {shouldTranslate ? t(linkText) : linkText}
              </a>
            </span>
          )}
        </div>
      </RichText>
    );
  }

  _renderTitle = () => {
    const { title, shouldTranslate, t } = this.props;
    if (!title) {
      return null;
    }
    return (
      <div style={{ paddingBottom: '5px' }}>
        <TextLabel
          type="T03"
          value={shouldTranslate ? t(title) : title}
          shouldTranslate={false}
        />
      </div>
    );
  };

  _renderBody = () => {
    const { text, shouldTranslate, t } = this.props;
    const body = shouldTranslate ? t(text) : text;
    const parts = body.split('\n');
    return parts.length === 1 ? (
      <span>{body} </span>
    ) : (
      _.map(parts, (part, index) => {
        return (
          <span key={index}>
            {part === '\\n' ? '' : part}
            <br />
          </span>
        );
      })
    );
  };
}

export default translate()(TooltipContent);
