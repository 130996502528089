"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
var trim_trailing_slash_1 = require("../../utils/trim-trailing-slash");
var get_1 = require("../../utils/get");
var calculate_duration_1 = require("./calculate-duration");
var ContextProps_1 = require("../../types/ContextProps");
exports.IDs = {
    SD_JSON_PROGRAMS: 'programs-component.structured-data.programs',
    SD_DISABLE_SCHEMA: 'programs-component.disable-schema',
};
var SchemaTypes = {
    PROGRAMS_COMPONENT_COURSE_LIST: 'programs-component-course-list-preset',
};
var userVisible = [];
var requiredSchemaVariables = function () {
    return [exports.IDs.SD_JSON_PROGRAMS];
};
var getSdStatus = function (item, itemData) {
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables(), item, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getSdFields = function (challenge) {
    var name = (0, get_1.get)(challenge, 'challenge.settings.description.title');
    var description = (0, get_1.get)(challenge, 'challenge.settings.description.details').substring(0, 60);
    var price = (0, get_1.getRawValue)(challenge, 'challenge.settings.pricing.singlePayment.price.amount');
    var currency = (0, get_1.get)(challenge, 'challenge.settings.pricing.singlePayment.price.currency');
    var providerName = (0, get_1.get)(challenge, 'challenge.owners.0.fullName');
    var sameAs = (0, get_1.get)(challenge, 'challenge.settings.seo.url.base');
    return {
        name: name,
        description: description,
        price: price,
        currency: currency,
        providerName: providerName,
        sameAs: sameAs,
    };
};
var getDuration = function (challenge) {
    var timelineType = (0, get_1.getRawValue)(challenge, 'challenge.settings.timelineType');
    if (timelineType) {
        var keys = Object.keys(timelineType);
        if (keys.length > 0) {
            var type = keys[0];
            return timelineType[type].duration;
        }
    }
};
var getStructuredData = function (item) {
    var _a;
    var siteCurrency = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.CURRENCY));
    var programs = (_a = item.challenges) === null || _a === void 0 ? void 0 : _a.map(function (challenge, index) {
        var position = index;
        if (item.settings) {
            var setting = item.settings.find(function (setting) {
                return setting.id === challenge.challenge.id;
            });
            if (!setting || setting.isHidden) {
                return;
            }
            position = setting.position;
        }
        var duration = getDuration(challenge);
        if (!duration) {
            return;
        }
        var base = (0, trim_trailing_slash_1.trimTrailingSlash)((0, get_1.get)(challenge, 'challenge.settings.seo.url.base'));
        var path = (0, get_1.get)(challenge, 'challenge.settings.seo.url.path');
        var _a = getSdFields(challenge), name = _a.name, description = _a.description, price = _a.price, currency = _a.currency, providerName = _a.providerName, sameAs = _a.sameAs;
        var courseWorkload = (0, calculate_duration_1.calculateDuration)(duration.unit, duration.value);
        return {
            position: position,
            url: base.concat(path),
            name: name,
            description: description,
            courseWorkload: courseWorkload,
            price: price,
            currency: currency,
            providerName: providerName,
            sameAs: sameAs,
        };
    }).filter(function (program) {
        return program;
    }).sort(function (program) {
        return program.position;
    });
    if (programs && programs.length > 1) {
        return {
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: programs.map(function (program, index) { return ({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@type': 'Course',
                    url: (0, get_1.get)(item, "settings.".concat(index, ".url")),
                    name: program.name,
                    description: program.description,
                    hasCourseInstance: [
                        {
                            '@type': 'CourseInstance',
                            courseMode: 'Online',
                            courseWorkload: program.courseWorkload,
                        },
                    ],
                    offers: {
                        '@type': 'Offer',
                        price: program.price || '0',
                        priceCurrency: program.currency || siteCurrency,
                        category: 'course',
                    },
                    provider: {
                        '@type': 'Organization',
                        name: program.providerName,
                        sameAs: program.sameAs,
                    },
                },
            }); }),
        };
    }
    return '';
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = (_a = {},
            _a[exports.IDs.SD_JSON_PROGRAMS] = getStructuredData(item),
            _a);
        var sdStatus = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
