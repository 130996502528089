import React from 'react';
import { js_beautify } from 'js-beautify';
import { Code } from '@wix/wix-ui-icons-common';
import { SCRIPT_TAG_PARTS } from './consts';
import { getIsSchemaMissingBusinessInfo } from '../app-base';

export const SD_ACTION_TYPES = {
  TEST_SCHEMA: 'test in google',
  MANAGE_SCHEMAS: 'open patterns',
  HIDE_SCHEMA: 'hide schema',
  SHOW_SCHEMA: 'show schema',
  ADD_SCHEMA: 'add schema',
  EDIT_SCHEMA: 'edit schema',
  REMOVE_SCHEMA: 'remove schema',
};

export const BI_CTA_DATA = {
  tabName: 'ADVANCED',
  fieldName: 'schemasMap',
  type: 'CTA',
};

export const buildSchemaItems = ({
  schemasMap,
  schemaPresets = {},
  itemType,
  siteContext,
  experiments,
  t,
}) =>
  schemasMap?.map((schema) => {
    const schemaPreset = schemaPresets[schema.schemaType];
    const isSchemaMissingBusinessInfo = getIsSchemaMissingBusinessInfo(
      itemType,
      {
        context: siteContext,
      },
      schema.schemaType,
    );
    const isCustom = !schemaPreset;
    return {
      ...schema,
      custom: isCustom,
      shouldShowWarning: !isCustom && isSchemaMissingBusinessInfo,
      icon: schemaPreset?.iconComponent || <Code />,
      displayName:
        schema.displayName ||
        (isCustom && t('adv-seo.common.schema-items.title.default-custom')),
    };
  });

const { OPENNING, CLOSING, PARTIAL } = SCRIPT_TAG_PARTS;

export const addEnclosingScriptTag = (schema) =>
  schema && !schema.includes(OPENNING)
    ? `${OPENNING}
${js_beautify(schema)}
${CLOSING}`
    : schema;

export const removeEnclosingScriptTag = (schema) =>
  schema && removeScriptTag(schema).trim();

function removeScriptTag(structuredDataString) {
  const sdWithoutSpaces = structuredDataString.replace(/\s/g, '');

  if (sdWithoutSpaces.startsWith(PARTIAL)) {
    const contentStartIndex = structuredDataString.indexOf('>');
    const contentEndIndex = structuredDataString.lastIndexOf('<');
    structuredDataString = structuredDataString.substring(
      contentStartIndex + 1,
      contentEndIndex,
    );
  }
  return structuredDataString;
}

export const removeWhiteSpace = (schema) => {
  try {
    return JSON.stringify(JSON.parse(schema));
  } catch (e) {
    return schema.replace(/(\r\n|\n|\r\\ \\)/g, ' ').replace(/\\"/g, '"');
  }
};
