import htmlParser from '../../utils/html-parser';
/**
 * Parses a given HTML fragment and returns them as tags.
 *
 * @param {string} html fragment to parse.
 */
export function parse(html) {
    if (typeof html !== 'string') {
        return [];
    }
    var tags = [];
    try {
        var currentIndex_1 = 0;
        htmlParser(html, {
            start: function (type, attr) {
                var tag = { type: type };
                if (attr.length) {
                    tag.props = extractProps(attr);
                }
                tags.push(tag);
                currentIndex_1 = tags.length - 1;
            },
            chars: function (children) {
                if (tags[currentIndex_1] && tags[currentIndex_1].type && children) {
                    tags[currentIndex_1].children = children;
                }
            },
        });
    }
    catch (e) { }
    return tags;
}
function extractProps(attrs) {
    return (attrs || []).reduce(function (acc, curr) {
        acc[curr.name] = curr.value;
        return acc;
    }, {});
}
