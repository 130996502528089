import React from 'react';
import { ROBOTS_DIRECTIVES } from '@wix/advanced-seo-utils';
import {
  useApplyOrRemoveDirective,
  useIsDirectiveExists,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

export const dataHook = 'no-image-index-directive-checkbox';

export const NoImageIndexDirective = translate()(({ t }) => {
  const isNoimageIndexDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.NOIMAGEINDEX,
  );
  const onNoimageindexCheckboxClicked = useApplyOrRemoveDirective(
    ROBOTS_DIRECTIVES.NOIMAGEINDEX,
  );

  return (
    <CheckboxWithInfoIcon
      dataHook={dataHook}
      isChecked={isNoimageIndexDirectiveExists}
      onChange={onNoimageindexCheckboxClicked}
      label={t('adv-seo.common.robots-tag.directives.no-image-index.title')}
      infoText={t(
        'adv-seo.common.robots-tag.directives.no-image-index.tooltip',
      )}
      infoTextBiName="adv-seo.common.robots-tag.directives.no-image-index.tooltip"
      helpCenterId={t(
        'adv-seo.common.robots-tag.directives.no-image-index.tooltip.editor-help-center-id',
      )}
    />
  );
});

NoImageIndexDirective.propTypes = {};
