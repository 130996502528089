"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var consts_1 = require("../../consts");
var utils_2 = require("../utils");
var consts_2 = require("../consts");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
exports.IDs = {
    ID: 'blog-hashtags.id',
    TITLE: 'blog-hashtags.title',
    LABEL: 'blog-hashtags.label',
    URL: 'blog-hashtags.url',
    PREV_URL: 'blog-hashtags.pagination.prevUrl',
    NEXT_URL: 'blog-hashtags.pagination.nextUrl',
    PAGE_COUNT: 'blog-hashtags.pagination.totalPages',
    CURRENT_PAGE: 'blog-hashtags.pagination.currentPage',
    TOTAL_ITEMS: 'blog-hashtags.items.numberOfItems',
    BLOG_FEED_ROUTE: 'blog-hashtags.blogFeedRoute',
};
var SchemaTypes = {
    BLOG_HASHTAGS_ITEM_LIST: 'blog-hashtags-item-list-preset',
};
var userVisible = [
    exports.IDs.TITLE,
    exports.IDs.LABEL,
    exports.IDs.URL,
    exports.IDs.PAGE_COUNT,
    exports.IDs.TOTAL_ITEMS,
    exports.IDs.CURRENT_PAGE,
];
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[exports.IDs.ID] = (0, get_1.get)(item, 'hashtag.title'),
            _a[exports.IDs.TITLE] = (0, get_1.get)(item, 'hashtag.title'),
            _a[exports.IDs.LABEL] = (0, get_1.get)(item, 'hashtag.label'),
            _a[exports.IDs.URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.BLOG_HASHTAGS, item, (0, get_1.get)(item, 'hashtag.url')),
            _a[exports.IDs.PREV_URL] = (0, get_1.get)(item, 'hashtag.pagination.prevUrl'),
            _a[exports.IDs.NEXT_URL] = (0, get_1.get)(item, 'hashtag.pagination.nextUrl'),
            _a[exports.IDs.PAGE_COUNT] = (0, get_1.get)(item, 'hashtag.pagination.totalPages'),
            _a[exports.IDs.CURRENT_PAGE] = (0, get_1.get)(item, 'hashtag.pagination.currentPage'),
            _a[exports.IDs.TOTAL_ITEMS] = (0, get_1.get)(item, 'hashtag.items.numberOfItems'),
            _a[exports.IDs.BLOG_FEED_ROUTE] = (0, get_1.get)(item, 'hashtag.blogFeedRoute') ||
                (0, utils_2.getRssFullURL)({
                    siteUrl: (0, get_1.get)(item, 'context.siteUrl'),
                    rssRoute: consts_2.RSS_ROUTES[consts_2.RSS_ROUTE_TYPES.BLOG],
                }),
            _a[utils_1.siteIDs.CURRENT_PAGE_NUMBER] = (0, get_1.get)(item, 'hashtag.pagination.currentPage'),
            _a[utils_1.siteIDs.TOTAL_PAGE_COUNT] = (0, get_1.get)(item, 'hashtag.pagination.totalPages'),
            _a[utils_1.siteIDs.PREV] = (0, get_1.get)(item, 'hashtag.pagination.prevUrl'),
            _a[utils_1.siteIDs.NEXT] = (0, get_1.get)(item, 'hashtag.pagination.nextUrl'),
            _a;
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
