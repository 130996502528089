"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveItemTypeMultilingualUrl = void 0;
var ContextProps_1 = require("../types/ContextProps");
var MultilingualTypes_1 = require("../types/MultilingualTypes");
var get_1 = require("./get");
var getPrimaryLanguage = function (languages) {
    if (languages === void 0) { languages = []; }
    return languages === null || languages === void 0 ? void 0 : languages.filter(function (lang) { return lang === null || lang === void 0 ? void 0 : lang.isPrimaryLanguage; });
};
var resolveItemTypeMultilingualUrl = function (itemType, item, url) {
    var _a;
    if (url === void 0) { url = ''; }
    var context = (0, get_1.getRawValue)(item, 'context');
    if ((0, get_1.getRawValue)(context, ContextProps_1.CONTEXT_PROPS.CURR_LANG_IS_ORIGINAL)) {
        return url;
    }
    if (!(0, MultilingualTypes_1.isMultilingualType)(itemType)) {
        var primaryLanguage = getPrimaryLanguage((0, get_1.getRawValue)(context, ContextProps_1.CONTEXT_PROPS.SITE_LANGUAGES));
        return ((_a = primaryLanguage[0]) === null || _a === void 0 ? void 0 : _a.url) || url;
    }
    // Check if current url include param
    if ((0, get_1.getRawValue)(context, ContextProps_1.CONTEXT_PROPS.CURR_LANG_RESOLUTION_METHOD) ===
        'QueryParam') {
        try {
            var langCode = (0, get_1.getRawValue)(context, ContextProps_1.CONTEXT_PROPS.CURR_LANG_CODE);
            var urlObject = new URL(url);
            urlObject.searchParams.set('lang', langCode);
            return decodeURI(urlObject.toString());
        }
        catch (_b) {
            return url;
        }
    }
    else {
        return url;
    }
};
exports.resolveItemTypeMultilingualUrl = resolveItemTypeMultilingualUrl;
