import adapter from '../adapter-gift-card';
import { siteIDs } from '../../../adapters/utils';
var IDs = adapter.IDs, SchemaTypes = adapter.SchemaTypes;
export default {
    type: SchemaTypes.GIFT_CARD,
    schema: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: "{{".concat(IDs.NAME, "}}"),
        description: "{{".concat(IDs.DESCRIPTION, "}}"),
        brand: {
            '@type': 'Thing',
            name: "{{".concat(siteIDs.BUSINESS_NAME, "}}"),
        },
        offers: "{{".concat(IDs.SD_JSON_OFFERS, "}}"),
        image: "{{".concat(IDs.SD_JSON_IMAGE, "}}"),
    },
};
