import { buildStructuredData } from '../../types/builders';
import adapter from './adapter-reviews-component';
import reviewsComponent from './schema-presets/reviews-component';
var IDs = adapter.IDs;
export var REVIEWS_COMPONENT = {
    tags: [
        buildStructuredData({
            schema: reviewsComponent.schema,
            schemaType: reviewsComponent.schemaType,
            parentSchemaType: reviewsComponent.parentSchemaType,
            disabled: [
                "{{".concat(IDs.DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ],
};
