"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
var trim_trailing_slash_1 = require("../../utils/trim-trailing-slash");
var get_1 = require("../../utils/get");
exports.IDs = {
    SD_JSON_SERVICES: 'services-component.structured-data.services',
    SD_DISABLE_SCHEMA: 'services-component.disable-schema',
};
var SchemaTypes = {
    SERVICES_COMPONENT_ITEM_LIST: 'services-component-item-list-preset',
};
var userVisible = [];
var requiredSchemaVariables = function () {
    return [exports.IDs.SD_JSON_SERVICES];
};
var getSdStatus = function (item, itemData) {
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables(), item, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getStructuredData = function (item) {
    var _a;
    var services = (_a = item.services) === null || _a === void 0 ? void 0 : _a.map(function (service) {
        var base = (0, trim_trailing_slash_1.trimTrailingSlash)((0, get_1.get)(service, 'urls.servicePage.url'));
        var path = (0, get_1.get)(service, 'urls.servicePage.relativePath');
        return {
            url: base.concat(path),
        };
    });
    if (services && services.length > 1) {
        return {
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: services.map(function (service, index) { return ({
                '@type': 'ListItem',
                url: service.url,
                position: index + 1,
            }); }),
        };
    }
    return '';
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = (_a = {},
            _a[exports.IDs.SD_JSON_SERVICES] = getStructuredData(item),
            _a);
        var sdStatus = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
