var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getKeys, hasMissingSchemaVariables } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { trimTrailingSlash } from '../../utils/trim-trailing-slash';
import { get } from '../../utils/get';
export var IDs = {
    SD_JSON_SERVICES: 'services-component.structured-data.services',
    SD_DISABLE_SCHEMA: 'services-component.disable-schema',
};
var SchemaTypes = {
    SERVICES_COMPONENT_ITEM_LIST: 'services-component-item-list-preset',
};
var userVisible = [];
var requiredSchemaVariables = function () {
    return [IDs.SD_JSON_SERVICES];
};
var getSdStatus = function (item, itemData) {
    if (hasMissingSchemaVariables(requiredSchemaVariables(), item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
var getStructuredData = function (item) {
    var _a;
    var services = (_a = item.services) === null || _a === void 0 ? void 0 : _a.map(function (service) {
        var base = trimTrailingSlash(get(service, 'urls.servicePage.url'));
        var path = get(service, 'urls.servicePage.relativePath');
        return {
            url: base.concat(path),
        };
    });
    if (services && services.length > 1) {
        return {
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: services.map(function (service, index) { return ({
                '@type': 'ListItem',
                url: service.url,
                position: index + 1,
            }); }),
        };
    }
    return '';
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = (_a = {},
            _a[IDs.SD_JSON_SERVICES] = getStructuredData(item),
            _a);
        var sdStatus = getSdStatus(item, data) !== SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
