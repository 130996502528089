var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get } from '../../utils/get';
import { getKeys, hasMissingSchemaVariables } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
export var IDs = {
    RATING_COUNT: 'reviews-component.rating-count',
    RATING_VALUE: 'reviews-component.rating-value',
    BEST_RATING: 'reviews-component.best-rating',
    WORST_RATING: 'reviews-component.worst-rating',
    REVIEW_COUNT: 'reviews-component.review-count',
    REVIEWS: 'reviews-component.review',
    DISABLE_SCHEMA: 'reviews-component.disable-schema',
};
var requiredSchemaVariables = [IDs.REVIEW_COUNT, IDs.RATING_VALUE];
var SchemaTypes = {
    REVIEWS_COMPONENT_ITEM_LIST: 'reviews-component-component-item-list-preset',
};
var userVisible = [];
var getSdStatus = function (item, itemData) {
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
var getStructuredReviews = function (item) {
    var _a;
    if (!((_a = item === null || item === void 0 ? void 0 : item.review) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    return item.review.map(function (review) {
        var _a, _b;
        return ({
            '@type': 'Review',
            reviewRatings: {
                '@type': 'Rating',
                ratingValue: "".concat(((_a = review.reviewRatings) === null || _a === void 0 ? void 0 : _a.ratingValue) || ''),
            },
            author: {
                '@type': 'Person',
                name: (_b = review.author) === null || _b === void 0 ? void 0 : _b.name,
            },
        });
    });
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = (_a = {},
            _a[IDs.RATING_COUNT] = get(item, 'aggregateRating.ratingCount'),
            _a[IDs.RATING_VALUE] = get(item, 'aggregateRating.ratingValue'),
            _a[IDs.BEST_RATING] = get(item, 'aggregateRating.bestRating'),
            _a[IDs.WORST_RATING] = get(item, 'aggregateRating.worstRating'),
            _a[IDs.REVIEW_COUNT] = get(item, 'aggregateRating.reviewCount'),
            _a[IDs.REVIEWS] = getStructuredReviews(item),
            _a);
        return __assign(__assign({}, data), (_b = {}, _b[IDs.DISABLE_SCHEMA] = getSdStatus(item, data) !== SD_STATUS.ENABLED, _b));
    },
    getSdStatus: getSdStatus,
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
