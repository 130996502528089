var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildOgMetaRefs, buildLink, buildStructuredData, buildTwitterMetaRefs, } from '../../types/builders';
import { siteIDs } from '../../adapters/utils';
import adapter from './adapter-bookings-service';
import serviceSchema from './schema-presets/bookings-service';
var IDs = adapter.IDs;
/**
 Here you can find more details about the implementation
 https://docs.google.com/document/d/1fLagCkL9Q_9G1jmVY6umrCqCnIDULCaD0Wt2JPPdtJc/edit#
*/
export var BOOKINGS_SERVICE = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildStandardMeta('description', "{{".concat(IDs.DESCRIPTION, "}}")),
        buildStandardMeta('robots', 'index')
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:url', "{{".concat(IDs.CALCULATED_SERVICE_URL, "}}")),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.CALCULATED_SERVICE_URL, "}}") })
    ], false), buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: serviceSchema.schema,
            schemaType: serviceSchema.type,
            removeSchemaIfEmpty: [IDs.IS_COURSE_SESSION_TYPE],
            disabled: [
                "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ], false),
};
