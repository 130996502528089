import { IDs } from './schema-ids';
import { siteIDs } from '../../../adapters/utils';
export var buildBlogPostSchema = function (_a) {
    var schemaType = _a.schemaType, type = _a.type;
    return ({
        type: schemaType,
        schema: {
            '@context': 'https://schema.org',
            '@type': type,
            author: {
                '@type': 'Person',
                name: "{{".concat(IDs.AUTHOR, "}}"),
                url: "{{".concat(IDs.AUTHOR_URL, "}}"),
            },
            dateModified: "{{".concat(IDs.LAST_MODIFIED_DATE, "}}"),
            datePublished: "{{".concat(IDs.PUBLISH_DATE, "}}"),
            headline: "{{".concat(IDs.HEADLINE, "}}"),
            mainEntityOfPage: {
                '@id': "{{".concat(IDs.POST_PAGE_URL, "}}"),
                '@type': 'itemPage',
                url: "{{".concat(IDs.POST_PAGE_URL, "}}"),
            },
            description: "{{".concat(IDs.DESCRIPTION, "}}"),
            url: "{{".concat(IDs.POST_PAGE_URL, "}}"),
            image: {
                '@type': 'ImageObject',
                url: "{{".concat(IDs.COVER_IMAGE, "}}"),
                width: "{{".concat(IDs.COVER_IMAGE_WIDTH, "}}"),
                height: "{{".concat(IDs.COVER_IMAGE_HEIGHT, "}}"),
            },
            publisher: {
                '@type': 'Organization',
                name: "{{".concat(siteIDs.SITE_NAME, "}}"),
                url: "{{".concat(siteIDs.SITE_URL, "}}"),
            },
        },
        disabled: "{{".concat(IDs.SD_DISABLE_SCHEMA, "}"),
    });
};
