"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var consts_1 = require("../../consts");
var get_data_1 = require("./get-data");
var get_data_legacy_1 = require("./get-data-legacy");
var get_url_parts_1 = require("../../utils/get-url-parts");
var trim_trailing_slash_1 = require("../../utils/trim-trailing-slash");
exports.IDs = {
    ID: 'challenge.id',
    AVAILABILITY: 'challenge.registration.availability',
    MAIN_IMAGE: 'challenge.mainImage',
    CALCULATED_ROBOTS: 'challenge.robots',
    CREATED_DATE: 'challenge.created',
    CURRENCY: 'challenge.registration.currency',
    DESCRIPTION: 'challenge.description',
    DESCRIPTION_150: 'challenge.description.150',
    IMAGE_HEIGHT: 'challenge.mainImage.height',
    IMAGE_ID: 'challenge.mainImage.id',
    IMAGE_WIDTH: 'challenge.mainImage.width',
    TITLE: 'challenge.name',
    CALCULATED_NAME: 'challenge.accessible.name',
    PRICE: 'challenge.registration.price',
    URL: 'challenge.url',
};
var userVisible = [
    exports.IDs.CALCULATED_NAME,
    exports.IDs.DESCRIPTION_150,
    exports.IDs.PRICE,
    exports.IDs.URL,
    exports.IDs.MAIN_IMAGE,
    exports.IDs.TITLE,
];
var requiredSchemaVariables = [utils_1.siteIDs.SITE_NAME, utils_1.siteIDs.BUSINESS_NAME];
var SchemaTypes = {
    CHALLENGES_PAGE: 'challenges-page-preset',
};
var getFullUrl = function (item) {
    if (!item.program) {
        return (0, get_1.get)(item, 'challenge.url');
    }
    var base = (0, trim_trailing_slash_1.trimTrailingSlash)((0, get_1.get)(item, 'program.settings.seo.url.base'));
    var path = (0, get_1.get)(item, 'program.settings.seo.url.path');
    return base.concat(path);
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        return (item === null || item === void 0 ? void 0 : item.program) ? (0, get_data_1.getData)(exports.IDs, item) : (0, get_data_legacy_1.getDataLegacy)(exports.IDs, item);
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function (item) {
        return (item === null || item === void 0 ? void 0 : item.program)
            ? (0, get_1.get)(item, 'program.settings.seo.slug')
            : (0, get_1.get)(item, 'challenge.slug');
    },
    getFullUrl: getFullUrl,
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        if (item === null || item === void 0 ? void 0 : item.program) {
            var _a = (0, get_url_parts_1.getUrlParts)(url), base = _a.base, path = _a.path;
            item.program.settings.seo.url = {
                base: base,
                path: path,
            };
            return item;
        }
        else {
            item.challenge.url = url;
            return item;
        }
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        if (!(itemData === null || itemData === void 0 ? void 0 : itemData.program)) {
            return itemData;
        }
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        if ((0, get_1.get)(updatedItemData, 'program.settings.seo.slug') !== '') {
            updatedItemData.program.settings.seo.slug = slugValue;
        }
        return updatedItemData;
    },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
