"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRestaurantImages = void 0;
var get_1 = require("../../utils/get");
var ContextProps_1 = require("../../types/ContextProps");
var getRestaurantImages = function (item) {
    var imagesArray = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.RESTAURANT_IMAGES));
    var businessLogo = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOGO));
    return (imagesArray === null || imagesArray === void 0 ? void 0 : imagesArray.length) > 0
        ? imagesArray
        : businessLogo
            ? [businessLogo]
            : undefined;
};
exports.getRestaurantImages = getRestaurantImages;
