var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildOgMetaRefs, } from '../../types/builders';
export var STATIC_PAGE = {
    tags: __spreadArray(__spreadArray([
        buildTitle('{{page.title}}'),
        buildStandardMeta('description', "{{page.description}}")
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:url', '{{page.url}}'),
        buildOgMeta('og:site_name', '{{site.name}}'),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:image', '{{page.image}}'),
        buildOgMeta('og:image:width', '{{page.imageWidth}}'),
        buildOgMeta('og:image:height', '{{page.imageHeight}}'),
        buildOgMeta('fb:admins', "{{site.facebookAdminId}}"),
        buildLink({ rel: 'canonical', href: '{{page.url}}' }),
    ], false),
};
