"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TWITTER_IDENTIFIERS = exports.GENERAL_IDENTIFIERS = exports.IDENTIFIERS = void 0;
var TWITTER_IDENTIFIERS = [
    'TWITTER_CARD',
    'TWITTER_TITLE',
    'TWITTER_DESCRIPTION',
    'TWITTER_IMAGE',
].reduce(function (acc, curr) {
    acc[curr] = curr;
    return acc;
}, {});
exports.TWITTER_IDENTIFIERS = TWITTER_IDENTIFIERS;
var IDENTIFIERS = __spreadArray([
    'TITLE',
    'DESCRIPTION',
    'OG_TITLE',
    'OG_DESCRIPTION',
    'OG_IMAGE',
    'FB_ADMINS',
    'ROBOTS',
    'CANONICAL',
    'STRUCTURED_DATA',
    'OG_IMAGE_WIDTH',
    'OG_IMAGE_HEIGHT'
], Object.keys(TWITTER_IDENTIFIERS), true).reduce(function (acc, curr) {
    acc[curr] = curr;
    return acc;
}, {});
exports.IDENTIFIERS = IDENTIFIERS;
var GENERAL_IDENTIFIERS = {
    LINK: 'LINK',
    OG_TAG: 'OG_TAG',
    SATANDARD_META: 'SATANDARD_META',
};
exports.GENERAL_IDENTIFIERS = GENERAL_IDENTIFIERS;
