"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var get_1 = require("../../utils/get");
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
var utils_2 = require("../utils");
var consts_2 = require("../consts");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var ItemTypes_1 = require("../../types/ItemTypes");
exports.IDs = {
    MONTH_NUMBER: 'blog-archive.month-number',
    MONTH_NAME: 'blog-archive.month-name',
    YEAR: 'blog-archive.year',
    URL: 'blog-archive.url',
    POST_COUNT: 'blog-archive.post-count',
    CURRENT_PAGE: 'blog-archive.current-page',
    PREV_URL: 'blog-archive.prev-url',
    NEXT_URL: 'blog-archive.next-url',
    TOTAL_PAGES: 'blog-archive.total-pages',
    BLOG_FEED_ROUTE: 'blog-archive.blogFeedRoute',
};
var userVisible = [
    exports.IDs.POST_COUNT,
    exports.IDs.URL,
    exports.IDs.MONTH_NAME,
    exports.IDs.MONTH_NUMBER,
    exports.IDs.YEAR,
];
var SchemaTypes = {
    BLOG_ARCHIVE_ITEM_LIST: 'blog-archive-item-list-preset',
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var numberOfItems = (0, get_1.get)(item, 'archive.items.numberOfItems');
        return _a = {},
            _a[exports.IDs.MONTH_NUMBER] = (0, get_1.get)(item, 'archive.monthNumber'),
            _a[exports.IDs.MONTH_NAME] = (0, get_1.get)(item, 'archive.monthName'),
            _a[exports.IDs.YEAR] = (0, get_1.get)(item, 'archive.year'),
            _a[exports.IDs.URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.BLOG_ARCHIVE, item, (0, get_1.get)(item, 'archive.url')),
            _a[exports.IDs.POST_COUNT] = numberOfItems,
            _a[exports.IDs.CURRENT_PAGE] = (0, get_1.get)(item, 'archive.pagination.currentPage'),
            _a[exports.IDs.PREV_URL] = (0, get_1.get)(item, 'archive.pagination.prevUrl'),
            _a[exports.IDs.NEXT_URL] = (0, get_1.get)(item, 'archive.pagination.nextUrl'),
            _a[exports.IDs.TOTAL_PAGES] = (0, get_1.get)(item, 'archive.pagination.totalPages'),
            _a[exports.IDs.BLOG_FEED_ROUTE] = (0, get_1.get)(item, 'archive.blogFeedRoute') ||
                (0, utils_2.getRssFullURL)({
                    siteUrl: (0, get_1.get)(item, 'context.siteUrl'),
                    rssRoute: consts_2.RSS_ROUTES[consts_2.RSS_ROUTE_TYPES.BLOG],
                }),
            _a[utils_1.siteIDs.CURRENT_PAGE_NUMBER] = (0, get_1.get)(item, 'archive.pagination.currentPage'),
            _a[utils_1.siteIDs.PREV] = (0, get_1.get)(item, 'archive.pagination.prevUrl'),
            _a[utils_1.siteIDs.NEXT] = (0, get_1.get)(item, 'archive.pagination.nextUrl'),
            _a[utils_1.siteIDs.TOTAL_PAGE_COUNT] = (0, get_1.get)(item, 'archive.pagination.totalPages'),
            _a;
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
