/**
 * Shorten a string avoiding mid-word
 * @param {string} value
 * @param {integer} maxLength
 * @param {string} separator
 */
export function shorten(value, maxLength, separator) {
    if (separator === void 0) { separator = ' '; }
    if (typeof value !== 'string') {
        return '';
    }
    if (value.length <= maxLength) {
        return value;
    }
    return value.substr(0, value.lastIndexOf(separator, maxLength));
}
