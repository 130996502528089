import React, { PureComponent } from 'react';
import { func, string, bool, array, object } from 'prop-types';
import { translate } from 'react-i18next';
import { KEYS } from '../../../core/utils/maps';
import RenderIf from '../../../core/components/render-if';
import AdvancedPanelMultilingual from './advanced-panel-multilingual';
import AdvancedPanelMediaManager from './advanced-panel-media-manager';
import AdvancedPanelJsonLD from './advanced-panel-json-ld';
import AdvancedPanelAdvancedTags from './advanced-tags/advanced-panel-advanced-tags';
import AdvancedPanelCustomTags from './custom-tags/advanced-panel-custom-tags';
import AdvancedPanelHireAnExpert from './advanced-panel-hire-an-expert';
import AdvancedPanelTwitterMigration from './advanced-panel-twitter-migration';
import {
  wasTwitterTagsMigrated,
  shouldHideTwitterTagsMigratedHelper,
} from '../../../core/utils/twitter-tags';
import { AccordionSection, Divider } from '@wix/wix-base-ui';
import * as BI_TYPES from '../../../core/bi/action-types';
import { AdvancedPanelRobotsTag } from './robots-tag/advanced-panel-robots-tag';
import { RobotsTagMigrationDone } from './robots-tag/robots-tag-migration-done';
import {
  shouldHideRobotsTagMigratedHelper,
  wasRobotsTagMigrated,
} from '../../../core/utils/robots-tag-utils';
import {
  PROMOTE_SEO_STRUCTURED_DATA_SECTION_NAME,
  PROMOTE_SEO_ROBOTS_SECTION_NAME,
  PROMOTE_SEO_ADDITIONAL_TAGS_SECTION_NAME,
  PROMOTE_SEO_SECTION_EXPAND,
  PROMOTE_SEO_SECTION_COLLAPSE,
} from '../../../core/bi/constants';

class AdvancedPanel extends PureComponent {
  static propTypes = {
    onSave: func.isRequired,
    onChange: func.isRequired,
    onAddTempTag: func,
    onRemoveTempTag: func,
    onTagDeleted: func,
    jsonLD: string.isRequired,
    jsonLDErrorMessage: string,
    schemasMap: array.isRequired,
    isSchemaMissingBusinessInfo: bool.isRequired,
    onSdEdit: func.isRequired,
    onSdDelete: func.isRequired,
    onToggleSdHide: func.isRequired,
    showMultilingual: bool.isRequired,
    t: func.isRequired,
    openHelpCenter: func.isRequired,
    getImageFromMM: func.isRequired,
    advancedTags: array.isRequired,
    customTagsArray: array.isRequired,
    logBiEvent: func.isRequired,
    customTagsErrorMessage: string,
    getDefaultPatternValue: func,
    itemType: string.isRequired,
    siteContext: object.isRequired,
    siteDashboardURL: string.isRequired,
    experiments: object.isRequired,
    isIndexEnabled: bool.isRequired,
    isIndexEnabledFromSite: bool.isRequired,
    isIndexEnabledFromUserPattern: bool.isRequired,
    robotsTagValue: string.isRequired,
    showResetToPatternButton: func.isRequired,
    setActiveTab: func.isRequired,
    msid: string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isRobotsTagAccordionSectionOpen: false,
      isMetaTagsAccordionSectionOpen: false,
    };
  }

  render() {
    const {
      jsonLD,
      jsonLDErrorMessage,
      schemasMap,
      isSchemaMissingBusinessInfo,
      onSdEdit,
      onSdDelete,
      onToggleSdHide,
      advancedTags,
      customTagsArray,
      customTagsErrorMessage,
      showMultilingual,
      openHelpCenter,
      getImageFromMM,
      logBiEvent,
      experiments,
      isCoBranded,
      getDefaultPatternValue,
      itemType,
      siteContext,
      siteDashboardURL,
      t,
      isIndexEnabled,
      isIndexEnabledFromSite,
      isIndexEnabledFromPermission,
      isIndexEnabledFromUserPattern,
      robotsTagValue,
      setActiveTab,
      msid,
    } = this.props;
    const isCoBranding =
      isCoBranded && experiments.enabled('specs.experts.ArenaEntryPoint');

    return (
      <div
        data-aid="seo-advanced-panel"
        id="advanced-panel-container"
        data-hook="advanced-panel"
      >
        <AccordionSection
          dataHook="structured-data-section-accordion"
          shouldTranslate={false}
          label={t('adv-seo.adv-panel.structured-data.title')}
          removePadding
          removeTopDivider
          infoContent={t('adv-seo.adv-panel.structured-data.tooltip')}
          infoContentLink={{
            text: t('adv-seo.common.learn-more'),
            onClick: this.structuedDataAccordionOnInfoClicked,
          }}
          onToggle={(isOpen) =>
            this.reportBiForSectionExpandCollapse(
              PROMOTE_SEO_STRUCTURED_DATA_SECTION_NAME,
              isOpen,
            )
          }
        >
          <AdvancedPanelJsonLD
            itemType={itemType}
            value={jsonLD}
            error={jsonLDErrorMessage}
            schemasMap={schemasMap}
            isSchemaMissingBusinessInfo={isSchemaMissingBusinessInfo}
            onSdEdit={onSdEdit}
            onSdDelete={onSdDelete}
            onToggleSdHide={onToggleSdHide}
            onChange={this.onChangeJsonLD}
            onSave={this.onSaveJsonLD}
            openHelpCenter={openHelpCenter}
            siteContext={siteContext}
            logBiEvent={logBiEvent}
            experiments={experiments}
            siteDashboardURL={siteDashboardURL}
          />
        </AccordionSection>
        <AccordionSection
          dataHook="robots-tag-section-accordion"
          shouldTranslate={false}
          label={t('adv-seo.common.robots-tag.title')}
          removeTopDivider
          onToggle={this.onToggleRobotsTagAccordionSection}
          isOpen={this.state.isRobotsTagAccordionSectionOpen}
        >
          <AdvancedPanelRobotsTag
            robotsTagValue={robotsTagValue}
            onChange={this.props.onChange}
            onSave={this.props.onSave}
            openHelper={openHelpCenter}
            logBiEvent={logBiEvent}
            experiments={experiments}
            isIndexEnabled={isIndexEnabled}
            isIndexEnabledFromSite={isIndexEnabledFromSite}
            isIndexEnabledFromPermission={isIndexEnabledFromPermission}
            isIndexEnabledFromUserPattern={isIndexEnabledFromUserPattern}
            expandMetaTagsAccordionSection={this.expandMetaTagsAccordionSection}
            setActiveTab={setActiveTab}
            msid={msid}
            getDefaultPatternValue={getDefaultPatternValue}
          />
        </AccordionSection>
        <AccordionSection
          dataHook="meta-tags-section-accordion"
          shouldTranslate={false}
          label={t('adv-seo.common.additional-tags.title')}
          removePadding
          removeTopDivider
          infoContent={t('adv-seo.adv-panel.custom-tags.tooltip')}
          infoContentLink={{
            text: t('adv-seo.common.learn-more'),
            onClick: this.metaTagsAccordionOnInfoClicked,
          }}
          onToggle={this.onToggleMetaTagsAccordionSection}
          isOpen={this.state.isMetaTagsAccordionSectionOpen}
        >
          <AdvancedPanelAdvancedTags
            tagsList={advancedTags}
            error={customTagsErrorMessage}
            onApply={this.onSaveAdvancedTag}
            openHelpCenter={openHelpCenter}
            logBiEvent={logBiEvent}
            getDefaultPatternValue={getDefaultPatternValue}
            itemType={itemType}
            siteDashboardURL={siteDashboardURL}
            experiments={experiments}
          />
          <AdvancedPanelCustomTags
            tagsList={customTagsArray}
            error={customTagsErrorMessage}
            onChange={this.onChangeCustomTags}
            onSave={this.onSaveCustomTags}
            onApply={this.onSaveCustomTags}
            onAddTempTag={this.onAddTempCustomTag}
            onRemoveTempTag={this.onRemoveTempCustomTag}
            onTagDeleted={this.onTagDeleted}
            openHelpCenter={openHelpCenter}
            logBiEvent={logBiEvent}
            experiments={experiments}
          />
          {wasTwitterTagsMigrated() &&
            !shouldHideTwitterTagsMigratedHelper() && (
              <>
                <Divider long={false} />
                <AdvancedPanelTwitterMigration
                  logBiEvent={logBiEvent}
                  setActiveTab={this.props.setActiveTab}
                />
              </>
            )}
          {wasRobotsTagMigrated() && !shouldHideRobotsTagMigratedHelper() && (
            <>
              <Divider long={false} />
              <RobotsTagMigrationDone
                expandRobotsTagAccordionSection={() => {
                  this.collapseMetaTagsAccordionSection();
                  this.expandRobotsTagAccordionSection();
                }}
                logBiEvent={logBiEvent}
              />
            </>
          )}
          <Divider long={false} />
          <AdvancedPanelMediaManager
            onOpenMediaManager={getImageFromMM}
            logBiEvent={logBiEvent}
          />
        </AccordionSection>
        <RenderIf truthy={showMultilingual}>
          <AdvancedPanelMultilingual
            logBiEvent={logBiEvent}
            openHelpCenter={openHelpCenter}
          />
        </RenderIf>
        {!isCoBranding && (
          <>
            {showMultilingual && <Divider long={false} />}
            <AdvancedPanelHireAnExpert logBiEvent={logBiEvent} />
          </>
        )}
      </div>
    );
  }

  onSaveJsonLD = this.props.onSave(KEYS.JSON_LD);
  onChangeJsonLD = this.props.onChange(KEYS.JSON_LD);

  onSaveCanonicalUrl = this.props.onSave(KEYS.CANONICAL);
  onChangeCanonicalUrl = this.props.onChange(KEYS.CANONICAL);

  onSaveCustomTags = this.props.onSave(KEYS.CUSTOM_TAGS_ARRAY);
  onChangeCustomTags = this.props.onChange(KEYS.CUSTOM_TAGS_ARRAY);
  onAddTempCustomTag = this.props.onAddTempTag(KEYS.CUSTOM_TAGS_ARRAY);
  onRemoveTempCustomTag = this.props.onRemoveTempTag(KEYS.CUSTOM_TAGS_ARRAY);
  onTagDeleted = this.props.onTagDeleted(KEYS.CUSTOM_TAGS_ARRAY);

  onSaveAdvancedTag = this.props.onSave(KEYS.ADVANCED_TAGS);
  onChangeAdvancedTag = this.props.onChange(KEYS.ADVANCED_TAGS);

  structuedDataAccordionOnInfoClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('80512a86-ab07-4bbe-850d-52e8ffb8a2ad');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.structured-data.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };

  metaTagsAccordionOnInfoClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('b2abe8ce-90a0-4ea1-ae8e-0ed3aeec3103');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.custom-tags.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };

  onToggleRobotsTagAccordionSection = (isOpen) => {
    this.reportBiForSectionExpandCollapse(
      PROMOTE_SEO_ROBOTS_SECTION_NAME,
      isOpen,
    );
    this.setState(({ isRobotsTagAccordionSectionOpen }) => ({
      isRobotsTagAccordionSectionOpen: !isRobotsTagAccordionSectionOpen,
    }));
  };

  expandRobotsTagAccordionSection = () => {
    this.setState({ isRobotsTagAccordionSectionOpen: true });
  };

  reportBiForSectionExpandCollapse = (sectionName, isOpen) =>
    this.props.logBiEvent(BI_TYPES.PROMOTE_SEO_PANEL_SECTION_EXPAND_COLLAPSE, {
      sectionName,
      action: isOpen
        ? PROMOTE_SEO_SECTION_EXPAND
        : PROMOTE_SEO_SECTION_COLLAPSE,
    });

  onToggleMetaTagsAccordionSection = (isOpen) => {
    this.reportBiForSectionExpandCollapse(
      PROMOTE_SEO_ADDITIONAL_TAGS_SECTION_NAME,
      isOpen,
    );

    this.setState(({ isMetaTagsAccordionSectionOpen }) => ({
      isMetaTagsAccordionSectionOpen: !isMetaTagsAccordionSectionOpen,
    }));
  };

  expandMetaTagsAccordionSection = () => {
    this.setState({ isMetaTagsAccordionSectionOpen: true });
  };

  collapseMetaTagsAccordionSection = () => {
    this.setState({ isMetaTagsAccordionSectionOpen: false });
  };
}

export default translate()(AdvancedPanel);
