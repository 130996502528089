import { updateValueBySchema } from '../values/update-by-schema';
import { TAG_SCHEMAS } from '../../types/TagSchemas';
export var updateSimplifiedSeoTag = function (advancedSeoData, simplifiedTag, value) {
    var tags = advancedSeoData.tags;
    var tagSchema = TAG_SCHEMAS[simplifiedTag.generalIdentifier](simplifiedTag.label);
    var updatedTags = updateValueBySchema({
        tags: tags,
        tagSchema: tagSchema,
        value: value,
        meta: false,
        isDisabled: simplifiedTag.isDisabled,
        isCustom: simplifiedTag.isCustom,
    });
    return updatedTags;
};
