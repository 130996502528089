import React from 'react';
import { number, string } from 'prop-types';

export function Delete({ color, width, height }) {
  return (
    <svg
      viewBox="0 0 25 25"
      fill={color}
      width={width}
      height={height}
      transform="translate(1,0)"
    >
      <path
        d="M17,17 C17,18.6568542 15.6568542,20 14,20 L9,20 C7.34314575,20 6,18.6568542 6,17 L6,7 L5,7 L5,6 L18,6 L18,7 L17,7 L17,17 Z M9,9 L10,9 L10,16 L9,16 L9,9 Z M11,9 L12,9 L12,16 L11,16 L11,9 Z M13,9 L14,9 L14,16 L13,16 L13,9 Z M7,17 C7,18.1045695 7.8954305,19 9,19 L14,19 C15.1045695,19 16,18.1045695 16,17 L16,7 L7,7 L7,17 Z M13,6 L13,5 L10,5 L10,6 L9,6 L9,5 C9,4.44771525 9.44771525,4 10,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,6 L13,6 Z"
        fillRule="evenodd"
      />
    </svg>
  );
}

Delete.propTypes = {
  width: number,
  height: number,
  color: string,
};

Delete.defaultProps = {
  width: 25,
  height: 25,
  color: 'currentColor',
};
