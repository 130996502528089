import { string, object, func, bool, shape, number, array } from 'prop-types';

const propTypes = {
  getDataByCurrLang: func.isRequired,
  getDataByLang: func.isRequired,
  setData: func.isRequired,
  pageId: string.isRequired,
  tabName: string.isRequired,
  locale: string.isRequired,
  title: string.isRequired,
  homePageTitle: string.isRequired,
  setTitle: func.isRequired,
  description: string.isRequired,
  setDescription: func.isRequired,
  isIndexEnabled: bool.isRequired,
  isIndexEnabledFromSite: bool.isRequired,
  setIndexEnabled: func.isRequired,
  uri: string.isRequired,
  setUri: func.isRequired,
  convertPageNameToUrl: func.isRequired,
  isUriInputDisabled: bool.isRequired,
  isUriForbidden: func.isRequired,
  isUriDuplicate: func.isRequired,
  ogImageFromSite: string,
  ogImage: shape({
    uri: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }),
  setOGImage: func.isRequired,
  isHomePage: bool.isRequired,
  isSitePublished: bool.isRequired,
  language: shape({
    current: string,
    original: string,
  }).isRequired,
  constants: shape({
    titleSeoMaxLength: number.isRequired,
    uriSeoMaxLength: number.isRequired,
    descriptionSeoMaxLength: number.isRequired,
  }).isRequired,
  validators: shape({
    noHTMLTags: func.isRequired,
    noEmoji: func.isRequired,
    notEmptyString: func.isRequired,
    invalidUrlCharacters: func.isRequired,
    startsOrEndsWithDash: func.isRequired,
  }).isRequired,
  openHelpCenter: func.isRequired,
  wixSeoUrl: string.isRequired,
  siteDashboardURL: string.isRequired,
  publicURL: string.isRequired,
  siteDisplayName: string.isRequired,
  mediaManager: object.isRequired,
  pageData: object.isRequired,
  msid: string.isRequired,
  numPages: number.isRequired,
  setPageKeywordsSEO: func.isRequired,
  pageKeywordsSEO: string.isRequired,
  permission: string.isRequired,
  userPatterns: array.isRequired,
  siteData: object.isRequired,
};

const defaultProps = {
  pageId: '',
  tabName: '',
  locale: '',
  msid: 'd5a9eebe-4b3a-46c6-9e2b-dd25c5dc4b8a',
  numPages: 1,
  userPatterns: [],
  siteData: { propeties: {} },
};

export { propTypes, defaultProps };
