import React, { useState, useEffect } from 'react';
import {
  useCharLimit,
  Composites,
  TextLabel,
  CharLimit,
  TextInputMultiline,
} from '@wix/wix-base-ui';
import { translate } from 'react-i18next';
import styles from '../panels.scss';
import InfoIcon from '../ui/info-icon';
import { MAX_LENGTH } from '../../../core/validators/validators';
import { KEYS } from '../../../core/utils/maps';
import RenderIf from '../../../core/components/render-if';
import ResetToPatternButton from '../ui/reset-to-pattern-button';

const SocialPanelTwitterDescription = ({
  t,
  value,
  onSave,
  onChange,
  error,
  disabled,
  logBiEvent,
  resetToPattern,
  showResetToPatternButton,
  getDefaultPatternValue,
}) => {
  const [isFocused, setFocused] = useState(false);

  const onBlur = () => {
    setFocused(false);
    onSave();
  };

  const onFocus = () => setFocused(true);

  const { showCharLimit, getTextInputProps, getCharLimitProps } = useCharLimit({
    initialValue: value,
    tooltipAlignment: 'RIGHT',
  });

  const { onChange: inputOnChange } = getTextInputProps({});
  useEffect(() => {
    inputOnChange(value);
  }, [value, inputOnChange]);

  return (
    <Composites.TextInputMultilineLabeled>
      <InfoIcon text="adv-seo.social-panel.page-twitter-description.input-tooltip" />
      <RenderIf truthy={showCharLimit && isFocused}>
        <CharLimit
          {...getCharLimitProps(MAX_LENGTH[KEYS.TWITTER_DESCRIPTION])}
          maxThreshold={50}
        />
      </RenderIf>
      <TextLabel
        value={t('adv-seo.social-panel.page-twitter-description.label')}
        shouldTranslate={false}
        automationId="twitter-description-label"
      />
      <div className={styles.inputWithResetContainer}>
        <div className={styles.inputContainer}>
          <TextInputMultiline
            {...getTextInputProps({
              invalidMessage: error,
              onChange,
              onFocus,
              onBlur,
            })}
            disabled={disabled}
            value={value}
            isValid={!error}
            className="control-text-input"
            placeholder={t(
              'adv-seo.social-panel.page-twitter-description.input-placeholder',
            )}
            shouldTranslate={false}
            automationId="twitter-description-input"
          />
        </div>
        <RenderIf
          truthy={
            !isFocused && showResetToPatternButton(KEYS.TWITTER_DESCRIPTION)
          }
        >
          <ResetToPatternButton
            topAlignment
            onClick={() => {
              resetToPattern();
              inputOnChange(getDefaultPatternValue(KEYS.TWITTER_DESCRIPTION));
            }}
            biKey={KEYS.TWITTER_DESCRIPTION}
            logBiEvent={logBiEvent}
          />
        </RenderIf>
      </div>
    </Composites.TextInputMultilineLabeled>
  );
};

export default translate()(SocialPanelTwitterDescription);
