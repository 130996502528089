import { resolvePayload } from '@wix/advanced-seo-utils/dist/src/private/renderer/resolve-payload';
import {
  IDENTIFIERS,
  getValueByIdentifier,
  getMetaByIdentifier,
  updateValueByIdentifier,
  getSimplifiedTagsArray,
  getCustomTagsString,
  getCustomTagsArray,
  getValueFromHtmlTag,
  parse,
  updateCustomTags,
  updateSimplifiedSeoTag,
  fillInPatternBlob,
  getPatternBlob,
  getSimplifiedTag,
  updateCustomTagsArray,
  transformCustomTagsToIdentified,
  getSchemasArray,
  SCHEMA_PRESET_TYPES,
  ROBOTS_DIRECTIVES,
  isRobotsDirectiveExists,
  getRobotsTagValueWithDirective,
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
  SD_STATUS,
  getSettingValueById,
  updateSettingById,
  SETTING_IDS,
} from '@wix/advanced-seo-utils';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import { getAdapter } from '@wix/advanced-seo-utils/dist/src/private/adapters';
import { jsonToScriptTag } from './utils/html';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import { ERROR_NAMES, KEYS } from './utils/maps';
import getErrorMessage from './utils/errors';
import { ERRORS_MAP } from './errors-map';
import {
  getTwitterIdentifiers,
  setWasTwitterTagsMigrated,
} from './utils/twitter-tags';
import { setWasRobotsTagMigrated } from './utils/robots-tag-utils';

export function getFullUrl(itemType, itemData) {
  return getAdapter(ITEM_TYPES[itemType]) &&
    getAdapter(ITEM_TYPES[itemType]).getFullUrl
    ? getAdapter(ITEM_TYPES[itemType]).getFullUrl(itemData)
    : '';
}

export function setFullUrl(itemType, itemData, url) {
  return getAdapter(ITEM_TYPES[itemType]).setFullUrl(itemData, url);
}

export function getItemLegacyBlob(itemType, itemData) {
  const adapter = getAdapter(ITEM_TYPES[itemType]);
  return adapter.getLegacySeoBlob && adapter.getLegacySeoBlob(itemData);
}

export function getSdStatus(itemType, itemData, schemaType) {
  const adapter = getAdapter(ITEM_TYPES[itemType]);
  const data = adapter?.getData(itemData);
  return adapter.getSdStatus(itemData, data, schemaType);
}

export function getIsSchemaMissingBusinessInfo(itemType, itemData, schemaType) {
  const sdStatus = getSdStatus(itemType, itemData, schemaType);
  return sdStatus === SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
}

export function getSdSchemaTypes() {
  return SCHEMA_PRESET_TYPES;
}

export function getSlugByItemType(itemType, itemData) {
  return getAdapter(ITEM_TYPES[itemType]).getSlug(itemData);
}

export function updateItemDataWithSlug(itemType, itemData, value, options) {
  return getAdapter(ITEM_TYPES[itemType]).updateItemDataWithSlug(
    itemData,
    value,
    options,
  );
}

export function getBiData(itemType, itemData) {
  return getAdapter(ITEM_TYPES[itemType]).getBiData(itemData);
}

export function createGetter(identifier) {
  return (blob) => ({ value: getValueByIdentifier(blob, identifier) || '' });
}

export function createAdvancedTagsGetter() {
  return (blob) =>
    getSimplifiedTagsArray(blob, {
      keepDefaultTags: true,
      excludeCustomTags: true,
    }) || [];
}

export function createCustomTagsGetter() {
  return (blob) => ({ value: getCustomTagsString(blob) || '' });
}

export function createCustomTagsArrayGetter() {
  return (blob) => getCustomTagsArray(blob) || [];
}

export function createJsonLdGetter(context) {
  return (blob, defaultPattern) => {
    const schemasArray = getSchemasArray(blob, {
      withMeta: true,
      defaultPattern,
      context,
    });
    const preset = schemasArray.find((schema) => !schema.disabled);
    const schemaString = JSON.stringify(preset?.schema) || '';
    return {
      value: jsonToScriptTag(schemaString === `"{}"` ? '{}' : schemaString),
    };
  };
}

export function createUriGetter(value) {
  return () => ({
    value,
  });
}

export const stucturedDataGetter = (mergedPattern, defaultPattern, context) => {
  const schemasArray = getSchemasArray(mergedPattern, {
    withMeta: true,
    defaultPattern,
    context,
  });
  return {
    value: schemasArray,
    initialValue: schemasArray,
  };
};

const stringifySchema = (schema) => {
  let schemaString;
  try {
    const schemaObject =
      typeof schema === 'string' ? JSON.parse(schema) : schema;
    schemaString = JSON.stringify(schemaObject, null, 2);
  } catch {
    schemaString = '{}';
  }
  return schemaString;
};

export function buildStructuredDataState(
  schemas = {},
  { keepEmptyPresets = false } = {},
) {
  return schemas.value
    ?.filter((schema) => {
      const isPreset = !schema.displayName;
      if (isPreset && schema.disabled && !keepEmptyPresets) {
        return schema.schema !== '{}';
      }
      return schema.schema;
    })
    .map((schemaItem) => {
      const variants = schemaItem?.variants?.map((variant) => {
        return {
          ...variant,
          value: stringifySchema(variant.schema),
        };
      });
      const schemaString = stringifySchema(schemaItem.schema);

      const schema = {
        schemaType: schemaItem.schemaType,
        displayName: schemaItem.displayName,
        disabled: schemaItem.disabled,
        value: schemaString,
        initialValue: schemaString,
        selectedVariant: schemaItem.selectedVariant || schemaItem.schemaType,
        ...(variants?.length ? { variants } : {}),
      };
      return schema;
    });
}

export function createSchemasMapGetter(context) {
  return (mergedPattern, defaultPattern) => {
    const structuredData = stucturedDataGetter(
      mergedPattern,
      defaultPattern,
      context,
    );
    return buildStructuredDataState(structuredData, {
      keepEmptyPresets: true,
    });
  };
}

export function createIsIndexEnabledGetter() {
  return (blob) => ({
    value: !isRobotsDirectiveExists(
      getValueByIdentifier(blob, IDENTIFIERS.ROBOTS),
      ROBOTS_DIRECTIVES.NOINDEX,
    ),
  });
}

export function createPreventAutoRedirectGetter() {
  return (blob) => ({
    value:
      getSettingValueById(blob, SETTING_IDS.PREVENT_AUTO_REDIRECT) || false,
  });
}

export function createImageGetter(identifier) {
  return (blob) => {
    const uri = getValueByIdentifier(blob, identifier) || '';
    const meta = getMetaByIdentifier(blob, identifier) || {};
    return {
      value: {
        uri,
        width: (meta && meta.width) || 0,
        height: (meta && meta.height) || 0,
      },
    };
  };
}

export function createSetter(identifier) {
  return (blob, oldValue, newValue) => {
    if (oldValue !== newValue) {
      return updateValueByIdentifier(
        blob,
        identifier,
        newValue,
        undefined,
        false,
      );
    }
    return null;
  };
}

export function createSetterNoop() {
  return (blob) => {
    return blob;
  };
}

export function createStructuredDataSetter() {
  return (blob, oldValue, newValue) => {
    if (oldValue !== newValue) {
      const tags = parse(newValue);
      const value = getValueFromHtmlTag(tags[0], IDENTIFIERS.STRUCTURED_DATA);
      return updateValueByIdentifier(blob, IDENTIFIERS.STRUCTURED_DATA, value);
    }
    return null;
  };
}

export function createPreventAutoRedirectSetter() {
  return (blob, oldValue, newValue) => {
    if (oldValue !== newValue) {
      return updateSettingById(
        blob,
        SETTING_IDS.PREVENT_AUTO_REDIRECT,
        newValue,
      );
    }
    return null;
  };
}

export function createIsIndexEnabledSetter() {
  return (blob, _oldValue, newValue) => {
    const existingRobotsTagValue =
      getValueByIdentifier(blob, IDENTIFIERS.ROBOTS) ?? '';
    return updateValueByIdentifier(
      blob,
      IDENTIFIERS.ROBOTS,
      getRobotsTagValueWithDirective(
        existingRobotsTagValue,
        ROBOTS_DIRECTIVES.NOINDEX,
        newValue ? REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE : undefined,
      ),
    );
  };
}

export function createImageSetter(identifier) {
  return (blob, oldValue, newValue) => {
    if (newValue && oldValue.uri !== newValue.uri) {
      return updateValueByIdentifier(blob, identifier, newValue.uri, {
        width: newValue.width,
        height: newValue.height,
      });
    }
    return null;
  };
}

export function createCustomTagsSetter() {
  return (blob, oldValue, newValue) => {
    if (oldValue !== newValue) {
      return updateCustomTags(blob, newValue);
    }
    return null;
  };
}

export function createAdvancedTagsSetter() {
  return (blob, data, oldValue, newValue, toggleIsDisabled = false) => {
    if (oldValue !== newValue || toggleIsDisabled) {
      return updateSimplifiedSeoTag(blob, data, newValue);
    }
    return null;
  };
}

export function createCustomTagsArraySetter() {
  return (blob, data, oldValue, newValue, removeTag) => {
    if (oldValue !== newValue || removeTag) {
      return updateCustomTagsArray(blob, data, newValue, removeTag);
    }
    return null;
  };
}

export function getDefaultPatternBlob(itemType) {
  if (itemType && ITEM_TYPES[itemType]) {
    return getPatternBlob(ITEM_TYPES[itemType]);
  }
  return {};
}

export function mergeBlobs(...blobs) {
  return resolvePayload(blobs);
}

export function mergeBlobsAndTransform(
  isTwitterCardValueSupportedOrMissing,
  isRobotsTagValueSupported,
  ...blobs
) {
  const mergedPattern = mergeBlobs(...blobs);
  const transformedTagsAfterTwitterTags = transformCustomTagsToIdentified(
    mergedPattern.tags,
    isTwitterCardValueSupportedOrMissing ? getTwitterIdentifiers() : [],
  );
  if (!isEqual(mergedPattern.tags, transformedTagsAfterTwitterTags)) {
    setWasTwitterTagsMigrated(true);
  }

  const transformedTagsAfterRobotsTag = transformCustomTagsToIdentified(
    transformedTagsAfterTwitterTags,
    isRobotsTagValueSupported ? [IDENTIFIERS.ROBOTS] : [],
  );
  if (
    !isEqual(transformedTagsAfterTwitterTags, transformedTagsAfterRobotsTag)
  ) {
    setWasRobotsTagMigrated(true);
  }

  return {
    ...mergedPattern,
    tags: transformedTagsAfterRobotsTag,
  };
}

export function getPayload(itemType, item, context, options = {}) {
  if (itemType && ITEM_TYPES[itemType]) {
    return {
      ...getAdapter(ITEM_TYPES[itemType]).getData(
        {
          ...item,
          context,
        },
        options,
      ),
    };
  }
  return getAdapter(ITEM_TYPES.STATIC_PAGE_V2).getData({ context }, options);
}

export function fillIn(blob, payload) {
  return fillInPatternBlob(blob, payload);
}

const initialStateTag = {
  error: '',
  warning: '',
  value: '',
};

export function buildStateTag(tag = {}, prevStateTag = initialStateTag) {
  return {
    ...prevStateTag,
    ...tag,
  };
}

function buildAdvancedStateTagWithDefaults(tag, advancedTagsListWithDefaults) {
  if (!tag.value && tag.isDisabled) {
    const defaultValue = getAdvancedTagPropByLabel(
      advancedTagsListWithDefaults,
      tag.label,
    );
    if (defaultValue) {
      tag.value = defaultValue;
    }
  }
  return buildStateTag(tag);
}

export function buildStateWithAdvancedTags(
  blob,
  fields,
  dataWithDefaults = { tags: [] },
) {
  return Object.keys(fields).reduce((acc, curr) => {
    const tag = fields[curr](blob);
    let value;
    switch (curr) {
      case KEYS.ADVANCED_TAGS: {
        const advancedTagsListWithDefaults = fields[curr](dataWithDefaults);
        value = map(tag, (t) =>
          buildAdvancedStateTagWithDefaults(t, advancedTagsListWithDefaults),
        ).sort((tag1, tag2) => {
          if (tag1.label === IDENTIFIERS.CANONICAL) {
            return -1;
          }
          if (tag2.label === IDENTIFIERS.CANONICAL) {
            return 1;
          }
          return tag1.label.localeCompare(tag2.label);
        });
        break;
      }

      case KEYS.CUSTOM_TAGS_ARRAY:
        value = map(tag, (t) => buildStateTag(t));
        break;

      default:
        value = buildStateTag(tag);
        break;
    }

    return Object.assign(acc, {
      [curr]: value,
    });
  }, {});
}

export function buildState(blob, fields, dataWithDefaults = { tags: [] }) {
  return Object.keys(fields).reduce((acc, curr) => {
    const tag = fields[curr](blob);
    let value;
    switch (curr) {
      case KEYS.ADVANCED_TAGS: {
        const advancedTagsListWithDefaults = fields[curr](dataWithDefaults);
        value = map(tag, (t) =>
          buildAdvancedStateTagWithDefaults(t, advancedTagsListWithDefaults),
        ).sort((tag1, tag2) => {
          if (tag1.label === IDENTIFIERS.CANONICAL) {
            return -1;
          }
          if (tag2.label === IDENTIFIERS.CANONICAL) {
            return 1;
          }
          const customTagComparison = !!tag1.isCustom - !!tag2.isCustom;
          return customTagComparison === 0
            ? tag1.label.localeCompare(tag2.label)
            : customTagComparison;
        });
        break;
      }

      case KEYS.CUSTOM_TAGS_ARRAY:
        value = map(tag, (t) => buildStateTag(t));
        break;

      case KEYS.SCHEMAS_MAP:
        value = tag;
        break;

      default:
        value = buildStateTag(tag);
        break;
    }

    return Object.assign(acc, {
      [curr]: value,
    });
  }, {});
}

export function getAdvancedTagIndexByLabel(tagsList, label) {
  return findIndex(tagsList, (tag) => {
    return tag.label === label;
  });
}

export function getAdvancedTagPropByLabel(tagsList, label, prop = 'value') {
  const index = getAdvancedTagIndexByLabel(tagsList, label);
  const tag = index >= 0 ? tagsList[index] : null;
  if (!tag) {
    return null;
  }
  return prop === 'isDisabled' ? tag.isDisabled : tagsList[index][prop];
}

const TAG_TYPE_TO_PROPERTY_MAP = {
  link: 'rel',
  meta: 'property',
};

export function getTagFromVisibleData(key, visibleData) {
  const tagIndex = findIndex(visibleData.tags, (tag) => {
    const propKey = TAG_TYPE_TO_PROPERTY_MAP[tag.type];
    return propKey
      ? tag.props[TAG_TYPE_TO_PROPERTY_MAP[tag.type]] === key
      : null;
  });

  return tagIndex >= 0 ? visibleData.tags[tagIndex] : null;
}

export function getValuesAfterValidation(
  result,
  value,
  key,
  i18n,
  errorMapFlags,
) {
  const getErrorName = () => {
    if (result.isValid || !result.error) {
      return '';
    }
    if (result.error.name === ERROR_NAMES.CUSTOM_VALIDATOR) {
      return ERRORS_MAP(errorMapFlags)[key][ERROR_NAMES.CUSTOM_VALIDATOR][
        result.error.validatorIndex
      ];
    }
    return result.error.name;
  };

  return {
    error: result.isValid
      ? ''
      : getErrorMessage(result.error, key, i18n, errorMapFlags),
    warning: result.hasWarning || '',
    value: value.value || value,
    hasWarning: result.hasWarning,
    errorName: getErrorName(),
  };
}

export function getCurrentItemPreview(key) {
  return (data, value) => {
    if (key === KEYS.ADVANCED_TAGS) {
      const isEmptyValue = !value;
      if (isEmptyValue) {
        value = 'blank';
      }
      const tagsArray = updateSimplifiedSeoTag({ tags: [] }, data, value);
      const simplifiedTag = getSimplifiedTag(tagsArray[0]);
      let preview = '';
      if (simplifiedTag && simplifiedTag.html.length) {
        preview = isEmptyValue
          ? simplifiedTag.html[0].replace('blank', '')
          : simplifiedTag.html[0];
      }
      return preview;
    }
  };
}

export function getErrorKey(key, data) {
  let errorKey;
  switch (key) {
    case KEYS.ADVANCED_TAGS:
      errorKey = data.label;
      break;

    case KEYS.CUSTOM_TAGS_ARRAY:
      errorKey = KEYS.CUSTOM_TAGS_ARRAY;
      break;

    default:
      errorKey = key;
  }
  return errorKey;
}

export function translateBySchemaType(key, schemaType, t) {
  const itemTypeKey = `${key}.${schemaType}`;

  const contentByItemExist = t(itemTypeKey) !== itemTypeKey;
  return contentByItemExist ? t(itemTypeKey) : schemaType;
}
