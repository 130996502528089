import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts/index';
import { getData } from './get-data';
import { getDataLegacy } from './get-data-legacy';
var IDs = {
    ID: 'group.id',
    INDEX_PAGE: 'page.index',
    GROUP_TAB_TITLE: 'group.tab.title',
    GROUP_NAME: 'group.name',
    GROUP_DESCRIPTION: 'group.description',
    MAIN_IMAGE: 'group.image.url',
    GROUP_IMAGE_WIDTH: 'group.image.width',
    GROUP_IMAGE_HEIGHT: 'group.image.height',
    GROUP_TAB_URL: 'group.tab.url',
    GROUP_ROBOTS: 'group.robots',
};
var userVisible = [
    IDs.GROUP_NAME,
    IDs.GROUP_TAB_URL,
    IDs.GROUP_DESCRIPTION,
    IDs.MAIN_IMAGE,
    IDs.GROUP_TAB_TITLE,
];
export default {
    IDs: IDs,
    getData: function (item) {
        return (item === null || item === void 0 ? void 0 : item.isMigrated) ? getData(IDs, item) : getDataLegacy(IDs, item);
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
