import adapter from '../adapter-events-page';
var IDs = adapter.IDs, SchemaTypes = adapter.SchemaTypes;
export default {
    type: SchemaTypes.EVENTS_PAGE,
    schema: {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: "{{".concat(IDs.TITLE, "}}"),
        description: "{{".concat(IDs.DESCRIPTION, "}}"),
        startDate: "{{".concat(IDs.START_DATE, "}}"),
        endDate: "{{".concat(IDs.END_DATE, "}}"),
        eventStatus: "{{".concat(IDs.SD_STATUS, "}}"),
        eventAttendanceMode: "{{".concat(IDs.SD_ATTANDANCE_MODE, "}}"),
        location: "{{".concat(IDs.SD_JSON_LOCATION, "}}"),
        offers: "{{".concat(IDs.SD_JSON_OFFERS, "}}"),
        image: {
            '@type': 'ImageObject',
            url: "{{".concat(IDs.MAIN_IMAGE, "}}"),
            width: "{{".concat(IDs.IMAGE_WIDTH, "}}"),
            height: "{{".concat(IDs.IMAGE_HEIGHT, "}}"),
        },
    },
};
