import React from 'react';
import { string } from 'prop-types';
import {
  PageSegment,
  SortOrder,
} from '@wix/ambassador-viewer-structure-v1-site-structure-item/types';
import { withExperiments } from '@wix/wix-experiments-react';
import { getSiteStructure, buildOgImagePreview } from '@wix/advanced-seo-utils';
import { AppWrapper } from '../app-wrapper';
import { getIsDomainConnected } from '../../../core/utils/utils';
import { initBiLogger } from '../../initializers';
import { newToLegacy } from '../../tabs/tab-names';
import { HttpClient } from '@wix/http-client';
import { getMultilingualResolutionMethod } from '../fetchers/get-multilingual-resolution-method';

class ProxyApp extends React.Component {
  static propTypes = {
    tabName: string.isRequired,
  };
  static defaultProps = {
    tabName: '',
  };

  state = {
    isReady: false,
  };

  constructor(props) {
    super(props);

    const { metasiteInstance } = this.props;
    const httpClient = new HttpClient({
      headers: {
        Authorization: metasiteInstance,
      },
    });

    this.httpClient = httpClient;
    this.biLogger = initBiLogger(props);
    this.unsubscribeAfterPublish = null;
  }

  componentDidMount = async () => {
    const newState = {
      isReady: true,
    };

    this.unsubscribeAfterPublish = this.props.subscribeAfterPublish?.(
      this._afterPublish,
    );

    const {
      siteData,
      userPatterns,
      mlResolutionMethod,
      connectedDomainResponse,
      siteStructure,
    } = await this._initSeoPanelContext();
    newState.siteData = siteData;
    newState.userPatterns = userPatterns;
    newState.mlResolutionMethod = mlResolutionMethod;
    newState.connectedDomainResponse = connectedDomainResponse;
    newState.siteStructure = siteStructure;
    this.setState(newState);
  };

  componentWillUnmount = () => {
    this.unsubscribeAfterPublish?.();
  };

  render() {
    const { tabName, isCoBranded } = this.props;
    const {
      userPatterns,
      siteData,
      mlResolutionMethod,
      connectedDomainResponse,
      siteStructure,
    } = this.state;
    const isDomainConnected =
      !connectedDomainResponse?.hasError &&
      connectedDomainResponse?.isConnected;
    if (!this.state.isReady) {
      return null;
    }
    return (
      <AppWrapper
        {...this.props}
        tabName={newToLegacy[tabName] || tabName}
        userPatterns={userPatterns}
        siteData={siteData}
        isCoBranded={isCoBranded}
        mlResolutionMethod={mlResolutionMethod}
        connectedDomainResponse={connectedDomainResponse}
        isDomainConnected={isDomainConnected}
        siteStructure={siteStructure}
        biLogger={this.biLogger}
      />
    );
  }

  async _initSeoPanelContext() {
    let siteData,
      userPatterns,
      mlResolutionMethod,
      connectedDomainResponse,
      siteStructure;
    const { msid } = this.props;
    if (window.seoPanelContext) {
      siteData = window.seoPanelContext.siteData;
      userPatterns = window.seoPanelContext.userPatterns;
      mlResolutionMethod = window.seoPanelContext.mlResolutionMethod;
      connectedDomainResponse = window.seoPanelContext.connectedDomainResponse;
      siteStructure = window.seoPanelContext.siteStructure;
    } else {
      [
        { siteData },
        { userPatterns },
        mlResolutionMethod,
        connectedDomainResponse,
        siteStructure,
      ] = await Promise.all([
        this._getSiteData(),
        this._getUserPatterns(),
        this._getMultilingualResolutionMethod(),
        getIsDomainConnected(msid, this.biLogger.logBiEvent, this.httpClient),
        this._getSiteStructure(),
      ]);
      window.seoPanelContext = {
        siteData,
        userPatterns,
        mlResolutionMethod,
        connectedDomainResponse,
        siteStructure,
      };
    }
    return {
      siteData,
      userPatterns,
      mlResolutionMethod,
      connectedDomainResponse,
      siteStructure,
    };
  }

  async _getUserPatterns() {
    let userPatterns = [];
    try {
      const apiUrl = '/_api/wix-html-live-site-data-webapp/seo-patterns';
      const { data } = await this.httpClient.get(apiUrl);
      userPatterns = (data && data.patterns) || [];
    } catch (e) {
      console.error(e);
    }
    return { userPatterns };
  }

  async _getSiteData() {
    let siteData = [];
    try {
      const { msid: metasiteId } = this.props;
      const apiUrl = '/_api/site-properties-service/properties';

      const { data } = await this.httpClient.get(apiUrl, {
        params: { metasiteId },
      });
      const properties = {
        ...data.properties,
        logo: buildOgImagePreview(data.properties.logo),
      };
      siteData = {
        ...data,
        properties,
      };
    } catch (e) {
      console.error(e);
    }
    return { siteData };
  }

  async _getMultilingualResolutionMethod() {
    let resolutionMethod;
    try {
      resolutionMethod = await getMultilingualResolutionMethod(this.httpClient);
    } catch (e) {
      console.error(e);
    }
    return resolutionMethod;
  }

  _afterPublish = async () => {
    const siteStructure = await this._getSiteStructure();
    this.setState({ siteStructure });
    if (window.seoPanelContext) {
      window.seoPanelContext.siteStructure = siteStructure;
    }
  };

  _getSiteStructure = async () => {
    return getSiteStructure({
      httpClient: this.httpClient,
      pageVersion: PageSegment.PUBLISHED,
      sortOrder: SortOrder.DESC,
      filters: [
        {
          itemTypeIdentifier: 'static_page',
          sourceId: 'editor',
          configId: '',
        },
      ],
    });
  };
}

export default withExperiments(ProxyApp);
