import React from 'react';
import { number, string } from 'prop-types';

export function Upload({ color, width, height }) {
  return (
    <svg viewBox="0 0 25 25" fill={color} width={width} height={height}>
      <path d="M6,13 L5,13 L5,17 C5,18.1045695 5.8954305,19 7,19 L18,19 C19.1045695,19 20,18.1045695 20,17 L20,13 L19,13 L19,17 C19,17.5522847 18.5522847,18 18,18 L7,18 C6.44771525,18 6,17.5522847 6,17 L6,13 Z M12,6.5 L12,16 L13,16 L13,6.5 L15.5,9 L16,8.375 L12.5,5 L9,8.375 L9.5,9 L12,6.5 Z" />
    </svg>
  );
}

Upload.propTypes = {
  width: number,
  height: number,
  color: string,
};

Upload.defaultProps = {
  width: 25,
  height: 25,
  color: 'currentColor',
};
