import React from 'react';

import SocialPanel from '../components/social-panel';

export const SocialTab = ({
  ogImageFromSite,
  ogImageFromPattern,
  publicURL,
  openHelpCenter,
  onSave,
  validateAndSetState,
  logBiEvent,
  ogTitle,
  ogDescription,
  ogImage,
  isTwitterCardValueSupportedOrMissing,
  twitterCard,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterImageFromSite,
  twitterImageFromPattern,
  customTwitterImageUrl,
  experiments,
  resetToPattern,
  showResetToPatternButton,
  setActiveTab,
  getDefaultPatternValue,
}) => (
  <SocialPanel
    onSave={onSave}
    onChange={validateAndSetState}
    title={ogTitle.value || ''}
    titleErrorMessage={ogTitle.error}
    description={ogDescription.value || ''}
    descriptionErrorMessage={ogDescription.error}
    ogImageFromSite={ogImageFromPattern || ogImageFromSite}
    ogImage={(ogImage.value || {}).uri}
    ogImageError={ogImage.error}
    ogImageHasWarning={!!ogImage.hasWarning}
    publicURL={publicURL}
    openHelpCenter={openHelpCenter}
    logBiEvent={logBiEvent}
    experiments={experiments}
    isTwitterCardValueSupportedOrMissing={isTwitterCardValueSupportedOrMissing}
    twitterCard={twitterCard.value || ''}
    twitterTitle={twitterTitle.value || ''}
    twitterTitleErrorMessage={twitterTitle.error || ''}
    twitterDescription={twitterDescription.value || ''}
    twitterDescriptionErrorMessage={twitterDescription.error || ''}
    twitterImageFromSite={twitterImageFromPattern || twitterImageFromSite || ''}
    twitterImage={(twitterImage.value || {}).uri}
    customTwitterImageUrl={customTwitterImageUrl}
    twitterImageError={twitterImage.error}
    twitterImageHasWarning={!!twitterImage.hasWarning}
    resetToPattern={resetToPattern}
    showResetToPatternButton={showResetToPatternButton}
    setActiveTab={setActiveTab}
    getDefaultPatternValue={getDefaultPatternValue}
  />
);
