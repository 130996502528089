"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var ItemTypes_1 = require("../../types/ItemTypes");
var get_1 = require("../../utils/get");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
exports.IDs = {
    SEARCH_TERM: 'search-page.search-term',
    ALL_RESULTS_URL: 'search-page.all-results-url',
    PAGE_URL: 'search-page.page-url',
    RESULTS_TOTAL: 'search-page.results-total',
};
var SchemaTypes = {};
var userVisible = [exports.IDs.SEARCH_TERM, exports.IDs.RESULTS_TOTAL];
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[exports.IDs.SEARCH_TERM] = (0, get_1.get)(item, 'searchTerm'),
            _a[exports.IDs.ALL_RESULTS_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.SEARCH_PAGE, item, (0, get_1.get)(item, 'allResultsUrl')),
            _a[exports.IDs.PAGE_URL] = (0, get_1.get)(item, 'pageUrl'),
            _a[exports.IDs.RESULTS_TOTAL] = (0, get_1.get)(item, 'resultsTotal'),
            _a;
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
