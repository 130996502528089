"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStructuredData = void 0;
var get_1 = require("../../utils/get");
var structure_data_utils_1 = require("../../adapters/structure-data-utils");
var getStructuredData = function (IDs, collectionItems, coverImageData) {
    var _a;
    if (!IDs) {
        return {};
    }
    var imagesData = collectionItems.map(function (collectionItem) {
        return {
            url: (0, get_1.get)(collectionItem, 'project.coverImage.imageInfo.url'),
            width: (0, get_1.get)(collectionItem, 'project.coverImage.imageInfo.width'),
            height: (0, get_1.get)(collectionItem, 'project.coverImage.imageInfo.height'),
            alt: (0, get_1.get)(collectionItem, 'project.coverImage.imageInfo.altText'),
        };
    });
    imagesData.push({
        url: coverImageData[IDs.COVER_IMAGE],
        width: coverImageData[IDs.COVER_IMAGE_WIDTH],
        height: coverImageData[IDs.COVER_IMAGE_HEIGHT],
        alt: coverImageData[IDs.COVER_IMAGE_ALT],
    });
    var _b = (0, structure_data_utils_1.getImageObject)('images', imagesData, false).images, images = _b === void 0 ? '' : _b;
    return _a = {},
        _a[IDs.SD_JSON_IMAGES] = images,
        _a;
};
exports.getStructuredData = getStructuredData;
