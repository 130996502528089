import { FIELD_TYPE } from './consts';
export var getDynamicKeysData = function (collections) {
    var _a;
    if (!collections || !collections.length) {
        return {};
    }
    var dynamicPagesFields = {};
    var mainCollection = collections === null || collections === void 0 ? void 0 : collections.find(function (collection) { return collection.primary; });
    (_a = mainCollection.fields) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var type = _a.type;
        return type &&
            [FIELD_TYPE.TEXT, FIELD_TYPE.NUMBER, FIELD_TYPE.IMAGE].includes(type);
    }).forEach(function (_a) {
        var key = _a.key, value = _a.value;
        var fieldValue = value;
        if (typeof value === 'object') {
            fieldValue = value === null || value === void 0 ? void 0 : value.url;
        }
        dynamicPagesFields["wix-data-page-item.".concat(mainCollection.id, ".").concat(key)] =
            fieldValue;
    });
    return dynamicPagesFields;
};
