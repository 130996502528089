"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../../adapters/utils");
var schema_types_1 = require("./schema-types");
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'LocalBusiness',
    name: "{{".concat(utils_1.siteIDs.BUSINESS_NAME, "}}"),
    url: "{{".concat(utils_1.siteIDs.SITE_URL, "}}"),
    image: "{{".concat(utils_1.siteIDs.BUSINESS_SCHEMA_IMAGE, "}}"),
    address: {
        '@type': 'PostalAddress',
        addressCountry: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_COUNTRY, "}}"),
        addressLocality: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_CITY, "}}"),
        addressRegion: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_STATE, "}}"),
        postalCode: "{{".concat(utils_1.siteIDs.BUSINESS_POSTAL_CODE, "}}"),
        streetAddress: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_STREET_ADDRESS, "}}"),
    },
    telephone: "{{".concat(utils_1.siteIDs.BUSINESS_PHONE, "}}"),
};
var requiredSchemaVariables = [
    utils_1.siteIDs.BUSINESS_NAME,
    utils_1.siteIDs.BUSINESS_LOCATION_FORMATTED,
];
exports.default = {
    type: schema_types_1.SCHEMA_TYPES.LOCAL_BUSINESS,
    schema: schema,
    requiredSchemaVariables: requiredSchemaVariables,
};
