import React, { useCallback } from 'react';
import { DropDown, DropDownOption } from '@wix/wix-base-ui';
import {
  ROBOTS_DIRECTIVES,
  MAX_IMAGE_PREVIEW_VALUES,
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
} from '@wix/advanced-seo-utils';
import {
  useDirectiveDisplayValue,
  useIsDirectiveExists,
  useUpdateDirectiveWithValue,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

const maxPreviewDefaultValue = MAX_IMAGE_PREVIEW_VALUES.STANDARD;

export const checkboxDataHook = 'max-image-preview-directive-checkbox';

export const dropdownDataHook = 'max-image-preview-directive-dropdown';

export const MaxImagePreviewDirective = translate()(({ t }) => {
  const isMaxImagePreviewDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.MAX_IMAGE_PREVIEW,
  );
  const maxImagePreviewDisplayValue = useDirectiveDisplayValue(
    ROBOTS_DIRECTIVES.MAX_IMAGE_PREVIEW,
    maxPreviewDefaultValue,
  );
  const onUpdateMaxImagePreview = useUpdateDirectiveWithValue(
    ROBOTS_DIRECTIVES.MAX_IMAGE_PREVIEW,
  );
  const onChangeMaxImagePreviewCheckbox = useCallback(
    (isEnabled) => {
      isEnabled
        ? onUpdateMaxImagePreview(maxPreviewDefaultValue)
        : onUpdateMaxImagePreview(REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE);
    },
    [onUpdateMaxImagePreview],
  );

  return (
    <>
      <CheckboxWithInfoIcon
        dataHook={checkboxDataHook}
        isChecked={isMaxImagePreviewDirectiveExists}
        onChange={onChangeMaxImagePreviewCheckbox}
        label={t(
          'adv-seo.common.robots-tag.directives.max-image-preview.title',
        )}
        infoText={t(
          'adv-seo.common.robots-tag.directives.max-image-preview.tooltip',
        )}
        infoTextBiName="adv-seo.common.robots-tag.directives.max-image-preview.tooltip"
        helpCenterId={t(
          'adv-seo.common.robots-tag.directives.max-image-preview.tooltip.editor-help-center-id',
        )}
      />
      {isMaxImagePreviewDirectiveExists && (
        <div style={{ marginLeft: '30px' }}>
          <DropDown
            dataHook={dropdownDataHook}
            value={maxImagePreviewDisplayValue}
            onChange={onUpdateMaxImagePreview}
            shouldTranslate={false}
          >
            <DropDownOption
              value={MAX_IMAGE_PREVIEW_VALUES.STANDARD}
              label={t(
                'adv-seo.common.robots-tag.directives.max-image-preview.values.standard',
              )}
              shouldTranslate={false}
            />
            <DropDownOption
              value={MAX_IMAGE_PREVIEW_VALUES.LARGE}
              label={t(
                'adv-seo.common.robots-tag.directives.max-image-preview.values.large',
              )}
              shouldTranslate={false}
            />
            <DropDownOption
              value={MAX_IMAGE_PREVIEW_VALUES.NONE}
              label={t(
                'adv-seo.common.robots-tag.directives.max-image-preview.values.none',
              )}
              shouldTranslate={false}
            />
          </DropDown>
        </div>
      )}
    </>
  );
});

MaxImagePreviewDirective.propTypes = {};
