import { TAG_TYPES } from '../types/TagTypes';
export function convertTpaModel(payload) {
    if (!payload) {
        return {
            tags: [],
        };
    }
    var mergedTags = Object.keys(payload)
        .map(function (compId) { return ((payload[compId] || {}).compData || {}).meta || {}; })
        .reduce(function (acc, curr) { return Object.assign(acc, curr); }, {});
    var linkTagRels = {
        canonical: 'canonical',
        prevLink: 'prev',
        nextLink: 'next',
    };
    return {
        tags: Object.keys(mergedTags)
            .map(function (tag) {
            return !mergedTags[tag]
                ? false
                : tag === 'title'
                    ? {
                        type: TAG_TYPES.TITLE,
                        children: mergedTags[tag],
                    }
                    : linkTagRels[tag]
                        ? {
                            type: TAG_TYPES.LINK,
                            props: {
                                rel: linkTagRels[tag],
                                href: mergedTags[tag],
                            },
                        }
                        : tag.indexOf('og') === 0
                            ? {
                                type: TAG_TYPES.META,
                                props: {
                                    property: tag.toLowerCase() === 'ogsitename'
                                        ? 'og:site_name'
                                        : tag.replace(/([a-z])([A-Z])/g, '$1:$2').toLowerCase(),
                                    content: mergedTags[tag],
                                },
                            }
                            : {
                                type: TAG_TYPES.META,
                                props: {
                                    name: tag,
                                    content: mergedTags[tag],
                                },
                            };
        })
            .filter(Boolean),
    };
}
