import { getCustomTagsIndexArray } from './get-custom-tags-index-array';
export var removeTagByIndex = function (tags, customIndex, ignoreDefaultTags) {
    if (tags === void 0) { tags = []; }
    if (ignoreDefaultTags === void 0) { ignoreDefaultTags = false; }
    var customTagsIndexArray = getCustomTagsIndexArray(tags, ignoreDefaultTags);
    if (!customTagsIndexArray.length) {
        return tags;
    }
    var updatedTags = tags.slice();
    var index = customIndex >= 0 && customIndex < customTagsIndexArray.length
        ? customTagsIndexArray[customIndex]
        : -1;
    if (index !== -1) {
        updatedTags.splice(index, 1);
    }
    return updatedTags;
};
