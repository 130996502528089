"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var get_1 = require("../../utils/get");
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
exports.IDs = {
    RATING_COUNT: 'reviews-component.rating-count',
    RATING_VALUE: 'reviews-component.rating-value',
    BEST_RATING: 'reviews-component.best-rating',
    WORST_RATING: 'reviews-component.worst-rating',
    REVIEW_COUNT: 'reviews-component.review-count',
    REVIEWS: 'reviews-component.review',
    DISABLE_SCHEMA: 'reviews-component.disable-schema',
};
var requiredSchemaVariables = [exports.IDs.REVIEW_COUNT, exports.IDs.RATING_VALUE];
var SchemaTypes = {
    REVIEWS_COMPONENT_ITEM_LIST: 'reviews-component-component-item-list-preset',
};
var userVisible = [];
var getSdStatus = function (item, itemData) {
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables, item, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getStructuredReviews = function (item) {
    var _a;
    if (!((_a = item === null || item === void 0 ? void 0 : item.review) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    return item.review.map(function (review) {
        var _a, _b;
        return ({
            '@type': 'Review',
            reviewRatings: {
                '@type': 'Rating',
                ratingValue: "".concat(((_a = review.reviewRatings) === null || _a === void 0 ? void 0 : _a.ratingValue) || ''),
            },
            author: {
                '@type': 'Person',
                name: (_b = review.author) === null || _b === void 0 ? void 0 : _b.name,
            },
        });
    });
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = (_a = {},
            _a[exports.IDs.RATING_COUNT] = (0, get_1.get)(item, 'aggregateRating.ratingCount'),
            _a[exports.IDs.RATING_VALUE] = (0, get_1.get)(item, 'aggregateRating.ratingValue'),
            _a[exports.IDs.BEST_RATING] = (0, get_1.get)(item, 'aggregateRating.bestRating'),
            _a[exports.IDs.WORST_RATING] = (0, get_1.get)(item, 'aggregateRating.worstRating'),
            _a[exports.IDs.REVIEW_COUNT] = (0, get_1.get)(item, 'aggregateRating.reviewCount'),
            _a[exports.IDs.REVIEWS] = getStructuredReviews(item),
            _a);
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.DISABLE_SCHEMA] = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED, _b));
    },
    getSdStatus: getSdStatus,
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
