import React from 'react';
import { getSdSchemaTypes } from '../../../../core/app-base';
import { Location, Search } from '@wix/wix-ui-icons-common';

const schemaTypes = getSdSchemaTypes();
export const schemaPresets = {
  [schemaTypes.LOCAL_BUSINESS]: {
    iconComponent: <Location />,
  },
  [schemaTypes.SITE_SEARCH]: {
    iconComponent: <Search />,
  },
};
