"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.experimentEnabled = void 0;
var ContextProps_1 = require("../types/ContextProps");
var utils_1 = require("./utils");
function experimentEnabled(key, context) {
    var experiments = (context === null || context === void 0 ? void 0 : context[utils_1.siteIDs.EXPERIMENTS]) ||
        (context === null || context === void 0 ? void 0 : context[ContextProps_1.CONTEXT_PROPS.EXPERIMENTS]) ||
        {};
    return ['true', 'new', true].includes(experiments[key]);
}
exports.experimentEnabled = experimentEnabled;
