import { get, getRawValue } from '../../../utils/get';
import { getEventUrl } from '../calculated/get-event-url';
import { getStockStatus, STOCK_STATUS } from '../calculated/get-stock-status';
export var getJsonOffers = function (item, ticketsLength) {
    if (item === void 0) { item = {}; }
    if (ticketsLength === void 0) { ticketsLength = 0; }
    var tickets = item.tickets;
    if (!ticketsLength) {
        return '';
    }
    var offers = tickets.map(function (ticket) {
        var url = getEventUrl(item);
        var offer = {
            '@type': 'offer',
            name: get(ticket, 'name'),
            price: "".concat(getTicketFinalPrice(ticket)),
            priceCurrency: get(ticket, 'price.currency'),
            availability: "https://schema.org/".concat(getStockStatus(ticket)),
            validFrom: get(item, 'event.created'),
        };
        if (url) {
            offer.url = url;
        }
        return offer;
    });
    var lowPrice = get(item, 'event.registration.ticketing.lowestTicketPrice.amount');
    var json = {
        '@type': 'AggregateOffer',
        highPrice: get(item, 'event.registration.ticketing.highestTicketPrice.amount') ||
            lowPrice,
        lowPrice: lowPrice,
        offerCount: "".concat(ticketsLength),
        priceCurrency: get(item, 'event.registration.ticketing.currency'),
        availability: tickets.some(function (ticket) { return getStockStatus(ticket) === STOCK_STATUS.IN_STOCK; })
            ? "https://schema.org/".concat(STOCK_STATUS.IN_STOCK)
            : "https://schema.org/".concat(STOCK_STATUS.SOLD_OUT),
        validFrom: get(item, 'event.created'),
        url: get(item, 'event.eventPageUrl.base') +
            get(item, 'event.eventPageUrl.path'),
    };
    if (offers.length) {
        json.offers = offers;
    }
    return json;
};
export var getTicketFinalPrice = function (ticket) {
    return parseFloat(getRawValue(ticket, 'finalPrice.amount') || 0);
};
export var getTicketPrice = function (ticket) {
    return parseFloat(getRawValue(ticket, 'price.amount') || 0);
};
