"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var index_1 = require("../index");
var schema_types_1 = require("./schema-types");
var IDs = index_1.adapter.IDs;
exports.default = {
    type: schema_types_1.SCHEMA_TYPES.RESTAURANTS_MENU_PAGE_IMAGES,
    schema: {
        '@context': 'https://schema.org/',
        images: "{{".concat(IDs.SD_JSON_IMAGES, "}}"),
    },
};
