import React, { PureComponent } from 'react';
import { bool, func, string, object } from 'prop-types';
import { Interpolate, translate, Trans } from 'react-i18next';
import { ToggleSwitch, Composites, RichText, Divider } from '@wix/wix-base-ui';

import * as BI_TYPES from '../../../core/bi/action-types';

class BasicPanelIndex extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    isIndexEnabled: bool.isRequired,
    isIndexEnabledFromSite: bool.isRequired,
    isIndexEnabledFromPermission: bool.isRequired,
    isIndexEnabledFromUserPattern: bool.isRequired,
    wixSeoSettingsUrl: string.isRequired,
    wixSeoPatternsUrl: string.isRequired,
    logBiEvent: func.isRequired,
    t: func.isRequired,
    experiments: object.isRequired,
  };

  render() {
    const { onChange, isIndexEnabled, t } = this.props;
    const warning = this._getIndexEnabledWarning();
    const value = warning ? false : isIndexEnabled;
    return (
      <div>
        <Composites.ToggleSwitch>
          <ToggleSwitch
            dataHook="basic-panel-index-toggle"
            disabled={warning}
            label={t('adv-seo.basic-panel.page-index.label')}
            value={value}
            onChange={onChange}
            shouldTranslate={false}
            automationId="seo-index-toggle"
          />
        </Composites.ToggleSwitch>
        {warning}
      </div>
    );
  }
  _getIndexEnabledWarning = () => {
    const {
      isIndexEnabledFromSite,
      isIndexEnabledFromPermission,
      experiments,
      t,
    } = this.props;
    let warning = false;
    if (!isIndexEnabledFromPermission) {
      warning = (
        <>
          <Divider long={false} />
          <Composites.RichText>
            <RichText>
              <p>{t('adv-seo.basic-panel.page-index.hidden-permission')}</p>
            </RichText>
          </Composites.RichText>
        </>
      );
    } else if (!isIndexEnabledFromSite) {
      warning = (
        <>
          <Divider long={false} />
          <Composites.RichText>
            <RichText>
              <p>
                <Trans
                  i18nKey="adv-seo.basic-panel.page-index.hidden.site-settings"
                  components={[
                    <a onClick={this._onOpenSeoWizard}>
                      Go to site preferences
                    </a>,
                  ]}
                />
              </p>
            </RichText>
          </Composites.RichText>
        </>
      );
    }
    return warning;
  };

  _onOpenSeoWizard = () => {
    const { wixSeoSettingsUrl, logBiEvent } = this.props;
    window.open(wixSeoSettingsUrl, '_blank');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.basic-panel.page-index-in-google',
      linkType: 'general',
      linkSource: 'panel',
    });
  };
  _onOpenSeoPatterns = () => {
    const { wixSeoPatternsUrl, logBiEvent } = this.props;
    window.open(wixSeoPatternsUrl, '_blank');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.basic-panel.page-seo-patterns',
      linkType: 'general',
      linkSource: 'panel',
    });
  };
}

export default translate()(BasicPanelIndex);
