"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMenuItems = void 0;
var get_1 = require("../../../utils/get");
var getMenuItems = function (item) {
    var sections = (0, get_1.getRawValue)(item, 'menu.sections');
    if (!sections) {
        return [];
    }
    return sections.map(function (_a) {
        var items = _a.items;
        return items;
    }).flat();
};
exports.getMenuItems = getMenuItems;
