// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PLpiEn{width:430px;height:42px;border-radius:6px;border:solid 1px;background-color:#ffffff;border-color:#dfe5eb;display:flex;flex-direction:row;align-items:center;justify-content:space-between;margin-bottom:6px}.PLpiEn:hover{background-color:#eaf7ff}.xWhTzI{padding-left:12px;display:flex;overflow:hidden}.i6kYTM{margin-right:12px}.l83uWb{color:#2b5672}.jYKUlz{color:#7a92a5;font-weight:300;font-family:HelveticaNeueW01-45;font-stretch:normal;font-style:normal;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.hkPRvq{color:#bcbcbc}._WASkL{padding-right:12px;display:flex;flex-direction:row;align-items:center;justify-content:center}.z6T0IZ{margin-left:8px}.McAdLX{margin-left:10px}.TIpBrM{justify-content:center;display:flex}.lKcyQD{color:#7a92a5;width:18px;height:17px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `PLpiEn`,
	"text-container": `xWhTzI`,
	"textContainer": `xWhTzI`,
	"text-box": `i6kYTM`,
	"textBox": `i6kYTM`,
	"property-text": `l83uWb`,
	"propertyText": `l83uWb`,
	"value-text": `jYKUlz`,
	"valueText": `jYKUlz`,
	"disabled-value-text": `hkPRvq`,
	"disabledValueText": `hkPRvq`,
	"icons-container": `_WASkL`,
	"iconsContainer": `_WASkL`,
	"action-title": `z6T0IZ`,
	"actionTitle": `z6T0IZ`,
	"context-menu-button-container": `McAdLX`,
	"contextMenuButtonContainer": `McAdLX`,
	"disable-container": `TIpBrM`,
	"disableContainer": `TIpBrM`,
	"visibile-hidden-icon": `lKcyQD`,
	"visibileHiddenIcon": `lKcyQD`
};
export default ___CSS_LOADER_EXPORT___;
