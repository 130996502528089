import { ITEM_TYPES } from './ItemTypes';
// Item types that integrated with multilingual
var MULTILINGUAL_TYPES = [
    ITEM_TYPES.BLOG_POST,
    ITEM_TYPES.BLOG_CATEGORY,
    ITEM_TYPES.BLOG_ARCHIVE,
    ITEM_TYPES.BLOG_TAGS,
    ITEM_TYPES.BLOG_HASHTAGS,
    ITEM_TYPES.STORES_PRODUCT,
    ITEM_TYPES.STORES_CATEGORY,
    ITEM_TYPES.CHALLENGES_PAGE,
    ITEM_TYPES.EVENTS_PAGE,
    ITEM_TYPES.STATIC_PAGE,
    ITEM_TYPES.STATIC_PAGE_V2,
    ITEM_TYPES.MEMBERS_AREA_PROFILE,
    ITEM_TYPES.RESTAURANTS_MENU_PAGE,
    ITEM_TYPES.WIX_DATA_PAGE_ITEM,
];
export var isMultilingualType = function (type) { return MULTILINGUAL_TYPES.includes(type); };
var NON_MULTILINGUAL_STATIC_PAGES = [
    'menu', // Resturants menus
];
var EXCLUDE_MULTILINGUAL_TAGS = [
    ITEM_TYPES.BLOG_ARCHIVE,
    ITEM_TYPES.BLOG_TAGS,
    ITEM_TYPES.BLOG_HASHTAGS,
];
export var skipMultilingualTagsForTpaPageId = function (type, tpaPageId) {
    return EXCLUDE_MULTILINGUAL_TAGS.includes(type) ||
        (type === ITEM_TYPES.STATIC_PAGE_V2 &&
            NON_MULTILINGUAL_STATIC_PAGES.includes(tpaPageId));
};
