// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fkC3SH{padding-top:12px;margin-left:12px}.lsppF0{padding-bottom:0px}._sfCNh{padding-top:0px;margin-left:24px}.FcBiaC{margin-left:24px;margin-bottom:9px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tags-list-container": `fkC3SH`,
	"tagsListContainer": `fkC3SH`,
	"tags-list-composite": `lsppF0`,
	"tagsListComposite": `lsppF0`,
	"custom-tags-list": `_sfCNh`,
	"customTagsList": `_sfCNh`,
	"add-new-tag-container": `FcBiaC`,
	"addNewTagContainer": `FcBiaC`
};
export default ___CSS_LOADER_EXPORT___;
