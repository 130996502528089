import { getSiteStructure } from '@wix/advanced-seo-utils';
import {
  PageSegment,
  SortOrder,
} from '@wix/ambassador-viewer-structure-v1-site-structure-item/types';

export const getSiteStructurePages = async ({ httpClient }) => {
  const siteStructure = await getSiteStructure({
    httpClient,
    pageVersion: PageSegment.PUBLISHED,
    sortOrder: SortOrder.DESC,
    filters: [
      {
        itemTypeIdentifier: 'static_page',
        sourceId: 'editor',
        configId: '',
      },
    ],
  });
  const pages =
    siteStructure?.static_page?.map(({ itemId: pageId, path: slug }) => ({
      pageId,
      slug,
    })) ?? [];
  return pages;
};
