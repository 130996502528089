"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventStatus = exports.EVENT_STATUS = void 0;
var get_1 = require("../../../utils/get");
var CANCELED_STATUS = 'CANCELED';
exports.EVENT_STATUS = {
    CANCELED: 'EventCancelled',
    SCHEDULED: 'EventScheduled',
};
var getEventStatus = function (item) {
    if (item === void 0) { item = {}; }
    return item.event &&
        ((0, get_1.get)(item, 'event.status') === CANCELED_STATUS
            ? exports.EVENT_STATUS.CANCELED
            : exports.EVENT_STATUS.SCHEDULED);
};
exports.getEventStatus = getEventStatus;
