import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
export var IDs = {
    SD_JSON_RENDER: 'breadcrumb-component.structured-data.render',
};
var SchemaTypes = {
    BREADCRUMBS_COMPONENT_ITEM_LIST: 'breadcrumbs-component-item-list-preset',
};
var userVisible = [];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function () {
        var _a;
        return _a = {},
            _a[IDs.SD_JSON_RENDER] = { '@context': 'https://schema.org' },
            _a;
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
