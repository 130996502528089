"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.identify = void 0;
var Identifiers_1 = require("../../types/Identifiers");
var TagSchemas_1 = require("../../types/TagSchemas");
var validate_tag_structure_1 = require("../../tags/utils/validate-tag-structure");
var filter_identifier_keys_1 = require("../../tags/filters/filter-identifier-keys");
/**
 * Tries to identify a tag and either returns identifier or `undefined`.
 *
 * @param {*} tag to identify.
 */
function identify(tag, ignoreList) {
    if (ignoreList === void 0) { ignoreList = []; }
    if (!tag) {
        return undefined;
    }
    return findTagWithIdentifiers(tag, ignoreList);
}
exports.identify = identify;
var findTagWithIdentifiers = function (tag, ignoreList) {
    var identifierKeys = (0, filter_identifier_keys_1.filterIdentifierKeys)(Identifiers_1.IDENTIFIERS, ignoreList);
    return identifierKeys.find(function (identifier) {
        var schema = TagSchemas_1.TAG_SCHEMAS[identifier];
        if (!schema) {
            return false;
        }
        return (0, validate_tag_structure_1.validateTagStructure)(tag, schema);
    });
};
