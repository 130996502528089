import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { Composites } from '@wix/wix-base-ui';
import Popup from './popup';
import { getBoundingRect, getSafePanelPosition } from '../ui-utils';

export const PopupWrapper = ({ onClickOutside, containerId, children }) => {
  const [position, setPosition] = useState();
  useEffect(() => {
    const panelPosition = getSafePanelPosition(
      getBoundingRect(containerId),
      getBoundingRect('rendered-component'),
    );
    setPosition(panelPosition);
  }, [containerId]);

  return (
    <Popup onClickOutside={onClickOutside}>
      <div
        id="rendered-component"
        onMouseDown={(event) => event.stopPropagation()}
        style={{
          ...(position || {}),
          position: 'absolute',
          zIndex: 2000,
        }}
      >
        <Composites.PopupMediumSize>{children}</Composites.PopupMediumSize>
      </div>
    </Popup>
  );
};

PopupWrapper.propType = {
  containerId: string.isRequired,
  onClickOutside: func,
};
