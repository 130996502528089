"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../adapters/utils");
var restaurants_menu_page_1 = require("../restaurants-menu-page");
var IDs = restaurants_menu_page_1.adapter.IDs, getSdStatus = restaurants_menu_page_1.adapter.getSdStatus, getData = restaurants_menu_page_1.adapter.getData;
var SchemaTypes = {
    RESTAURANTS_MENU_COMPONENT_IMAGES: 'restaurants-menu-component-images',
};
var userVisible = [IDs.SD_JSON_IMAGES];
var userVisibleSdOnly = [IDs.IMAGES_SD];
exports.default = {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var data = getData(item);
        return _a = {},
            _a[IDs.SD_JSON_IMAGES] = data[IDs.SD_JSON_IMAGES],
            _a[IDs.DISABLE_IMAGES_SCHEMA] = data[IDs.DISABLE_IMAGES_SCHEMA],
            _a;
    },
    getSdStatus: function (item, data) { return getSdStatus(item, data); },
    getLegacySeoBlob: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(IDs, userVisibleSdOnly); },
};
