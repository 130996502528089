import { HttpClient } from '@wix/http-client';
import { CREATE_REDIRECT_RESULT_STATUS } from '@wix/advanced-seo-utils';
import { getPagesWithoutPreventAutoRedirects } from './get-pages-without-prevent-auto-redirects';

export const getHttpClient = ({ metaSiteInstance }) =>
  new HttpClient({
    headers: {
      Authorization: metaSiteInstance,
    },
  });

export const getMetaSiteInstance = ({ scope, metaSiteAppId }) =>
  scope.editorApi.dsRead.platform.getAppDataByApplicationId(metaSiteAppId)
    .instance;

export const getPagesFilteredByNotPreventAutoRedirect = ({
  pages,
  pagesData,
}) => {
  try {
    const pagesAdvancedSeoData = pagesData.map(
      ({ id, advancedSeoData = '{}' }) => ({
        pageId: id,
        advancedSeoData: JSON.parse(advancedSeoData),
      }),
    );
    const pagesWithoutPreventAutoRedirects =
      getPagesWithoutPreventAutoRedirects({
        pagesAdvancedSeoData,
      });
    return pages.filter(({ pageId }) =>
      pagesWithoutPreventAutoRedirects.includes(pageId),
    );
  } catch (e) {
    console.error(e);
  }
};

export const getRedirect = ({ redirectResult }) =>
  redirectResult?.request?.redirect;

export const classifyCreateRedirectResults = (createRedirectResults) => {
  return {
    successfulRedirectResults: createRedirectResults.filter(
      (result) => result?.status === CREATE_REDIRECT_RESULT_STATUS.OK,
    ),
    failedRedirectResults: createRedirectResults.filter(
      (result) => result?.status === CREATE_REDIRECT_RESULT_STATUS.ERROR,
    ),
    conflictedRedirectResults: createRedirectResults.filter(
      (result) => result?.status === CREATE_REDIRECT_RESULT_STATUS.CONFLICT,
    ),
  };
};
