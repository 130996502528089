/* eslint-disable camelcase, prettier/prettier */
import * as BI_TYPES from './action-types';

export default {
  [BI_TYPES.PROMOTE_SEO_PANEL_VIEW]: props => ({
             evid: 2,
              tab: props.tabName,
         language: props.language,
    main_language: props.mainLanguage,
          page_id: props.pageId,
     is_home_page: props.isHomePage,
      open_source: props.openSource,
     num_of_pages: props.numPages,
  }),
  [BI_TYPES.PROMOTE_SEO_FIELD_UPDATE]: ({
    tabName: tab,
    language,
    mainLanguage: main_language,
    pageId: page_id,
    isHomePage: is_home_page,
    fieldName: field_name,
    updatedValue,
    updateType: update_type
  }) => {
    let updated_value = updatedValue;
    if (Array.isArray(updatedValue)) {
      try{
        updated_value = updatedValue.map(({value}) => JSON.stringify(value))

      } catch (error) {
        updated_value = updatedValue
      }
    }
    return ({
    evid: 3,
    tab,
    language,
    main_language,
    page_id,
    is_home_page,
    field_name,
    updated_value,
    update_type,
  })},
  [BI_TYPES.PROMOTE_SEO_CTA_CLICK]: ({
    tabName: tab,
    language,
    mainLanguage: main_language,
    pageId: page_id,
    isHomePage: is_home_page,
    linkType: link_type,
    linkName: link_name,
    linkSource: link_source,
  }) => ({
    evid: 4,
    tab,
    language,
    main_language,
    page_id,
    is_home_page,
    link_type,
    link_name,
    link_source,
  }),
  [BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW]: ({
    tabName: tab,
    language,
    mainLanguage: main_language,
    pageId: page_id,
    isHomePage: is_home_page,
    tooltipName: tooltip_name,
  }) => ({
    evid: 5,
    tab,
    language,
    main_language,
    page_id,
    is_home_page,
    tooltip_name,
  }),
  [BI_TYPES.PROMOTE_SEO_TOOLTIP_CTA_CLICK]: ({
    language,
    mainLanguage: main_language,
    pageId: page_id,
    tabName: tab,
    isHomePage: is_home_page,
    ctaName: cta_name,
    tooltipName: tooltip_name,
  }) => ({
    evid: 15,
    main_language,
    language,
    page_id,
    tab,
    is_home_page,
    cta_name,
    tooltip_name,
  }),
  [BI_TYPES.PROMOTE_SEO_ERROR_VIEW]: ({
    tabName: tab,
    language,
    mainLanguage: main_language,
    pageId: page_id,
    isHomePage: is_home_page,
    errorName: error_name,
    errorType: error_type,
  }) => ({
    evid: 6,
    tab,
    language,
    main_language,
    page_id,
    is_home_page,
    error_name,
    error_type,
  }),
  [BI_TYPES.PROMOTE_SEO_ACTION_CLICK]: ({
    tabName: tab,
    fieldName: field_name,
    action,
    type,
    isHomePage: is_home_page,
    language,
    mainLanguage: main_language,
    pageId: page_id,
  }) => ({
    evid: 7,
    tab,
    field_name,
    action,
    type,
    is_home_page,
    language,
    main_language,
    page_id
  }),
  [BI_TYPES.PROMOTE_SEO_CLOSE_TAG_SETTINGS]: ({
    tabName: tab,
    fieldName: field_name,
    closeType: type,
  }) => ({
    evid: 8,
    tab,
    field_name,
    type,
  }),
  [BI_TYPES.PROMOTE_SEO_MODAL_VIEW]: ({
    modalName: modal_name,
  }) => ({
    evid: 10,
    modal_name,
  }),
  [BI_TYPES.PROMOTE_SEO_MODAL_CTA_CLICK]: ({
    modalName: modal_name,
    ctaType: cta_type,
  }) => ({
    evid: 9,
    modal_name,
    cta_type,
  }),
  [BI_TYPES.PROMOTE_SEO_PANEL_API_FAILED_CALL]: props => ({
    evid: 12,
    language: props.language,
    main_language: props.mainLanguage,
    api_name: props.apiName,
    error_type: props.errorType,
  }),
  [BI_TYPES.PROMOTE_SEO_PANEL_SECTION_EXPAND_COLLAPSE]: ({
    tabName: tab,
    language,
    mainLanguage: main_language,
    pageId: page_id,
    isHomePage: is_home_page,
    action,
    sectionName: section_name,
  }) => ({
    evid: 13,
    tab,
    language,
    main_language,
    page_id,
    is_home_page,
    action,
    section_name,
  }),
  [BI_TYPES.PROMOTE_SEO_PANEL_COMPONENT_VIEW]: ({
    openSource: open_source,
    pageId: page_id,
    field,
    component,
    tabName: tab,
  }) => ({
    evid: 14,
    open_source,
    page_id,
    field,
    component,
    tab
  }),
  [BI_TYPES.PROMOTE_SEO_PANEL_NOTIFICATION_VIEW]: ({
    notificationType	: notification_type	,
  }) => ({
    evid: 16,
    notification_type,
  }),
  [BI_TYPES.PROMOTE_SEO_PANEL_NOTIFICATION_CTA_CLICK]: ({
    notificationType	: notification_type	,
    ctaType: cta_type,
    isPrimaryCta: is_primary_cta,
  }) => ({
    evid: 17,
    notification_type,
    cta_type,
    is_primary_cta,
  }),
  [BI_TYPES.SEO_PANEL_AUTO_REDIRECT_REQUEST]: ({
    context,
    originUrl: origin_url,
    destinationUrl: destination_url,
  }) => ({
    evid: 557,
    context,
    origin_url,
    destination_url,
  }),
  [BI_TYPES.SEO_PANEL_AUTO_REDIRECT_SUCCESS]: ({
    context,
    originUrl: origin_url,
    destinationUrl: destination_url,
  }) => ({
    evid: 558,
    context,
    origin_url,
    destination_url,
  }),
  [BI_TYPES.SEO_PANEL_AUTO_REDIRECT_FAILED]: ({
    context,
    originUrl: origin_url,
    destinationUrl: destination_url,
    errorType: error_type
  }) => ({
    evid: 559,
    context,
    origin_url,
    destination_url,
    error_type,
  }),
  [BI_TYPES.SEO_PANEL_COLLAPSE]: ({
    context,
    pageType: page_type,
    itemType: item_type,
    numberOfChanges: number_of_changes,
    data,
  }) => ({
    evid: 561,
    context,
    page_type,
    item_type,
    number_of_changes /* depracated */,
    num_of_changes: number_of_changes,
    data,
  }),
  [BI_TYPES.SEO_PANEL_HIERARCHY_DROP_DOWN_EXPAND_COLLAPSE]: ({
    context,
    numberOfAccessiblePages: number_of_accessible_pages,
    numberOfPagesInDropdown: number_of_pages_in_dropdown,
    type,
  }) => ({
    evid: 565,
    context,
    number_of_accessible_pages,
    number_of_pages_in_dropdown,
    type,
  })
}
