import { get } from '../../../../utils/get';
import { buildOgImagePreview } from '../../../../utils/build-og-image-preview';
export var getMainImageUrl = function (item) {
    if (item === void 0) { item = {}; }
    var imageUrl = get(item, 'program.settings.description.media.image.url');
    if (imageUrl.startsWith('media/')) {
        imageUrl = imageUrl.substring(6);
    }
    var width = get(item, 'program.settings.description.media.image.width');
    var height = get(item, 'program.settings.description.media.image.height');
    return (item.program &&
        buildOgImagePreview(imageUrl, width !== '0' ? width : undefined, height !== '0' ? height : undefined, 'fill'));
};
