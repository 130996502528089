export const tWithWizRename = (
  key: string,
  t: (key: string) => string,
  experiments: { enabled: (key: string) => boolean },
) => {
  const isWizRenameEnabled = experiments.enabled('specs.seo-wiz.RenameTest');
  if (!isWizRenameEnabled) {
    return t(key);
  }

  const updatedKey = `${key}.setup-checklist`;
  const isKeyExist = t(updatedKey) !== updatedKey;
  return isKeyExist ? t(updatedKey) : t(key);
};
