import i18next from 'i18next';

export default function i18n(locale) {
  return new Promise((resolve) => {
    i18next
      .use({
        type: 'backend',
        read: (language, namespace, callback) => {
          return import(`../../../../locales/messages_${language}.json`)
            .then((translation) => callback(null, translation))
            .catch((error) => callback(error));
        },
      })
      .init(
        {
          lng: locale,
          fallbackLng: 'en',
          keySeparator: false,
          interpolation: {
            escapeValue: false,
          },
        },
        (_, t) => {
          resolve(t);
        },
      );
  });
}
