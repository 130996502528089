import * as BI_TYPES from '../../../../core/bi/action-types';
import { initBiLogger } from '../../../initializers';
import { MESSAGES } from './consts';

function handlePostMessage({ redirectResults }) {
  return (event) => {
    if (
      event.origin === 'https://editor.wix.com' &&
      typeof event.data === 'string'
    ) {
      try {
        const message = JSON.parse(event.data);
        const { action } = message;
        if (action === MESSAGES.GET_AUTO_REDIRECT_RESULTS) {
          const messageToSend = {
            action: MESSAGES.AUTO_REDIRECT_RESULTS,
            data: { redirectResults },
          };
          window.postMessage(JSON.stringify(messageToSend), '*');
          window.removeEventListener(
            'message',
            window.seoAutoRedirectsContext.postMessageHandler,
          );
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
}

const openRedirectsManagerOverEditor = ({ scope, redirectResults }) => {
  window.seoAutoRedirectsContext = {
    ...window.seoAutoRedirectsContext,
    postMessageHandler: handlePostMessage({ redirectResults }),
  };
  window.addEventListener(
    'message',
    window.seoAutoRedirectsContext.postMessageHandler,
  );
  scope.editorApi.account.openSettings({
    path: '/seo-home/redirects?consumeAutoRedirects=true',
  });
};

export const showEditorNotification = ({
  scope,
  notificationData: { title = '', message, type, linkCaption },
  redirectResults,
}) => {
  const notificationType = 'auto-redirects-for-static-pages-could-not-be-added';
  const logBiEvent = initBiLogger({
    msid: scope.editorApi.dsRead.generalInfo.getMetaSiteId(),
  }).logBiEvent;
  logBiEvent(BI_TYPES.PROMOTE_SEO_PANEL_NOTIFICATION_VIEW, {
    notificationType,
  });
  scope.editorApi.showUserActionNotification({
    title,
    message,
    type,
    link: {
      caption: linkCaption,
      onNotificationLinkClick: () => {
        logBiEvent(BI_TYPES.PROMOTE_SEO_PANEL_NOTIFICATION_CTA_CLICK, {
          notificationType,
          ctaType: 'review',
          isPrimaryCta: true,
        });
        openRedirectsManagerOverEditor({
          scope,
          redirectResults,
        });
      },
    },
    shouldTranslate: false,
  });
};
