var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { getBasicVideosObject } from '../../adapters/structure-data-utils';
export var IDs = {
    SD_JSON_RENDER: 'video-component.structured-data.render',
};
var SchemaTypes = {
    VIDEO_COMPONENT: 'video-component-preset',
};
var userVisible = [];
var getStructuredData = function (item) {
    var _a;
    var videosSchemas = ((_a = getBasicVideosObject(item === null || item === void 0 ? void 0 : item.video)) === null || _a === void 0 ? void 0 : _a.videos) || [];
    var videos = videosSchemas.filter(function (_a) {
        var thumbnailUrl = _a.thumbnailUrl, name = _a.name, uploadDate = _a.uploadDate;
        return [thumbnailUrl, name, uploadDate].every(Boolean);
    });
    if (!videos.length) {
        return {};
    }
    else if (videos.length === 1) {
        return videos[0];
    }
    else {
        return {
            '@type': 'ItemList',
            itemListElement: videos.map(function (video, index) { return (__assign(__assign({}, video), { position: index + 1 })); }),
        };
    }
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.SD_JSON_RENDER] = __assign({ '@context': 'https://schema.org' }, getStructuredData(item)),
            _a;
    },
    getSdStatus: function () { return SD_STATUS.ENABLED; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
