// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N14ZT4>div{justify-content:flex-start}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent-page-drop-down-container": `N14ZT4`,
	"parentPageDropDownContainer": `N14ZT4`
};
export default ___CSS_LOADER_EXPORT___;
