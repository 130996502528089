import { KEYS } from './maps';

export const getAdvancedTagValue = (scheme, data = {}) => {
  const {
    generalIdentifier: currentTagGeneralIdentifier = '',
    label: currentTagLabel = '',
  } = data;
  return scheme?.find(
    ({ generalIdentifier, label }) =>
      generalIdentifier === currentTagGeneralIdentifier &&
      label === currentTagLabel,
  )?.value;
};

export const getValueFromScheme = (key, data, scheme = {}) =>
  key === KEYS.ADVANCED_TAGS
    ? getAdvancedTagValue(scheme[KEYS.ADVANCED_TAGS], data)
    : scheme[key]?.value;
