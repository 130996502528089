import React from 'react';
import { func } from 'prop-types';
import { translate } from 'react-i18next';
import classnames from 'classnames';

import { MessageModal } from '@wix/wix-base-ui';
import { PROMOTE_SEO_MODAL_CTA_CLICK } from '../../../../../core/bi/action-types';
import { MODAL_TYPES, BI_DATA } from '../constants';
import styles from './confirm-remove-schema-modal.scss';

const ConfirmRemoveSchemaModal = ({ logBiEvent, onClose, onApply, t }) => (
  <MessageModal
    theme="destructive"
    dataHook="confirm-remove-schema-modal"
    className={styles.root}
    primaryButtonText={t(
      'adv-seo.adv-panel.structured-data.confirm-remove-schema-modal.footer.cta',
    )}
    primaryButtonProps={{
      className: classnames('btn-danger-primary', 'btn-md', styles.applyButton),
    }}
    secondaryButtonText={t(
      'adv-seo.adv-panel.structured-data.confirm-remove-schema-modal.footer.cancel',
    )}
    secondaryButtonProps={{
      className: classnames('btn-danger-secondary', 'btn-md'),
    }}
    primaryButtonOnClick={() => {
      logBiEvent(PROMOTE_SEO_MODAL_CTA_CLICK, {
        modalName: MODAL_TYPES.WARNING,
        ctaType: BI_DATA.REMOVE_ACTION,
      });
      onApply();
      onClose();
    }}
    secondaryButtonOnClick={() => {
      logBiEvent(PROMOTE_SEO_MODAL_CTA_CLICK, {
        modalName: MODAL_TYPES.WARNING,
        ctaType: BI_DATA.CANCEL_ACTION,
      });

      onClose();
    }}
    onCloseButtonClick={() => {
      logBiEvent(PROMOTE_SEO_MODAL_CTA_CLICK, {
        modalName: MODAL_TYPES.WARNING,
        ctaType: BI_DATA.CLOSE_ACTION,
      });

      onClose();
    }}
    title={t(
      `adv-seo.adv-panel.structured-data.confirm-remove-schema-modal.header.label`,
    )}
  />
);

ConfirmRemoveSchemaModal.propTypes = {
  logBiEvent: func.isRequired,
  onApply: func.isRequired,
  onClose: func.isRequired,
  t: func.isRequired,
};

export default translate(null, { wait: true })(ConfirmRemoveSchemaModal);
