import React, { useEffect } from 'react';
import { func, bool, string, object } from 'prop-types';
import styles from '../panels.scss';
import { Revert } from '@wix/wix-ui-icons-common';
import { translate } from 'react-i18next';
import { Tooltip, IconButton, RichText } from '@wix/wix-base-ui';
import { PROMOTE_SEO_PANEL_COMPONENT_VIEW } from '../../../core/bi/action-types';
import { RESET_TO_PATTERN_BUTTON } from '../../../core/utils/consts';
import { useExperiments } from '@wix/wix-experiments-react';

const ResetToPatternButton = ({
  onClick = () => {},
  topAlignment = false,
  t,
  logBiEvent,
  biKey = '',
}) => {
  const { experiments } = useExperiments();
  useEffect(() => {
    logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
      field: biKey,
      component: RESET_TO_PATTERN_BUTTON,
    });
  }, [biKey, logBiEvent]);
  return (
    <div
      className={
        topAlignment
          ? styles.resetButtonTopContainer
          : styles.resetButtonCenterContainer
      }
    >
      <Tooltip
        content={
          <RichText type="T02">
            {t('adv-seo.common.reset-to-pattern.tooltip.label.site-settings')}
          </RichText>
        }
      >
        <IconButton
          skin="light"
          size="medium"
          onClick={(e) => onClick(e)}
          className={styles.resetButton}
        >
          <Revert />
        </IconButton>
      </Tooltip>
    </div>
  );
};

ResetToPatternButton.propTypes = {
  onClick: func,
  topAlignment: bool,
  t: func.isRequired,
  logBiEvent: func.isRequired,
  biKey: string.isRequired,
  experiments: object.isRequired,
};

export default translate()(ResetToPatternButton);
