// TODO: remove once groups post finish migration to new architecture
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { get, getRawValue } from '../../utils/get';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { sanitizeUrl } from '../../utils/sanitize-url';
var getPostImage = function (item) {
    var _a = getRawValue(item, 'postData.images.0') || {}, _b = _a.imageUrl, imageUrl = _b === void 0 ? '' : _b, _c = _a.imageWidth, imageWidth = _c === void 0 ? '' : _c, _d = _a.imageHeight, imageHeight = _d === void 0 ? '' : _d;
    return { imageUrl: imageUrl, imageWidth: imageWidth, imageHeight: imageHeight };
};
var getPublishDate = function (item) {
    var publishDate = get(item, 'post.createdAt');
    if (!publishDate) {
        return '';
    }
    return new Date(parseInt(publishDate, 10)).toISOString();
};
export var getDataLegacy = function (IDs, itemData) {
    var _a;
    var _b;
    var postContent = get(itemData, 'postData.content');
    var postImage = getPostImage(itemData);
    return _a = {},
        _a[IDs.ID] = ((_b = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _b === void 0 ? void 0 : _b.groupId) || '',
        _a[IDs.INDEX_PAGE] = getRawValue(itemData, "context.".concat(CONTEXT_PROPS.INDEX_PAGE)),
        _a[IDs.POST_CONTENT] = postContent,
        _a[IDs.POST_CONTENT_60] = postContent.substring(0, 60),
        _a[IDs.POST_CONTENT_200] = postContent.substring(0, 200),
        _a[IDs.GROUP_NAME] = get(itemData, 'group.details.title'),
        _a[IDs.POST_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.GROUPS_POST, itemData, sanitizeUrl(get(itemData, 'directUrl'))),
        _a[IDs.POST_AUTHOR] = get(itemData, 'post.createdBy.name'),
        _a[IDs.POST_PUBLISH_DATE] = getPublishDate(itemData),
        _a[IDs.POST_IMAGE_HEIGHT] = postImage === null || postImage === void 0 ? void 0 : postImage.imageHeight,
        _a[IDs.POST_IMAGE_WIDTH] = postImage === null || postImage === void 0 ? void 0 : postImage.imageWidth,
        _a[IDs.MAIN_IMAGE] = postImage === null || postImage === void 0 ? void 0 : postImage.imageUrl,
        _a;
};
