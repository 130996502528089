var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function getCustomTagsIndexArray(tags, ignoreDefaultTags) {
    if (ignoreDefaultTags === void 0) { ignoreDefaultTags = false; }
    return tags.reduce(function (acc, curr, index) {
        var shouldSkipDefault = curr.isDefault && ignoreDefaultTags;
        return curr.custom && !shouldSkipDefault ? __spreadArray(__spreadArray([], acc, true), [index], false) : acc;
    }, []);
}
