import { INITIAL_REQUEST_OPTIONS } from './consts';
import { convertRedirectToRequest } from './convert-redirect-to-request';
export var getAutoRedirectRequests = function (_a) {
    var _b = _a.redirects, redirects = _b === void 0 ? [] : _b;
    return redirects.map(function (redirect) {
        return convertRedirectToRequest({
            redirect: redirect,
            requestOptions: INITIAL_REQUEST_OPTIONS,
        });
    });
};
