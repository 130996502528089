import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { TextLabel, ContextMenuAction, ContextMenu } from '@wix/wix-base-ui';
import styles from './tag-item.scss';
import { func, object, string, array } from 'prop-types';
import RenderIf from '../../../../core/components/render-if';
import EditAdvancedTagPanel from '../advanced-tags/edit-advanced-tag-panel';
import CustomTagPanel from '../custom-tags/custom-tag-panel';
import { TAG_TYPE } from '../../../../core/utils/maps';
import classnames from 'classnames';
import map from 'lodash/map';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { PROMOTE_SEO_ACTION_CLICK } from '../../../../core/bi/action-types';

import { VisibileHidden } from '@wix/wix-ui-icons-common';

class TagItem extends Component {
  static propTypes = {
    tag: object.isRequired,
    tagType: string.isRequired,
    onApply: func.isRequired,
    onAction: func,
    logBiEvent: func.isRequired,
    openHelpCenter: func.isRequired,
    t: func.isRequired,
    actions: array,
    selector: string.isRequired,
    getDefaultPatternValue: func,
    itemType: string,
    siteDashboardURL: string,
    experiments: object,
  };

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      showActions: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { tag } = this.props;
    if (prevProps.tag && !isEqual(tag, prevProps.tag) && !tag.error) {
      this.setState({ editMode: false });
    }
  }

  render() {
    const {
      tag,
      tagType,
      t,
      logBiEvent,
      openHelpCenter,
      selector,
      onApply,
      getDefaultPatternValue = () => {},
      itemType,
      siteDashboardURL,
      experiments,
    } = this.props;

    return (
      <div
        id={selector}
        onMouseDown={this._onTagClick}
        data-aid="editor-tag-item"
      >
        <div
          className={styles.item}
          onMouseEnter={this._handleMouseEnter}
          onMouseLeave={this._handleMouseLeave}
          data-aid="editor-tag-item-content"
        >
          <div className={styles.textContainer}>
            <div className={classnames(styles.textBox, styles.propertyText)}>
              {tag.label}
            </div>
            <TextLabel
              className={`${classnames(styles.valueText)} ${
                tag.isDisabled && classnames(styles.disabledValueText)
              }`}
              value={
                tag.isDisabled
                  ? t('adv-seo.adv-panel.advanced-tags.hidden-tag.label')
                  : tag.value
              }
              shouldTranslate={false}
            />
          </div>
          {this._renderTagIcons()}
        </div>
        <RenderIf truthy={this.state.editMode}>
          <RenderIf truthy={tagType === TAG_TYPE.ADVANCED}>
            <EditAdvancedTagPanel
              data-aid="advanced-tag-panel"
              tag={tag}
              onClose={this._onClose}
              onApply={onApply}
              t={t}
              logBiEvent={logBiEvent}
              openHelpCenter={openHelpCenter}
              getDefaultPatternValue={getDefaultPatternValue}
              itemType={itemType}
              siteDashboardURL={siteDashboardURL}
            />
          </RenderIf>
          <RenderIf truthy={tagType === TAG_TYPE.CUSTOM}>
            <CustomTagPanel
              data-aid="custom-tag-panel"
              tag={tag}
              onClose={this._onClose}
              onApply={(data) =>
                onApply({ ...data, isEditMode: this.state.editMode })
              }
              t={t}
              logBiEvent={logBiEvent}
              openHelpCenter={openHelpCenter}
            />
          </RenderIf>
        </RenderIf>
      </div>
    );
  }

  _onContextMenuContainerMouseDown = (event) => {
    event.stopPropagation();

    const targetClass = get(event, 'target.attributes.class.nodeValue');
    if (targetClass === 'block-click-outside') {
      this.setState({ showActions: false });
    }
  };

  _onClose = () => {
    this.setState({ editMode: false });
  };

  _onTagClick = (event) => {
    const targetClass = get(event, 'target.attributes.class.nodeValue');
    if (targetClass !== 'block-click-outside') {
      const { logBiEvent, tagType, tag } = this.props;

      logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
        fieldName: tagType === TAG_TYPE.ADVANCED ? tag.label : 'customTags',
        action: 'tag settings',
        type: 'content',
      });
      !tag.isDisabled && this.setState({ editMode: true });
    } else {
      this.setState({ showActions: false });
    }
  };

  _renderDisabledIcon = () => {
    return (
      <div
        className={styles.disableContainer}
        data-aid="editor-tag-item-disabled-icon"
      >
        <VisibileHidden className={classnames(styles.visibileHiddenIcon)} />
      </div>
    );
  };

  _renderTagIcons = () => {
    const { actions, tag } = this.props;
    return (
      <div className={styles.iconsContainer}>
        {tag.isDisabled && this._renderDisabledIcon()}
        <RenderIf truthy={actions && actions.length && this.state.showActions}>
          <div
            className={classnames(styles.contextMenuButtonContainer)}
            onMouseDown={this._onContextMenuContainerMouseDown}
          >
            <ContextMenu
              alignment="RIGHT"
              data-hook="adv-seo.editor.tag-item.context-menu"
              className="flat"
            >
              {map(actions, this._renderAction)}
            </ContextMenu>
          </div>
        </RenderIf>
      </div>
    );
  };

  _renderAction = (action) => {
    const { t } = this.props;
    const dataHook = `adv-seo.editor.tag-item.context-action-${action}`;

    return (
      <ContextMenuAction
        key={action.id}
        onClick={() => this._onAction(action.id)}
        data-hook={dataHook}
        disabled={action.isDisabled}
      >
        {action.icon}
        <span className={classnames(styles.actionTitle)}>{t(action.name)}</span>
      </ContextMenuAction>
    );
  };

  _onAction = (actionId) => {
    const { logBiEvent, tag } = this.props;
    switch (actionId) {
      case 'edit':
        const { tagType } = this.props;
        logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
          fieldName: tagType === TAG_TYPE.ADVANCED ? tag.label : 'customTags',
          action: 'tag settings',
          type: 'CTA',
        });

        this.setState({ editMode: true, showActions: false });
        break;

      case 'toggle-hide': {
        const { onApply } = this.props;
        const newValue = !tag.isDisabled;
        onApply({
          value: tag.value,
          isDisabled: newValue,
        });
        logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
          fieldName: tag.label,
          action: newValue ? 'hide' : 'unhide',
        });
        break;
      }

      default:
        this.props.onAction && this.props.onAction(actionId);
        break;
    }
  };

  _handleMouseEnter = () => {
    this.setState({ showActions: true });
  };

  _handleMouseLeave = () => {
    this.setState({ showActions: false });
  };

  _onTagEdit = () => {
    this.setState({ editMode: true });
  };
}

export default translate()(TagItem);
