export var STATIC_PAGE_V2 = 'STATIC_PAGE_V2';
export var ITEM_TYPES = {
    DEFAULT: 'DEFAULT',
    STATIC_PAGE: 'STATIC_PAGE',
    STATIC_PAGE_V2: STATIC_PAGE_V2,
    STORES_PRODUCT: 'STORES_PRODUCT',
    FORUM_POST: 'FORUM_POST',
    FORUM_CATEGORY: 'FORUM_CATEGORY',
    PRO_GALLERY_ITEM: 'PRO_GALLERY_ITEM',
    BLOG_POST: 'BLOG_POST',
    BLOG_CATEGORY: 'BLOG_CATEGORY',
    BLOG_TAGS: 'BLOG_TAGS',
    BLOG_ARCHIVE: 'BLOG_ARCHIVE',
    GROUPS_PAGE: 'GROUPS_PAGE',
    GROUPS_POST: 'GROUPS_POST',
    EVENTS_PAGE: 'EVENTS_PAGE',
    CHALLENGES_PAGE: 'CHALLENGES_PAGE',
    SEARCH_PAGE: 'SEARCH_PAGE',
    BOOKINGS_SERVICE: 'BOOKINGS_SERVICE',
    BOOKINGS_CALENDAR: 'BOOKINGS_CALENDAR',
    BOOKINGS_FORM: 'BOOKINGS_FORM',
    BREADCRUMBS_COMPONENT: 'BREADCRUMBS_COMPONENT',
    BLOG_HASHTAGS: 'BLOG_HASHTAGS',
    RESTAURANTS_ORDER_PAGE: 'RESTAURANTS_ORDER_PAGE',
    MEMBERS_AREA_PROFILE: 'MEMBERS_AREA_PROFILE',
    VIDEO_COMPONENT: 'VIDEO_COMPONENT',
    PORTFOLIO_COLLECTIONS: 'PORTFOLIO_COLLECTIONS',
    PORTFOLIO_PROJECTS: 'PORTFOLIO_PROJECTS',
    GIFT_CARD: 'GIFT_CARD',
    SCHEDULE_PAGE: 'SCHEDULE_PAGE',
    WIX_DATA_PAGE_ITEM: 'WIX_DATA_PAGE_ITEM',
    REVIEWS_COMPONENT: 'REVIEWS_COMPONENT',
    STORES_CATEGORY: 'STORES_CATEGORY',
    STORES_GALLERY_COMPONENT: 'STORES_GALLERY_COMPONENT',
    RESTAURANTS_MENU_PAGE: 'RESTAURANTS_MENU_PAGE',
    RESTAURANTS_MENU_COMPONENT: 'RESTAURANTS_MENU_COMPONENT',
    MEMBERS_AREA_PROFILE_TABS: 'MEMBERS_AREA_PROFILE_TABS',
    PROGRAMS_COMPONENT: 'PROGRAMS_COMPONENT',
    SERVICES_COMPONENT: 'SERVICES_COMPONENT',
    PAYMENT_PAGE: 'PAYMENT_PAGE',
    THANK_YOU_PAGE: 'THANK_YOU_PAGE',
};
// Reminder: please add the type under ItemTypes.typed.ts and inside README.md
