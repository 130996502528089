var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { adapters } from '../adapters/index';
var schemaTypesArray = Object.values(adapters).map(function (_a) {
    var SchemaTypes = _a.SchemaTypes;
    return SchemaTypes;
});
export var SCHEMA_PRESET_TYPES = Object.assign.apply(Object, __spreadArray([{}], schemaTypesArray, false));
