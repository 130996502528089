import React, { PureComponent } from 'react';
import { func, array, string, object } from 'prop-types';
import { translate } from 'react-i18next';
import { Composites, TextLabel } from '@wix/wix-base-ui';

import TagItem from '../edit-tag-items/tag-item';
import { TAG_TYPE } from '../../../../core/utils/maps';
import * as BI_TYPES from '../../../../core/bi/action-types';
import classnames from 'classnames';
import styles from '../advanced-panel-tags.scss';

import map from 'lodash/map';

import { Edit, Visible, Hidden } from '@wix/wix-ui-icons-common';

const getActions = ({ isDisabled }) => [
  {
    id: 'edit',
    icon: <Edit />,
    name: 'adv-seo.adv-panel.custom-tags.edit-tag',
    isDisabled,
  },
  {
    id: 'toggle-hide',
    ...(isDisabled
      ? {
          icon: <Visible />,
          name: 'adv-seo.adv-panel.advanced-tags.unhide-tag',
        }
      : {
          icon: <Hidden />,
          name: 'adv-seo.adv-panel.advanced-tags.hide-tag',
        }),
  },
];

class AdvancedPanelAdvancedTags extends PureComponent {
  static propTypes = {
    tagsList: array.isRequired,
    onApply: func.isRequired,
    logBiEvent: func.isRequired,
    openHelpCenter: func.isRequired,
    t: func.isRequired,
    getDefaultPatternValue: func.isRequired,
    itemType: string.isRequired,
    siteDashboardURL: string.isRequired,
    experiments: object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };
  }

  render() {
    const { tagsList, t } = this.props;

    return (
      <div data-aid="seo-advanced-panel-advanced-tags">
        <Composites.TextInputMultilineLabeled
          className={classnames(styles.tagsListComposite)}
        >
          <TextLabel
            type="T02"
            value={t('adv-seo.adv-panel.advanced-tags.description')}
            automationId="json-ld-title"
            enableEllipsis={false}
            shouldTranslate={false}
          />

          <div
            className={classnames(styles.tagsListContainer)}
            data-aid="seo-advanced-panel-advanced-tags-tagsList"
          >
            {map(tagsList, this._renderTag)}
          </div>
        </Composites.TextInputMultilineLabeled>
      </div>
    );
  }

  _renderTag = (tag, index) => {
    const {
      onApply,
      t,
      logBiEvent,
      openHelpCenter,
      getDefaultPatternValue,
      itemType,
      siteDashboardURL,
      experiments,
    } = this.props;
    const key = `${TAG_TYPE.ADVANCED}${index}`;
    return (
      <TagItem
        key={key}
        selector={key}
        tag={tag}
        tagType={TAG_TYPE.ADVANCED}
        actions={getActions(tag)}
        onApply={(appliedData) =>
          onApply({
            ...appliedData,
            label: tag.label,
            generalIdentifier: tag.generalIdentifier,
          })
        }
        logBiEvent={logBiEvent}
        openHelpCenter={openHelpCenter}
        t={t}
        getDefaultPatternValue={getDefaultPatternValue}
        itemType={itemType}
        siteDashboardURL={siteDashboardURL}
        experiments={experiments}
      />
    );
  };

  _onInfoClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('14cc2f0c-c4cf-4d3c-93da-aeee2019a394');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.advanced-tags.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default translate()(AdvancedPanelAdvancedTags);
