var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getLocationType, LOCATION_TYPES, } from '../calculated/get-location-type';
import { get } from '../../../utils/get';
import { getEventUrl } from '../calculated/get-event-url';
export var getLocation = function (item) {
    if (!item.event) {
        return '';
    }
    var locationType = getLocationType(item);
    var name = get(item, 'event.location.name');
    var address = get(item, 'event.location.address');
    var url = getEventUrl(item);
    var location = __assign({ '@type': locationType }, (url ? { url: url } : {}));
    if (name) {
        location.name = name;
    }
    if (locationType === LOCATION_TYPES.PHYSICAL) {
        location.address = address;
    }
    return location;
};
