import _ from 'lodash';

export const getRedirects = ({
  beforePublishedPages = [],
  afterPublishedPages = [],
}) =>
  _(beforePublishedPages)
    .concat(afterPublishedPages)
    .uniqWith(_.isEqual)
    .groupBy('pageId')
    .reject({ length: 1 })
    .map(
      _.spread((beforePublishPage, afterPublishedPage) => ({
        from: beforePublishPage.slug,
        to: afterPublishedPage.slug,
      })),
    )
    .value();
