var _a;
import { IDENTIFIERS } from '../../types/Identifiers';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { getValueByIdentifier } from '../values/get-by-identifier';
import { sanitizeUrl } from '../../utils/sanitize-url';
var HANDLERS = (_a = {},
    _a[IDENTIFIERS.TITLE] = getDefaultTitle,
    _a[IDENTIFIERS.DESCRIPTION] = getDefaultDescription,
    _a[IDENTIFIERS.OG_TITLE] = getDefaultOgTitle,
    _a[IDENTIFIERS.OG_DESCRIPTION] = getDefaultOgDescription,
    _a[IDENTIFIERS.OG_IMAGE] = getDefaultOgImage,
    _a[IDENTIFIERS.FB_ADMINS] = getDefaultFbAdmins,
    _a[IDENTIFIERS.CANONICAL] = getDefaultCanonicalUrl,
    _a);
export var getDefaultValueByIdentifier = function (tags, identifier, context) {
    return (HANDLERS[identifier] || getDefault)(context, tags);
};
function getDefault() {
    return undefined;
}
function getDefaultTitle(context) {
    if (context[CONTEXT_PROPS.TITLE]) {
        return context[CONTEXT_PROPS.TITLE];
    }
    if (context[CONTEXT_PROPS.IS_HOME_PAGE]) {
        return context[CONTEXT_PROPS.SITE_NAME] || '';
    }
    return [
        context[CONTEXT_PROPS.PAGE_NAME],
        context[CONTEXT_PROPS.HOME_PAGE_TITLE] || context[CONTEXT_PROPS.SITE_NAME],
    ]
        .filter(function (_) { return !!_; })
        .join(' | ');
}
function getDefaultDescription(context) {
    return context[CONTEXT_PROPS.DESCRIPTION];
}
function getDefaultOgTitle(context, tags) {
    return (getValueByIdentifier(tags, IDENTIFIERS.TITLE) ||
        getDefaultTitle(context, tags));
}
function getDefaultOgDescription(context, tags) {
    return (getValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION) ||
        getDefaultDescription(context, tags));
}
function getDefaultOgImage(context) {
    return (context[CONTEXT_PROPS.OG_IMAGE] || context[CONTEXT_PROPS.SITE_OG_IMAGE]);
}
function getDefaultCanonicalUrl(context) {
    return sanitizeUrl(context[CONTEXT_PROPS.DEFAULT_URL]);
}
function getDefaultFbAdmins(context) {
    return context[CONTEXT_PROPS.FB_ADMINS];
}
