import { renderToStaticMarkup } from '../../renderer/render-to-static-markup';
import { isEmpty } from '../../utils/is-empty';
export var getCustomTagsString = function (tags, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.filterDefaultTags, filterDefaultTags = _c === void 0 ? true : _c;
    return isEmpty(tags)
        ? ''
        : renderToStaticMarkup({
            tags: tags.filter(function (tag) {
                return filterDefaultTags ? tag.custom && !tag.isDefault : tag.custom;
            }),
        }).join('\n');
};
