import {
  getFlagFromLocalStorage,
  setFlagInLocalStorage,
} from './local-storage-utils';

const wasRobotsTagMigratedKey = 'seoClient-wasRobotsTagMigrated';

export const wasRobotsTagMigrated = () =>
  getFlagFromLocalStorage(wasRobotsTagMigratedKey);

export const setWasRobotsTagMigrated = (wasRobotsTagMigratedValue) => {
  setFlagInLocalStorage(wasRobotsTagMigratedKey, wasRobotsTagMigratedValue);
};

const shouldHideRobotsTagMigratedHelperKey =
  'seoClient-shouldHideRobotsTagMigratedHelper';

export const shouldHideRobotsTagMigratedHelper = () =>
  getFlagFromLocalStorage(shouldHideRobotsTagMigratedHelperKey);

export const setShouldHideRobotsTagMigratedHelper = (
  shouldHideRobotsTagMigratedHelperValue,
) => {
  setFlagInLocalStorage(
    shouldHideRobotsTagMigratedHelperKey,
    shouldHideRobotsTagMigratedHelperValue,
  );
};
