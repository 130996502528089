import { nanoid } from 'nanoid';
import {
  convertSeoModel,
  resolveLegacyCustomSd,
} from '@wix/advanced-seo-utils';
import { resolvePayload } from '@wix/advanced-seo-utils/renderer';

export const resolveAdvancedSeoData = (props) => {
  const {
    language: { current, original },
    getDataByCurrLang,
    getDataByLang,
  } = props;

  const isSecondaryLanguage = current && original && current !== original;
  const data = convertSeoModel(getDataByCurrLang());
  const resovledData = resolveLegacyCustomSd(data, () => nanoid(8));

  if (isSecondaryLanguage) {
    return resolvePayload([
      convertSeoModel(getDataByLang(original)),
      resovledData,
    ]);
  }
  return resovledData;
};
