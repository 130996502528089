import { translate, Interpolate } from 'react-i18next';
import { Composites, RichText } from '@wix/wix-base-ui';
import React from 'react';
import { PROMOTE_SEO_LINK_EXPAND_ROBOTS_TAG } from '../../../../core/bi/constants';
import { PROMOTE_SEO_CTA_CLICK } from '../../../../core/bi/action-types';
import { func } from 'prop-types';

export const RobotsTagMigrationDone = translate()(
  ({ expandRobotsTagAccordionSection, logBiEvent, t }) => (
    <Composites.RichText>
      <RichText type="T02 Light">
        <Interpolate
          i18nKey="adv-seo.common.robots-tag.migration.title"
          robotsMetaTag={
            <a
              onClick={() => {
                logBiEvent(PROMOTE_SEO_CTA_CLICK, {
                  linkName: PROMOTE_SEO_LINK_EXPAND_ROBOTS_TAG,
                  linkSource: 'panel',
                  linkType: 'general',
                });
                expandRobotsTagAccordionSection();
              }}
            >
              {t('adv-seo.common.robots-tag.title')}
            </a>
          }
        />
      </RichText>
    </Composites.RichText>
  ),
);

RobotsTagMigrationDone.propTypes = {
  expandRobotsTagAccordionSection: func.isRequired,
  logBiEvent: func.isRequired,
};
