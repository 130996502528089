var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var updateSettingById = function (settings, settingId, value) {
    var _a;
    if (settings === void 0) { settings = {}; }
    if (!settings) {
        return null;
    }
    if (!value) {
        var _b = settings, _c = settingId, _1 = _b[_c], rest = __rest(_b, [typeof _c === "symbol" ? _c : _c + ""]);
        return rest;
    }
    return __assign(__assign({}, settings), (_a = {}, _a[settingId] = value, _a));
};
