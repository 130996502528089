var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PATTERN_PREFIX, PATTERN_SUFFIX } from '../../consts/consts';
import { IDENTIFIERS } from '../../types/Identifiers';
import { identify } from '../../renderer/utils/identify';
export var resolveDynamicPattern = function (patternBlob, dynamicKeys) {
    try {
        var blobString = JSON.stringify(patternBlob);
        var patternRegex = new RegExp("\"".concat(PATTERN_PREFIX, "(.+?)").concat(PATTERN_SUFFIX, "\""), 'g');
        var dynamicPatternString = blobString.replace(patternRegex, function (match, key) {
            var value = dynamicKeys[key] || match;
            if (typeof value !== 'string') {
                return JSON.stringify(value);
            }
            return value;
        });
        var dynamicPattern = clearEmptySd(JSON.parse(dynamicPatternString));
        return dynamicPattern;
    }
    catch (_a) {
        return patternBlob;
    }
};
function clearEmptySd(dynamicePattern) {
    return __assign(__assign({}, dynamicePattern), { tags: dynamicePattern.tags.filter(function (tag) {
            if (identify(tag) === IDENTIFIERS.STRUCTURED_DATA) {
                if (typeof tag.children === 'object') {
                    return true;
                }
                return false;
            }
            return true;
        }) });
}
