import { PrimitiveAtom, atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const pageIdAtom = atom('');

export const hasBreadcrumbsComponentOnPageAtom = atom(false);

export const businessManagerDashboardUrlAtom = atom('');

export const isEditorXAtom = atom(false);

export const routerUrlPrefixAtom = atom<string | undefined>(
  undefined,
) as PrimitiveAtom<string | undefined>; // see https://github.com/pmndrs/jotai/issues/550 need to enable strictNullChecks in the tsconfig for it to work without casting

const isUrlHierarchyFlattenedAtomKey = 'seo-isUrlHierarchyFlattenedAtom';
export const isUrlHierarchyFlattenedAtom = atomWithStorage(
  isUrlHierarchyFlattenedAtomKey,
  false,
);

const seoSettingsMainPagesUrlAtom = atom(
  (get) =>
    `${get(businessManagerDashboardUrlAtom)}/seo-home/seo-settings/static-page`,
);

export const seoSettingsMainPagesWithPageUrlCardOpenedUrlAtom = atom(
  (get) => `${get(seoSettingsMainPagesUrlAtom)}?openCardId=url-mapper`,
);
