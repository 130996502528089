"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESOLUTION_METHOD = exports.SD_STATUS = exports.X_DEFAULT = exports.NO_INDEX = exports.INDEX = exports.WIX_DOMAINLESS_IDS = exports.PATTERN_SUFFIX = exports.SELF_REF_PATTERN_PREFIX = exports.PATTERN_PREFIX = exports.QUERY_DEFAULT_ALLOWLIST = exports.LIGHTBOX_QUERY_PARAM = exports.LANG_QUERY_PARAM = exports.QUERY_PARAM_SEPARATOR = exports.QUERY_SEPARATOR = void 0;
exports.QUERY_SEPARATOR = '?';
exports.QUERY_PARAM_SEPARATOR = '&';
exports.LANG_QUERY_PARAM = 'lang=';
exports.LIGHTBOX_QUERY_PARAM = 'lightbox=';
exports.QUERY_DEFAULT_ALLOWLIST = [exports.LANG_QUERY_PARAM, exports.LIGHTBOX_QUERY_PARAM];
exports.PATTERN_PREFIX = '{{';
exports.SELF_REF_PATTERN_PREFIX = '{{@';
exports.PATTERN_SUFFIX = '}}';
exports.WIX_DOMAINLESS_IDS = [
    'wixsite.com',
    'editor.wix.com',
    'editorx.io',
    'wixstudio.io',
];
exports.INDEX = 'index';
exports.NO_INDEX = 'noindex';
exports.X_DEFAULT = 'x-default';
exports.SD_STATUS = {
    ENABLED: 'enabled',
    DISABLED_DUE_TO_MISSING_INFO: 'disabled-due-to-missing-info',
    DISABLED_BY_VERTICAL_ITEM_DATA: 'disabled-by-vertical-item-data',
    DOES_NOT_EXIST: 'does-not-exist',
};
exports.RESOLUTION_METHOD = {
    SUBDIRECTORY: 'SUBDIRECTORY',
    SUBDOMAIN: 'SUBDOMAIN',
    QUERY_PARAM: 'QUERY_PARAM', // www.mysite.com?lang=fr
};
