var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GENERAL_IDENTIFIERS, TWITTER_IDENTIFIERS, } from '../../types/Identifiers';
import { identify } from '../../renderer/utils/identify';
import { parse } from '../../renderer/utils/parse';
import { ERRORS } from '../../types/Errors';
import { TAG_TYPES } from '../../types/TagTypes';
import PROPS from '../../types/Props';
import { validateBrokenTags, validateIsInWhiteList, validateAttributeIsInBlacklist, validateIsCss, validateEmptyProps, } from '../../types/Validators';
import { filterIdentifiedTags } from '../filters/filter-identified-tags';
import { filterDuplicateCustomTags } from '../filters/filter-duplicate-custom-tags';
import { removeTagByIndex } from '../values/remove-tag-by-index-and-html';
import { identifyCustomTag } from './identify-custom-tag';
import { identifyTag } from '../advanced/identify-tag';
var MAX_CUSTOM_TAG_LENGTH = 300;
var PROPS_EXCLUDED_FROM_ALREADY_EXIST_CHECK = [PROPS.ALTERNATE];
var hasVariablesInKey = function (key) { return (key.match(/{{[^{}]*}}/g) || []).length > 0; };
export function validateCustomTag(htmlString, data, excludedIdentifiers) {
    if (data === void 0) { data = {}; }
    if (excludedIdentifiers === void 0) { excludedIdentifiers = Object.keys(TWITTER_IDENTIFIERS); }
    if (htmlString === '') {
        return { isValid: true };
    }
    var errors = [];
    var tags = parse(htmlString);
    if (!tags.length) {
        errors.push(new ERRORS.INVALID_TAG_STRUCTURE());
        return { isValid: false, errors: errors };
    }
    if (tags.length !== 1) {
        errors.push(new ERRORS.INVALID_TAGS_LENGTH());
    }
    else if (!validateBrokenTags(htmlString).isValid) {
        errors.push(new ERRORS.INVALID_TAG_STRUCTURE());
    }
    var tag = tags[0];
    var invalidAttributes = [];
    var whiteListValidationResult = validateIsInWhiteList(tag);
    if (!whiteListValidationResult.isValid) {
        errors.push(whiteListValidationResult.error);
    }
    var attrValidationResult = validateAttributeIsInBlacklist(tag);
    if (!attrValidationResult.isValid) {
        invalidAttributes = invalidAttributes.concat(attrValidationResult.error.duplicates);
    }
    if (invalidAttributes.length) {
        errors.push(new ERRORS.TAG_CONTAINS_BLACKLISTED_ATTRIBUTE(invalidAttributes));
    }
    var cssValidationResult = validateIsCss(tag);
    if (!cssValidationResult.isValid) {
        errors.push(cssValidationResult.error);
    }
    if (whiteListValidationResult.isValid) {
        var identifiedTag_1 = getIdentifiedTags([tag], excludedIdentifiers)[0];
        if (identifiedTag_1) {
            var forbiddenProps = ['name', 'property', 'content', 'rel', 'href'];
            var forbiddenProp = identifiedTag_1.props &&
                forbiddenProps.find(function (prop) { return identifiedTag_1.props[prop]; });
            if (forbiddenProp) {
                errors.push(new ERRORS.TAG_ALREADY_EXISTS([identifiedTag_1.props[forbiddenProp]]));
            }
        }
        else {
            if (!tag.props) {
                if (tag.type === TAG_TYPES.LINK) {
                    errors.push(new ERRORS.REL_ATTRIBUTE_REQUIRED());
                }
                else {
                    errors.push(new ERRORS.NAME_OR_PROPERTY_ATTRIBUTE_REQUIRED());
                }
            }
            else {
                var customTagIdentifier = identifyCustomTag(tag);
                if (!customTagIdentifier) {
                    var tagIdentifier = identifyTag(tag);
                    if (tagIdentifier) {
                        switch (tagIdentifier) {
                            case GENERAL_IDENTIFIERS.LINK:
                                if (!tag.props[PROPS.REL]) {
                                    errors.push(new ERRORS.REL_ATTRIBUTE_REQUIRED());
                                }
                                if (!tag.props[PROPS.HREF]) {
                                    errors.push(new ERRORS.HREF_ATTRIBUTE_REQUIRED());
                                }
                                break;
                            case GENERAL_IDENTIFIERS.OG_TAG:
                                if (!tag.props[PROPS.CONTENT]) {
                                    errors.push(new ERRORS.CONTENT_ATTRIBUTE_REQUIRED());
                                }
                                break;
                            default:
                                if (!tag.props[PROPS.NAME] && !tag.props[PROPS.PROPERTY]) {
                                    errors.push(new ERRORS.NAME_OR_PROPERTY_ATTRIBUTE_REQUIRED());
                                }
                                if (!tag.props[PROPS.CONTENT]) {
                                    errors.push(new ERRORS.CONTENT_ATTRIBUTE_REQUIRED());
                                }
                                break;
                        }
                    }
                }
                else {
                    switch (customTagIdentifier) {
                        case GENERAL_IDENTIFIERS.LINK:
                            if (hasVariablesInKey(tag.props[PROPS.REL])) {
                                errors.push(new ERRORS.VARIABLE_NOT_ALLOWED_IN_KEY());
                            }
                            break;
                        case GENERAL_IDENTIFIERS.OG_TAG:
                            if (hasVariablesInKey(tag.props[PROPS.PROPERTY])) {
                                errors.push(new ERRORS.VARIABLE_NOT_ALLOWED_IN_KEY());
                            }
                            break;
                        default:
                            if (hasVariablesInKey(tag.props[PROPS.NAME])) {
                                errors.push(new ERRORS.VARIABLE_NOT_ALLOWED_IN_KEY());
                            }
                            break;
                    }
                }
                var unidentifiedBlob = filterIdentifiedTags(data.schema || { tags: [] }, excludedIdentifiers);
                var unidentifiedBlobTags = data.isEditMode
                    ? removeTagByIndex(unidentifiedBlob.tags, data.index, true)
                    : unidentifiedBlob.tags;
                var _a = getUnidentifiedTag(unidentifiedBlobTags, tag), unidentifiedTag = _a.unidentifiedTag, propValue = _a.propValue;
                var isTagAMetaTitle = tag.type === TAG_TYPES.META && tag.props[PROPS.NAME] === PROPS.TITLE;
                if ((unidentifiedTag &&
                    !isExcluded(unidentifiedTag, excludedIdentifiers)) ||
                    isTagAMetaTitle) {
                    if (!PROPS_EXCLUDED_FROM_ALREADY_EXIST_CHECK.includes(propValue) ||
                        isDuplicate(unidentifiedBlobTags, tag)) {
                        errors.push(new ERRORS.TAG_ALREADY_EXISTS([propValue]));
                    }
                }
                else {
                    var isEmptyValidationResult = validateEmptyProps(tag);
                    if (!isEmptyValidationResult.isValid) {
                        errors.push(isEmptyValidationResult.error);
                    }
                }
            }
        }
    }
    if (htmlString.length > MAX_CUSTOM_TAG_LENGTH) {
        errors.push(new ERRORS.MAX_LENGTH_EXCEEDED(MAX_CUSTOM_TAG_LENGTH));
    }
    if (errors.length) {
        return { isValid: false, errors: errors };
    }
    return { isValid: true };
}
function getIdentifiedTags(tags, ignoreList) {
    if (ignoreList === void 0) { ignoreList = []; }
    return tags.filter(function (tag) { return identify(tag, ignoreList); });
}
function getUnidentifiedTag(unidentifiedTags, tag) {
    if (!tag.props) {
        return {};
    }
    var propName = Object.keys(tag.props).find(function (prop) {
        return ((tag.type === TAG_TYPES.META &&
            (prop === PROPS.NAME || prop === PROPS.PROPERTY)) ||
            (tag.type === TAG_TYPES.LINK && prop === PROPS.REL));
    });
    if (!propName) {
        return {};
    }
    var propValue = tag.props[propName];
    var unidentifiedTag = unidentifiedTags.find(function (t) {
        var _a;
        return (t.type === tag.type &&
            ((_a = t === null || t === void 0 ? void 0 : t.props[propName]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (propValue === null || propValue === void 0 ? void 0 : propValue.toLowerCase()));
    });
    return { unidentifiedTag: unidentifiedTag, propValue: propValue };
}
function isExcluded(tag, excludedIdentifiers) {
    if (tag === undefined || tag.custom) {
        return false;
    }
    return excludedIdentifiers.includes(identify(tag));
}
function isDuplicate(existingTags, tag) {
    if (existingTags === void 0) { existingTags = []; }
    if (!existingTags.length) {
        return false;
    }
    return (filterDuplicateCustomTags(existingTags, [__assign(__assign({}, tag), { custom: true })]).length <
        existingTags.length);
}
