"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmptyProps = exports.validateIsCss = exports.validateBrokenTags = exports.validateIsInWhiteList = exports.validateAttributeIsInBlacklist = exports.validateFullUrl = exports.validateJson = void 0;
var Errors_1 = require("./Errors");
var validValue = { isValid: true };
var getInvalidValue = function (Error, property) { return ({
    isValid: false,
    error: new Error(property),
}); };
function validateJson(value) {
    try {
        JSON.parse(value);
    }
    catch (err) {
        return getInvalidValue(Errors_1.ERRORS.INVALID_JSON);
    }
    return validValue;
}
exports.validateJson = validateJson;
function validateFullUrl(value) {
    var validFullUrl = /^(?:(?:https?:)?\/\/)+(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?\S$/i;
    if (validFullUrl.test(value)) {
        return validValue;
    }
    return getInvalidValue(Errors_1.ERRORS.INVALID_FULL_URL);
}
exports.validateFullUrl = validateFullUrl;
function validateAttributeIsInBlacklist(tag) {
    var unauthorizedAttributes = [
        'x-wix-meta-site-id',
        'x-ua-compatible',
        'x-wix-renderer-server',
        'x-wix-application-instance-id',
        'x-wix-published-version',
        'etag',
        'skype_toolbar',
        'fb_admins_meta_tag',
        'fb:admins',
        'viewport',
    ];
    var invalidAttributes = unauthorizedAttributes.filter(function (pattern) { return tag.props && tag.props[pattern]; });
    return invalidAttributes.length
        ? getInvalidValue(Errors_1.ERRORS.TAG_CONTAINS_BLACKLISTED_ATTRIBUTE, invalidAttributes)
        : validValue;
}
exports.validateAttributeIsInBlacklist = validateAttributeIsInBlacklist;
function validateIsInWhiteList(tag) {
    var authorizedTags = ['link', 'meta'];
    var isTagWhiteListed = authorizedTags.some(function (pattern) { return tag.type.toLowerCase().indexOf(pattern) !== -1; });
    return isTagWhiteListed
        ? validValue
        : getInvalidValue(Errors_1.ERRORS.TAG_TYPE_NOT_ALLOWED);
}
exports.validateIsInWhiteList = validateIsInWhiteList;
function validateBrokenTags(value) {
    if (typeof value !== 'string') {
        return validValue;
    }
    var numOpenTags = 0;
    for (var i = 0; i < value.length; i++) {
        var char = value[i];
        if (char === '<') {
            numOpenTags++;
            if (numOpenTags !== 1) {
                return true;
            }
        }
        if (char === '>') {
            numOpenTags -= 1;
            if (numOpenTags !== 0) {
                return true;
            }
        }
    }
    return numOpenTags !== 0
        ? getInvalidValue(Errors_1.ERRORS.INVALID_TAG_STRUCTURE)
        : validValue;
}
exports.validateBrokenTags = validateBrokenTags;
function validateIsCss(tag) {
    var isCssTag = tag.type === 'link' &&
        tag.props &&
        tag.props.rel &&
        tag.props.rel.toLowerCase().trim() === 'stylesheet';
    return isCssTag ? getInvalidValue(Errors_1.ERRORS.CSS_NOT_ALLOWED) : validValue;
}
exports.validateIsCss = validateIsCss;
function validateEmptyProps(tag) {
    if (tag.type === 'link' || tag.type === 'meta') {
        if (!tag.props) {
            return getInvalidValue(Errors_1.ERRORS.EMPTY_PROPS);
        }
        return Object.values(tag.props)
            .slice(0, 2)
            .map(function (p) { return p.replace(/\s+/g, ''); })
            .every(function (p) { return p.length; })
            ? validValue
            : getInvalidValue(Errors_1.ERRORS.EMPTY_PROPS);
    }
    return validValue;
}
exports.validateEmptyProps = validateEmptyProps;
