var cloneDeep = function (obj) {
    try {
        return JSON.parse(JSON.stringify(obj));
    }
    catch (_a) {
        return { msg: 'failed to parse', obj: obj };
    }
};
export var logInfo = function (options, data, funcName) {
    if (options.logInfo) {
        options.logInfo('state before ' + funcName, {
            time: Date.now(),
            data: cloneDeep(data),
        });
    }
};
