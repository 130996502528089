"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var schema_types_1 = require("./schema-types");
var utils_1 = require("../../../adapters/utils");
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'Restaurant',
    name: "{{".concat(utils_1.siteIDs.BUSINESS_NAME, "}}"),
    image: "{{".concat(utils_1.siteIDs.RESTAURANT_IMAGES, "}}"),
    address: {
        '@type': 'PostalAddress',
        addressCountry: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_COUNTRY, "}}"),
        addressLocality: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_CITY, "}}"),
        addressRegion: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_STATE, "}}"),
        postalCode: "{{".concat(utils_1.siteIDs.BUSINESS_POSTAL_CODE, "}}"),
        streetAddress: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_STREET_ADDRESS, "}}"),
    },
    geo: {
        '@type': 'GeoCoordinates',
        latitude: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_LATITUDE, "}}"),
        longitude: "{{".concat(utils_1.siteIDs.BUSINESS_LOCATION_LONGITUDE, "}}"),
    },
    url: "{{".concat(utils_1.siteIDs.SITE_URL, "}}"),
    telephone: "{{".concat(utils_1.siteIDs.BUSINESS_PHONE, "}}"),
    openingHoursSpecification: "{{".concat(utils_1.siteIDs.OPENING_HOURS_SPECIFICATION, "}}"),
    acceptReservations: 'True',
};
var requiredSchemaVariables = [
    utils_1.siteIDs.RESTAURANT_IMAGES,
    utils_1.siteIDs.BUSINESS_NAME,
    utils_1.siteIDs.BUSINESS_LOCATION_COUNTRY,
    utils_1.siteIDs.BUSINESS_LOCATION_CITY,
    utils_1.siteIDs.BUSINESS_LOCATION_STREET_ADDRESS,
    utils_1.siteIDs.RESTAURANT_IMAGES,
];
exports.default = {
    type: schema_types_1.SCHEMA_TYPES.RESTAURANT_SCHEMA,
    schema: schema,
    requiredSchemaVariables: requiredSchemaVariables,
};
