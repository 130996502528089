export var CONTEXT_PROPS = {
    SITE_NAME: 'siteName',
    DOMAIN: 'domain',
    SITE_URL: 'siteUrl',
    SITE_OG_IMAGE: 'siteOgImage',
    HOME_PAGE_TITLE: 'homePageTitle',
    IS_HOME_PAGE: 'isHomePage',
    PAGE_NAME: 'pageName',
    CURRENT_PAGE_URL: 'currentPageUrl',
    TITLE: 'title',
    DESCRIPTION: 'description',
    OG_TITLE: 'ogTitle',
    OG_DESCRIPTION: 'ogDescription',
    TWITTER_CARD: 'twitterCard',
    TWITTER_TITLE: 'twitterTitle',
    TWITTER_DESCRIPTION: 'twitterDescription',
    TWITTER_IMAGE: 'twitterImage',
    OG_IMAGE: 'ogImage',
    OG_IMAGE_WIDTH: 'ogImageWidth',
    OG_IMAGE_HEIGHT: 'ogImageHeight',
    DEFAULT_URL: 'defaultUrl',
    OG_TYPE: 'ogType',
    INDEX_SITE: 'indexSite',
    INDEX_PAGE: 'indexPage',
    ROBOTS_FROM_USER_PATTERN: 'robotsFromUserPatterns',
    FB_ADMINS: 'facebookAdminId',
    NEXT: 'nextLink',
    PREV: 'prevLink',
    CURR_LANG_CODE: 'currLangCode',
    CURR_LANG_IS_ORIGINAL: 'currLangIsOriginal',
    CURR_LANG_RESOLUTION_METHOD: 'currLangResolutionMethod',
    SEO_LANG: 'seoLang',
    BUSINESS_NAME: 'businessName',
    BUSINESS_LOCATION_COUNTRY: 'businessLocationCountry',
    BUSINESS_LOCATION_STATE: 'businesLocationsState',
    BUSINESS_LOCATION_CITY: 'businessLocationCity',
    BUSINESS_LOCATION_STREET: 'businesLocationsStreet',
    BUSINESS_LOCATION_STREET_NUMBER: 'businessLocationsStreetNumber',
    BUSINESS_LOCATION_DESCRIPTION: 'businesLocationsDescription',
    BUSINESS_LOCATION_FORMATTED: 'businessLocationFormatted',
    BUSINESS_LOCATION_COORDINATES: 'businessLocationCoordinates',
    BUSINESS_POSTAL_CODE: 'businessPostalCode',
    BUSINESS_DESCRIPTION: 'businesDescription',
    BUSINESS_LOGO: 'businesLogo',
    BUSINESS_LOCALE: 'businesLocale',
    BUSINESS_PHONE: 'businessPhone',
    BUSINESS_SCHEDULE: 'businessSchedule',
    CURRENCY: 'currency',
    SITE_LANGUAGES: 'siteLanguages',
    EXPERIMENTS: 'experiments',
    PLATFORM_APPS_EXPERIMENTS: 'platformAppsExperiments',
    INSTALLED_APPS: 'installedApps',
    TPA_PAGE_ID: 'tpaPageId',
    PAGE_ID: 'pageId',
    RESTAURANT_IMAGES: 'restaurantImages',
};
