import { validateCustomTags } from './validate-custom-tags';
import { parse } from '../../renderer/utils/parse';
export function updateCustomTags(tags, customTagsArrayString) {
    if (validateCustomTags(customTagsArrayString).isValid) {
        var parsedTags = parse(customTagsArrayString).map(function (customTag) {
            customTag.custom = true;
            return customTag;
        });
        return tags.filter(function (tag) { return !tag.custom; }).concat(parsedTags);
    }
    else {
        return tags;
    }
}
