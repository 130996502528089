import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import {
  useCharLimit,
  CharLimit,
  Composites,
  TextLabel,
  TextInput,
  RichText,
  Tooltip,
} from '@wix/wix-base-ui';

import InfoIcon from '../ui/info-icon';
import TooltipContent from '../ui/tooltip-content';
import RenderIf from '../../../core/components/render-if';
import * as BI_TYPES from '../../../core/bi/action-types';
import { MAX_LENGTH } from '../../../core/validators/validators';
import { KEYS } from '../../../core/utils/maps';
import BasicPanelUrlAutoRedirect from './basic-panel-url-auto-redirect';
import {
  ParentPageSection,
  useShouldShowParentPageSection,
} from './basic-panel-url-hierarchy/parent-page-section';
import { UrlPrefix } from './basic-panel-url-hierarchy/url-hierarchy';
import { useGetFullSlug } from '../../app/url-hooks';

const BasicPanelUrl = ({
  t,
  uri,
  uriErrorMessage,
  isUriInputDisabled,
  goToUrl,
  isSitePublished,
  openHelpCenter,
  onSaveUri,
  onSavePreventAutoRedirect,
  onChange,
  domainInfoExists,
  isDomainConnected,
  premiumDomainsUrl,
  logBiEvent,
  initialUri,
  preventAutoRedirectValue,
  isEditorX,
  isMLSlugExperimentOpen,
  language,
}) => {
  const [isUriInputFocused, setUriInputFocused] = useState(false);
  const onLearnMore = () => {
    openHelpCenter('1ffdd271-9140-424e-b7d9-315c6116eff8');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkType: 'learn_more',
      linkName: 'adv-seo.basic-panel.page-uri.input-tooltip',
      linkSource: 'tooltip',
    });
    setUriInputFocused(false);
  };

  const onGoToUri = () => {
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.basic-panel.page-url.goto',
      linkType: 'general',
      linkSource: 'panel',
    });
    goToUrl();
  };

  const onFocus = () => {
    logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW, {
      tooltipName: 'adv-seo.basic-panel.page-uri.input-tooltip-title',
    });
    return setUriInputFocused(true);
  };

  const onBlur = () => {
    setUriInputFocused(false);
    onSaveUri();
  };

  const { showCharLimit, getTextInputProps, getCharLimitProps } = useCharLimit({
    initialValue: uri,
    tooltipAlignment: 'RIGHT',
  });
  const { onChange: inputOnChange } = getTextInputProps({});
  useEffect(() => {
    inputOnChange(uri);
  }, [uri, inputOnChange]);

  const shouldShowParentPageSection = useShouldShowParentPageSection();

  const fullSlug = useGetFullSlug(uri);

  return (
    <>
      {shouldShowParentPageSection && (
        <ParentPageSection
          t={t}
          logBiEvent={logBiEvent}
          openHelpCenter={openHelpCenter}
          language={language}
        />
      )}
      <Composites.TextInputLabeled>
        <InfoIcon text="adv-seo.basic-panel.page-url.info.tooltip" />
        <RenderIf truthy={showCharLimit && isUriInputFocused}>
          <CharLimit {...getCharLimitProps(MAX_LENGTH[KEYS.SLUG])} />
        </RenderIf>
        <TextLabel
          value={t('adv-seo.basic-panel.page-url.input.label')}
          shouldTranslate={false}
          automationId="seo-url-label"
        />
        {domainInfoExists ? (
          <TextInput
            {...getTextInputProps({
              invalidMessage: uriErrorMessage,
              onChange,
              onFocus,
              onBlur,
            })}
            value={uri}
            isValid={!uriErrorMessage}
            hideSuccessIndication
            prefix={<UrlPrefix isEditorX={isEditorX} />}
            placeholder={t('adv-seo.basic-panel.page-url.input.placeholder')}
            disabled={isUriInputDisabled}
            shouldTranslate={false}
            automationId="seo-url-input"
            maxLength={MAX_LENGTH[KEYS.URI]}
          />
        ) : (
          <Tooltip
            closeOnContentMouseClick={true}
            content={
              <TooltipContent
                title="adv-seo.basic-panel.page-uri.input-tooltip-title"
                text="adv-seo.basic-panel.page-uri.input-tooltip-description"
                linkText="adv-seo.common.learn-more"
                onLinkClick={onLearnMore}
              />
            }
            marginTop={-6}
            marginLeft={-376}
            alignment="BOTTOM"
            isOpen={isUriInputFocused && !uriErrorMessage && isSitePublished}
            openOnMouseEnter={false}
            shouldTranslate={false}
            interactive={true}
          >
            <TextInput
              {...getTextInputProps({
                invalidMessage: uriErrorMessage,
                onChange,
                onFocus,
                onBlur,
              })}
              value={uri}
              isValid={!uriErrorMessage}
              hideSuccessIndication
              prefix="/"
              placeholder={t('adv-seo.basic-panel.page-url.input.placeholder')}
              disabled={isUriInputDisabled}
              shouldTranslate={false}
              automationId="seo-url-input"
              maxLength={MAX_LENGTH[KEYS.URI]}
            />
          </Tooltip>
        )}

        <RenderIf truthy={domainInfoExists}>
          <div
            style={{
              paddingTop: '5px',
            }}
          >
            {!isMLSlugExperimentOpen &&
            !isEditorX &&
            initialUri &&
            fullSlug !== initialUri ? (
              <BasicPanelUrlAutoRedirect
                isDomainConnected={isDomainConnected}
                premiumDomainsUrl={premiumDomainsUrl}
                preventAutoRedirectValue={preventAutoRedirectValue}
                onSave={onSavePreventAutoRedirect}
                logBiEvent={logBiEvent}
              />
            ) : (
              <RenderIf truthy={isSitePublished}>
                <RichText>
                  <Tooltip
                    onOpen={() =>
                      logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW, {
                        tooltipName:
                          'adv-seo.basic-panel.page-url.link.tooltip',
                      })
                    }
                    content={
                      <TooltipContent text="adv-seo.basic-panel.page-url.link.tooltip" />
                    }
                    alignment="TOP"
                  >
                    <a onClick={onGoToUri}>
                      {t('adv-seo.basic-panel.page-url.link')}
                    </a>
                  </Tooltip>
                </RichText>
              </RenderIf>
            )}
          </div>
        </RenderIf>
      </Composites.TextInputLabeled>
    </>
  );
};

export default translate()(BasicPanelUrl);
