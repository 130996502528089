import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  isRobotsDirectiveExists,
  getRobotsTagValueWithDirective,
  getDirectiveValue,
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
  getRobotsTagValueWithoutDirective,
} from '@wix/advanced-seo-utils';
import { KEYS } from '../../utils/maps';
import * as BI_TYPES from '../../bi/action-types';

export const RobotsTagContext = React.createContext({
  robotsTagDefaultPatternValue: undefined,
  robotsTagValue: undefined,
  onChange: undefined,
  onSave: undefined,
  logBiEvent: undefined,
  openHelper: undefined,
});

export const useIsDirectiveExists = (directiveName) => {
  const { robotsTagValue } = useContext(RobotsTagContext);

  return isRobotsDirectiveExists(robotsTagValue, directiveName);
};

const getDirectiveDisplayValue = (directiveValue, defaultValue) => {
  if (
    directiveValue === undefined ||
    directiveValue === REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE
  ) {
    return defaultValue;
  }

  return directiveValue;
};

export const useDirectiveDisplayValue = (directiveName, defaultValue) => {
  const { robotsTagValue } = useContext(RobotsTagContext);

  return getDirectiveDisplayValue(
    getDirectiveValue(robotsTagValue, directiveName),
    defaultValue,
  );
};

export const getUpdatedRobotsTagValue = (
  robotsTagDefaultPatternValue,
  robotsTagValue,
  directiveName,
  newValue,
) => {
  const isDirectiveExistsinPatterns = isRobotsDirectiveExists(
    robotsTagDefaultPatternValue,
    directiveName,
  );
  const existsInPatternsAndHasSameValue =
    isDirectiveExistsinPatterns &&
    getDirectiveValue(robotsTagDefaultPatternValue, directiveName) === newValue;
  const missingInPatternsAndNewValueIsRemove =
    !isDirectiveExistsinPatterns &&
    newValue === REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE;

  const updatedRobotsTagValue =
    existsInPatternsAndHasSameValue || missingInPatternsAndNewValueIsRemove
      ? getRobotsTagValueWithoutDirective(robotsTagValue, directiveName)
      : getRobotsTagValueWithDirective(robotsTagValue, directiveName, newValue);

  return updatedRobotsTagValue;
};

export const useUpdateDirectiveWithValue = (directiveName) => {
  const { robotsTagDefaultPatternValue, robotsTagValue, onChange, onSave } =
    useContext(RobotsTagContext);

  return useCallback(
    async (value = undefined) => {
      const updatedRobotsTagValue = getUpdatedRobotsTagValue(
        robotsTagDefaultPatternValue,
        robotsTagValue,
        directiveName,
        value,
      );

      await onChange(KEYS.ROBOTS_TAG)(updatedRobotsTagValue);
      onSave(KEYS.ROBOTS_TAG)();
    },
    [
      robotsTagDefaultPatternValue,
      robotsTagValue,
      directiveName,
      onSave,
      onChange,
    ],
  );
};

export const useApplyOrRemoveDirective = (directiveName) => {
  const updateDirectiveWithValue = useUpdateDirectiveWithValue(directiveName);

  return useCallback(
    (isEnabled) => {
      return updateDirectiveWithValue(
        isEnabled ? undefined : REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
      );
    },
    [updateDirectiveWithValue],
  );
};

export const isCheckedFromEvent = (event) => !!event?.target?.checked;

export const getValueFromEvent = (event) => event?.target?.value;

export const useUpdateDirectiveWithNumericRange = (
  directiveName,
  defaultValue,
  min,
  max,
) => {
  const updateDirectiveWithValue = useUpdateDirectiveWithValue(directiveName);

  return useCallback(
    (value) => {
      const convertedValue = +value;
      if (
        value !== REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE &&
        (Number.isNaN(convertedValue) ||
          value?.trim() === '' ||
          convertedValue < min ||
          convertedValue > max)
      ) {
        updateDirectiveWithValue(defaultValue);
      } else {
        updateDirectiveWithValue(value);
      }
    },
    [updateDirectiveWithValue, defaultValue, min, max],
  );
};

export const useForcedRender = () => {
  const [forceRender, setForceRender] = useState(true);

  useEffect(() => {
    if (!forceRender) {
      setForceRender(true);
    }
  }, [setForceRender, forceRender]);

  const applyForcedRender = useCallback(
    (Component) => {
      return forceRender ? Component : undefined;
    },
    [forceRender],
  );

  const triggerForcedRender = useCallback(() => {
    setForceRender(false);
  }, [setForceRender]);

  return { applyForcedRender, triggerForcedRender };
};

export const useForceUpdate = () => {
  const [, setDummyValue] = useState(true);
  const forceUpdate = useCallback(() => {
    setDummyValue((dummyValue) => !dummyValue);
  }, [setDummyValue]);

  return forceUpdate;
};

export const useOnTooltipOpen = (infoTextBiName) => {
  const { logBiEvent } = useContext(RobotsTagContext);
  return useCallback(() => {
    logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW, {
      tooltipName: infoTextBiName,
    });
  }, [logBiEvent, infoTextBiName]);
};

export const useOnLinkClick = (infoTextBiName, helperData) => {
  const { logBiEvent, openHelper } = useContext(RobotsTagContext);
  return useCallback(() => {
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: infoTextBiName,
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
    openHelper(helperData);
  }, [logBiEvent, openHelper, infoTextBiName, helperData]);
};
