import { ITEM_TYPES } from '@wix/advanced-seo-utils';

export const getPatternByItemType = ({ userPatterns }, itemType) => {
  const { content: userPattern } =
    userPatterns.find(({ patternType }) => {
      const fallbackToStaticPageV1 =
        itemType === ITEM_TYPES.STATIC_PAGE_V2 &&
        patternType === ITEM_TYPES.STATIC_PAGE; // TODO: remove when cleaning up static pattern v1
      return patternType === itemType || fallbackToStaticPageV1;
    }) || {};

  return userPattern;
};
