import React from 'react';
import BasicPanel from '../components/basic-panel';
import {
  getPremiumDomainsUrl,
  getWixSeoSettingsUrl,
} from '../../core/utils/consts';

export const BasicTab = ({
  isUriInputDisabled,
  openHelpCenter,
  wixSeoUrl,
  isSitePublished,
  isIndexEnabledFromSite,
  isHomePage,
  msid,
  title,
  description,
  isIndexEnabled,
  uri,
  initialUri,
  isIndexEnabledFromPermission,
  onSave,
  validateAndSetState,
  logBiEvent,
  experiments,
  isIndexEnabledFromUserPattern,
  connectedDomainResponse,
  resetToPattern,
  showResetToPatternButton,
  getDefaultPatternValue,
  preventAutoRedirectValue,
  isEditorX,
  isMLSlugExperimentOpen,
  publicURL,
  language,
}) => {
  const wixSeoSettingsUrl = getWixSeoSettingsUrl(msid);
  const wixSeoPatternsUrl = `https://www.wix.com/dashboard/${msid}/seo-home/seo-settings/static-page`;
  const premiumDomainsUrl = getPremiumDomainsUrl(msid);

  const goToUrl = () => window.open(publicURL, '_blank');

  return (
    <div>
      <BasicPanel
        onSave={onSave}
        onChange={validateAndSetState}
        title={title.value || ''}
        titleErrorMessage={title.error}
        description={description.value || ''}
        descriptionErrorMessage={description.error}
        isIndexEnabled={isIndexEnabled.value}
        isIndexEnabledFromSite={isIndexEnabledFromSite}
        isIndexEnabledFromPermission={isIndexEnabledFromPermission}
        isIndexEnabledFromUserPattern={isIndexEnabledFromUserPattern}
        isHomePage={isHomePage}
        uri={uri.value}
        uriErrorMessage={uri.error}
        initialUri={initialUri}
        isUriInputDisabled={isUriInputDisabled}
        publicURL={publicURL}
        openHelpCenter={openHelpCenter}
        wixSeoUrl={wixSeoUrl}
        wixSeoSettingsUrl={wixSeoSettingsUrl}
        wixSeoPatternsUrl={wixSeoPatternsUrl}
        premiumDomainsUrl={premiumDomainsUrl}
        logBiEvent={logBiEvent}
        isSitePublished={isSitePublished}
        domainInfoExists={!connectedDomainResponse.hasError}
        isDomainConnected={
          !connectedDomainResponse.hasError &&
          connectedDomainResponse.isConnected
        }
        goToUrl={goToUrl}
        resetToPattern={resetToPattern}
        showResetToPatternButton={showResetToPatternButton}
        experiments={experiments}
        getDefaultPatternValue={getDefaultPatternValue}
        preventAutoRedirectValue={preventAutoRedirectValue}
        isEditorX={isEditorX}
        isMLSlugExperimentOpen={isMLSlugExperimentOpen}
        language={language}
      />
    </div>
  );
};
