import { FIELD_TYPE } from './consts';
export var getMainKeysData = function (IDs, collections) {
    var _a;
    var _b, _c;
    var mainCollection = collections === null || collections === void 0 ? void 0 : collections.find(function (collection) { return collection.primary; });
    var mainImage = (_b = mainCollection === null || mainCollection === void 0 ? void 0 : mainCollection.fields.find(function (field) {
        var _a;
        return field.type === FIELD_TYPE.IMAGE &&
            field.connected &&
            ((_a = field.value) === null || _a === void 0 ? void 0 : _a.connectedToSrc);
    })) === null || _b === void 0 ? void 0 : _b.value;
    var mainVideo = (_c = mainCollection === null || mainCollection === void 0 ? void 0 : mainCollection.fields.find(function (field) {
        var _a;
        return field.type === FIELD_TYPE.VIDEO &&
            field.connected &&
            ((_a = field.value) === null || _a === void 0 ? void 0 : _a.connectedToSrc);
    })) === null || _c === void 0 ? void 0 : _c.value;
    return _a = {},
        _a[IDs.MAIN_COLLECTION_NAME] = mainCollection === null || mainCollection === void 0 ? void 0 : mainCollection.name,
        _a[IDs.TOTAL_ITEMS_IN_MAIN_COLLECTION] = mainCollection === null || mainCollection === void 0 ? void 0 : mainCollection.totalCount,
        _a[IDs.IMAGE_TITLE] = mainImage === null || mainImage === void 0 ? void 0 : mainImage.alt,
        _a[IDs.MAIN_IMAGE] = mainImage === null || mainImage === void 0 ? void 0 : mainImage.url,
        _a[IDs.IMAGE_HEIGHT] = mainImage === null || mainImage === void 0 ? void 0 : mainImage.height,
        _a[IDs.IMAGE_WIDTH] = mainImage === null || mainImage === void 0 ? void 0 : mainImage.width,
        _a[IDs.VIDEO_NAME] = mainVideo === null || mainVideo === void 0 ? void 0 : mainVideo.title,
        _a[IDs.VIDEO_DESCRIPTION] = mainVideo === null || mainVideo === void 0 ? void 0 : mainVideo.description,
        _a[IDs.VIDEO_UPLOAD_DATE] = mainVideo === null || mainVideo === void 0 ? void 0 : mainVideo.uploadDate,
        _a[IDs.VIDEO_THUMBNAIL_URL] = mainVideo === null || mainVideo === void 0 ? void 0 : mainVideo.thumbnailUrl,
        _a;
};
