"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrency = void 0;
var get_1 = require("../../../../utils/get");
var getCurrency = function (item) {
    if (item === void 0) { item = {}; }
    var currency = (0, get_1.get)(item, 'program.settings.pricing.singlePayment.price.currency');
    if (currency === '') {
        return undefined;
    }
    return currency;
};
exports.getCurrency = getCurrency;
