var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildOgMetaRefs, buildLink, buildTwitterMetaRefs, } from '../../types/builders';
import { siteIDs } from '../../adapters/utils';
import adapter from './adapter-challenges-page';
/**
 Here you can find more details about the implementation
 https://docs.google.com/document/d/16MAdLjujVtzlDrez1JQs8SlI146sgp7KGIq_8XEEGRI/edit#
*/
var IDs = adapter.IDs;
export var CHALLENGES_PAGE = {
    tags: __spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.CALCULATED_NAME, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildStandardMeta('robots', "{{".concat(IDs.CALCULATED_ROBOTS, "}}"))
    ], buildOgMetaRefs({ excludeDescription: true }), true), [
        buildOgMeta('og:description', "{{".concat(IDs.DESCRIPTION_150, "}}")),
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:url', "{{".concat(IDs.URL, "}}")),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.URL, "}}") })
    ], false), buildTwitterMetaRefs(), true),
};
