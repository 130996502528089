import React, { PureComponent } from 'react';
import { bool, func, object, string } from 'prop-types';
import { translate } from 'react-i18next';
import { Divider } from '@wix/wix-base-ui';

import { KEYS } from '../../../core/utils/maps';
import BasicPanelSeoWiz from './basic-panel-seo-wiz';
import BasicPanelUrl from './basic-panel-url';
import BasicPanelGooglePreview from './basic-panel-google-preview';
import BasicPanelTitle from './basic-panel-title';
import BasicPanelDescription from './basic-panel-description';
import BasicPanelIndex from './basic-panel-index';
import { getUpdatedRobotsTagValue } from '../../../core/components/robots-tag/robots-tag-hooks';
import {
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
  ROBOTS_DIRECTIVES,
} from '@wix/advanced-seo-utils';
import BasicPanelHomePage from './basic-panel-home-page';

class BasicPanel extends PureComponent {
  static propTypes = {
    onSave: func.isRequired,
    onChange: func.isRequired,
    title: string.isRequired,
    titleErrorMessage: string,
    description: string.isRequired,
    descriptionErrorMessage: string,
    uri: string.isRequired,
    uriErrorMessage: string,
    initialUri: string.isRequired,
    isUriInputDisabled: bool.isRequired,
    isIndexEnabled: bool.isRequired,
    isIndexEnabledFromSite: bool.isRequired,
    publicURL: string.isRequired,
    t: func.isRequired,
    wixSeoSettingsUrl: string.isRequired,
    wixSeoPatternsUrl: string.isRequired,
    premiumDomainsUrl: string.isRequired,
    wixSeoUrl: string.isRequired,
    logBiEvent: func.isRequired,
    domainInfoExists: bool.isRequired,
    isDomainConnected: bool.isRequired,
    goToUrl: func.isRequired,
    isSitePublished: bool,
    experiments: object.isRequired,
    resetToPattern: func.isRequired,
    showResetToPatternButton: func.isRequired,
    getDefaultPatternValue: func.isRequired,
    robotsTagValue: string.isRequired,
    preventAutoRedirectValue: bool.isRequired,
  };

  render() {
    const {
      title,
      titleErrorMessage,
      description,
      descriptionErrorMessage,
      uri,
      uriErrorMessage,
      initialUri,
      isIndexEnabled,
      isIndexEnabledFromSite,
      isIndexEnabledFromPermission,
      isIndexEnabledFromUserPattern,
      isHomePage,
      isUriInputDisabled,
      publicURL,
      openHelpCenter,
      wixSeoUrl,
      wixSeoSettingsUrl,
      wixSeoPatternsUrl,
      premiumDomainsUrl,
      logBiEvent,
      isSitePublished,
      domainInfoExists,
      isDomainConnected,
      goToUrl,
      experiments,
      showResetToPatternButton,
      getDefaultPatternValue,
      preventAutoRedirectValue,
      isEditorX,
      isMLSlugExperimentOpen,
      language,
    } = this.props;

    const shouldEnableHomePageSlug = !isHomePage;
    return (
      <div data-hook="basic-panel" style={{ paddingTop: '10px' }}>
        <BasicPanelSeoWiz
          wixSeoUrl={wixSeoUrl}
          logBiEvent={logBiEvent}
          experiments={experiments}
        />
        <Divider long={false} />
        <BasicPanelGooglePreview
          title={title}
          description={description}
          url={publicURL}
          openHelpCenter={openHelpCenter}
          logBiEvent={logBiEvent}
        />
        <Divider long={false} />
        {shouldEnableHomePageSlug ? (
          <BasicPanelUrl
            onSaveUri={this.onSaveUri}
            onSavePreventAutoRedirect={this.onSavePreventAutoRedirect}
            onChange={this.onChangeUri}
            uri={uri}
            uriErrorMessage={uriErrorMessage}
            isUriInputDisabled={isUriInputDisabled}
            publicURL={publicURL}
            logBiEvent={logBiEvent}
            premiumDomainsUrl={premiumDomainsUrl}
            openHelpCenter={openHelpCenter}
            isSitePublished={isSitePublished}
            domainInfoExists={domainInfoExists}
            isDomainConnected={isDomainConnected}
            experiments={experiments}
            goToUrl={goToUrl}
            initialUri={initialUri}
            preventAutoRedirectValue={preventAutoRedirectValue}
            isEditorX={isEditorX}
            experiment={experiments}
            isMLSlugExperimentOpen={isMLSlugExperimentOpen}
            language={language}
          />
        ) : (
          <BasicPanelHomePage logBiEvent={logBiEvent} goToUrl={goToUrl} />
        )}
        <Divider long={false} />
        <BasicPanelTitle
          value={title}
          error={titleErrorMessage}
          onChange={this.onChangeTitle}
          onSave={this.onSaveTitle}
          logBiEvent={logBiEvent}
          resetToPattern={this.resetToPatternTitle}
          showResetToPatternButton={showResetToPatternButton}
          getDefaultPatternValue={getDefaultPatternValue}
        />
        <Divider long={false} />
        <BasicPanelDescription
          value={description}
          error={descriptionErrorMessage}
          onChange={this.onChangeDescription}
          onSave={this.onSaveDescription}
          resetToPattern={this.resetToPatternDescription}
          showResetToPatternButton={showResetToPatternButton}
          getDefaultPatternValue={getDefaultPatternValue}
          logBiEvent={logBiEvent}
        />
        <Divider long={false} />
        <BasicPanelIndex
          onChange={this.onChangeIndexToggle}
          isIndexEnabled={isIndexEnabled}
          isIndexEnabledFromSite={isIndexEnabledFromSite}
          isIndexEnabledFromPermission={isIndexEnabledFromPermission}
          isIndexEnabledFromUserPattern={isIndexEnabledFromUserPattern}
          wixSeoSettingsUrl={wixSeoSettingsUrl}
          wixSeoPatternsUrl={wixSeoPatternsUrl}
          logBiEvent={logBiEvent}
          experiments={experiments}
        />
      </div>
    );
  }

  onSaveTitle = this.props.onSave(KEYS.TITLE);
  onChangeTitle = this.props.onChange(KEYS.TITLE);
  resetToPatternTitle = this.props.resetToPattern(KEYS.TITLE);
  onSaveDescription = this.props.onSave(KEYS.DESCRIPTION);
  onChangeDescription = this.props.onChange(KEYS.DESCRIPTION);
  resetToPatternDescription = this.props.resetToPattern(KEYS.DESCRIPTION);
  onSaveUri = this.props.onSave(KEYS.URI);
  onChangeUri = this.props.onChange(KEYS.URI);
  onSavePreventAutoRedirect = this.props.onSave(KEYS.PREVENT_AUTO_REDIRECT);

  onChangeIndexToggle = async (isEnabled) => {
    const updatedRobotsTagValue = getUpdatedRobotsTagValue(
      this.props.getDefaultPatternValue(KEYS.ROBOTS_TAG) ?? '',
      this.props.robotsTagValue,
      ROBOTS_DIRECTIVES.NOINDEX,
      !isEnabled ? undefined : REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
    );
    await this.props.onChange(KEYS.ROBOTS_TAG)(updatedRobotsTagValue);
    this.props.onSave(KEYS.ROBOTS_TAG)();
  };
}

export default translate()(BasicPanel);
