import { adapter } from '../index';
import { SCHEMA_TYPES } from './schema-types';
var IDs = adapter.IDs;
export default {
    type: SCHEMA_TYPES.RESTAURANTS_MENU_PAGE_IMAGES,
    schema: {
        '@context': 'https://schema.org/',
        images: "{{".concat(IDs.SD_JSON_IMAGES, "}}"),
    },
};
