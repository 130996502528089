var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getKeys, hasMissingSchemaVariables } from '../../adapters/utils';
import { getSdType, getStructuredData, getServiceUrl, getBookingDescription, getServiceMediaItems, } from './adapter-utils';
import { get, getRawValue } from '../../utils/get';
import { getUrlParts } from '../../utils/get-url-parts';
import { buildOgImagePreviewUrl } from '../../utils/build-og-image-preview';
import { SD_STATUS } from '../../consts';
import { SESSION_TYPES } from './constants';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
export var IDs = {
    ID: 'service.id',
    CALCULATED_SERVICE_URL: 'service.url',
    TITLE: 'service.info.name',
    DESCRIPTION: 'service.info.description',
    STATUS: 'service.status',
    IMAGE_HEIGHT: 'service.mainImage.height',
    IMAGE_ID: 'service.mainImage.id',
    IMAGE_WIDTH: 'service.mainImage.width',
    MAIN_IMAGE: 'service.mainImage.url',
    IMAGE_ALT: 'service.mainImage.url',
    CATEGORY_NAME: 'service.category.name',
    PRICE_AMOUNT: 'service.price.amount',
    CURRENCY: 'service.price.currency',
    START_DATE: 'service.start-date',
    END_DATE: 'service.end-date',
    PRICE_TEXTUAL: 'service.price.textual',
    SESSION_TYPE: 'service.type',
    FIRST_SESSION: 'service.session.first',
    LAST_SESSION: 'service.session.last',
    SD_DISABLE_SCHEMA: 'service.disable-schema',
    AVAILABILITY: 'service.availability',
    INSTRUCTOR_NAME: 'service.instructor-name',
    INSTRUCTOR_IMAGE: 'service.instructor-image',
    SD_JSON_PROVIDER: 'service.structured-data.provider',
    SD_JSON_IMAGES: 'service.structured-data.images',
    SD_JSON_OFFERS: 'service.structured-data.offers',
    IS_COURSE_SESSION_TYPE: 'service.is-course-session-type',
    MEDIA_ITEMS: 'service.media',
    FULL_DESCRIPTION: 'service.full-description',
};
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.SESSION_TYPE,
    IDs.CALCULATED_SERVICE_URL,
    IDs.PRICE_AMOUNT,
    IDs.CURRENCY,
    IDs.MAIN_IMAGE,
];
var userVisibleSdOnly = [
    IDs.IMAGE_HEIGHT,
    IDs.IMAGE_WIDTH,
    IDs.END_DATE,
    IDs.AVAILABILITY,
    IDs.INSTRUCTOR_NAME,
    IDs.INSTRUCTOR_IMAGE,
    IDs.SD_JSON_PROVIDER,
    IDs.SD_JSON_IMAGES,
    IDs.SD_JSON_OFFERS,
];
var requiredSchemaVariables = function (item) {
    if (item === void 0) { item = {}; }
    var serviceResponse = item.serviceResponse;
    return getSdType(serviceResponse) === SESSION_TYPES.COURSE
        ? [IDs.TITLE, IDs.DESCRIPTION]
        : [];
};
var SchemaTypes = {
    BOOKINGS_SERVICE: 'bookings-service-preset',
};
var getSdStatus = function (item, itemData) {
    var serviceResponse = item.serviceResponse;
    if (hasMissingSchemaVariables(requiredSchemaVariables(item), serviceResponse, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    if (!get(serviceResponse, 'service.info.name') ||
        (get(serviceResponse, 'service.status') || 'created').toLowerCase() !==
            'created') {
        return SD_STATUS.DISABLED_BY_VERTICAL_ITEM_DATA;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        var serviceResponse = item.serviceResponse;
        var sessionType = getSdType(serviceResponse);
        var imageData = getRawValue(serviceResponse, 'service.info.images.0') || {};
        var mediaItems = getServiceMediaItems(serviceResponse) || [];
        var data = __assign((_a = {}, _a[IDs.ID] = get(serviceResponse, 'service.id'), _a[IDs.CALCULATED_SERVICE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BOOKINGS_SERVICE, item, getServiceUrl(serviceResponse)), _a[IDs.TITLE] = get(serviceResponse, 'service.info.name'), _a[IDs.DESCRIPTION] = getBookingDescription(serviceResponse), _a[IDs.STATUS] = get(serviceResponse, 'schedules.0.status') || 'CREATED', _a[IDs.IMAGE_HEIGHT] = imageData.height || '', _a[IDs.IMAGE_WIDTH] = imageData.width || '', _a[IDs.MAIN_IMAGE] = buildOgImagePreviewUrl(imageData) || '', _a[IDs.CATEGORY_NAME] = get(serviceResponse, 'category.name'), _a[IDs.PRICE_AMOUNT] = get(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.amount'), _a[IDs.CURRENCY] = get(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.currency'), _a[IDs.START_DATE] = get(serviceResponse, 'schedules.0.availability.start'), _a[IDs.END_DATE] = get(serviceResponse, 'schedules.0.availability.end'), _a[IDs.PRICE_TEXTUAL] = get(serviceResponse, 'schedules.0.rate.priceText'), _a[IDs.SESSION_TYPE] = sessionType, _a[IDs.FIRST_SESSION] = get(serviceResponse, 'schedules.0.firstSessionStart'), _a[IDs.LAST_SESSION] = get(serviceResponse, 'schedules.0.lastSessionEnd'), _a[IDs.IS_COURSE_SESSION_TYPE] = sessionType === SESSION_TYPES.COURSE, _a[IDs.MEDIA_ITEMS] = mediaItems, _a[IDs.FULL_DESCRIPTION] = getBookingDescription(serviceResponse), _a), getStructuredData(IDs, item));
        var sdStatus = getSdStatus(item, data) !== SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { return get(item, 'serviceResponse.slugs.0.name') || ''; },
    getFullUrl: function (item) { return getServiceUrl(getRawValue(item, 'serviceResponse')); },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        var _a = getUrlParts(url), base = _a.base, path = _a.path;
        item.serviceResponse.urls.servicePageUrl.base = base;
        item.serviceResponse.urls.servicePageUrl.path = path;
        return item;
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        var oldSlug = get(updatedItemData, 'serviceResponse.slugs.0.name');
        if (oldSlug !== '') {
            updatedItemData.serviceResponse.slugs[0].name = slugValue;
        }
        return updatedItemData;
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
    getDefaultSchemaType: function () {
        return SchemaTypes.BOOKINGS_SERVICE;
    },
};
