"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRobotsTagValueSupported = exports.formatRobotsTagForRendering = exports.getRobotsTagValueWithoutDirective = exports.getRobotsTagValueWithDirective = exports.getDirectiveValue = exports.isRobotsDirectiveExists = exports.mergeRobotsValues = exports.mergeRobotsTags = exports.REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE = exports.MAX_VIDEO_PREVIEW_DEFAULT_VALUE = exports.MAX_SNIPPET_DEFAULT_VALUE = exports.MAX_IMAGE_PREVIEW_VALUES = exports.ROBOTS_DIRECTIVES = void 0;
var Identifiers_1 = require("../../types/Identifiers");
var TagSchemas_1 = require("../../types/TagSchemas");
var get_by_identifier_1 = require("../values/get-by-identifier");
var update_by_identifier_1 = require("../values/update-by-identifier");
var ROBOTS_DIRECTIVE_NAME_VALUE_SEPARATOR = ':';
exports.ROBOTS_DIRECTIVES = {
    NOINDEX: 'noindex',
    NOFOLLOW: 'nofollow',
    NOSNIPPET: 'nosnippet',
    NOARCHIVE: 'noarchive',
    NOIMAGEINDEX: 'noimageindex',
    MAX_IMAGE_PREVIEW: 'max-image-preview',
    MAX_SNIPPET: 'max-snippet',
    MAX_VIDEO_PREVIEW: 'max-video-preview',
};
exports.MAX_IMAGE_PREVIEW_VALUES = {
    NONE: 'none',
    STANDARD: 'standard',
    LARGE: 'large',
};
exports.MAX_SNIPPET_DEFAULT_VALUE = '-1';
exports.MAX_VIDEO_PREVIEW_DEFAULT_VALUE = '-1';
var INDEX = 'index';
var noindexRelatedDirectiveNames = new Set([
    exports.ROBOTS_DIRECTIVES.NOINDEX,
    INDEX,
]);
exports.REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE = '$remove$';
var robotsTagSchema = TagSchemas_1.TAG_SCHEMAS[Identifiers_1.IDENTIFIERS.ROBOTS];
var toCanonicalForm = function (directive) { return directive.toLowerCase(); };
var sortDirectives = function (directives) {
    return __spreadArray([], directives, true).sort(function (firstDirective, otherDirective) {
        return firstDirective.localeCompare(otherDirective);
    });
};
var removeTrailingComma = function (directive) { return directive.replace(/,$/g, ''); };
var getDirectiveNameValuePair = function (directive) {
    if (directive === undefined) {
        return [undefined, undefined];
    }
    var nameValueSeparatorIndex = directive.lastIndexOf(ROBOTS_DIRECTIVE_NAME_VALUE_SEPARATOR);
    if (nameValueSeparatorIndex === -1) {
        return [directive, undefined];
    }
    return [
        directive.slice(0, nameValueSeparatorIndex),
        directive.slice(nameValueSeparatorIndex + 1),
    ];
};
var createDirective = function (directive, value) {
    return value !== undefined
        ? "".concat(directive).concat(ROBOTS_DIRECTIVE_NAME_VALUE_SEPARATOR).concat(value)
        : directive;
};
var getDirectiveNameFromDirective = function (directive) {
    return getDirectiveNameValuePair(directive)[0];
};
var getDirectiveValueFromDirective = function (directive) {
    return getDirectiveNameValuePair(directive)[1];
};
var getDirectives = function (robotsTagValue) {
    return (robotsTagValue !== null && robotsTagValue !== void 0 ? robotsTagValue : '')
        .split(' ')
        .filter(function (directive) { return directive !== ''; })
        .map(removeTrailingComma)
        .map(toCanonicalForm);
};
var getRobotsTagValue = function (directives) {
    return sortDirectives(directives)
        .filter(function (directive) { return directive !== ''; })
        .map(removeTrailingComma)
        .map(toCanonicalForm)
        .join(', ');
};
var isVariable = function (directive) { return directive && directive.startsWith('{{'); };
var mergeDirectives = function (specificDirectives, generalDirectives) {
    var directiveNameToDirective = new Map();
    var allDirectives = __spreadArray(__spreadArray([], generalDirectives, true), specificDirectives, true);
    allDirectives.forEach(function (directive) {
        if (isVariable(directive)) {
            // if the directive is a variable, remove all existing variables
            Array.from(directiveNameToDirective.values()).forEach(function (currentDirective) {
                if (isVariable(currentDirective)) {
                    directiveNameToDirective.delete(getDirectiveNameFromDirective(currentDirective));
                }
            });
        }
        var directiveName = getDirectiveNameFromDirective(directive);
        if (noindexRelatedDirectiveNames.has(directiveName)) {
            // if the directive is related to noindex, remove all existing directives related to noindex
            noindexRelatedDirectiveNames.forEach(function (relatedDirectiveName) {
                directiveNameToDirective.delete(relatedDirectiveName);
            });
        }
        directiveNameToDirective.set(getDirectiveNameFromDirective(directive), directive);
    });
    var mergedDirectives = Array.from(directiveNameToDirective.values());
    if (mergedDirectives.some(function (directive) { return !isVariable(directive); })) {
        // if there's at least one non-variable directive remove all variables
        return mergedDirectives.filter(function (directive) { return !isVariable(directive); });
    }
    return mergedDirectives;
};
var mergeRobotsTags = function (specificRobotsTag, generalRobotsTag) {
    var specificRobotsValue = robotsTagSchema.getValue(specificRobotsTag);
    var generalRobotsValue = robotsTagSchema.getValue(generalRobotsTag);
    var mergedValue = (0, exports.mergeRobotsValues)(specificRobotsValue, generalRobotsValue);
    var mergedTag = __assign({}, specificRobotsTag);
    robotsTagSchema.setValue(mergedTag, mergedValue);
    return mergedTag;
};
exports.mergeRobotsTags = mergeRobotsTags;
var mergeRobotsValues = function (specificRobotsValue, generalRobotsValue) {
    var specificDirectives = getDirectives(specificRobotsValue);
    var generalDirectives = getDirectives(generalRobotsValue);
    var mergedDirectives = mergeDirectives(specificDirectives, generalDirectives);
    var mergedValue = getRobotsTagValue(mergedDirectives);
    return mergedValue;
};
exports.mergeRobotsValues = mergeRobotsValues;
var isRobotsDirectiveExists = function (robotsTagValue, directiveName) {
    return getDirectives(robotsTagValue).some(function (currentDirective) {
        var _a = getDirectiveNameValuePair(currentDirective), name = _a[0], value = _a[1];
        return (name === directiveName && value !== exports.REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE);
    });
};
exports.isRobotsDirectiveExists = isRobotsDirectiveExists;
var getDirectiveValue = function (robotsTagValue, directiveName) {
    var directive = getDirectives(robotsTagValue).find(function (currentDirective) {
        return getDirectiveNameFromDirective(currentDirective) === directiveName;
    });
    return directive && getDirectiveValueFromDirective(directive);
};
exports.getDirectiveValue = getDirectiveValue;
var getRobotsTagValueWithDirective = function (robotsTagValue, directiveName, directiveValue) {
    if (directiveValue === void 0) { directiveValue = undefined; }
    var newDirective = createDirective(directiveName, directiveValue);
    var existingDirectives = getDirectives(robotsTagValue);
    var newDirectives = mergeDirectives([newDirective], existingDirectives);
    return getRobotsTagValue(newDirectives);
};
exports.getRobotsTagValueWithDirective = getRobotsTagValueWithDirective;
var getRobotsTagValueWithoutDirective = function (robotsTagValue, directiveName) {
    var newDirectives = getDirectives(robotsTagValue).filter(function (directive) { return getDirectiveNameFromDirective(directive) !== directiveName; });
    return getRobotsTagValue(newDirectives);
};
exports.getRobotsTagValueWithoutDirective = getRobotsTagValueWithoutDirective;
var formatRobotsTagForRendering = function (tags) {
    var robotsTagValue = (0, get_by_identifier_1.getValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.ROBOTS);
    var directives = getDirectives(robotsTagValue)
        .filter(function (directive) { return getDirectiveNameFromDirective(directive) !== ''; })
        .filter(function (directive) {
        return getDirectiveValueFromDirective(directive) !==
            exports.REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE;
    });
    var updatedValue = getRobotsTagValue(directives);
    return (0, update_by_identifier_1.updateValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.ROBOTS, updatedValue);
};
exports.formatRobotsTagForRendering = formatRobotsTagForRendering;
var isRobotsTagValueSupported = function (robotsTagValue) {
    var supportedRobotDirectives = new Set(__spreadArray(__spreadArray([], Array.from(Object.values(exports.ROBOTS_DIRECTIVES)), true), [
        INDEX,
    ], false));
    return getDirectives(robotsTagValue).every(function (directive) {
        var directiveName = getDirectiveNameFromDirective(directive);
        return supportedRobotDirectives.has(directiveName) || isVariable(directive);
    });
};
exports.isRobotsTagValueSupported = isRobotsTagValueSupported;
