import { buildStructuredData } from '../../types/builders';
import giftCardSchema from './schema-presets/gift-card';
import adapter from './adapter-gift-card';
var IDs = adapter.IDs;
export var GIFT_CARD = {
    tags: [
        buildStructuredData({
            schema: giftCardSchema.schema,
            schemaType: giftCardSchema.type,
            disabled: [
                "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ],
};
