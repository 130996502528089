import React from 'react';
import { node, any } from 'prop-types';

export default class RenderIf extends React.PureComponent {
  static propTypes = {
    children: node,
    truthy: any,
  };

  render() {
    const { truthy, children } = this.props;
    return truthy ? children : null;
  }
}
