import adapter from '../adapter-bookings-service';
var IDs = adapter.IDs, SchemaTypes = adapter.SchemaTypes;
export default {
    type: SchemaTypes.BOOKINGS_SERVICE,
    schema: {
        '@context': 'https://schema.org/',
        '@type': "{{".concat(IDs.SESSION_TYPE, "}}"),
        name: "{{".concat(IDs.TITLE, "}}"),
        description: "{{".concat(IDs.DESCRIPTION, "}}"),
        provider: "{{".concat(IDs.SD_JSON_PROVIDER, "}}"),
        image: "{{".concat(IDs.SD_JSON_IMAGES, "}}"),
        offers: "{{".concat(IDs.SD_JSON_OFFERS, "}}"),
    },
};
