import React from 'react';
import {
  updateValueByIdentifier,
  getDefaultValueByIdentifier,
  updateCustomTags,
  getEmptyTags,
  getValueByIdentifier,
  compareCustomTags,
  updateStructuredData,
  deleteStructuredData,
  isRobotsDirectiveExists,
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
  ROBOTS_DIRECTIVES,
  getDirectiveValue,
} from '@wix/advanced-seo-utils';
import * as BI_TYPES from '../bi/action-types';
import { getIdentifierByKey, getScaledLargeImagePreview } from '../utils/utils';
import getErrorMessage from '../utils/errors';
import { KEYS, UI_ONLY_KEYS, EDITOR_DATA, ADV_SEO_DATA } from '../utils/maps';
import flatMap from 'lodash/flatMap';
import _pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import {
  buildState,
  mergeBlobsAndTransform,
  fillIn,
  getAdvancedTagIndexByLabel,
  buildStateTag,
  getValuesAfterValidation,
  getAdvancedTagPropByLabel,
  getErrorKey,
  stucturedDataGetter,
  buildStructuredDataState,
} from '../app-base';
import { initMergedPattern } from '../initializers';
import { IDENTIFIERS } from '@wix/advanced-seo-utils/dist/src/private/types/Identifiers';
import { getErrorType } from '../bi/errors-types';
import { BI_FIELD_ACTION } from '../bi/constants';
import { getPatternByItemType } from '../initializers/pattern-by-item-type';

export default class BaseApplication extends React.Component {
  constructor(props, state) {
    super(props, state);
    this.state = { data: {}, experiments: {} };
  }

  getDataForPanelCloseBi() {
    const dirtyFields = this.dirtyFields ?? new Map();
    return {
      context: this.biContext,
      itemType: this.itemType,
      numberOfChanges: dirtyFields?.size,
      data: JSON.stringify(Object.fromEntries(dirtyFields)),
    };
  }

  reportPanelCollapseBiEvent() {
    this.logBiEvent(BI_TYPES.SEO_PANEL_COLLAPSE, this.getDataForPanelCloseBi());
  }

  componentWillUnmount() {
    this.reportPanelCollapseBiEvent();
  }

  updateDirtyFields = (key, data) => {
    if (!this.dirtyFields) {
      this.dirtyFields = new Map();
    }

    let initialValue;
    let currentValue;
    let tagKey = key;

    if (data?.generalIdentifier) {
      const label = data?.label;
      const initialTag = this.initialState?.[key]?.find(
        (tag) => tag.label === label,
      );

      initialValue = [initialTag?.value, initialTag?.isDisabled].join(', ');
      currentValue = [data?.value, data?.isDisabled].join(', ');
      tagKey = [key, data?.label].join(' - ');
    } else {
      if (!data?.target) {
        return;
      } else {
        initialValue = this.initialState?.[tagKey]?.value;
        currentValue = data?.target?.value;
      }
    }

    if (initialValue === currentValue) {
      this.dirtyFields.delete(tagKey);
      return;
    }

    if (key === KEYS.ROBOTS_TAG) {
      const directive = ROBOTS_DIRECTIVES.NOINDEX;

      const isSetToNoIndex =
        isRobotsDirectiveExists(currentValue, directive) ||
        (getDirectiveValue(initialValue, directive) ===
          REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE &&
          !isRobotsDirectiveExists(currentValue, directive));

      const isSetToIndex =
        getDirectiveValue(currentValue, directive) ===
          REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE ||
        (!isRobotsDirectiveExists(currentValue, directive) &&
          isRobotsDirectiveExists(initialValue, directive));

      if (!isSetToNoIndex && !isSetToIndex) {
        this.dirtyFields.delete(tagKey);
        return;
      }

      this.dirtyFields.set(
        tagKey,
        isSetToNoIndex
          ? BI_FIELD_ACTION.SET_TO_NO_INDEX
          : BI_FIELD_ACTION.SET_TO_INDEX,
      );
      return;
    }

    if (!initialValue) {
      this.dirtyFields.set(tagKey, BI_FIELD_ACTION.CREATED);
      return;
    }
    if (initialValue && !currentValue) {
      this.dirtyFields.set(tagKey, BI_FIELD_ACTION.DELETED);
      return;
    }
    this.dirtyFields.set(tagKey, BI_FIELD_ACTION.UPDATED);
  };

  resetTagError = (key) => (data) => {
    let nextValue;
    switch (key) {
      case KEYS.ADVANCED_TAGS: {
        const tagIndex = getAdvancedTagIndexByLabel(
          this.state[KEYS.ADVANCED_TAGS],
          data.label,
        );
        const advancedTagsArray = cloneDeep(this.state[key]);
        advancedTagsArray[tagIndex].error = '';
        nextValue = advancedTagsArray;
        break;
      }

      case KEYS.CUSTOM_TAGS_ARRAY: {
        const customTagsArray = cloneDeep(this.state[key]);
        customTagsArray[data.index].error = '';
        nextValue = customTagsArray;
        break;
      }

      default:
        break;
    }
    if (nextValue) {
      this.setState({ [key]: nextValue });
    }
  };

  onAddTempTag = (key) => () => {
    if (key === KEYS.CUSTOM_TAGS_ARRAY) {
      const customTagsArray = cloneDeep(this.state[key]);
      const newTag = buildStateTag();
      newTag.temp = true;
      customTagsArray.push(newTag);
      this.setState({ customTagsArray });
    }
  };

  onRemoveTempTag = (key) => () => {
    if (key === KEYS.CUSTOM_TAGS_ARRAY) {
      const customTagsArray = cloneDeep(this.state[key]);
      customTagsArray.pop();
      this.setState({ customTagsArray });
    }
  };

  updateSchemasMap(schemas, updatedSchema) {
    return schemas.map((scehma) =>
      scehma.schemaType === updatedSchema?.schemaType
        ? {
            ...scehma,
            schema: updatedSchema.schema || scehma.schema,
            schemaType: updatedSchema.schemaType,
            disabled: updatedSchema.disabled,
          }
        : scehma,
    );
  }

  clearStaleSchemas = (advancedSeoData, schemasMap) => {
    const validSchemaTypes = schemasMap?.map(({ schemaType }) => schemaType);
    const tags = advancedSeoData?.tags?.filter((tag) =>
      tag.type === 'script'
        ? validSchemaTypes?.includes(tag?.meta?.schemaType)
        : true,
    );

    return {
      ...advancedSeoData,
      tags,
    };
  };

  onToggleSdHide = (schema) => {
    const toggledSchema = {
      ...schema,
      disabled: !schema.disabled,
    };

    const advancedSeoData = updateStructuredData(
      this.state.advancedSeoData,
      toggledSchema,
    );

    const schemasMap = this.updateSchemasMap(
      this.state.schemasMap,
      toggledSchema,
    );

    this.saveAndUpdateState &&
      this.saveAndUpdateState(
        KEYS.SCHEMAS_MAP,
        schemasMap,
        this.clearStaleSchemas(advancedSeoData, schemasMap),
      );
  };

  onSdDelete = (schema) => {
    const advancedSeoData = deleteStructuredData(
      this.state.advancedSeoData,
      schema,
    );

    const structuredData = stucturedDataGetter(advancedSeoData);
    const schemasMap = buildStructuredDataState(structuredData);

    this.saveAndUpdateState &&
      this.saveAndUpdateState(KEYS.SCHEMAS_MAP, schemasMap, advancedSeoData);
  };

  onSdEdit = (schema) => {
    const advancedSeoData = updateStructuredData(
      this.state.advancedSeoData,
      schema,
      { sourceSchemaType: schema.schemaType },
    );

    const structuredData = stucturedDataGetter(advancedSeoData);
    const schemasMap = buildStructuredDataState(structuredData);

    this.saveAndUpdateState &&
      this.saveAndUpdateState(KEYS.SCHEMAS_MAP, schemasMap, advancedSeoData);
  };

  updateSchema = (key, value) => {
    if (flatMap([EDITOR_DATA, ADV_SEO_DATA], Object.keys).includes(key)) {
      let data = this.state.data;
      if (key === KEYS.CUSTOM_TAGS) {
        data = updateCustomTags(data, value);
      } else {
        data = this.updateByIdentifier(data, key, value);
      }
      if (data) {
        this.setState({ data });
      }
      return data;
    }
  };

  updateByIdentifier = (data, key, value) => {
    const identifier = getIdentifierByKey(key);
    if (!identifier) {
      return data;
    }

    if (key === KEYS.IS_INDEX_ENABLED) {
      value = value ? '' : 'noindex';
    }

    let meta = null;
    if ((key === KEYS.OG_IMAGE || key === KEYS.TWITTER_IMAGE) && value) {
      meta = { width: value.width, height: value.height };
      value = value.uri;
    }

    return updateValueByIdentifier(data, identifier, value, meta);
  };

  createImageValidator =
    (key) =>
    async (value, resolve, reject, data = {}) => {
      let newValue = null;
      switch (value) {
        case 'delete':
          this.setState(
            (state) => ({
              [key]: {
                ...state[key],
                value: { uri: '', width: 0, height: 0 },
                error: '',
              },
            }),
            resolve,
          );
          break;
        case 'adjust':
          try {
            const { defaultSiteImageUrl = '' } = data;
            const imageUrl =
              (this.state[key].value || {}).uri || defaultSiteImageUrl;
            newValue = await this.getAdjustedImageFromPS(imageUrl);
          } catch (e) {
            reject();
          }
          break;
        default:
        case 'change':
          try {
            newValue = await this.getImageFromMM();
          } catch (e) {
            reject();
          }
          break;
      }
      return newValue;
    };

  validateOgImage = this.createImageValidator(KEYS.OG_IMAGE);

  validateTwitterImage = this.createImageValidator(KEYS.TWITTER_IMAGE);

  validateAdvancedTags = (value, tag, validation) => {
    const newArray = cloneDeep(this.state[KEYS.ADVANCED_TAGS]);
    const stateItemIndex = getAdvancedTagIndexByLabel(
      this.state[KEYS.ADVANCED_TAGS],
      tag.label,
    );

    if (stateItemIndex >= 0) {
      newArray[stateItemIndex] = buildStateTag(
        {
          ...tag,
          ...getValuesAfterValidation(
            validation,
            newArray[stateItemIndex].value,
            tag.label,
            this.i18n || { t: this.props.t },
            this.getErrorMapFlags(),
          ),
        },
        this.state[KEYS.ADVANCED_TAGS][stateItemIndex],
      );
    }
    return newArray;
  };

  validateAndSetState = (key) => (value, data) => {
    this.updateDirtyFields(key, { target: { value } });
    return new Promise(async (resolve, reject) => {
      if (key === KEYS.OG_IMAGE) {
        value = await this.validateOgImage(value, resolve, reject, data);
        if (value === null) {
          return;
        }
      }

      if (key === KEYS.TWITTER_IMAGE) {
        value = await this.validateTwitterImage(value, resolve, reject, data);
        if (value === null) {
          return;
        }
      }

      if (key === KEYS.URI) {
        value = value.replace(/\s/g, '');
      }

      this.validate =
        this.validate || (this.validators && this.validators.validate);

      const result = this.validate(key, value, data);
      if (
        !result.isValid &&
        (key === KEYS.OG_IMAGE || key === KEYS.TWITTER_IMAGE)
      ) {
        value = this.state[key].value;
      }

      let updatedValue;
      if (key === KEYS.ADVANCED_TAGS) {
        updatedValue = this.validateAdvancedTags(value, data, result);
      } else {
        updatedValue = buildStateTag(
          getValuesAfterValidation(
            result,
            value,
            key,
            this.i18n || { t: this.props.t },
            this.getErrorMapFlags(),
          ),
          this.state[key],
        );
      }

      this.setState(
        () => ({
          [key]: updatedValue,
        }),
        resolve,
      );
    });
  };

  createContext = () => {
    return this.getContext();
  };

  getImageFromMM = () => {
    const { mediaManager } = this.props;
    return new Promise((resolve, reject) => {
      mediaManager.open(mediaManager.categories.IMAGE, {
        onSuccess: ({ items: [{ uri, width, height }] }) => {
          resolve({ uri, width, height });
        },
        onCancel: reject,
        multiSelect: false,
        restrictContent: true,
      });
    });
  };
  getAdjustedImageFromPS = (uri) => {
    const { openMediaStudio } = this.props;
    return new Promise((resolve, reject) => {
      if (openMediaStudio) {
        openMediaStudio(uri, (payload) => {
          const { id, width, height } = payload;
          resolve({ uri: id, width, height });
        });
      } else {
        reject();
      }
    });
  };

  getDefaultValueByKey = (key) => {
    const defaultVal = EDITOR_DATA[key] && EDITOR_DATA[key].defaultVal;
    const identifier = getIdentifierByKey(key);
    const context = this.getContext();
    return (
      defaultVal ||
      (identifier
        ? getDefaultValueByIdentifier(this.state.data, identifier, context)
        : undefined)
    );
  };

  getCustomUpdatedScheme(data, value, schema, removeTag) {
    if (!schema?.tags) {
      schema = { tags: [] };
    }
    const list = this.getters[KEYS.CUSTOM_TAGS_ARRAY](schema) || [];
    const oldValue = list[data.index] ? list[data.index].html : '';
    const newSchema = this.setters[KEYS.CUSTOM_TAGS_ARRAY](
      schema,
      data,
      oldValue,
      value,
      removeTag,
    );
    return newSchema
      ? {
          tags: newSchema,
        }
      : schema;
  }

  getAdvancedUpdatedScheme(data, value, schema) {
    if (!schema?.tags) {
      schema = { tags: [] };
    }
    const list = this.getters[KEYS.ADVANCED_TAGS](schema) || [];
    const oldValue = getAdvancedTagPropByLabel(list, data.label) || '';
    const oldIsDisabled = getAdvancedTagPropByLabel(list, 'isDisabled');
    if (!oldValue && oldIsDisabled) {
      value = '';
    }
    const isDisabled = getAdvancedTagPropByLabel(
      list,
      data.label,
      'isDisabled',
    );
    const newSchema = this.setters[KEYS.ADVANCED_TAGS](
      schema,
      data,
      oldValue,
      value,
      isDisabled !== data.isDisabled,
    );
    return newSchema
      ? {
          tags: newSchema,
        }
      : schema;
  }

  getUpdatedSchema(key, value, schema) {
    const oldValue = this.getters[key](schema).value || '';
    return this.setters[key](schema, oldValue, value) || schema;
  }

  buildStateSafely(visibleData, { keyToValidateByValue = '' } = {}) {
    return _pickBy(
      buildState(
        visibleData,
        this.getters,
        this.getDataWithDefaults({
          isDomainConnected: this.props.isDomainConnected,
        }),
      ),
      (v, k) => {
        return !(keyToValidateByValue === k ? v : this.state[k]).error;
      },
    );
  }

  handleBlurError = (key, validationResult, data) => {
    const shouldLogErrorToBI = [
      KEYS.URI,
      KEYS.TITLE,
      KEYS.OG_TITLE,
      KEYS.DESCRIPTION,
      KEYS.OG_DESCRIPTION,
      KEYS.CUSTOM_TAGS,
      KEYS.CUSTOM_TAGS_ARRAY,
      KEYS.JSON_LD,
      KEYS.ADVANCED_TAGS,
      KEYS.TWITTER_TITLE,
      KEYS.TWITTER_DESCRIPTION,
    ].includes(key);
    if (shouldLogErrorToBI) {
      this.logBiEvent(BI_TYPES.PROMOTE_SEO_ERROR_VIEW, {
        errorType: getErrorType(key, validationResult.error),
        errorName: key,
      });
    }
    const error =
      getErrorMessage(
        validationResult.error,
        getErrorKey(key, data),
        this.i18n || { t: this.props.t },
        this.getErrorMapFlags(),
      ) || validationResult.error;

    let nextValue;

    switch (key) {
      case KEYS.ADVANCED_TAGS: {
        const stateItemIndex = getAdvancedTagIndexByLabel(
          this.state[KEYS.ADVANCED_TAGS],
          data.label,
        );
        const nextAdvancedTagsList = cloneDeep(this.state[key]);
        nextAdvancedTagsList[stateItemIndex].error = error;
        nextAdvancedTagsList[stateItemIndex].value =
          nextAdvancedTagsList[stateItemIndex].value || '';
        nextValue = nextAdvancedTagsList;
        break;
      }

      case KEYS.CUSTOM_TAGS_ARRAY: {
        const nextCustomTagsList = cloneDeep(this.state[key]);
        nextCustomTagsList[data.index].error = error;
        nextCustomTagsList[data.index].value =
          nextCustomTagsList[data.index].value || '';
        nextValue = nextCustomTagsList;
        break;
      }

      case KEYS.URI:
        nextValue = { ...this.state[key], error };
        break;

      default:
        nextValue = { ...this.state[key] };
    }

    this.setState({
      [key]: nextValue,
    });
  };

  handleFieldUpdateBi = ({
    key,
    value,
    logger,
    data,
    prevState,
    updateType,
  }) => {
    let fieldName = key;
    let biEventValue = value;

    switch (key) {
      case KEYS.OG_IMAGE:
        biEventValue =
          value && value.uri ? getScaledLargeImagePreview(value.uri) : {};
        break;

      case KEYS.TWITTER_IMAGE:
        biEventValue =
          value && value.uri ? getScaledLargeImagePreview(value.uri) : {};
        break;

      case KEYS.SCHEMAS_MAP:
        const schemas = value?.schema || value?.value || value;
        if (Array.isArray(schemas)) {
          biEventValue = schemas.map((schema) => schema.value).join();
        } else {
          biEventValue = schemas;
        }
        break;

      case KEYS.ADVANCED_TAGS: {
        if (data) {
          const prevIsDisabled = getAdvancedTagPropByLabel(
            prevState[KEYS.ADVANCED_TAGS],
            data.label,
            'isDisabled',
          );
          fieldName = data.label;
          biEventValue = value || data.value;
          if (prevIsDisabled !== data.isDisabled) {
            biEventValue = `${biEventValue}${biEventValue.length ? '+' : ''}${
              data.isDisabled ? 'disable' : 'enable'
            }`;
          }
        }
        break;
      }

      case KEYS.CUSTOM_TAGS_ARRAY:
        fieldName = KEYS.CUSTOM_TAGS;
        break;

      default:
        break;
    }

    logger(BI_TYPES.PROMOTE_SEO_FIELD_UPDATE, {
      fieldName,
      updatedValue: biEventValue,
      updateType,
    });
  };

  createVisibleData = ({ advancedSeoData, legacyData, isDomainConnected }) => {
    const defaultData = this.getDataWithDefaults({
      advancedSeoData,
      legacyData,
      isDomainConnected,
      isHeadlessSite: this.isHeadlessSite,
    });

    return mergeBlobsAndTransform(
      !!this.isTwitterCardValueSupportedOrMissing,
      !!this.isRobotsTagValueSupported,
      defaultData,
      legacyData,
      advancedSeoData,
    );
  };

  getDataWithDefaults({
    advancedSeoData = {},
    legacyData = {},
    isDomainConnected,
  } = {}) {
    const defaultPattern = this.isHeadlessSite
      ? getEmptyTags()
      : initMergedPattern(this.props, this.itemType);
    const defaultPatternCustomTags = defaultPattern.tags.filter(
      (tag) => tag.custom,
    );
    const mergedPattern = mergeBlobsAndTransform(
      this.isTwitterCardValueSupportedOrMissing,
      this.isRobotsTagValueSupported,
      defaultPattern,
      legacyData,
      advancedSeoData,
    );
    if (!isDomainConnected) {
      mergedPattern.settings = { preventAutoRedirect: true };
    }
    mergedPattern.tags.forEach((mergedTag) => {
      defaultPatternCustomTags.forEach((defaultCustomTag) => {
        if (compareCustomTags(mergedTag, defaultCustomTag)) {
          mergedTag.custom = true;
          mergedTag.isDefault = true;
        }
      });
    });

    const siteContextPayload = this.getSiteContextPayload();
    return fillIn(mergedPattern, siteContextPayload);
  }

  getValueWithoutDefault(key, data = {}) {
    if (data.tempItem) {
      return '';
    }
    const value =
      key === KEYS.ADVANCED_TAGS ||
      key === KEYS.CUSTOM_TAGS_ARRAY ||
      key === KEYS.PREVENT_AUTO_REDIRECT
        ? data.value
        : this.state[key]
        ? this.state[key].value
        : data;

    const isUiKey = Object.values(UI_ONLY_KEYS).includes(key);
    if (typeof value !== 'string' || isUiKey) {
      return value;
    }
    const defaultData = this.buildStateSafely(this.getDataWithDefaults());
    let defaultValue;
    switch (key) {
      case KEYS.ADVANCED_TAGS: {
        const isPrevDisabled = getAdvancedTagPropByLabel(
          this.state[key],
          data.label,
          'isDisabled',
        );
        defaultValue =
          defaultData[key] &&
          getAdvancedTagPropByLabel(defaultData[key], data.label);
        if (isPrevDisabled && !defaultValue) {
          defaultValue = '';
        }
        break;
      }

      case KEYS.CUSTOM_TAGS_ARRAY:
        defaultValue = '';
        break;

      default:
        defaultValue = defaultData[key] && defaultData[key].value;
    }

    return value === defaultValue ? '' : value;
  }

  getIsIndexEnabledFromUserPattern = () => {
    return !isRobotsDirectiveExists(
      getValueByIdentifier(
        initMergedPattern(this.props, this.itemType),
        IDENTIFIERS.ROBOTS,
      ),
      ROBOTS_DIRECTIVES.NOINDEX,
    );
  };
  getOGImageFromUserPattern = () =>
    getValueByIdentifier(
      getPatternByItemType(this.props, this.itemType),
      IDENTIFIERS.OG_IMAGE,
    );

  getOGImageFromAdvancedBlob = () =>
    getValueByIdentifier(this.state.advancedSeoData, IDENTIFIERS.OG_IMAGE);

  getTwitterImageFromUserPattern = () =>
    getValueByIdentifier(
      getPatternByItemType(this.props, this.itemType),
      IDENTIFIERS.TWITTER_IMAGE,
    );

  getTwitterImageFromAdvancedBlob = () =>
    getValueByIdentifier(this.state.advancedSeoData, IDENTIFIERS.TWITTER_IMAGE);

  removeError = (key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        error: '',
      },
    });
  };

  removeWarning = (key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        warning: false,
      },
    });
  };
  getErrorMapFlags = () => ({});
}
