"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var schema_ids_1 = require("./schema-ids");
var schema_types_1 = require("./schema-types");
var requiredSchemaVariables = [schema_ids_1.IDs.FAQ];
exports.default = {
    type: schema_types_1.SCHEMA_TYPES.BLOG_POST_FAQ,
    schema: {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: "{{".concat(schema_ids_1.IDs.FAQ, "}}"),
    },
    requiredSchemaVariables: requiredSchemaVariables,
};
