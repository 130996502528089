"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getName = void 0;
var is_accessible_1 = require("./is-accessible");
var get_1 = require("../../../../utils/get");
var getName = function (item) {
    if (item === void 0) { item = {}; }
    if (!(item === null || item === void 0 ? void 0 : item.program)) {
        return '';
    }
    var name = (0, get_1.get)(item, 'program.settings.description.title');
    return (0, is_accessible_1.isAccessible)(item) ? name : 'Access Forbidden';
};
exports.getName = getName;
