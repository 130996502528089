import { EDITOR_DATA, ADV_SEO_DATA } from './maps';
import { buildOgImagePreview } from '@wix/advanced-seo-utils/renderer';
import { convertUrlMultilingual } from '@wix/advanced-seo-utils/converters';
import * as BI_TYPES from '../bi/action-types';

import {
  TWITTER_IMAGE_SMALL_WIDTH,
  TWITTER_IMAGE_SMALL_HEIGHT,
} from './twitter-tags';

export const getIdentifierByKey = (key) =>
  (({ ...EDITOR_DATA, ...ADV_SEO_DATA }[key] || {}).identifier);

export const getHostnameFromUrl = ({ url, removeSubdomain = false } = {}) => {
  try {
    const urlObject = new URL(url);
    const hostName = removeSubdomain
      ? urlObject.hostname.replace(/^www./, '')
      : urlObject.hostname;
    const path = urlObject.pathname !== '/' && urlObject.pathname;
    const urlWithPath = path ? [hostName, path].join('') : hostName;
    return urlObject.search
      ? [urlWithPath, urlObject.search.toString()].join('')
      : urlWithPath;
  } catch (e) {
    return url;
  }
};

export const getScaledLargeImagePreview = (url) =>
  buildOgImagePreview(url, 430 * 2, 226 * 2, 'fill');

export const getScaledTwitterSmallImagePreview = (url) =>
  buildOgImagePreview(
    url,
    TWITTER_IMAGE_SMALL_WIDTH * 2,
    TWITTER_IMAGE_SMALL_HEIGHT * 2,
    'fill',
  );

export const getUrlPath = (fullUrl, baseUrl) =>
  fullUrl.replace(baseUrl, '').replace(/^\//, '');

export const buildDefaultUrl = (
  publicURL,
  { current, original },
  mlResolutionMethod,
) => {
  const url = publicURL.replace(/\/$/, '') || '';
  if (current && original && current !== original) {
    const convertedUrl = convertUrlMultilingual({
      urlString: url,
      lang: current,
      mlResolutionMethod,
    });
    return convertedUrl;
  }
  return url;
};
export const getIsDomainConnected = async (
  metaSiteId,
  logBiEvent,
  httpClient,
) => {
  let hasError, isConnected;

  try {
    const {
      data: { domains },
    } = await httpClient.get(
      `/_api/wix-html-live-site-data-webapp/html_embeds/${metaSiteId}`,
    );
    isConnected = domains.length > 0;
    hasError = false;
  } catch (error) {
    hasError = true;
    logBiEvent(BI_TYPES.PROMOTE_SEO_PANEL_API_FAILED_CALL, {
      apiName: 'Fech domain info',
      errorType: error.message,
    });
  }
  return { isConnected, hasError };
};

export const getItemTypeKebabCase = (itemType) => {
  return itemType.replace('_', '-').toLowerCase();
};
