var _a;
import { ITEM_TYPES } from '../types/ItemTypes';
import { enrichWithSiteLevelData } from './utils';
import defaultAdapter from '../item-types/default/adapter-default';
import storesProductAdapter from '../item-types/stores-product/adapter-stores-product';
import staticPageAdapter from '../item-types/static-page/adapter-static-page';
import staticPageAdapterV2 from '../item-types/static-page-v2/adapter-static-page-v2';
import forumCategoryAdapter from '../item-types/forum-category/adapter-forum-category';
import forumPostAdapter from '../item-types/forum-post/adapter-forum-post';
import proGalleryItemAdapter from '../item-types/pro-gallery-item/adapter-pro-gallery-item';
import blogCategoryAdapter from '../item-types/blog-category/adapter-blog-category';
import blogPostAdapter from '../item-types/blog-post/adapter-blog-post';
import blogTagsAdapter from '../item-types/blog-tags/adapter-blog-tags';
import blogArchiveAdapter from '../item-types/blog-archive/adapter-blog-archive';
import groupsPostAdapter from '../item-types/groups-post/adapter-groups-post';
import groupsPageAdapter from '../item-types/groups-page/adapter-groups-page';
import eventsPageAdapter from '../item-types/events-page/adapter-events-page';
import challengesPageAdapter from '../item-types/challenges-page/adapter-challenges-page';
import bookingsServiceAdapter from '../item-types/bookings-service/adapter-bookings-service';
import breadcrumbsComponentAdapter from '../item-types/breadcrumbs-component/adapter-breadcrumbs-component';
import searchPageAdapter from '../item-types/search-page/adapter-search-page';
import blogHashtagsAdapter from '../item-types/blog-hashtags/adapter-blog-hashtags';
import bookingsCalendarAdapter from '../item-types/bookings-calendar/adapter-bookings-calendar';
import restaurantsOrderPageAdapter from '../item-types/restaurants-order-page/adapter-restaurants-order-page';
import bookingsFormAdapter from '../item-types/bookings-form/adapter-bookings-form';
import videoComponentAdapter from '../item-types/video-component/adapter-video-component';
import membersAreaProfileAdapter from '../item-types/members-area-profile/adapter-members-area-profile';
import portfolioCollectionsAdapter from '../item-types/portfolio-collections/adapter-portfolio-collections';
import portfolioProjectsAdapter from '../item-types/portfolio-projects/adapter-portfolio-projects';
import giftCardAdapter from '../item-types/gift-card/adapter-gift-card';
import schedulePageAdapter from '../item-types/schedule-page/adapter-schedule-page';
import wixDataPageItemAdapter from '../item-types/wix-data-page-item/adapter-wix-data-page-item';
import reviewsAdapter from '../item-types/reviews-component/adapter-reviews-component';
import storesCategoryAdapter from '../item-types/stores-category/adapter-stores-category';
import storesGalleryAdapter from '../item-types/stores-gallery-component/adapter-stores-gallery-component';
import restaurantsMenuPageAdapter from '../item-types/restaurants-menu-page/adapter-restaurants-menu-page';
import membersAreaProfileTabsAdapter from '../item-types/members-area-profile-tabs/adapter-members-area-profile-tabs';
import programsComponentAdapter from '../item-types/programs-component/adapter-programs-component';
import servicesComponentAdapter from '../item-types/services-component/adapter-services-component';
import paymentPageAdapter from '../item-types/payment-page/adapter-payment-page';
import thankYouPageAdapter from '../item-types/thank-you-page/adapter-thank-you-page';
import restaurantsMenuComponentAdapter from '../item-types/restaurants-menu-component/adapter-restaurants-menu-component';
export var adapters = (_a = {},
    _a[ITEM_TYPES.STORES_PRODUCT] = storesProductAdapter,
    _a[ITEM_TYPES.STATIC_PAGE] = staticPageAdapter,
    _a[ITEM_TYPES.STATIC_PAGE_V2] = staticPageAdapterV2,
    _a[ITEM_TYPES.FORUM_CATEGORY] = forumCategoryAdapter,
    _a[ITEM_TYPES.FORUM_POST] = forumPostAdapter,
    _a[ITEM_TYPES.PRO_GALLERY_ITEM] = proGalleryItemAdapter,
    _a[ITEM_TYPES.BLOG_CATEGORY] = blogCategoryAdapter,
    _a[ITEM_TYPES.BLOG_POST] = blogPostAdapter,
    _a[ITEM_TYPES.BLOG_TAGS] = blogTagsAdapter,
    _a[ITEM_TYPES.BLOG_ARCHIVE] = blogArchiveAdapter,
    _a[ITEM_TYPES.GROUPS_POST] = groupsPostAdapter,
    _a[ITEM_TYPES.GROUPS_PAGE] = groupsPageAdapter,
    _a[ITEM_TYPES.EVENTS_PAGE] = eventsPageAdapter,
    _a[ITEM_TYPES.CHALLENGES_PAGE] = challengesPageAdapter,
    _a[ITEM_TYPES.SEARCH_PAGE] = searchPageAdapter,
    _a[ITEM_TYPES.BOOKINGS_SERVICE] = bookingsServiceAdapter,
    _a[ITEM_TYPES.BREADCRUMBS_COMPONENT] = breadcrumbsComponentAdapter,
    _a[ITEM_TYPES.BLOG_HASHTAGS] = blogHashtagsAdapter,
    _a[ITEM_TYPES.BOOKINGS_CALENDAR] = bookingsCalendarAdapter,
    _a[ITEM_TYPES.BOOKINGS_FORM] = bookingsFormAdapter,
    _a[ITEM_TYPES.RESTAURANTS_ORDER_PAGE] = restaurantsOrderPageAdapter,
    _a[ITEM_TYPES.VIDEO_COMPONENT] = videoComponentAdapter,
    _a[ITEM_TYPES.MEMBERS_AREA_PROFILE] = membersAreaProfileAdapter,
    _a[ITEM_TYPES.PORTFOLIO_COLLECTIONS] = portfolioCollectionsAdapter,
    _a[ITEM_TYPES.PORTFOLIO_PROJECTS] = portfolioProjectsAdapter,
    _a[ITEM_TYPES.GIFT_CARD] = giftCardAdapter,
    _a[ITEM_TYPES.SCHEDULE_PAGE] = schedulePageAdapter,
    _a[ITEM_TYPES.WIX_DATA_PAGE_ITEM] = wixDataPageItemAdapter,
    _a[ITEM_TYPES.REVIEWS_COMPONENT] = reviewsAdapter,
    _a[ITEM_TYPES.STORES_CATEGORY] = storesCategoryAdapter,
    _a[ITEM_TYPES.STORES_GALLERY_COMPONENT] = storesGalleryAdapter,
    _a[ITEM_TYPES.RESTAURANTS_MENU_PAGE] = restaurantsMenuPageAdapter,
    _a[ITEM_TYPES.MEMBERS_AREA_PROFILE_TABS] = membersAreaProfileTabsAdapter,
    _a[ITEM_TYPES.PROGRAMS_COMPONENT] = programsComponentAdapter,
    _a[ITEM_TYPES.SERVICES_COMPONENT] = servicesComponentAdapter,
    _a[ITEM_TYPES.PAYMENT_PAGE] = paymentPageAdapter,
    _a[ITEM_TYPES.THANK_YOU_PAGE] = thankYouPageAdapter,
    _a[ITEM_TYPES.RESTAURANTS_MENU_COMPONENT] = restaurantsMenuComponentAdapter,
    _a);
/**
 *
 * @param {keyof typeof ITEM_TYPES} itemType
 * @returns {import('../../../index').Adapter}
 */
export function getAdapter(itemType) {
    var adapter = adapters[itemType];
    return adapter ? enrichWithSiteLevelData(adapter) : defaultAdapter;
}
export var adaptersHandlers = {
    getData: 'function',
    getSdStatus: 'function',
    getLegacySeoBlob: 'function',
    getKeys: 'function',
    getSlug: 'function',
    updateItemDataWithSlug: 'function',
    getMissingSchemaVariables: 'function',
    getDynamicPatternsSchema: 'function',
    hasMultilingual: 'function',
    getDefaultSchemaType: 'function',
};
export var optionalHandlers = [
    'getDynamicPatternsSchema',
    'hasMultilingual',
    'getSdKeys',
    'getDefaultSchemaType',
    'getLegacySeoBlob',
];
export var componentAdapterHandlers = {
    getData: 'function',
    getLegacySeoBlob: 'function',
    getKeys: 'function',
    getMissingSchemaVariables: 'function',
};
