var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { buildStructuredData } from '../../types/builders';
export var updateStructuredData = function (tags, structuredData, _a) {
    if (tags === void 0) { tags = []; }
    var _b = _a === void 0 ? {} : _a, sourceSchemaType = _b.sourceSchemaType;
    if (!(structuredData === null || structuredData === void 0 ? void 0 : structuredData.schemaType)) {
        return tags;
    }
    var schemaType = sourceSchemaType || structuredData.schemaType;
    return schemaExists(tags, schemaType)
        ? tags.filter(removeEmptySchemas).map(updateExistingSchema)
        : tags.concat(buildStructuredData(structuredData));
    function removeEmptySchemas(existingTag) {
        var _a;
        if (((_a = existingTag.meta) === null || _a === void 0 ? void 0 : _a.schemaType) === schemaType) {
            var variantValue = existingTag.meta.selectedVariant;
            var isVariant = variantValue && variantValue !== schemaType;
            return !!existingTag.children || isVariant;
        }
        return true;
    }
    function updateExistingSchema(tag) {
        var _a;
        var schema = structuredData.schema, disabled = structuredData.disabled;
        var isDisabledProvided = [true, false].includes(disabled);
        return ((_a = tag.meta) === null || _a === void 0 ? void 0 : _a.schemaType) === schemaType
            ? __assign(__assign(__assign(__assign({}, tag), (schema ? { children: schema } : {})), (sourceSchemaType
                ? {
                    meta: __assign(__assign({}, tag.meta), { schemaType: structuredData.schemaType, displayName: structuredData.displayName }),
                }
                : {})), (isDisabledProvided ? { disabled: disabled } : {})) : tag;
    }
};
function schemaExists(tags, schemaType) {
    if (tags === void 0) { tags = []; }
    return tags.some(function (tag) { var _a; return ((_a = tag.meta) === null || _a === void 0 ? void 0 : _a.schemaType) === schemaType; });
}
