import React from 'react';
import { number, string } from 'prop-types';

export function Error({ color, width, height }) {
  return (
    <svg width={width} height={height} fill={color}>
      <path d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm.729-13.725a.94.94 0 0 0-.727-.273.94.94 0 0 0-1 1v4a.94.94 0 0 0 1 1 .94.94 0 0 0 1-1v-4a.94.94 0 0 0-.273-.727zm0 8a.94.94 0 0 0-.727-.273.94.94 0 0 0-1 1 .94.94 0 0 0 1 1 .94.94 0 0 0 1-1 .94.94 0 0 0-.273-.727z" />
    </svg>
  );
}

Error.propTypes = {
  width: number,
  height: number,
  color: string,
};

Error.defaultProps = {
  width: 18,
  height: 18,
  color: '#ffc233',
};
