import pick from 'lodash/pick';
import negate from 'lodash/negate';
import {
  validateTagValueByIdentifier,
  validateCustomTags,
  getValueFromHtmlTag,
  validateCustomTag,
  parse,
} from '@wix/advanced-seo-utils';
import seoValidations from '@wix/seo-validations';

import { getIdentifierByKey } from '../utils/utils';
import {
  KEYS,
  ERROR_NAMES,
  CANONICAL_SIMPLIFIED_LABEL,
  OG_URL_SIMPLIFIED_LABEL,
} from '../utils/maps';
import * as defaultValidators from './default-validators';
import get from 'lodash/get';
import {
  MIN_TWITTER_IMAGE_SMALL_HEIGHT,
  MIN_TWITTER_IMAGE_SMALL_WIDTH,
} from '../utils/twitter-tags';

const {
  TITLE,
  DESCRIPTION,
  CANONICAL,
  OG_IMAGE,
  OG_TITLE,
  OG_DESCRIPTION,
  TWITTER_IMAGE,
  TWITTER_TITLE,
  TWITTER_DESCRIPTION,
  URI,
  CUSTOM_TAGS,
  CUSTOM_TAGS_ARRAY,
  ADVANCED_TAGS,
  STRUCTURED_DATA_NAME,
  JSON_LD,
  SLUG,
} = KEYS;

export const MAX_LENGTH = {
  [TITLE]: 200,
  [DESCRIPTION]: 500,
  [URI]: 100,
  [OG_TITLE]: 200,
  [OG_DESCRIPTION]: 500,
  [TWITTER_TITLE]: 200,
  [TWITTER_DESCRIPTION]: 500,
  [CANONICAL]: 300,
  [STRUCTURED_DATA_NAME]: 50,
  [JSON_LD]: 7000,
  [SLUG]: 100,
};

export const KEYS_FOR_CHANGE = [
  TITLE,
  DESCRIPTION,
  CANONICAL,
  OG_IMAGE,
  OG_TITLE,
  OG_DESCRIPTION,
  TWITTER_IMAGE,
  TWITTER_TITLE,
  TWITTER_DESCRIPTION,
  URI,
  JSON_LD,
  ADVANCED_TAGS,
];

export const KEYS_FOR_SAVE = [
  ...KEYS_FOR_CHANGE,
  ADVANCED_TAGS,
  CUSTOM_TAGS,
  CUSTOM_TAGS_ARRAY,
  JSON_LD,
  OG_IMAGE,
];

export function createValidator(
  custom,
  keys = KEYS_FOR_CHANGE,
  { experiments = { enabled: () => {} }, excludedIdentifiers } = {},
) {
  const removeUriValidation = experiments.enabled(
    'specs.seo.RemoveUriValidation',
  );
  const [titleMaxLength, descriptionMaxLength, slugMaxLength] = [
    KEYS.TITLE,
    KEYS.DESCRIPTION,
    KEYS.SLUG,
  ].map((key) => MAX_LENGTH[key]);
  const safe = { ...(custom || {}) };

  safe.noEmoji = safe.noEmoji || defaultValidators.noEmoji;
  safe.noHTMLTags = safe.noHTMLTags || defaultValidators.noHTMLTags;
  safe.notEmptyString = safe.notEmptyString || defaultValidators.notEmptyString;
  safe.invalidTitleLength =
    safe.invalidTitleLength ||
    defaultValidators.invalidStringLength(titleMaxLength);
  safe.invalidDescriptionLength =
    safe.invalidDescriptionLength ||
    defaultValidators.invalidStringLength(descriptionMaxLength);
  if (removeUriValidation) {
    safe.invalidUrlCharacters = () => true;
  } else {
    safe.invalidUrlCharacters =
      safe.invalidUrlCharacters || defaultValidators.invalidUrlCharacters;
  }
  safe.startsOrEndsWithDash =
    safe.startsOrEndsWithDash || defaultValidators.startsOrEndsWithDash;
  safe.isUriForbidden = safe.isUriForbidden || falsy;
  safe.isUriDuplicate = safe.isUriDuplicate || falsy;

  safe.isSlugUriTooLong =
    safe.isSlugUriTooLong ||
    negate(defaultValidators.invalidStringLength(slugMaxLength)) ||
    falsy;

  const { invalidTitleLength, invalidDescriptionLength, noHTMLTags } = safe;

  const validators = pick(
    {
      [TITLE]: validate(TITLE, [invalidTitleLength, noHTMLTags]),
      [DESCRIPTION]: validate(DESCRIPTION, [
        invalidDescriptionLength,
        noHTMLTags,
      ]),
      [CANONICAL]: validate(CANONICAL, [noHTMLTags], true),
      [OG_IMAGE]: validateOgImage,
      [OG_TITLE]: validate(OG_TITLE, [invalidTitleLength, noHTMLTags]),
      [OG_DESCRIPTION]: validate(OG_DESCRIPTION, [
        invalidDescriptionLength,
        noHTMLTags,
      ]),
      [TWITTER_IMAGE]: validateTwitterImage,
      [TWITTER_TITLE]: validate(TWITTER_TITLE, [
        invalidTitleLength,
        noHTMLTags,
      ]),
      [TWITTER_DESCRIPTION]: validate(TWITTER_DESCRIPTION, [
        invalidDescriptionLength,
        noHTMLTags,
      ]),
      [URI]: validateUri(safe),
      [CUSTOM_TAGS]: validateCustomTagsHtmls({
        excludedIdentifiers,
      }),
      [CUSTOM_TAGS_ARRAY]: validateCurrentCustomTag({
        excludedIdentifiers,
      }),
      [ADVANCED_TAGS]: validateAdvancedTag(safe, {
        experiments,
        excludedIdentifiers,
      }),
      [JSON_LD]: validateJsonLd,
    },
    keys,
  );

  return (key, value, data) => {
    if (key === ADVANCED_TAGS || key === CUSTOM_TAGS_ARRAY) {
      return validators[key] ? validators[key](value, data) : isValid();
    }
    return validators[key] ? validators[key](value) : isValid();
  };
}

function validate(key, custom, byPassEmpty) {
  const identifier = getIdentifierByKey(key);
  return (value) => {
    if (byPassEmpty && !value) {
      return isValid();
    }
    return validateTagValueByIdentifier(identifier, value, custom);
  };
}

export function validateOgImage(value) {
  if (value) {
    const { uri, width, height } = value;
    if (
      uri !== undefined &&
      uri !== '' &&
      !/(.png)|(.jpg)|(.jpeg)|(.gif)/gi.test(uri)
    ) {
      return hasError('invalid-image-type');
    }
    if (width > 0 && height > 0) {
      if (width <= 200 || height <= 200) {
        return hasError('invalid-image-size');
      }
      if (width < 600 || height < 315) {
        return hasWarning();
      }
    }
  }
  return isValid();
}

export function validateTwitterImage(value) {
  if (value) {
    const { uri, width, height } = value;
    if (
      uri !== undefined &&
      uri !== '' &&
      !/(.png)|(.jpg)|(.jpeg)|(.gif)/gi.test(uri)
    ) {
      return hasError('invalid-image-type');
    }
    if (width > 0 && height > 0) {
      if (
        width <= MIN_TWITTER_IMAGE_SMALL_WIDTH ||
        height <= MIN_TWITTER_IMAGE_SMALL_HEIGHT
      ) {
        return hasError('invalid-image-size');
      }
    }
  }
  return isValid();
}

export function enableInvalidUrlCharacters(result) {
  return result.error &&
    result.error.name === ERROR_NAMES.INVALID_URL_CHARACTERS
    ? { isValid: true }
    : result;
}

function validateUri(optionalCustomValidators) {
  return (value) => {
    const validationResult = seoValidations.pageSlug(
      value,
      optionalCustomValidators,
    );

    return validationResult.valid
      ? isValid()
      : hasError(validationResult.results[0].failReason);
  };
}

function validateCustomTagsHtmls({ excludedIdentifiers }) {
  return (value) => {
    if (value) {
      const result = validateCustomTags(value, excludedIdentifiers);
      if (result.errors) {
        return { isValid: false, error: result.errors[0] };
      }
    }
    return isValid();
  };
}

function validateCurrentCustomTag({ excludedIdentifiers }) {
  return (value, data) => {
    if (value) {
      const result = validateCustomTag(value, data, excludedIdentifiers);
      if (result.errors) {
        return { isValid: false, error: result.errors[0] };
      }
    }
    return isValid();
  };
}

function validateAdvancedTag({ noEmoji, noHTMLTags }) {
  return (value, data) => {
    const isUrlField = [
      CANONICAL_SIMPLIFIED_LABEL,
      OG_URL_SIMPLIFIED_LABEL,
    ].includes(get(data, 'label', ''));

    if (isUrlField && !value) {
      return isValid();
    }

    const customValidators = isUrlField ? [noEmoji, noHTMLTags] : [];

    return validateTagValueByIdentifier(
      data.generalIdentifier,
      value,
      customValidators,
      data.label,
      false,
    );
  };
}

function validateJsonLd(value) {
  if (value) {
    if (value.length > MAX_LENGTH[KEYS.JSON_LD]) {
      return hasError(ERROR_NAMES.INVALID_STRING_LENGTH);
    }
    const tags = parse(value);
    if (tags.length > 1) {
      return hasError(ERROR_NAMES.HTML_TAG_INSIDE_SCRIPT);
    }
    const json = getValueFromHtmlTag(tags[0], getIdentifierByKey(KEYS.JSON_LD));
    const identifier = getIdentifierByKey(KEYS.JSON_LD);
    return json
      ? validateTagValueByIdentifier(identifier, json)
      : hasError(ERROR_NAMES.INVALID_TAG_STRUCTURE);
  }
  return isValid();
}

function hasError(name) {
  if (name) {
    return { isValid: false, error: { name } };
  }
  return { isValid: false };
}

function isValid() {
  return { isValid: true };
}

function hasWarning() {
  return { ...isValid(), hasWarning: true };
}

function falsy() {
  return false;
}
