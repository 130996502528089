"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../../adapters/utils");
var schema_types_1 = require("./schema-types");
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    url: "{{".concat(utils_1.siteIDs.SITE_URL, "}}/"),
    potentialAction: {
        '@type': 'SearchAction',
        target: {
            '@type': 'EntryPoint',
            urlTemplate: "{{".concat(utils_1.siteIDs.SITE_URL, "}}/{{").concat(utils_1.siteIDs.SEARCH_PAGE_SLUG, "}}?q={search_term}"),
        },
        'query-input': 'required name=search_term',
    },
};
var requiredSchemaVariables = [utils_1.siteIDs.SEARCH_PAGE_SLUG];
var appDefId = '1484cb44-49cd-5b39-9681-75188ab429de';
var tpaPageId = 'search_results';
exports.default = {
    type: schema_types_1.SCHEMA_TYPES.SITE_SEARCH,
    schema: schema,
    requiredSchemaVariables: requiredSchemaVariables,
    appDefId: appDefId,
    tpaPageId: tpaPageId,
};
