"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BLOG_TPA_PAGE_ID = exports.RSS_ROUTES = exports.RSS_ROUTE_TYPES = void 0;
exports.RSS_ROUTE_TYPES = {
    BLOG: 'BLOG',
};
exports.RSS_ROUTES = (_a = {},
    _a[exports.RSS_ROUTE_TYPES.BLOG] = 'blog-feed.xml',
    _a);
exports.BLOG_TPA_PAGE_ID = 'blog';
