import biLogger from '@wix/web-bi-logger';
import biEventsMap from './events-map';

export const createBiEventLogger = (
  defaults,
  initialCommonBiDataResolver = () => ({}),
) => {
  const logger = biLogger
    .factory({ endpoint: 'adv-seo-panel' })
    .updateDefaults({
      src: 102,
      page_type: 'static',
      context: 'editor',
      ...defaults,
    })
    .logger();

  let _commonBiDataResolver = initialCommonBiDataResolver;
  return {
    setCommonBiResolver: (commonBiDataResolver) => {
      _commonBiDataResolver = commonBiDataResolver;
    },
    logBiEvent: (type, biData) => {
      const resolvedBiData = { ..._commonBiDataResolver(), ...biData };
      return logger.log(biEventsMap[type](resolvedBiData)).catch(() => {});
    },
  };
};
