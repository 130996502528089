"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUrlParts = void 0;
var getUrlParts = function (url) {
    if (typeof url !== 'string') {
        return undefined;
    }
    var _a = 
    // eslint-disable-next-line no-useless-escape
    (/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim.exec(url) || [])[0], base = _a === void 0 ? '' : _a;
    var path = url.replace(base, '');
    return {
        base: base,
        path: path,
    };
};
exports.getUrlParts = getUrlParts;
