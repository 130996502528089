"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDefaultUrl = void 0;
var get_1 = require("./get");
var consts_1 = require("../consts");
var buildDefaultUrl = function (item) {
    var domain = (0, get_1.get)(item, 'context.domain');
    var siteUrl = (0, get_1.get)(item, 'context.siteUrl');
    if (domain && domain !== '') {
        var isWixDomain = consts_1.WIX_DOMAINLESS_IDS.some(function (domainId) {
            return domainId.includes(domain);
        });
        return isWixDomain
            ? removeAfterWebsiteName(siteUrl, domain)
            : "https://www.".concat(domain);
    }
    else {
        return siteUrl;
    }
};
exports.buildDefaultUrl = buildDefaultUrl;
function removeAfterWebsiteName(url, domain) {
    if (!url || !domain) {
        return url;
    }
    var domainEndIndex = url.indexOf(domain) + domain.length + 1;
    if (domainEndIndex !== -1) {
        var websiteNameEndIndex = url.indexOf('/', domainEndIndex);
        if (websiteNameEndIndex !== -1) {
            return url.substring(0, websiteNameEndIndex);
        }
        return url;
    }
    else {
        return url;
    }
}
