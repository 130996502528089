import React, { useEffect } from 'react';
import { RobotsTagContext } from '../../../../core/components/robots-tag/robots-tag-hooks';
import { Row } from './robots-tag-common-components';
import { NoIndexDirective } from './no-index/robots-tag-no-index-directive';
import { NoFollowDirective } from './no-follow/robots-tag-no-follow-directive';
import { NoSnippetDirective } from './no-snippet/robots-tag-no-snippet-directive';
import { NoArchiveDirective } from './no-archive/robots-tag-no-archive-directive';
import { NoImageIndexDirective } from './no-image-index/robots-tag-no-image-index-directive';
import { MaxImagePreviewDirective } from './max-image-preview/robots-tag-max-image-preview-directive';
import { MaxSnippetDirective } from './max-snippet/robots-tag-max-snippet-directive';
import { MaxVideoPreviewDirective } from './max-video-preview/robots-tag-max-video-preview-directive';
import { isRobotsTagValueSupported } from '@wix/advanced-seo-utils';
import { func, string, bool } from 'prop-types';
import { RobotsTagDescription } from './robots-tag-description';
import { RobotsTagNoMigration } from './robots-tag-no-migration';
import { ResetToPattern } from './robots-tag-reset-to-pattern';
import { KEYS } from '../../../../core/utils/maps';
import { PROMOTE_SEO_PANEL_COMPONENT_VIEW } from '../../../../core/bi/action-types';
import { RESET_TO_PATTERN_BUTTON } from '../../../../core/utils/consts';

export const AdvancedPanelRobotsTag = ({
  robotsTagValue,
  onChange,
  onSave,
  openHelper,
  logBiEvent,
  isIndexEnabled,
  isIndexEnabledFromSite,
  isIndexEnabledFromPermission,
  expandMetaTagsAccordionSection,
  msid,
  setActiveTab,
  getDefaultPatternValue,
}) => {
  const robotsTagDefaultPatternValue =
    getDefaultPatternValue(KEYS.ROBOTS_TAG) ?? '';
  const shouldShowResetToPattern =
    robotsTagValue !== robotsTagDefaultPatternValue;

  useEffect(() => {
    if (shouldShowResetToPattern) {
      logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
        field: KEYS.ROBOTS_TAG,
        component: RESET_TO_PATTERN_BUTTON,
      });
    }
  }, [shouldShowResetToPattern, logBiEvent]);

  if (!isRobotsTagValueSupported(robotsTagValue)) {
    return (
      <RobotsTagContext.Provider
        value={{
          robotsTagDefaultPatternValue,
          robotsTagValue,
          onChange,
          onSave,
          logBiEvent,
          openHelper,
        }}
      >
        <Row first>
          <RobotsTagDescription />
        </Row>
        <Row last>
          <RobotsTagNoMigration
            expandMetaTagsAccordionSection={expandMetaTagsAccordionSection}
          />
        </Row>
      </RobotsTagContext.Provider>
    );
  }

  return (
    <RobotsTagContext.Provider
      value={{
        robotsTagDefaultPatternValue,
        robotsTagValue,
        onChange,
        onSave,
        logBiEvent,
        openHelper,
      }}
    >
      <Row first>
        <RobotsTagDescription />
      </Row>
      <Row>
        <NoIndexDirective
          isIndexEnabled={isIndexEnabled}
          isIndexEnabledFromSite={isIndexEnabledFromSite}
          isIndexEnabledFromPermission={isIndexEnabledFromPermission}
          msid={msid}
          setActiveTab={setActiveTab}
        />
      </Row>
      <Row>
        <NoFollowDirective />
      </Row>
      <Row>
        <NoSnippetDirective />
      </Row>
      <Row>
        <NoArchiveDirective />
      </Row>
      <Row>
        <NoImageIndexDirective />
      </Row>
      <Row>
        <MaxImagePreviewDirective />
      </Row>
      <Row>
        <MaxSnippetDirective />
      </Row>
      <Row last={!shouldShowResetToPattern}>
        <MaxVideoPreviewDirective />
      </Row>
      {shouldShowResetToPattern && (
        <Row last>
          <ResetToPattern />
        </Row>
      )}
    </RobotsTagContext.Provider>
  );
};

AdvancedPanelRobotsTag.propTypes = {
  robotsTagValue: string.isRequired,
  onChange: func.isRequired,
  onSave: func.isRequired,
  openHelper: func.isRequired,
  logBiEvent: func.isRequired,
  isIndexEnabled: bool.isRequired,
  isIndexEnabledFromSite: bool.isRequired,
  isIndexEnabledFromPermission: bool.isRequired,
  expandMetaTagsAccordionSection: func.isRequired,
  getDefaultPatternValue: func.isRequired,
};
