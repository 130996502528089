var _a;
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { INDEX, NO_INDEX } from '../../consts/index';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { sanitizeUrl } from '../../utils/sanitize-url';
var TAB_TYPES = {
    ABOUT: 'about',
    FEED: 'feed',
    MEDIA: 'media',
    MEMBERS: 'members',
};
var INDEXED_TABS = [TAB_TYPES.ABOUT, TAB_TYPES.FEED];
var labelByTabType = (_a = {},
    _a[TAB_TYPES.FEED] = 'discussion',
    _a);
export var getDataLegacy = function (IDs, itemData) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var tabType = itemData === null || itemData === void 0 ? void 0 : itemData.tabType;
    var tabLabel = labelByTabType[tabType] || tabType;
    return _a = {},
        _a[IDs.ID] = ((_b = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _b === void 0 ? void 0 : _b.id) || ((_c = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _c === void 0 ? void 0 : _c.groupId) || '',
        _a[IDs.INDEX_PAGE] = ((_d = itemData === null || itemData === void 0 ? void 0 : itemData.context) === null || _d === void 0 ? void 0 : _d[CONTEXT_PROPS.INDEX_PAGE]) || '',
        _a[IDs.GROUP_TAB_TITLE] = ((_e = itemData === null || itemData === void 0 ? void 0 : itemData.tabs) === null || _e === void 0 ? void 0 : _e[tabLabel]) || '',
        _a[IDs.GROUP_NAME] = ((_g = (_f = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _f === void 0 ? void 0 : _f.details) === null || _g === void 0 ? void 0 : _g.title) || '',
        _a[IDs.GROUP_DESCRIPTION] = ((_j = (_h = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _h === void 0 ? void 0 : _h.details) === null || _j === void 0 ? void 0 : _j.description) || '',
        _a[IDs.MAIN_IMAGE] = ((_l = (_k = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _k === void 0 ? void 0 : _k.details) === null || _l === void 0 ? void 0 : _l.logoUrl) || '',
        _a[IDs.GROUP_IMAGE_WIDTH] = ((_p = (_o = (_m = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _m === void 0 ? void 0 : _m.details) === null || _o === void 0 ? void 0 : _o.logo) === null || _p === void 0 ? void 0 : _p.width) || '',
        _a[IDs.GROUP_IMAGE_HEIGHT] = ((_s = (_r = (_q = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _q === void 0 ? void 0 : _q.details) === null || _r === void 0 ? void 0 : _r.logo) === null || _s === void 0 ? void 0 : _s.height) || '',
        _a[IDs.GROUP_TAB_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.GROUPS_PAGE, itemData, sanitizeUrl(itemData === null || itemData === void 0 ? void 0 : itemData.directUrl)),
        _a[IDs.GROUP_ROBOTS] = INDEXED_TABS.includes(tabType) ? INDEX : NO_INDEX,
        _a;
};
