import { get } from '../../../utils/get';
var RAW_LOCATION_TYPES = {
    ONLINE: 'ONLINE',
};
export var LOCATION_TYPES = {
    VIRTUAL: 'VirtualLocation',
    PHYSICAL: 'Place',
};
export var getLocationType = function (item) {
    if (item === void 0) { item = {}; }
    return get(item, 'event.location.type') === RAW_LOCATION_TYPES.ONLINE
        ? LOCATION_TYPES.VIRTUAL
        : LOCATION_TYPES.PHYSICAL;
};
