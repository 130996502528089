"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var get_1 = require("../../utils/get");
var ContextProps_1 = require("../../types/ContextProps");
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
var schema_utils_1 = require("./schema-presets/schema-utils");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var IDs = {
    SITE_NAME: 'site.name',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    ID: 'post.id',
    TITLE: 'post.title',
    HEADLINE: 'post.headline',
    CATEGORY_NAME: 'category.name',
    CONTENT: 'post.content',
    CONTENT_FULL: 'post.content.full',
    COMMENT_AMOUNT: 'post.comments.amount',
    LIKE_AMOUNT: 'post.likes.amount',
    VIEW_AMOUNT: 'post.views.amount',
    MAIN_IMAGE: 'post.main.image',
    COVER_IMAGE: 'post.cover.image',
    COVER_IMAGE_HEIGHT: 'post.cover.image.height',
    COVER_IMAGE_WIDTH: 'post.cover.image.width',
    VIDEO: 'post.video',
    VIDEO_HEIGHT: 'post.video.height',
    VIDEO_WIDTH: 'post.video.width',
    POST_PAGE_URL: 'post.url',
    AUTHOR: 'post.author',
    AUTHOR_PROFILE_URL: 'post.author.profile.url',
    AUTHOR_PROFILE_IMAGE: 'post.author.profile.image',
    PUBLISH_DATE: 'post.publish.date',
    LAST_MODIFIED_DATE: 'post.modified.date',
    ACCEPTED_ANSWERS: 'comments.acceptedAnswers',
    SD_DISABLE_SCHEMA: 'forum-post.disable-schema',
    IS_QUESTION_AND_ANSWERS_TYPE: 'post.post-type.is-question-and-answers-type',
    QUESTIONS_AND_ANSWERS_SD_ACCEPTED_ANSWER: 'post.questions-and-answers.acceptedAnswer',
    QUESTIONS_AND_ANSWERS_SD_SUGGESTED_ANSWER: 'post.questions-and-answers.suggestedAnswer',
    QUESTIONS_AND_ANSWERS_SD_DISABLE_SCHEMA: 'post.questions-and-answers.disable-schema',
    PREV_URL: 'post.paging.prevUrl',
    NEXT_URL: 'post.paging.nextUrl',
    PAGING_TOTAL: 'post.paging.total',
    PAGING_CURRENT: 'post.paging.current',
};
var userVisible = [
    IDs.TITLE,
    IDs.CONTENT,
    IDs.COMMENT_AMOUNT,
    IDs.LIKE_AMOUNT,
    IDs.VIEW_AMOUNT,
    IDs.CATEGORY_NAME,
    IDs.POST_PAGE_URL,
    IDs.AUTHOR,
    IDs.SITE_NAME,
    IDs.PUBLISH_DATE,
    IDs.LAST_MODIFIED_DATE,
    IDs.COVER_IMAGE,
    IDs.PAGING_TOTAL,
    IDs.PAGING_CURRENT,
];
var userVisibleSdOnly = [
    IDs.COVER_IMAGE_HEIGHT,
    IDs.COVER_IMAGE_WIDTH,
    IDs.AUTHOR_PROFILE_URL,
    IDs.AUTHOR_PROFILE_IMAGE,
    IDs.CONTENT_FULL,
    IDs.QUESTIONS_AND_ANSWERS_SD_ACCEPTED_ANSWER,
    IDs.QUESTIONS_AND_ANSWERS_SD_SUGGESTED_ANSWER,
    IDs.HEADLINE,
    IDs.VIDEO,
];
var requiredSchemaVariables = [];
var SchemaTypes = {
    FORUM_POST_QUESTIONS_AND_ANSWERS: 'forum-post-questions-and-answers',
};
var getSdStatus = function (item, itemData) {
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables, item, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
exports.default = {
    IDs: IDs,
    requiredSchemaVariables: requiredSchemaVariables,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var postContent = (0, get_1.get)(item, 'post.contentText');
        var pagingCurrent = (0, get_1.getRawValue)(item, 'post.paging.current');
        var pagingTotal = (0, get_1.getRawValue)(item, 'post.paging.total');
        var data = __assign((_a = {}, _a[IDs.SITE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME)), _a[IDs.INDEX_SITE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_SITE)), _a[IDs.INDEX_PAGE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_PAGE)), _a[IDs.ID] = (0, get_1.get)(item, 'post._id'), _a[IDs.TITLE] = (0, get_1.get)(item, 'post.title'), _a[IDs.HEADLINE] = (0, get_1.get)(item, 'post.title').substring(0, 110), _a[IDs.CATEGORY_NAME] = (0, get_1.get)(item, 'category.label'), _a[IDs.CONTENT_FULL] = postContent, _a[IDs.CONTENT] = postContent.substring(0, 300), _a[IDs.LIKE_AMOUNT] = (0, get_1.get)(item, 'post.likeCount'), _a[IDs.VIEW_AMOUNT] = (0, get_1.get)(item, 'post.viewCount'), _a[IDs.COMMENT_AMOUNT] = (0, get_1.get)(item, 'post.totalComments'), _a[IDs.MAIN_IMAGE] = (0, get_1.getRawValue)(item, 'post.images.0.src') ||
            (0, get_1.getRawValue)(item, 'post.videos.0.src') ||
            '', _a[IDs.COVER_IMAGE] = (0, get_1.getRawValue)(item, 'post.images.0.src') ||
            (0, get_1.getRawValue)(item, 'post.videos.0.src') ||
            '', _a[IDs.COVER_IMAGE_HEIGHT] = (0, get_1.getRawValue)(item, 'post.images.0.height') ||
            (0, get_1.getRawValue)(item, 'post.videos.0.height') ||
            '', _a[IDs.COVER_IMAGE_WIDTH] = (0, get_1.getRawValue)(item, 'post.images.0.width') ||
            (0, get_1.getRawValue)(item, 'post.videos.0.width') ||
            '', _a[IDs.VIDEO] = (0, get_1.getRawValue)(item, 'post.videos.0.videoUrl') || '', _a[IDs.VIDEO_HEIGHT] = (0, get_1.getRawValue)(item, 'post.videos.0.height') || '', _a[IDs.VIDEO_WIDTH] = (0, get_1.getRawValue)(item, 'post.videos.0.width') || '', _a[IDs.POST_PAGE_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.FORUM_POST, item, (0, get_1.get)(item, 'post.url')), _a[IDs.PREV_URL] = pagingCurrent > 1 ? (0, get_1.get)(item, 'post.paging.prevUrl') : '', _a[IDs.NEXT_URL] = pagingCurrent < pagingTotal ? (0, get_1.get)(item, 'post.paging.nextUrl') : '', _a[IDs.PAGING_TOTAL] = pagingTotal !== null && pagingTotal !== void 0 ? pagingTotal : '', _a[IDs.PAGING_CURRENT] = pagingCurrent !== null && pagingCurrent !== void 0 ? pagingCurrent : '', _a[IDs.AUTHOR] = (0, get_1.get)(item, 'post.owner.name'), _a[IDs.AUTHOR_PROFILE_URL] = (0, get_1.get)(item, 'post.owner.profile.url'), _a[IDs.AUTHOR_PROFILE_IMAGE] = (0, get_1.get)(item, 'post.owner.image'), _a[IDs.PUBLISH_DATE] = (0, get_1.get)(item, 'post.createdDate'), _a[IDs.LAST_MODIFIED_DATE] = (0, get_1.get)(item, 'post.editedDate') || (0, get_1.get)(item, 'post.createdDate'), _a), (0, schema_utils_1.getStructuredData)(IDs, item));
        var sdStatus = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getKeys: function () { return (0, utils_1.getKeys)(IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(IDs, userVisibleSdOnly); },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
};
