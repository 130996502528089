import { GENERAL_IDENTIFIERS } from '../../types/Identifiers';
import PROPS from '../../types/Props';
import { identifyCustomTag } from './identify-custom-tag';
export var compareCustomTags = function (tagA, tagB) {
    var identifierA = identifyCustomTag(tagA);
    var identifierB = identifyCustomTag(tagB);
    if (identifierA && identifierB && identifierA === identifierB) {
        switch (identifierA) {
            case GENERAL_IDENTIFIERS.LINK:
                var relA = tagA.props[PROPS.REL];
                var relB = tagB.props[PROPS.REL];
                return relA && relB && relA === relB;
            case GENERAL_IDENTIFIERS.SATANDARD_META:
                var nameA = tagA.props[PROPS.NAME];
                var nameB = tagB.props[PROPS.NAME];
                return nameA && nameB && nameA === nameB;
            case GENERAL_IDENTIFIERS.OG_TAG:
                var propertyA = tagA.props[PROPS.PROPERTY];
                var propertyB = tagB.props[PROPS.PROPERTY];
                return propertyA && propertyB && propertyA === propertyB;
            default:
                return false;
        }
    }
    return false;
};
