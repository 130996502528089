// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qLZNR4{width:510px;height:508px}.qLZNR4 .jYI9NP .Q37BM2{padding:4px 18px 12px 30px;border-bottom:1px solid #e1e3e6}.qLZNR4 .jYI9NP .Q37BM2 .g_vD1V{margin-top:2px;margin-left:-6px}.qLZNR4 .jYI9NP .lRNZZA{padding:16px 18px 9px 30px;border-bottom:1px solid #e1e3e6}.qLZNR4 .jYI9NP .lRNZZA .chXEAk{display:flex;justify-content:space-between}.qLZNR4 .jYI9NP .lRNZZA .chXEAk .UXMQyR{margin-left:6px}.qLZNR4 .jYI9NP .lRNZZA .NbodVe{margin-top:2px;margin-left:-6px;height:230px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qLZNR4`,
	"modal-content": `jYI9NP`,
	"modalContent": `jYI9NP`,
	"name-container": `Q37BM2`,
	"nameContainer": `Q37BM2`,
	"name-input": `g_vD1V`,
	"nameInput": `g_vD1V`,
	"code-container": `lRNZZA`,
	"codeContainer": `lRNZZA`,
	"code-label": `chXEAk`,
	"codeLabel": `chXEAk`,
	"info-icon": `UXMQyR`,
	"infoIcon": `UXMQyR`,
	"code-input": `NbodVe`,
	"codeInput": `NbodVe`
};
export default ___CSS_LOADER_EXPORT___;
