"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var ContextProps_1 = require("../../types/ContextProps");
var ItemTypes_1 = require("../../types/ItemTypes");
var get_1 = require("../../utils/get");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
exports.IDs = {
    PAGE_NAME: 'page.name',
    ORDER_PAGE_URL: 'restaurants-order-page.page-url',
};
var SchemaTypes = {};
var userVisible = [];
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        if (!(0, get_1.get)(item, 'menu')) {
            return {};
        }
        return _a = {},
            _a[exports.IDs.ORDER_PAGE_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.RESTAURANTS_ORDER_PAGE, item, (0, get_1.get)(item, 'menu')),
            _a[exports.IDs.PAGE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PAGE_NAME)),
            _a;
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
