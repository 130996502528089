import { getKeys } from '../../adapters/utils';
import { adapter as restaurantsMenuPageAdapter } from '../restaurants-menu-page';
var IDs = restaurantsMenuPageAdapter.IDs, getSdStatus = restaurantsMenuPageAdapter.getSdStatus, getData = restaurantsMenuPageAdapter.getData;
var SchemaTypes = {
    RESTAURANTS_MENU_COMPONENT_IMAGES: 'restaurants-menu-component-images',
};
var userVisible = [IDs.SD_JSON_IMAGES];
var userVisibleSdOnly = [IDs.IMAGES_SD];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var data = getData(item);
        return _a = {},
            _a[IDs.SD_JSON_IMAGES] = data[IDs.SD_JSON_IMAGES],
            _a[IDs.DISABLE_IMAGES_SCHEMA] = data[IDs.DISABLE_IMAGES_SCHEMA],
            _a;
    },
    getSdStatus: function (item, data) { return getSdStatus(item, data); },
    getLegacySeoBlob: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
};
