export var calculateDuration = function (unit, time) {
    switch (unit) {
        case 'WEEKS':
            return 'P' + time + 'W';
        case 'DAYS':
            return 'P' + time + 'D';
        case 'HOURS':
            return 'PT' + time + 'H';
        case 'MINUTES':
            return 'PT' + time + 'M';
        default:
            return;
    }
};
