import React from 'react';
import { translate } from 'react-i18next';
import { Composites, TextLabel, RichText } from '@wix/wix-base-ui';
import { HomeSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { PROMOTE_SEO_CTA_CLICK } from '../../../core/bi/action-types';
import styles from '../panels.scss';

const BasicPanelHomePage = ({ t, logBiEvent, goToUrl }) => {
  const onGoToUri = () => {
    logBiEvent(PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.basic-panel.page-url.homepage.goto',
      linkType: 'general',
      linkSource: 'panel',
    });
    goToUrl();
  };
  return (
    <Composites.RichTextLabeled automationId="seo-homepage-url">
      <HomeSmall className={styles.homeIcon} />
      <TextLabel
        type="T10"
        shouldTranslate={false}
        value={t('adv-seo.basic-panel.page-url.homepage.title')}
      />
      <RichText
        type="T02"
        shouldTranslate={false}
        className={styles.homeSubtitle}
      >
        {t('adv-seo.basic-panel.page-url.homepage.description')}&nbsp;
        <a onClick={onGoToUri}>{t('adv-seo.basic-panel.page-url.link')}</a>
      </RichText>
    </Composites.RichTextLabeled>
  );
};

export default translate()(BasicPanelHomePage);
