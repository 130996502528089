import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { sanitizeUrl } from '../../utils/sanitize-url';
var IDs = {
    ID: 'members-profile-page.id',
    PAGE_NAME: 'page.name',
    PAGE_URL: 'page.url',
    FIRST_NAME: 'members-profile-page.first.name',
    LAST_NAME: 'members-profile-page.last.name',
    COMPANY: 'members-profile-page.company',
    JOB_TITLE: 'members-profile-page.job.title',
    NICKNAME: 'members-profile-page.nickname',
    TITLE: 'members-profile-page.title',
};
var userVisible = [
    IDs.PAGE_NAME,
    IDs.PAGE_URL,
    IDs.FIRST_NAME,
    IDs.LAST_NAME,
    IDs.COMPANY,
    IDs.JOB_TITLE,
    IDs.NICKNAME,
    IDs.TITLE,
];
var requiredSchemaVariables = [];
var SchemaTypes = {};
export default {
    IDs: IDs,
    requiredSchemaVariables: requiredSchemaVariables,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        return _a = {},
            _a[IDs.ID] = item === null || item === void 0 ? void 0 : item.id,
            _a[IDs.PAGE_NAME] = (_b = item === null || item === void 0 ? void 0 : item.context) === null || _b === void 0 ? void 0 : _b[CONTEXT_PROPS.PAGE_NAME],
            _a[IDs.PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.MEMBERS_AREA_PROFILE, item, sanitizeUrl((_c = item === null || item === void 0 ? void 0 : item.context) === null || _c === void 0 ? void 0 : _c[CONTEXT_PROPS.DEFAULT_URL])),
            _a[IDs.FIRST_NAME] = (_e = (_d = item === null || item === void 0 ? void 0 : item.member) === null || _d === void 0 ? void 0 : _d.contact) === null || _e === void 0 ? void 0 : _e.firstName,
            _a[IDs.LAST_NAME] = (_g = (_f = item === null || item === void 0 ? void 0 : item.member) === null || _f === void 0 ? void 0 : _f.contact) === null || _g === void 0 ? void 0 : _g.lastName,
            _a[IDs.COMPANY] = (_j = (_h = item === null || item === void 0 ? void 0 : item.member) === null || _h === void 0 ? void 0 : _h.contact) === null || _j === void 0 ? void 0 : _j.company,
            _a[IDs.JOB_TITLE] = (_l = (_k = item === null || item === void 0 ? void 0 : item.member) === null || _k === void 0 ? void 0 : _k.contact) === null || _l === void 0 ? void 0 : _l.title,
            _a[IDs.NICKNAME] = (_o = (_m = item === null || item === void 0 ? void 0 : item.member) === null || _m === void 0 ? void 0 : _m.profile) === null || _o === void 0 ? void 0 : _o.nickname,
            _a[IDs.TITLE] = (_q = (_p = item === null || item === void 0 ? void 0 : item.member) === null || _p === void 0 ? void 0 : _p.profile) === null || _q === void 0 ? void 0 : _q.title,
            _a;
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
