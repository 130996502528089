"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveSdSchema = void 0;
function resolveSdSchema(structuredData, _a) {
    if (structuredData === void 0) { structuredData = '{}'; }
    var _b = _a === void 0 ? {} : _a, _c = _b.shouldClearEmptyFields, shouldClearEmptyFields = _c === void 0 ? true : _c;
    var structuredDataString = structuredData;
    try {
        if (typeof structuredData === 'object') {
            structuredDataString = JSON.stringify(structuredData);
        }
        var resolvedSdString = fixNestedObjectFields(structuredDataString);
        resolvedSdString = removeScriptTag(resolvedSdString);
        resolvedSdString = fixNestedArrayFields(resolvedSdString);
        resolvedSdString = fixEmptyArrayFields(resolvedSdString);
        resolvedSdString = fixPrimitiveArray(resolvedSdString);
        var dataObject = void 0;
        try {
            dataObject = JSON.parse(resolvedSdString);
        }
        catch (error) {
            resolvedSdString = shouldRemoveEscape(resolvedSdString)
                ? removeEscape(resolvedSdString)
                : resolvedSdString;
            dataObject = JSON.parse(resolvedSdString);
        }
        return shouldClearEmptyFields
            ? clearEmptyFields({ dataObject: dataObject })
            : resolvedSdString;
    }
    catch (error) {
        return '{}';
    }
}
exports.resolveSdSchema = resolveSdSchema;
function removeScriptTag(structuredDataString) {
    var sdWithoutSpaces = structuredDataString.replace(/\s/g, '');
    if (sdWithoutSpaces.startsWith('<script')) {
        var contentStartIndex = structuredDataString.indexOf('>');
        var contentEndIndex = structuredDataString.lastIndexOf('<');
        structuredDataString = structuredDataString.substring(contentStartIndex + 1, contentEndIndex);
    }
    return structuredDataString;
}
function removeEscape(structuredDataString) {
    structuredDataString = structuredDataString
        .replace(/(\\r\\n|\\n|\\r\\ \\)/g, '')
        .replace(/\\"/g, '"');
    var matches = structuredDataString.match(/"(.*?)"/g);
    for (var _i = 0, matches_1 = matches; _i < matches_1.length; _i++) {
        var match = matches_1[_i];
        structuredDataString = structuredDataString.replace(match, function (matchedString) {
            return "\"".concat(matchedString
                .split('')
                .slice(1, -1)
                .join('')
                .replace(/(\\|")/g, function (value) { return '\\' + value; }), "\"");
        });
    }
    return structuredDataString;
}
function fixNestedObjectFields(structuredDataString) {
    var nestedObjectWithQuotes = /"{"(.*?)}"/g;
    return structuredDataString.replace(nestedObjectWithQuotes, function (match) {
        return match.slice(1, -1);
    });
}
function fixNestedArrayFields(structuredDataString) {
    var nestedArrayWithQuotes = /"\[{"(.*?)]"/g;
    return structuredDataString.replace(nestedArrayWithQuotes, function (match) {
        return match.slice(1, -1);
    });
}
function fixPrimitiveArray(structuredDataString) {
    var primitiveArrayWithQuotes = /"\[(.*?)]"/g;
    return structuredDataString.replace(primitiveArrayWithQuotes, function (match) {
        return match.slice(1, -1);
    });
}
function fixEmptyArrayFields(structuredDataString) {
    return structuredDataString.replace('"[]"', '[]');
}
function clearEmptyFields(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.dataObject, dataObject = _c === void 0 ? {} : _c, containerObject = _b.containerObject, containerKey = _b.containerKey;
    if (!dataObject) {
        return '{}';
    }
    Object.entries(dataObject).forEach(function (_a) {
        var key = _a[0], val = _a[1];
        var isEmptyArray = (val === null || val === void 0 ? void 0 : val.length) === 0;
        if (val && typeof val === 'object' && !isEmptyArray) {
            clearEmptyFields({
                dataObject: val,
                containerObject: dataObject,
                containerKey: key,
            });
        }
        else {
            if (!val || isEmptyArray) {
                if (isKeyRequired(key, containerObject && containerObject[containerKey])) {
                    delete containerObject[containerKey];
                }
                else {
                    delete dataObject[key];
                }
            }
        }
    });
    return JSON.stringify(dataObject);
}
function isKeyRequired(key, container) {
    var requiredKeys = ['url', 'contentUrl'];
    if (isBlogAuthor(container)) {
        return false;
    }
    if (key === 'name') {
        var siblingKeys_1 = Object.keys(container || {});
        return requiredKeys.every(function (x) { return !siblingKeys_1.includes(x); });
    }
    return requiredKeys.includes(key);
}
// blog author is an exception - as it allows for an empty URL value
function isBlogAuthor(container) {
    if (container === void 0) { container = {}; }
    return container['@type'] === 'Person';
}
function shouldRemoveEscape(structuredDataString) {
    var ExcludedFromEscapingList = ['"FAQPage"'];
    return !ExcludedFromEscapingList.some(function (excludedItem) {
        return (structuredDataString.includes("\"@type\":".concat(excludedItem)) ||
            structuredDataString.includes("\"@type\": ".concat(excludedItem)));
    });
}
