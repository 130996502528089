var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get, getRawValue } from '../../utils/get';
import { getKeys, hasMissingSchemaVariables } from '../../adapters/utils';
import { getCalculatedData } from './calculated';
import { getStructuredData, shouldRenderSchema } from './structured-data';
import { updateValueByIdentifier } from '../../tags/values/update-by-identifier';
import { IDENTIFIERS } from '../../types/Identifiers';
import { getEventUrl } from './calculated/get-event-url';
import { SD_STATUS } from '../../consts';
export var IDs = {
    ID: 'event.id',
    TITLE: 'event.name',
    DESCRIPTION: 'event.description',
    ABOUT: 'event.about',
    START_DATE: 'event.schedule.start.date',
    END_DATE: 'event.schedule.end.date',
    SCHEDULE_TBD: 'event.schedule.tbd',
    STATUS: 'event.raw.status',
    ONLINE_ENABLED: 'event.online.enabled',
    CREATED_DATE: 'event.created.date',
    LOCATION_TYPE: 'event.raw.location.type',
    LOCATION_ADDRESS: 'event.location.address',
    LOCATION_NAME: 'event.location.name',
    REGISTRATION_TYPE: 'event.registration.type',
    REGISTRATION_TICKET_LOWEST_PRICE: 'event.registration.ticket.price.lowest',
    REGISTRATION_TICKET_HIGHEST_PRICE: 'event.registration.ticket.price.highest',
    REGISTRATION_TICKET_CURRENCY: 'event.registration.ticket.price.currency',
    REGISTRATION_TICKET_TAX: 'event.registration.ticket.price.tax',
    REGISTRATION_STATUS: 'event.registration.status',
    TICKETS: 'event.raw.tickets',
    IMAGE_ID: 'event.image.id',
    IMAGE_WIDTH: 'event.image.width',
    IMAGE_HEIGHT: 'event.image.height',
    CALCULATED_LOCATION_TYPE: 'event.location.type',
    CALCULATED_EVENT_STATUS: 'event.status',
    CALCULATED_ATTENDANCE_MODE: 'event.attendance.mode',
    MAIN_IMAGE: 'event.main.image.url',
    CALCULATED_ONLINE_CONF_AVAILABLE: 'event.online.conf.available',
    CALCULATED_SEO_DESCRIPTION: 'event.seo.description',
    CALCULATED_EVENT_URL: 'event.url',
    CALCULATED_TICKETS: 'event.tickets',
    CALCULATED_TICKETS_COUNT: 'event.tickets.count',
    CALCULATED_EVENT_MONTH: 'event.month',
    SD_DISABLE_SCHEMA: 'event.structured-data.disable-schema',
    SD_JSON_OFFERS: 'event.structured-data.offers',
    SD_JSON_LOCATION: 'event.structured-data.location',
    SD_STATUS: 'event.structured-data.status',
    SD_ATTANDANCE_MODE: 'event.structured-data.attandance-mode',
    FULL_DESCRIPTION: 'event.full-description',
};
var userVisible = [
    IDs.TITLE,
    IDs.CALCULATED_SEO_DESCRIPTION,
    IDs.ABOUT,
    IDs.REGISTRATION_TICKET_LOWEST_PRICE,
    IDs.REGISTRATION_TICKET_HIGHEST_PRICE,
    IDs.LOCATION_NAME,
    IDs.LOCATION_ADDRESS,
    IDs.CALCULATED_EVENT_URL,
    IDs.MAIN_IMAGE,
];
var userVisibleSdOnly = [
    IDs.IMAGE_HEIGHT,
    IDs.IMAGE_WIDTH,
    IDs.DESCRIPTION,
    IDs.START_DATE,
    IDs.END_DATE,
    IDs.SD_STATUS,
    IDs.SD_ATTANDANCE_MODE,
    IDs.SD_JSON_LOCATION,
    IDs.CALCULATED_LOCATION_TYPE,
    IDs.SD_JSON_OFFERS,
    IDs.REGISTRATION_TICKET_CURRENCY,
    IDs.CALCULATED_TICKETS_COUNT,
];
var requiredSchemaVariables = [];
var SchemaTypes = {
    EVENTS_PAGE: 'events-page-preset',
};
var getSdStatus = function (item, itemData) {
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    if (!shouldRenderSchema(item)) {
        return SD_STATUS.DISABLED_BY_VERTICAL_ITEM_DATA;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = __assign(__assign((_a = {}, _a[IDs.ID] = get(item, 'event.id'), _a[IDs.TITLE] = get(item, 'event.title'), _a[IDs.DESCRIPTION] = get(item, 'event.description'), _a[IDs.ABOUT] = get(item, 'event.about'), _a[IDs.START_DATE] = get(item, 'event.scheduling.config.startDate'), _a[IDs.END_DATE] = get(item, 'event.scheduling.config.endDate'), _a[IDs.SCHEDULE_TBD] = getRawValue(item, 'event.scheduling.config.scheduleTbd') || '', _a[IDs.STATUS] = get(item, 'event.status'), _a[IDs.ONLINE_ENABLED] = !!getRawValue(item, 'event.onlineConferencing.config.enabled'), _a[IDs.CREATED_DATE] = get(item, 'event.created'), _a[IDs.LOCATION_TYPE] = get(item, 'event.location.type'), _a[IDs.LOCATION_ADDRESS] = get(item, 'event.location.address'), _a[IDs.LOCATION_NAME] = get(item, 'event.location.name'), _a[IDs.REGISTRATION_TYPE] = get(item, 'event.registration.type'), _a[IDs.REGISTRATION_TICKET_LOWEST_PRICE] = get(item, 'event.registration.ticketing.lowestTicketPrice.amount'), _a[IDs.REGISTRATION_TICKET_HIGHEST_PRICE] = get(item, 'event.registration.ticketing.highestTicketPrice.amount'), _a[IDs.REGISTRATION_TICKET_CURRENCY] = get(item, 'event.registration.ticketing.currency'), _a[IDs.REGISTRATION_TICKET_TAX] = get(item, 'event.registration.ticketing.config.taxConfig.rate'), _a[IDs.REGISTRATION_STATUS] = get(item, 'event.registration.status'), _a[IDs.IMAGE_ID] = get(item, 'event.mainImage.id'), _a[IDs.IMAGE_WIDTH] = get(item, 'event.mainImage.width'), _a[IDs.IMAGE_HEIGHT] = get(item, 'event.mainImage.height'), _a[IDs.FULL_DESCRIPTION] = get(item, 'event.description'), _a), getCalculatedData(IDs, item)), getStructuredData(IDs, item));
        var sdStatus = getSdStatus(item, data) !== SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getLegacySeoBlob: function (item) {
        var tags = updateValueByIdentifier([], IDENTIFIERS.TITLE, get(item, 'legacySeoData.title'));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION, get(item, 'legacySeoData.description'));
        return { tags: tags };
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
    getSlug: function (item) { return get(item, 'event.slug'); },
    getFullUrl: function (item) { return getEventUrl(item); },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        var urlArray = url.split('/');
        var lastPart = urlArray.pop();
        item.event.eventPageUrl.base = urlArray.join('/');
        item.event.eventPageUrl.path = lastPart ? "/".concat(lastPart) : '';
        return item;
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        var oldSlug = get(updatedItemData, 'event.slug');
        if (oldSlug !== '') {
            updatedItemData.event.slug = slugValue;
        }
        return updatedItemData;
    },
};
