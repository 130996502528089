"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.identifyCustomTag = void 0;
var Identifiers_1 = require("../../types/Identifiers");
var validate_tag_structure_1 = require("../utils/validate-tag-structure");
var TagSchemas_1 = require("../../types/TagSchemas");
var TagTypes_1 = require("../../types/TagTypes");
var filter_identifier_keys_1 = require("../filters/filter-identifier-keys");
var identifyCustomTag = function (tag, ignoreList) {
    if (ignoreList === void 0) { ignoreList = []; }
    var identifierKeys = (0, filter_identifier_keys_1.filterIdentifierKeys)(Identifiers_1.GENERAL_IDENTIFIERS, ignoreList);
    return identifierKeys.find(function (identifier) {
        var _a, _b;
        var schema = TagSchemas_1.TAG_SCHEMAS[identifier];
        if (!schema || !tag.props) {
            return false;
        }
        switch (tag.type) {
            case TagTypes_1.TAG_TYPES.LINK:
                schema = schema(tag.props.rel);
                break;
            case TagTypes_1.TAG_TYPES.META:
                schema =
                    identifier === Identifiers_1.GENERAL_IDENTIFIERS.SATANDARD_META
                        ? schema((_a = tag.props.name) === null || _a === void 0 ? void 0 : _a.toLowerCase())
                        : schema((_b = tag.props.property) === null || _b === void 0 ? void 0 : _b.toLowerCase());
                break;
            default:
                return false;
        }
        return (0, validate_tag_structure_1.validateTagStructure)(tag, schema);
    });
};
exports.identifyCustomTag = identifyCustomTag;
