"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveDynamicPattern = void 0;
var consts_1 = require("../../consts/consts");
var Identifiers_1 = require("../../types/Identifiers");
var identify_1 = require("../../renderer/utils/identify");
var resolveDynamicPattern = function (patternBlob, dynamicKeys) {
    try {
        var blobString = JSON.stringify(patternBlob);
        var patternRegex = new RegExp("\"".concat(consts_1.PATTERN_PREFIX, "(.+?)").concat(consts_1.PATTERN_SUFFIX, "\""), 'g');
        var dynamicPatternString = blobString.replace(patternRegex, function (match, key) {
            var value = dynamicKeys[key] || match;
            if (typeof value !== 'string') {
                return JSON.stringify(value);
            }
            return value;
        });
        var dynamicPattern = clearEmptySd(JSON.parse(dynamicPatternString));
        return dynamicPattern;
    }
    catch (_a) {
        return patternBlob;
    }
};
exports.resolveDynamicPattern = resolveDynamicPattern;
function clearEmptySd(dynamicePattern) {
    return __assign(__assign({}, dynamicePattern), { tags: dynamicePattern.tags.filter(function (tag) {
            if ((0, identify_1.identify)(tag) === Identifiers_1.IDENTIFIERS.STRUCTURED_DATA) {
                if (typeof tag.children === 'object') {
                    return true;
                }
                return false;
            }
            return true;
        }) });
}
