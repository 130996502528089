var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { get, getRawValue } from '../../utils/get';
import { getKeys, siteIDs, hasMissingSchemaVariables, } from '../../adapters/utils';
import { updateValueByIdentifier } from '../../tags/values/update-by-identifier';
import { IDENTIFIERS } from '../../types/Identifiers';
import { getPageUrl } from './adapter-utils';
import { SD_STATUS } from '../../consts';
import { getRssRoute } from '../utils';
import { RSS_ROUTE_TYPES } from '../consts';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { SCHEMA_TYPES } from './schema-presets/schema-types';
import LOCAL_BUSINESS_SCHEMA from './schema-presets/local-business';
import SITE_SEARCH_SCHEMA from './schema-presets/site_search';
import RESTAURANT_SCHEMA from './schema-presets/restaurant-schema';
import { getSiteSearchSlug } from '../../utils/get-search-site-slug';
export var IDs = {
    TITLE: 'page.name',
    PAGE_URL: 'page.url',
    SEARCH_PAGE_SLUG: 'site.search.slug',
    INSTALLED_APPS: 'site.installedApps',
    SITE_NAME: 'site.name',
    HOME_PAGE_TITLE: 'site.homePageTitle',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    IS_HOME_PAGE: 'page.isHomePage',
    MAIN_IMAGE: 'site.image',
    IMAGE_WIDTH: 'site.imageWidth',
    IMAGE_HEIGHT: 'site.imageHeight',
    FB_ADMINS: 'site.facebookAdminId',
    NEXT: 'site.nextLink',
    PREV: 'site.prevLink',
    BLOG_FEED_ROUTE: 'site.blogFeedRoute',
    TPA_PAGE_ID: 'page.tpaPageId',
    ID: 'page.pageId',
    DISABLE_LOCAL_BUSINESS_SCHEMA: 'page.local-business-schema.disable',
    DISABLE_SITE_SEARCH_SCHEMA: 'page.site-search-schema.disable',
    DISABLE_RESTAURANT_SCHEMA: 'page.restaurant-schema.disable',
    FULL_DESCRIPTION: 'page.full-description',
};
var userVisible = [IDs.TITLE, IDs.PAGE_URL, IDs.SITE_NAME, IDs.MAIN_IMAGE];
var defaultRequiredSchemaVariables = [
    siteIDs.BUSINESS_NAME,
    siteIDs.BUSINESS_LOCATION_FORMATTED,
];
var SchemaTypes = {
    SITE_SEARCH: SITE_SEARCH_SCHEMA.type,
    LOCAL_BUSINESS: LOCAL_BUSINESS_SCHEMA.type,
    RESTAURANT_SCHEMA: RESTAURANT_SCHEMA.type,
};
var getSdStatus = function (item, itemData, schemaType) {
    var requiredSchemaVariables;
    switch (schemaType) {
        case SCHEMA_TYPES.LOCAL_BUSINESS:
            requiredSchemaVariables = LOCAL_BUSINESS_SCHEMA.requiredSchemaVariables;
            break;
        case SCHEMA_TYPES.SITE_SEARCH:
            requiredSchemaVariables = SITE_SEARCH_SCHEMA.requiredSchemaVariables;
            break;
        case SCHEMA_TYPES.RESTAURANT_SCHEMA:
            requiredSchemaVariables = RESTAURANT_SCHEMA.requiredSchemaVariables;
            break;
        default:
            requiredSchemaVariables = defaultRequiredSchemaVariables;
            break;
    }
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var data = (_a = {},
            _a[IDs.TITLE] = get(item, "context.".concat(CONTEXT_PROPS.PAGE_NAME)) ||
                get(item, CONTEXT_PROPS.PAGE_NAME),
            _a[IDs.PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.STATIC_PAGE_V2, item, getPageUrl(item)),
            _a[IDs.SITE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.INDEX_SITE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_SITE)),
            _a[IDs.INSTALLED_APPS] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INSTALLED_APPS)),
            _a[IDs.SEARCH_PAGE_SLUG] = getSiteSearchSlug(getRawValue(item, "context.".concat(CONTEXT_PROPS.INSTALLED_APPS))),
            _a[IDs.INDEX_PAGE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_PAGE)),
            _a[IDs.IS_HOME_PAGE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.IS_HOME_PAGE)),
            _a[IDs.MAIN_IMAGE] = get(item, "context.".concat(CONTEXT_PROPS.SITE_OG_IMAGE)),
            _a[IDs.IMAGE_WIDTH] = get(item, "context.".concat(CONTEXT_PROPS.OG_IMAGE_WIDTH)),
            _a[IDs.IMAGE_HEIGHT] = get(item, "context.".concat(CONTEXT_PROPS.OG_IMAGE_HEIGHT)),
            _a[IDs.FB_ADMINS] = get(item, "context.".concat(CONTEXT_PROPS.FB_ADMINS)),
            _a[IDs.NEXT] = get(item, "context.".concat(CONTEXT_PROPS.NEXT)),
            _a[IDs.PREV] = get(item, "context.".concat(CONTEXT_PROPS.PREV)),
            _a[IDs.BLOG_FEED_ROUTE] = getRssRoute({
                rssRouteType: RSS_ROUTE_TYPES.BLOG,
                payload: { item: item },
            }),
            _a[IDs.TPA_PAGE_ID] = get(item, "context.".concat(CONTEXT_PROPS.TPA_PAGE_ID)),
            _a[IDs.ID] = get(item, CONTEXT_PROPS.PAGE_ID),
            _a);
        var localBusinessSdStatus = getSdStatus(item, data, SchemaTypes.LOCAL_BUSINESS) !== SD_STATUS.ENABLED;
        var siteSearchSdStatus = getSdStatus(item, data, SchemaTypes.SITE_SEARCH) !== SD_STATUS.ENABLED;
        var restaurantSdStatus = getSdStatus(item, data, SchemaTypes.RESTAURANT_SCHEMA) !==
            SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.DISABLE_LOCAL_BUSINESS_SCHEMA] = localBusinessSdStatus, _b[IDs.DISABLE_SITE_SEARCH_SCHEMA] = siteSearchSdStatus, _b[IDs.DISABLE_RESTAURANT_SCHEMA] = restaurantSdStatus, _b[IDs.FULL_DESCRIPTION] = '' /* Should contain H1 + H2 */, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { var _a, _b; return (_b = (_a = item === null || item === void 0 ? void 0 : item.staticPage) === null || _a === void 0 ? void 0 : _a.uri) !== null && _b !== void 0 ? _b : ''; },
    setFullUrl: function (itemData, url) {
        var _a;
        if (url === void 0) { url = ''; }
        return __assign(__assign({}, itemData), { context: __assign(__assign({}, itemData.context), (_a = {}, _a[CONTEXT_PROPS.DEFAULT_URL] = url, _a)) });
    },
    getFullUrl: function (itemData) { return getPageUrl(itemData); },
    updateItemDataWithSlug: function (itemData, slugValue) {
        return __assign(__assign({}, itemData), { staticPage: __assign(__assign({}, itemData.staticPage), { uri: slugValue }) });
    },
    getLegacySeoBlob: function (item) {
        var tags = updateValueByIdentifier([], IDENTIFIERS.TITLE, get(item, "context.".concat(CONTEXT_PROPS.TITLE)));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION, get(item, "context.".concat(CONTEXT_PROPS.DESCRIPTION)));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.OG_IMAGE, get(item, "context.".concat(CONTEXT_PROPS.OG_IMAGE)));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.ROBOTS, ['noindex', 'false'].includes(get(item, "context.".concat(CONTEXT_PROPS.INDEX_PAGE)))
            ? 'noindex'
            : '');
        return { tags: tags };
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
