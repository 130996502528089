import { get } from './get';
import { WIX_DOMAINLESS_IDS } from '../consts';
export var buildDefaultUrl = function (item) {
    var domain = get(item, 'context.domain');
    var siteUrl = get(item, 'context.siteUrl');
    if (domain && domain !== '') {
        var isWixDomain = WIX_DOMAINLESS_IDS.some(function (domainId) {
            return domainId.includes(domain);
        });
        return isWixDomain
            ? removeAfterWebsiteName(siteUrl, domain)
            : "https://www.".concat(domain);
    }
    else {
        return siteUrl;
    }
};
function removeAfterWebsiteName(url, domain) {
    if (!url || !domain) {
        return url;
    }
    var domainEndIndex = url.indexOf(domain) + domain.length + 1;
    if (domainEndIndex !== -1) {
        var websiteNameEndIndex = url.indexOf('/', domainEndIndex);
        if (websiteNameEndIndex !== -1) {
            return url.substring(0, websiteNameEndIndex);
        }
        return url;
    }
    else {
        return url;
    }
}
