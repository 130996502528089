import adapter from '../adapter-reviews-component';
import { adapter as storesProductAdapter } from '../../stores-product';
var IDs = adapter.IDs, SchemaTypes = adapter.SchemaTypes;
var schema = {
    aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: "{{".concat(IDs.RATING_VALUE, "}}"),
        ratingCount: "{{".concat(IDs.RATING_COUNT, "}}"),
        bestRating: "{{".concat(IDs.BEST_RATING, "}}"),
        worstRating: "{{".concat(IDs.WORST_RATING, "}}"),
        reviewCount: "{{".concat(IDs.REVIEW_COUNT, "}}"),
    },
    review: "{{".concat(IDs.REVIEWS, "}}"),
};
export default {
    schemaType: SchemaTypes.REVIEWS_COMPONENT_ITEM_LIST,
    schema: schema,
    parentSchemaType: storesProductAdapter.SchemaTypes.STORES_PRODUCT_PAGE,
};
