"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAttendenceMode = exports.getIsVenue = exports.ATTENDENCE_MODE = void 0;
var get_1 = require("../../../utils/get");
var EVENT_TYPES = {
    PHYSICAL: 'VENUE',
    ONLINE: 'ONLINE',
};
exports.ATTENDENCE_MODE = {
    MIXED: 'MixedEventAttendanceMode',
    ONLINE: 'OnlineEventAttendanceMode',
    OFFLINE: 'OfflineEventAttendanceMode',
};
var getIsVenue = function (item) {
    return (0, get_1.get)(item, 'event.location.type') === EVENT_TYPES.PHYSICAL;
};
exports.getIsVenue = getIsVenue;
var getAttendenceMode = function (item) {
    if (item === void 0) { item = {}; }
    if (!item.event) {
        return;
    }
    var isVenue = (0, exports.getIsVenue)(item);
    var isOnlineEnabled = !!(0, get_1.get)(item, 'event.onlineConferencing.config.enabled');
    var isOnlineOnly = (0, get_1.get)(item, 'event.location.type') === EVENT_TYPES.ONLINE;
    return isVenue && isOnlineEnabled
        ? exports.ATTENDENCE_MODE.MIXED
        : isOnlineOnly
            ? exports.ATTENDENCE_MODE.ONLINE
            : exports.ATTENDENCE_MODE.OFFLINE;
};
exports.getAttendenceMode = getAttendenceMode;
