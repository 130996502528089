import { identify } from '../../renderer/utils/identify';
import { safelyParseJsonData } from '../../renderer/utils/safely-parse-json-data';
import { isEmpty } from '../../utils/is-empty';
export var filterIdentifiedTags = function (advancedSeoData, ignoreList) {
    if (ignoreList === void 0) { ignoreList = []; }
    var filteredTags;
    if (typeof advancedSeoData === 'string') {
        filteredTags = safelyParseJsonData(advancedSeoData);
    }
    if (isEmpty(advancedSeoData)) {
        filteredTags = {};
    }
    filteredTags = ((filteredTags || advancedSeoData).tags || []).filter(function (tag) {
        var identifier = identify(tag, ignoreList);
        return identifier === undefined;
    });
    return { tags: filteredTags };
};
