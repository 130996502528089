import React, { PureComponent } from 'react';
import { func, string, object, array } from 'prop-types';
import { translate } from 'react-i18next';

import { Composites, TextLabel } from '@wix/wix-base-ui';

import { removeEnclosingScriptTag } from '../../../core/utils/structured-data';
import * as BI_TYPES from '../../../core/bi/action-types';
import SchemasList from './structured-data/schemas-list';

const HIDE = { display: 'none' };

class AdvancedPanelJsonLD extends PureComponent {
  static propTypes = {
    value: string.isRequired,
    error: string.isRequired,
    schemasMap: array.isRequired,
    onSdEdit: func.isRequired,
    onSdDelete: func.isRequired,
    onToggleSdHide: func.isRequired,
    onSave: func.isRequired,
    onChange: func.isRequired,
    logBiEvent: func.isRequired,
    openHelpCenter: func.isRequired,
    t: func.isRequired,
    experiments: object.isRequired,
    siteDashboardURL: string.isRequired,
    itemType: string.isRequired,
    siteContext: object.isRequired,
  };

  state = {
    isFocused: false,
    jsonLd: '',
  };

  render() {
    const {
      t,
      schemasMap,
      isSchemaMissingBusinessInfo,
      onSdEdit,
      logBiEvent,
      onSdDelete,
      onToggleSdHide,
      experiments,
      siteDashboardURL,
      itemType,
      siteContext,
    } = this.props;

    return (
      <Composites.TextInputMultilineLabeled>
        <TextLabel
          type="T02"
          value={t('adv-seo.adv-panel.structured-data.label')}
          automationId="json-ld-title"
          enableEllipsis={false}
          shouldTranslate={false}
        />
        <SchemasList
          onTestSchema={this._onValidateOnGoogle}
          onSdEdit={onSdEdit}
          onSdDelete={onSdDelete}
          onToggleSdHide={onToggleSdHide}
          schemasMap={schemasMap}
          isSchemaMissingBusinessInfo={isSchemaMissingBusinessInfo}
          logBiEvent={logBiEvent}
          siteDashboardURL={siteDashboardURL}
          experiments={experiments}
          itemType={itemType}
          siteContext={siteContext}
        />
        <form
          data-hook="validation-hidden-form"
          ref={this._onUpdateFormReference}
          method="post"
          target="_blank"
          action={t(
            'adv-seo.adv-panel.structured-data.rich-results-validation-link.url',
          )}
          style={HIDE}
        >
          <textarea name="code_snippet" value={this.state.jsonLd} readOnly />
        </form>
      </Composites.TextInputMultilineLabeled>
    );
  }

  _onFocus = () => {
    this.setState({ isFocused: true });
  };

  _onBlur = () => {
    this.setState({ isFocused: false });
    this.props.onSave();
  };

  _onUpdateFormReference = (form) => {
    this._form = form;
  };

  _onValidateOnGoogle = (jsonLd) => {
    const { logBiEvent, value } = this.props;
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName:
        'adv-seo.adv-panel.structured-data.rich-results-validation-link',
      linkType: 'general',
      linkSource: 'panel',
    });

    const testedJsonLd = removeEnclosingScriptTag(jsonLd || value);

    this.setState(
      { jsonLd: testedJsonLd },
      () => this._form && this._form.submit(),
    );
  };

  _onInfoClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('80512a86-ab07-4bbe-850d-52e8ffb8a2ad');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.structured-data.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };

  _onErrorClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('f4562f37-8718-4cca-9b07-5e0f44e36b32');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.structured-data.error.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default translate()(AdvancedPanelJsonLD);
