"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPageUrl = void 0;
var ContextProps_1 = require("../../types/ContextProps");
var get_1 = require("../../utils/get");
var index_1 = require("../../converters/index");
var removeTrailingSlash = function (url) { return url.replace(/\/+$/, ''); };
var getPageUrl = function (item) {
    if (item === void 0) { item = {}; }
    var siteUrl = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_URL));
    var defaultUrl = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.DEFAULT_URL));
    var isHomePage = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.IS_HOME_PAGE));
    var urlWithoutQuery = defaultUrl.split('?')[0];
    var isCurrentHomePage = removeTrailingSlash(urlWithoutQuery) === removeTrailingSlash(siteUrl);
    var url = isHomePage && !isCurrentHomePage ? siteUrl : defaultUrl;
    return (0, index_1.sanitizeUrl)(url);
};
exports.getPageUrl = getPageUrl;
