import React from 'react';
import { I18nextProvider } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import { CustomScroll } from '@wix/wix-base-ui';
import {
  CONTEXT_PROPS,
  ITEM_TYPES,
  isRobotsTagValueSupported,
} from '@wix/advanced-seo-utils';
import i18n from '../../core/i18n';
import { KEYS, EDITOR_DATA } from '../../core/utils/maps';
import * as BI_TYPES from '../../core/bi/action-types';
import BaseApplication from '../../core/components/app-base';

import {
  getPayload,
  buildState,
  getItemLegacyBlob,
  getAdvancedTagPropByLabel,
} from '../../core/app-base';

import { propTypes, defaultProps } from './config';
import { TAB_NAMES, BasicTab, SocialTab, AdvancedTab } from '../tabs';
import { initLegacyHandlers } from '../initializers';
import {
  initValidators,
  initGetters,
  initSetters,
  initContext,
} from '../../core/initializers';
import { handleLegacySeoKeywords } from '../handlers';
import { resolveAdvancedSeoData, getCustomValidators } from '../utils';
import './app.global.scss';
import {
  isTwitterCardValueSupportedOrMissing,
  getExcludedIdentifiers,
} from '../../core/utils/twitter-tags';
import { getValueFromScheme } from '../../core/utils/reset-to-pattern';
import { SAVE_METHOD_TYPE } from '../../core/utils/consts';

const tryParseJson = (json) => {
  if (json === undefined) {
    return undefined;
  }

  try {
    return JSON.parse(json);
  } catch {
    return undefined;
  }
};

export default class App extends BaseApplication {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  constructor(props, state) {
    super(props, state);
    const { locale, experiments, biLogger, isDomainConnected } = this.props;
    const siteContext = initContext(this.props, CONTEXT_PROPS);
    this.i18n = i18n(locale);
    this.biContext = 'editor';
    this.logBiEvent = biLogger.logBiEvent;
    this.legacyHandlers = initLegacyHandlers(props);
    this.getters = {
      ...initGetters(experiments, siteContext),
      [KEYS.URI]: () => this.state[KEYS.URI],
    };
    this.setters = initSetters();

    const getRobotsTag = this.getters[KEYS.ROBOTS_TAG];
    const robotsTagValue = getRobotsTag(
      tryParseJson(this.props.pageData?.advancedSeoData) || { tags: [] },
    ).value;
    this.isRobotsTagValueSupported = isRobotsTagValueSupported(robotsTagValue);

    const getTwitterCard = this.getters[KEYS.TWITTER_CARD];
    const twitterCardValue = getTwitterCard(
      tryParseJson(this.props.pageData?.advancedSeoData) || { tags: [] },
    ).value;
    this.isTwitterCardValueSupportedOrMissing =
      isTwitterCardValueSupportedOrMissing(twitterCardValue);
    this.validators = initValidators(getCustomValidators(props), {
      experiments,
      excludedIdentifiers: getExcludedIdentifiers(twitterCardValue),
    });
    this.itemType = ITEM_TYPES.STATIC_PAGE_V2;
    this.siteContext = siteContext;
    this.state = this.getPageState({
      isDomainConnected,
    });
    if (!this.initialState) {
      this.initialState = this.state;
    }
    this.updateType = SAVE_METHOD_TYPE.ON_BLUR;
    this.showResetToPatternButton = (key, data) =>
      this.getDefaultPatternValue(key, data) !==
      this.getCurrentValue(key, data);
  }

  componentDidMount = () => {
    this.logBiEvent(BI_TYPES.PROMOTE_SEO_PANEL_VIEW);
    this.resolveLegacySeoKeywords();
  };

  componentDidUpdate(prevProps) {
    const { pageId, pageName, isDomainConnected, language } = this.props;
    const isPageChanged = prevProps.pageId !== pageId;
    const isPageNameChanged = prevProps.pageName !== pageName;
    const isLanguageChanged =
      language.current !== this.siteContext[CONTEXT_PROPS.CURR_LANG_CODE];

    if (isPageChanged || isPageNameChanged || isLanguageChanged) {
      this.logBiEvent(BI_TYPES.PROMOTE_SEO_PANEL_VIEW);
      this.siteContext = initContext(this.props, CONTEXT_PROPS);
      this.legacyHandlers = initLegacyHandlers(this.props);
      const pageState = this.getPageState({
        isDomainConnected,
      });
      this.setState(pageState);
    }
  }

  render() {
    return (
      <CustomScroll heightRelativeToParent="100%">
        <I18nextProvider i18n={this.i18n}>{this.renderTab()}</I18nextProvider>
      </CustomScroll>
    );
  }

  renderTab = () => {
    const tabData = this.getTabData();
    const handlers = this.getTabHandlers();
    const isIndexEnabledFromUserPattern =
      this.getIsIndexEnabledFromUserPattern();
    const basicTabProps = {
      isIndexEnabledFromUserPattern,
      initialUri: this.getPublishedUri(),
      preventAutoRedirectValue: this.state.preventAutoRedirect.value,
      ...this.props,
    };
    const socialTabProps = {
      ogImageFromPattern: this.getOGImageFromUserPattern(),
      twitterImageFromPattern: this.getTwitterImageFromUserPattern(),
      customTwitterImageUrl: this.getTwitterImageFromAdvancedBlob(),
      isTwitterCardValueSupportedOrMissing:
        this.isTwitterCardValueSupportedOrMissing,
      ...this.props,
    };
    const advancedTabProps = {
      isIndexEnabledFromUserPattern,
      siteContext: this.siteContext,
      ...this.props,
    };
    const commonProps = {
      getDefaultPatternValue: this.getDefaultPatternValue,
    };
    return (
      {
        [TAB_NAMES.BASIC_TAB_NAME]: () => (
          <BasicTab
            {...basicTabProps}
            {...handlers}
            {...tabData}
            {...commonProps}
            isHomePage={basicTabProps.isHomePage}
          />
        ),
        [TAB_NAMES.SOCIAL_TAB_NAME]: () => (
          <SocialTab
            {...socialTabProps}
            {...handlers}
            {...tabData}
            {...commonProps}
          />
        ),
        [TAB_NAMES.ADVANCED_TAB_NAME]: () => (
          <AdvancedTab
            {...advancedTabProps}
            {...handlers}
            {...tabData}
            {...commonProps}
          />
        ),
      }[this.props.tabName] || (() => null)
    )();
  };

  getTabData = () => {
    const isIndexEnabledFromPermission =
      !this.props.permission || this.props.permission === 'everyone';
    return (
      {
        [TAB_NAMES.BASIC_TAB_NAME]: {
          [KEYS.TITLE]: this.state[KEYS.TITLE],
          [KEYS.DESCRIPTION]: this.state[KEYS.DESCRIPTION],
          [CONTEXT_PROPS.IS_HOME_PAGE]:
            this.siteContext[CONTEXT_PROPS.IS_HOME_PAGE],
          [KEYS.IS_INDEX_ENABLED]: this.state[KEYS.IS_INDEX_ENABLED],
          [KEYS.URI]: this.state[KEYS.URI],
          isIndexEnabledFromPermission,
          [KEYS.ROBOTS_TAG]: this.state[KEYS.ROBOTS_TAG],
        },
        [TAB_NAMES.SOCIAL_TAB_NAME]: {
          [KEYS.OG_TITLE]: this.state[KEYS.OG_TITLE],
          [KEYS.OG_DESCRIPTION]: this.state[KEYS.OG_DESCRIPTION],
          [KEYS.OG_IMAGE]: this.state[KEYS.OG_IMAGE],
          [KEYS.TWITTER_CARD]: this.state[KEYS.TWITTER_CARD],
          [KEYS.TWITTER_TITLE]: this.state[KEYS.TWITTER_TITLE],
          [KEYS.TWITTER_DESCRIPTION]: this.state[KEYS.TWITTER_DESCRIPTION],
          [KEYS.TWITTER_IMAGE]: this.state[KEYS.TWITTER_IMAGE],
        },
        [TAB_NAMES.ADVANCED_TAB_NAME]: {
          [KEYS.JSON_LD]: this.state[KEYS.JSON_LD],
          [KEYS.CANONICAL]: this.state[KEYS.CANONICAL],
          [KEYS.CUSTOM_TAGS]: this.state[KEYS.CUSTOM_TAGS],
          [KEYS.CUSTOM_TAGS_ARRAY]: this.state[KEYS.CUSTOM_TAGS_ARRAY],
          [KEYS.ADVANCED_TAGS]: this.state[KEYS.ADVANCED_TAGS],
          [KEYS.SCHEMAS_MAP]: this.state[KEYS.SCHEMAS_MAP],
          [KEYS.ITEM_TYPE]: this.itemType,
          [KEYS.IS_INDEX_ENABLED]: this.state[KEYS.IS_INDEX_ENABLED],
          isIndexEnabledFromPermission,
          [KEYS.ROBOTS_TAG]: this.state[KEYS.ROBOTS_TAG],
        },
      }[this.props.tabName] || {}
    );
  };

  getTabHandlers = () =>
    ({
      [TAB_NAMES.BASIC_TAB_NAME]: {
        onSave: this.handleBlur,
        validateAndSetState: this.validateAndSetState,
        logBiEvent: this.logBiEvent,
        resetToPattern: this.resetToPattern,
        showResetToPatternButton: this.showResetToPatternButton,
      },
      [TAB_NAMES.SOCIAL_TAB_NAME]: {
        onSave: this.handleBlur,
        openHelpCenter: this.props.openHelpCenter,
        validateAndSetState: this.validateAndSetState,
        logBiEvent: this.logBiEvent,
        resetToPattern: this.resetToPattern,
        showResetToPatternButton: this.showResetToPatternButton,
      },
      [TAB_NAMES.ADVANCED_TAB_NAME]: {
        onTagDeleted: this.onTagDeleted,
        onAddTempTag: this.onAddTempTag,
        onRemoveTempTag: this.onRemoveTempTag,
        onSave: this.handleBlur,
        openHelpCenter: this.props.openHelpCenter,
        validateAndSetState: this.validateAndSetState,
        onSdEdit: this.onSdEdit,
        onSdDelete: this.onSdDelete,
        onToggleSdHide: this.onToggleSdHide,
        logBiEvent: this.logBiEvent,
        getImageFromMM: () => this.getImageFromMM(this.props.mediaManager),
        showResetToPatternButton: this.showResetToPatternButton,
      },
    }[this.props.tabName] || {});

  getPageState({ isDomainConnected }) {
    const advancedSeoData = resolveAdvancedSeoData(this.props);
    const legacyData = getItemLegacyBlob(this.itemType, {
      context: this.siteContext,
    });
    const visibleData = this.createVisibleData({
      advancedSeoData,
      legacyData,
      isDomainConnected,
    });
    return {
      ...buildState(
        visibleData,
        this.getters,
        this.getDataWithDefaults({ isDomainConnected }),
      ),
      [KEYS.URI]: { value: this.props.uri },
      visibleData,
      advancedSeoData,
    };
  }

  getSiteContextPayload = () => {
    return getPayload(this.itemType, {}, this.siteContext, {
      ignoreLegacy: true, // TODO: remove when cleaning up static pattern v1, flag is not needed for v2
    });
  };

  handleDisabledTagWithInvalidValue = (key, result, data) => {
    let useDefaultValue = false;
    const { isDomainConnected } = this.props;
    if (key === KEYS.ADVANCED_TAGS) {
      if (!result.isValid && data.isDisabled) {
        const defaultData = this.buildStateSafely(
          this.getDataWithDefaults({ isDomainConnected }),
        );
        const defaultError =
          defaultData[key] &&
          getAdvancedTagPropByLabel(defaultData[key], data.label, 'error');
        if (!defaultError) {
          const defaultValue =
            defaultData[key] &&
            getAdvancedTagPropByLabel(defaultData[key], data.label);
          data.value = defaultValue;
          useDefaultValue = true;
        }
      }
    }
    return useDefaultValue;
  };

  onTagDeleted = (key) => (data) => {
    this.saveAndUpdateState(key, null, data, true);
  };

  handleBlur = (key) => (data) => {
    const isHandlerDone = this.handleCustomAndUri(key);
    if (isHandlerDone) {
      return;
    }
    const valueWithoutDefault = this.getValueWithoutDefault(key, data);
    if (key === KEYS.CUSTOM_TAGS_ARRAY) {
      const { isDomainConnected } = this.props;
      data = {
        ...data,
        schema: this.createVisibleData({
          advancedSeoData: this.state.advancedSeoData,
          legacyData: this.state.legacyData,
          isDomainConnected,
        }),
      };
    }
    const result = this.validators.validateBeforeSave(
      key,
      valueWithoutDefault,
      data,
    );

    const useDefaultValue = this.handleDisabledTagWithInvalidValue(
      key,
      result,
      data,
    );

    if ((result.isValid || useDefaultValue) && this.setters[key]) {
      this.saveAndUpdateState(
        key,
        useDefaultValue ? data.value : valueWithoutDefault,
        data,
      );
    } else {
      this.handleBlurError(key, result, data);
    }
  };

  saveAndUpdateState = (key, value, data, deleteTag = false) => {
    this.handleFieldUpdateBi({
      key,
      value,
      logger: this.logBiEvent,
      data,
      prevState: this.state,
      updateType: this.updateType,
    });

    this.updateSiteContext(key, value);
    this.legacyHandlers[key] && this.legacyHandlers[key](value);

    const advancedSeoData = this.getAdvancedSeoData(
      key,
      value,
      data,
      deleteTag,
    );
    const legacyData = getItemLegacyBlob(this.itemType, {
      context: this.siteContext,
    });

    const visibleData = this.createVisibleData({
      advancedSeoData,
      legacyData,
      isDomainConnected: this.props.isDomainConnected,
    });
    const newState = this.buildStateSafely(visibleData);

    this.props.setData(JSON.stringify(advancedSeoData));

    this.setState({
      ...newState,
      visibleData,
      advancedSeoData,
    });
  };

  getKeyDefaultContext = (key) => {
    const context = cloneDeep(this.siteContext);
    context[key] = '';
    return context;
  };

  getDefaultPatternValue = (key, data) => {
    const context = this.getKeyDefaultContext(key);
    const advancedSeoData = this.getAdvancedSeoData(key, '', data, false);
    const legacyData = getItemLegacyBlob(this.itemType, { context });
    const { isDomainConnected } = this.props;
    const visibleData = this.createVisibleData({
      advancedSeoData,
      legacyData,
      isDomainConnected,
    });
    const defaultState = buildState(
      visibleData,
      this.getters,
      this.getDataWithDefaults({ isDomainConnected }),
    );
    return getValueFromScheme(key, data, defaultState);
  };

  removeLeadingSlash = (str) => str.replace(/^\/+/g, '');

  getPublishedUri = () => {
    const {
      siteStructure: { static_page = [] } = {},
      pageData: { id: pageId },
    } = this.props;
    const pageData = static_page.find(({ itemId }) => itemId === pageId);
    if (!pageData) {
      console.error("getting published uri - couldn't find page data");
      return null;
    }
    const uri = pageData.path;
    return this.removeLeadingSlash(uri);
  };

  getCurrentValue = (key, data) => getValueFromScheme(key, data, this.state);

  resetToPattern = (key) => () => {
    this.updateSiteContext(key, '');
    this.legacyHandlers[key] && this.legacyHandlers[key]('');
    const advancedSeoData = this.getUpdatedSchema(
      key,
      '',
      this.state.advancedSeoData,
    );
    const legacyData = getItemLegacyBlob(this.itemType, {
      context: this.siteContext,
    });

    const visibleData = this.createVisibleData({
      advancedSeoData,
      legacyData,
      isDomainConnected: this.props.isDomainConnected,
    });
    const newState = this.buildStateSafely(visibleData, {
      keyToValidateByValue: key,
    });
    this.props.setData(JSON.stringify(advancedSeoData));

    this.setState({
      ...newState,
      visibleData,
      advancedSeoData,
    });
    this.logBiEvent(BI_TYPES.PROMOTE_SEO_ACTION_CLICK, {
      fieldName: key,
      action: 'reset',
    });
  };

  getAdvancedSeoData(key, value, data, deleteTag) {
    let advancedSeoData = null;
    switch (key) {
      case KEYS.ADVANCED_TAGS:
        advancedSeoData = this.getAdvancedUpdatedScheme(
          data,
          value,
          this.state.advancedSeoData,
        );
        break;

      case KEYS.CUSTOM_TAGS_ARRAY:
        advancedSeoData = this.getCustomUpdatedScheme(
          data,
          value,
          this.state.advancedSeoData,
          deleteTag,
        );
        break;
      case KEYS.SCHEMAS_MAP:
        advancedSeoData = data;
        break;

      default:
        advancedSeoData = this.getUpdatedSchema(
          key,
          value,
          this.state.advancedSeoData,
        );
        break;
    }

    return advancedSeoData;
  }

  updateSiteContext(key, value) {
    if (!key) {
      return;
    }
    switch (key) {
      case KEYS.URI:
        this.siteContext[CONTEXT_PROPS.DEFAULT_URL] = value;
        break;
      case KEYS.IS_INDEX_ENABLED:
        this.siteContext[CONTEXT_PROPS.INDEX_PAGE] = value;
        break;
      case KEYS.OG_IMAGE:
        const { uri, width, height } = value;
        this.siteContext[CONTEXT_PROPS.OG_IMAGE] = uri === '' ? null : uri;
        this.siteContext[CONTEXT_PROPS.OG_IMAGE_WIDTH] =
          width === 0 ? null : width;
        this.siteContext[CONTEXT_PROPS.OG_IMAGE_HEIGHT] =
          height === 0 ? null : height;
        break;
      default:
        this.siteContext[key] = value;
    }
  }

  handleCustomAndUri = (key) => {
    let value = this.state[key].value;
    const biData = {
      fieldName: key,
      updatedValue: value,
      updateType: this.updateType,
      ...(this.state[key].error
        ? {
            errorName: key,
            errorType: this.state[key].errorName,
          }
        : {}),
    };
    if (this.state[key].error) {
      this.logBiEvent(BI_TYPES.PROMOTE_SEO_ERROR_VIEW, biData);
      if (key === KEYS.URI && value === '') {
        value = EDITOR_DATA[key].defaultVal;
      } else {
        return true;
      }
    }
    if (key === KEYS.URI && value) {
      value = this.props.convertPageNameToUrl(value);
      this.legacyHandlers[key] && this.legacyHandlers[key](value);
      this.setState((state) => ({
        [key]: {
          ...state[key],
          value,
          error: '',
        },
      }));
      this.logBiEvent(BI_TYPES.PROMOTE_SEO_FIELD_UPDATE, biData);
      return true;
    }
    return false;
  };

  resolveLegacySeoKeywords = async () => {
    if (!this.props.pageKeywordsSEO) {
      return;
    }

    const key = KEYS.CUSTOM_TAGS_ARRAY;
    const customTagsWithKeywords = await handleLegacySeoKeywords(this.props, {
      key,
      customTagsFromState: this.state[key].value,
      logger: this.logBiEvent,
      validateAndSetState: this.validateAndSetState,
      updateType: this.updateType,
    });
    this.saveAndUpdateState(KEYS.CUSTOM_TAGS, customTagsWithKeywords);
  };
  getErrorMapFlags = () => ({});
}
