import { updateValueByIndex } from '../values/update-value-by-index-and-html';
import { removeTagByIndex } from '../values/remove-tag-by-index-and-html';
export function updateCustomTagsArray(schema, data, htmlValue, removeTag) {
    if (removeTag) {
        return removeTagByIndex(schema.tags, data.index);
    }
    else {
        return updateValueByIndex(schema.tags, htmlValue, data.index);
    }
}
