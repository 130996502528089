import { get, getRawValue } from '../../../utils/get';
import { getIsVenue } from '../calculated/get-attendence-mode';
import { getEventUrl } from '../calculated/get-event-url';
export var shouldRenderSchema = function (item) {
    if (item === void 0) { item = {}; }
    if (!item.event) {
        return false;
    }
    var url = !!getEventUrl(item);
    var titleExists = !!get(item, 'event.title');
    var isVenue = getIsVenue(item);
    var locationExists = !!get(item, 'event.location.address');
    var isAddressEnteredIfPhysical = (!isVenue && url) || (locationExists && isVenue);
    var isEventScheduled = !getRawValue(item, 'event.scheduling.config.scheduleTbd');
    var startDateExists = !!get(item, 'event.scheduling.config.startDate');
    return (url &&
        titleExists &&
        isAddressEnteredIfPhysical &&
        isEventScheduled &&
        startDateExists);
};
