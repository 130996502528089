import { experimentEnabled } from '../../../adapters/experimentEnabled';
import { IDs } from '../../../item-types/static-page-v2/adapter-static-page-v2';
export var shouldRenderPanelSchema = function (tag, context) {
    if (context === void 0) { context = {}; }
    var _a = tag.meta || {}, enabledByExperiment = _a.enabledByExperiment, _b = _a.removeSchemaIfEmpty, removeSchemaIfEmpty = _b === void 0 ? [] : _b, _c = _a.removeSchemaIfAppsNotInstalled, removeSchemaIfAppsNotInstalled = _c === void 0 ? [] : _c, _d = _a.removeSchemaIfAppsInstalled, removeSchemaIfAppsInstalled = _d === void 0 ? [] : _d;
    var isEnabledBySpec = !enabledByExperiment || experimentEnabled(enabledByExperiment, context);
    var isMissingData = removeSchemaIfEmpty.some(function (variable) { return !context[variable]; });
    var findInstalledApp = function (appId) {
        var _a;
        return (_a = context[IDs.INSTALLED_APPS]) === null || _a === void 0 ? void 0 : _a.find(function (installedApp) {
            return installedApp.tpaPageId === appId ||
                installedApp.appDefinitionId === appId ||
                installedApp.managingAppDefId === appId;
        });
    };
    var isMissingApp = removeSchemaIfAppsNotInstalled.some(function (appId) { return !findInstalledApp(appId); });
    var isDisabledByApp = removeSchemaIfAppsInstalled.some(function (appId) {
        return findInstalledApp(appId);
    });
    return isEnabledBySpec && !isMissingData && !isMissingApp && !isDisabledByApp;
};
