import adapter from '../adapter-forum-post';
var IDs = adapter.IDs, SchemaTypes = adapter.SchemaTypes;
export default {
    type: SchemaTypes.FORUM_POST_QUESTIONS_AND_ANSWERS,
    schema: {
        '@context': 'https://schema.org',
        '@type': 'QAPage',
        mainEntity: {
            '@type': 'Question',
            name: "{{".concat(IDs.TITLE, "}}"),
            text: "{{".concat(IDs.CONTENT_FULL, "}}"),
            answerCount: "{{".concat(IDs.COMMENT_AMOUNT, "}}"),
            upvoteCount: "{{".concat(IDs.LIKE_AMOUNT, "}}"),
            dateCreated: "{{".concat(IDs.PUBLISH_DATE, "}}"),
            author: {
                '@type': 'Person',
                name: "{{".concat(IDs.AUTHOR, "}}"),
            },
            acceptedAnswer: "{{".concat(IDs.QUESTIONS_AND_ANSWERS_SD_ACCEPTED_ANSWER, "}}"),
            suggestedAnswer: "{{".concat(IDs.QUESTIONS_AND_ANSWERS_SD_SUGGESTED_ANSWER, "}}"),
        },
    },
};
