import { get } from '../../utils/get';
import { getImageObject } from '../../adapters/structure-data-utils';
export var getStructuredData = function (IDs, collectionItems, coverImageData) {
    var _a;
    if (!IDs) {
        return {};
    }
    var imagesData = collectionItems.map(function (collectionItem) {
        return {
            url: get(collectionItem, 'project.coverImage.imageInfo.url'),
            width: get(collectionItem, 'project.coverImage.imageInfo.width'),
            height: get(collectionItem, 'project.coverImage.imageInfo.height'),
            alt: get(collectionItem, 'project.coverImage.imageInfo.altText'),
        };
    });
    imagesData.push({
        url: coverImageData[IDs.COVER_IMAGE],
        width: coverImageData[IDs.COVER_IMAGE_WIDTH],
        height: coverImageData[IDs.COVER_IMAGE_HEIGHT],
        alt: coverImageData[IDs.COVER_IMAGE_ALT],
    });
    var _b = getImageObject('images', imagesData, false).images, images = _b === void 0 ? '' : _b;
    return _a = {},
        _a[IDs.SD_JSON_IMAGES] = images,
        _a;
};
