const arenaBaseUrl =
  'https://www.wix.com/marketplace/dynamic-brief?entry=SEOWizard&serviceType=6&innerType=1';
export const HIRE_AN_EXPERT_URL = `${arenaBaseUrl}&origin=advanceseo`;
export const HIRE_AN_EXPERT_URL_VERTICAL = `${arenaBaseUrl}&origin=verticaladvanceseo`;

export const MAX_CUSTOM_TAGS_ALLOWED = 10;
export const RESET_TO_PATTERN_BUTTON = 'reset_to_pattern_button';
export const STRUCTURED_DATA_MARKUP = 'structured_data_markup';

export const SCRIPT_TAG_PARTS = {
  OPENNING: '<script type="application/ld+json">',
  CLOSING: '</script>',
  PARTIAL: '<script',
};

export const getWixSeoSettingsUrl = (msid) =>
  `https://www.wix.com/dashboard/${msid}/seo-home/seo-settings`;

export const getPremiumDomainsUrl = (metaSiteId) =>
  `https://www.wix.com/dashboard/${metaSiteId}/manage-website/domains?referralInfo=editor-seo-panel`;

export const SAVE_METHOD_TYPE = {
  DONE_BUTTON: 'done_button',
  ON_BLUR: 'on_blur',
};

export const EXPAND_COLLAPSE_TYPE = {
  EXPAND: 'Expand',
  COLLAPSE: 'Collapse',
};
