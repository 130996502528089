import React from 'react';
import { number, string } from 'prop-types';

export function Change({ color, width, height }) {
  return (
    <svg viewBox="0 0 25 25" fill={color} width={width} height={height}>
      <path d="M10,9 L6.758,9.083 C7.781,7.272 10.135,6.119 12.234,6.119 C15.412,6.119 18,9 18,12 L19,12 C19,8 15.959,5.119 12.23,5.119 C8.175,5.119 6,8.333 6,8.333 L6,5 L5,5 L5,10 L10,10 L10,9 Z M14,15 L17.25,14.917 C16.228,16.728 13.877,17.881 11.778,17.881 C8.601,17.881 6.016,15 6.016,12 L5.016,12 C5.016,16 8.049,18.881 11.778,18.881 C15.833,18.881 18,15.667 18,15.667 L18,19 L19,19 L19,14 L14,14 L14,15 Z" />
    </svg>
  );
}

Change.propTypes = {
  width: number,
  height: number,
  color: string,
};

Change.defaultProps = {
  width: 25,
  height: 25,
  color: 'currentColor',
};
