import { get, getRawValue } from '../../utils/get';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
var IDs = {
    ID: 'category.id',
    SITE_NAME: 'site.name',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    TITLE: 'category.name',
    DESCRIPTION: 'category.description',
    MAIN_IMAGE: 'category.image',
    IMAGE_WIDTH: 'category.image.width',
    IMAGE_HEIGHT: 'category.image.height',
    CATEGORY_PAGE_URL: 'category.url',
    POST_AMOUNT: 'category.posts.amount',
    VIEW_AMOUNT: 'category.views.amount',
};
var userVisible = [
    IDs.TITLE,
    IDs.POST_AMOUNT,
    IDs.VIEW_AMOUNT,
    IDs.DESCRIPTION,
    IDs.CATEGORY_PAGE_URL,
    IDs.MAIN_IMAGE,
    IDs.SITE_NAME,
];
var getCategoryPageUrl = function (item) {
    return resolveItemTypeMultilingualUrl(ITEM_TYPES.FORUM_CATEGORY, item, get(item, 'category.refersTo'));
};
export default {
    IDs: IDs,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.ID] = get(item, 'category._id'),
            _a[IDs.SITE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.INDEX_SITE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_SITE)),
            _a[IDs.INDEX_PAGE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_PAGE)),
            _a[IDs.TITLE] = get(item, 'category.title'),
            _a[IDs.DESCRIPTION] = get(item, 'category.description'),
            _a[IDs.MAIN_IMAGE] = get(item, 'category.images.0.src'),
            _a[IDs.IMAGE_WIDTH] = get(item, 'category.images.0.width'),
            _a[IDs.IMAGE_HEIGHT] = get(item, 'category.images.0.height'),
            _a[IDs.CATEGORY_PAGE_URL] = getCategoryPageUrl(item),
            _a[IDs.POST_AMOUNT] = get(item, 'category.totalPosts'),
            _a[IDs.VIEW_AMOUNT] = get(item, 'category.totalViews'),
            _a;
    },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        var urlArray = url.split('/');
        var lastPart = urlArray.pop();
        item.category.refersTo = lastPart;
        return item;
    },
    getFullUrl: function (item) { return getCategoryPageUrl(item); },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function (item) { return get(item, 'category.slug'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
