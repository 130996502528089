import React, { PureComponent } from 'react';
import { string, func } from 'prop-types';
import {
  Tooltip,
  TextLabel,
  ContextMenu,
  ContextMenuAction,
  Text,
} from '@wix/wix-base-ui';

import { Change } from '../../../../core/components/icons/change';
import { Adjust } from '../../../../core/components/icons/adjust';
import { Delete } from '../../../../core/components/icons/delete';
import { Upload } from '../../../../core/components/icons/upload';
import { More } from '../../../../core/components/icons/more';
import { Button } from './button';
import {
  getScaledLargeImagePreview,
  getScaledTwitterSmallImagePreview,
} from '../../../../core/utils/utils';
import {
  TWITTER_IMAGE_SMALL_HEIGHT_PX,
  TWITTER_IMAGE_SMALL_WIDTH_PX,
} from '../../../../core/utils/twitter-tags';
import { SocialPreview as SocialPreviewComponent } from 'wix-style-react';

const TWITTER_IMAGE_BORDER_RADIUS = '12px';
export class SocialPreview extends PureComponent {
  static propTypes = {
    defaultSiteImageUrl: string,
    siteImageUrl: string,
    customSiteImageUrl: string,
    siteUrl: string,
    siteTitle: string,
    siteDescription: string,
    t: func.isRequired,
    onUpload: func.isRequired,
    onChange: func.isRequired,
    onAdjust: func.isRequired,
    onDelete: func.isRequired,
    skin: string,
    size: string,
  };

  isTwitter = () => this.props.skin === 'twitter';

  isTwitterSmall = () => this.isTwitter() && this.props.size === 'small';

  isTwitterLarge = () => this.isTwitter() && !this.isTwitterSmall();

  render() {
    const { siteUrl, siteTitle, siteDescription } = this.props;

    if (this.isTwitter()) {
      return this.renderTwitterSocialPreview();
    }

    return (
      <div
        className="seo-advanced-client-social-preview"
        data-hook="social-preview"
      >
        <div className="seo-advanced-client-social-preview-container">
          {this.renderImage()}
          <div className="seo-advanced-client-social-preview-details">
            {siteUrl && (
              <div className="seo-advanced-client-social-preview-url">
                {siteUrl}
              </div>
            )}
            {siteTitle && (
              <div
                className="seo-advanced-client-social-preview-title"
                data-hook="seo-advanced-client-social-preview-title"
              >
                {siteTitle}
              </div>
            )}
            {siteDescription && (
              <div
                className="seo-advanced-client-social-preview-description"
                data-hook="seo-advanced-client-social-preview-description"
              >
                {siteDescription}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderImage = () => {
    const { siteImageUrl, defaultSiteImageUrl } = this.props;
    if (siteImageUrl && siteImageUrl !== defaultSiteImageUrl) {
      return this.renderPreviewWithImage();
    }
    if (defaultSiteImageUrl) {
      return this.renderPreviewWithDefaultImage();
    }
    return this.renderEmptyPreview();
  };

  renderEmptyPreview = () => {
    const { onUpload, t } = this.props;
    return (
      <div
        onClick={onUpload}
        className="seo-advanced-client-social-preview-image-empty"
        style={{
          ...this.getImageStyleProperties(),
        }}
      >
        <Button
          icon={<Upload />}
          text={
            !this.isTwitterSmall() &&
            t('adv-seo.social-panel.preview.upload.image')
          }
        />
      </div>
    );
  };

  renderAdjustButton() {
    const { t, onAdjust, defaultSiteImageUrl } = this.props;
    return this.isTwitterSmall() ? (
      <Tooltip
        content={
          <TextLabel
            type="T02"
            value={t('adv-seo.social-panel.preview.adjust')}
            shouldTranslate={false}
          />
        }
        shouldTranslate={false}
      >
        <Button
          onClick={() => {
            onAdjust({ defaultSiteImageUrl });
          }}
          icon={<Adjust />}
          dataHook="adv-seo.social-panel.preview.adjust.image"
        />
      </Tooltip>
    ) : (
      <Button
        onClick={() => {
          onAdjust({ defaultSiteImageUrl });
        }}
        icon={<Adjust style={{ paddingRight: '3px' }} />}
        text={t('adv-seo.social-panel.preview.adjust.image')}
        dataHook="adv-seo.social-panel.preview.adjust.image"
      />
    );
  }

  renderChangeButton = () => {
    const { t, onChange } = this.props;
    return (
      <Tooltip
        content={
          <TextLabel
            type="T02"
            value={t('adv-seo.social-panel.preview.change.image')}
            shouldTranslate={false}
          />
        }
        shouldTranslate={false}
      >
        <Button
          onClick={onChange}
          icon={<Change />}
          dataHook="adv-seo.social-panel.preview.change.image"
        />
      </Tooltip>
    );
  };

  renderDeleteButton = () => {
    const { onDelete, t } = this.props;
    return (
      <Tooltip
        content={
          <TextLabel
            type="T02"
            value={t('adv-seo.social-panel.preview.delete.image')}
            shouldTranslate={false}
          />
        }
        shouldTranslate={false}
      >
        <Button
          onClick={onDelete}
          icon={<Delete />}
          dataHook="adv-seo.social-panel.preview.delete.image"
        />
      </Tooltip>
    );
  };

  renderContextMenu = () => {
    const { t, onChange, onDelete } = this.props;
    return (
      <ContextMenu
        alignment="RIGHT"
        customButton={
          <Button
            icon={<More style={{ paddingRight: '2px' }} />}
            dataHook="adv-seo.social-panel.preview.more.image"
          />
        }
      >
        <ContextMenuAction onClick={onChange}>
          <span style={{ marginRight: '10px' }}>
            <Change />
          </span>
          <Text shouldTranslate={false} size="small" weight="thin">
            {t('adv-seo.social-panel.preview.change.image')}
          </Text>
        </ContextMenuAction>
        <ContextMenuAction onClick={onDelete}>
          <span style={{ marginRight: '10px' }}>
            <Delete />
          </span>
          <Text shouldTranslate={false} size="small" weight="thin">
            {t('adv-seo.social-panel.preview.delete.image')}
          </Text>
        </ContextMenuAction>
      </ContextMenu>
    );
  };

  getImageStyleProperties = () => {
    if (this.isTwitterSmall()) {
      return {
        height: TWITTER_IMAGE_SMALL_HEIGHT_PX,
        width: TWITTER_IMAGE_SMALL_WIDTH_PX,
        flexGrow: 0,
        flexShrink: 0,
        borderTopLeftRadius: TWITTER_IMAGE_BORDER_RADIUS,
        borderBottomLeftRadius: TWITTER_IMAGE_BORDER_RADIUS,
      };
    } else if (this.isTwitterLarge()) {
      return {
        borderTopLeftRadius: TWITTER_IMAGE_BORDER_RADIUS,
        borderTopRightRadius: TWITTER_IMAGE_BORDER_RADIUS,
      };
    }

    return {};
  };

  renderPreviewWithDefaultImage = () => {
    const { defaultSiteImageUrl } = this.props;
    const url = this.isTwitterSmall()
      ? getScaledTwitterSmallImagePreview(defaultSiteImageUrl) ||
        defaultSiteImageUrl
      : getScaledLargeImagePreview(defaultSiteImageUrl) || defaultSiteImageUrl;
    return (
      <div
        className="seo-advanced-client-social-preview-image"
        style={{
          backgroundImage: `url(${url})`,
          ...this.getImageStyleProperties(),
        }}
      >
        {this.renderAdjustButton()}
        {this.renderChangeButton()}
      </div>
    );
  };

  shouldShowDelete = () => {
    const { customSiteImageUrl } = this.props;
    return !this.isTwitter() || !!customSiteImageUrl;
  };

  hasContextMenu() {
    return this.isTwitterSmall() && this.shouldShowDelete();
  }

  renderPreviewWithImage = () => {
    const { siteImageUrl } = this.props;
    const url = this.isTwitterSmall()
      ? getScaledTwitterSmallImagePreview(siteImageUrl)
      : getScaledLargeImagePreview(siteImageUrl);
    return (
      <div
        className="seo-advanced-client-social-preview-image"
        style={{
          backgroundImage: `url(${url})`,
          ...this.getImageStyleProperties(),
        }}
      >
        {this.hasContextMenu() ? (
          <>
            {this.renderAdjustButton()}
            {this.renderContextMenu()}
          </>
        ) : (
          <>
            {this.renderAdjustButton()}
            {this.renderChangeButton()}
            {this.shouldShowDelete() && this.renderDeleteButton()}
          </>
        )}
      </div>
    );
  };

  renderTwitterSocialPreview = () => {
    const { size, siteTitle, siteDescription, siteUrl } = this.props;
    return (
      <SocialPreviewComponent
        dataHook="twitter-preview"
        size={size}
        skin="twitter"
        data-hook="twitter-social-preview"
        title={siteTitle}
        description={siteDescription}
        previewUrl={siteUrl}
        media={this.renderImage()}
      />
    );
  };
}
