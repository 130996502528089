"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAccessible = void 0;
var get_1 = require("../../../../utils/get");
var isAccessible = function (item) {
    if (item === void 0) { item = {}; }
    return (0, get_1.get)(item, 'program.settings.accessRestrictions.accessType').toLowerCase() !==
        'secret';
};
exports.isAccessible = isAccessible;
