import { IDENTIFIERS } from '@wix/advanced-seo-utils';
import { KEYS } from '../utils/maps';

import {
  createGetter,
  createAdvancedTagsGetter,
  createCustomTagsGetter,
  createCustomTagsArrayGetter,
  createJsonLdGetter,
  createSchemasMapGetter,
  createIsIndexEnabledGetter,
  createImageGetter,
  createPreventAutoRedirectGetter,
} from '../app-base';

export const initGetters = (experiments, siteContext = {}) => {
  const faqSdSpec = 'specs.seo.EnableFaqSD';
  const contextForSdCalculation = {
    ...siteContext,
    'site.experiments': {
      [faqSdSpec]: experiments?.enabled(faqSdSpec),
    },
    'page.isHomePage': siteContext.isHomePage,
    'site.business.location.formatted': siteContext.businessLocationFormatted,
    'site.installedApps': siteContext.installedApps,
  };

  return {
    [KEYS.TITLE]: createGetter(IDENTIFIERS.TITLE),
    [KEYS.DESCRIPTION]: createGetter(IDENTIFIERS.DESCRIPTION),
    [KEYS.OG_TITLE]: createGetter(IDENTIFIERS.OG_TITLE),
    [KEYS.OG_DESCRIPTION]: createGetter(IDENTIFIERS.OG_DESCRIPTION),
    [KEYS.OG_IMAGE]: createImageGetter(IDENTIFIERS.OG_IMAGE),
    [KEYS.TWITTER_CARD]: createGetter(IDENTIFIERS.TWITTER_CARD),
    [KEYS.TWITTER_TITLE]: createGetter(IDENTIFIERS.TWITTER_TITLE),
    [KEYS.TWITTER_DESCRIPTION]: createGetter(IDENTIFIERS.TWITTER_DESCRIPTION),
    [KEYS.TWITTER_IMAGE]: createImageGetter(IDENTIFIERS.TWITTER_IMAGE),
    [KEYS.CANONICAL]: createGetter(IDENTIFIERS.CANONICAL),
    [KEYS.ADVANCED_TAGS]: createAdvancedTagsGetter(),
    [KEYS.CUSTOM_TAGS]: createCustomTagsGetter(),
    [KEYS.CUSTOM_TAGS_ARRAY]: createCustomTagsArrayGetter(),
    [KEYS.JSON_LD]: createJsonLdGetter(contextForSdCalculation),
    [KEYS.SCHEMAS_MAP]: createSchemasMapGetter(contextForSdCalculation),
    [KEYS.IS_INDEX_ENABLED]: createIsIndexEnabledGetter(),
    [KEYS.ROBOTS_TAG]: createGetter(IDENTIFIERS.ROBOTS),
    [KEYS.PREVENT_AUTO_REDIRECT]: createPreventAutoRedirectGetter(),
  };
};
