import React, { Component } from 'react';
import { translate } from 'react-i18next';
import styles from '../edit-tag-items/edit-tag-panel.scss';
import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { func, object, string } from 'prop-types';
import classnames from 'classnames';
import EditTagPanelFooter from '../edit-tag-items/edit-tag-panel-footer';
import TooltipContent from '../../ui/tooltip-content';
import Popup from '../../ui/popup';
import isEqual from 'lodash/isEqual';
import InfoIcon from '../../ui/info-icon';
import { Revert } from '@wix/wix-ui-icons-common';
import {
  PanelHeader,
  TextButton,
  Composites,
  TextLabel,
  TextInput,
  RichText,
  ToggleSwitch,
  Divider,
} from '@wix/wix-base-ui';
import { getBoundingRect, getSafePanelPosition } from '../../ui-utils';
import {
  PROMOTE_SEO_CLOSE_TAG_SETTINGS,
  PROMOTE_SEO_CTA_CLICK,
  PROMOTE_SEO_ACTION_CLICK,
  PROMOTE_SEO_PANEL_COMPONENT_VIEW,
} from '../../../../core/bi/action-types';
import { TagPreview } from '../../../../core/components/tag-preview';
import { KEYS } from '../../../../core/utils/maps';
import { getItemTypeKebabCase } from '../../../../core/utils/utils';
import { RESET_TO_PATTERN_BUTTON } from '../../../../core/utils/consts';

class EditAdvancedTagPanel extends Component {
  static propTypes = {
    onClose: func.isRequired,
    onApply: func.isRequired,
    logBiEvent: func.isRequired,
    tag: object.isRequired,
    t: func.isRequired,
    itemType: string.isRequired,
    siteDashboardURL: string.isRequired,
    getDefaultPatternValue: func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      tag: { value = '', isDisabled, label, generalIdentifier },
      getDefaultPatternValue,
    } = this.props;
    this.state = {
      value,
      isDisabled,
      error: '',
      validateError: false,
      isVisible: false,
      position: null,
    };
    this.defaultPatternValue = getDefaultPatternValue(KEYS.ADVANCED_TAGS, {
      value: '',
      isDisabled: false,
      label,
      generalIdentifier,
    });
  }

  componentDidMount() {
    const position = getSafePanelPosition(
      getBoundingRect('advanced-panel-container'),
      getBoundingRect('edit-advanced-panel'),
    );
    const { logBiEvent, tag } = this.props;
    if (this.defaultPatternValue !== this.state.value) {
      logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
        field: `${[KEYS.ADVANCED_TAGS]}_${tag.label}`,
        component: RESET_TO_PATTERN_BUTTON,
      });
    }
    this.setState({ isVisible: true, position });
  }

  componentDidUpdate(prevProps, prevState) {
    const { tag, logBiEvent } = this.props;
    if (this.state.validateError) {
      if (tag.error) {
        this.setState({ error: tag.error, validateError: false });
      } else if (!this.state.value && isEqual(tag, prevProps.tag)) {
        this.props.onClose();
        return;
      }
    }
    if (prevState.isDisabled && !this.state.isDisabled) {
      setTimeout(() => {
        this.props.onLayout(getBoundingRect('edit-advanced-panel'));
      }, 100);
    }
    if (
      this.defaultPatternValue !== this.state.value &&
      this.defaultPatternValue === prevState.value
    ) {
      logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
        field: `${[KEYS.ADVANCED_TAGS]}_${tag.label}`,
        component: RESET_TO_PATTERN_BUTTON,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Popup
        data-hook="adv-seo.editor.advanced-tag.edit-panel"
        onClickOutside={() => this._onClose('off click')}
      >
        <div
          id="edit-advanced-panel"
          onMouseDown={(event) => event.stopPropagation()}
          style={{
            ...(this.state.position || {}),
            position: 'absolute',
            zIndex: 2000,
            opacity: this.state.isVisible ? 1 : 0,
          }}
        >
          <Composites.PopupSmallSize className={classnames(styles.panel)}>
            {this._renderHeader()}
            <Divider long />
            <div className={styles.panelContent}>
              <>
                {this._renderInput()}
                <div className={styles.patternsCtaContainer}>
                  {this._renderPatternsCTA()}
                </div>
                <Divider long />
                {this._renderPreview()}
                <Divider long />
              </>
              <EditTagPanelFooter
                onApply={this._onApply}
                onCancel={() => this._onClose('cancel')}
                isApplyDisabled={!this._isDirty()}
                t={t}
              />
            </div>
          </Composites.PopupSmallSize>
        </div>
      </Popup>
    );
  }

  _renderHeader = () => {
    const { t } = this.props;

    return (
      <PanelHeader
        dataHook="adv-seo.editor.advanced-tag.edit-panel.header"
        className="light"
        noHelpBtn={true}
        onClose={() => this._onClose('X')}
      >
        <span>{t('adv-seo.adv-panel.advanced-tags.edit-panel.title')}</span>
      </PanelHeader>
    );
  };

  _renderToggleSwitch = () => {
    const { t } = this.props;
    return (
      <Composites.ToggleSwitch>
        <ToggleSwitch
          label={t('adv-seo.adv-panel.advanced-tags.edit-panel.enable-tag')}
          shouldTranslate={false}
          dataHook="adv-seo.editor.advanced-tags.edit-panel.toggle"
          value={!this.state.isDisabled}
          onChange={this._handleToggleSwitch}
        />
      </Composites.ToggleSwitch>
    );
  };

  _handleToggleSwitch = (v) => {
    this.setState({ isDisabled: !v });
  };

  _renderInput = () => {
    const { t } = this.props;
    const { value, error: isError } = this.state;

    return (
      <Composites.TextInputLabeled
        data-hook="adv-seo.editor.advanced-tag.edit-panel.tag-input-container"
        className={styles.inputContainer}
      >
        <InfoIcon
          text={t(
            'adv-seo.adv-panel.advanced-tags.tag-input.info-text.site-settings',
          )}
        />
        <TextLabel
          value={t('adv-seo.adv-panel.advanced-tags.edit-panel.tag-value')}
          shouldTranslate={false}
        />
        <TextInput
          placeholder={t('adv-seo.adv-panel.tags.edit-panel.placeholder')}
          dataHook="adv-seo.editor.advanced-tags.edit-panel.text-input"
          value={value}
          shouldTranslate={false}
          onChange={this._onChange}
          onBlur={(e) => this._onBlur(e)}
          tooltipIsOpen={isError}
          invalidMessage={
            isError && (
              <TooltipContent shouldTranslate={false} text={this.state.error} />
            )
          }
        />
      </Composites.TextInputLabeled>
    );
  };

  _renderPreview = () => {
    const { tag, t } = this.props;
    const { value } = this.state;

    return (
      <Composites.RichText data-hook="adv-seo.editor.advanced-tag.edit-panel.tag-preview-container">
        <TextLabel
          value={t('adv-seo.adv-panel.advanced-tags.edit-panel.tag-preview')}
          shouldTranslate={false}
        />
        <RichText type="T02 Light" className={styles.textPreviewContainer}>
          <TagPreview
            generalIdentifier={tag.generalIdentifier}
            label={tag.label}
            value={value}
            textProps={{
              className: classnames(styles.textPreview),
              'data-hook':
                'adv-seo.editor.advanced-tag-edit-panel.preview-text',
            }}
            valueProps={{
              className: styles.valuePreview,
            }}
          />
        </RichText>
      </Composites.RichText>
    );
  };

  _renderPatternsCTA = () => {
    const { t } = this.props;
    return this.defaultPatternValue === this.state.value ? (
      <RichText
        className="light"
        type="T04"
        dataHook="adv-seo.editor.advanced-tag.edit-panel.edit-pattern-cta"
      >
        {t(
          'adv-seo.adv-panel.advanced-tags.edit-panel.follows-pattern.text.site-settings',
        )}{' '}
        <a onClick={this._openPatternPage}>
          {t(
            'adv-seo.adv-panel.advanced-tags.edit-panel.follows-pattern.cta.text.site-settings',
          )}
        </a>
      </RichText>
    ) : (
      <TextButton
        dataHook="adv-seo.editor.advanced-tag.edit-panel.reset-to-pattern-cta"
        onClick={this._resetTagValueToPattern}
        size="small"
        prefixIcon={<Revert />}
        shouldTranslate={false}
      >
        {t(
          'adv-seo.adv-panel.advanced-tags.edit-panel.edit-tag.reset-to-pattern.site-settings',
        )}
      </TextButton>
    );
  };

  _onClose = (source) => {
    const { onClose, logBiEvent, tag } = this.props;
    logBiEvent(PROMOTE_SEO_CLOSE_TAG_SETTINGS, {
      fieldName: tag.label,
      closeType: source,
    });

    onClose();
  };

  _isDirty = () => {
    const { tag } = this.props;
    const { value, isDisabled } = this.state;
    return tag.value !== value || tag.isDisabled !== isDisabled;
  };

  _onApply = () => {
    const { onApply, tag } = this.props;
    const { value, isDisabled, error } = this.state;

    this.setState({ validateError: true });

    onApply({
      value: error && isDisabled ? tag.value : value,
      isDisabled,
    });
  };

  _onChange = (value) => {
    const newState = { value };
    if (this.state.error.length) {
      newState.error = '';
    }
    this.setState({ ...newState });
  };

  _onBlur = (e) => {
    if (!e.target.value) {
      const newState = { value: this.defaultPatternValue, error: '' };
      this.setState({ ...newState });
    }
  };

  _openPatternPage = () => {
    const { siteDashboardURL, itemType, logBiEvent } = this.props;
    const queryParams = {
      openCardId: 'advanced-tags',
      scrollToOpenedCard: true,
    };
    const itemTypeRoute = getItemTypeKebabCase(
      itemType === ITEM_TYPES.STATIC_PAGE_V2
        ? ITEM_TYPES.STATIC_PAGE
        : itemType,
    );
    const queryParamsString = new URLSearchParams(queryParams);
    const patternAddress = `${siteDashboardURL}/seo-home/seo-settings/${itemTypeRoute}?${queryParamsString.toString()}`;
    window.open(patternAddress, '_blank');
    logBiEvent(PROMOTE_SEO_CTA_CLICK, {
      linkName: 'go to patterns',
      linkType: 'general',
      linkSource: 'panel',
    });
  };

  _resetTagValueToPattern = () => {
    const {
      logBiEvent,
      tag: { label },
    } = this.props;
    this.setState({ ...{ value: this.defaultPatternValue, error: '' } });
    logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
      fieldName: label,
      action: 'reset',
    });
  };
}

export default translate()(EditAdvancedTagPanel);
