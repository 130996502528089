import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { sanitizeUrl } from '../../utils/sanitize-url';
import { get } from '../../utils/get';
import { getMainImageUrl } from './calculated/program/get-main-image-url';
import { getRobots } from './calculated/program/get-robots';
import { shorten } from '../../utils/shorten';
import { getName } from './calculated/program/get-name';
import { getCreatedDate } from './calculated/program/get-created-date';
import { getCurrency } from './calculated/program/get-currency';
import { getPrice } from './calculated/program/get-price';
export var getData = function (IDs, itemData) {
    var _a;
    var fullDescription = get(itemData, 'program.settings.description.details');
    return _a = {},
        _a[IDs.ID] = get(itemData, 'program.id'),
        _a[IDs.AVAILABILITY] = get(itemData, 'program.settings.accessRestrictions.accessType'),
        _a[IDs.MAIN_IMAGE] = getMainImageUrl(itemData),
        _a[IDs.CALCULATED_ROBOTS] = getRobots(itemData),
        _a[IDs.CREATED_DATE] = getCreatedDate(itemData),
        _a[IDs.CURRENCY] = getCurrency(itemData),
        _a[IDs.DESCRIPTION] = fullDescription,
        _a[IDs.DESCRIPTION_150] = shorten(fullDescription, 150),
        _a[IDs.IMAGE_HEIGHT] = get(itemData, 'program.settings.description.media.image.height'),
        _a[IDs.IMAGE_ID] = get(itemData, 'program.settings.description.media.image.id'),
        _a[IDs.IMAGE_WIDTH] = get(itemData, 'program.settings.description.media.image.width'),
        _a[IDs.TITLE] = get(itemData, 'program.settings.description.title'),
        _a[IDs.CALCULATED_NAME] = getName(itemData),
        _a[IDs.PRICE] = getPrice(itemData),
        _a[IDs.URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.CHALLENGES_PAGE, itemData, sanitizeUrl("".concat(get(itemData, 'program.settings.seo.url.base')).concat(get(itemData, 'program.settings.seo.url.path')))),
        _a;
};
