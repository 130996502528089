import React, { useState, useEffect } from 'react';
import {
  useCharLimit,
  Composites,
  TextLabel,
  TextInput,
  CharLimit,
} from '@wix/wix-base-ui';
import { translate } from 'react-i18next';
import styles from '../panels.scss';
import InfoIcon from '../ui/info-icon';
import { MAX_LENGTH } from '../../../core/validators/validators';
import { KEYS } from '../../../core/utils/maps';
import RenderIf from '../../../core/components/render-if';
import ResetToPatternButton from '../ui/reset-to-pattern-button';

const SocialPanelTitle = ({
  t,
  value,
  onSave,
  onChange,
  error,
  logBiEvent,
  resetToPattern,
  showResetToPatternButton,
  getDefaultPatternValue,
}) => {
  const [isFocused, setFocused] = useState(false);

  const onBlur = () => {
    setFocused(false);
    onSave();
  };

  const onFocus = () => setFocused(true);

  const { showCharLimit, getTextInputProps, getCharLimitProps } = useCharLimit({
    initialValue: value,
    tooltipAlignment: 'RIGHT',
  });

  const { onChange: inputOnChange } = getTextInputProps({});
  useEffect(() => {
    inputOnChange(value);
  }, [value, inputOnChange]);
  return (
    <Composites.TextInputLabeled>
      <InfoIcon text="adv-seo.social-panel.page-title.input-tooltip" />
      <RenderIf truthy={showCharLimit && isFocused}>
        <CharLimit {...getCharLimitProps(MAX_LENGTH[KEYS.OG_TITLE])} />
      </RenderIf>
      <TextLabel
        value={t('adv-seo.social-panel.page-title.label')}
        shouldTranslate={false}
        automationId="social-title-label"
      />
      <div className={styles.inputWithResetContainer}>
        <div className={styles.inputContainer}>
          <TextInput
            {...getTextInputProps({
              invalidMessage: error,
              onChange,
              onFocus,
              onBlur,
            })}
            value={value}
            isValid={!error}
            hideSuccessIndication
            className="control-text-input"
            placeholder={t('adv-seo.common.add-title')}
            shouldTranslate={false}
            automationId="social-title-input"
          />
        </div>
        <RenderIf
          truthy={!isFocused && showResetToPatternButton(KEYS.OG_TITLE)}
        >
          <ResetToPatternButton
            onClick={() => {
              resetToPattern();
              inputOnChange(getDefaultPatternValue(KEYS.OG_TITLE));
            }}
            biKey={KEYS.OG_TITLE}
            logBiEvent={logBiEvent}
          />
        </RenderIf>
      </div>
    </Composites.TextInputLabeled>
  );
};

export default translate()(SocialPanelTitle);
