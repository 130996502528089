var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildOgMetaRefs, buildLink, buildTwitterMetaRefs, } from '../../types/builders';
export var FORUM_CATEGORY = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle('{{category.name}} | {{site.name}}'),
        buildStandardMeta('description', "{{category.description}}")
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:url', '{{category.url}}'),
        buildOgMeta('og:site_name', '{{site.name}}'),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:image', '{{category.image}}'),
        buildOgMeta('og:image:height', '{{category.image.height}}'),
        buildOgMeta('og:image:width', '{{category.image.width}}'),
        buildStandardMeta('robots', 'index')
    ], false), buildTwitterMetaRefs(), true), [
        buildLink({ rel: 'canonical', href: '{{category.url}}' }),
    ], false),
};
