"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.enrichWithSiteLevelData = exports.hasMissingSchemaVariables = exports.getSiteData = exports.siteIDs = exports.getKeys = void 0;
var get_1 = require("../utils/get");
var ContextProps_1 = require("../types/ContextProps");
var build_og_image_preview_1 = require("../utils/build-og-image-preview");
var calculate_opening_hours_specifications_1 = require("./calculated/calculate-opening-hours-specifications");
var get_resturants_images_1 = require("./calculated/get-resturants-images");
var getKeys = function (IDs, userVisible) {
    var keysMetaData = Object.values(IDs).reduce(function (acc, val) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[val] = { userVisibility: userVisible.includes(val) }, _a)));
    }, {});
    return Object.values(IDs).map(function (key) { return (__assign({ key: key }, keysMetaData[key])); });
};
exports.getKeys = getKeys;
exports.siteIDs = {
    SITE_NAME: 'site.name',
    PAGE_NAME: 'page.name',
    SITE_URL: 'site.url',
    SEARCH_PAGE_SLUG: 'site.search.slug',
    INDEX_SITE: 'site.index',
    ROBOTS_FROM_USER_PATTERN: 'userPatterns.robots',
    SITE_IMAGE: 'site.image',
    SITE_IMAGE_WIDTH: 'site.imageWidth',
    SITE_IMAGE_HEIGHT: 'site.imageHeight',
    FB_ADMINS: 'site.facebookAdminId',
    NEXT: 'site.nextLink',
    PREV: 'site.prevLink',
    CURRENT_PAGE_NUMBER: 'site.currentPageNumber',
    TOTAL_PAGE_COUNT: 'site.totalPageCount',
    BUSINESS_NAME: 'site.business.name',
    BUSINESS_LOCATION_COUNTRY: 'site.business.location.country',
    BUSINESS_LOCATION_STATE: 'site.business.location.state',
    BUSINESS_LOCATION_CITY: 'site.business.location.city',
    BUSINESS_LOCATION_STREET: 'site.business.location.street',
    BUSINESS_LOCATION_STREET_NUMBER: 'site.business.location.street.number',
    BUSINESS_LOCATION_STREET_ADDRESS: 'site.business.location.street.address',
    BUSINESS_LOCATION_DESCRIPTION: 'site.business.location.description',
    BUSINESS_LOCATION_FORMATTED: 'site.business.location.formatted',
    BUSINESS_LOCATION_LATITUDE: 'site.business.location.latitude',
    BUSINESS_LOCATION_LONGITUDE: 'site.business.location.longitude',
    BUSINESS_POSTAL_CODE: 'site.business.postal.code',
    BUSINESS_DESCRIPTION: 'site.business.description',
    BUSINESS_LOGO: 'site.business.logo',
    BUSINESS_SCHEMA_IMAGE: 'site.business.schema.image',
    BUSINESS_LOCALE: 'site.business.locale',
    BUSINESS_PHONE: 'site.business.phone',
    RESTAURANT_IMAGES: 'site.restaurant.images',
    EXPERIMENTS: 'site.experiments',
    OPENING_HOURS_SPECIFICATION: 'site.openingHoursSpecification',
};
var userVisibleSiteKeys = [
    exports.siteIDs.SITE_NAME,
    exports.siteIDs.BUSINESS_NAME,
    exports.siteIDs.BUSINESS_LOCATION_COUNTRY,
    exports.siteIDs.BUSINESS_LOCATION_STATE,
    exports.siteIDs.BUSINESS_LOCATION_CITY,
    exports.siteIDs.BUSINESS_LOCATION_STREET,
    exports.siteIDs.BUSINESS_LOCATION_DESCRIPTION,
    exports.siteIDs.BUSINESS_DESCRIPTION,
    exports.siteIDs.BUSINESS_LOGO,
];
var userVisibleSdOnly = [exports.siteIDs.SITE_URL];
var getSiteData = function (item) {
    var _a, _b;
    var _c;
    var experiments = getExperiments(item);
    var enableRestaurantMenuPageRenderTime = (experiments === null || experiments === void 0 ? void 0 : experiments['specs.restaurants.renderMenusSEOTags']) === 'true';
    var data = (_a = {},
        _a[exports.siteIDs.SITE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME)),
        _a[exports.siteIDs.PAGE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PAGE_NAME)),
        _a[exports.siteIDs.SITE_URL] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_URL)),
        _a[exports.siteIDs.INDEX_SITE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_SITE)),
        _a[exports.siteIDs.ROBOTS_FROM_USER_PATTERN] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.ROBOTS_FROM_USER_PATTERN)),
        _a[exports.siteIDs.SITE_IMAGE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_OG_IMAGE)),
        _a[exports.siteIDs.SITE_IMAGE_WIDTH] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.OG_IMAGE_WIDTH)),
        _a[exports.siteIDs.SITE_IMAGE_HEIGHT] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.OG_IMAGE_HEIGHT)),
        _a[exports.siteIDs.FB_ADMINS] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.FB_ADMINS)),
        _a[exports.siteIDs.NEXT] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.NEXT)),
        _a[exports.siteIDs.PREV] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PREV)),
        _a[exports.siteIDs.BUSINESS_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_NAME)),
        _a[exports.siteIDs.BUSINESS_LOCATION_COUNTRY] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_COUNTRY)),
        _a[exports.siteIDs.BUSINESS_LOCATION_STATE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_STATE)),
        _a[exports.siteIDs.BUSINESS_LOCATION_CITY] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_CITY)),
        _a[exports.siteIDs.BUSINESS_LOCATION_STREET] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_STREET)),
        _a[exports.siteIDs.BUSINESS_LOCATION_STREET_NUMBER] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_STREET_NUMBER)),
        _a[exports.siteIDs.BUSINESS_LOCATION_STREET_ADDRESS] = [
            (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_STREET_NUMBER)),
            (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_STREET)),
        ]
            .join(' ')
            .trim(),
        _a[exports.siteIDs.BUSINESS_LOCATION_DESCRIPTION] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_DESCRIPTION)),
        _a[exports.siteIDs.BUSINESS_LOCATION_FORMATTED] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_FORMATTED)),
        _a[exports.siteIDs.BUSINESS_POSTAL_CODE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_POSTAL_CODE)),
        _a[exports.siteIDs.BUSINESS_DESCRIPTION] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_DESCRIPTION)),
        _a[exports.siteIDs.BUSINESS_LOGO] = (0, build_og_image_preview_1.buildOgImagePreviewUrl)({
            url: (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOGO)),
        }),
        _a[exports.siteIDs.BUSINESS_SCHEMA_IMAGE] = (_c = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOGO))) !== null && _c !== void 0 ? _c : (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_OG_IMAGE)),
        _a[exports.siteIDs.BUSINESS_LOCALE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCALE)),
        _a[exports.siteIDs.BUSINESS_PHONE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_PHONE)),
        _a[exports.siteIDs.EXPERIMENTS] = experiments,
        _a[exports.siteIDs.BUSINESS_LOCATION_LATITUDE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_COORDINATES, ".latitude")),
        _a[exports.siteIDs.BUSINESS_LOCATION_LONGITUDE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_LOCATION_COORDINATES, ".longitude")),
        _a);
    var restaurantsMenuPageData = enableRestaurantMenuPageRenderTime
        ? (_b = {},
            _b[exports.siteIDs.RESTAURANT_IMAGES] = (0, get_resturants_images_1.getRestaurantImages)(item),
            _b[exports.siteIDs.OPENING_HOURS_SPECIFICATION] = (0, calculate_opening_hours_specifications_1.calculateOpeningHoursSpecifications)(item),
            _b) : {};
    return __assign(__assign({}, data), restaurantsMenuPageData);
};
exports.getSiteData = getSiteData;
var getExperiments = function (item) {
    var seoExperiments = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.EXPERIMENTS));
    var platformAppsExperiments = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PLATFORM_APPS_EXPERIMENTS));
    if (!seoExperiments && !platformAppsExperiments) {
        return undefined;
    }
    if (platformAppsExperiments) {
        platformAppsExperiments = Object.values(platformAppsExperiments).reduce(function (acc, experiments) { return (__assign(__assign({}, acc), experiments)); }, {});
    }
    return __assign(__assign({}, platformAppsExperiments), seoExperiments);
};
var getMissingSchemaVariables = function (requiredSchemaVariables) {
    return function (item, itemData) {
        if (itemData === void 0) { itemData = {}; }
        var siteData = (0, exports.getSiteData)(item);
        return requiredSchemaVariables.filter(function (variable) { return !(siteData[variable] || itemData[variable]); });
    };
};
var hasMissingSchemaVariables = function (requiredSchemaVariables, item, itemData) {
    return getMissingSchemaVariables(requiredSchemaVariables)(item, itemData).length > 0;
};
exports.hasMissingSchemaVariables = hasMissingSchemaVariables;
function enrichWithSiteLevelData(adapter) {
    var IDs = __assign(__assign({}, exports.siteIDs), adapter.IDs);
    return __assign(__assign({ requiredSchemaVariables: [] }, adapter), { IDs: IDs, getMissingSchemaVariables: getMissingSchemaVariables(adapter.requiredSchemaVariables), getData: function (item) { return (__assign(__assign({}, (0, exports.getSiteData)(item)), adapter.getData(item))); }, getKeys: function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.exposeBusinessKeys, exposeBusinessKeys = _c === void 0 ? false : _c;
            return __spreadArray(__spreadArray([], (exposeBusinessKeys ? (0, exports.getKeys)(exports.siteIDs, userVisibleSiteKeys) : []), true), adapter.getKeys(), true);
        }, getSdKeys: function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.exposeBusinessKeys, exposeBusinessKeys = _c === void 0 ? false : _c;
            return __spreadArray(__spreadArray([], (exposeBusinessKeys ? (0, exports.getKeys)(exports.siteIDs, userVisibleSdOnly) : []), true), adapter.getSdKeys(), true);
        } });
}
exports.enrichWithSiteLevelData = enrichWithSiteLevelData;
