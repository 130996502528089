import adapter from './adapter-stores-gallery-component';
import { buildOgMeta, buildLink, buildTwitterMeta } from '../../types/builders';
import { IDENTIFIERS } from '../../types/Identifiers';
import { siteIDs } from '../../adapters/utils';
var IDs = adapter.IDs;
var PREV = siteIDs.PREV, NEXT = siteIDs.NEXT;
export var STORES_GALLERY_COMPONENT = {
    tags: [
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.CURRENT_PAGE_URL, "}}") }),
        buildLink({ rel: 'prev', href: "{{".concat(PREV, "}}") }),
        buildLink({ rel: 'next', href: "{{".concat(NEXT, "}}") }),
        buildOgMeta('og:url', "{{".concat(IDs.CURRENT_PAGE_URL, "}}")),
        buildTwitterMeta('twitter:title', "{{@".concat(IDENTIFIERS.OG_TITLE, "}}")),
        buildTwitterMeta('twitter:description', "{{@".concat(IDENTIFIERS.OG_DESCRIPTION, "}}")),
    ],
};
