import React, { PureComponent } from 'react';
import { func, string, array, object } from 'prop-types';
import { translate } from 'react-i18next';
import { TextButton, Tooltip } from '@wix/wix-base-ui';
import * as BI_TYPES from '../../../../core/bi/action-types';
import { TAG_TYPE } from '../../../../core/utils/maps';

import RenderIf from '../../../../core/components/render-if';
import CustomTagPanel from './custom-tag-panel';
import TagItem from '../edit-tag-items/tag-item';
import map from 'lodash/map';
import classnames from 'classnames';
import styles from '../advanced-panel-tags.scss';

import { Add, Delete, Edit } from '@wix/wix-ui-icons-common';

import findIndex from 'lodash/findIndex';

import { PROMOTE_SEO_ACTION_CLICK } from '../../../../core/bi/action-types';

import { MAX_CUSTOM_TAGS_ALLOWED } from '../../../../core/utils/consts';

const ACTIONS = [
  {
    id: 'edit',
    name: 'adv-seo.adv-panel.custom-tag.edit-tag.settings-button',
    icon: <Edit />,
  },
  {
    id: 'delete',
    name: 'adv-seo.adv-panel.edit-tag.delete-button',
    icon: <Delete />,
  },
];

class AdvancedPanelCustomTags extends PureComponent {
  static propTypes = {
    tagsList: array.isRequired,
    error: string,
    onApply: func.isRequired,
    onChange: func,
    onTagDeleted: func,
    logBiEvent: func.isRequired,
    openHelpCenter: func.isRequired,
    t: func.isRequired,
    onAddTempTag: func,
    onRemoveTempTag: func,
    experiments: object,
  };

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      tempTagIndex: -1,
      panelPosition: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { tagsList } = this.props;
    if (tagsList !== prevProps.tagsList) {
      this.setState({
        tempTagIndex: findIndex(tagsList, (tag) => tag.temp),
      });
    }
  }

  render() {
    const { t, tagsList, onApply, logBiEvent, openHelpCenter } = this.props;
    const { tempTagIndex } = this.state;
    const disableAddNew =
      tagsList && tagsList.length >= MAX_CUSTOM_TAGS_ALLOWED;

    return (
      <div id="adavenced-panel-custom-tag-container">
        <>
          <div
            className={classnames(styles.customTagsList)}
            data-aid="seo-advanced-panel-custom-tags-tagsList"
          >
            {map(tagsList, this._renderTag)}
          </div>
          <div className={classnames(styles.addNewTagContainer)}>
            {this._renderAddNewTag(disableAddNew)}
          </div>
          <RenderIf truthy={tempTagIndex >= 0}>
            <CustomTagPanel
              t={t}
              tag={tagsList ? tagsList[tempTagIndex] : null}
              onClose={this._onPanelClosed}
              onApply={(appliedData) =>
                onApply({ ...appliedData, index: tempTagIndex })
              }
              logBiEvent={logBiEvent}
              openHelpCenter={openHelpCenter}
            />
          </RenderIf>
        </>
      </div>
    );
  }

  _renderAddNewTag = (disableAddNew) => {
    const { t } = this.props;
    return (
      <Tooltip
        content={t('adv-seo.adv-panel.custom-tags.add-new-tag-tooltip')}
        shouldTranslate={false}
        disabled={!disableAddNew}
      >
        <TextButton
          shouldTranslate={false}
          onClick={!disableAddNew ? this._handleAddNewTag : undefined}
          prefixIcon={<Add />}
          size="small"
          underline="none"
          data-aid="add-tag-button"
          disabled={disableAddNew}
        >
          {t('adv-seo.adv-panel.custom-tags.add-new-tag')}
        </TextButton>
      </Tooltip>
    );
  };

  _handleAddNewTag = () => {
    const { logBiEvent, onAddTempTag } = this.props;
    onAddTempTag();

    logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
      fieldName: 'customTags',
      action: 'Add New Tag',
      type: 'CTA',
    });
  };

  _onPanelClosed = () => {
    this.setState({ tempTagIndex: -1, panelPosition: null });
    this.props.onRemoveTempTag();
  };

  _renderTag = (tag, index) => {
    if (tag.temp) {
      return false;
    }

    const { onApply, logBiEvent, openHelpCenter } = this.props;
    const key = `${TAG_TYPE.CUSTOM}${index}`;

    return (
      <TagItem
        key={key}
        selector={key}
        logBiEvent={logBiEvent}
        tag={tag}
        tagType={TAG_TYPE.CUSTOM}
        actions={ACTIONS}
        onApply={(appliedData) => onApply({ ...appliedData, index })}
        onAction={(action) => this._onAction(action, index)}
        openHelpCenter={openHelpCenter}
        experiments={this.props.experiments}
      />
    );
  };

  _onAction = (action, index) => {
    let sendBiEvent = true;

    switch (action) {
      case 'delete':
        this.props.onTagDeleted({ index });
        break;

      default:
        sendBiEvent = false;
        break;
    }

    if (sendBiEvent) {
      const { logBiEvent } = this.props;
      logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
        fieldName: 'customTags',
        action,
        type: 'CTA',
      });
    }
  };

  _onFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  _onBlur = () => {
    this.setState({
      isFocused: false,
    });
    this.props.onSave();
  };

  _onInfoClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('b2abe8ce-90a0-4ea1-ae8e-0ed3aeec3103');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.custom-tags.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };

  _onErrorClicked = () => {
    const { logBiEvent, openHelpCenter } = this.props;
    openHelpCenter('b2abe8ce-90a0-4ea1-ae8e-0ed3aeec3103');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.custom-tags.error.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default translate()(AdvancedPanelCustomTags);
