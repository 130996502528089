import React, { useCallback, useMemo } from 'react';
import {
  DropDown,
  DropDownIllustrationOption,
  Text,
  Tooltip,
} from '@wix/wix-base-ui';
import {
  getHomePageId,
  getParent,
  getTitle,
  getPossibleParentsStatuses,
  getSortedHomePageChildren,
  POSSIBLE_PARENT_STATUS,
} from '@wix/seo-page-hierarchy-library';
import * as BI_TYPES from '../../../../core/bi/action-types';

import { Page, Home } from '@wix/wix-base-ui/icons';

import { usePageHierarchyContext } from '../../../hooks/page-hierarchy';
import styles from './parent-page-drop-down.scss';
import { createDropDownOptions } from './parent-page-drop-down-option';
import {
  SAVE_METHOD_TYPE,
  EXPAND_COLLAPSE_TYPE,
} from '../../../../core/utils/consts';
import { SEO_PANEL_TYPES } from '../../auto-redirects/utils/consts';
import { useAtomValue } from 'jotai';
import { pageIdAtom } from '../../../app/atoms';

type ParentPageDropDownProps = {
  t: any;
  logBiEvent: any;
  openHelpCenter: any;
  language: { current?: string; original?: string };
};

export const ParentPageDropDown: React.FC<ParentPageDropDownProps> = ({
  t,
  logBiEvent,
  openHelpCenter,
  language,
}) => {
  const isSecondaryLang =
    language.current && language.current !== language.original;
  const pageId = useAtomValue(pageIdAtom);
  const { siteStructureGraph, setParentPageId, clearParentPageId } =
    usePageHierarchyContext();
  const possibleParentStatuses = useMemo(
    () => getPossibleParentsStatuses(siteStructureGraph, pageId),
    [siteStructureGraph, pageId],
  );
  const homePageId = getHomePageId(siteStructureGraph);
  const selectedParentPageId = useMemo(
    () => getParent(siteStructureGraph, pageId) ?? homePageId,
    [siteStructureGraph, pageId, homePageId],
  );
  const homePageChildren = useMemo(
    () =>
      getSortedHomePageChildren(
        siteStructureGraph,
        possibleParentStatuses,
        pageId,
      ),
    [siteStructureGraph, possibleParentStatuses, pageId],
  );

  const onUpdateSelectedParentPageId = useCallback(
    (updatedParentPageId) => {
      updatedParentPageId === homePageId
        ? clearParentPageId()
        : setParentPageId(updatedParentPageId);
      logBiEvent(BI_TYPES.PROMOTE_SEO_FIELD_UPDATE, {
        fieldName: 'parent page',
        updatedValue: updatedParentPageId,
        updateType: SAVE_METHOD_TYPE.ON_BLUR,
      });
    },
    [homePageId, clearParentPageId, setParentPageId, logBiEvent],
  );

  const numberOfPagesInDropdown = useMemo(
    () => possibleParentStatuses.size,
    [possibleParentStatuses],
  );
  const numberOfAccessiblePages = useMemo(
    () =>
      [...possibleParentStatuses.values()].filter(
        (possibleParentStatus) =>
          possibleParentStatus === POSSIBLE_PARENT_STATUS.VALID,
      ).length,
    [possibleParentStatuses],
  );
  const onToggleDropDown = useCallback(
    (isOpen: boolean) => {
      logBiEvent(BI_TYPES.SEO_PANEL_HIERARCHY_DROP_DOWN_EXPAND_COLLAPSE, {
        context: SEO_PANEL_TYPES.EDITOR,
        numberOfAccessiblePages,
        numberOfPagesInDropdown,
        type: isOpen
          ? EXPAND_COLLAPSE_TYPE.EXPAND
          : EXPAND_COLLAPSE_TYPE.COLLAPSE,
      });
    },
    [logBiEvent, numberOfAccessiblePages, numberOfPagesInDropdown],
  );

  return (
    <div className="parent-page-drop-down-container">
      <Tooltip
        disabled={!isSecondaryLang}
        shouldTranslate={false}
        content={t(
          'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-disabled-secondary-lang-tooltip-text',
        )}
      >
        <DropDown
          disabled={isSecondaryLang}
          optionsMaxHeight={200}
          onToggle={onToggleDropDown}
          customSelectedText={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {selectedParentPageId === homePageId ? <Home /> : <Page />}
              <div style={{ width: '3px' }} />
              {getTitle(siteStructureGraph, selectedParentPageId)}
            </div>
          }
          value={selectedParentPageId}
          onChange={onUpdateSelectedParentPageId}
          shouldTranslate={false}
        >
          <DropDownIllustrationOption
            className={styles.parentPageDropDownContainer}
            value={homePageId}
            label=""
            key={homePageId}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '6px',
              }}
            >
              <Home />
              <div style={{ width: '3px' }} />
              <Text
                shouldTranslate={false}
                light={selectedParentPageId === homePageId}
              >
                {getTitle(siteStructureGraph, homePageId)}
              </Text>
            </div>
          </DropDownIllustrationOption>
          {createDropDownOptions(
            t,
            pageId,
            homePageChildren,
            siteStructureGraph,
            possibleParentStatuses,
            selectedParentPageId,
            logBiEvent,
            openHelpCenter,
          )}
        </DropDown>
      </Tooltip>
    </div>
  );
};
