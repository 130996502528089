import {
  getAutoRedirectRequests,
  getAutoRedirectsResults,
} from '@wix/advanced-seo-utils';
import { getRedirects } from './utils/get-redirects-from-pages';
import {
  getHttpClient,
  getMetaSiteInstance,
  getPagesFilteredByNotPreventAutoRedirect,
} from './utils/utils';
import { getSiteStructurePages } from './utils/get-site-structure-pages';
import { getCreateRedirect } from './utils/create-redirect';
import { handleCreateRedirectsResults } from './utils/handle-create-redirect-results';
import { initBiLogger } from '../../initializers';

export { getBeforePublishedPages, createAutoRedirect };

async function getBeforePublishedPages({ scope, metaSiteAppId }) {
  const isSitePublished = scope.editorApi.dsRead.generalInfo.isSitePublished();
  if (isSitePublished) {
    const metaSiteInstance = getMetaSiteInstance({ scope, metaSiteAppId });
    const siteStructurePages = await getSiteStructurePages({
      httpClient: getHttpClient({ metaSiteInstance }),
    });
    return siteStructurePages;
  }
  return [];
}

async function createAutoRedirect({
  scope,
  beforePublishedPages = [],
  metaSiteAppId,
  locale,
  showUserActionNotification,
  isEditorX,
} = {}) {
  if (isEditorX) {
    return;
  }
  const pagesData = scope.editorApi.dsRead.pages.getPagesData();
  const metaSiteInstance = getMetaSiteInstance({ scope, metaSiteAppId });
  const afterPublishedPages = await getSiteStructurePages({
    httpClient: getHttpClient({ metaSiteInstance }),
  });
  const redirects = getRedirects({
    beforePublishedPages,
    afterPublishedPages: getPagesFilteredByNotPreventAutoRedirect({
      pages: afterPublishedPages,
      pagesData,
    }),
  });
  const requests = getAutoRedirectRequests({ redirects });
  const logBiEvent = initBiLogger({
    msid: scope.editorApi.dsRead.generalInfo.getMetaSiteId(),
  }).logBiEvent;
  const results = await getAutoRedirectsResults({
    requests,
    createRedirect: getCreateRedirect({ metaSiteInstance, logBiEvent }),
  });
  await handleCreateRedirectsResults({
    createRedirectResults: results,
    scope,
    showUserActionNotification,
    locale,
    logBiEvent,
  });
}
