var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getKeys, siteIDs } from '../../adapters/utils';
import { get } from '../../utils/get';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { updateValueByIdentifier } from '../../tags/values/update-by-identifier';
import { IDENTIFIERS } from '../../types/Identifiers';
export var IDs = {
    SITE_NAME: 'site.name',
    TITLE: 'stores-category.categoryName',
    DESCRIPTION: 'stores-category.categoryDesc',
    CATEGORY_PAGE_URL: 'stores-category.categoryUrl',
    VISIBLE_ITEMS: 'stores-category.numberOfItems',
    MAIN_IMAGE: 'stores-category.image.imageUrl',
    IMAGE_WIDTH: 'stores-category.image.imageWidth',
    IMAGE_HEIGHT: 'stores-category.image.imageHeight',
    IMAGE_ALT: 'stores-category.image.imageAlt',
    SLUG: 'stores-category.categorySlug',
};
var SchemaTypes = {
    STORES_CATEGORY_ITEM_LIST: 'stores-category-item-list-preset',
};
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.CATEGORY_PAGE_URL,
    IDs.VISIBLE_ITEMS,
    IDs.MAIN_IMAGE,
];
var getCategoryFullUrl = function (item) { return get(item, 'category.categoryUrl'); };
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        var data = (_a = {},
            _a[IDs.SITE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.TITLE] = get(item, 'category.categoryName'),
            _a[IDs.DESCRIPTION] = get(item, 'category.categoryDesc'),
            _a[IDs.CATEGORY_PAGE_URL] = get(item, 'category.categoryUrl'),
            _a[IDs.VISIBLE_ITEMS] = get(item, 'category.numberOfItems'),
            _a[IDs.MAIN_IMAGE] = get(item, 'category.image.imageUrl'),
            _a[IDs.IMAGE_WIDTH] = get(item, 'category.image.imageWidth'),
            _a[IDs.IMAGE_HEIGHT] = get(item, 'category.image.imageHeight'),
            _a[IDs.IMAGE_ALT] = get(item, 'category.image.imageAlt'),
            _a[IDs.SLUG] = get(item, 'category.categorySlug'),
            _a[siteIDs.PREV] = (_d = (_c = (_b = item === null || item === void 0 ? void 0 : item.category) === null || _b === void 0 ? void 0 : _b.pagination) === null || _c === void 0 ? void 0 : _c.prevUrl) !== null && _d !== void 0 ? _d : '',
            _a[siteIDs.NEXT] = (_g = (_f = (_e = item === null || item === void 0 ? void 0 : item.category) === null || _e === void 0 ? void 0 : _e.pagination) === null || _f === void 0 ? void 0 : _f.nextUrl) !== null && _g !== void 0 ? _g : '',
            _a[siteIDs.CURRENT_PAGE_NUMBER] = (_k = (_j = (_h = item === null || item === void 0 ? void 0 : item.category) === null || _h === void 0 ? void 0 : _h.pagination) === null || _j === void 0 ? void 0 : _j.currentPage) !== null && _k !== void 0 ? _k : '',
            _a[siteIDs.TOTAL_PAGE_COUNT] = (_o = (_m = (_l = item === null || item === void 0 ? void 0 : item.category) === null || _l === void 0 ? void 0 : _l.pagination) === null || _m === void 0 ? void 0 : _m.totalPages) !== null && _o !== void 0 ? _o : '',
            _a);
        return __assign({}, data);
    },
    getSdStatus: function () { },
    getLegacySeoBlob: function (item) {
        var tags = updateValueByIdentifier([], IDENTIFIERS.TITLE, get(item, 'legacySeoData.title'));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION, get(item, 'legacySeoData.description'));
        return { tags: tags };
    },
    getSlug: function (item) {
        return get(item, 'category.categorySlug');
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        if (get(updatedItemData, 'category.categorySlug') !== '') {
            updatedItemData.category.categorySlug = slugValue;
        }
        return updatedItemData;
    },
    getFullUrl: function (itemData) { return getCategoryFullUrl(itemData); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.category.categoryUrl = url;
        return itemData;
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
