import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import { translate } from 'react-i18next';

import { Error } from '../../../core/components/icons/error';
import InfoIcon from '../ui/info-icon';
import * as BI_TYPES from '../../../core/bi/action-types';

class SocialPanelImageError extends PureComponent {
  static propTypes = {
    error: string.isRequired,
    openHelpCenter: func.isRequired,
    logBiEvent: func.isRequired,
    t: func.isRequired,
  };

  render() {
    const { t, error } = this.props;
    return (
      <InfoIcon
        error={true}
        customSymbol={<Error color="#ee5951" />}
        text={error}
        linkText={t('adv-seo.common.learn-more')}
        onLinkClick={this._onLearnMore}
        shouldTranslate={false}
      />
    );
  }

  _onLearnMore = () => {
    const { logBiEvent, openHelpCenter, baseTranslationKey } = this.props;
    openHelpCenter('d8405fb1-c787-4566-8f97-c43d920cc615');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: `${baseTranslationKey}.preview.error`,
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default translate()(SocialPanelImageError);
