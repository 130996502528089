// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sRWbiV{height:42px;width:430px;border-radius:6px;border:solid 1px #dfe5eb;background-color:#ffffff;display:flex;align-items:center;justify-content:space-between;margin-bottom:6px}.sRWbiV:hover{background-color:#eaf7ff}.sRWbiV .LK9BvA{display:flex;align-items:center;padding:0 12px;overflow:hidden}.sRWbiV .LK9BvA .jcQnRn{margin-top:3px;margin-right:12px}.sRWbiV .LK9BvA .JRonoj{color:#7a92a5;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.sRWbiV .Pk2QB9{margin-right:12px}.sRWbiV .a_7Ing{color:#7a92a5}.sRWbiV .ykoe24{opacity:0.7}.sRWbiV .ykoe24 .dFftpu{margin-left:6px;color:#fdb10c}.sRWbiV .aRAgeA{padding-right:12px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sRWbiV`,
	"label-container": `LK9BvA`,
	"labelContainer": `LK9BvA`,
	"icon": `jcQnRn`,
	"name": `JRonoj`,
	"hidden-icon": `Pk2QB9`,
	"hiddenIcon": `Pk2QB9`,
	"disabled-icon": `a_7Ing`,
	"disabledIcon": `a_7Ing`,
	"label-container-with-warning": `ykoe24`,
	"labelContainerWithWarning": `ykoe24`,
	"warning-tooltip": `dFftpu`,
	"warningTooltip": `dFftpu`,
	"context-menu-button": `aRAgeA`,
	"contextMenuButton": `aRAgeA`
};
export default ___CSS_LOADER_EXPORT___;
