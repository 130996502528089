import React, { Component } from 'react';
import {
  PanelHeader,
  Composites,
  TextLabel,
  Divider,
  TextInputMultiline,
} from '@wix/wix-base-ui';
import { translate } from 'react-i18next';
import styles from '../edit-tag-items/edit-tag-panel.scss';
import { func, object } from 'prop-types';
import classnames from 'classnames';
import EditTagPanelFooter from '../edit-tag-items/edit-tag-panel-footer';
import TooltipContent from '../../ui/tooltip-content';
import Popup from '../../ui/popup';
import InfoIcon from '../../ui/info-icon';
import { getBoundingRect, getSafePanelPosition } from '../../ui-utils';
import { PROMOTE_SEO_CLOSE_TAG_SETTINGS } from '../../../../core/bi/action-types';

class CustomTagPanel extends Component {
  static propTypes = {
    onClose: func.isRequired,
    onApply: func.isRequired,
    tag: object.isRequired,
    t: func.isRequired,
    logBiEvent: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.tag ? this.props.tag.html : '',
      error: '',
      validateError: false,
      isVisible: false,
      position: null,
    };
  }

  componentDidMount() {
    const position = getSafePanelPosition(
      getBoundingRect('advanced-panel-container'),
      getBoundingRect('edit-custom-tag-panel'),
    );
    this.setState({ isVisible: true, position });
  }

  componentDidUpdate() {
    const { tag } = this.props;
    if (this.state.validateError && tag.error.length) {
      this.setState({ error: tag.error, validateError: false });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Popup
        dataHook="adv-seo.editor.custom-tag.edit-panel"
        onClickOutside={() => this._onClose('off click')}
      >
        <div
          id="edit-custom-tag-panel"
          onMouseDown={(event) => event.stopPropagation()}
          style={{
            ...(this.state.position || {}),
            position: 'absolute',
            zIndex: 2000,
            opacity: this.state.isVisible ? 1 : 0,
          }}
        >
          <Composites.PopupSmallSize className={styles.panel}>
            {this._renderHeader()}
            <Divider long />
            <div className={styles.panelContent}>
              {this._renderInput()}
              <Divider long />
              <EditTagPanelFooter
                onApply={this._onApply}
                onCancel={() => this._onClose('cancel')}
                isApplyDisabled={!this._isDirty()}
                t={t}
              />
            </div>
          </Composites.PopupSmallSize>
        </div>
      </Popup>
    );
  }

  _renderHeader = () => {
    const { t, tag } = this.props;
    return (
      <PanelHeader
        dataHook="adv-seo.editor.custom-tag.edit-panel.header"
        className="light"
        noHelpBtn={true}
        onClose={() => this._onClose('X')}
      >
        <span>
          {t(
            tag.temp
              ? 'adv-seo.adv-panel.custom-tags.edit-panel.title'
              : 'adv-seo.adv-panel.custom-tags.edit-panel.title-edit',
          )}
        </span>
      </PanelHeader>
    );
  };

  _renderInput = () => {
    const { t } = this.props;
    const isError = this.state.error;

    return (
      <Composites.TextInputMultilineLabeled data-hook="adv-seo.editor.advanced-tag.edit-panel.tag-input-container">
        <InfoIcon
          text={t('adv-seo.adv-panel.custom-tags.tag-input.info-text')}
        />
        <TextLabel
          value={t('adv-seo.adv-panel.custom-tags.edit-panel.tag-content')}
          shouldTranslate={false}
        />
        <TextInputMultiline
          className={classnames(styles.customPanelTextInputContainer)}
          dataHook="adv-seo.editor.custom-tags.edit-panel.text-input"
          placeholder={t('adv-seo.adv-panel.custom-tags.input.placeholder')}
          value={this.state.value}
          shouldTranslate={false}
          onChange={this._onChange}
          tooltipIsOpen={isError}
          invalidMessage={
            isError && (
              <TooltipContent shouldTranslate={false} text={this.state.error} />
            )
          }
        />
      </Composites.TextInputMultilineLabeled>
    );
  };

  _onClose = (source) => {
    const { onClose, logBiEvent } = this.props;
    logBiEvent(PROMOTE_SEO_CLOSE_TAG_SETTINGS, {
      fieldName: 'customTags',
      closeType: source,
    });

    onClose();
  };

  _isDirty = () => {
    const { tag } = this.props;
    const { value } = this.state;
    return tag.html !== value;
  };

  _onApply = () => {
    const { onApply } = this.props;
    const { value } = this.state;

    this.setState({ validateError: true });

    onApply({
      value,
    });
  };

  _onChange = (value) => {
    const newState = { value };
    if (this.state.error.length) {
      newState.error = '';
    }
    this.setState({ ...newState });
  };
}

export default translate()(CustomTagPanel);
