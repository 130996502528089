import { IDENTIFIERS } from '../types/Identifiers';
import { CONTEXT_PROPS } from '../types/ContextProps';
import { resolvePayload } from './resolve-payload';
import { sort } from './sort';
import { applyOgImageModifications } from '../utils/build-og-image';
import { applyDefaultTags } from '../tags/defaults/apply-default-tags';
import { updateValueByIdentifier } from '../tags/values/update-by-identifier';
import { getValueByIdentifier } from '../tags/values/get-by-identifier';
import { getLink } from './utils/get-link';
import { isRobotsDirectiveExists, ROBOTS_DIRECTIVES, getRobotsTagValueWithDirective, getRobotsTagValueWithoutDirective, formatRobotsTagForRendering, } from '../tags/robots-tag/robots-tag';
import { INDEX } from '../consts/consts';
export function resolve(payload, context, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.withDefaults, withDefaults = _c === void 0 ? true : _c;
    context = context || {};
    var result = resolvePayload(payload);
    var robots = getValueByIdentifier(result.tags, IDENTIFIERS.ROBOTS);
    var isIndexableBySchema = robots !== undefined &&
        !isRobotsDirectiveExists(robots, ROBOTS_DIRECTIVES.NOINDEX);
    if (isIndexableBySchema) {
        var robotsTagValueWithoutIndex = getRobotsTagValueWithoutDirective(getValueByIdentifier(result.tags, IDENTIFIERS.ROBOTS), INDEX);
        var newRobotsTagValue = getRobotsTagValueWithoutDirective(robotsTagValueWithoutIndex, ROBOTS_DIRECTIVES.NOINDEX);
        result.tags = updateValueByIdentifier(result.tags, IDENTIFIERS.ROBOTS, newRobotsTagValue);
    }
    if (withDefaults) {
        if (!isIndexableBySchema && !isIndexable(context)) {
            var newRobotsTagValue = getRobotsTagValueWithDirective(getValueByIdentifier(result.tags, IDENTIFIERS.ROBOTS), ROBOTS_DIRECTIVES.NOINDEX);
            result.tags = updateValueByIdentifier(result.tags, IDENTIFIERS.ROBOTS, newRobotsTagValue);
        }
        result.tags = applyDefaultTags(result.tags, context)
            .concat(getLink(context, CONTEXT_PROPS.NEXT, 'next'))
            .concat(getLink(context, CONTEXT_PROPS.PREV, 'prev'));
        result.tags = formatRobotsTagForRendering(result.tags);
        result.tags = applyOgImageModifications(result.tags);
    }
    result.tags = sort(result.tags);
    return result;
}
function isIndexable(context) {
    return context[CONTEXT_PROPS.INDEX_SITE] && context[CONTEXT_PROPS.INDEX_PAGE];
}
