import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import { translate } from 'react-i18next';
import { Composites, RichText } from '@wix/wix-base-ui';

import { HireAnExpert } from '../../../core/components/icons/hire-an-expert';
import * as BI_TYPES from '../../../core/bi/action-types';
import { HIRE_AN_EXPERT_URL } from '../../../core/utils/consts';

class AdvancedPanelHireAnExpert extends PureComponent {
  static propTypes = {
    t: func.isRequired,
    logBiEvent: func.isRequired,
  };

  render() {
    const { t, logBiEvent } = this.props;
    return (
      <div
        className="seo-advanced-client-hire-an-expert"
        data-aid="seo-hire-an-expert"
      >
        <div className="seo-advanced-client-hire-an-expert-icon">
          <HireAnExpert />
        </div>
        <div>
          <Composites.RichTextLabeled>
            <RichText type="T06" shouldTranslate={false}>
              <p className="seo-advanced-client-hire-an-expert-title">
                {t('adv-seo.adv-panel.hire-an-expert.title')}
              </p>
            </RichText>
            <RichText type="T02 Light" shouldTranslate={false}>
              <p className="seo-advanced-client-hire-an-expert-description">
                {t('adv-seo.adv-panel.hire-an-expert.description')}
              </p>
              <RichText>
                <div style={{ paddingTop: '5px' }}>
                  <a
                    data-aid="seo-hire-an-expert-link"
                    onClick={() => {
                      logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
                        linkName: 'adv-seo.adv-panel.hire-an-expert',
                        linkType: 'general',
                        linkSource: 'panel',
                      });
                      window.open(HIRE_AN_EXPERT_URL, '_blank');
                    }}
                  >
                    {t('adv-seo.adv-panel.hire-an-expert.link')}
                  </a>
                </div>
              </RichText>
            </RichText>
          </Composites.RichTextLabeled>
        </div>
      </div>
    );
  }
}

export default translate()(AdvancedPanelHireAnExpert);
