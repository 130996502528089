"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStructuredData = exports.shouldRenderSchema = void 0;
var get_json_offers_1 = require("./get-json-offers");
var get_tickets_1 = require("../calculated/get-tickets");
var get_event_status_1 = require("../calculated/get-event-status");
var get_attendence_mode_1 = require("../calculated/get-attendence-mode");
var get_location_1 = require("./get-location");
var should_render_schema_1 = require("./should-render-schema");
Object.defineProperty(exports, "shouldRenderSchema", { enumerable: true, get: function () { return should_render_schema_1.shouldRenderSchema; } });
var getStructuredData = function (IDs, item) {
    var _a;
    if (item === void 0) { item = {}; }
    if (!IDs) {
        return {};
    }
    var tickets = (0, get_tickets_1.getTickets)(item);
    return _a = {},
        _a[IDs.SD_JSON_OFFERS] = (0, get_json_offers_1.getJsonOffers)(item, tickets.length),
        _a[IDs.SD_JSON_LOCATION] = (0, get_location_1.getLocation)(item),
        _a[IDs.SD_STATUS] = "https://schema.org/".concat((0, get_event_status_1.getEventStatus)(item)),
        _a[IDs.SD_ATTANDANCE_MODE] = "https://schema.org/".concat((0, get_attendence_mode_1.getAttendenceMode)(item)),
        _a;
};
exports.getStructuredData = getStructuredData;
