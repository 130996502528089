"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPrice = void 0;
var get_1 = require("../../../../utils/get");
var getPrice = function (item) {
    if (item === void 0) { item = {}; }
    var price = (0, get_1.get)(item, 'program.settings.pricing.singlePayment.price.amount');
    if (price === '') {
        return undefined;
    }
    return price;
};
exports.getPrice = getPrice;
