"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.merge = void 0;
var Identifiers_1 = require("../types/Identifiers");
var identify_1 = require("./utils/identify");
var get_simplified_tag_label_1 = require("../tags/advanced/get-simplified-tag-label");
var filter_duplicate_custom_tags_1 = require("../tags/filters/filter-duplicate-custom-tags");
var robots_tag_1 = require("../tags/robots-tag/robots-tag");
var get_by_identifier_1 = require("../tags/values/get-by-identifier");
var getIdentifier = function (tag) {
    var identifier = (0, identify_1.identify)(tag);
    if (!identifier && tag.props && !tag.custom) {
        var simplifiedTagLabel = (0, get_simplified_tag_label_1.getSimplifiedTagLabel)(tag);
        var simplifiedTagIdentifier = getSimplifiedTagIdentifier(simplifiedTagLabel);
        if (simplifiedTagIdentifier) {
            return simplifiedTagIdentifier;
        }
    }
    return identifier;
};
var getSimplifiedTagIdentifier = function (simplifiedTagLabel) {
    var simplifiedIdentifier = Object.values(Identifiers_1.IDENTIFIERS).find(function (identifier) { return simplifiedTagLabel === identifier.toLowerCase(); });
    return simplifiedIdentifier || simplifiedTagLabel;
};
var identifierToMergeLogic = (_a = {}, _a[Identifiers_1.IDENTIFIERS.ROBOTS] = robots_tag_1.mergeRobotsTags, _a);
// eslint-disable-next-line no-unused-vars
function merge(allTags, currentTags, options) {
    if (options === void 0) { options = { logError: function () { } }; }
    var tags = [];
    var uniqueTagCache = new Map();
    allTags = Array.isArray(allTags) ? allTags : [];
    currentTags = Array.isArray(currentTags) ? currentTags : [];
    var tagsWithoutDuplicateCustom = (0, filter_duplicate_custom_tags_1.filterDuplicateCustomTags)(allTags, currentTags);
    var mergedTags = []
        .concat(currentTags.some(function (tag) { return tag.allowMultiple; })
        ? tagsWithoutDuplicateCustom.filter(function (tag) { return !tag.allowMultiple; })
        : tagsWithoutDuplicateCustom)
        .concat(currentTags)
        .reverse();
    for (var _i = 0, mergedTags_1 = mergedTags; _i < mergedTags_1.length; _i++) {
        var tag = mergedTags_1[_i];
        var identifier = getIdentifier(tag);
        var allowMultiple = Boolean(tag.allowMultiple) || identifier === Identifiers_1.IDENTIFIERS.STRUCTURED_DATA;
        var isCached = uniqueTagCache.has(identifier);
        if (allowMultiple || !isCached) {
            var specificMergeLogic = identifierToMergeLogic[identifier];
            if (specificMergeLogic) {
                var matchedTags = (0, get_by_identifier_1.getTagsByIdentifier)(mergedTags, identifier);
                if (matchedTags.length) {
                    var mergedTag = matchedTags.reduce(specificMergeLogic);
                    tags.push(mergedTag);
                }
            }
            else {
                tags.push(tag);
            }
            var isGeneralIdentifier = Identifiers_1.GENERAL_IDENTIFIERS[identifier];
            if (!allowMultiple && identifier && !isGeneralIdentifier) {
                uniqueTagCache.set(identifier);
            }
        }
    }
    return tags.reverse();
}
exports.merge = merge;
