// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kSmj7O{width:240px;background-color:white;border-radius:8px;box-shadow:0 0 6px 6px rgba(22,45,61,0.18)}.OtNvyp{width:100%}.AO6V4f{display:flex;width:100%;flex-direction:column}.U0DJni{display:flex;flex-direction:row;align-items:center;justify-content:space-between}.wuySzG{padding-top:22px;padding-bottom:22px}.dd2vEU{display:flex;flex-grow:1;flex-direction:row;align-items:center;justify-content:space-between}.ZnGfHg{margin-right:9px}.u71PqD{height:150px;padding-bottom:30px}.cW2_l5{padding-top:6px}.s12jgw{width:100%;height:auto;font-size:14x;word-wrap:break-word;margin-top:0;margin-bottom:0}.DJkJ8z{padding-top:24px;padding-bottom:24px}.LOjhu2{display:flex;flex-direction:column}.CS_duB{align-self:center;padding-bottom:12px}.L3NjPC{align-self:center;padding-top:0;padding-bottom:0}.qDvt7X{color:#162d3d}.CmryX3{padding-bottom:6px}.Bi2ED_{padding:0 24px 14px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `kSmj7O`,
	"pane-header": `OtNvyp`,
	"paneHeader": `OtNvyp`,
	"panel-content": `AO6V4f`,
	"panelContent": `AO6V4f`,
	"itemHeader": `U0DJni`,
	"switch-tag-item": `wuySzG`,
	"switchTagItem": `wuySzG`,
	"switch-tag-container": `dd2vEU`,
	"switchTagContainer": `dd2vEU`,
	"switch-tag": `ZnGfHg`,
	"switchTag": `ZnGfHg`,
	"custom-panel-text-input-container": `u71PqD`,
	"customPanelTextInputContainer": `u71PqD`,
	"text-preview-container": `cW2_l5`,
	"textPreviewContainer": `cW2_l5`,
	"text-preview": `s12jgw`,
	"textPreview": `s12jgw`,
	"panel-footer-item": `DJkJ8z`,
	"panelFooterItem": `DJkJ8z`,
	"buttons-container": `LOjhu2`,
	"buttonsContainer": `LOjhu2`,
	"apply-button-container": `CS_duB`,
	"applyButtonContainer": `CS_duB`,
	"cancel-button-container": `L3NjPC`,
	"cancelButtonContainer": `L3NjPC`,
	"value-preview": `qDvt7X`,
	"valuePreview": `qDvt7X`,
	"input-container": `CmryX3`,
	"inputContainer": `CmryX3`,
	"patterns-cta-container": `Bi2ED_`,
	"patternsCtaContainer": `Bi2ED_`
};
export default ___CSS_LOADER_EXPORT___;
