"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var consts_1 = require("../../consts");
var adapter_utils_1 = require("./adapter-utils");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var ItemTypes_1 = require("../../types/ItemTypes");
exports.IDs = {
    ID: 'portfolio-collection.id',
    TITLE: 'portfolio-collection.title',
    DESCRIPTION: 'portfolio-collection.description',
    COLLECTION_PAGE_URL: 'portfolio-collection.url',
    TOTAL_PROJECTS_COUNT: 'portfolio-collection.total-projects',
    MAIN_IMAGE: 'portfolio-collection.main-image.url',
    COVER_IMAGE: 'portfolio-collection.cover-image.url',
    COVER_IMAGE_HEIGHT: 'portfolio-collection.cover-image.height',
    COVER_IMAGE_WIDTH: 'portfolio-collection.cover-image.width',
    COVER_IMAGE_ALT: 'portfolio-collection.cover-image.alt',
    DISABLE_IMAGES_SCHEMA: 'portfolio-collection.images-schema.disable',
    SD_JSON_IMAGES: 'portfolio-collection.structured-data.images',
};
var SchemaTypes = {
    COLLECTION_IMAGES: 'portfolio-collection-images',
};
var userVisible = [
    exports.IDs.TITLE,
    exports.IDs.DESCRIPTION,
    exports.IDs.COLLECTION_PAGE_URL,
    exports.IDs.TOTAL_PROJECTS_COUNT,
    exports.IDs.COVER_IMAGE,
];
var userVisibleSdOnly = [exports.IDs.SD_JSON_IMAGES];
var getSdStatus = function (item, data) {
    if (data[exports.IDs.SD_JSON_IMAGES] === '') {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getCoverImageData = function (item) {
    var _a;
    var hasImageUrl = (0, get_1.getRawValue)(item, 'collection.coverImage.imageInfo.url');
    return _a = {},
        _a[exports.IDs.COVER_IMAGE] = hasImageUrl
            ? (0, get_1.get)(item, 'collection.coverImage.imageInfo.url')
            : '',
        _a[exports.IDs.COVER_IMAGE_HEIGHT] = hasImageUrl
            ? (0, get_1.get)(item, 'collection.coverImage.imageInfo.height')
            : '',
        _a[exports.IDs.COVER_IMAGE_WIDTH] = hasImageUrl
            ? (0, get_1.get)(item, 'collection.coverImage.imageInfo.width')
            : '',
        _a[exports.IDs.COVER_IMAGE_ALT] = hasImageUrl
            ? (0, get_1.get)(item, 'collection.coverImage.imageInfo.altText')
            : '',
        _a;
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b, _c;
        var _d, _e;
        var collectionItems = (_d = (0, get_1.getRawValue)(item, 'collectionProjects')) !== null && _d !== void 0 ? _d : [];
        var coverImageData = getCoverImageData(item);
        var data = __assign(__assign(__assign((_a = {}, _a[exports.IDs.ID] = (0, get_1.get)(item, 'collection.id'), _a[exports.IDs.TITLE] = (0, get_1.get)(item, 'collection.title'), _a[exports.IDs.DESCRIPTION] = (0, get_1.get)(item, 'collection.description'), _a[exports.IDs.TOTAL_PROJECTS_COUNT] = collectionItems.length, _a[exports.IDs.COLLECTION_PAGE_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.PORTFOLIO_PROJECTS, item, (0, get_1.get)(item, 'collection.url.url')), _a), coverImageData), (_b = {}, _b[exports.IDs.MAIN_IMAGE] = (_e = (0, get_1.get)(item, 'collection.coverImage.imageInfo.url')) !== null && _e !== void 0 ? _e : '', _b)), (0, adapter_utils_1.getStructuredData)(exports.IDs, collectionItems, coverImageData));
        var imagesSdStatus = getSdStatus(item, data, SchemaTypes.COLLECTION_IMAGES) !==
            consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_c = {}, _c[exports.IDs.DISABLE_IMAGES_SCHEMA] = imagesSdStatus, _c));
    },
    getSdStatus: getSdStatus,
    getFullUrl: function (itemData) { return (0, get_1.get)(itemData, 'collection.url.url'); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData.collection.url.url = url;
        return itemData;
    },
    getSlug: function (itemData) { return (0, get_1.get)(itemData, 'collection.slug'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisibleSdOnly); },
};
