var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildOgMetaRefs, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-blog-category';
import { siteIDs } from '../../adapters/utils';
import { LINK_TAG_TYPES } from '../../types/LinkTagTypes';
import { getRssTitle } from '../utils';
var IDs = adapter.IDs;
export var BLOG_CATEGORY = {
    tags: __spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}}")),
        buildStandardMeta('description', "{{".concat(IDs.DESCRIPTION, "}}"))
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:url', "{{".concat(IDs.PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildStandardMeta('robots', 'index'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.PAGE_URL, "}}") }),
        buildLink({ rel: 'prev', href: "{{".concat(siteIDs.PREV, "}}") }),
        buildLink({ rel: 'next', href: "{{".concat(siteIDs.NEXT, "}}") }),
        buildLink({
            rel: 'alternate',
            type: LINK_TAG_TYPES.RSS,
            href: "{{".concat(IDs.BLOG_FEED_ROUTE, "}}"),
            title: getRssTitle("{{".concat(siteIDs.SITE_NAME, "}}")),
        })
    ], false), buildTwitterMetaRefs(), true),
};
