import { get } from '../../utils/get';
import { getImageObject, getBasicVideosObject, } from '../../adapters/structure-data-utils';
var PREFIX = 'media/';
export var getStructuredData = function (IDs, projectItems, coverImageData) {
    var _a;
    if (!IDs) {
        return {};
    }
    var imagesData = [];
    var videosData = [];
    projectItems === null || projectItems === void 0 ? void 0 : projectItems.forEach(function (projectItem) {
        var type = get(projectItem, 'type');
        switch (type) {
            case 'IMAGE':
                imagesData.push({
                    url: get(projectItem, 'image.imageInfo.url'),
                    width: get(projectItem, 'image.imageInfo.width'),
                    height: get(projectItem, 'image.imageInfo.height'),
                    alt: get(projectItem, 'image.imageInfo.altText'),
                });
                break;
            case 'VIDEO':
                var video = {
                    thumbnailUrl: get(projectItem, 'video.videoInfo.posters.0.url'),
                    name: get(projectItem, 'video.videoInfo.filename'),
                    description: get(projectItem, 'description'),
                    uploadDate: get(projectItem, 'createdDate'),
                    duration: get(projectItem, 'video.duration'),
                    contentUrl: get(projectItem, 'video.videoInfo.url'),
                };
                if (video.thumbnailUrl &&
                    video.name &&
                    video.description &&
                    video.uploadDate) {
                    if (video.thumbnailUrl.startsWith(PREFIX)) {
                        video.thumbnailUrl = video.thumbnailUrl.slice(PREFIX.length);
                    }
                    videosData.push(video);
                }
                break;
            default:
                break;
        }
    });
    imagesData.push({
        url: coverImageData[IDs.COVER_IMAGE],
        width: coverImageData[IDs.COVER_IMAGE_WIDTH],
        height: coverImageData[IDs.COVER_IMAGE_HEIGHT],
        alt: coverImageData[IDs.COVER_IMAGE_ALT],
    });
    var _b = getImageObject('images', imagesData, false).images, images = _b === void 0 ? '' : _b;
    var _c = getBasicVideosObject(videosData).videos, videos = _c === void 0 ? '' : _c;
    return _a = {},
        _a[IDs.SD_JSON_IMAGES] = images,
        _a[IDs.SD_JSON_VIDEOS] = videos,
        _a;
};
