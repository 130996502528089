import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Button, Composites } from '@wix/wix-base-ui';
import styles from './edit-tag-panel.scss';
import { func, bool } from 'prop-types';
import classnames from 'classnames';

class EditTagPanelFooter extends Component {
  static propTypes = {
    onApply: func.isRequired,
    onCancel: func.isRequired,
    isApplyDisabled: bool,
    t: func.isRequired,
  };

  render() {
    const { onApply, onCancel, isApplyDisabled, t } = this.props;
    return (
      <div className={classnames(styles.panelFooterItem)}>
        <div className={classnames(styles.buttonsContainer)}>
          <div
            className={classnames(styles.applyButtonContainer)}
            onMouseDown={onApply}
            data-hook="adv-seo.editor.tag-edit-panel.footer.apply-button-container"
          >
            <Button
              disabled={isApplyDisabled}
              dataHook="adv-seo.editor.tag-edit-panel.footer.apply-button"
              className="btn-md"
            >
              <span>{t('adv-seo.adv-panel.edit-panel.apply-button')}</span>
            </Button>
          </div>
          <div onMouseDown={onCancel}>
            <Composites.ButtonLarge className={styles.cancelButtonContainer}>
              <Button
                className="btn-text"
                dataHook="adv-seo.editor.tag-edit-panel.footer.cancel-button"
              >
                {t('adv-seo.adv-panel.edit-panel.cancel-button')}
              </Button>
            </Composites.ButtonLarge>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(EditTagPanelFooter);
