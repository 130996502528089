import React from 'react';
import { number, string } from 'prop-types';

export function Warning({ color, width, height }) {
  return (
    <svg width={width} height={height}>
      <path
        fill={color}
        fillRule="nonzero"
        d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm.729-4.275a.94.94 0 0 0 .273-.727v-4a.94.94 0 0 0-1-1 .94.94 0 0 0-1 1v4a.94.94 0 0 0 1 1 .94.94 0 0 0 .727-.273zm0-8a.94.94 0 0 0 .273-.727.94.94 0 0 0-1-1 .94.94 0 0 0-1 1 .94.94 0 0 0 1 1 .94.94 0 0 0 .727-.273z"
      />
    </svg>
  );
}

Warning.propTypes = {
  width: number,
  height: number,
  color: string,
};

Warning.defaultProps = {
  width: 18,
  height: 18,
  color: '#ffc233',
};
