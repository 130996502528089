"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var adapter_utils_1 = require("./adapter-utils");
var get_1 = require("../../utils/get");
var get_url_parts_1 = require("../../utils/get-url-parts");
var build_og_image_preview_1 = require("../../utils/build-og-image-preview");
var consts_1 = require("../../consts");
var constants_1 = require("./constants");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
exports.IDs = {
    ID: 'service.id',
    CALCULATED_SERVICE_URL: 'service.url',
    TITLE: 'service.info.name',
    DESCRIPTION: 'service.info.description',
    STATUS: 'service.status',
    IMAGE_HEIGHT: 'service.mainImage.height',
    IMAGE_ID: 'service.mainImage.id',
    IMAGE_WIDTH: 'service.mainImage.width',
    MAIN_IMAGE: 'service.mainImage.url',
    IMAGE_ALT: 'service.mainImage.url',
    CATEGORY_NAME: 'service.category.name',
    PRICE_AMOUNT: 'service.price.amount',
    CURRENCY: 'service.price.currency',
    START_DATE: 'service.start-date',
    END_DATE: 'service.end-date',
    PRICE_TEXTUAL: 'service.price.textual',
    SESSION_TYPE: 'service.type',
    FIRST_SESSION: 'service.session.first',
    LAST_SESSION: 'service.session.last',
    SD_DISABLE_SCHEMA: 'service.disable-schema',
    AVAILABILITY: 'service.availability',
    INSTRUCTOR_NAME: 'service.instructor-name',
    INSTRUCTOR_IMAGE: 'service.instructor-image',
    SD_JSON_PROVIDER: 'service.structured-data.provider',
    SD_JSON_IMAGES: 'service.structured-data.images',
    SD_JSON_OFFERS: 'service.structured-data.offers',
    IS_COURSE_SESSION_TYPE: 'service.is-course-session-type',
    MEDIA_ITEMS: 'service.media',
    FULL_DESCRIPTION: 'service.full-description',
};
var userVisible = [
    exports.IDs.TITLE,
    exports.IDs.DESCRIPTION,
    exports.IDs.SESSION_TYPE,
    exports.IDs.CALCULATED_SERVICE_URL,
    exports.IDs.PRICE_AMOUNT,
    exports.IDs.CURRENCY,
    exports.IDs.MAIN_IMAGE,
];
var userVisibleSdOnly = [
    exports.IDs.IMAGE_HEIGHT,
    exports.IDs.IMAGE_WIDTH,
    exports.IDs.END_DATE,
    exports.IDs.AVAILABILITY,
    exports.IDs.INSTRUCTOR_NAME,
    exports.IDs.INSTRUCTOR_IMAGE,
    exports.IDs.SD_JSON_PROVIDER,
    exports.IDs.SD_JSON_IMAGES,
    exports.IDs.SD_JSON_OFFERS,
];
var requiredSchemaVariables = function (item) {
    if (item === void 0) { item = {}; }
    var serviceResponse = item.serviceResponse;
    return (0, adapter_utils_1.getSdType)(serviceResponse) === constants_1.SESSION_TYPES.COURSE
        ? [exports.IDs.TITLE, exports.IDs.DESCRIPTION]
        : [];
};
var SchemaTypes = {
    BOOKINGS_SERVICE: 'bookings-service-preset',
};
var getSdStatus = function (item, itemData) {
    var serviceResponse = item.serviceResponse;
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables(item), serviceResponse, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    if (!(0, get_1.get)(serviceResponse, 'service.info.name') ||
        ((0, get_1.get)(serviceResponse, 'service.status') || 'created').toLowerCase() !==
            'created') {
        return consts_1.SD_STATUS.DISABLED_BY_VERTICAL_ITEM_DATA;
    }
    return consts_1.SD_STATUS.ENABLED;
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        var serviceResponse = item.serviceResponse;
        var sessionType = (0, adapter_utils_1.getSdType)(serviceResponse);
        var imageData = (0, get_1.getRawValue)(serviceResponse, 'service.info.images.0') || {};
        var mediaItems = (0, adapter_utils_1.getServiceMediaItems)(serviceResponse) || [];
        var data = __assign((_a = {}, _a[exports.IDs.ID] = (0, get_1.get)(serviceResponse, 'service.id'), _a[exports.IDs.CALCULATED_SERVICE_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.BOOKINGS_SERVICE, item, (0, adapter_utils_1.getServiceUrl)(serviceResponse)), _a[exports.IDs.TITLE] = (0, get_1.get)(serviceResponse, 'service.info.name'), _a[exports.IDs.DESCRIPTION] = (0, adapter_utils_1.getBookingDescription)(serviceResponse), _a[exports.IDs.STATUS] = (0, get_1.get)(serviceResponse, 'schedules.0.status') || 'CREATED', _a[exports.IDs.IMAGE_HEIGHT] = imageData.height || '', _a[exports.IDs.IMAGE_WIDTH] = imageData.width || '', _a[exports.IDs.MAIN_IMAGE] = (0, build_og_image_preview_1.buildOgImagePreviewUrl)(imageData) || '', _a[exports.IDs.CATEGORY_NAME] = (0, get_1.get)(serviceResponse, 'category.name'), _a[exports.IDs.PRICE_AMOUNT] = (0, get_1.get)(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.amount'), _a[exports.IDs.CURRENCY] = (0, get_1.get)(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.currency'), _a[exports.IDs.START_DATE] = (0, get_1.get)(serviceResponse, 'schedules.0.availability.start'), _a[exports.IDs.END_DATE] = (0, get_1.get)(serviceResponse, 'schedules.0.availability.end'), _a[exports.IDs.PRICE_TEXTUAL] = (0, get_1.get)(serviceResponse, 'schedules.0.rate.priceText'), _a[exports.IDs.SESSION_TYPE] = sessionType, _a[exports.IDs.FIRST_SESSION] = (0, get_1.get)(serviceResponse, 'schedules.0.firstSessionStart'), _a[exports.IDs.LAST_SESSION] = (0, get_1.get)(serviceResponse, 'schedules.0.lastSessionEnd'), _a[exports.IDs.IS_COURSE_SESSION_TYPE] = sessionType === constants_1.SESSION_TYPES.COURSE, _a[exports.IDs.MEDIA_ITEMS] = mediaItems, _a[exports.IDs.FULL_DESCRIPTION] = (0, adapter_utils_1.getBookingDescription)(serviceResponse), _a), (0, adapter_utils_1.getStructuredData)(exports.IDs, item));
        var sdStatus = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { return (0, get_1.get)(item, 'serviceResponse.slugs.0.name') || ''; },
    getFullUrl: function (item) { return (0, adapter_utils_1.getServiceUrl)((0, get_1.getRawValue)(item, 'serviceResponse')); },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        var _a = (0, get_url_parts_1.getUrlParts)(url), base = _a.base, path = _a.path;
        item.serviceResponse.urls.servicePageUrl.base = base;
        item.serviceResponse.urls.servicePageUrl.path = path;
        return item;
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        var oldSlug = (0, get_1.get)(updatedItemData, 'serviceResponse.slugs.0.name');
        if (oldSlug !== '') {
            updatedItemData.serviceResponse.slugs[0].name = slugValue;
        }
        return updatedItemData;
    },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisibleSdOnly); },
    getDefaultSchemaType: function () {
        return SchemaTypes.BOOKINGS_SERVICE;
    },
};
