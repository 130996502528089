"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var get_1 = require("../../utils/get");
var ContextProps_1 = require("../../types/ContextProps");
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
var IDs = {
    ID: 'item.id',
    SITE_NAME: 'site.name',
    PAGE_NAME: 'page.name',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    GALLERY_TYPE: 'item.type',
    TITLE: 'item.title',
    DESCRIPTION: 'item.description',
    PAGE_URL: 'page.url',
    FULLSCREEN_URL: 'page.fullScreenUrl',
    MAIN_IMAGE: 'item.image.url',
    IMAGE_WIDTH: 'item.image.width',
    IMAGE_HEIGHT: 'item.image.height',
    VIDEO_URL: 'item.videoUrl',
    THUMBNAIL_URL: 'item.thumbnail.url',
    THUMBNAIL_WIDTH: 'item.thumbnail.width',
    THUMBNAIL_HEIGHT: 'item.thumbnail.height',
};
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.MAIN_IMAGE,
    IDs.PAGE_NAME,
    IDs.GALLERY_TYPE,
    IDs.PAGE_URL,
    IDs.FULLSCREEN_URL,
    IDs.SITE_NAME,
];
exports.default = {
    IDs: IDs,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.ID] = (0, get_1.get)(item, 'item.id'),
            _a[IDs.SITE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.PAGE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PAGE_NAME)),
            _a[IDs.INDEX_SITE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_SITE)),
            _a[IDs.INDEX_PAGE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_PAGE)),
            _a[IDs.GALLERY_TYPE] = ['image', 'video'].includes((0, get_1.get)(item, 'item.type'))
                ? (0, get_1.get)(item, 'item.type')
                : 'website',
            _a[IDs.TITLE] = (0, get_1.get)(item, 'item.title'),
            _a[IDs.DESCRIPTION] = (0, get_1.get)(item, 'item.description'),
            _a[IDs.PAGE_URL] = (0, get_1.get)(item, 'item.page_url'),
            _a[IDs.FULLSCREEN_URL] = (0, get_1.get)(item, 'item.fullscreen_url'),
            _a[IDs.MAIN_IMAGE] = (0, get_1.get)(item, 'item.image.url'),
            _a[IDs.IMAGE_WIDTH] = (0, get_1.get)(item, 'item.image.width'),
            _a[IDs.IMAGE_HEIGHT] = (0, get_1.get)(item, 'item.image.height'),
            _a[IDs.VIDEO_URL] = (0, get_1.get)(item, 'item.video_url'),
            _a[IDs.THUMBNAIL_URL] = (0, get_1.get)(item, 'item.thumbnail.url'),
            _a[IDs.THUMBNAIL_WIDTH] = (0, get_1.get)(item, 'item.thumbnail.width'),
            _a[IDs.THUMBNAIL_HEIGHT] = (0, get_1.get)(item, 'item.thumbnail.height'),
            _a;
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return (0, utils_1.getKeys)(IDs, userVisible); },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getSdKeys: function () { return []; },
};
