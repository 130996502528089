"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolvePayload = void 0;
var is_empty_1 = require("../utils/is-empty");
var merge_1 = require("./merge");
function resolvePayload(payload, methodOptions) {
    if (methodOptions === void 0) { methodOptions = { logError: function () { } }; }
    var _a = (Array.isArray(payload) ? payload : []).reduce(function (acc, curr) {
        acc.tags = (0, merge_1.merge)(acc.tags, curr === null || curr === void 0 ? void 0 : curr.tags, methodOptions);
        acc.settings = __assign(__assign({}, acc.settings), curr === null || curr === void 0 ? void 0 : curr.settings);
        acc.options = __assign(__assign({}, acc.options), curr === null || curr === void 0 ? void 0 : curr.options);
        return acc;
    }, { tags: [], settings: {}, options: {} }), tags = _a.tags, settings = _a.settings, options = _a.options;
    return __assign(__assign({ tags: tags }, (!(0, is_empty_1.isEmpty)(settings) ? { settings: settings } : {})), (Object.keys(options).length ? { options: options } : {}));
}
exports.resolvePayload = resolvePayload;
