import React, { PureComponent } from 'react';
import { func } from 'prop-types';
import { translate, Interpolate } from 'react-i18next';
import { Composites, RichText } from '@wix/wix-base-ui';

import * as BI_TYPES from '../../../core/bi/action-types';

class AdvancedPanelMediaManager extends PureComponent {
  static propTypes = {
    onOpenMediaManager: func.isRequired,
    logBiEvent: func.isRequired,
    t: func.isRequired,
  };

  render() {
    const { t } = this.props;
    const siteFiles = (
      <a onClick={this._onOpenMediaManager} data-aid="seo-media-manager-open">
        {t('adv-seo.adv-panel.image-url.site-files')}
      </a>
    );
    return (
      <Composites.RichText automationId="seo-media-manager">
        <RichText>
          <p className="seo-advanced-client-media-manager-description">
            <Interpolate
              i18nKey="adv-seo.adv-panel.image-url"
              siteFiles={siteFiles}
            />
          </p>
        </RichText>
      </Composites.RichText>
    );
  }

  _onOpenMediaManager = () => {
    const { logBiEvent, onOpenMediaManager } = this.props;
    onOpenMediaManager().catch(() => {});
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.adv-panel.image-url.site-files',
      linkType: 'general',
      linkSource: 'panel',
    });
  };
}

export default translate()(AdvancedPanelMediaManager);
