"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.skipMultilingualTagsForTpaPageId = exports.isMultilingualType = void 0;
var ItemTypes_1 = require("./ItemTypes");
// Item types that integrated with multilingual
var MULTILINGUAL_TYPES = [
    ItemTypes_1.ITEM_TYPES.BLOG_POST,
    ItemTypes_1.ITEM_TYPES.BLOG_CATEGORY,
    ItemTypes_1.ITEM_TYPES.BLOG_ARCHIVE,
    ItemTypes_1.ITEM_TYPES.BLOG_TAGS,
    ItemTypes_1.ITEM_TYPES.BLOG_HASHTAGS,
    ItemTypes_1.ITEM_TYPES.STORES_PRODUCT,
    ItemTypes_1.ITEM_TYPES.STORES_CATEGORY,
    ItemTypes_1.ITEM_TYPES.CHALLENGES_PAGE,
    ItemTypes_1.ITEM_TYPES.EVENTS_PAGE,
    ItemTypes_1.ITEM_TYPES.STATIC_PAGE,
    ItemTypes_1.ITEM_TYPES.STATIC_PAGE_V2,
    ItemTypes_1.ITEM_TYPES.MEMBERS_AREA_PROFILE,
    ItemTypes_1.ITEM_TYPES.RESTAURANTS_MENU_PAGE,
    ItemTypes_1.ITEM_TYPES.WIX_DATA_PAGE_ITEM,
];
var isMultilingualType = function (type) { return MULTILINGUAL_TYPES.includes(type); };
exports.isMultilingualType = isMultilingualType;
var NON_MULTILINGUAL_STATIC_PAGES = [
    'menu', // Resturants menus
];
var EXCLUDE_MULTILINGUAL_TAGS = [
    ItemTypes_1.ITEM_TYPES.BLOG_ARCHIVE,
    ItemTypes_1.ITEM_TYPES.BLOG_TAGS,
    ItemTypes_1.ITEM_TYPES.BLOG_HASHTAGS,
];
var skipMultilingualTagsForTpaPageId = function (type, tpaPageId) {
    return EXCLUDE_MULTILINGUAL_TAGS.includes(type) ||
        (type === ItemTypes_1.ITEM_TYPES.STATIC_PAGE_V2 &&
            NON_MULTILINGUAL_STATIC_PAGES.includes(tpaPageId));
};
exports.skipMultilingualTagsForTpaPageId = skipMultilingualTagsForTpaPageId;
