import { CONTEXT_PROPS } from '../types/ContextProps';
import { isMultilingualType } from '../types/MultilingualTypes';
import { getRawValue } from './get';
var getPrimaryLanguage = function (languages) {
    if (languages === void 0) { languages = []; }
    return languages === null || languages === void 0 ? void 0 : languages.filter(function (lang) { return lang === null || lang === void 0 ? void 0 : lang.isPrimaryLanguage; });
};
export var resolveItemTypeMultilingualUrl = function (itemType, item, url) {
    var _a;
    if (url === void 0) { url = ''; }
    var context = getRawValue(item, 'context');
    if (getRawValue(context, CONTEXT_PROPS.CURR_LANG_IS_ORIGINAL)) {
        return url;
    }
    if (!isMultilingualType(itemType)) {
        var primaryLanguage = getPrimaryLanguage(getRawValue(context, CONTEXT_PROPS.SITE_LANGUAGES));
        return ((_a = primaryLanguage[0]) === null || _a === void 0 ? void 0 : _a.url) || url;
    }
    // Check if current url include param
    if (getRawValue(context, CONTEXT_PROPS.CURR_LANG_RESOLUTION_METHOD) ===
        'QueryParam') {
        try {
            var langCode = getRawValue(context, CONTEXT_PROPS.CURR_LANG_CODE);
            var urlObject = new URL(url);
            urlObject.searchParams.set('lang', langCode);
            return decodeURI(urlObject.toString());
        }
        catch (_b) {
            return url;
        }
    }
    else {
        return url;
    }
};
