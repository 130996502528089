import { get } from '../../../utils/get';
var EVENT_TYPES = {
    PHYSICAL: 'VENUE',
    ONLINE: 'ONLINE',
};
export var ATTENDENCE_MODE = {
    MIXED: 'MixedEventAttendanceMode',
    ONLINE: 'OnlineEventAttendanceMode',
    OFFLINE: 'OfflineEventAttendanceMode',
};
export var getIsVenue = function (item) {
    return get(item, 'event.location.type') === EVENT_TYPES.PHYSICAL;
};
export var getAttendenceMode = function (item) {
    if (item === void 0) { item = {}; }
    if (!item.event) {
        return;
    }
    var isVenue = getIsVenue(item);
    var isOnlineEnabled = !!get(item, 'event.onlineConferencing.config.enabled');
    var isOnlineOnly = get(item, 'event.location.type') === EVENT_TYPES.ONLINE;
    return isVenue && isOnlineEnabled
        ? ATTENDENCE_MODE.MIXED
        : isOnlineOnly
            ? ATTENDENCE_MODE.ONLINE
            : ATTENDENCE_MODE.OFFLINE;
};
