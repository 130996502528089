import React, { useCallback } from 'react';
import { RichText, NumericInput, Tooltip } from '@wix/wix-base-ui';
import {
  ROBOTS_DIRECTIVES,
  MAX_SNIPPET_DEFAULT_VALUE,
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
} from '@wix/advanced-seo-utils';
import {
  useDirectiveDisplayValue,
  useIsDirectiveExists,
  useUpdateDirectiveWithNumericRange,
  useForcedRender,
  getValueFromEvent,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

const MIN_VALUE = -1;
const MAX_VALUE = 999;

export const checkboxDataHook = 'max-snippet-directive-checkbox';

export const inputDataHook = 'max-snippet-directive-input';

export const MaxSnippetDirective = translate()(({ t }) => {
  const isMaxSnippetDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.MAX_SNIPPET,
  );
  const maxSnippetDisplayValue = useDirectiveDisplayValue(
    ROBOTS_DIRECTIVES.MAX_SNIPPET,
    MAX_SNIPPET_DEFAULT_VALUE,
  );
  const onUpdateMaxSnippet = useUpdateDirectiveWithNumericRange(
    ROBOTS_DIRECTIVES.MAX_SNIPPET,
    MAX_SNIPPET_DEFAULT_VALUE,
    MIN_VALUE,
    MAX_VALUE,
  );

  const onChangeMaxSnippetCheckbox = useCallback(
    (isEnabled) => {
      onUpdateMaxSnippet(
        isEnabled
          ? MAX_SNIPPET_DEFAULT_VALUE
          : REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
      );
    },
    [onUpdateMaxSnippet],
  );

  const { applyForcedRender, triggerForcedRender } = useForcedRender();

  const onMaxSnippetBlur = useCallback(
    (event) => {
      onUpdateMaxSnippet(getValueFromEvent(event));
      triggerForcedRender();
    },
    [onUpdateMaxSnippet, triggerForcedRender],
  );

  return (
    <>
      <CheckboxWithInfoIcon
        dataHook={checkboxDataHook}
        isChecked={isMaxSnippetDirectiveExists}
        onChange={onChangeMaxSnippetCheckbox}
        label={t('adv-seo.common.robots-tag.directives.max-snippet.title')}
        infoText={t('adv-seo.common.robots-tag.directives.max-snippet.tooltip')}
        infoTextBiName="adv-seo.common.robots-tag.directives.max-snippet.tooltip"
        helpCenterId={t(
          'adv-seo.common.robots-tag.directives.max-snippet.tooltip.editor-help-center-id',
        )}
      />
      {isMaxSnippetDirectiveExists && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '30px',
          }}
        >
          <div style={{ marginTop: '9px' }}>
            <RichText type="T02">
              {t('adv-seo.common.robots-tag.directives.max-snippet.value-type')}
            </RichText>
          </div>
          {applyForcedRender(
            <Tooltip
              content={t(
                'adv-seo.common.robots-tag.directives.max-snippet.input-tooltip',
              )}
              disabled={maxSnippetDisplayValue !== MAX_SNIPPET_DEFAULT_VALUE}
              shouldTranslate={false}
            >
              <NumericInput
                dataHook={inputDataHook}
                placeholder=""
                disabled={!isMaxSnippetDirectiveExists}
                min={Number.NEGATIVE_INFINITY}
                max={Number.POSITIVE_INFINITY}
                onBlur={onMaxSnippetBlur}
                shouldTranslate={false}
                value={
                  maxSnippetDisplayValue === ''
                    ? undefined
                    : +maxSnippetDisplayValue
                }
              />
            </Tooltip>,
          )}
        </div>
      )}
    </>
  );
});

MaxSnippetDirective.propTypes = {};
