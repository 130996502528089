import { range } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import {
  DropDownIllustrationOption,
  Text,
  Tooltip,
  RichText,
  TooltipProps,
} from '@wix/wix-base-ui';
import {
  getTitle,
  SiteStructureGraph,
  PossibleParentStatusMapping,
  POSSIBLE_PARENT_STATUS,
  PossibleParentStatus,
  getSortedChildren,
  PageId,
} from '@wix/seo-page-hierarchy-library';

import { Page } from '@wix/wix-base-ui/icons';

import { DividerIcon } from './page-drop-down-option-divider-icon';

import styles from './parent-page-drop-down.scss';
import { usePageHierarchyContext } from '../../../hooks/page-hierarchy';
import { BI_TYPES } from '../../../../../core/bi';

const assertNever = (input: never) => {
  throw new Error(`should be never - ${input}`);
};

const TOOLTIP_ANIMATION_DURATION = 1000;

const TOOLTIP_OPEN_DELAY = 500;

const tooltipInteractionProps: Partial<TooltipProps> = {
  shouldTranslate: false,
  animationDuration: TOOLTIP_ANIMATION_DURATION,
  openDelay: TOOLTIP_OPEN_DELAY,
  marginRight: 400,
};

const dropDownLevelStyle = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '12px',
  marginRight: '6px',
};

const DropdownLevel = () => (
  <>
    <div style={dropDownLevelStyle}>
      <DividerIcon />
    </div>
  </>
);

const createPageDropDownOptionsRecursive = (
  t: any,
  pageId: PageId,
  siteStructureGraph: SiteStructureGraph,
  nodeId: PageId,
  possibleParentStatuses: PossibleParentStatusMapping,
  level: number,
  selectedParentPageId: PageId,
  logBiEvent: any,
  openHelpCenter: any,
): React.ReactNode[] => {
  const possibleParentStatus = possibleParentStatuses.get(nodeId);
  const isInvalid = possibleParentStatus !== POSSIBLE_PARENT_STATUS.VALID;

  return [
    <DropDownIllustrationOption
      className={styles.parentPageDropDownContainer}
      value={nodeId}
      label=""
      disabled={isInvalid}
      key={nodeId}
    >
      <SingleDropDownOption
        level={level}
        possibleParentStatus={possibleParentStatus}
        t={t}
        nodeId={nodeId}
        selectedParentPageId={selectedParentPageId}
        logBiEvent={logBiEvent}
        openHelpCenter={openHelpCenter}
      />
    </DropDownIllustrationOption>,
    ...getSortedChildren(
      siteStructureGraph,
      possibleParentStatuses,
      nodeId,
      pageId,
    ).flatMap((childNodeId) =>
      createPageDropDownOptionsRecursive(
        t,
        pageId,
        siteStructureGraph,
        childNodeId,
        possibleParentStatuses,
        level + 1,
        selectedParentPageId,
        logBiEvent,
        openHelpCenter,
      ),
    ),
  ];
};

const TOP_LEVEL = 0;

export const createDropDownOptions = (
  t: any,
  pageId: PageId,
  homePageChildren: PageId[],
  siteStructureGraph: SiteStructureGraph,
  possibleParentStatuses: PossibleParentStatusMapping,
  selectedParentPageId: PageId,
  logBiEvent: any,
  openHelpCenter: any,
): React.ReactNode[] =>
  homePageChildren.flatMap((nodeId) =>
    createPageDropDownOptionsRecursive(
      t,
      pageId,
      siteStructureGraph,
      nodeId,
      possibleParentStatuses,
      TOP_LEVEL,
      selectedParentPageId,
      logBiEvent,
      openHelpCenter,
    ),
  );

type SingleDropDownOptionProps = {
  level: number;
  possibleParentStatus: PossibleParentStatus;
  t: any;
  nodeId: string;
  selectedParentPageId: string;
  logBiEvent: any;
  openHelpCenter: any;
};

const useTooltipContent = (
  nodeId: PageId,
  possibleParentStatus: PossibleParentStatus,
  t: any,
  logBiEvent: any,
  openHelpCenter: any,
): React.ReactNode => {
  const { siteStructureGraph } = usePageHierarchyContext();

  const onInvalidDueToTpaSectionPage = useCallback(() => {
    window.open(
      t(
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-tpa-section-page.url',
      ),
      '_blank',
    );
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName:
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-tpa-section-page',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  }, [logBiEvent, t]);

  const onInvalidDueToDepthLinkClick = useCallback(() => {
    window.open(
      t(
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-depth.url',
      ),
      '_blank',
    );
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName:
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-depth',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  }, [logBiEvent, t]);

  return useMemo(() => {
    if (possibleParentStatus === POSSIBLE_PARENT_STATUS.INVALID_DUE_TO_SELF) {
      return t(
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-self',
      );
    }

    if (
      possibleParentStatus === POSSIBLE_PARENT_STATUS.INVALID_DUE_TO_DESCENDANT
    ) {
      return t(
        'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-descendant',
        { pageName: getTitle(siteStructureGraph, nodeId) },
      );
    }

    if (
      possibleParentStatus ===
      POSSIBLE_PARENT_STATUS.INVALID_DUE_TO_TPA_SECTION_PAGE
    ) {
      return (
        <RichText type="T02" shouldTranslate={false}>
          <Trans
            i18nKey="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-tpa-section-page"
            values={{ pageName: getTitle(siteStructureGraph, nodeId) }}
            components={[
              <a target="_blank" onClick={onInvalidDueToTpaSectionPage}>
                Learn more
              </a>,
              <br />,
            ]}
          />
        </RichText>
      );
    }

    if (possibleParentStatus === POSSIBLE_PARENT_STATUS.INVALID_DUE_TO_DEPTH) {
      return (
        <RichText type="T02" shouldTranslate={false}>
          <Trans
            i18nKey="adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.invalid-due-to-depth"
            values={{ pageName: getTitle(siteStructureGraph, nodeId) }}
            components={[
              <a target="_blank" onClick={onInvalidDueToDepthLinkClick}>
                Learn more
              </a>,
              <br />,
            ]}
          />
        </RichText>
      );
    }

    if (possibleParentStatus === POSSIBLE_PARENT_STATUS.VALID) {
      return undefined;
    }

    assertNever(possibleParentStatus);
  }, [
    possibleParentStatus,
    siteStructureGraph,
    onInvalidDueToDepthLinkClick,
    onInvalidDueToTpaSectionPage,
    t,
    nodeId,
  ]);
};

const SingleDropDownOption: React.FC<SingleDropDownOptionProps> = ({
  level,
  possibleParentStatus,
  t,
  nodeId,
  selectedParentPageId,
  logBiEvent,
  openHelpCenter,
}) => {
  const { siteStructureGraph } = usePageHierarchyContext();
  const tooltipContent = useTooltipContent(
    nodeId,
    possibleParentStatus,
    t,
    logBiEvent,
    openHelpCenter,
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
      {range(level).map((currentLevel) => (
        <DropdownLevel key={`${nodeId}-${currentLevel}`} />
      ))}
      <Page />
      <div style={{ width: '3px' }} />
      {possibleParentStatus === POSSIBLE_PARENT_STATUS.VALID ? (
        <Text shouldTranslate={false} light={selectedParentPageId === nodeId}>
          {getTitle(siteStructureGraph, nodeId)}
        </Text>
      ) : possibleParentStatus ===
        POSSIBLE_PARENT_STATUS.INVALID_DUE_TO_SELF ? (
        <Tooltip {...tooltipInteractionProps} content={tooltipContent}>
          <Text skin="placeholder" shouldTranslate={false}>
            {t(
              'adv-seo.basic-panel.page-url.hierarchy.parent-dropdown.dropdown-item.current-page',
              { pageName: getTitle(siteStructureGraph, nodeId) },
            )}
          </Text>
        </Tooltip>
      ) : (
        <Tooltip {...tooltipInteractionProps} content={tooltipContent}>
          <Text skin="placeholder" shouldTranslate={false}>
            {getTitle(siteStructureGraph, nodeId)}
          </Text>
        </Tooltip>
      )}
    </div>
  );
};
