"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDynamicKeysData = void 0;
var consts_1 = require("./consts");
var getDynamicKeysData = function (collections) {
    var _a;
    if (!collections || !collections.length) {
        return {};
    }
    var dynamicPagesFields = {};
    var mainCollection = collections === null || collections === void 0 ? void 0 : collections.find(function (collection) { return collection.primary; });
    (_a = mainCollection.fields) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var type = _a.type;
        return type &&
            [consts_1.FIELD_TYPE.TEXT, consts_1.FIELD_TYPE.NUMBER, consts_1.FIELD_TYPE.IMAGE].includes(type);
    }).forEach(function (_a) {
        var key = _a.key, value = _a.value;
        var fieldValue = value;
        if (typeof value === 'object') {
            fieldValue = value === null || value === void 0 ? void 0 : value.url;
        }
        dynamicPagesFields["wix-data-page-item.".concat(mainCollection.id, ".").concat(key)] =
            fieldValue;
    });
    return dynamicPagesFields;
};
exports.getDynamicKeysData = getDynamicKeysData;
