import { getJsonOffers } from './get-json-offers';
import { getTickets } from '../calculated/get-tickets';
import { getEventStatus } from '../calculated/get-event-status';
import { getAttendenceMode } from '../calculated/get-attendence-mode';
import { getLocation } from './get-location';
export { shouldRenderSchema } from './should-render-schema';
export var getStructuredData = function (IDs, item) {
    var _a;
    if (item === void 0) { item = {}; }
    if (!IDs) {
        return {};
    }
    var tickets = getTickets(item);
    return _a = {},
        _a[IDs.SD_JSON_OFFERS] = getJsonOffers(item, tickets.length),
        _a[IDs.SD_JSON_LOCATION] = getLocation(item),
        _a[IDs.SD_STATUS] = "https://schema.org/".concat(getEventStatus(item)),
        _a[IDs.SD_ATTANDANCE_MODE] = "https://schema.org/".concat(getAttendenceMode(item)),
        _a;
};
