"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMenuImagesStructuredData = void 0;
var structure_data_utils_1 = require("../../adapters/structure-data-utils");
var getMenuImagesStructuredData = function (items) {
    var imagesData = items === null || items === void 0 ? void 0 : items.map(function (_a) {
        var image = _a.image;
        return image;
    }).filter(function (image) { return !!image; });
    if (!imagesData || !imagesData.length) {
        return null;
    }
    var images = (0, structure_data_utils_1.getImageObject)('images', imagesData, false, true).images;
    return images;
};
exports.getMenuImagesStructuredData = getMenuImagesStructuredData;
