import { getKeys } from '../../adapters/utils';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { get } from '../../utils/get';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { sanitizeUrl } from '../../utils/sanitize-url';
export var IDs = {
    FORM_NAME: 'bookings-form.form-name',
    FORM_DESCRIPTION: 'bookings-form.form-description',
    PAGE_URL: 'bookings-form.page-url',
};
var SchemaTypes = {};
var userVisible = [];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.FORM_NAME] = get(item, 'service.info.name'),
            _a[IDs.FORM_DESCRIPTION] = get(item, 'service.info.description'),
            _a[IDs.PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BOOKINGS_FORM, item, sanitizeUrl(get(item, "context.".concat(CONTEXT_PROPS.DEFAULT_URL)))),
            _a;
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
