"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStructuredData = exports.getSdType = exports.getBookingDescription = exports.getOfferAvailability = exports.getServiceUrl = exports.getServiceMediaItems = void 0;
var get_1 = require("../../utils/get");
var structure_data_utils_1 = require("../../adapters/structure-data-utils");
var ContextProps_1 = require("../../types/ContextProps");
var constants_1 = require("./constants");
var getServiceMediaItems = function (serviceResponse) {
    if (serviceResponse === void 0) { serviceResponse = {}; }
    var mainMedia = (0, get_1.getRawValue)(serviceResponse, 'service.info.media.mainMedia');
    var mainImage = mainMedia ? [mainMedia] : [];
    var coverMedia = (0, get_1.getRawValue)(serviceResponse, 'service.info.media.coverMedia');
    var coverImage = coverMedia ? [coverMedia] : [];
    var media = (0, get_1.getRawValue)(serviceResponse, 'service.info.media.items') || [];
    var result = __spreadArray(__spreadArray(__spreadArray([], mainImage, true), coverImage, true), media, true);
    return result;
};
exports.getServiceMediaItems = getServiceMediaItems;
var getServiceUrl = function (serviceResponse) {
    if (serviceResponse === void 0) { serviceResponse = {}; }
    return serviceResponse.urls &&
        (0, get_1.get)(serviceResponse, 'urls.servicePageUrl.base').concat((0, get_1.get)(serviceResponse, 'urls.servicePageUrl.path'));
};
exports.getServiceUrl = getServiceUrl;
var getOfferAvailability = function (_a) {
    var _b = _a === void 0 ? {} : _a, isBookable = _b.isBookable, isFullyBooked = _b.isFullyBooked, isServiceAvailable = _b.isServiceAvailable, isTooEarlyToBook = _b.isTooEarlyToBook, isTooLateToBook = _b.isTooLateToBook;
    return isBookable &&
        isServiceAvailable &&
        !isTooEarlyToBook &&
        !isTooLateToBook &&
        !isFullyBooked
        ? 'OnlineOnly'
        : 'SoldOut';
};
exports.getOfferAvailability = getOfferAvailability;
var getBookingDescription = function (serviceResponse) {
    var sessionType = (0, exports.getSdType)(serviceResponse);
    var description = (0, get_1.get)(serviceResponse, 'service.info.description');
    return sessionType === constants_1.SESSION_TYPES.COURSE
        ? description || (0, get_1.get)(serviceResponse, 'service.info.tagLine')
        : description;
};
exports.getBookingDescription = getBookingDescription;
var getSdType = function (serviceResponse) {
    return (0, get_1.get)(serviceResponse, 'schedules.0.tags.0').toLowerCase() ===
        constants_1.SESSION_TYPES.COURSE
        ? constants_1.SESSION_TYPES.COURSE
        : constants_1.SESSION_TYPES.SERVICE;
};
exports.getSdType = getSdType;
var getStructuredData = function (IDs, item) {
    var _a;
    if (!IDs) {
        return {};
    }
    var serviceResponse = item.serviceResponse;
    var url = (0, exports.getServiceUrl)(serviceResponse);
    var offerAvailability = (0, exports.getOfferAvailability)((0, get_1.getRawValue)(item, 'bookingsPolicyDto'));
    var price = (0, get_1.get)(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.amount');
    var priceCurrency = (0, get_1.get)(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.currency');
    var sdType = (0, exports.getSdType)(serviceResponse);
    var validUntil = (0, get_1.get)(serviceResponse, 'schedules.0.availability.end');
    var domain = (0, get_1.get)(serviceResponse, 'urls.servicePageUrl.base');
    var imagesData = ((0, get_1.getRawValue)(serviceResponse, 'service.info.images') || []).map(function (_a) {
        var imageURL = _a.url, width = _a.width, height = _a.height;
        return ({
            url: imageURL,
            width: width,
            height: height,
        });
    });
    var _b = (0, structure_data_utils_1.getImageObject)('images', imagesData).images, images = _b === void 0 ? '' : _b;
    var offers = parseInt(price, 10) > 0
        ? __assign({ '@type': 'offer', availability: "http://schema.org/".concat(offerAvailability), price: price, priceCurrency: priceCurrency, url: url }, (validUntil ? { validUntil: validUntil } : {})) : '';
    var providerName = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_NAME));
    var provider = sdType.toLowerCase() === 'course'
        ? providerName && {
            '@type': 'Organization',
            name: providerName,
            sameAs: domain,
            url: domain,
        }
        : '';
    return _a = {},
        _a[IDs.AVAILABILITY] = offerAvailability,
        _a[IDs.INSTRUCTOR_NAME] = (0, get_1.get)(serviceResponse, 'resources.0.name'),
        _a[IDs.INSTRUCTOR_IMAGE] = (0, get_1.get)(serviceResponse, 'resources.0.image.url'),
        _a[IDs.SD_JSON_PROVIDER] = provider,
        _a[IDs.SD_JSON_IMAGES] = images,
        _a[IDs.SD_JSON_OFFERS] = offers,
        _a;
};
exports.getStructuredData = getStructuredData;
