import { BI_TYPES } from '../../../../core';
import { createRedirectApi } from '../create-redirect-api';
import { SEO_PANEL_TYPES } from './consts';

const createRedirect = ({ request, metaSiteInstance }) => {
  return createRedirectApi({
    request,
    metaSiteInstance,
  });
};

export const getCreateRedirect =
  ({ metaSiteInstance, logBiEvent }) =>
  (request) => {
    const { from, to } = request?.redirect;
    logBiEvent(BI_TYPES.SEO_PANEL_AUTO_REDIRECT_REQUEST, {
      context: SEO_PANEL_TYPES.EDITOR,
      originUrl: from,
      destinationUrl: to,
    });
    return createRedirect({ request, metaSiteInstance });
  };
