import { get, getRawValue } from '../../utils/get';
import { CONTEXT_PROPS } from '../../types/ContextProps';
export var getRestaurantImages = function (item) {
    var imagesArray = getRawValue(item, "context.".concat(CONTEXT_PROPS.RESTAURANT_IMAGES));
    var businessLogo = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOGO));
    return (imagesArray === null || imagesArray === void 0 ? void 0 : imagesArray.length) > 0
        ? imagesArray
        : businessLogo
            ? [businessLogo]
            : undefined;
};
