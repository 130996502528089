"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEventMonth = void 0;
var get_1 = require("../../../utils/get");
var getEventMonth = function (item) {
    if (item === void 0) { item = {}; }
    var startDate = (0, get_1.get)(item, 'event.scheduling.config.startDate');
    if (!startDate) {
        return;
    }
    return new Date(startDate).toLocaleString('default', { month: 'long' });
};
exports.getEventMonth = getEventMonth;
