import React, { useCallback } from 'react';
import { RichText, NumericInput, Tooltip } from '@wix/wix-base-ui';
import {
  ROBOTS_DIRECTIVES,
  MAX_VIDEO_PREVIEW_DEFAULT_VALUE,
  REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
} from '@wix/advanced-seo-utils';
import {
  getValueFromEvent,
  useDirectiveDisplayValue,
  useForcedRender,
  useIsDirectiveExists,
  useUpdateDirectiveWithNumericRange,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

const MIN_VALUE = -1;
const MAX_VALUE = 999;

export const checkboxDataHook = 'max-video-preview-directive-checkbox';

export const inputDataHook = 'max-video-preview-directive-input';

export const MaxVideoPreviewDirective = translate()(({ t }) => {
  const isMaxVideoPreviewDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.MAX_VIDEO_PREVIEW,
  );
  const maxVideoPreviewDisplayValue = useDirectiveDisplayValue(
    ROBOTS_DIRECTIVES.MAX_VIDEO_PREVIEW,
    MAX_VIDEO_PREVIEW_DEFAULT_VALUE,
  );
  const onUpdateMaxVideoPreview = useUpdateDirectiveWithNumericRange(
    ROBOTS_DIRECTIVES.MAX_VIDEO_PREVIEW,
    MAX_VIDEO_PREVIEW_DEFAULT_VALUE,
    MIN_VALUE,
    MAX_VALUE,
  );

  const onChangeMaxVideoPreviewCheckbox = useCallback(
    (isEnabled) => {
      onUpdateMaxVideoPreview(
        isEnabled
          ? MAX_VIDEO_PREVIEW_DEFAULT_VALUE
          : REMOVE_ROBOTS_DIRECTIVE_OVERRIDE_VALUE,
      );
    },
    [onUpdateMaxVideoPreview],
  );

  const { applyForcedRender, triggerForcedRender } = useForcedRender();

  const onMaxVideoPreviewBlur = useCallback(
    (event) => {
      onUpdateMaxVideoPreview(getValueFromEvent(event));
      triggerForcedRender();
    },
    [onUpdateMaxVideoPreview, triggerForcedRender],
  );

  return (
    <>
      <CheckboxWithInfoIcon
        dataHook={checkboxDataHook}
        isChecked={isMaxVideoPreviewDirectiveExists}
        onChange={onChangeMaxVideoPreviewCheckbox}
        label={t(
          'adv-seo.common.robots-tag.directives.max-video-preview.title',
        )}
        infoText={t(
          'adv-seo.common.robots-tag.directives.max-video-preview.tooltip',
        )}
        infoTextBiName="adv-seo.common.robots-tag.directives.max-video-preview.tooltip"
        helpCenterId={t(
          'adv-seo.common.robots-tag.directives.max-video-preview.tooltip.editor-help-center-id',
        )}
      />
      {isMaxVideoPreviewDirectiveExists && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '30px',
          }}
        >
          <div style={{ marginTop: '9px' }}>
            <RichText type="T02">
              {t(
                'adv-seo.common.robots-tag.directives.max-video-preview.value-type',
              )}
            </RichText>
          </div>
          {applyForcedRender(
            <Tooltip
              content={t(
                'adv-seo.common.robots-tag.directives.max-video-preview.input-tooltip',
              )}
              disabled={
                maxVideoPreviewDisplayValue !== MAX_VIDEO_PREVIEW_DEFAULT_VALUE
              }
              shouldTranslate={false}
            >
              <NumericInput
                dataHook={inputDataHook}
                placeholder=""
                disabled={!isMaxVideoPreviewDirectiveExists}
                min={Number.NEGATIVE_INFINITY}
                max={Number.POSITIVE_INFINITY}
                onBlur={onMaxVideoPreviewBlur}
                shouldTranslate={false}
                value={
                  maxVideoPreviewDisplayValue === ''
                    ? undefined
                    : +maxVideoPreviewDisplayValue
                }
              />
            </Tooltip>,
          )}
        </div>
      )}
    </>
  );
});

MaxVideoPreviewDirective.propTypes = {};
