var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { IDENTIFIERS } from '../../types/Identifiers';
import { TAG_SCHEMAS } from '../../types/TagSchemas';
import { validateTagStructure } from '../utils/validate-tag-structure';
var sdTagSchema = TAG_SCHEMAS[IDENTIFIERS.STRUCTURED_DATA];
export var resolveLegacyCustomSd = function (tags, generator) {
    if (!Array.isArray(tags) || typeof generator !== 'function') {
        return tags;
    }
    return tags.map(function (tag) {
        var _a;
        var isSdTag = validateTagStructure(tag, sdTagSchema);
        var tagHasSchemaType = (_a = tag.meta) === null || _a === void 0 ? void 0 : _a.schemaType;
        return isSdTag && !tagHasSchemaType
            ? __assign(__assign({}, tag), { meta: __assign({ schemaType: generator() }, tag.meta) }) : tag;
    });
};
