import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { sanitizeUrl } from '../../utils/sanitize-url';
import { get } from '../../utils/get';
import { getMainImageUrl } from './calculated/challenge/get-main-image-url';
import { getRobots } from './calculated/challenge/get-robots';
import { shorten } from '../../utils/shorten';
import { getName } from './calculated/challenge/get-name';
export var getDataLegacy = function (IDs, itemData) {
    var _a;
    var fullDescription = get(itemData, 'challenge.description');
    return _a = {},
        _a[IDs.ID] = get(itemData, 'challenge.id'),
        _a[IDs.AVAILABILITY] = get(itemData, 'challenge.registration.availability'),
        _a[IDs.MAIN_IMAGE] = getMainImageUrl(itemData),
        _a[IDs.CALCULATED_ROBOTS] = getRobots(itemData),
        _a[IDs.CREATED_DATE] = get(itemData, 'challenge.created'),
        _a[IDs.CURRENCY] = get(itemData, 'challenge.registration.currency'),
        _a[IDs.DESCRIPTION] = fullDescription,
        _a[IDs.DESCRIPTION_150] = shorten(fullDescription, 150),
        _a[IDs.IMAGE_HEIGHT] = get(itemData, 'challenge.mainImage.height'),
        _a[IDs.IMAGE_ID] = get(itemData, 'challenge.mainImage.id'),
        _a[IDs.IMAGE_WIDTH] = get(itemData, 'challenge.mainImage.width'),
        _a[IDs.TITLE] = get(itemData, 'challenge.name'),
        _a[IDs.CALCULATED_NAME] = getName(itemData),
        _a[IDs.PRICE] = get(itemData, 'challenge.registration.price'),
        _a[IDs.URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.CHALLENGES_PAGE, itemData, sanitizeUrl(get(itemData, 'challenge.url'))),
        _a;
};
