"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMenuMultilingualTags = void 0;
var get_1 = require("../../../utils/get");
var ContextProps_1 = require("../../../types/ContextProps");
var get_multilingual_tags_1 = require("../../../renderer/get-multilingual-tags");
var getMenuMultilingualTags = function (item) {
    var siteLanguages = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_LANGUAGES)) || [];
    var currentLanguageCode = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.CURR_LANG_CODE));
    var currentSeoLang = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SEO_LANG));
    var currentUrl = (0, get_1.get)(item, 'menu.url');
    var urlQueryParam = (0, get_1.get)(item, 'menu.urlQueryParam');
    var isMainMenu = (0, get_1.getRawValue)(item, 'menu.isMainMenu');
    var hasTranslations = siteLanguages === null || siteLanguages === void 0 ? void 0 : siteLanguages.some(function (_a) {
        var languageCode = _a.languageCode, status = _a.status;
        return languageCode !== currentLanguageCode && status === 'Active';
    });
    if (!item || !hasTranslations) {
        return [];
    }
    var addQueryParamIfNotMainMenu = function (languages) {
        return languages.map(function (language) {
            if (isMainMenu) {
                return language;
            }
            var url = new URL(language.url);
            url.searchParams.set('menu', urlQueryParam);
            return __assign(__assign({}, language), { url: url.href });
        });
    };
    return (0, get_multilingual_tags_1.getMultilingualTags)(__spreadArray([], addQueryParamIfNotMainMenu(__spreadArray([
        {
            url: currentUrl,
            languageCode: currentLanguageCode,
            seoLang: currentSeoLang,
        }
    ], siteLanguages.filter(function (_a) {
        var languageCode = _a.languageCode;
        return languageCode !== currentLanguageCode;
    }), true)), true));
};
exports.getMenuMultilingualTags = getMenuMultilingualTags;
