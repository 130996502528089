var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import adapter from './adapter-wix-data-page-item';
import imagesSd from './schema-presets/wix-data-item-images';
import videosSd from './schema-presets/wix-data-item-videos';
import { siteIDs } from '../../adapters/utils';
import { buildTitle, buildOgMeta, buildOgMetaRefs, buildLink, buildTwitterMetaRefs, buildStructuredData, } from '../../types/builders';
var IDs = adapter.IDs;
export var WIX_DATA_PAGE_ITEM = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.MAIN_COLLECTION_PRIMARY_FIELD, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.PAGE_URL, "}}") })
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:url', "{{".concat(IDs.PAGE_URL, "}}")),
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website')
    ], false), buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: imagesSd.schema,
            schemaType: imagesSd.type,
            disabled: [
                "{{".concat(IDs.DISABLE_IMAGES_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
        buildStructuredData({
            schema: videosSd.schema,
            schemaType: videosSd.type,
            disabled: [
                "{{".concat(IDs.DISABLE_VIDEOS_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ], false),
};
