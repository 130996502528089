import { invert, mapValues } from 'lodash';

export const TAB_NAMES = {
  BASIC_TAB_NAME: 'BASIC',
  SOCIAL_TAB_NAME: 'SOCIAL',
  ADVANCED_TAB_NAME: 'ADVANCED',
};

export const newToLegacy = {
  seo: 'BASIC',
  social: 'SOCIAL',
  advanced: 'ADVANCED',
};

const legacyToNew = invert(newToLegacy);

export const LEGACY_TAB_NAMES = mapValues(
  TAB_NAMES,
  (tabName) => legacyToNew[tabName],
);

export const PERMISSIONS_TAB_NAME = 'permissions';
