var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getKeys } from '../../adapters/utils';
import { get, getRawValue } from '../../utils/get';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { getMenuImagesStructuredData } from './get-menu-images-structured-data';
import { SCHEMA_TYPES } from './schema-presets/schema-types';
import { SD_STATUS } from '../../consts';
import { getMenuItems } from './calculated/get-menu-items';
import { getMenuMultilingualTags } from './calculated/get-menu-multilinigual-tags';
export var IDs = {
    TITLE: 'menu.name',
    DESCRIPTION: 'menu.description',
    MENU_URL: 'menu.url',
    IS_MAIN_MENU: 'menu.isMainMenu',
    IMAGES_SD: SCHEMA_TYPES.RESTAURANTS_MENU_PAGE_IMAGES,
    SD_JSON_IMAGES: 'menu.structured-data.images',
    DISABLE_IMAGES_SCHEMA: 'menu.images-schema.disable',
};
var SchemaTypes = __assign({}, SCHEMA_TYPES);
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.MENU_URL,
    IDs.SD_JSON_IMAGES,
];
var userVisibleSdOnly = [IDs.IMAGES_SD];
var getSdStatus = function (item, data) {
    var menuItems = getMenuItems(item);
    if (!menuItems || menuItems.length === 0 || !data[IDs.SD_JSON_IMAGES]) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var menuItems = getMenuItems(item);
        var data = (_a = {},
            _a[IDs.TITLE] = get(item, 'menu.name'),
            _a[IDs.DESCRIPTION] = get(item, 'menu.description'),
            _a[IDs.MENU_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.RESTAURANTS_MENU_PAGE, item, getRawValue(item, 'menu.url')),
            _a[IDs.IS_MAIN_MENU] = getRawValue(item, 'menu.isMainMenu'),
            _a[IDs.SD_JSON_IMAGES] = getMenuImagesStructuredData(menuItems),
            _a);
        var imagesSdStatus = getSdStatus(item, data, SchemaTypes.COLLECTION_IMAGES) !==
            SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.DISABLE_IMAGES_SCHEMA] = imagesSdStatus, _b));
    },
    getDynamicPatternsSchema: function (item) {
        var multilingualTags = getMenuMultilingualTags(item);
        return { tags: __spreadArray([], multilingualTags, true) };
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { return get(item, 'menu.id'); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        return (__assign(__assign({}, itemData), { menu: __assign(__assign({}, itemData.menu), { url: url }) }));
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        return __assign(__assign({}, itemData), { menu: __assign(__assign({}, itemData.menu), { id: slugValue }) });
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
};
