import { CREATE_REDIRECT_RESULT_STATUS } from '@wix/advanced-seo-utils';
import i18n from './i18n';
import { logBiResults } from './logBiResults';
import { showEditorNotification } from './show-editor-notifictaion';
import { classifyCreateRedirectResults } from './utils';

export const handleCreateRedirectsResults = async ({
  createRedirectResults,
  scope,
  showUserActionNotification,
  locale,
  logBiEvent,
}) => {
  if (!createRedirectResults.length) {
    return;
  }
  logBiResults({
    classifiedResults: classifyCreateRedirectResults(createRedirectResults),
    logBiEvent,
  });
  const successfulRedirectResults = createRedirectResults.filter(
    (result) => result?.status === CREATE_REDIRECT_RESULT_STATUS.OK,
  );
  if (successfulRedirectResults.length !== createRedirectResults.length) {
    const redirectResultsWithIssues = createRedirectResults.filter(
      (result) => result?.status !== CREATE_REDIRECT_RESULT_STATUS.OK,
    );
    const t = await i18n(locale);
    const notificationData = {
      message: t('adv-seo.editor.auto-redirect.need-to-review.message', {
        count: redirectResultsWithIssues.length,
      }),
      type: 'error',
      linkCaption: t('adv-seo.editor.auto-redirect.need-to-review.cta.label'),
    };
    showEditorNotification({
      showUserActionNotification,
      scope,
      notificationData,
      redirectResults: redirectResultsWithIssues,
    });
  }
};
