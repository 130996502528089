var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import adapter from './adapter-members-area-profile';
import { siteIDs } from '../../adapters/utils';
import { buildOgMeta, buildOgMetaRefs, buildLink, buildTwitterMetaRefs, buildTitle, buildStandardMeta, } from '../../types/builders';
import { TWITTER_CARD_SUPPORTED_TYPES } from '../../types/twitterTagSchemas';
var IDs = adapter.IDs;
var SITE_NAME = siteIDs.SITE_NAME;
export var MEMBERS_AREA_PROFILE = {
    tags: __spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.PAGE_NAME, "}} | {{").concat(SITE_NAME, "}}")),
        buildStandardMeta('robots', 'noindex'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.PAGE_URL, "}}") })
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:url', "{{".concat(IDs.PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(SITE_NAME, "}}"))
    ], false), buildTwitterMetaRefs(TWITTER_CARD_SUPPORTED_TYPES.SUMMARY), true),
};
