var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { applyOgImageModifications } from '../utils/build-og-image';
import { resolvePayload } from './resolve-payload';
import { resolveIsIndexable } from './utils/resolve-is-indexable';
import { resolveStructuredData } from '../tags/structured-data/resolve-structured-data';
import { sort } from './sort';
import { fillInPatternBlob } from '../patterns/fill-in-pattern-blob';
import { removeBlackListedTags } from './utils/remove-black-listed-tags';
import { applyOptions } from '../options/apply-options';
import { resolveTwitterImage } from '../utils/resolve-twitter-image';
import { formatRobotsTagForRendering } from '../tags/robots-tag/robots-tag';
import { resolvePagination } from './resolve-pagination';
import { resolveEmptyTitlePrefix } from './resolve-empty-title-prefix';
import { logInfo } from '../utils/log-info';
export function resolveWithPatterns(payload, context, options) {
    if (options === void 0) { options = { logError: function () { } }; }
    logInfo(options, { payload: payload }, 'resolvePayload');
    var result = resolvePayload(payload, options);
    var contextWithOptions = __assign(__assign({}, context), applyOptions(result.options));
    logInfo(options, { tags: result.tags }, 'resolvePagination');
    result.tags = resolvePagination(result.tags, contextWithOptions);
    logInfo(options, { tags: result.tags }, 'resolveStructuredData');
    result.tags = resolveStructuredData(result.tags, contextWithOptions);
    logInfo(options, { tags: result.tags }, 'contextWithOptions');
    result.tags = resolveIsIndexable(result.tags, contextWithOptions);
    logInfo(options, { tags: result.tags }, 'fillInPatternBlob');
    result = fillInPatternBlob(result, contextWithOptions);
    logInfo(options, { tags: result.tags }, 'applyOgImageModifications');
    result.tags = applyOgImageModifications(result.tags);
    logInfo(options, { tags: result.tags }, 'resolveTwitterImage');
    result.tags = resolveTwitterImage(result.tags);
    logInfo(options, { tags: result.tags }, 'removeBlackListedTags');
    result.tags = removeBlackListedTags(result.tags);
    logInfo(options, { tags: result.tags }, 'formatRobotsTagForRendering');
    result.tags = formatRobotsTagForRendering(result.tags);
    logInfo(options, { tags: result.tags }, 'resolveEmptyTitlePrefix');
    result.tags = resolveEmptyTitlePrefix(result.tags);
    logInfo(options, { tags: result.tags }, 'sort');
    result.tags = sort(result.tags);
    logInfo(options, { tags: result.tags }, 'return');
    return result;
}
