import React, { PureComponent } from 'react';
import { func, node, string } from 'prop-types';

export class Button extends PureComponent {
  static propTypes = {
    onClick: func,
    icon: node,
    text: string,
    dataHook: string,
  };

  render() {
    const { onClick, icon, text, dataHook } = this.props;
    const className = text
      ? 'seo-advanced-client-button'
      : 'seo-advanced-client-button seo-advanced-client-button--icon';
    return (
      <div className={className} onClick={onClick} data-hook={dataHook}>
        {icon}
        {text && <span>{text}</span>}
      </div>
    );
  }
}
