import { WritableAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const useEffectSetAtom = <T>(
  atomToSet: WritableAtom<unknown, [T], unknown>,
  value: T,
) => {
  const setAtom = useSetAtom(atomToSet);
  useEffect(() => {
    setAtom(value);
  }, [value, setAtom]);
};

export const useEffectFetchAndSetAtom = <T>(
  atomToSet: WritableAtom<unknown, [T], unknown>,
  generateValue: () => Promise<T>,
) => {
  const setAtom = useSetAtom(atomToSet);
  useEffect(() => {
    const setValue = async () => {
      const value = await generateValue();
      setAtom(value);
    };

    setValue();
  }, [generateValue, setAtom]);
};
