import { buildStructuredData } from '../../types/builders';
import videoSchema from './schema-presets/video-component';
export var VIDEO_COMPONENT = {
    tags: [
        buildStructuredData({
            schema: videoSchema.schema,
            schemaType: videoSchema.schemaType,
        }),
    ],
};
