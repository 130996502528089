import { siteIDs } from '../../../adapters/utils';
import { SCHEMA_TYPES } from './schema-types';
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'LocalBusiness',
    name: "{{".concat(siteIDs.BUSINESS_NAME, "}}"),
    url: "{{".concat(siteIDs.SITE_URL, "}}"),
    image: "{{".concat(siteIDs.BUSINESS_SCHEMA_IMAGE, "}}"),
    address: {
        '@type': 'PostalAddress',
        addressCountry: "{{".concat(siteIDs.BUSINESS_LOCATION_COUNTRY, "}}"),
        addressLocality: "{{".concat(siteIDs.BUSINESS_LOCATION_CITY, "}}"),
        addressRegion: "{{".concat(siteIDs.BUSINESS_LOCATION_STATE, "}}"),
        postalCode: "{{".concat(siteIDs.BUSINESS_POSTAL_CODE, "}}"),
        streetAddress: "{{".concat(siteIDs.BUSINESS_LOCATION_STREET_ADDRESS, "}}"),
    },
    telephone: "{{".concat(siteIDs.BUSINESS_PHONE, "}}"),
};
var requiredSchemaVariables = [
    siteIDs.BUSINESS_NAME,
    siteIDs.BUSINESS_LOCATION_FORMATTED,
];
export default {
    type: SCHEMA_TYPES.LOCAL_BUSINESS,
    schema: schema,
    requiredSchemaVariables: requiredSchemaVariables,
};
