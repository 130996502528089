import React from 'react';

export const DividerIcon = () => {
  return (
    <svg
      width="1"
      height="24"
      viewBox="0 0 1 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0H0V24H1V0Z" fill="#ACAFC4" />
    </svg>
  );
};
