"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
exports.IDs = {
    SD_JSON_RENDER: 'breadcrumb-component.structured-data.render',
};
var SchemaTypes = {
    BREADCRUMBS_COMPONENT_ITEM_LIST: 'breadcrumbs-component-item-list-preset',
};
var userVisible = [];
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function () {
        var _a;
        return _a = {},
            _a[exports.IDs.SD_JSON_RENDER] = { '@context': 'https://schema.org' },
            _a;
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
