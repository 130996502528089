import {
  getValueFromHtmlTag,
  parse,
  validateTagValueByIdentifier,
  IDENTIFIERS,
} from '@wix/advanced-seo-utils';

import { KEYS, ERROR_NAMES } from '../../../../../core/utils/maps';
import { ERRORS_MAP } from '../../../../../core/errors-map';
import { SCRIPT_TAG_PARTS } from '../../../../../core/utils/consts';

import { MAX_LENGTH } from '../../../../../core/validators/validators';

const MAX_SCHEMA_NAME_LENGTH = MAX_LENGTH[KEYS.STRUCTURED_DATA_NAME];
const MAX_SCHEMA_CONTENT_LENGTH = MAX_LENGTH[KEYS.JSON_LD];

const errorsMap = ERRORS_MAP();
const SCHEMA_NAME_ERROR_MAP = errorsMap[KEYS.STRUCTURED_DATA_NAME];
const SCHEMA_CODE_ERROR_MAP = errorsMap[KEYS.JSON_LD];

function validateJson(value) {
  return validateTagValueByIdentifier(IDENTIFIERS.STRUCTURED_DATA, value);
}

function validateMainObjectContainsValues(value) {
  let isValid = true;
  try {
    const schemaObject = JSON.parse(value);
    const mainObjectContainsEmptyValues = Object.values(schemaObject).some(
      (mainObjectValue) =>
        typeof mainObjectValue === 'string' &&
        !mainObjectValue.replace(/\s/g, '').length,
    );
    isValid = !mainObjectContainsEmptyValues;
  } catch (error) {
    isValid = false;
  }
  return isValid;
}

function onlyAlphaNumericSpacesAndDashes(value) {
  return !/^[\w\-\s]+$/.test(value);
}

export const validateName = (name, otherSchemas = []) => {
  if (!name) {
    return buildInvalid(SCHEMA_NAME_ERROR_MAP[ERROR_NAMES.EMPTY_STRING]);
  }
  if (name.length > MAX_SCHEMA_NAME_LENGTH) {
    return buildInvalid(
      SCHEMA_NAME_ERROR_MAP[ERROR_NAMES.INVALID_STRING_LENGTH],
    );
  }
  if (onlyAlphaNumericSpacesAndDashes(name)) {
    return buildInvalid(SCHEMA_NAME_ERROR_MAP[ERROR_NAMES.INVALID_SD_NAME]);
  }
  if (
    otherSchemas.some(
      ({ schemaType, displayName }) =>
        schemaType === name || displayName === name,
    )
  ) {
    return buildInvalid(
      SCHEMA_NAME_ERROR_MAP[ERROR_NAMES.SD_NAME_NOT_DISTINCT],
    );
  } else {
    return {
      isValid: true,
    };
  }
};

export const validateSchema = (value, otherSchemas) => {
  if (!value) {
    return buildInvalid(SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.EMPTY_STRING]);
  }
  if (value.length > MAX_SCHEMA_CONTENT_LENGTH) {
    return buildInvalid(
      SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.INVALID_STRING_LENGTH],
    );
  }
  let json = value;
  const tags = parse(value);
  if (
    tags.length >= 1 &&
    (!value.trim().startsWith(SCRIPT_TAG_PARTS.PARTIAL) ||
      !value.trim().endsWith(SCRIPT_TAG_PARTS.CLOSING))
  ) {
    return buildInvalid(
      SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.HTML_TAG_INSIDE_SCRIPT],
    );
  }
  if (tags.length > 1 && value.trim().startsWith(SCRIPT_TAG_PARTS.PARTIAL)) {
    return buildInvalid(SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.INVALID_TAGS_LENGTH]);
  }
  json = getValueFromHtmlTag(tags[0], IDENTIFIERS.STRUCTURED_DATA);
  if (!json && value.trim().startsWith(SCRIPT_TAG_PARTS.PARTIAL)) {
    return buildInvalid(
      SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.INVALID_TAG_STRUCTURE],
    );
  }
  json = (json || value).replace(/\n/g, '');
  const { isValid: isJsonValid } = validateJson(json);
  if (!isJsonValid || !json.includes('{')) {
    return buildInvalid(SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.INVALID_JSON]);
  }
  let warning;
  if (otherSchemas?.length) {
    otherSchemas
      .filter((otherSchema) => !otherSchema.disabled)
      .forEach((otherSchema) => {
        try {
          const schemaType = JSON.parse(json)['@type'];
          const otherSchemaType = JSON.parse(otherSchema.value)['@type'];
          if (schemaType && schemaType === otherSchemaType) {
            warning = {
              message:
                SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.SD_TYPE_NOT_DISTINCT_WARNING],
              type: schemaType?.replace(/[{}]/g, ''),
            };
          }
        } catch (err) {
          console.warn('failed to parse schemas for input validation', err);
        }
      });
  }
  const isMainObjectValid = validateMainObjectContainsValues(json);
  if (!isMainObjectValid) {
    return buildInvalid(
      SCHEMA_CODE_ERROR_MAP[ERROR_NAMES.SD_MAIN_OBJECT_IS_EMPTY],
    );
  }
  return {
    isValid: true,
    warning,
  };
};

export const validateForm = (
  { value: name },
  { value: code },
  otherSchemas,
) => {
  const { isValid: isNameValid } = validateName(name, otherSchemas);
  const { isValid: isSchemaValid } = validateSchema(code, otherSchemas);
  return isNameValid && isSchemaValid ? { isValid: true } : buildInvalid();
};

function buildInvalid(message) {
  return {
    isValid: false,
    ...(message ? { error: message } : {}),
  };
}
