var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function mergeParentChildSchemas(parentSchema, childSchema) {
    var parentChildren, childChildren;
    try {
        parentChildren = JSON.parse(parentSchema.children);
    }
    catch (e) {
        parentChildren = {};
    }
    try {
        childChildren = JSON.parse(childSchema.children);
    }
    catch (e) {
        childChildren = {};
    }
    var mergedChildren = __assign(__assign({}, parentChildren), childChildren);
    return __assign(__assign({}, parentSchema), { children: JSON.stringify(mergedChildren) });
}
