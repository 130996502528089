import { KEYS, ERROR_NAMES } from './utils/maps';

export const ERRORS_MAP = () => ({
  [KEYS.OG_URL]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.adv-panel.canonical.input-error.empty-symbols-emojis',
    },
  },
  [KEYS.OG_IMAGE]: {
    'invalid-image-type': 'adv-seo.adv-panel.og-image.invalid-image-type',
    'invalid-image-size': 'adv-seo.adv-panel.og-image.invalid-image-size',
  },
  [KEYS.TWITTER_IMAGE]: {
    'invalid-image-type': 'adv-seo.adv-panel.twitter-image.invalid-image-type',
    'invalid-image-size': 'adv-seo.adv-panel.twitter-image.invalid-image-size',
  },
  [KEYS.TITLE]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.common.input-error.invalid-length.title',
      1: 'adv-seo.common.input-error.symbols-emojis',
    },
  },
  [KEYS.DESCRIPTION]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.common.input-error.invalid-length.description',
      1: 'adv-seo.common.input-error.symbols-emojis',
    },
  },
  [KEYS.OG_TITLE]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.common.input-error.invalid-length.title',
      1: 'adv-seo.common.input-error.symbols-emojis',
    },
  },
  [KEYS.OG_DESCRIPTION]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.common.input-error.invalid-length.description',
      1: 'adv-seo.common.input-error.symbols-emojis',
    },
  },
  [KEYS.TWITTER_TITLE]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.common.input-error.invalid-length.title',
      1: 'adv-seo.common.input-error.symbols-emojis',
    },
  },
  [KEYS.TWITTER_DESCRIPTION]: {
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.common.input-error.invalid-length.description',
      1: 'adv-seo.common.input-error.symbols-emojis',
    },
  },
  [KEYS.URI]: {
    [ERROR_NAMES.URI_DUPLICATE]:
      'adv-seo.basic-panel.page-url.input-error.duplicate',
    [ERROR_NAMES.URI_FORBIDDEN]:
      'adv-seo.basic-panel.page-url.input-error.forbidden',
    [ERROR_NAMES.INVALID_URL_CHARACTERS]:
      'adv-seo.basic-panel.page-url.input-error.characters',
    [ERROR_NAMES.STARTS_OR_ENDS_WITH_DASH]:
      'adv-seo.side-panel.page-seo.page-url.input-error.starts-or-ends-with-dash',
    [ERROR_NAMES.INVALID_URL_CHARACTERS_LEGACY]:
      'adv-seo.basic-panel.page-url.input-error.englishOnly',
    [ERROR_NAMES.EMPTY_STRING]:
      'adv-seo.basic-panel.page-url.input-error.invalid',
    [ERROR_NAMES.INVALID_URI_LENGTH]:
      'adv-seo.common.input-error.invalid-length.url',
  },
  [KEYS.JSON_LD]: {
    [ERROR_NAMES.INVALID_JSON]:
      'adv-seo.adv-panel.structured-data.input-error.invalid-json',
    [ERROR_NAMES.INVALID_TAG_STRUCTURE]:
      'adv-seo.adv-panel.structured-data.input-error.invalid-script-tag',
    [ERROR_NAMES.INVALID_STRING_LENGTH]:
      'adv-seo.adv-panel.structured-data.input-error.char-limit',
    [ERROR_NAMES.HTML_TAG_INSIDE_SCRIPT]:
      'adv-seo.adv-panel.structured-data.input-error.invalid-script-tag.html-tag-inside-script',
    [ERROR_NAMES.EMPTY_STRING]:
      'adv-seo.adv-panel.structured-data.input-error.empty.message',
    [ERROR_NAMES.INVALID_TAGS_LENGTH]:
      'adv-seo.adv-panel.structured-data.input-error.invalid-tag-length.message',
    [ERROR_NAMES.SD_TYPE_NOT_DISTINCT_WARNING]:
      'adv-seo.adv-panel.structured-data.input-warning.distinct-type.message',
    [ERROR_NAMES.SD_MAIN_OBJECT_IS_EMPTY]:
      'adv-seo.adv-panel.structured-data.input-error.invalid-empty-main-object',
  },
  [KEYS.STRUCTURED_DATA_NAME]: {
    [ERROR_NAMES.EMPTY_STRING]:
      'adv-seo.adv-panel.structured-data.input-error.name.empty.message',
    [ERROR_NAMES.INVALID_STRING_LENGTH]:
      'adv-seo.adv-panel.structured-data.input-error.name.too-long.message',
    [ERROR_NAMES.INVALID_SD_NAME]:
      'adv-seo.adv-panel.structured-data.input-error.name.invalid-char.message',
    [ERROR_NAMES.SD_NAME_NOT_DISTINCT]:
      'adv-seo.adv-panel.structured-data.input-error.name.not-dictinct.message',
  },
  [KEYS.CANONICAL]: {
    [ERROR_NAMES.INVALID_FULL_URL]:
      'adv-seo.adv-panel.canonical.input-error.invalid-url',
    [ERROR_NAMES.CUSTOM_VALIDATOR]: {
      0: 'adv-seo.adv-panel.canonical.input-error.empty-symbols-emojis',
    },
  },
  [KEYS.CUSTOM_TAGS]: {
    [ERROR_NAMES.INVALID_TAG_STRUCTURE]:
      'adv-seo.adv-panel.custom-tags.errors.invalid-tag-structure',
    [ERROR_NAMES.TAG_ALREADY_EXISTS]:
      'adv-seo.adv-panel.custom-tags.errors.duplicate-tags',
    [ERROR_NAMES.TAG_TYPE_NOT_ALLOWED]:
      'adv-seo.adv-panel.custom-tags.errors.tag-type-not-allowed',
    [ERROR_NAMES.TAG_CONTAINS_BLACKLISTED_ATTRIBUTE]:
      'adv-seo.adv-panel.custom-tags.errors.tag-contains-blacklisted-attribute',
  },
  [KEYS.CUSTOM_TAGS_ARRAY]: {
    [ERROR_NAMES.INVALID_TAG_STRUCTURE]:
      'adv-seo.adv-panel.custom-tags.errors.invalid-tag-structure',
    [ERROR_NAMES.TAG_ALREADY_EXISTS]:
      'adv-seo.adv-panel.custom-tags.errors.duplicate-tags',
    [ERROR_NAMES.TAG_TYPE_NOT_ALLOWED]:
      'adv-seo.adv-panel.custom-tags.errors.tag-type-not-allowed',
    [ERROR_NAMES.TAG_CONTAINS_BLACKLISTED_ATTRIBUTE]:
      'adv-seo.adv-panel.custom-tags.errors.tag-contains-blacklisted-attribute',
    [ERROR_NAMES.INVALID_TAGS_LENGTH]:
      'adv-seo.adv-panel.custom-tags.errors.invalid-length-tags',
    [ERROR_NAMES.MAX_LENGTH_EXCEEDED]:
      'adv-seo.adv-panel.custom-tags.errors.invalid-tag-length',
    [ERROR_NAMES.CSS_NOT_ALLOWED]:
      'adv-seo.adv-panel.custom-tags.errors.css-not-allowed',
    [ERROR_NAMES.EMPTY_PROPS]:
      'adv-seo.adv-panel.custom-tags.errors.empty-props',
    [ERROR_NAMES.HREF_ATTRIBUTE_REQUIRED]:
      'adv-seo.adv-panel.custom-tags.errors.href-prop-required',
    [ERROR_NAMES.CONTENT_ATTRIBUTE_REQUIRED]:
      'adv-seo.adv-panel.custom-tags.errors.content-prop-required',
    [ERROR_NAMES.NAME_OR_PROPERTY_ATTRIBUTE_REQUIRED]:
      'adv-seo.adv-panel.custom-tags.errors.props-required',
    [ERROR_NAMES.REL_ATTRIBUTE_REQUIRED]:
      'adv-seo.adv-panel.custom-tags.errors.rel-prop-required',
  },
});

export const ERRORS_CONSTANTS_MAP = () => ({
  [KEYS.CUSTOM_TAGS_ARRAY]: {
    [ERROR_NAMES.HREF_ATTRIBUTE_REQUIRED]: {
      linkPrefix: "<link rel='html' href='",
      linkSuffix: "'/>",
    },
    [ERROR_NAMES.CONTENT_ATTRIBUTE_REQUIRED]: {
      metaPrefix: "<meta name='",
      metaMiddle: "' content='",
      metaSuffix: "'/>",
    },
    [ERROR_NAMES.NAME_OR_PROPERTY_ATTRIBUTE_REQUIRED]: {
      metaPrefix: "<meta name='",
      metaMiddle: "' content='",
      metaSuffix: "'/>",
    },
    [ERROR_NAMES.REL_ATTRIBUTE_REQUIRED]: {
      linkPrefix: "<link rel='html' href='",
      linkSuffix: "'/>",
    },
  },
});
