"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataLegacy = void 0;
// TODO: remove once groups post finish migration to new architecture
var ContextProps_1 = require("../../types/ContextProps");
var ItemTypes_1 = require("../../types/ItemTypes");
var get_1 = require("../../utils/get");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var sanitize_url_1 = require("../../utils/sanitize-url");
var getPostImage = function (item) {
    var _a = (0, get_1.getRawValue)(item, 'postData.images.0') || {}, _b = _a.imageUrl, imageUrl = _b === void 0 ? '' : _b, _c = _a.imageWidth, imageWidth = _c === void 0 ? '' : _c, _d = _a.imageHeight, imageHeight = _d === void 0 ? '' : _d;
    return { imageUrl: imageUrl, imageWidth: imageWidth, imageHeight: imageHeight };
};
var getPublishDate = function (item) {
    var publishDate = (0, get_1.get)(item, 'post.createdAt');
    if (!publishDate) {
        return '';
    }
    return new Date(parseInt(publishDate, 10)).toISOString();
};
var getDataLegacy = function (IDs, itemData) {
    var _a;
    var _b;
    var postContent = (0, get_1.get)(itemData, 'postData.content');
    var postImage = getPostImage(itemData);
    return _a = {},
        _a[IDs.ID] = ((_b = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _b === void 0 ? void 0 : _b.groupId) || '',
        _a[IDs.INDEX_PAGE] = (0, get_1.getRawValue)(itemData, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_PAGE)),
        _a[IDs.POST_CONTENT] = postContent,
        _a[IDs.POST_CONTENT_60] = postContent.substring(0, 60),
        _a[IDs.POST_CONTENT_200] = postContent.substring(0, 200),
        _a[IDs.GROUP_NAME] = (0, get_1.get)(itemData, 'group.details.title'),
        _a[IDs.POST_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.GROUPS_POST, itemData, (0, sanitize_url_1.sanitizeUrl)((0, get_1.get)(itemData, 'directUrl'))),
        _a[IDs.POST_AUTHOR] = (0, get_1.get)(itemData, 'post.createdBy.name'),
        _a[IDs.POST_PUBLISH_DATE] = getPublishDate(itemData),
        _a[IDs.POST_IMAGE_HEIGHT] = postImage === null || postImage === void 0 ? void 0 : postImage.imageHeight,
        _a[IDs.POST_IMAGE_WIDTH] = postImage === null || postImage === void 0 ? void 0 : postImage.imageWidth,
        _a[IDs.MAIN_IMAGE] = postImage === null || postImage === void 0 ? void 0 : postImage.imageUrl,
        _a;
};
exports.getDataLegacy = getDataLegacy;
