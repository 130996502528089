var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get, getRawValue } from '../../utils/get';
import { getImageObject } from '../../adapters/structure-data-utils';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { SESSION_TYPES } from './constants';
export var getServiceMediaItems = function (serviceResponse) {
    if (serviceResponse === void 0) { serviceResponse = {}; }
    var mainMedia = getRawValue(serviceResponse, 'service.info.media.mainMedia');
    var mainImage = mainMedia ? [mainMedia] : [];
    var coverMedia = getRawValue(serviceResponse, 'service.info.media.coverMedia');
    var coverImage = coverMedia ? [coverMedia] : [];
    var media = getRawValue(serviceResponse, 'service.info.media.items') || [];
    var result = __spreadArray(__spreadArray(__spreadArray([], mainImage, true), coverImage, true), media, true);
    return result;
};
export var getServiceUrl = function (serviceResponse) {
    if (serviceResponse === void 0) { serviceResponse = {}; }
    return serviceResponse.urls &&
        get(serviceResponse, 'urls.servicePageUrl.base').concat(get(serviceResponse, 'urls.servicePageUrl.path'));
};
export var getOfferAvailability = function (_a) {
    var _b = _a === void 0 ? {} : _a, isBookable = _b.isBookable, isFullyBooked = _b.isFullyBooked, isServiceAvailable = _b.isServiceAvailable, isTooEarlyToBook = _b.isTooEarlyToBook, isTooLateToBook = _b.isTooLateToBook;
    return isBookable &&
        isServiceAvailable &&
        !isTooEarlyToBook &&
        !isTooLateToBook &&
        !isFullyBooked
        ? 'OnlineOnly'
        : 'SoldOut';
};
export var getBookingDescription = function (serviceResponse) {
    var sessionType = getSdType(serviceResponse);
    var description = get(serviceResponse, 'service.info.description');
    return sessionType === SESSION_TYPES.COURSE
        ? description || get(serviceResponse, 'service.info.tagLine')
        : description;
};
export var getSdType = function (serviceResponse) {
    return get(serviceResponse, 'schedules.0.tags.0').toLowerCase() ===
        SESSION_TYPES.COURSE
        ? SESSION_TYPES.COURSE
        : SESSION_TYPES.SERVICE;
};
export var getStructuredData = function (IDs, item) {
    var _a;
    if (!IDs) {
        return {};
    }
    var serviceResponse = item.serviceResponse;
    var url = getServiceUrl(serviceResponse);
    var offerAvailability = getOfferAvailability(getRawValue(item, 'bookingsPolicyDto'));
    var price = get(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.amount');
    var priceCurrency = get(serviceResponse, 'schedules.0.rate.labeledPriceOptions.general.currency');
    var sdType = getSdType(serviceResponse);
    var validUntil = get(serviceResponse, 'schedules.0.availability.end');
    var domain = get(serviceResponse, 'urls.servicePageUrl.base');
    var imagesData = (getRawValue(serviceResponse, 'service.info.images') || []).map(function (_a) {
        var imageURL = _a.url, width = _a.width, height = _a.height;
        return ({
            url: imageURL,
            width: width,
            height: height,
        });
    });
    var _b = getImageObject('images', imagesData).images, images = _b === void 0 ? '' : _b;
    var offers = parseInt(price, 10) > 0
        ? __assign({ '@type': 'offer', availability: "http://schema.org/".concat(offerAvailability), price: price, priceCurrency: priceCurrency, url: url }, (validUntil ? { validUntil: validUntil } : {})) : '';
    var providerName = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_NAME));
    var provider = sdType.toLowerCase() === 'course'
        ? providerName && {
            '@type': 'Organization',
            name: providerName,
            sameAs: domain,
            url: domain,
        }
        : '';
    return _a = {},
        _a[IDs.AVAILABILITY] = offerAvailability,
        _a[IDs.INSTRUCTOR_NAME] = get(serviceResponse, 'resources.0.name'),
        _a[IDs.INSTRUCTOR_IMAGE] = get(serviceResponse, 'resources.0.image.url'),
        _a[IDs.SD_JSON_PROVIDER] = provider,
        _a[IDs.SD_JSON_IMAGES] = images,
        _a[IDs.SD_JSON_OFFERS] = offers,
        _a;
};
