var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Creates a shallow copy of a given advanced seo data.
 *
 * @param {Object} value advanced seo data to clone.
 */
export function clone(value) {
    return __assign({ tags: (value || {}).tags || [] }, ((value || {}).settings ? { settings: value.settings } : {}));
}
