export const BI_DATA = {
  FIELD_NAME: 'schemasMap',
  CLOSE_ACTION: 'close',
  CANCEL_ACTION: 'cancel',
  APPLY_ACTION: 'apply',
  REMOVE_ACTION: 'apply',
};

export const MODAL_TYPES = {
  ADD: 'sd-add',
  EDIT: 'sd-edit',
  WARNING: 'sd-warning',
  NONE: '',
};
