"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDuration = void 0;
var calculateDuration = function (unit, time) {
    switch (unit) {
        case 'WEEKS':
            return 'P' + time + 'W';
        case 'DAYS':
            return 'P' + time + 'D';
        case 'HOURS':
            return 'PT' + time + 'H';
        case 'MINUTES':
            return 'PT' + time + 'M';
        default:
            return;
    }
};
exports.calculateDuration = calculateDuration;
