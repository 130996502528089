import { useMemo } from 'react';

import {
  SiteStructureGraph,
  getSlugWithHierarchy,
} from '@wix/seo-page-hierarchy-library';
import {
  usePageHierarchyContext,
  useHasUrlHierarchyAndHasParent,
} from '../hooks/page-hierarchy';
import { buildDefaultUrl } from '../../core/utils/utils';
import { useAtomValue } from 'jotai';
import { pageIdAtom, routerUrlPrefixAtom } from './atoms';

export const removeLeadingSlash = (url: string): string =>
  url.replace(/^\//u, '');

const removeTrailingSlash = (url: string): string => url.replace(/\/$/u, '');

const removeLeadingAndTrailingSlash = (url: string): string => {
  return removeLeadingSlash(removeTrailingSlash(url));
};

export const useGetFullSlug = (uri: string) => {
  const pageId = useAtomValue(pageIdAtom);
  const { siteStructureGraph } = usePageHierarchyContext();
  const hasUrlHierarchy = useHasUrlHierarchyAndHasParent();
  const fullSlug = useMemo(() => {
    if (!hasUrlHierarchy) {
      return uri;
    }

    return removeLeadingSlash(getSlugWithHierarchy(siteStructureGraph, pageId));
  }, [hasUrlHierarchy, siteStructureGraph, pageId, uri]);

  return fullSlug;
};

export const getResolvedUrlByRouterUrlPrefixOrHierarchy = ({
  publicURL,
  siteName,
  routerUrlPrefix,
  isDomainConnected,
  hasUrlHierarchyAndHasParent,
  uri,
  siteStructureGraph,
  pageId,
}: {
  publicURL: string;
  siteName: string;
  routerUrlPrefix: string | undefined;
  isDomainConnected: boolean;
  hasUrlHierarchyAndHasParent: boolean;
  uri: string;
  siteStructureGraph: SiteStructureGraph;
  pageId: string;
}) => {
  const origin = new URL(publicURL).origin;
  const base = isDomainConnected ? origin : new URL(siteName, origin).href;
  if (routerUrlPrefix) {
    return (
      removeTrailingSlash(base) +
      '/' +
      removeLeadingAndTrailingSlash(routerUrlPrefix) +
      '/' +
      removeTrailingSlash(uri)
    );
  } else if (hasUrlHierarchyAndHasParent) {
    const fullSlug = getSlugWithHierarchy(siteStructureGraph, pageId);
    return removeTrailingSlash(base) + fullSlug;
  } else {
    return publicURL;
  }
};

export const useGetPublicURL = ({
  publicURL,
  isDomainConnected,
  siteName,
  language,
  isHomePage,
  uri,
  mlResolutionMethod,
}: {
  publicURL: string;
  isDomainConnected: boolean;
  siteName: string;
  language: { current: string; original: string };
  isHomePage: boolean;
  uri: string;
  mlResolutionMethod: any;
}) => {
  const pageId = useAtomValue(pageIdAtom);
  const routerUrlPrefix = useAtomValue(routerUrlPrefixAtom);
  const { siteStructureGraph } = usePageHierarchyContext();
  const hasUrlHierarchyAndHasParent = useHasUrlHierarchyAndHasParent();
  const resolvedUrl = useMemo(
    () =>
      getResolvedUrlByRouterUrlPrefixOrHierarchy({
        publicURL,
        siteName,
        routerUrlPrefix,
        isDomainConnected,
        hasUrlHierarchyAndHasParent,
        uri,
        siteStructureGraph,
        pageId,
      }),
    [
      publicURL,
      siteStructureGraph,
      pageId,
      isDomainConnected,
      siteName,
      hasUrlHierarchyAndHasParent,
      routerUrlPrefix,
      uri,
    ],
  );

  const url = isHomePage
    ? resolvedUrl
    : resolvedUrl.replace(/[^/]*$/, '').concat(uri);

  return buildDefaultUrl(url, language, mlResolutionMethod);
};
