import React from 'react';

export const More = ({ style }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24"
    viewBox="0 0 30 24"
  >
    <defs>
      <filter
        id="base-ui-32-context-menu-a"
        width="391.7%"
        height="391.7%"
        x="-145.8%"
        y="-145.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="6"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.0862745098   0 0 0 0 0.176470588   0 0 0 0 0.239215686  0 0 0 0.18 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="#3899EC"
      fill-rule="evenodd"
      filter="url(#base-ui-32-context-menu-a)"
      transform="translate(4 1)"
    >
      <path
        d="M6,8 L6,10 L4,10 L4,8 L6,8 Z M10,8 L10,10 L8,10 L8,8 L10,8 Z M14,8 L14,10 L12,10 L12,8 L14,8 Z"
        transform="translate(3 3)"
      />
    </g>
  </svg>
);
