import {
  IDENTIFIERS,
  ERROR_NAMES as LOGIC_ERROR_NAMES,
} from '@wix/advanced-seo-utils';

export const UI_ONLY_KEYS = {
  URI: 'uri',
};

export const KEYS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  IS_INDEX_ENABLED: 'isIndexEnabled',
  ROBOTS_TAG: 'robotsTag',
  OG_IMAGE: 'ogImage',
  OG_TITLE: 'ogTitle',
  OG_DESCRIPTION: 'ogDescription',
  TWITTER_CARD: 'twitterCard',
  TWITTER_TITLE: 'twitterTitle',
  TWITTER_DESCRIPTION: 'twitterDescription',
  TWITTER_IMAGE: 'twitterImage',
  STRUCTURED_DATA_NAME: 'structuredDataName',
  JSON_LD: 'jsonLD',
  SCHEMAS_MAP: 'schemasMap',
  CANONICAL: 'canonicalUrl',
  OG_URL: 'og:url',
  ADVANCED_TAGS: 'advancedTags',
  CUSTOM_TAGS: 'customTags',
  CUSTOM_TAGS_ARRAY: 'customTagsArray',
  SLUG: 'slug',
  ITEM_TYPE: 'itemType',
  PREVENT_AUTO_REDIRECT: 'preventAutoRedirect',
  KEYWORD: 'keyword',
  ...UI_ONLY_KEYS,
};

export const TAG_TYPE = {
  ADVANCED: 'advanced',
  CUSTOM: 'custom',
};

export const EDITOR_DATA = {
  [KEYS.TITLE]: {
    prop: 'title',
    identifier: IDENTIFIERS.TITLE,
  },
  [KEYS.DESCRIPTION]: {
    prop: 'description',
    identifier: IDENTIFIERS.DESCRIPTION,
  },
  [KEYS.IS_INDEX_ENABLED]: {
    prop: 'isIndexEnabled',
    identifier: IDENTIFIERS.ROBOTS,
  },
  [KEYS.URI]: {
    prop: 'uri',
    defaultVal: 'blank',
  },
  [KEYS.OG_IMAGE]: {
    prop: 'ogImage',
    identifier: IDENTIFIERS.OG_IMAGE,
  },
  [KEYS.TWITTER_IMAGE]: {
    prop: 'twitterImage',
    identifier: IDENTIFIERS.TWITTER_IMAGE,
  },
  [KEYS.PREVENT_AUTO_REDIRECT]: {
    prop: 'preventAutoRedirect',
    defaultVal: false,
  },
};

export const ADV_SEO_DATA = {
  [KEYS.OG_TITLE]: {
    identifier: IDENTIFIERS.OG_TITLE,
  },
  [KEYS.OG_DESCRIPTION]: {
    identifier: IDENTIFIERS.OG_DESCRIPTION,
  },
  [KEYS.TWITTER_TITLE]: {
    identifier: IDENTIFIERS.TWITTER_TITLE,
  },
  [KEYS.TWITTER_DESCRIPTION]: {
    identifier: IDENTIFIERS.TWITTER_DESCRIPTION,
  },
  [KEYS.JSON_LD]: {
    identifier: IDENTIFIERS.STRUCTURED_DATA,
  },
  [KEYS.CANONICAL]: {
    identifier: IDENTIFIERS.CANONICAL,
  },
  [KEYS.CUSTOM_TAGS]: {
    identifier: '',
  },
};

export const ERROR_NAMES = {
  ...LOGIC_ERROR_NAMES,
  URI_FORBIDDEN: 'URI_FORBIDDEN',
  URI_DUPLICATE: 'URI_DUPLICATE',
  INVALID_URL_CHARACTERS_LEGACY: 'INVALID_URL_CHARACTERS_LEGACY',
  INVALID_URI_LENGTH: 'INVALID_URI_LENGTH',
  STARTS_OR_ENDS_WITH_DASH: 'STARTS_OR_ENDS_WITH_DASH',
  HTML_TAG_INSIDE_SCRIPT: 'HTML_TAG_INSIDE_SCRIPT',
  SD_MAIN_OBJECT_IS_EMPTY: 'SD_MAIN_OBJECT_IS_EMPTY',
};

export const CANONICAL_SIMPLIFIED_LABEL = 'canonical';
export const OG_URL_SIMPLIFIED_LABEL = 'og:url';
