"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getData = void 0;
var ContextProps_1 = require("../../types/ContextProps");
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var sanitize_url_1 = require("../../utils/sanitize-url");
var getData = function (IDs, itemData) {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var postContent = ((_b = itemData === null || itemData === void 0 ? void 0 : itemData.postData) === null || _b === void 0 ? void 0 : _b.text) || '';
    return _a = {},
        _a[IDs.ID] = ((_c = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _c === void 0 ? void 0 : _c.id) || '',
        _a[IDs.INDEX_PAGE] = (_d = itemData === null || itemData === void 0 ? void 0 : itemData.context) === null || _d === void 0 ? void 0 : _d[ContextProps_1.CONTEXT_PROPS.INDEX_PAGE],
        _a[IDs.POST_CONTENT] = postContent,
        _a[IDs.POST_CONTENT_60] = postContent.substring(0, 60),
        _a[IDs.POST_CONTENT_200] = postContent.substring(0, 200),
        _a[IDs.GROUP_NAME] = ((_e = itemData === null || itemData === void 0 ? void 0 : itemData.group) === null || _e === void 0 ? void 0 : _e.title) || '',
        _a[IDs.POST_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.GROUPS_POST, itemData, (0, sanitize_url_1.sanitizeUrl)(itemData === null || itemData === void 0 ? void 0 : itemData.directUrl)),
        _a[IDs.POST_AUTHOR] = ((_g = (_f = itemData === null || itemData === void 0 ? void 0 : itemData.post) === null || _f === void 0 ? void 0 : _f.createdBy) === null || _g === void 0 ? void 0 : _g.name) || '',
        _a[IDs.POST_PUBLISH_DATE] = ((_h = itemData === null || itemData === void 0 ? void 0 : itemData.post) === null || _h === void 0 ? void 0 : _h.createdAt) || '',
        _a[IDs.POST_IMAGE_HEIGHT] = ((_l = (_k = (_j = itemData === null || itemData === void 0 ? void 0 : itemData.postData) === null || _j === void 0 ? void 0 : _j.images) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.image.height) || '',
        _a[IDs.POST_IMAGE_WIDTH] = ((_p = (_o = (_m = itemData === null || itemData === void 0 ? void 0 : itemData.postData) === null || _m === void 0 ? void 0 : _m.images) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.image.width) || '',
        _a[IDs.MAIN_IMAGE] = ((_t = (_s = (_r = (_q = itemData === null || itemData === void 0 ? void 0 : itemData.postData) === null || _q === void 0 ? void 0 : _q.images) === null || _r === void 0 ? void 0 : _r[0]) === null || _s === void 0 ? void 0 : _s.image.src) === null || _t === void 0 ? void 0 : _t.url) || '',
        _a;
};
exports.getData = getData;
