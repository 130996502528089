export enum DataHooks {
  SchemasItemContainer = 'schema-item-container',
  SchemaItemTitle = 'schema-item.display-name-label',
  SchemasAddButton = 'schemas-list-add-button',

  EditSchemaModalNameInput = 'edit-schema-modal-name-input',

  SchemaItemPopover = 'adv-seo.adv-panel.advanced-tags.schema-item-popover',
  SchemaItemPopoverButton = 'adv-seo.adv-panel.advanced-tags.schema-item-popover-button',
  PopoverEditButton = 'adv-seo.adv-panel.advanced-tags.schema-item-popover.edit-schema-menu-item',
}
