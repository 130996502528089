"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTickets = void 0;
var get_1 = require("../../../utils/get");
var getTickets = function (item) {
    if (item === void 0) { item = {}; }
    var tickets = (0, get_1.getRawValue)(item, 'tickets');
    if (!tickets || !(tickets instanceof Array)) {
        return [];
    }
    return tickets.map(function (_a) {
        var name = _a.name, free = _a.free, price = _a.price, finalPrice = _a.finalPrice, _b = _a.dashboard, dashboard = _b === void 0 ? {} : _b;
        return (__assign({ name: name, isFree: free, price: price === null || price === void 0 ? void 0 : price.amount, finalPrice: finalPrice === null || finalPrice === void 0 ? void 0 : finalPrice.amount }, (dashboard.limited ? { unsold: dashboard.unsold || 0 } : {})));
    });
};
exports.getTickets = getTickets;
