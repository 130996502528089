var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isEmpty } from '../../utils/is-empty';
import { renderToStaticMarkup } from '../../renderer/render-to-static-markup';
import { TAG_TYPES } from '../../types/TagTypes';
export var getCustomTagsArray = function (tags, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.filterDefaultTags, filterDefaultTags = _c === void 0 ? true : _c;
    if (isEmpty(tags)) {
        return [];
    }
    var customTags = [];
    tags.forEach(function (tag) {
        if (filterDefaultTags ? tag.custom && !tag.isDefault : tag.custom) {
            customTags.push(buildOutputTag(tag));
        }
    });
    return customTags;
};
function buildOutputTag(tag) {
    var staticMarkupList = renderToStaticMarkup({ tags: [tag] });
    var html = staticMarkupList && staticMarkupList.length > 0 ? staticMarkupList[0] : '';
    return __assign({ html: html }, getLabelValue(tag));
}
function getLabelValue(tag) {
    var result = {};
    switch (tag.type) {
        case TAG_TYPES.META:
            result.label =
                tag.props &&
                    (tag.props.name || tag.props.property || Object.values(tag.props)[0]);
            result.value =
                tag.props && (tag.props.content || Object.values(tag.props)[1]);
            break;
        case TAG_TYPES.LINK:
            result.label = tag.props && tag.props.rel;
            result.value = tag.props && tag.props.href;
            break;
        default:
            break;
    }
    return result;
}
