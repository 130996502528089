"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMainImageUrl = void 0;
var get_1 = require("../../../utils/get");
var build_og_image_preview_1 = require("../../../utils/build-og-image-preview");
var getMainImageUrl = function (item) {
    if (item === void 0) { item = {}; }
    return item.event &&
        (0, build_og_image_preview_1.buildOgImagePreview)((0, get_1.get)(item, 'event.mainImage.id'), (0, get_1.get)(item, 'event.mainImage.width'), (0, get_1.get)(item, 'event.mainImage.height'), 'fill');
};
exports.getMainImageUrl = getMainImageUrl;
