import { buildStructuredData } from '../../types/builders';
import adapter from './adapter-restaurants-menu-component';
import restaurantsMenuPageImagesSd from '../restaurants-menu-page/schema-presets/restaurants-menu-page-items-list-schema';
var IDs = adapter.IDs;
export var RESTAURANTS_MENU_COMPONENT = {
    tags: [
        buildStructuredData({
            schema: restaurantsMenuPageImagesSd.schema,
            schemaType: restaurantsMenuPageImagesSd.type,
            disabled: [
                "{{".concat(IDs.DISABLE_IMAGES_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ],
};
