"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSchema = exports.getRssRoute = exports.getRssFullURL = exports.getRssTitle = void 0;
var trim_trailing_slash_1 = require("../utils/trim-trailing-slash");
var consts_1 = require("./consts");
var get_verticals_rss_route_1 = require("./get-verticals-rss-route");
var getRssTitle = function (siteName) { return "".concat(siteName, " - RSS"); };
exports.getRssTitle = getRssTitle;
var getRssFullURL = function (_a) {
    var siteUrl = _a.siteUrl, rssRoute = _a.rssRoute;
    if (siteUrl) {
        return "".concat((0, trim_trailing_slash_1.trimTrailingSlash)(siteUrl), "/").concat(rssRoute);
    }
    else {
        return '';
    }
};
exports.getRssFullURL = getRssFullURL;
var getRssRoute = function (_a) {
    var _b = _a === void 0 ? {} : _a, rssRouteType = _b.rssRouteType, _c = _b.payload, payload = _c === void 0 ? {} : _c;
    switch (rssRouteType) {
        case consts_1.RSS_ROUTE_TYPES.BLOG:
            var _d = payload.item, item = _d === void 0 ? {} : _d;
            return (0, get_verticals_rss_route_1.getBlogFeedRoute)(item);
        default:
            break;
    }
};
exports.getRssRoute = getRssRoute;
var getSchema = function (tags, schemaType) {
    return tags.filter(function (tag) {
        return tag.type === 'script' && JSON.parse(tag.children)['@type'] === schemaType;
    });
};
exports.getSchema = getSchema;
