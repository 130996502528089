import React from 'react';
import { Checkbox, InfoIcon, Divider } from '@wix/wix-base-ui';
import classnames from 'classnames';
import styles from './robots-tag-common-components.scss';
import {
  useOnLinkClick,
  useOnTooltipOpen,
} from '../../../../core/components/robots-tag/robots-tag-hooks';
import { translate } from 'react-i18next';
import { bool, string, node, func } from 'prop-types';

export const Row = ({ children, first, last }) => (
  <>
    <div
      className={classnames(styles.row, {
        [styles.first]: first,
        [styles.last]: last,
      })}
    >
      {children}
    </div>
    {!last && <Divider long />}
  </>
);

Row.propTypes = {
  children: node,
  first: bool,
  last: bool,
};

export const CheckboxWithInfoIcon = translate()(
  ({
    dataHook,
    disabled,
    isChecked,
    onChange,
    label,
    infoText,
    infoTextBiName,
    helpCenterId,
    t,
  }) => {
    const onLinkClick = useOnLinkClick(infoTextBiName, helpCenterId);
    const onTooltipOpen = useOnTooltipOpen(infoTextBiName);

    return (
      <div className={styles.checkboxWrapper}>
        <Checkbox
          dataHook={dataHook}
          disabled={disabled}
          value={isChecked}
          onChange={onChange}
          labelAfterSymbol
          label={label}
          type="T02"
          shouldTranslate={false}
        />
        {infoText && (
          <InfoIcon
            className={styles.showWhenRowHovered}
            text={infoText}
            linkText={t('adv-seo.common.learn-more')}
            onTooltipOpen={onTooltipOpen}
            onLinkClick={onLinkClick}
            shouldTranslate={false}
          />
        )}
      </div>
    );
  },
);

CheckboxWithInfoIcon.propTypes = {
  dataHook: string,
  disabled: bool,
  isChecked: bool.isRequired,
  onChange: func.isRequired,
  label: string,
  infoText: string,
  infoTextBiName: string,
  helpCenterId: string,
};
