var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildOgMetaRefs, buildStructuredData, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-forum-post';
import { siteIDs } from '../../adapters/utils';
import questionsAndAnswersForumPostSchema from './schema-presets/forum-post-questions-and-answers';
var IDs = adapter.IDs;
export var FORUM_POST = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildStandardMeta('description', "{{".concat(IDs.CONTENT, "}}"))
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:url', "{{".concat(IDs.POST_PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'article'),
        buildOgMeta('og:image', "{{".concat(IDs.COVER_IMAGE, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.COVER_IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.COVER_IMAGE_WIDTH, "}}")),
        buildOgMeta('article:author', "{{".concat(IDs.AUTHOR, "}}")),
        buildOgMeta('article:published_time', "{{".concat(IDs.PUBLISH_DATE, "}}")),
        buildOgMeta('article:modified_time', "{{".concat(IDs.LAST_MODIFIED_DATE, "}}")),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.POST_PAGE_URL, "}}") }),
        buildLink({ rel: 'prev', href: "{{".concat(IDs.PREV_URL, "}}") }),
        buildLink({ rel: 'next', href: "{{".concat(IDs.NEXT_URL, "}}") }),
        buildStandardMeta('robots', 'index')
    ], false), buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: questionsAndAnswersForumPostSchema.schema,
            schemaType: questionsAndAnswersForumPostSchema.type,
            disabled: [
                '{{options.disable-pattern-schema}}',
                "{{".concat(IDs.QUESTIONS_AND_ANSWERS_SD_DISABLE_SCHEMA, "}}"),
            ],
        }),
    ], false),
};
