"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCalculatedData = void 0;
var get_location_type_1 = require("./get-location-type");
var get_event_status_1 = require("./get-event-status");
var get_attendence_mode_1 = require("./get-attendence-mode");
var get_main_image_url_1 = require("./get-main-image-url");
var get_seo_description_1 = require("./get-seo-description");
var get_online_conf_available_1 = require("./get-online-conf-available");
var get_event_url_1 = require("./get-event-url");
var get_tickets_1 = require("./get-tickets");
var get_event_month_1 = require("./get-event-month");
var ItemTypes_1 = require("../../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../../utils/resolve-item-type-multilingual-url");
var getCalculatedData = function (IDs, item) {
    var _a;
    if (item === void 0) { item = {}; }
    if (!IDs) {
        return {};
    }
    var tickets = (0, get_tickets_1.getTickets)(item);
    return _a = {},
        _a[IDs.CALCULATED_LOCATION_TYPE] = (0, get_location_type_1.getLocationType)(item),
        _a[IDs.CALCULATED_EVENT_STATUS] = (0, get_event_status_1.getEventStatus)(item),
        _a[IDs.CALCULATED_ATTENDANCE_MODE] = (0, get_attendence_mode_1.getAttendenceMode)(item),
        _a[IDs.MAIN_IMAGE] = (0, get_main_image_url_1.getMainImageUrl)(item),
        _a[IDs.CALCULATED_ONLINE_CONF_AVAILABLE] = (0, get_online_conf_available_1.getOnlineConfAvailable)(item),
        _a[IDs.CALCULATED_SEO_DESCRIPTION] = (0, get_seo_description_1.getSeoDescription)(item),
        _a[IDs.CALCULATED_EVENT_URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.EVENTS_PAGE, item, (0, get_event_url_1.getEventUrl)(item)),
        _a[IDs.CALCULATED_TICKETS] = tickets,
        _a[IDs.CALCULATED_TICKETS_COUNT] = tickets.length,
        _a[IDs.CALCULATED_EVENT_MONTH] = (0, get_event_month_1.getEventMonth)(item),
        _a;
};
exports.getCalculatedData = getCalculatedData;
