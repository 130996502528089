import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import { translate } from 'react-i18next';
import { Composites, RichText, Tooltip, GooglePreview } from '@wix/wix-base-ui';

import TooltipContent from '../ui/tooltip-content';
import * as BI_TYPES from '../../../core/bi/action-types';

class BasicPanelGooglePreview extends PureComponent {
  static propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    url: string.isRequired,
    logBiEvent: func.isRequired,
    openHelpCenter: func.isRequired,
    t: func.isRequired,
  };

  render() {
    const { title, description, url, t } = this.props;
    return (
      <Composites.GooglePreviewWithRichText>
        <RichText className="seo-advanced-client-google-preview-description">
          <span>{t('adv-seo.basic-panel.google-prev.label')}</span>
          <span>&nbsp;</span>
          <Tooltip
            openDelay={350}
            closeOnContentMouseClick={true}
            shouldTranslate={false}
            interactive={true}
            onOpen={this._onTooltipOpen}
            content={
              <TooltipContent
                text="adv-seo.basic-panel.google-prev.learn.tooltip.description"
                linkText="adv-seo.common.learn-more"
                onLinkClick={this._onLearnMore}
              />
            }
          >
            <a>{t('adv-seo.basic-panel.google-prev.learn')}</a>
          </Tooltip>
        </RichText>
        <GooglePreview
          className="seo-advanced-client-google-preview"
          title={title}
          url={this._removeDuplicateSlashes(url)}
          description={description}
          shouldTranslate={false}
          automationId="seo-advanced-client-google-preview"
        />
      </Composites.GooglePreviewWithRichText>
    );
  }
  _removeDuplicateSlashes = (url) => url.replace(/([^:]\/)\/+/g, '$1');
  _onTooltipOpen = () => {
    const { logBiEvent } = this.props;
    logBiEvent(BI_TYPES.PROMOTE_SEO_TOOLTIP_VIEW, {
      tooltipName: 'adv-seo.basic-panel.google-prev.learn.tooltip',
    });
  };

  _onLearnMore = () => {
    const { openHelpCenter, logBiEvent } = this.props;
    openHelpCenter('0aafdc03-cf40-40c1-8e72-fdcbd95d006c');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.basic-panel.google-prev.learn.tooltip',
      linkType: 'learn_more',
      linkSource: 'tooltip',
    });
  };
}

export default translate()(BasicPanelGooglePreview);
