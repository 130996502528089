import React from 'react';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import ProxyApp from './ProxyApp';

const ProxyIndex = (props) => (
  <ExperimentsProvider
    options={{
      scope: [
        'advanced-seo',
        'promote-seo-patterns',
        'promote-seo',
        'promote-seo-utils',
        'bm-apps-d70b68e2-8d77-4e0c-9c00-c292d6e0025e',
        'bm-apps-d6522ab5-d620-4771-b200-25d8ec9cc7ea',
        'bm-apps-6cafaa92-261b-49bd-812f-007825936ef2',
        'bm-apps-6068b800-84b9-4bc4-8b9a-dc3c0b74f727',
      ],
    }}
  >
    <ProxyApp {...props} />
  </ExperimentsProvider>
);

export default ProxyIndex;
