export function validateTagStructure(tag, tagSchema) {
    if (tag.type !== tagSchema.type) {
        return false;
    }
    if (!tagSchema.props) {
        return true;
    }
    if (!tag.props) {
        return false;
    }
    var requiredProps = Object.keys(tagSchema.props);
    return requiredProps.every(function (propName) {
        var _a, _b;
        if (tagSchema.props[propName]) {
            return (((_a = tag.props[propName]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                ((_b = tagSchema.props[propName]) === null || _b === void 0 ? void 0 : _b.toLowerCase()));
        }
        return typeof tag.props[propName] === 'string';
    });
}
