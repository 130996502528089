import { getSettingValueById, SETTING_IDS } from '@wix/advanced-seo-utils';

export const getPagesWithoutPreventAutoRedirects = ({ pagesAdvancedSeoData }) =>
  pagesAdvancedSeoData
    .filter(
      ({ advancedSeoData }) =>
        !getSettingValueById(
          advancedSeoData,
          SETTING_IDS.PREVENT_AUTO_REDIRECT,
        ),
    )
    .map(({ pageId }) => pageId);
