"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var ContextProps_1 = require("../../types/ContextProps");
var update_by_identifier_1 = require("../../tags/values/update-by-identifier");
var Identifiers_1 = require("../../types/Identifiers");
exports.IDs = {
    SITE_NAME: 'site.name',
    TITLE: 'stores-category.categoryName',
    DESCRIPTION: 'stores-category.categoryDesc',
    CATEGORY_PAGE_URL: 'stores-category.categoryUrl',
    VISIBLE_ITEMS: 'stores-category.numberOfItems',
    MAIN_IMAGE: 'stores-category.image.imageUrl',
    IMAGE_WIDTH: 'stores-category.image.imageWidth',
    IMAGE_HEIGHT: 'stores-category.image.imageHeight',
    IMAGE_ALT: 'stores-category.image.imageAlt',
    SLUG: 'stores-category.categorySlug',
};
var SchemaTypes = {
    STORES_CATEGORY_ITEM_LIST: 'stores-category-item-list-preset',
};
var userVisible = [
    exports.IDs.TITLE,
    exports.IDs.DESCRIPTION,
    exports.IDs.CATEGORY_PAGE_URL,
    exports.IDs.VISIBLE_ITEMS,
    exports.IDs.MAIN_IMAGE,
];
var getCategoryFullUrl = function (item) { return (0, get_1.get)(item, 'category.categoryUrl'); };
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        var data = (_a = {},
            _a[exports.IDs.SITE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME)),
            _a[exports.IDs.TITLE] = (0, get_1.get)(item, 'category.categoryName'),
            _a[exports.IDs.DESCRIPTION] = (0, get_1.get)(item, 'category.categoryDesc'),
            _a[exports.IDs.CATEGORY_PAGE_URL] = (0, get_1.get)(item, 'category.categoryUrl'),
            _a[exports.IDs.VISIBLE_ITEMS] = (0, get_1.get)(item, 'category.numberOfItems'),
            _a[exports.IDs.MAIN_IMAGE] = (0, get_1.get)(item, 'category.image.imageUrl'),
            _a[exports.IDs.IMAGE_WIDTH] = (0, get_1.get)(item, 'category.image.imageWidth'),
            _a[exports.IDs.IMAGE_HEIGHT] = (0, get_1.get)(item, 'category.image.imageHeight'),
            _a[exports.IDs.IMAGE_ALT] = (0, get_1.get)(item, 'category.image.imageAlt'),
            _a[exports.IDs.SLUG] = (0, get_1.get)(item, 'category.categorySlug'),
            _a[utils_1.siteIDs.PREV] = (_d = (_c = (_b = item === null || item === void 0 ? void 0 : item.category) === null || _b === void 0 ? void 0 : _b.pagination) === null || _c === void 0 ? void 0 : _c.prevUrl) !== null && _d !== void 0 ? _d : '',
            _a[utils_1.siteIDs.NEXT] = (_g = (_f = (_e = item === null || item === void 0 ? void 0 : item.category) === null || _e === void 0 ? void 0 : _e.pagination) === null || _f === void 0 ? void 0 : _f.nextUrl) !== null && _g !== void 0 ? _g : '',
            _a[utils_1.siteIDs.CURRENT_PAGE_NUMBER] = (_k = (_j = (_h = item === null || item === void 0 ? void 0 : item.category) === null || _h === void 0 ? void 0 : _h.pagination) === null || _j === void 0 ? void 0 : _j.currentPage) !== null && _k !== void 0 ? _k : '',
            _a[utils_1.siteIDs.TOTAL_PAGE_COUNT] = (_o = (_m = (_l = item === null || item === void 0 ? void 0 : item.category) === null || _l === void 0 ? void 0 : _l.pagination) === null || _m === void 0 ? void 0 : _m.totalPages) !== null && _o !== void 0 ? _o : '',
            _a);
        return __assign({}, data);
    },
    getSdStatus: function () { },
    getLegacySeoBlob: function (item) {
        var tags = (0, update_by_identifier_1.updateValueByIdentifier)([], Identifiers_1.IDENTIFIERS.TITLE, (0, get_1.get)(item, 'legacySeoData.title'));
        tags = (0, update_by_identifier_1.updateValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.DESCRIPTION, (0, get_1.get)(item, 'legacySeoData.description'));
        return { tags: tags };
    },
    getSlug: function (item) {
        return (0, get_1.get)(item, 'category.categorySlug');
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        if ((0, get_1.get)(updatedItemData, 'category.categorySlug') !== '') {
            updatedItemData.category.categorySlug = slugValue;
        }
        return updatedItemData;
    },
    getFullUrl: function (itemData) { return getCategoryFullUrl(itemData); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.category.categoryUrl = url;
        return itemData;
    },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
