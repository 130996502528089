"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMainImageUrl = void 0;
var get_1 = require("../../../../utils/get");
var build_og_image_preview_1 = require("../../../../utils/build-og-image-preview");
var getMainImageUrl = function (item) {
    if (item === void 0) { item = {}; }
    var imageUrl = (0, get_1.get)(item, 'program.settings.description.media.image.url');
    if (imageUrl.startsWith('media/')) {
        imageUrl = imageUrl.substring(6);
    }
    var width = (0, get_1.get)(item, 'program.settings.description.media.image.width');
    var height = (0, get_1.get)(item, 'program.settings.description.media.image.height');
    return (item.program &&
        (0, build_og_image_preview_1.buildOgImagePreview)(imageUrl, width !== '0' ? width : undefined, height !== '0' ? height : undefined, 'fill'));
};
exports.getMainImageUrl = getMainImageUrl;
