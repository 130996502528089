import { filterCustomTags } from '../filters/filter-custom-tags';
import { filterIdentifiedTags } from '../filters/filter-identified-tags';
import { getCanonical } from '../../../renderer';
import { updateValueBySchema } from '../values/update-by-schema';
import { GENERAL_IDENTIFIERS, IDENTIFIERS } from '../../types/Identifiers';
import { getSimplifiedTag } from './get-simplified-tag';
import { TAG_SCHEMAS } from '../../types/TagSchemas';
import { getDisabledByIdentifier } from '../values/get-by-identifier';
export var getSimplifiedTagsArray = function (advancedSeoData, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.excludeCustomTags, excludeCustomTags = _c === void 0 ? true : _c, _d = _b.keepDefaultTags, keepDefaultTags = _d === void 0 ? false : _d;
    var url = getCanonical(advancedSeoData);
    var isCanonicalDisabled = getDisabledByIdentifier(advancedSeoData, IDENTIFIERS.CANONICAL);
    var unidentifiedBlob = filterIdentifiedTags(advancedSeoData);
    var resolvedUnidentifiedBlob = excludeCustomTags
        ? filterCustomTags(unidentifiedBlob, { keepDefaultTags: keepDefaultTags })
        : unidentifiedBlob;
    var updatedTags = updateValueBySchema({
        tags: resolvedUnidentifiedBlob.tags,
        tagSchema: TAG_SCHEMAS[GENERAL_IDENTIFIERS.LINK]('canonical'),
        value: url,
        meta: false,
        isDisabled: isCanonicalDisabled,
    });
    var simplifiedTagsArray = updatedTags.map(getSimplifiedTag);
    return simplifiedTagsArray;
};
