// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S6q1mW{width:510px}.S6q1mW .L5RBoS{margin-left:12px}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `S6q1mW`,
	"apply-button": `L5RBoS`,
	"applyButton": `L5RBoS`
};
export default ___CSS_LOADER_EXPORT___;
