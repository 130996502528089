import { API_RESULT_STATUS, CREATE_REDIRECT_RESULT_STATUS } from './consts';
export var getResultStatus = function (result) {
    var _a, _b, _c;
    if ((result === null || result === void 0 ? void 0 : result.status) === API_RESULT_STATUS.FULFILLED) {
        return CREATE_REDIRECT_RESULT_STATUS.OK;
    }
    if (((_a = result === null || result === void 0 ? void 0 : result.reason) === null || _a === void 0 ? void 0 : _a.httpStatus) === 409 ||
        ((_c = (_b = result === null || result === void 0 ? void 0 : result.reason) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.status) === 409) {
        return CREATE_REDIRECT_RESULT_STATUS.CONFLICT;
    }
    return CREATE_REDIRECT_RESULT_STATUS.ERROR;
};
