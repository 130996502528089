import React, { useState, useEffect } from 'react';
import { object, func, shape } from 'prop-types';
import { translate, Interpolate } from 'react-i18next';
import classnames from 'classnames';
import {
  Edit,
  Hidden,
  Visible,
  ExternalLink,
  Delete,
  StatusWarningFilledSmall,
} from '@wix/wix-ui-icons-common';

import {
  Text,
  ContextMenu,
  ContextMenuAction,
  Tooltip,
  TextButton,
} from '@wix/wix-base-ui';

import styles from './schema-item.scss';
import { translateBySchemaType } from '../../../../core/app-base';
import { PROMOTE_SEO_PANEL_COMPONENT_VIEW } from '../../../../core/bi/action-types';
import { STRUCTURED_DATA_MARKUP } from '../../../../core/utils/consts';
import { TAB_NAMES } from '../../../tabs';
import { DataHooks } from './test-selectors';

const SchemaItem = ({ handlers, logBiEvent, schemaItem, t }) => {
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    logBiEvent(PROMOTE_SEO_PANEL_COMPONENT_VIEW, {
      field: schemaItem.displayName || schemaItem.schemaType,
      component: STRUCTURED_DATA_MARKUP,
      tab: TAB_NAMES.ADVANCED_TAB_NAME,
    });
  }, [logBiEvent, schemaItem.displayName, schemaItem.schemaType]);

  return (
    <div
      className={styles.root}
      data-hook={DataHooks.SchemasItemContainer}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <div
        data-hook={`schema-item-${schemaItem.schemaType}`}
        className={classnames(
          styles.labelContainer,
          schemaItem.shouldShowWarning ? styles.labelContainerWithWarning : '',
        )}
      >
        <div
          className={classnames(
            styles.icon,
            schemaItem.shouldShowWarning ? styles.disabledIcon : '',
          )}
        >
          {schemaItem.icon}
        </div>
        <div className={styles.name}>
          <Text
            weight="normal"
            secondary
            size="small"
            ellipsis
            dataHook={DataHooks.SchemaItemTitle}
            shouldTranslate={false}
          >
            {translateBySchemaType(
              `adv-seo.common.schema-items.title`,
              schemaItem.displayName || schemaItem.schemaType,
              t,
            )}{' '}
          </Text>
        </div>
        {schemaItem.shouldShowWarning && (
          <Tooltip
            className={styles.warningTooltip}
            shouldTranslate={false}
            interactive={true}
            maxWidth="228px"
            dataHook="schema-item-missing-data-warning"
            content={
              <Interpolate
                i18nKey="adv-seo.adv-panel.structured-data.warning.tooltip.label"
                useDangerouslySetInnerHTML
                generalSettingsBusinessInfo={
                  <TextButton
                    size="small"
                    underline="always"
                    skin="light"
                    as="button"
                    shouldTranslate={false}
                    onClick={handlers.navigateToSettings}
                  >
                    {t(
                      `adv-seo.adv-panel.structured-data.warning.tooltip.cta.text`,
                    )}
                  </TextButton>
                }
              />
            }
          >
            <StatusWarningFilledSmall />
          </Tooltip>
        )}
      </div>
      {showActions && !schemaItem.shouldShowWarning ? (
        <div className={styles.contextMenuButton}>
          <ContextMenu
            alignment="RIGHT"
            dataHook="schema-item.context-menu"
            className="flat"
            onToggle={(isOpen) => !isOpen && setShowActions(false)}
          >
            {schemaItem.custom && (
              <ContextMenuAction
                onClick={() => handlers.openEditModal(schemaItem)}
              >
                <Edit />
                <span className={styles.actionTitle}>
                  {t('adv-seo.adv-panel.structured-data.actions.edit')}
                </span>
              </ContextMenuAction>
            )}
            <ContextMenuAction onClick={() => handlers.testSchema(schemaItem)}>
              <ExternalLink />
              <span className={styles.actionTitle}>
                {t('adv-seo.adv-panel.structured-data.actions.test')}
              </span>
            </ContextMenuAction>
            {schemaItem.custom ? (
              <ContextMenuAction
                dataHook="schema-item.delete-button"
                onClick={() =>
                  handlers.openConfirmRemoveSchemaModal(schemaItem)
                }
              >
                <Delete />
                <span className={styles.actionTitle}>
                  {t('adv-seo.adv-panel.structured-data.actions.remove')}
                </span>
              </ContextMenuAction>
            ) : (
              <ContextMenuAction
                onClick={() => handlers.toggleHide(schemaItem)}
              >
                {schemaItem.disabled ? <Visible /> : <Hidden />}
                <span
                  className={styles.actionTitle}
                  data-hook="schema-item.toggle-button"
                >
                  {schemaItem.disabled
                    ? t('adv-seo.adv-panel.structured-data.actions.show')
                    : t('adv-seo.adv-panel.structured-data.actions.hide')}
                </span>
              </ContextMenuAction>
            )}
          </ContextMenu>
        </div>
      ) : (
        schemaItem.disabled &&
        !schemaItem.shouldShowWarning && (
          <span
            data-hook={`adv-seo.adv-panel.advanced-tags-${schemaItem.schemaType}-hidden-label`}
          >
            <Hidden
              className={classnames(styles.disabledIcon, styles.hiddenIcon)}
            />
          </span>
        )
      )}
    </div>
  );
};

SchemaItem.propTypes = {
  handlers: shape({
    openEditModal: func.isRequired,
    testSchema: func.isRequired,
    removeSchema: func.isRequired,
  }).isRequired,
  logBiEvent: func.isRequired,
  schemaItem: object.isRequired,
  t: func.isRequired,
};

export default translate(null)(SchemaItem);
