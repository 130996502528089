var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get, getRawValue } from '../utils/get';
import { CONTEXT_PROPS } from '../types/ContextProps';
import { buildOgImagePreviewUrl } from '../utils/build-og-image-preview';
import { calculateOpeningHoursSpecifications } from './calculated/calculate-opening-hours-specifications';
import { getRestaurantImages } from './calculated/get-resturants-images';
export var getKeys = function (IDs, userVisible) {
    var keysMetaData = Object.values(IDs).reduce(function (acc, val) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[val] = { userVisibility: userVisible.includes(val) }, _a)));
    }, {});
    return Object.values(IDs).map(function (key) { return (__assign({ key: key }, keysMetaData[key])); });
};
export var siteIDs = {
    SITE_NAME: 'site.name',
    PAGE_NAME: 'page.name',
    SITE_URL: 'site.url',
    SEARCH_PAGE_SLUG: 'site.search.slug',
    INDEX_SITE: 'site.index',
    ROBOTS_FROM_USER_PATTERN: 'userPatterns.robots',
    SITE_IMAGE: 'site.image',
    SITE_IMAGE_WIDTH: 'site.imageWidth',
    SITE_IMAGE_HEIGHT: 'site.imageHeight',
    FB_ADMINS: 'site.facebookAdminId',
    NEXT: 'site.nextLink',
    PREV: 'site.prevLink',
    CURRENT_PAGE_NUMBER: 'site.currentPageNumber',
    TOTAL_PAGE_COUNT: 'site.totalPageCount',
    BUSINESS_NAME: 'site.business.name',
    BUSINESS_LOCATION_COUNTRY: 'site.business.location.country',
    BUSINESS_LOCATION_STATE: 'site.business.location.state',
    BUSINESS_LOCATION_CITY: 'site.business.location.city',
    BUSINESS_LOCATION_STREET: 'site.business.location.street',
    BUSINESS_LOCATION_STREET_NUMBER: 'site.business.location.street.number',
    BUSINESS_LOCATION_STREET_ADDRESS: 'site.business.location.street.address',
    BUSINESS_LOCATION_DESCRIPTION: 'site.business.location.description',
    BUSINESS_LOCATION_FORMATTED: 'site.business.location.formatted',
    BUSINESS_LOCATION_LATITUDE: 'site.business.location.latitude',
    BUSINESS_LOCATION_LONGITUDE: 'site.business.location.longitude',
    BUSINESS_POSTAL_CODE: 'site.business.postal.code',
    BUSINESS_DESCRIPTION: 'site.business.description',
    BUSINESS_LOGO: 'site.business.logo',
    BUSINESS_SCHEMA_IMAGE: 'site.business.schema.image',
    BUSINESS_LOCALE: 'site.business.locale',
    BUSINESS_PHONE: 'site.business.phone',
    RESTAURANT_IMAGES: 'site.restaurant.images',
    EXPERIMENTS: 'site.experiments',
    OPENING_HOURS_SPECIFICATION: 'site.openingHoursSpecification',
};
var userVisibleSiteKeys = [
    siteIDs.SITE_NAME,
    siteIDs.BUSINESS_NAME,
    siteIDs.BUSINESS_LOCATION_COUNTRY,
    siteIDs.BUSINESS_LOCATION_STATE,
    siteIDs.BUSINESS_LOCATION_CITY,
    siteIDs.BUSINESS_LOCATION_STREET,
    siteIDs.BUSINESS_LOCATION_DESCRIPTION,
    siteIDs.BUSINESS_DESCRIPTION,
    siteIDs.BUSINESS_LOGO,
];
var userVisibleSdOnly = [siteIDs.SITE_URL];
export var getSiteData = function (item) {
    var _a, _b;
    var _c;
    var experiments = getExperiments(item);
    var enableRestaurantMenuPageRenderTime = (experiments === null || experiments === void 0 ? void 0 : experiments['specs.restaurants.renderMenusSEOTags']) === 'true';
    var data = (_a = {},
        _a[siteIDs.SITE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.SITE_NAME)),
        _a[siteIDs.PAGE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.PAGE_NAME)),
        _a[siteIDs.SITE_URL] = get(item, "context.".concat(CONTEXT_PROPS.SITE_URL)),
        _a[siteIDs.INDEX_SITE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_SITE)),
        _a[siteIDs.ROBOTS_FROM_USER_PATTERN] = getRawValue(item, "context.".concat(CONTEXT_PROPS.ROBOTS_FROM_USER_PATTERN)),
        _a[siteIDs.SITE_IMAGE] = get(item, "context.".concat(CONTEXT_PROPS.SITE_OG_IMAGE)),
        _a[siteIDs.SITE_IMAGE_WIDTH] = get(item, "context.".concat(CONTEXT_PROPS.OG_IMAGE_WIDTH)),
        _a[siteIDs.SITE_IMAGE_HEIGHT] = get(item, "context.".concat(CONTEXT_PROPS.OG_IMAGE_HEIGHT)),
        _a[siteIDs.FB_ADMINS] = get(item, "context.".concat(CONTEXT_PROPS.FB_ADMINS)),
        _a[siteIDs.NEXT] = get(item, "context.".concat(CONTEXT_PROPS.NEXT)),
        _a[siteIDs.PREV] = get(item, "context.".concat(CONTEXT_PROPS.PREV)),
        _a[siteIDs.BUSINESS_NAME] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_NAME)),
        _a[siteIDs.BUSINESS_LOCATION_COUNTRY] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_COUNTRY)),
        _a[siteIDs.BUSINESS_LOCATION_STATE] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_STATE)),
        _a[siteIDs.BUSINESS_LOCATION_CITY] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_CITY)),
        _a[siteIDs.BUSINESS_LOCATION_STREET] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_STREET)),
        _a[siteIDs.BUSINESS_LOCATION_STREET_NUMBER] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_STREET_NUMBER)),
        _a[siteIDs.BUSINESS_LOCATION_STREET_ADDRESS] = [
            get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_STREET_NUMBER)),
            get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_STREET)),
        ]
            .join(' ')
            .trim(),
        _a[siteIDs.BUSINESS_LOCATION_DESCRIPTION] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_DESCRIPTION)),
        _a[siteIDs.BUSINESS_LOCATION_FORMATTED] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_FORMATTED)),
        _a[siteIDs.BUSINESS_POSTAL_CODE] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_POSTAL_CODE)),
        _a[siteIDs.BUSINESS_DESCRIPTION] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_DESCRIPTION)),
        _a[siteIDs.BUSINESS_LOGO] = buildOgImagePreviewUrl({
            url: get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOGO)),
        }),
        _a[siteIDs.BUSINESS_SCHEMA_IMAGE] = (_c = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOGO))) !== null && _c !== void 0 ? _c : get(item, "context.".concat(CONTEXT_PROPS.SITE_OG_IMAGE)),
        _a[siteIDs.BUSINESS_LOCALE] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCALE)),
        _a[siteIDs.BUSINESS_PHONE] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_PHONE)),
        _a[siteIDs.EXPERIMENTS] = experiments,
        _a[siteIDs.BUSINESS_LOCATION_LATITUDE] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_COORDINATES, ".latitude")),
        _a[siteIDs.BUSINESS_LOCATION_LONGITUDE] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_LOCATION_COORDINATES, ".longitude")),
        _a);
    var restaurantsMenuPageData = enableRestaurantMenuPageRenderTime
        ? (_b = {},
            _b[siteIDs.RESTAURANT_IMAGES] = getRestaurantImages(item),
            _b[siteIDs.OPENING_HOURS_SPECIFICATION] = calculateOpeningHoursSpecifications(item),
            _b) : {};
    return __assign(__assign({}, data), restaurantsMenuPageData);
};
var getExperiments = function (item) {
    var seoExperiments = getRawValue(item, "context.".concat(CONTEXT_PROPS.EXPERIMENTS));
    var platformAppsExperiments = getRawValue(item, "context.".concat(CONTEXT_PROPS.PLATFORM_APPS_EXPERIMENTS));
    if (!seoExperiments && !platformAppsExperiments) {
        return undefined;
    }
    if (platformAppsExperiments) {
        platformAppsExperiments = Object.values(platformAppsExperiments).reduce(function (acc, experiments) { return (__assign(__assign({}, acc), experiments)); }, {});
    }
    return __assign(__assign({}, platformAppsExperiments), seoExperiments);
};
var getMissingSchemaVariables = function (requiredSchemaVariables) {
    return function (item, itemData) {
        if (itemData === void 0) { itemData = {}; }
        var siteData = getSiteData(item);
        return requiredSchemaVariables.filter(function (variable) { return !(siteData[variable] || itemData[variable]); });
    };
};
export var hasMissingSchemaVariables = function (requiredSchemaVariables, item, itemData) {
    return getMissingSchemaVariables(requiredSchemaVariables)(item, itemData).length > 0;
};
export function enrichWithSiteLevelData(adapter) {
    var IDs = __assign(__assign({}, siteIDs), adapter.IDs);
    return __assign(__assign({ requiredSchemaVariables: [] }, adapter), { IDs: IDs, getMissingSchemaVariables: getMissingSchemaVariables(adapter.requiredSchemaVariables), getData: function (item) { return (__assign(__assign({}, getSiteData(item)), adapter.getData(item))); }, getKeys: function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.exposeBusinessKeys, exposeBusinessKeys = _c === void 0 ? false : _c;
            return __spreadArray(__spreadArray([], (exposeBusinessKeys ? getKeys(siteIDs, userVisibleSiteKeys) : []), true), adapter.getKeys(), true);
        }, getSdKeys: function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.exposeBusinessKeys, exposeBusinessKeys = _c === void 0 ? false : _c;
            return __spreadArray(__spreadArray([], (exposeBusinessKeys ? getKeys(siteIDs, userVisibleSdOnly) : []), true), adapter.getSdKeys(), true);
        } });
}
