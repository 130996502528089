import adapter from '../adapter-stores-product';
import { siteIDs } from '../../../adapters/utils';
var IDs = adapter.IDs, SchemaTypes = adapter.SchemaTypes;
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: "{{".concat(IDs.TITLE, "}}"),
    description: "{{".concat(IDs.DESCRIPTION, "}}"),
    sku: "{{".concat(IDs.SKU, "}}"),
    brand: {
        '@type': 'Brand',
        name: "{{".concat(IDs.BRAND, "}}"),
    },
    image: "{{".concat(IDs.SD_JSON_IMAGES, "}}"),
    Offers: {
        '@type': 'Offer',
        url: "{{".concat(IDs.PRODUCT_PAGE_URL, "}}"),
        priceCurrency: "{{".concat(IDs.CURRENCY, "}}"),
        price: "{{".concat(IDs.PRICE, "}}"),
        Availability: "{{".concat(IDs.AVAILABILITY_SCHEMA, "}}"),
        seller: {
            '@type': 'Organization',
            name: "{{".concat(IDs.BUSINESS_NAME, "}}"),
            sameAs: "{{".concat(siteIDs.SITE_URL, "}}"),
        },
    },
};
export default {
    type: SchemaTypes.STORES_PRODUCT_PAGE,
    schema: schema,
};
