"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveStructuredData = void 0;
var should_render_panel_schema_1 = require("./utils/should-render-panel-schema");
var resolve_panel_schemas_1 = require("./utils/resolve-panel-schemas");
var resolveStructuredData = function (tags, context) {
    if (tags === void 0) { tags = []; }
    if (context === void 0) { context = {}; }
    var schemasSetInPanels = {};
    return __spreadArray(__spreadArray([], tags.reduce(function (resolvedTags, tag) {
        var _a;
        var isPanelsSchema = (_a = tag === null || tag === void 0 ? void 0 : tag.meta) === null || _a === void 0 ? void 0 : _a.schemaType;
        if (isPanelsSchema) {
            if ((0, should_render_panel_schema_1.shouldRenderPanelSchema)(tag, context)) {
                schemasSetInPanels = (0, resolve_panel_schemas_1.resolvePanelSchemas)(schemasSetInPanels, tag);
            }
            return resolvedTags;
        }
        return __spreadArray(__spreadArray([], resolvedTags, true), [tag], false);
    }, []), true), Object.values(schemasSetInPanels), true);
};
exports.resolveStructuredData = resolveStructuredData;
