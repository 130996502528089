/* eslint-disable prettier/prettier */
var _a;
import { render } from './render';
import { TAG_TYPES } from '../types/TagTypes';
import { escapeHtml } from "../utils/escape-html";
import PROPS from '../types/Props';
var SELF_CLOSING_TAGS = (_a = {},
    _a[TAG_TYPES.LINK] = true,
    _a[TAG_TYPES.META] = true,
    _a);
var ATTRIBUTE_NAME_START_CHAR = ':A-Z_a-z\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u02FF\\u0370-\\u037D\\u037F-\\u1FFF\\u200C-\\u200D\\u2070-\\u218F\\u2C00-\\u2FEF\\u3001-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFFD';
var ATTRIBUTE_NAME_CHAR = ATTRIBUTE_NAME_START_CHAR + '\\-.0-9\\u00B7\\u0300-\\u036F\\u203F-\\u2040';
var VALID_ATTRIBUTE_NAME_REGEX = new RegExp("^[".concat(ATTRIBUTE_NAME_START_CHAR, "][").concat(ATTRIBUTE_NAME_CHAR, "]*$"));
/**
 * Renders given tag list to an array of HTML strings.
 * If tag is not supported, then it will be ommited from results.
 *
 * @example
 * [
 *  '<title>It works on my machine</title>',
 *  '<meta name="description" content="Life would be so much easier if we only had the source code"/>
 * ]
 * @see render function implemention for more details.
 * @param {Object} tags to render.
 */
export function renderToStaticMarkup(_a, _b) {
    var _c = _a === void 0 ? {} : _a, tags = _c.tags;
    var _d = _b === void 0 ? {} : _b, _e = _d.allowDisabled, allowDisabled = _e === void 0 ? false : _e, _f = _d.logError, logError = _f === void 0 ? function () { } : _f, _g = _d.enablePropSort, enablePropSort = _g === void 0 ? false : _g;
    var sanitizedTags = sanitizeTags(tags, allowDisabled);
    return render(sanitizedTags, { logError: logError })
        .map(function (element) {
        var type = element.type, props = element.props;
        var htmlAttributes = Object.keys(props)
            .filter(function (prop) { return !['children'].includes(prop) && prop.match(VALID_ATTRIBUTE_NAME_REGEX) && prop !== PROPS.CONTENT; })
            .concat(props.content ? PROPS.CONTENT : [])
            .map(function (prop) { return "".concat(prop, "=\"").concat(escapeHtml(props[prop]), "\""); })
            .join(' ');
        var html = "<".concat(type);
        if (htmlAttributes) {
            html = "".concat(html, " ").concat(htmlAttributes);
        }
        if (SELF_CLOSING_TAGS[type]) {
            html = "".concat(html, "/>");
        }
        else {
            html = "".concat(html, ">").concat(renderChildren(props, type), "</").concat(type, ">");
        }
        return html;
    });
}
function sanitizeTags(tags, allowDisabled) {
    if (tags === void 0) { tags = []; }
    if (!Array.isArray(tags) || allowDisabled) {
        return tags;
    }
    return tags.filter(function (tag) { return !tag.disabled || tag.disabled === 'false'; });
}
function renderChildren(props, type) {
    if (props && typeof props.children === 'string') {
        return type === 'script' ? props.children : escapeHtml(props.children);
    }
    return '';
}
