"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildBlogPostSchema = void 0;
var schema_ids_1 = require("./schema-ids");
var utils_1 = require("../../../adapters/utils");
var buildBlogPostSchema = function (_a) {
    var schemaType = _a.schemaType, type = _a.type;
    return ({
        type: schemaType,
        schema: {
            '@context': 'https://schema.org',
            '@type': type,
            author: {
                '@type': 'Person',
                name: "{{".concat(schema_ids_1.IDs.AUTHOR, "}}"),
                url: "{{".concat(schema_ids_1.IDs.AUTHOR_URL, "}}"),
            },
            dateModified: "{{".concat(schema_ids_1.IDs.LAST_MODIFIED_DATE, "}}"),
            datePublished: "{{".concat(schema_ids_1.IDs.PUBLISH_DATE, "}}"),
            headline: "{{".concat(schema_ids_1.IDs.HEADLINE, "}}"),
            mainEntityOfPage: {
                '@id': "{{".concat(schema_ids_1.IDs.POST_PAGE_URL, "}}"),
                '@type': 'itemPage',
                url: "{{".concat(schema_ids_1.IDs.POST_PAGE_URL, "}}"),
            },
            description: "{{".concat(schema_ids_1.IDs.DESCRIPTION, "}}"),
            url: "{{".concat(schema_ids_1.IDs.POST_PAGE_URL, "}}"),
            image: {
                '@type': 'ImageObject',
                url: "{{".concat(schema_ids_1.IDs.COVER_IMAGE, "}}"),
                width: "{{".concat(schema_ids_1.IDs.COVER_IMAGE_WIDTH, "}}"),
                height: "{{".concat(schema_ids_1.IDs.COVER_IMAGE_HEIGHT, "}}"),
            },
            publisher: {
                '@type': 'Organization',
                name: "{{".concat(utils_1.siteIDs.SITE_NAME, "}}"),
                url: "{{".concat(utils_1.siteIDs.SITE_URL, "}}"),
            },
        },
        disabled: "{{".concat(schema_ids_1.IDs.SD_DISABLE_SCHEMA, "}"),
    });
};
exports.buildBlogPostSchema = buildBlogPostSchema;
