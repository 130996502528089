export const PROMOTE_SEO_TWITTER_SHARE_SOCIAL_SECTION_NAME =
  'twitter_share_social';

export const PROMOTE_SEO_STRUCTURED_DATA_SECTION_NAME =
  'structured_data_markup';

export const PROMOTE_SEO_ROBOTS_SECTION_NAME = 'robots_meta_tag';

export const PROMOTE_SEO_ADDITIONAL_TAGS_SECTION_NAME = 'additional_tags';

export const PROMOTE_SEO_SECTION_EXPAND = 'expand';

export const PROMOTE_SEO_SECTION_COLLAPSE = 'collapse';

export const PROMOTE_SEO_LINK_GO_TO_ADVANCED_TAB = 'go to advanced tab';

export const PROMOTE_SEO_LINK_GO_TO_SOCIAL_TAB = 'go to social tab';

export const PROMOTE_SEO_LINK_EXPAND_ROBOTS_TAG = 'expand robots tag';

export const PROMOTE_SEO_LINK_EXPAND_META_TAGS = 'expand meta tags';

export const BI_FIELD_ACTION = {
  CREATED: 'created',
  DELETED: 'deleted',
  UPDATED: 'updated',
  SET_TO_INDEX: 'set_to_index',
  SET_TO_NO_INDEX: 'set_to_no_index',
};
