var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { get, getRawValue } from '../../utils/get';
import { getKeys, siteIDs } from '../../adapters/utils';
import { updateValueByIdentifier } from '../../tags/values/update-by-identifier';
import { IDENTIFIERS } from '../../types/Identifiers';
import { getCurrentLanguage, getCurrentSeoLang, } from '../blog-post/calculated/getCurrentLanguage';
import { getPageUrl } from './calculated/getPageUrl';
import { getMultilingualTags } from '../../renderer/get-multilingual-tags';
import { SD_STATUS } from '../../consts';
import { getRssFullURL } from '../utils';
import { RSS_ROUTES, RSS_ROUTE_TYPES } from '../consts';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
var IDs = {
    TITLE: 'blog-category.name',
    DESCRIPTION: 'blog-category.description',
    MAIN_IMAGE: 'blog-category.image',
    IMAGE_HEIGHT: 'blog-category.image.height',
    IMAGE_WIDTH: 'blog-category.image.width',
    PAGE_URL: 'blog-category.url',
    PREV_PAGE_URL: 'blog-category.prevUrl',
    NEXT_PAGE_URL: 'blog-category.nextUrl',
    CURRENT_PAGE: 'blog-category.currentPage',
    TOTAL_PAGE_COUNT: 'blog-category.totalPageCount',
    BLOG_FEED_ROUTE: 'blog-category.blogFeedRoute',
};
var userVisible = [
    IDs.TITLE,
    IDs.TOTAL_PAGE_COUNT,
    IDs.CURRENT_PAGE,
    IDs.DESCRIPTION,
    IDs.PAGE_URL,
    IDs.MAIN_IMAGE,
];
var requiredSchemaVariables = [];
var SchemaTypes = {
    BLOG_CATEGORY_COLLECTION: 'blog-category-collection-preset',
};
var getImageData = function (item) {
    var _a;
    var hasImage = getRawValue(item, 'category.mainImage.hasImage');
    return _a = {},
        _a[IDs.MAIN_IMAGE] = hasImage ? get(item, 'category.mainImage.url') : '',
        _a[IDs.IMAGE_HEIGHT] = hasImage ? get(item, 'category.mainImage.height') : '',
        _a[IDs.IMAGE_WIDTH] = hasImage ? get(item, 'category.mainImage.width') : '',
        _a;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        return __assign(__assign((_a = {}, _a[IDs.TITLE] = get(item, 'category.label'), _a[IDs.DESCRIPTION] = get(item, 'category.description'), _a), getImageData(item)), (_b = {}, _b[IDs.PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BLOG_CATEGORY, item, getPageUrl(item)), _b[IDs.PREV_PAGE_URL] = get(item, 'category.prevUrl'), _b[IDs.NEXT_PAGE_URL] = get(item, 'category.nextUrl'), _b[IDs.CURRENT_PAGE] = get(item, 'category.pagination.currentPage'), _b[IDs.TOTAL_PAGE_COUNT] = get(item, 'category.pagination.totalPages'), _b[IDs.BLOG_FEED_ROUTE] = get(item, 'category.blogFeedRoute') ||
            getRssFullURL({
                siteUrl: get(item, 'context.siteUrl'),
                rssRoute: RSS_ROUTES[RSS_ROUTE_TYPES.BLOG],
            }), _b[siteIDs.PREV] = get(item, 'category.prevUrl'), _b[siteIDs.NEXT] = get(item, 'category.nextUrl'), _b[siteIDs.CURRENT_PAGE_NUMBER] = get(item, 'category.pagination.currentPage'), _b[siteIDs.TOTAL_PAGE_COUNT] = get(item, 'category.pagination.totalPages'), _b));
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getLegacySeoBlob: function (item) {
        var tags = updateValueByIdentifier([], IDENTIFIERS.TITLE, get(item, 'category.title'));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION, get(item, 'category.description'));
        return { tags: tags };
    },
    getDynamicPatternsSchema: function (item) {
        var translatedPages = getRawValue(item, 'category.translatedPages') || [];
        var hasTranslations = Boolean(translatedPages.length);
        var currentLanguageCode = getCurrentLanguage(item);
        var currentSeoLang = getCurrentSeoLang(item);
        var currentUrl = getPageUrl(item);
        if (!item || !hasTranslations) {
            return { tags: [] };
        }
        var siteUrl = get(item, 'context.siteUrl');
        var tags = getMultilingualTags(__spreadArray(__spreadArray([
            {
                url: currentUrl,
                langCode: currentLanguageCode,
                seoLang: currentSeoLang,
            }
        ], translatedPages, true), [
            { url: siteUrl, langCode: 'x-default' },
        ], false));
        return { tags: tags };
    },
    getSlug: function (item) { return get(item, 'category.slug'); },
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        item.category.path = url;
        item.category.canonicalUrl = url;
        return item;
    },
    getFullUrl: function (item) { return get(item, 'category.canonicalUrl'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
    hasMultilingual: function (item) {
        return Array.isArray(getRawValue(item, 'category.translatedPages'));
    },
};
