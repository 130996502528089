import { getLocationType } from './get-location-type';
import { getEventStatus } from './get-event-status';
import { getAttendenceMode } from './get-attendence-mode';
import { getMainImageUrl } from './get-main-image-url';
import { getSeoDescription } from './get-seo-description';
import { getOnlineConfAvailable } from './get-online-conf-available';
import { getEventUrl } from './get-event-url';
import { getTickets } from './get-tickets';
import { getEventMonth } from './get-event-month';
import { ITEM_TYPES } from '../../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../../utils/resolve-item-type-multilingual-url';
export var getCalculatedData = function (IDs, item) {
    var _a;
    if (item === void 0) { item = {}; }
    if (!IDs) {
        return {};
    }
    var tickets = getTickets(item);
    return _a = {},
        _a[IDs.CALCULATED_LOCATION_TYPE] = getLocationType(item),
        _a[IDs.CALCULATED_EVENT_STATUS] = getEventStatus(item),
        _a[IDs.CALCULATED_ATTENDANCE_MODE] = getAttendenceMode(item),
        _a[IDs.MAIN_IMAGE] = getMainImageUrl(item),
        _a[IDs.CALCULATED_ONLINE_CONF_AVAILABLE] = getOnlineConfAvailable(item),
        _a[IDs.CALCULATED_SEO_DESCRIPTION] = getSeoDescription(item),
        _a[IDs.CALCULATED_EVENT_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.EVENTS_PAGE, item, getEventUrl(item)),
        _a[IDs.CALCULATED_TICKETS] = tickets,
        _a[IDs.CALCULATED_TICKETS_COUNT] = tickets.length,
        _a[IDs.CALCULATED_EVENT_MONTH] = getEventMonth(item),
        _a;
};
