var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { identify } from './utils/identify';
export var transformCustomTagsToIdentified = function (tags, identifiers) {
    if (!tags || !identifiers) {
        return tags;
    }
    return tags.map(function (tag) {
        if (identifiers.includes(identify(tag))) {
            var modifiedTag = __assign({}, tag);
            delete modifiedTag.custom;
            return modifiedTag;
        }
        return tag;
    });
};
