import React from 'react';
import { number, string, object } from 'prop-types';

export function Adjust({ color, width, height, style }) {
  return (
    <svg viewBox="0 0 20 20" width={width} height={height} style={style}>
      <g transform="translate(4 5)" fill="none">
        <path fill={color} d="M0 1h12v1H0z" />
        <circle fill={color} cx="8.5" cy="1.5" r="1.5" />
        <path fill="#FFF" d="M8 1h1v1H8z" />
        <path fill={color} d="M0 1h12v1H0zM0 5h12v1H0z" />
        <circle fill={color} cx="8.5" cy="1.5" r="1.5" />
        <circle fill={color} cx="3.5" cy="5.5" r="1.5" />
        <path fill="#FFF" d="M8 1h1v1H8zM3 5h1v1H3z" />
        <path fill={color} d="M0 9h12v1H0z" />
        <circle fill={color} cx="8.5" cy="9.5" r="1.5" />
        <path fill="#FFF" d="M8 9h1v1H8z" />
      </g>
    </svg>
  );
}

Adjust.propTypes = {
  width: number,
  height: number,
  color: string,
  style: object,
};

Adjust.defaultProps = {
  width: 20,
  height: 20,
  color: 'currentColor',
  style: {},
};
