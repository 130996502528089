var _a;
import { ITEM_TYPES } from '../types/ItemTypes';
import { DEFAULT } from '../item-types/default/pattern-default';
import { STATIC_PAGE } from '../item-types/static-page/pattern-static-page';
import { STATIC_PAGE_V2 } from '../item-types/static-page-v2/pattern-static-page-v2';
import { STORES_PRODUCT } from '../item-types/stores-product/pattern-stores-product';
import { FORUM_POST } from '../item-types/forum-post/pattern-forum-post';
import { FORUM_CATEGORY } from '../item-types/forum-category/pattern-forum-category';
import { BLOG_POST } from '../item-types/blog-post/pattern-blog-post';
import { BLOG_CATEGORY } from '../item-types/blog-category/pattern-blog-category';
import { BLOG_TAGS } from '../item-types/blog-tags/pattern-blog-tags';
import { BLOG_ARCHIVE } from '../item-types/blog-archive/pattern-blog-archive';
import { PRO_GALLERY_ITEM } from '../item-types/pro-gallery-item/pattern-pro-gallery-item';
import { GROUPS_POST } from '../item-types/groups-post/pattern-groups-post';
import GROUPS_PAGE from '../item-types/groups-page/pattern-groups-page';
import { EVENTS_PAGE } from '../item-types/events-page/pattern-events-page';
import { CHALLENGES_PAGE } from '../item-types/challenges-page/pattern-challenges-page';
import { BOOKINGS_SERVICE } from '../item-types/bookings-service/pattern-bookings-service';
import { BREADCRUMBS_COMPONENT } from '../item-types/breadcrumbs-component/pattern-breadcrumbs-component';
import { SEARCH_PAGE } from '../item-types/search-page/pattern-search-page';
import { BLOG_HASHTAGS } from '../item-types/blog-hashtags/pattern-blog-hashtags';
import { BOOKINGS_CALENDAR } from '../item-types/bookings-calendar/pattern-bookings-calendar';
import { RESTAURANTS_ORDER_PAGE } from '../item-types/restaurants-order-page/pattern-restaurants-order-page';
import { BOOKINGS_FORM } from '../item-types/bookings-form/pattern-bookings-form';
import { VIDEO_COMPONENT } from '../item-types/video-component/pattern-video-component';
import { MEMBERS_AREA_PROFILE } from '../item-types/members-area-profile/pattern-members-area-profile';
import { PORTFOLIO_COLLECTIONS } from '../item-types/portfolio-collections/pattern-portfolio-collections';
import { PORTFOLIO_PROJECTS } from '../item-types/portfolio-projects/pattern-portfolio-projects';
import { GIFT_CARD } from '../item-types/gift-card/pattern-gift-card';
import { SCHEDULE_PAGE } from '../item-types/schedule-page/pattern-schedule-page';
import { WIX_DATA_PAGE_ITEM } from '../item-types/wix-data-page-item/pattern-wix-data-page-item';
import { REVIEWS_COMPONENT } from '../item-types/reviews-component/pattern-reviews-component';
import { STORES_CATEGORY } from '../item-types/stores-category/pattern-stores-category';
import { STORES_GALLERY_COMPONENT } from '../item-types/stores-gallery-component/pattern-stores-gallery-component';
import { RESTAURANTS_MENU_PAGE } from '../item-types/restaurants-menu-page/pattern-restaurants-menu-page';
import { MEMBERS_AREA_PROFILE_TABS } from '../item-types/members-area-profile-tabs/pattern-members-area-profile-tabs';
import { PROGRAMS_COMPONENT } from '../item-types/programs-component/pattern-programs-component';
import { SERVICES_COMPONENT } from '../item-types/services-component/pattern-services-component';
import { PAYMENT_PAGE } from '../item-types/payment-page/pattern-payment-page';
import { THANK_YOU_PAGE } from '../item-types/thank-you-page/pattern-thank-you-page';
import { RESTAURANTS_MENU_COMPONENT } from '../item-types/restaurants-menu-component/pattern-restaurants-menu-component';
export var PATTERNS = (_a = {},
    _a[ITEM_TYPES.STATIC_PAGE] = STATIC_PAGE,
    _a[ITEM_TYPES.STATIC_PAGE_V2] = STATIC_PAGE_V2,
    _a[ITEM_TYPES.STORES_PRODUCT] = STORES_PRODUCT,
    _a[ITEM_TYPES.FORUM_POST] = FORUM_POST,
    _a[ITEM_TYPES.FORUM_CATEGORY] = FORUM_CATEGORY,
    _a[ITEM_TYPES.BLOG_POST] = BLOG_POST,
    _a[ITEM_TYPES.BLOG_CATEGORY] = BLOG_CATEGORY,
    _a[ITEM_TYPES.BLOG_TAGS] = BLOG_TAGS,
    _a[ITEM_TYPES.BLOG_ARCHIVE] = BLOG_ARCHIVE,
    _a[ITEM_TYPES.PRO_GALLERY_ITEM] = PRO_GALLERY_ITEM,
    _a[ITEM_TYPES.GROUPS_POST] = GROUPS_POST,
    _a[ITEM_TYPES.GROUPS_PAGE] = GROUPS_PAGE,
    _a[ITEM_TYPES.EVENTS_PAGE] = EVENTS_PAGE,
    _a[ITEM_TYPES.CHALLENGES_PAGE] = CHALLENGES_PAGE,
    _a[ITEM_TYPES.SEARCH_PAGE] = SEARCH_PAGE,
    _a[ITEM_TYPES.BOOKINGS_SERVICE] = BOOKINGS_SERVICE,
    _a[ITEM_TYPES.BREADCRUMBS_COMPONENT] = BREADCRUMBS_COMPONENT,
    _a[ITEM_TYPES.DEFAULT] = DEFAULT,
    _a[ITEM_TYPES.BLOG_HASHTAGS] = BLOG_HASHTAGS,
    _a[ITEM_TYPES.BOOKINGS_CALENDAR] = BOOKINGS_CALENDAR,
    _a[ITEM_TYPES.BOOKINGS_FORM] = BOOKINGS_FORM,
    _a[ITEM_TYPES.RESTAURANTS_ORDER_PAGE] = RESTAURANTS_ORDER_PAGE,
    _a[ITEM_TYPES.VIDEO_COMPONENT] = VIDEO_COMPONENT,
    _a[ITEM_TYPES.MEMBERS_AREA_PROFILE] = MEMBERS_AREA_PROFILE,
    _a[ITEM_TYPES.PORTFOLIO_COLLECTIONS] = PORTFOLIO_COLLECTIONS,
    _a[ITEM_TYPES.PORTFOLIO_PROJECTS] = PORTFOLIO_PROJECTS,
    _a[ITEM_TYPES.GIFT_CARD] = GIFT_CARD,
    _a[ITEM_TYPES.SCHEDULE_PAGE] = SCHEDULE_PAGE,
    _a[ITEM_TYPES.WIX_DATA_PAGE_ITEM] = WIX_DATA_PAGE_ITEM,
    _a[ITEM_TYPES.REVIEWS_COMPONENT] = REVIEWS_COMPONENT,
    _a[ITEM_TYPES.STORES_CATEGORY] = STORES_CATEGORY,
    _a[ITEM_TYPES.STORES_GALLERY_COMPONENT] = STORES_GALLERY_COMPONENT,
    _a[ITEM_TYPES.RESTAURANTS_MENU_PAGE] = RESTAURANTS_MENU_PAGE,
    _a[ITEM_TYPES.MEMBERS_AREA_PROFILE_TABS] = MEMBERS_AREA_PROFILE_TABS,
    _a[ITEM_TYPES.PROGRAMS_COMPONENT] = PROGRAMS_COMPONENT,
    _a[ITEM_TYPES.SERVICES_COMPONENT] = SERVICES_COMPONENT,
    _a[ITEM_TYPES.PAYMENT_PAGE] = PAYMENT_PAGE,
    _a[ITEM_TYPES.THANK_YOU_PAGE] = THANK_YOU_PAGE,
    _a[ITEM_TYPES.RESTAURANTS_MENU_COMPONENT] = RESTAURANTS_MENU_COMPONENT,
    _a);
