var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-groups-post';
import { siteIDs } from '../../adapters/utils';
var IDs = adapter.IDs;
export var GROUPS_POST = {
    tags: __spreadArray([
        buildTitle("{{".concat(IDs.POST_CONTENT_60, "}} | {{").concat(IDs.GROUP_NAME, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildStandardMeta('description', "{{".concat(IDs.POST_CONTENT_200, "}}")),
        buildStandardMeta('robots', 'index'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.POST_URL, "}}") }),
        buildOgMeta('og:title', "{{".concat(IDs.POST_CONTENT_60, "}}")),
        buildOgMeta('og:description', "{{".concat(IDs.POST_CONTENT_200, "}}")),
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.POST_IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.POST_IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:type', 'article'),
        buildOgMeta('og:url', "{{".concat(IDs.POST_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('article:published_time', "{{".concat(IDs.POST_PUBLISH_DATE, "}}"))
    ], buildTwitterMetaRefs(), true),
};
