import React from 'react';
import ReactDOM from 'react-dom';

export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.display = 'contents';
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    document.addEventListener('mousedown', this._handleClickOutside);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
    document.removeEventListener('mousedown', this._handleClickOutside);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }

  _handleClickOutside = (event) => {
    if (this.el && !this.el.contains(event.target)) {
      this.props.onClickOutside && this.props.onClickOutside();
    }
  };
}
