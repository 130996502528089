import { isEmpty } from '../../utils/is-empty';
import { identify } from '../../renderer/utils/identify';
import { getValueByIdentifier } from './get-by-identifier';
import { TAG_SCHEMAS } from '../../types/TagSchemas';
export function getValueFromHtmlTag(htmlTag, identifier) {
    if (!htmlTag || !TAG_SCHEMAS[identifier]) {
        return null;
    }
    var isTagValid = !isEmpty(htmlTag) && identify(htmlTag) === identifier;
    return isTagValid ? getValueByIdentifier([htmlTag], identifier) : null;
}
