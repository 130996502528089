import React, { PureComponent } from 'react';
import { func, object, string } from 'prop-types';
import { translate } from 'react-i18next';
import { Button, Composites, TextLabel, RichText } from '@wix/wix-base-ui';
import { tWithWizRename } from '../../utils/translate-with-wiz-rename';
import * as BI_TYPES from '../../../core/bi/action-types';

class BasicPanelSeoWiz extends PureComponent {
  static propTypes = {
    t: func.isRequired,
    wixSeoUrl: string.isRequired,
    logBiEvent: func.isRequired,
    experiments: object.isRequired,
  };

  render() {
    const { t, experiments } = this.props;
    return (
      <Composites.RichTextLabeled className="composite-centered">
        <TextLabel
          value={tWithWizRename(
            'adv-seo.basic-panel.seo-wiz.title',
            t,
            experiments,
          )}
          type="T03"
          shouldTranslate={false}
          enableEllipsis={false}
        />
        <RichText>
          <p>
            {tWithWizRename(
              'adv-seo.basic-panel.seo-wiz.description',
              t,
              experiments,
            )}
          </p>
          <p>
            <Button
              className="btn-confirm-secondary"
              onClick={this._openSeoWiz}
            >
              {tWithWizRename(
                'adv-seo.basic-panel.seo-wiz.button',
                t,
                experiments,
              )}
            </Button>
          </p>
        </RichText>
      </Composites.RichTextLabeled>
    );
  }

  _openSeoWiz = () => {
    const { wixSeoUrl, logBiEvent } = this.props;
    window.open(wixSeoUrl, '_blank');
    logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
      linkName: 'adv-seo.basic-panel.seo-wiz.button',
      linkType: 'general',
      linkSource: 'panel',
    });
  };
}

export default translate()(BasicPanelSeoWiz);
