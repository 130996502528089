var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { siteIDs } from '../../adapters/utils';
import { buildOgMeta, buildOgMetaRefs, buildLink, buildTwitterMetaRefs, buildTitle, buildStandardMeta, } from '../../types/builders';
import adapter from './adapter-stores-category';
var IDs = adapter.IDs;
var PREV = siteIDs.PREV, NEXT = siteIDs.NEXT;
export var STORES_CATEGORY = {
    tags: __spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}} | {{").concat(IDs.SITE_NAME, "}}"))
    ], buildOgMetaRefs({ excludeDescription: true }), true), [
        buildOgMeta('og:description', "{{".concat(IDs.DESCRIPTION, "}}")),
        buildStandardMeta('robots', 'index'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.CATEGORY_PAGE_URL, "}}") }),
        buildLink({ rel: 'prev', href: "{{".concat(PREV, "}}") }),
        buildLink({ rel: 'next', href: "{{".concat(NEXT, "}}") }),
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:url', "{{".concat(IDs.CATEGORY_PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(IDs.SITE_NAME, "}}"))
    ], false), buildTwitterMetaRefs(), true),
};
