import React from 'react';
import { number } from 'prop-types';

export function HireAnExpert({ width, height }) {
  return (
    <svg width={width} height={height}>
      <title>Advanced / Expert Illustration</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M28 71.198c-2.483-2.677-4-6.26-4-10.198 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 3.938-1.517 7.521-4 10.198C48.148 66.96 43.92 64 39 64s-9.148 2.96-11 7.198z"
          fill="#EAF7FF"
        />
        <path
          d="M28 71.198C29.852 66.96 34.08 64 39 64s9.148 2.96 11 7.198A14.96 14.96 0 0 1 39 76a14.96 14.96 0 0 1-11-4.802z"
          fill="#4BAAFF"
        />
        <path
          d="M39 54c-3.268 0-6 2.688-6 6s2.732 6 6 6 6-2.688 6-6-2.732-6-6-6z"
          fill="#4BAAFF"
          fillRule="nonzero"
        />
        <path
          d="M24 43l-2.45 1.288a.6.6 0 0 1-.871-.632l.468-2.729-1.983-1.933a.6.6 0 0 1 .333-1.023l2.74-.398 1.225-2.483a.6.6 0 0 1 1.076 0l1.225 2.483 2.74.398a.6.6 0 0 1 .333 1.023l-1.983 1.933.468 2.729a.6.6 0 0 1-.87.632L24 43zM39 39l-2.45 1.288a.6.6 0 0 1-.871-.632l.468-2.729-1.983-1.933a.6.6 0 0 1 .333-1.023l2.74-.398 1.225-2.483a.6.6 0 0 1 1.076 0l1.225 2.483 2.74.398a.6.6 0 0 1 .333 1.023l-1.983 1.933.468 2.729a.6.6 0 0 1-.87.632L39 39zM54 43l-2.45 1.288a.6.6 0 0 1-.871-.632l.468-2.729-1.983-1.933a.6.6 0 0 1 .333-1.023l2.74-.398 1.225-2.483a.6.6 0 0 1 1.076 0l1.225 2.483 2.74.398a.6.6 0 0 1 .333 1.023l-1.983 1.933.468 2.729a.6.6 0 0 1-.87.632L54 43z"
          fill="#4EB7F5"
        />
      </g>
    </svg>
  );
}
HireAnExpert.propTypes = {
  width: number,
  height: number,
};

HireAnExpert.defaultProps = {
  width: 60,
  height: 79,
};
