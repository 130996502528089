import React from 'react';
import { number } from 'prop-types';

export function Multilingual({ width, height }) {
  return (
    <svg width={width} height={height}>
      <g fill="none" fillRule="nonzero">
        <g transform="translate(0 0)">
          <rect fill="#3899EC" width="26" height="26" rx="2" />
          <path
            stroke="#FFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7 20l6-14h0l6 14"
          />
          <path
            d="M10 14.5h6"
            stroke="#FFF"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </g>
        <g transform="translate(16 18)">
          <rect fill="#D7EBFB" width="26" height="26" rx="2" />
          <g stroke="#6ED0F2" strokeLinecap="round" strokeWidth="1.5">
            <path d="M5 7.5h16M13 5v2M6 21c5.54-.706 11.5-7 11.5-13M20.5 21C14.96 20.294 9 14.235 9 10" />
          </g>
        </g>
      </g>
    </svg>
  );
}

Multilingual.propTypes = {
  width: number,
  height: number,
};

Multilingual.defaultProps = {
  width: 42,
  height: 44,
};
