import { IHttpClient } from '@wix/http-client';
import { getSettings } from '@wix/ambassador-multilingual-settings-v1-settings-data/http';
import { ResolutionMethod } from '@wix/ambassador-multilingual-settings-v1-settings-data/types';

export const getMultilingualResolutionMethod = async (
  httpClient: IHttpClient,
): Promise<ResolutionMethod> => {
  const { data } = await httpClient.request(getSettings({}));
  return data?.settings?.resolutionMethod;
};
