var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getKeys } from '../../adapters/utils';
import { get, getRawValue } from '../../utils/get';
import { SD_STATUS } from '../../consts';
import { getStructuredData } from './adapter-utils';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
import { ITEM_TYPES } from '../../types/ItemTypes';
export var IDs = {
    ID: 'portfolio-collection.id',
    TITLE: 'portfolio-collection.title',
    DESCRIPTION: 'portfolio-collection.description',
    COLLECTION_PAGE_URL: 'portfolio-collection.url',
    TOTAL_PROJECTS_COUNT: 'portfolio-collection.total-projects',
    MAIN_IMAGE: 'portfolio-collection.main-image.url',
    COVER_IMAGE: 'portfolio-collection.cover-image.url',
    COVER_IMAGE_HEIGHT: 'portfolio-collection.cover-image.height',
    COVER_IMAGE_WIDTH: 'portfolio-collection.cover-image.width',
    COVER_IMAGE_ALT: 'portfolio-collection.cover-image.alt',
    DISABLE_IMAGES_SCHEMA: 'portfolio-collection.images-schema.disable',
    SD_JSON_IMAGES: 'portfolio-collection.structured-data.images',
};
var SchemaTypes = {
    COLLECTION_IMAGES: 'portfolio-collection-images',
};
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.COLLECTION_PAGE_URL,
    IDs.TOTAL_PROJECTS_COUNT,
    IDs.COVER_IMAGE,
];
var userVisibleSdOnly = [IDs.SD_JSON_IMAGES];
var getSdStatus = function (item, data) {
    if (data[IDs.SD_JSON_IMAGES] === '') {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
var getCoverImageData = function (item) {
    var _a;
    var hasImageUrl = getRawValue(item, 'collection.coverImage.imageInfo.url');
    return _a = {},
        _a[IDs.COVER_IMAGE] = hasImageUrl
            ? get(item, 'collection.coverImage.imageInfo.url')
            : '',
        _a[IDs.COVER_IMAGE_HEIGHT] = hasImageUrl
            ? get(item, 'collection.coverImage.imageInfo.height')
            : '',
        _a[IDs.COVER_IMAGE_WIDTH] = hasImageUrl
            ? get(item, 'collection.coverImage.imageInfo.width')
            : '',
        _a[IDs.COVER_IMAGE_ALT] = hasImageUrl
            ? get(item, 'collection.coverImage.imageInfo.altText')
            : '',
        _a;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b, _c;
        var _d, _e;
        var collectionItems = (_d = getRawValue(item, 'collectionProjects')) !== null && _d !== void 0 ? _d : [];
        var coverImageData = getCoverImageData(item);
        var data = __assign(__assign(__assign((_a = {}, _a[IDs.ID] = get(item, 'collection.id'), _a[IDs.TITLE] = get(item, 'collection.title'), _a[IDs.DESCRIPTION] = get(item, 'collection.description'), _a[IDs.TOTAL_PROJECTS_COUNT] = collectionItems.length, _a[IDs.COLLECTION_PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.PORTFOLIO_PROJECTS, item, get(item, 'collection.url.url')), _a), coverImageData), (_b = {}, _b[IDs.MAIN_IMAGE] = (_e = get(item, 'collection.coverImage.imageInfo.url')) !== null && _e !== void 0 ? _e : '', _b)), getStructuredData(IDs, collectionItems, coverImageData));
        var imagesSdStatus = getSdStatus(item, data, SchemaTypes.COLLECTION_IMAGES) !==
            SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_c = {}, _c[IDs.DISABLE_IMAGES_SCHEMA] = imagesSdStatus, _c));
    },
    getSdStatus: getSdStatus,
    getFullUrl: function (itemData) { return get(itemData, 'collection.url.url'); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData.collection.url.url = url;
        return itemData;
    },
    getSlug: function (itemData) { return get(itemData, 'collection.slug'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
};
