var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { shouldRenderPanelSchema } from './utils/should-render-panel-schema';
import { resolvePanelSchemas } from './utils/resolve-panel-schemas';
export var resolveStructuredData = function (tags, context) {
    if (tags === void 0) { tags = []; }
    if (context === void 0) { context = {}; }
    var schemasSetInPanels = {};
    return __spreadArray(__spreadArray([], tags.reduce(function (resolvedTags, tag) {
        var _a;
        var isPanelsSchema = (_a = tag === null || tag === void 0 ? void 0 : tag.meta) === null || _a === void 0 ? void 0 : _a.schemaType;
        if (isPanelsSchema) {
            if (shouldRenderPanelSchema(tag, context)) {
                schemasSetInPanels = resolvePanelSchemas(schemasSetInPanels, tag);
            }
            return resolvedTags;
        }
        return __spreadArray(__spreadArray([], resolvedTags, true), [tag], false);
    }, []), true), Object.values(schemasSetInPanels), true);
};
