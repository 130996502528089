import React, { useCallback, useMemo } from 'react';
import { RadioButtons, Composites, TextLabel } from '@wix/wix-base-ui';
import { translate } from 'react-i18next';

import InfoIcon from '../ui/info-icon';
import { TWITTER_CARD_SUPPORTED_TYPES } from '@wix/advanced-seo-utils';

const SocialPanelTwitterCard = ({ value, onSave, onChange, disabled, t }) => {
  const onValueChange = useCallback(
    async (val) => {
      await onChange(val);
      onSave();
    },
    [onChange, onSave],
  );

  const radioButtonsOptions = useMemo(
    () => [
      {
        label: t('adv-seo.social-panel.page-twitter-card.large'),
        value: TWITTER_CARD_SUPPORTED_TYPES.SUMMARY_LARGE_IMAGE,
      },
      {
        label: t('adv-seo.social-panel.page-twitter-card.small'),
        value: TWITTER_CARD_SUPPORTED_TYPES.SUMMARY,
      },
    ],
    [t],
  );

  return (
    <Composites.RadioButtonsLabeled>
      <InfoIcon text="adv-seo.social-panel.page-twitter-card.tooltip" />
      <TextLabel
        value={t('adv-seo.social-panel.page-twitter-card.label')}
        shouldTranslate={false}
        automationId="twitter-card-label"
      />
      <RadioButtons
        disabled={disabled}
        value={value}
        shouldTranslate={false}
        onChange={onValueChange}
        options={radioButtonsOptions}
        automationId="twitter-card-radio-buttons"
      />
    </Composites.RadioButtonsLabeled>
  );
};

export default translate()(SocialPanelTwitterCard);
