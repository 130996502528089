"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildFAQ = void 0;
function BuildFAQ(item) {
    var _a;
    var FAQSchema;
    if (((_a = item === null || item === void 0 ? void 0 : item.faq) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        var questionsAndAnswers = item.faq.flat();
        FAQSchema = questionsAndAnswers === null || questionsAndAnswers === void 0 ? void 0 : questionsAndAnswers.map(function (qa) {
            var _a, _b;
            return {
                '@type': 'Question',
                name: (_a = qa === null || qa === void 0 ? void 0 : qa.plain) === null || _a === void 0 ? void 0 : _a.question,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: (_b = qa === null || qa === void 0 ? void 0 : qa.html) === null || _b === void 0 ? void 0 : _b.answer,
                },
            };
        });
    }
    return FAQSchema;
}
exports.BuildFAQ = BuildFAQ;
