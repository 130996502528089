export const getBoundingRect = (selector) => {
  const el = document.querySelector(`#${selector}`);
  let boundingRect = null;
  if (el) {
    boundingRect = el.getBoundingClientRect();
  }

  return boundingRect;
};

const getDynamicPosition = ({ x = 0, y = 0 }) => {
  return { left: x + 'px', top: y + 'px' };
};

const getSafePosition = ({ x, y, width, height }) => {
  const PADDING = 20;
  const { innerHeight, innerWidth } = window;
  const heightLeftover = innerHeight - (y + height);
  const widthLeftover = innerWidth - (x + width);
  const position = { x, y };

  if (heightLeftover < 0) {
    position.y = y - (Math.abs(heightLeftover) + PADDING);
  } else if (y < 0) {
    position.y = Math.abs(y) + PADDING;
  }

  if (widthLeftover < 0) {
    position.x = x - (Math.abs(widthLeftover) + PADDING);
  } else if (x < 0) {
    position.x = Math.abs(x) + PADDING;
  }
  return position;
};

export const getSafePanelPosition = (boundingRect, panelRect) => {
  const tab =
    document.querySelector('div.tab') || document.querySelector('#tab');
  const tabRect = tab ? tab.getBoundingClientRect() : boundingRect; // add boundingRect for testing
  const y = tabRect ? tabRect.y + 22 : 0;
  const x = boundingRect
    ? boundingRect.x + boundingRect.width - panelRect.width / 2
    : 0;
  const safePosition = getSafePosition({
    x,
    y,
    width: panelRect.width,
    height: panelRect.height,
  });
  return getDynamicPosition(safePosition);
};
