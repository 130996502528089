import { getKeys, siteIDs } from '../../adapters/utils';
import { get } from '../../utils/get';
import { SD_STATUS } from '../../consts';
import { getData } from './get-data';
import { getDataLegacy } from './get-data-legacy';
import { getUrlParts } from '../../utils/get-url-parts';
import { trimTrailingSlash } from '../../utils/trim-trailing-slash';
export var IDs = {
    ID: 'challenge.id',
    AVAILABILITY: 'challenge.registration.availability',
    MAIN_IMAGE: 'challenge.mainImage',
    CALCULATED_ROBOTS: 'challenge.robots',
    CREATED_DATE: 'challenge.created',
    CURRENCY: 'challenge.registration.currency',
    DESCRIPTION: 'challenge.description',
    DESCRIPTION_150: 'challenge.description.150',
    IMAGE_HEIGHT: 'challenge.mainImage.height',
    IMAGE_ID: 'challenge.mainImage.id',
    IMAGE_WIDTH: 'challenge.mainImage.width',
    TITLE: 'challenge.name',
    CALCULATED_NAME: 'challenge.accessible.name',
    PRICE: 'challenge.registration.price',
    URL: 'challenge.url',
};
var userVisible = [
    IDs.CALCULATED_NAME,
    IDs.DESCRIPTION_150,
    IDs.PRICE,
    IDs.URL,
    IDs.MAIN_IMAGE,
    IDs.TITLE,
];
var requiredSchemaVariables = [siteIDs.SITE_NAME, siteIDs.BUSINESS_NAME];
var SchemaTypes = {
    CHALLENGES_PAGE: 'challenges-page-preset',
};
var getFullUrl = function (item) {
    if (!item.program) {
        return get(item, 'challenge.url');
    }
    var base = trimTrailingSlash(get(item, 'program.settings.seo.url.base'));
    var path = get(item, 'program.settings.seo.url.path');
    return base.concat(path);
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        return (item === null || item === void 0 ? void 0 : item.program) ? getData(IDs, item) : getDataLegacy(IDs, item);
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function (item) {
        return (item === null || item === void 0 ? void 0 : item.program)
            ? get(item, 'program.settings.seo.slug')
            : get(item, 'challenge.slug');
    },
    getFullUrl: getFullUrl,
    setFullUrl: function (item, url) {
        if (url === void 0) { url = ''; }
        item = JSON.parse(JSON.stringify(item));
        if (item === null || item === void 0 ? void 0 : item.program) {
            var _a = getUrlParts(url), base = _a.base, path = _a.path;
            item.program.settings.seo.url = {
                base: base,
                path: path,
            };
            return item;
        }
        else {
            item.challenge.url = url;
            return item;
        }
    },
    updateItemDataWithSlug: function (itemData, slugValue) {
        if (!(itemData === null || itemData === void 0 ? void 0 : itemData.program)) {
            return itemData;
        }
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        if (get(updatedItemData, 'program.settings.seo.slug') !== '') {
            updatedItemData.program.settings.seo.slug = slugValue;
        }
        return updatedItemData;
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
