import React from 'react';
import { ROBOTS_DIRECTIVES } from '@wix/advanced-seo-utils';
import {
  useApplyOrRemoveDirective,
  useIsDirectiveExists,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

export const dataHook = 'no-follow-directive-checkbox';

export const NoFollowDirective = translate()(({ t }) => {
  const isNoFollowDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.NOFOLLOW,
  );
  const onNoFollowCheckboxClicked = useApplyOrRemoveDirective(
    ROBOTS_DIRECTIVES.NOFOLLOW,
  );

  return (
    <CheckboxWithInfoIcon
      dataHook={dataHook}
      isChecked={isNoFollowDirectiveExists}
      onChange={onNoFollowCheckboxClicked}
      label={t('adv-seo.common.robots-tag.directives.no-follow.title')}
      infoText={t('adv-seo.common.robots-tag.directives.no-follow.tooltip')}
      infoTextBiName="adv-seo.common.robots-tag.directives.no-follow.tooltip"
      helpCenterId={t(
        'adv-seo.common.robots-tag.directives.no-follow.tooltip.editor-help-center-id',
      )}
    />
  );
});

NoFollowDirective.propTypes = {};
