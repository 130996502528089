import {
  ExperimentsProvider,
  useExperiments,
} from '@wix/wix-experiments-react';
import React from 'react';
import { AppWrapper } from './app-wrapper';

export const AppWithExperiments = ({ experiments, ...props }) => (
  <ExperimentsProvider options={{ experiments: experiments ?? {} }}>
    <Wrapper {...props} />
  </ExperimentsProvider>
);

const Wrapper = (props) => {
  const { experiments } = useExperiments();
  return <AppWrapper {...props} experiments={experiments} />;
};
