import React, { PureComponent } from 'react';
import { func, string, bool } from 'prop-types';
import { translate } from 'react-i18next';

import { KEYS } from '../../../core/utils/maps';
import { SocialPreview } from './social-preview';
import { getHostnameFromUrl } from '../../../core/utils/utils';
import RenderIf from '../../../core/components/render-if';
import SocialPanelTitle from './social-panel-title';
import SocialPanelDescription from './social-panel-description';
import SocialPanelImageWarning from './social-panel-image-warning';
import SocialPanelImageError from './social-panel-image-error';
import SocialPanelImageDescription from './social-panel-image-description';
import {
  PROMOTE_SEO_ACTION_CLICK,
  PROMOTE_SEO_PANEL_SECTION_EXPAND_COLLAPSE,
} from '../../../core/bi/action-types';
import SocialPanelTwitterCard from './social-panel-twitter-card';
import SocialPanelTwitterTitle from './social-panel-twitter-title';
import SocialPanelTwitterDescription from './social-panel-twitter-description';
import SocialPanelTwitterMigration from './social-panel-twitter-migration';
import {
  AccordionSection,
  Composites,
  Divider,
  TextLabel,
  RichText,
} from '@wix/wix-base-ui';
import { TWITTER_CARD_SUPPORTED_TYPES } from '@wix/advanced-seo-utils';
import {
  PROMOTE_SEO_SECTION_COLLAPSE,
  PROMOTE_SEO_SECTION_EXPAND,
  PROMOTE_SEO_TWITTER_SHARE_SOCIAL_SECTION_NAME,
} from '../../../core/bi/constants';

const HELP_ARTICLE_SOCIAL_SETTINGS = 'a45e4ba6-b9a3-431e-be56-330ab1d4af73';
const HELP_ARTICLE_TWITTER_SETTINGS = 'e9fca6dc-c3c0-4e72-87ea-602ecd82af0f';
class SocialPanel extends PureComponent {
  static propTypes = {
    onSave: func.isRequired,
    onChange: func.isRequired,
    title: string.isRequired,
    titleErrorMessage: string,
    description: string.isRequired,
    descriptionErrorMessage: string,
    ogImageFromSite: string,
    ogImage: string,
    ogImageError: string,
    ogImageHasWarning: bool,
    t: func.isRequired,
    publicURL: string.isRequired,
    openHelpCenter: func.isRequired,
    logBiEvent: func.isRequired,
    isTwitterCardValueSupportedOrMissing: bool.isRequired,
    twitterCard: string.isRequired,
    twitterTitle: string.isRequired,
    twitterTitleErrorMessage: string,
    twitterDescription: string.isRequired,
    twitterDescriptionErrorMessage: string,
    twitterImageFromSite: string,
    twitterImage: string,
    twitterImageError: string,
    twitterImageHasWarning: bool,
    resetToPattern: func.isRequired,
    showResetToPatternButton: func.isRequired,
    setActiveTab: func.isRequired,
    getDefaultPatternValue: func.isRequired,
  };

  constructor(props) {
    super(props);
    this.urlForSocialPreview = getHostnameFromUrl({
      url: this.props.publicURL,
    }).toUpperCase();
    this.urlForTwitterPreview = getHostnameFromUrl({
      url: this.props.publicURL,
    });
  }

  render() {
    const {
      title,
      titleErrorMessage,
      description,
      descriptionErrorMessage,
      ogImageFromSite,
      ogImage,
      ogImageError,
      openHelpCenter,
      ogImageHasWarning,
      logBiEvent,
      t,
      isTwitterCardValueSupportedOrMissing,
      twitterCard,
      twitterTitle,
      twitterTitleErrorMessage,
      twitterDescription,
      twitterDescriptionErrorMessage,
      twitterImageFromSite,
      twitterImage,
      customTwitterImageUrl,
      twitterImageError,
      twitterImageHasWarning,
      showResetToPatternButton,
      getDefaultPatternValue,
    } = this.props;

    return (
      <div data-aid="seo-social-panel">
        <Composites.RichTextLabeled>
          <TextLabel
            type="T09"
            value={t('adv-seo.social-panel.title')}
            shouldTranslate={false}
          />
          <RichText type="T02">
            <div className="seo-advanced-client-social-subtitle">
              {t('adv-seo.social-panel.subtitle')}
            </div>
          </RichText>
        </Composites.RichTextLabeled>
        <Divider long={false} />
        <Composites.RichText>
          <RichText>
            <SocialPanelImageDescription
              openHelpCenter={() =>
                openHelpCenter(HELP_ARTICLE_SOCIAL_SETTINGS)
              }
              logBiEvent={logBiEvent}
              baseTranslationKey="adv-seo.social-panel"
            >
              <RenderIf truthy={ogImageError}>
                <SocialPanelImageError
                  error={ogImageError}
                  openHelpCenter={openHelpCenter}
                  logBiEvent={logBiEvent}
                  baseTranslationKey="adv-seo.social-panel"
                />
              </RenderIf>
              <RenderIf truthy={ogImageHasWarning}>
                <SocialPanelImageWarning
                  openHelpCenter={openHelpCenter}
                  logBiEvent={logBiEvent}
                  baseTranslationKey="adv-seo.social-panel"
                />
              </RenderIf>
            </SocialPanelImageDescription>
          </RichText>
          <SocialPreview
            dataHook="social-preview"
            t={t}
            onAdjust={this.onAdjustOGImage}
            onUpload={this.onPickOGImage}
            onChange={this.onPickOGImage}
            onDelete={this.onDeleteOGImage}
            siteImageUrl={ogImage}
            defaultSiteImageUrl={ogImageFromSite}
            siteUrl={this.urlForSocialPreview}
            siteTitle={title}
            siteDescription={description}
          />
        </Composites.RichText>
        <Divider long={false} />
        <SocialPanelTitle
          value={title}
          error={titleErrorMessage}
          onChange={this.onChangeTitle}
          onSave={this.onSaveTitle}
          resetToPattern={this.resetToPatternTitle}
          showResetToPatternButton={showResetToPatternButton}
          getDefaultPatternValue={getDefaultPatternValue}
          logBiEvent={logBiEvent}
        />
        <Divider long={false} />
        <SocialPanelDescription
          value={description}
          error={descriptionErrorMessage}
          onChange={this.onChangeDescription}
          onSave={this.onSaveDescription}
          resetToPattern={this.resetToPatternDescription}
          showResetToPatternButton={showResetToPatternButton}
          getDefaultPatternValue={getDefaultPatternValue}
          logBiEvent={logBiEvent}
        />

        <div style={{ paddingBottom: '29px' }}>
          <AccordionSection
            dataHook="twitter-settings"
            shouldTranslate={false}
            label={t('adv-seo.social-panel.page-twitter-settings.label')}
            infoContent={t(
              'adv-seo.social-panel.page-twitter-settings.tooltip',
            )}
            onToggle={this.sendTwitterSettingsToggleEvent}
            removePadding
          >
            {isTwitterCardValueSupportedOrMissing ? (
              <Composites.RichText>
                <RichText>
                  <SocialPanelImageDescription
                    openHelpCenter={() =>
                      openHelpCenter(HELP_ARTICLE_TWITTER_SETTINGS)
                    }
                    logBiEvent={logBiEvent}
                    baseTranslationKey="adv-seo.social-panel.twitter"
                  >
                    <RenderIf truthy={twitterImageError}>
                      <SocialPanelImageError
                        error={twitterImageError}
                        openHelpCenter={openHelpCenter}
                        logBiEvent={logBiEvent}
                        baseTranslationKey="adv-seo.social-panel.twitter"
                      />
                    </RenderIf>
                    <RenderIf truthy={twitterImageHasWarning}>
                      <SocialPanelImageWarning
                        openHelpCenter={openHelpCenter}
                        logBiEvent={logBiEvent}
                        baseTranslationKey="adv-seo.social-panel.twitter"
                      />
                    </RenderIf>
                  </SocialPanelImageDescription>
                </RichText>
                <SocialPreview
                  dataHook="twitter-preview"
                  t={t}
                  onAdjust={this.onAdjustTwitterImage}
                  onUpload={this.onPickTwitterImage}
                  onChange={this.onPickTwitterImage}
                  onDelete={this.onDeleteTwitterImage}
                  siteImageUrl={twitterImage}
                  customSiteImageUrl={customTwitterImageUrl}
                  defaultSiteImageUrl={twitterImageFromSite}
                  siteUrl={this.urlForTwitterPreview}
                  siteTitle={twitterTitle}
                  siteDescription={twitterDescription}
                  skin="twitter"
                  size={
                    twitterCard === TWITTER_CARD_SUPPORTED_TYPES.SUMMARY
                      ? 'small'
                      : 'large'
                  }
                />
              </Composites.RichText>
            ) : (
              <SocialPanelTwitterMigration
                logBiEvent={logBiEvent}
                setActiveTab={this.props.setActiveTab}
              />
            )}
            <Divider long={false} />
            <SocialPanelTwitterCard
              disabled={!isTwitterCardValueSupportedOrMissing}
              value={twitterCard}
              onChange={this.onChangeTwitterCard}
              onSave={this.onSaveTwitterCard}
            />
            <Divider long={false} />
            <SocialPanelTwitterTitle
              disabled={!isTwitterCardValueSupportedOrMissing}
              value={twitterTitle}
              error={twitterTitleErrorMessage}
              onChange={this.onChangeTwitterTitle}
              onSave={this.onSaveTwitterTitle}
              resetToPattern={this.resetToPatternTwitterTitle}
              showResetToPatternButton={showResetToPatternButton}
              getDefaultPatternValue={getDefaultPatternValue}
              logBiEvent={logBiEvent}
            />
            <Divider long={false} />
            <SocialPanelTwitterDescription
              disabled={!isTwitterCardValueSupportedOrMissing}
              value={twitterDescription}
              error={twitterDescriptionErrorMessage}
              onChange={this.onChangeTwitterDescription}
              onSave={this.onSaveTwitterDescription}
              resetToPattern={this.resetToPatternTwitterDescription}
              showResetToPatternButton={showResetToPatternButton}
              getDefaultPatternValue={getDefaultPatternValue}
              logBiEvent={logBiEvent}
            />
          </AccordionSection>
        </div>
      </div>
    );
  }

  onSaveTitle = this.props.onSave(KEYS.OG_TITLE);
  onChangeTitle = this.props.onChange(KEYS.OG_TITLE);
  resetToPatternTitle = this.props.resetToPattern(KEYS.OG_TITLE);
  onSaveDescription = this.props.onSave(KEYS.OG_DESCRIPTION);
  onChangeDescription = this.props.onChange(KEYS.OG_DESCRIPTION);
  resetToPatternDescription = this.props.resetToPattern(KEYS.OG_DESCRIPTION);
  onSaveTwitterCard = this.props.onSave(KEYS.TWITTER_CARD);
  onChangeTwitterCard = this.props.onChange(KEYS.TWITTER_CARD);
  onSaveTwitterTitle = this.props.onSave(KEYS.TWITTER_TITLE);
  resetToPatternTwitterTitle = this.props.resetToPattern(KEYS.TWITTER_TITLE);
  onChangeTwitterTitle = this.props.onChange(KEYS.TWITTER_TITLE);
  onSaveTwitterDescription = this.props.onSave(KEYS.TWITTER_DESCRIPTION);
  resetToPatternTwitterDescription = this.props.resetToPattern(
    KEYS.TWITTER_DESCRIPTION,
  );
  onChangeTwitterDescription = this.props.onChange(KEYS.TWITTER_DESCRIPTION);
  onChangeOGImage = async (source, data = {}) => {
    try {
      await this.props.onChange(KEYS.OG_IMAGE)(source, data);
      this.props.onSave(KEYS.OG_IMAGE)();
    } catch (e) {}
  };
  onPickOGImage = () => {
    this.sendBiEvent('replace');
    this.onChangeOGImage('change');
  };
  onAdjustOGImage = (data = {}) => {
    this.sendBiEvent('adjust');
    this.onChangeOGImage('adjust', data);
  };
  onDeleteOGImage = () => {
    this.sendBiEvent('delete');
    this.onChangeOGImage('delete');
  };
  sendBiEvent = (action) => {
    const { logBiEvent } = this.props;
    logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
      tabName: 'SOCIAL',
      fieldName: 'ogImage',
      action,
      type: 'CTA',
    });
  };
  onChangeTwitterImage = async (source, data = {}) => {
    try {
      await this.props.onChange(KEYS.TWITTER_IMAGE)(source, data);
      this.props.onSave(KEYS.TWITTER_IMAGE)();
    } catch (e) {}
  };
  onPickTwitterImage = () => {
    this.sendTwitterBiEvent('replace');
    this.onChangeTwitterImage('change');
  };
  onAdjustTwitterImage = (data = {}) => {
    this.sendTwitterBiEvent('adjust');
    this.onChangeTwitterImage('adjust', data);
  };
  onDeleteTwitterImage = () => {
    this.sendTwitterBiEvent('delete');
    this.onChangeTwitterImage('delete');
  };
  sendTwitterBiEvent = (action) => {
    const { logBiEvent } = this.props;
    logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
      tabName: 'SOCIAL',
      fieldName: 'twitterImage',
      action,
      type: 'CTA',
    });
  };
  sendTwitterSettingsToggleEvent = (isOpen) => {
    const { logBiEvent } = this.props;
    logBiEvent(PROMOTE_SEO_PANEL_SECTION_EXPAND_COLLAPSE, {
      sectionName: PROMOTE_SEO_TWITTER_SHARE_SOCIAL_SECTION_NAME,
      action: isOpen
        ? PROMOTE_SEO_SECTION_EXPAND
        : PROMOTE_SEO_SECTION_COLLAPSE,
    });
  };
}

export default translate()(SocialPanel);
