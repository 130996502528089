import React, { useCallback, useContext } from 'react';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import {
  RobotsTagContext,
  useApplyOrRemoveDirective,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { translate, Trans } from 'react-i18next';
import { bool, func, string } from 'prop-types';
import { RichText } from '@wix/wix-base-ui';
import { PERMISSIONS_TAB_NAME } from '../../../../tabs/tab-names';
import * as BI_TYPES from '../../../../../core/bi/action-types';
import { getWixSeoSettingsUrl } from '../../../../../core/utils/consts';
import { ROBOTS_DIRECTIVES } from '@wix/advanced-seo-utils';
import { useExperiments } from '@wix/wix-experiments-react';

export const dataHook = 'no-index-directive-checkbox';

const siteNoIndexKey =
  'adv-seo.common.robots-tag.directives.no-index.site-no-index.tooltip';
const permissionsNoIndexKey =
  'adv-seo.common.robots-tag.directives.no-index.permissions-no-index.tooltip';

export const NoIndexDirective = translate()(
  ({
    isIndexEnabled,
    isIndexEnabledFromSite,
    isIndexEnabledFromPermission,
    setActiveTab,
    msid,
    t,
  }) => {
    const { experiments } = useExperiments();
    const { logBiEvent } = useContext(RobotsTagContext);
    const onNoIndexCheckboxClicked = useApplyOrRemoveDirective(
      ROBOTS_DIRECTIVES.NOINDEX,
    );

    const onSiteNoIndexClick = useCallback(() => {
      logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
        linkName: siteNoIndexKey,
        linkType: 'learn_more',
        linkSource: 'tooltip',
      });
      window.open(getWixSeoSettingsUrl(msid), '_blank');
    }, [msid, logBiEvent]);

    const onPermissionsNoIndexLinkClick = useCallback(() => {
      logBiEvent(BI_TYPES.PROMOTE_SEO_CTA_CLICK, {
        linkName: permissionsNoIndexKey,
        linkType: 'learn_more',
        linkSource: 'tooltip',
      });
      setActiveTab(PERMISSIONS_TAB_NAME);
    }, [logBiEvent, setActiveTab]);

    return (
      <>
        <CheckboxWithInfoIcon
          dataHook="no-index-directive-checkbox"
          disabled={!isIndexEnabledFromSite || !isIndexEnabledFromPermission}
          isChecked={
            !isIndexEnabledFromSite ||
            !isIndexEnabledFromPermission ||
            !isIndexEnabled
          }
          onChange={onNoIndexCheckboxClicked}
          label={t('adv-seo.common.robots-tag.directives.no-index.title')}
          infoText={t('adv-seo.common.robots-tag.directives.no-index.tooltip')}
          infoTextBiName="adv-seo.common.robots-tag.directives.no-index.tooltip"
          helpCenterId={t(
            'adv-seo.common.robots-tag.directives.no-index.tooltip.editor-help-center-id',
          )}
        />
        {!isIndexEnabledFromSite && (
          <div style={{ marginTop: '3px', marginLeft: '30px' }}>
            <RichText type="T04">
              <Trans
                i18nKey="adv-seo.common.robots-tag.directives.no-index.site-no-index.tooltip.site-settings"
                components={[
                  <a onClick={onSiteNoIndexClick}>site preferences</a>,
                ]}
              />
            </RichText>
          </div>
        )}
        {isIndexEnabledFromSite && !isIndexEnabledFromPermission && (
          <div style={{ marginTop: '3px', marginLeft: '30px' }}>
            <RichText type="T04">
              {t(permissionsNoIndexKey)}{' '}
              <a onClick={onPermissionsNoIndexLinkClick}>
                {t(
                  'adv-seo.common.robots-tag.directives.no-index.permissions-no-index.tooltip.link',
                )}
              </a>
            </RichText>
          </div>
        )}
      </>
    );
  },
);

NoIndexDirective.propTypes = {
  isIndexEnabled: bool.isRequired,
  isIndexEnabledFromSite: bool.isRequired,
  isIndexEnabledFromPermission: bool.isRequired,
  setActiveTab: func.isRequired,
  msid: string.isRequired,
};
