var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getRawValue } from '../../utils/get';
import { CONTEXT_PROPS } from '../../types/ContextProps';
var DAY_START_TIME = '00:00:00';
var DAY_END_TIME = '23:59:59';
var Days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];
export var calculateOpeningHoursSpecifications = function (item) {
    var periods = getRawValue(item, "context.".concat(CONTEXT_PROPS.BUSINESS_SCHEDULE, ".periods"));
    if (!periods) {
        return [];
    }
    return periods.reduce(function (acc, _a) {
        var openDay = _a.openDay, closeDay = _a.closeDay, openTime = _a.openTime, closeTime = _a.closeTime;
        if (!openDay || !closeDay) {
            return acc;
        }
        var daysRange = getDaysRange(openDay, closeDay);
        return __spreadArray(__spreadArray([], acc, true), daysRange.map(function (day) {
            return buildHoursSpecificationObject(day, isEqualDays(day, openDay) ? openTime : DAY_START_TIME, isEqualDays(day, closeDay) ? closeTime : DAY_END_TIME);
        }), true);
    }, []);
};
var buildHoursSpecificationObject = function (day, opens, closes) { return ({
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: getStartCaseDayString(day.toLowerCase()),
    opens: opens,
    closes: closes,
}); };
var getDaysRange = function (startDay, endDay) {
    var startIndex = getDayIndex(startDay);
    var endIndex = getDayIndex(endDay);
    if (startIndex === -1 || endIndex === -1) {
        return [];
    }
    if (startIndex <= endIndex) {
        return Days.slice(startIndex, endIndex + 1);
    }
    return Days.slice(startIndex).concat(Days.slice(0, endIndex + 1));
};
var getDayIndex = function (day) { return Days.indexOf(day.toLowerCase()); };
var isEqualDays = function (day1, day2) { return day1.toLowerCase() === day2.toLowerCase(); };
var getStartCaseDayString = function (day) {
    return day.replace(/^(\w)(\w*)$/, function (_, firstLetter, rest) { return firstLetter.toUpperCase() + rest.toLowerCase(); });
};
