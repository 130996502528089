import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { KEYS } from '../../core/utils/maps';

const isEmptyOGImage = (ogImage) =>
  isEmpty(ogImage) || (get(ogImage, 'uri') || '').length === 0;
export const initLegacyHandlers = (props) => ({
  [KEYS.TITLE]: (title) => props.setTitle(title),
  [KEYS.DESCRIPTION]: (desc) => props.setDescription(desc),
  [KEYS.IS_INDEX_ENABLED]: (isEnabled) => props.setIndexEnabled(isEnabled),
  [KEYS.URI]: (uri) => props.setUri(uri),
  [KEYS.OG_IMAGE]: (ogImage) =>
    props.setOGImage(
      ogImage && !isEmptyOGImage(ogImage)
        ? { ...ogImage, type: 'Image' }
        : null,
    ),
});
