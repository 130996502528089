"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getData = void 0;
var ItemTypes_1 = require("../../types/ItemTypes");
var resolve_item_type_multilingual_url_1 = require("../../utils/resolve-item-type-multilingual-url");
var sanitize_url_1 = require("../../utils/sanitize-url");
var get_1 = require("../../utils/get");
var get_main_image_url_1 = require("./calculated/program/get-main-image-url");
var get_robots_1 = require("./calculated/program/get-robots");
var shorten_1 = require("../../utils/shorten");
var get_name_1 = require("./calculated/program/get-name");
var get_created_date_1 = require("./calculated/program/get-created-date");
var get_currency_1 = require("./calculated/program/get-currency");
var get_price_1 = require("./calculated/program/get-price");
var getData = function (IDs, itemData) {
    var _a;
    var fullDescription = (0, get_1.get)(itemData, 'program.settings.description.details');
    return _a = {},
        _a[IDs.ID] = (0, get_1.get)(itemData, 'program.id'),
        _a[IDs.AVAILABILITY] = (0, get_1.get)(itemData, 'program.settings.accessRestrictions.accessType'),
        _a[IDs.MAIN_IMAGE] = (0, get_main_image_url_1.getMainImageUrl)(itemData),
        _a[IDs.CALCULATED_ROBOTS] = (0, get_robots_1.getRobots)(itemData),
        _a[IDs.CREATED_DATE] = (0, get_created_date_1.getCreatedDate)(itemData),
        _a[IDs.CURRENCY] = (0, get_currency_1.getCurrency)(itemData),
        _a[IDs.DESCRIPTION] = fullDescription,
        _a[IDs.DESCRIPTION_150] = (0, shorten_1.shorten)(fullDescription, 150),
        _a[IDs.IMAGE_HEIGHT] = (0, get_1.get)(itemData, 'program.settings.description.media.image.height'),
        _a[IDs.IMAGE_ID] = (0, get_1.get)(itemData, 'program.settings.description.media.image.id'),
        _a[IDs.IMAGE_WIDTH] = (0, get_1.get)(itemData, 'program.settings.description.media.image.width'),
        _a[IDs.TITLE] = (0, get_1.get)(itemData, 'program.settings.description.title'),
        _a[IDs.CALCULATED_NAME] = (0, get_name_1.getName)(itemData),
        _a[IDs.PRICE] = (0, get_price_1.getPrice)(itemData),
        _a[IDs.URL] = (0, resolve_item_type_multilingual_url_1.resolveItemTypeMultilingualUrl)(ItemTypes_1.ITEM_TYPES.CHALLENGES_PAGE, itemData, (0, sanitize_url_1.sanitizeUrl)("".concat((0, get_1.get)(itemData, 'program.settings.seo.url.base')).concat((0, get_1.get)(itemData, 'program.settings.seo.url.path')))),
        _a;
};
exports.getData = getData;
