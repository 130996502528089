"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldRenderSchema = void 0;
var get_1 = require("../../../utils/get");
var get_attendence_mode_1 = require("../calculated/get-attendence-mode");
var get_event_url_1 = require("../calculated/get-event-url");
var shouldRenderSchema = function (item) {
    if (item === void 0) { item = {}; }
    if (!item.event) {
        return false;
    }
    var url = !!(0, get_event_url_1.getEventUrl)(item);
    var titleExists = !!(0, get_1.get)(item, 'event.title');
    var isVenue = (0, get_attendence_mode_1.getIsVenue)(item);
    var locationExists = !!(0, get_1.get)(item, 'event.location.address');
    var isAddressEnteredIfPhysical = (!isVenue && url) || (locationExists && isVenue);
    var isEventScheduled = !(0, get_1.getRawValue)(item, 'event.scheduling.config.scheduleTbd');
    var startDateExists = !!(0, get_1.get)(item, 'event.scheduling.config.startDate');
    return (url &&
        titleExists &&
        isAddressEnteredIfPhysical &&
        isEventScheduled &&
        startDateExists);
};
exports.shouldRenderSchema = shouldRenderSchema;
