import { getRawValue } from '../../../utils/get';
export var getMenuItems = function (item) {
    var sections = getRawValue(item, 'menu.sections');
    if (!sections) {
        return [];
    }
    return sections.map(function (_a) {
        var items = _a.items;
        return items;
    }).flat();
};
