"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var get_1 = require("../../utils/get");
var consts_1 = require("../../consts");
var structure_data_utils_1 = require("../../adapters/structure-data-utils");
var ContextProps_1 = require("../../types/ContextProps");
exports.IDs = {
    URL: 'giftCard.url',
    NAME: 'giftCard.name',
    DESCRIPTION: 'giftCard.description',
    CURRENCY: 'giftCard.price.currency',
    LOW_PRICE: 'giftCard.price.low',
    HIGH_PRICE: 'giftCard.price.high',
    SD_DISABLE_SCHEMA: 'giftCard.disable-schema',
    SD_JSON_IMAGE: 'giftCard.structured-data.image',
    SD_JSON_OFFERS: 'giftCard.structured-data.offers',
    IMAGE_HEIGHT: 'giftCard.image.height',
    IMAGE_WIDTH: 'giftCard.image.width',
    MAIN_IMAGE: 'giftCard.image.url',
    IMAGE_ALT: 'giftCard.image.alt',
};
var SchemaTypes = {
    GIFT_CARD: 'gift-card-preset',
};
var userVisible = [];
var requiredSchemaVariables = function () {
    return [exports.IDs.NAME, exports.IDs.SD_JSON_OFFERS];
};
var getSdStatus = function (item, itemData) {
    if ((0, utils_1.hasMissingSchemaVariables)(requiredSchemaVariables(), item, itemData)) {
        return consts_1.SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return consts_1.SD_STATUS.ENABLED;
};
var getSellerSdStatus = function (item) {
    return {
        '@type': 'Organization',
        name: (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.BUSINESS_NAME)),
    };
};
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        var url = (0, get_1.getRawValue)(item, 'giftCard.pageUrl') || '';
        var imageData = (0, get_1.getRawValue)(item, 'giftCard.image.0');
        var priceCurrency = (0, get_1.get)(item, 'giftCard.price.priceCurrency');
        var priceArray = ((0, get_1.getRawValue)(item, 'giftCard.price.price') || []).map(function (price) {
            return parseInt(price, 10);
        });
        var offerCount = priceArray.length;
        var lowPrice = offerCount > 0 ? Math.min.apply(Math, priceArray) : '';
        var highPrice = offerCount > 0 ? Math.max.apply(Math, priceArray) : '';
        var seller = getSellerSdStatus(item);
        var availability = 'http://schema.org/inStock';
        var offersSD = offerCount === 0 || priceCurrency === ''
            ? ''
            : offerCount === 1
                ? {
                    '@type': 'offer',
                    url: url,
                    price: lowPrice,
                    priceCurrency: priceCurrency,
                    availability: availability,
                    seller: seller,
                }
                : {
                    '@type': 'AggregateOffer',
                    url: url,
                    lowPrice: lowPrice,
                    highPrice: highPrice,
                    priceCurrency: priceCurrency,
                    availability: availability,
                    seller: seller,
                };
        var data = (_a = {},
            _a[exports.IDs.URL] = url,
            _a[exports.IDs.NAME] = (0, get_1.get)(item, 'giftCard.cardName'),
            _a[exports.IDs.DESCRIPTION] = (0, get_1.get)(item, 'giftCard.cardDesc'),
            _a[exports.IDs.CURRENCY] = priceCurrency,
            _a[exports.IDs.SD_JSON_IMAGE] = imageData
                ? (0, structure_data_utils_1.getBasicImageObject)({
                    height: imageData.imageHeight,
                    width: imageData.imageWidth,
                    url: imageData.imageUrl,
                    alt: imageData.imageAlt,
                }, true)
                : '',
            _a[exports.IDs.SD_JSON_OFFERS] = offersSD,
            _a[exports.IDs.LOW_PRICE] = lowPrice,
            _a[exports.IDs.HIGH_PRICE] = highPrice,
            _a[exports.IDs.IMAGE_HEIGHT] = (imageData === null || imageData === void 0 ? void 0 : imageData.imageHeight) || '',
            _a[exports.IDs.IMAGE_WIDTH] = (imageData === null || imageData === void 0 ? void 0 : imageData.imageWidth) || '',
            _a[exports.IDs.MAIN_IMAGE] = (imageData === null || imageData === void 0 ? void 0 : imageData.imageUrl) || '',
            _a[exports.IDs.IMAGE_ALT] = (imageData === null || imageData === void 0 ? void 0 : imageData.imageAlt) || '',
            _a);
        var sdStatus = getSdStatus(item, data) !== consts_1.SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[exports.IDs.SD_DISABLE_SCHEMA] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
