var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get, getRawValue } from '../../utils/get';
import { updateValueByIdentifier } from '../../tags/values/update-by-identifier';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { IDENTIFIERS } from '../../types/Identifiers';
import { getKeys, hasMissingSchemaVariables } from '../../adapters/utils';
import { getProductFullUrl } from './calculated/get-product-full-url';
import { SD_STATUS } from '../../consts';
import { getImageWithThumbnailObject } from '../../adapters/structure-data-utils';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
var IDs = {
    SITE_NAME: 'site.name',
    BUSINESS_NAME: 'site.business.name',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    ID: 'product.id',
    SKU: 'product.sku',
    TITLE: 'product.name',
    DESCRIPTION: 'product.description',
    BRAND: 'product.brand',
    PRICE: 'product.price',
    CURRENCY: 'product.currency',
    TYPE: 'product.type',
    WEIGHT: 'product.weight',
    AVAILABILITY_SCHEMA: 'product.availability.schema',
    AVAILABILITY_META_TAG: 'product.availability.metaTag',
    SLUG: 'product.slug',
    MAIN_IMAGE: 'product.image.url',
    IMAGE_WIDTH: 'product.image.width',
    IMAGE_HEIGHT: 'product.image.height',
    IMAGE_ALT: 'product.image.alt',
    THUMBNAIL_URL: 'product.thumbnail.url',
    THUMBNAIL_WIDTH: 'product.thumbnail.width',
    THUMBNAIL_HEIGHT: 'product.thumbnail.height',
    THUMBNAIL_ALT: 'product.thumbnail.alt',
    SD_JSON_IMAGES: 'product.structured-data.images',
    PRODUCT_PAGE_URL: 'product.url',
    PRODUCT_PAGE_BASE_URL: 'product.baseUrl',
    DISABLE_SCHEMA: 'product.disable-schema',
    MEDIA_ITEMS: 'product.media',
    FULL_DESCRIPTION: 'product.full-description',
};
var userVisible = [
    IDs.SITE_NAME,
    IDs.SKU,
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.BRAND,
    IDs.PRICE,
    IDs.CURRENCY,
    IDs.AVAILABILITY_META_TAG,
    IDs.PRODUCT_PAGE_URL,
    IDs.MAIN_IMAGE,
];
var userVisibleSdOnly = [
    IDs.IMAGE_HEIGHT,
    IDs.IMAGE_WIDTH,
    IDs.IMAGE_ALT,
    IDs.THUMBNAIL_URL,
    IDs.THUMBNAIL_HEIGHT,
    IDs.THUMBNAIL_WIDTH,
    IDs.THUMBNAIL_ALT,
    IDs.AVAILABILITY_SCHEMA,
    IDs.SD_JSON_IMAGES,
];
var requiredSchemaVariables = [];
var SchemaTypes = {
    STORES_PRODUCT_PAGE: 'stores-product-page-preset',
};
var getSdStatus = function (item, itemData) {
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    return SD_STATUS.ENABLED;
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    requiredSchemaVariables: requiredSchemaVariables,
    getData: function (item) {
        var _a, _b;
        var mediaItems = getRawValue(item, 'product.media.items') || [];
        var imagesSD = mediaItems
            .filter(function (_a) {
            var _b = _a.thumbnail, thumbnail = _b === void 0 ? {} : _b, _c = _a.image, image = _c === void 0 ? {} : _c;
            return image.url && thumbnail.url;
        })
            .map(function (_a) {
            var _b = _a.thumbnail, thumbnail = _b === void 0 ? {} : _b, _c = _a.image, image = _c === void 0 ? {} : _c;
            return getImageWithThumbnailObject(image, thumbnail);
        });
        var data = (_a = {},
            _a[IDs.SITE_NAME] = get(item, "context.".concat(CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.BUSINESS_NAME] = get(item, "context.".concat(CONTEXT_PROPS.BUSINESS_NAME)),
            _a[IDs.INDEX_SITE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_SITE)),
            _a[IDs.INDEX_PAGE] = getRawValue(item, "context.".concat(CONTEXT_PROPS.INDEX_PAGE)),
            _a[IDs.ID] = get(item, 'product.id'),
            _a[IDs.SKU] = get(item, 'product.sku'),
            _a[IDs.TITLE] = get(item, 'product.name'),
            _a[IDs.DESCRIPTION] = get(item, 'product.description'),
            _a[IDs.BRAND] = get(item, 'product.brand'),
            _a[IDs.PRICE] = get(item, 'product.price.discountedPrice') > 0
                ? "".concat(get(item, 'product.price.discountedPrice'))
                : "".concat(get(item, 'product.price.price')),
            _a[IDs.CURRENCY] = "".concat(get(item, 'product.price.currency')),
            _a[IDs.TYPE] = get(item, 'product.productType'),
            _a[IDs.WEIGHT] = get(item, 'product.weight'),
            _a[IDs.AVAILABILITY_SCHEMA] = "".concat(get(item, 'product.stock.inStock')) === 'true'
                ? 'https://schema.org/InStock'
                : 'https://schema.org/OutOfStock',
            _a[IDs.AVAILABILITY_META_TAG] = "".concat(get(item, 'product.stock.inStock')) === 'true'
                ? 'InStock'
                : 'out of stock',
            _a[IDs.SLUG] = get(item, 'product.slug'),
            _a[IDs.MAIN_IMAGE] = get(item, 'product.media.mainMedia.image.url'),
            _a[IDs.IMAGE_WIDTH] = get(item, 'product.media.mainMedia.image.width'),
            _a[IDs.IMAGE_HEIGHT] = get(item, 'product.media.mainMedia.image.height'),
            _a[IDs.IMAGE_ALT] = get(item, 'product.media.mainMedia.image.alt'),
            _a[IDs.THUMBNAIL_URL] = get(item, 'product.media.mainMedia.thumbnail.url'),
            _a[IDs.THUMBNAIL_WIDTH] = get(item, 'product.media.mainMedia.thumbnail.width'),
            _a[IDs.THUMBNAIL_HEIGHT] = get(item, 'product.media.mainMedia.thumbnail.height'),
            _a[IDs.THUMBNAIL_ALT] = get(item, 'product.media.mainMedia.thumbnail.alt'),
            _a[IDs.SD_JSON_IMAGES] = imagesSD,
            _a[IDs.PRODUCT_PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.STORES_PRODUCT, item, getProductFullUrl(item)),
            _a[IDs.PRODUCT_PAGE_BASE_URL] = get(item, 'product.productPageUrl.base'),
            _a[IDs.DISABLE_SCHEMA] = hasMissingSchemaVariables(requiredSchemaVariables, item),
            _a[IDs.MEDIA_ITEMS] = mediaItems,
            _a[IDs.FULL_DESCRIPTION] = get(item, 'product.description'),
            _a);
        var disabledSchema = hasMissingSchemaVariables(requiredSchemaVariables, item, data);
        return __assign(__assign({}, data), (_b = {}, _b[IDs.DISABLE_SCHEMA] = disabledSchema, _b));
    },
    getSdStatus: getSdStatus,
    getLegacySeoBlob: function (item) {
        var tags = updateValueByIdentifier([], IDENTIFIERS.TITLE, get(item, 'legacySeoData.title'));
        tags = updateValueByIdentifier(tags, IDENTIFIERS.DESCRIPTION, get(item, 'legacySeoData.description'));
        return { tags: tags };
    },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
    getSlug: function (item) {
        return get(item, 'product.slug');
    },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.product.productPageUrl.path = url;
        return itemData;
    },
    getFullUrl: function (itemData) { return getProductFullUrl(itemData); },
    updateItemDataWithSlug: function (itemData, slugValue) {
        var updatedItemData = JSON.parse(JSON.stringify(itemData));
        if (get(updatedItemData, 'product.slug') !== '') {
            updatedItemData.product.slug = slugValue;
        }
        return updatedItemData;
    },
    getDefaultSchemaType: function () {
        return SchemaTypes.STORES_PRODUCT_PAGE;
    },
};
