import { getKeys } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { getDataLegacy } from './get-data-legacy';
import { getData } from './get-data';
var IDs = {
    ID: 'group-post.id',
    INDEX_PAGE: 'page.index',
    POST_CONTENT: 'group-post.content',
    POST_CONTENT_60: 'group-post.content.60',
    POST_CONTENT_200: 'group-post.content.200',
    GROUP_NAME: 'group-post.name',
    POST_URL: 'group-post.url',
    POST_AUTHOR: 'group-post.createdBy.name',
    POST_PUBLISH_DATE: 'group-post.createdAt',
    POST_IMAGE_HEIGHT: 'group-post.image.height',
    POST_IMAGE_WIDTH: 'group-post.image.width',
    MAIN_IMAGE: 'group-post.image.url',
};
var userVisible = [
    IDs.POST_CONTENT_60,
    IDs.POST_CONTENT_200,
    IDs.POST_AUTHOR,
    IDs.POST_URL,
    IDs.GROUP_NAME,
    IDs.POST_PUBLISH_DATE,
    IDs.MAIN_IMAGE,
];
var requiredSchemaVariables = [];
var SchemaTypes = {
    GROUPS_POST: 'groups-post-preset',
};
export default {
    IDs: IDs,
    requiredSchemaVariables: requiredSchemaVariables,
    SchemaTypes: SchemaTypes,
    getData: function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.group) === null || _a === void 0 ? void 0 : _a.groupId) ? getDataLegacy(IDs, item) : getData(IDs, item); },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
