export const parseKeywordsContent = (pageKeywordsSEO) => {
  const removeDuplicationsOrEmpty = (keywords) =>
    Array.from(new Set(keywords.split(', ')))
      .filter((keyword) => keyword)
      .join(', ');
  const breakToCsv = (keywords) => keywords.replace(/\s+/g, ', ');
  const commasPrettier = (keywords) => keywords.replace(/,\s*/g, ', ');

  let parsedKeywords = pageKeywordsSEO;
  if (parsedKeywords.indexOf(',') === -1) {
    parsedKeywords = breakToCsv(parsedKeywords);
  } else {
    parsedKeywords = commasPrettier(parsedKeywords);
  }

  parsedKeywords = removeDuplicationsOrEmpty(parsedKeywords);
  return parsedKeywords;
};
