"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var utils_1 = require("../../adapters/utils");
var consts_1 = require("../../consts");
exports.IDs = {
    CURRENT_PAGE_URL: 'stores-gallery.current-page-url',
    PREVIOUS_PAGE_URL: 'stores-gallery.previous-page-url',
    NEXT_PAGE_URL: 'stores-gallery.next-page-url',
    TOTAL_ACTIVE_ITEMS_COUNT: 'stores-gallery.total-active-items',
};
var SchemaTypes = {};
var userVisible = [
    exports.IDs.TOTAL_ACTIVE_ITEMS_COUNT,
    utils_1.siteIDs.CURRENT_PAGE_NUMBER,
    utils_1.siteIDs.TOTAL_PAGE_COUNT,
];
exports.default = {
    IDs: exports.IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j;
        return _a = {},
            _a[exports.IDs.CURRENT_PAGE_URL] = (_b = item === null || item === void 0 ? void 0 : item.shop) === null || _b === void 0 ? void 0 : _b.url,
            _a[exports.IDs.PREVIOUS_PAGE_URL] = (_c = item === null || item === void 0 ? void 0 : item.pagination) === null || _c === void 0 ? void 0 : _c.prevUrl,
            _a[exports.IDs.NEXT_PAGE_URL] = (_d = item === null || item === void 0 ? void 0 : item.pagination) === null || _d === void 0 ? void 0 : _d.nextUrl,
            _a[exports.IDs.TOTAL_ACTIVE_ITEMS_COUNT] = (_e = item === null || item === void 0 ? void 0 : item.items) === null || _e === void 0 ? void 0 : _e.numberOfItems,
            _a[utils_1.siteIDs.PREV] = (_f = item === null || item === void 0 ? void 0 : item.pagination) === null || _f === void 0 ? void 0 : _f.prevUrl,
            _a[utils_1.siteIDs.NEXT] = (_g = item === null || item === void 0 ? void 0 : item.pagination) === null || _g === void 0 ? void 0 : _g.nextUrl,
            _a[utils_1.siteIDs.CURRENT_PAGE_NUMBER] = (_h = item === null || item === void 0 ? void 0 : item.pagination) === null || _h === void 0 ? void 0 : _h.currentPage,
            _a[utils_1.siteIDs.TOTAL_PAGE_COUNT] = (_j = item === null || item === void 0 ? void 0 : item.pagination) === null || _j === void 0 ? void 0 : _j.totalPages,
            _a;
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return (0, utils_1.getKeys)(exports.IDs, userVisible); },
    getSdKeys: function () { return []; },
};
