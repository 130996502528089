import { get } from '../../../utils/get';
var CANCELED_STATUS = 'CANCELED';
export var EVENT_STATUS = {
    CANCELED: 'EventCancelled',
    SCHEDULED: 'EventScheduled',
};
export var getEventStatus = function (item) {
    if (item === void 0) { item = {}; }
    return item.event &&
        (get(item, 'event.status') === CANCELED_STATUS
            ? EVENT_STATUS.CANCELED
            : EVENT_STATUS.SCHEDULED);
};
