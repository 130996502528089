import { siteIDs } from '../../../adapters/utils';
import { SCHEMA_TYPES } from './schema-types';
var schema = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    url: "{{".concat(siteIDs.SITE_URL, "}}/"),
    potentialAction: {
        '@type': 'SearchAction',
        target: {
            '@type': 'EntryPoint',
            urlTemplate: "{{".concat(siteIDs.SITE_URL, "}}/{{").concat(siteIDs.SEARCH_PAGE_SLUG, "}}?q={search_term}"),
        },
        'query-input': 'required name=search_term',
    },
};
var requiredSchemaVariables = [siteIDs.SEARCH_PAGE_SLUG];
var appDefId = '1484cb44-49cd-5b39-9681-75188ab429de';
var tpaPageId = 'search_results';
export default {
    type: SCHEMA_TYPES.SITE_SEARCH,
    schema: schema,
    requiredSchemaVariables: requiredSchemaVariables,
    appDefId: appDefId,
    tpaPageId: tpaPageId,
};
