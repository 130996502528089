"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTicketPrice = exports.getTicketFinalPrice = exports.getJsonOffers = void 0;
var get_1 = require("../../../utils/get");
var get_event_url_1 = require("../calculated/get-event-url");
var get_stock_status_1 = require("../calculated/get-stock-status");
var getJsonOffers = function (item, ticketsLength) {
    if (item === void 0) { item = {}; }
    if (ticketsLength === void 0) { ticketsLength = 0; }
    var tickets = item.tickets;
    if (!ticketsLength) {
        return '';
    }
    var offers = tickets.map(function (ticket) {
        var url = (0, get_event_url_1.getEventUrl)(item);
        var offer = {
            '@type': 'offer',
            name: (0, get_1.get)(ticket, 'name'),
            price: "".concat((0, exports.getTicketFinalPrice)(ticket)),
            priceCurrency: (0, get_1.get)(ticket, 'price.currency'),
            availability: "https://schema.org/".concat((0, get_stock_status_1.getStockStatus)(ticket)),
            validFrom: (0, get_1.get)(item, 'event.created'),
        };
        if (url) {
            offer.url = url;
        }
        return offer;
    });
    var lowPrice = (0, get_1.get)(item, 'event.registration.ticketing.lowestTicketPrice.amount');
    var json = {
        '@type': 'AggregateOffer',
        highPrice: (0, get_1.get)(item, 'event.registration.ticketing.highestTicketPrice.amount') ||
            lowPrice,
        lowPrice: lowPrice,
        offerCount: "".concat(ticketsLength),
        priceCurrency: (0, get_1.get)(item, 'event.registration.ticketing.currency'),
        availability: tickets.some(function (ticket) { return (0, get_stock_status_1.getStockStatus)(ticket) === get_stock_status_1.STOCK_STATUS.IN_STOCK; })
            ? "https://schema.org/".concat(get_stock_status_1.STOCK_STATUS.IN_STOCK)
            : "https://schema.org/".concat(get_stock_status_1.STOCK_STATUS.SOLD_OUT),
        validFrom: (0, get_1.get)(item, 'event.created'),
        url: (0, get_1.get)(item, 'event.eventPageUrl.base') +
            (0, get_1.get)(item, 'event.eventPageUrl.path'),
    };
    if (offers.length) {
        json.offers = offers;
    }
    return json;
};
exports.getJsonOffers = getJsonOffers;
var getTicketFinalPrice = function (ticket) {
    return parseFloat((0, get_1.getRawValue)(ticket, 'finalPrice.amount') || 0);
};
exports.getTicketFinalPrice = getTicketFinalPrice;
var getTicketPrice = function (ticket) {
    return parseFloat((0, get_1.getRawValue)(ticket, 'price.amount') || 0);
};
exports.getTicketPrice = getTicketPrice;
