import { translate, Interpolate } from 'react-i18next';
import { func } from 'prop-types';
import { Composites, RichText } from '@wix/wix-base-ui';

import React from 'react';
import { LEGACY_TAB_NAMES } from '../../tabs';
import { PROMOTE_SEO_LINK_GO_TO_SOCIAL_TAB } from '../../../core/bi/constants';
import { PROMOTE_SEO_CTA_CLICK } from '../../../core/bi/action-types';

const AdvancedPanelTwitterMigration = ({ setActiveTab, logBiEvent, t }) => (
  <Composites.RichText>
    <RichText type="T02 Light">
      <Interpolate
        i18nKey="adv-seo.adv-panel.twitter-migration.label"
        socialShareTab={
          <a
            onClick={() => {
              logBiEvent(PROMOTE_SEO_CTA_CLICK, {
                linkName: PROMOTE_SEO_LINK_GO_TO_SOCIAL_TAB,
                linkSource: 'panel',
                linkType: 'general',
              });
              setActiveTab(LEGACY_TAB_NAMES.SOCIAL_TAB_NAME);
            }}
          >
            {t('adv-seo.adv-panel.twitter-migration.button')}
          </a>
        }
      />
    </RichText>
  </Composites.RichText>
);

AdvancedPanelTwitterMigration.propTypes = {
  setActiveTab: func.isRequired,
  t: func.isRequired,
};

export default translate()(AdvancedPanelTwitterMigration);
