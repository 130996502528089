import { IDENTIFIERS } from '../../types/Identifiers';
import { CONTEXT_PROPS } from '../../types/ContextProps';
import { TAG_TYPES } from '../../types/TagTypes';
import PROPS from '../../types/Props';
import { updateValueByIdentifier } from '../values/update-by-identifier';
import { getValueByIdentifier } from '../values/get-by-identifier';
import { getDefaultValueByIdentifier } from './get-by-identifier';
export function applyDefaultTags(tags, context) {
    return Object.keys(IDENTIFIERS).reduce(function (acc, curr) {
        var value = getValueByIdentifier(acc, curr);
        if (!value) {
            var defaultValue = getDefaultValueByIdentifier(acc, curr, context);
            if (defaultValue) {
                acc = updateValueByIdentifier(acc, curr, defaultValue);
                if (isPageLevelOgImage(curr, defaultValue, context)) {
                    applyOgImageMeta(acc, context);
                }
            }
        }
        return acc;
    }, Array.isArray(tags) ? tags : []);
}
function isPageLevelOgImage(identifier, defaultValue, context) {
    return (identifier === IDENTIFIERS.OG_IMAGE &&
        defaultValue === context[CONTEXT_PROPS.OG_IMAGE]);
}
function applyOgImageMeta(tags, context) {
    var width = context[CONTEXT_PROPS.OG_IMAGE_WIDTH];
    var height = context[CONTEXT_PROPS.OG_IMAGE_HEIGHT];
    if (width && height) {
        var ogImageTag = tags.find(function (tag) {
            var type = tag.type, props = tag.props;
            if (type === TAG_TYPES.META) {
                return props && props[PROPS.PROPERTY] === 'og:image';
            }
            return false;
        });
        ogImageTag.meta = { width: width, height: height };
    }
}
