"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlogFeedRoute = void 0;
var ContextProps_1 = require("../types/ContextProps");
var get_1 = require("../utils/get");
var consts_1 = require("./consts");
var consts_2 = require("./static-page-v2/consts");
var utils_1 = require("./utils");
var getBlogFeedRoute = function (item) {
    var _a;
    var requiredApps = [consts_2.BLOG_APP_DEF_ID];
    var isHomePage = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.IS_HOME_PAGE)) === 'true';
    var installedApps = (_a = item === null || item === void 0 ? void 0 : item.context) === null || _a === void 0 ? void 0 : _a[ContextProps_1.CONTEXT_PROPS.INSTALLED_APPS];
    var shouldAppsRenderTag = requiredApps === null || requiredApps === void 0 ? void 0 : requiredApps.some(function (appDefId) {
        return installedApps === null || installedApps === void 0 ? void 0 : installedApps.find(function (app) {
            return [app.appDefinitionId, app.tpaPageId, app.managingAppDefId].includes(appDefId);
        });
    });
    var isBlogFeedPage = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.TPA_PAGE_ID)) === consts_1.BLOG_TPA_PAGE_ID;
    if ((isHomePage && shouldAppsRenderTag) || isBlogFeedPage) {
        var siteUrl = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_URL));
        return (0, utils_1.getRssFullURL)({
            siteUrl: siteUrl,
            rssRoute: consts_1.RSS_ROUTES[consts_1.RSS_ROUTE_TYPES.BLOG],
        });
    }
    return '';
};
exports.getBlogFeedRoute = getBlogFeedRoute;
