import { KEYS, ERROR_NAMES } from '../utils/maps';
import get from 'lodash/get';
import { ERRORS_MAP } from '../errors-map';

const ERRORS_TYPES = {
  [KEYS.JSON_LD]: {
    [ERROR_NAMES.INVALID_JSON]: 'Structured data must be in JSON format only',
    [ERROR_NAMES.INVALID_TAG_STRUCTURE]:
      'Structured data must be inside a script tag',
    [ERROR_NAMES.INVALID_STRING_LENGTH]: 'script character limit reached',
    [ERROR_NAMES.HTML_TAG_INSIDE_SCRIPT]:
      'Script cannot contain html tags in it',
  },
};
export const getErrorType = (key, error) => {
  if (!error) {
    return 'INVALID_SCRIPT_TAG';
  }

  const { name, validatorIndex } = error;
  let errorType = get(
    ERRORS_TYPES,
    [key, name],
    name ? name : 'INVALID_SCRIPT_TAG',
  );
  if (errorType === ERROR_NAMES.CUSTOM_VALIDATOR) {
    errorType = get(
      ERRORS_MAP(),
      [key, name, validatorIndex],
      'INVALID_SCRIPT_TAG',
    );
  }
  return errorType;
};
