var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildOgMetaRefs, buildStructuredData, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-stores-product';
import storesProductPagePreset from './schema-presets/stores-product-page';
var IDs = adapter.IDs;
export var STORES_PRODUCT = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}} | {{").concat(IDs.SITE_NAME, "}}"))
    ], buildOgMetaRefs({ excludeDescription: true }), true), [
        buildOgMeta('og:description', "{{".concat(IDs.DESCRIPTION, "}}")),
        buildOgMeta('og:url', "{{".concat(IDs.PRODUCT_PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(IDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'product'),
        buildStandardMeta('robots', 'index'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.PRODUCT_PAGE_URL, "}}") }),
        buildOgMeta('product:price:amount', "{{".concat(IDs.PRICE, "}}")),
        buildOgMeta('product:price:currency', "{{".concat(IDs.CURRENCY, "}}")),
        buildOgMeta('og:availability', "{{".concat(IDs.AVAILABILITY_META_TAG, "}}")),
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}"))
    ], false), buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: storesProductPagePreset.schema,
            schemaType: storesProductPagePreset.type,
            disabled: [
                "{{".concat(IDs.DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ], false),
};
