import React from 'react';
import { getCurrentItemPreview } from '../app-base';
import { object, string } from 'prop-types';
import { KEYS } from '../utils/maps';

function getRegExForProp(prop) {
  return new RegExp('(.*' + prop + '=")(.*)(".*)');
}

function splitByIdentifier(html, identifier) {
  const re = getRegExForProp(identifier === 'LINK' ? 'href' : 'content');
  const match = html.match(re);
  if (!match) {
    return null;
  }
  return [match[1], match[2], match[3]];
}

export const TagPreview = ({
  generalIdentifier,
  label,
  value,
  textProps,
  textComponent: Text,
}) => {
  const html = getCurrentItemPreview(KEYS.ADVANCED_TAGS)(
    { generalIdentifier, label },
    value,
  );
  const split = splitByIdentifier(html, generalIdentifier);
  if (!split) {
    return '';
  }
  return (
    <Text {...textProps}>
      {split.map((s) =>
        s === value ? (
          <Text size="small" weight="normal">
            {s}
          </Text>
        ) : (
          s
        ),
      )}
    </Text>
  );
};

TagPreview.propTypes = {
  generalIdentifier: string.isRequired,
  label: string.isRequired,
  value: string.isRequired,
  textProps: object,
};

TagPreview.defaultProps = {
  textProps: {},
  textComponent: 'span',
};
