import { getKeys } from '../../adapters/utils';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { get } from '../../utils/get';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
export var IDs = {
    SEARCH_TERM: 'search-page.search-term',
    ALL_RESULTS_URL: 'search-page.all-results-url',
    PAGE_URL: 'search-page.page-url',
    RESULTS_TOTAL: 'search-page.results-total',
};
var SchemaTypes = {};
var userVisible = [IDs.SEARCH_TERM, IDs.RESULTS_TOTAL];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.SEARCH_TERM] = get(item, 'searchTerm'),
            _a[IDs.ALL_RESULTS_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.SEARCH_PAGE, item, get(item, 'allResultsUrl')),
            _a[IDs.PAGE_URL] = get(item, 'pageUrl'),
            _a[IDs.RESULTS_TOTAL] = get(item, 'resultsTotal'),
            _a;
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
