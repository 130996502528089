import React from 'react';

import AdvancedPanel from '../components/advanced-panel';

export const AdvancedTab = ({
  language,
  openHelpCenter,
  onSave,
  onAddTempTag,
  onRemoveTempTag,
  onTagDeleted,
  validateAndSetState,
  logBiEvent,
  jsonLD,
  schemasMap,
  isSchemaMissingBusinessInfo,
  onSdEdit,
  onSdDelete,
  onToggleSdHide,
  canonicalUrl,
  customTags,
  customTagsArray,
  advancedTags,
  getImageFromMM,
  isCoBranded,
  experiments,
  getDefaultPatternValue,
  setActiveTab,
  itemType,
  siteContext,
  siteDashboardURL,
  isIndexEnabled,
  isIndexEnabledFromSite,
  isIndexEnabledFromPermission,
  isIndexEnabledFromUserPattern,
  robotsTag,
  showResetToPatternButton,
  msid,
}) => (
  <AdvancedPanel
    onSave={onSave}
    onChange={validateAndSetState}
    onAddTempTag={onAddTempTag}
    onRemoveTempTag={onRemoveTempTag}
    onTagDeleted={onTagDeleted}
    showMultilingual={!!language.current}
    jsonLD={jsonLD.value || ''}
    jsonLDErrorMessage={jsonLD.error}
    schemasMap={schemasMap}
    isSchemaMissingBusinessInfo={isSchemaMissingBusinessInfo}
    onSdEdit={onSdEdit}
    onSdDelete={onSdDelete}
    onToggleSdHide={onToggleSdHide}
    advancedTags={advancedTags || []}
    customTags={customTags ? customTags.value : ''}
    customTagsArray={customTagsArray || []}
    customTagsErrorMessage={customTags ? customTags.error : ''}
    canonicalUrl={canonicalUrl.value}
    canonicalUrlErrorMessage={canonicalUrl.error}
    openHelpCenter={openHelpCenter}
    getImageFromMM={getImageFromMM}
    logBiEvent={logBiEvent}
    isCoBranded={isCoBranded}
    experiments={experiments}
    getDefaultPatternValue={getDefaultPatternValue}
    setActiveTab={setActiveTab}
    itemType={itemType}
    siteContext={siteContext}
    siteDashboardURL={siteDashboardURL}
    isIndexEnabled={isIndexEnabled.value}
    isIndexEnabledFromSite={isIndexEnabledFromSite}
    isIndexEnabledFromPermission={isIndexEnabledFromPermission}
    isIndexEnabledFromUserPattern={isIndexEnabledFromUserPattern}
    robotsTagValue={robotsTag.value || ''}
    showResetToPatternButton={showResetToPatternButton}
    msid={msid}
  />
);
