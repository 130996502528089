var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { siteIDs } from '../../adapters/utils';
import { buildLink, buildOgMeta, buildOgMetaRefs, buildStandardMeta, buildTitle, buildTwitterMeta, } from '../../types/builders';
import { IDs } from './adapter-search-page';
var SITE_NAME = siteIDs.SITE_NAME;
var SEARCH_TERM = IDs.SEARCH_TERM, ALL_RESULTS_URL = IDs.ALL_RESULTS_URL, PAGE_URL = IDs.PAGE_URL;
export var SEARCH_PAGE = {
    tags: __spreadArray(__spreadArray([], buildOgMetaRefs(), true), [
        buildTitle("{{".concat(SEARCH_TERM, "}} - {{").concat(SITE_NAME, "}}")),
        buildStandardMeta('robots', 'noindex'),
        buildLink({ rel: 'canonical', href: "{{".concat(ALL_RESULTS_URL, "}}") }),
        buildOgMeta('og:title', "{{".concat(SEARCH_TERM, "}}")),
        buildOgMeta('og:url', "{{".concat(PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website'),
        buildTwitterMeta('twitter:card', 'summary_large_image'),
        buildTwitterMeta('twitter:title', "{{".concat(SEARCH_TERM, "}}")),
    ], false),
};
