"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.safelyParseJsonData = void 0;
var clone_1 = require("./clone");
function safelyParseJsonData(data) {
    var result = {
        tags: [],
    };
    try {
        result = (0, clone_1.clone)(JSON.parse(data));
    }
    catch (error) { }
    return result;
}
exports.safelyParseJsonData = safelyParseJsonData;
