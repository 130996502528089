import React, { useState, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { translate } from 'react-i18next';
import { Tooltip, TextButton } from '@wix/wix-base-ui';
import { Add } from '@wix/wix-ui-icons-common';

import {
  PROMOTE_SEO_ACTION_CLICK,
  PROMOTE_SEO_MODAL_VIEW,
  PROMOTE_SEO_CTA_CLICK,
} from '../../../../core/bi/action-types';
import {
  buildSchemaItems,
  SD_ACTION_TYPES,
  BI_CTA_DATA,
} from '../../../../core/utils/structured-data';
import { PopupWrapper } from '../../ui/popup-wrapper';
import EditSchemaModal from './modals/edit-schema-modal';
import ConfirmRemoveSchemaModal from './modals/confirm-remove-schema-modal';
import SchemaItem from './schema-item';
import { schemaPresets } from './schema-presets';
import { MODAL_TYPES } from './constants';
import styles from './schemas-list.scss';
import { DataHooks } from './test-selectors';

const SchemasList = ({
  logBiEvent,
  onSdEdit,
  onTestSchema,
  onSdDelete,
  onToggleSdHide,
  schemasMap,
  siteDashboardURL,
  isSchemaMissingBusinessInfo,
  itemType,
  siteContext,
  t,
  experiments,
}) => {
  const [openedModal, setOpenedModal] = useState({ type: MODAL_TYPES.NONE });
  useEffect(() => {
    if (openedModal.type) {
      logBiEvent(PROMOTE_SEO_MODAL_VIEW, {
        modalName: openedModal.type,
      });
    }
  }, [openedModal.type, logBiEvent]);

  const modalHandlers = {
    openEditModal: (schema = {}) => {
      const action = schema.schemaType
        ? SD_ACTION_TYPES.EDIT_SCHEMA
        : SD_ACTION_TYPES.ADD_SCHEMA;

      logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
        ...BI_CTA_DATA,
        action,
      });

      setOpenedModal({
        type: schema.schemaType ? MODAL_TYPES.EDIT : MODAL_TYPES.ADD,
        schema,
      });
    },
    openConfirmRemoveSchemaModal: (schema = {}) => {
      setOpenedModal({
        type: MODAL_TYPES.WARNING,
        schema,
      });
    },
    closeModal: () => setOpenedModal({ type: MODAL_TYPES.NONE }),
  };

  const schemaHandlers = {
    applySchema: (schema) => onSdEdit(schema),
    testSchema: (schema) => {
      logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
        ...BI_CTA_DATA,
        action: SD_ACTION_TYPES.TEST_SCHEMA,
      });
      onTestSchema(schema.value);
    },
    removeSchema: (schema) => {
      logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
        ...BI_CTA_DATA,
        action: SD_ACTION_TYPES.REMOVE_SCHEMA,
      });
      modalHandlers.openConfirmRemoveSchemaModal(schema);
      onSdDelete(schema);
    },
    toggleHide: (schema) => {
      logBiEvent(PROMOTE_SEO_ACTION_CLICK, {
        ...BI_CTA_DATA,
        action: schema.disabled
          ? SD_ACTION_TYPES.SHOW_SCHEMA
          : SD_ACTION_TYPES.HIDE_SCHEMA,
        fieldName: schema.displayName || schema.schemaType,
      });
      onToggleSdHide(schema);
    },
  };

  const navigateToSettings = () => {
    const patternAddress = `${siteDashboardURL}/settings/business-info?referralInfo=editor-seo-panel`;
    window.open(patternAddress, '_blank');
    logBiEvent(PROMOTE_SEO_CTA_CLICK, {
      linkName: 'go to business settings',
      linkType: 'openSettings',
      linkSource: 'structured data',
    });
  };

  const schemaItems = buildSchemaItems({
    schemasMap,
    schemaPresets,
    isSchemaMissingBusinessInfo,
    itemType,
    siteContext,
    experiments,
    t,
  });
  const isAddingEnabled = schemaItems.length < 5;

  return (
    <div data-hook="schemas-list" className={styles.root}>
      <>
        {schemaItems.map((item) => (
          <SchemaItem
            logBiEvent={logBiEvent}
            schemaItem={item}
            key={item.schemaType}
            handlers={{
              ...schemaHandlers,
              ...modalHandlers,
              navigateToSettings,
            }}
          />
        ))}
        <Tooltip
          disabled={isAddingEnabled}
          maxWidth={228}
          shouldTranslate={false}
          content={t(
            'adv-seo.adv-panel.structured-data.schemas-list.add-schema.tooltip.label.site-settings',
          )}
        >
          <div className={styles.addSchemaButtonContainer}>
            <TextButton
              shouldTranslate={false}
              onClick={isAddingEnabled && modalHandlers.openEditModal}
              prefixIcon={<Add />}
              size="small"
              underline="none"
              dataHook={DataHooks.SchemasAddButton}
              disabled={!isAddingEnabled}
            >
              {t(
                'adv-seo.adv-panel.structured-data.schemas-list.footer.add-schema.cta.label',
              )}
            </TextButton>
          </div>
        </Tooltip>
      </>
      {Boolean(openedModal.type) && (
        <PopupWrapper containerId="advanced-panel-container">
          {openedModal.type === MODAL_TYPES.WARNING ? (
            <ConfirmRemoveSchemaModal
              logBiEvent={logBiEvent}
              onClose={modalHandlers.closeModal}
              onApply={() => schemaHandlers.removeSchema(openedModal.schema)}
            />
          ) : (
            <EditSchemaModal
              initialSchema={openedModal.schema}
              logBiEvent={logBiEvent}
              onApply={schemaHandlers.applySchema}
              onClose={modalHandlers.closeModal}
              otherSchemas={schemaItems.filter(
                ({ schemaType }) =>
                  schemaType !== openedModal?.schema?.schemaType,
              )}
              sourcePreset={openedModal.sourcePreset}
            />
          )}
        </PopupWrapper>
      )}
    </div>
  );
};

SchemasList.propType = {
  logBiEvent: func.isRequired,
  onSdEdit: func.isRequired,
  onTestSchema: func.isRequired,
  onSdDelete: func.isRequired,
  schemasMap: array.isRequired,
  isSchemaMissingBusinessInfo: bool.isRequired,
  siteDashboardURL: string.isRequired,
  t: func.isRequired,
  experiments: object.isRequired,
};

export default translate()(SchemasList);
