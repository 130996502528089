import React, { PureComponent } from 'react';
import { func, string, bool, node } from 'prop-types';
import { InfoIcon } from '@wix/wix-base-ui';

import TooltipContent from './tooltip-content';

class CustomInfoIcon extends PureComponent {
  static displayName = 'InfoIcon';
  static getDisplayName = () => 'InfoIcon';

  static propTypes = {
    text: string,
    linkText: string,
    onLinkClick: func,
    shouldTranslate: bool,
    error: bool,
    customSymbol: node,
    tooltipContent: node,
  };

  render() {
    const {
      text,
      linkText,
      onLinkClick,
      error,
      customSymbol,
      shouldTranslate,
      tooltipContent,
    } = this.props;
    return (
      <InfoIcon
        customSymbol={customSymbol}
        error={error}
        closeOnContentMouseClick={true}
        shouldTranslate={false}
        onLinkClick={() => {}}
        text={
          tooltipContent ?? (
            <TooltipContent
              text={text}
              linkText={linkText}
              onLinkClick={onLinkClick}
              shouldTranslate={shouldTranslate}
            />
          )
        }
      />
    );
  }
}

export default CustomInfoIcon;
