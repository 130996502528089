"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertWixImageForMetaTags = exports.WIX_IMAGE_PREFIXES = exports.DEFAULT_IMAGE_METHOD = void 0;
var build_og_image_preview_1 = require("../utils/build-og-image-preview");
exports.DEFAULT_IMAGE_METHOD = 'fill';
exports.WIX_IMAGE_PREFIXES = {
    WIX_IMAGE: 'wix:image:',
    IMAGE: 'image:',
};
function convertWixImageForMetaTags(originalMetaTags, parseMediaItemUri) {
    var transformWixImageToPublicURL = function (wixImageUri) {
        var _a = parseMediaItemUri(wixImageUri), error = _a.error, mediaId = _a.mediaId, width = _a.width, height = _a.height, title = _a.title;
        return error
            ? ''
            : (0, build_og_image_preview_1.buildOgImagePreviewUrl)({
                url: mediaId,
                width: width,
                height: height,
                method: exports.DEFAULT_IMAGE_METHOD,
                name: title,
            });
    };
    var isWixImage = function (url) {
        if (url === void 0) { url = ''; }
        return Object.values(exports.WIX_IMAGE_PREFIXES).some(function (prefix) { return url.startsWith(prefix); });
    };
    var hasWixImage = function (metaTags) { return metaTags.some(function (url) { return isWixImage(url); }); };
    var resolveWixImage = function (metaTags) {
        return metaTags.map(function (tag) {
            return isWixImage(tag.content)
                ? __assign(__assign({}, tag), { content: transformWixImageToPublicURL(tag.content) }) : tag;
        });
    };
    var resolveWixImageObj = function (metaTags) {
        return Object.keys(metaTags).reduce(function (acc, curr) {
            var value = metaTags[curr];
            acc[curr] = isWixImage(value)
                ? transformWixImageToPublicURL(value)
                : value;
            return acc;
        }, {});
    };
    if (Array.isArray(originalMetaTags)) {
        return hasWixImage(originalMetaTags.map(function (tag) { return tag.content; }))
            ? resolveWixImage(originalMetaTags)
            : originalMetaTags;
    }
    return hasWixImage(Object.values(originalMetaTags))
        ? resolveWixImageObj(originalMetaTags)
        : originalMetaTags;
}
exports.convertWixImageForMetaTags = convertWixImageForMetaTags;
