import { getImageObject, getBasicVideosObject, } from '../../adapters/structure-data-utils';
import { FIELD_TYPE, MEDIA_PREFIX, IMAGES_PROPERTY } from './consts';
import { SCHEMA_TYPES } from './schema-presets/schema-types';
export var getStructuredData = function (collections, _a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, isSdPattern = _c.isSdPattern;
    var imagesData = [];
    var videosData = [];
    collections === null || collections === void 0 ? void 0 : collections.forEach(function (_a) {
        var id = _a.id, fields = _a.fields;
        fields.forEach(function (field) {
            if (!field.connected) {
                return;
            }
            switch (field.type.toLowerCase()) {
                case FIELD_TYPE.IMAGE:
                    imagesData.push({
                        url: getValue(id, field, 'url'),
                        width: getValue(id, field, 'width'),
                        height: getValue(id, field, 'height'),
                        name: getValue(id, field, 'alt'),
                    });
                    break;
                case FIELD_TYPE.VIDEO:
                    var video = {
                        thumbnailUrl: getValue(id, field, 'thumbnailUrl'),
                        name: getValue(id, field, 'title'),
                        description: getValue(id, field, 'description'),
                        uploadDate: getValue(id, field, 'uploadDate'),
                    };
                    if (video.thumbnailUrl && video.uploadDate) {
                        if (video.thumbnailUrl.startsWith(MEDIA_PREFIX)) {
                            video.thumbnailUrl = video.thumbnailUrl.slice(MEDIA_PREFIX.length);
                        }
                        videosData.push(video);
                    }
                    break;
                default:
                    break;
            }
        });
    });
    var images = getImageObject(IMAGES_PROPERTY, imagesData, false, true).images;
    var videos = getBasicVideosObject(videosData, { includeContext: true }).videos;
    return _b = {},
        _b[SCHEMA_TYPES.WIX_DATA_PAGE_ITEM_IMAGES] = JSON.stringify(images),
        _b[SCHEMA_TYPES.WIX_DATA_PAGE_ITEM_VIDEOS] = JSON.stringify(videos),
        _b;
    function getValue(collectionId, field, key) {
        var _a;
        if (isSdPattern) {
            return "{{".concat(collectionId, ".").concat(field.key, ".").concat(key, "}}");
        }
        return ((_a = field.value) === null || _a === void 0 ? void 0 : _a[key]) || (field === null || field === void 0 ? void 0 : field[key]);
    }
};
