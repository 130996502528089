import { getKeys } from '../../adapters/utils';
export var IDs = {};
var SchemaTypes = {};
var userVisible = [];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function () {
        return {};
    },
    getSdStatus: function () { },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
