"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocation = void 0;
var get_location_type_1 = require("../calculated/get-location-type");
var get_1 = require("../../../utils/get");
var get_event_url_1 = require("../calculated/get-event-url");
var getLocation = function (item) {
    if (!item.event) {
        return '';
    }
    var locationType = (0, get_location_type_1.getLocationType)(item);
    var name = (0, get_1.get)(item, 'event.location.name');
    var address = (0, get_1.get)(item, 'event.location.address');
    var url = (0, get_event_url_1.getEventUrl)(item);
    var location = __assign({ '@type': locationType }, (url ? { url: url } : {}));
    if (name) {
        location.name = name;
    }
    if (locationType === get_location_type_1.LOCATION_TYPES.PHYSICAL) {
        location.address = address;
    }
    return location;
};
exports.getLocation = getLocation;
