"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationType = exports.LOCATION_TYPES = void 0;
var get_1 = require("../../../utils/get");
var RAW_LOCATION_TYPES = {
    ONLINE: 'ONLINE',
};
exports.LOCATION_TYPES = {
    VIRTUAL: 'VirtualLocation',
    PHYSICAL: 'Place',
};
var getLocationType = function (item) {
    if (item === void 0) { item = {}; }
    return (0, get_1.get)(item, 'event.location.type') === RAW_LOCATION_TYPES.ONLINE
        ? exports.LOCATION_TYPES.VIRTUAL
        : exports.LOCATION_TYPES.PHYSICAL;
};
exports.getLocationType = getLocationType;
