import {
  createValidator,
  KEYS_FOR_CHANGE,
  KEYS_FOR_SAVE,
} from '../validators/validators';

export const initValidators = (customValidators = {}, options = {}) => ({
  validate: createValidator(customValidators, KEYS_FOR_CHANGE, options),
  validateBeforeSave: createValidator(customValidators, KEYS_FOR_SAVE, options),
});
