var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildStructuredData, buildOgMetaRefs, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-blog-post';
import { siteIDs } from '../../adapters/utils';
import blogPosting from './schema-presets/blog-post-blog-posting';
import FAQ from './schema-presets/blog-post-faq';
import newsArticle from './schema-presets/blog-post-news-article';
import article from './schema-presets/blog-post-article';
import { LINK_TAG_TYPES } from '../../types/LinkTagTypes';
import { getRssTitle } from '../utils';
var IDs = adapter.IDs;
export var BLOG_POST = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}}"))
    ], buildOgMetaRefs({ excludeDescription: true }), true), [
        buildOgMeta('og:description', "{{".concat(IDs.EXCERPT, "}}")),
        buildOgMeta('og:url', "{{".concat(IDs.POST_PAGE_URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'article'),
        buildOgMeta('og:image', "{{".concat(IDs.COVER_IMAGE, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.COVER_IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.COVER_IMAGE_WIDTH, "}}")),
        buildOgMeta('article:author', "{{".concat(IDs.AUTHOR, "}}")),
        buildOgMeta('article:published_time', "{{".concat(IDs.PUBLISH_DATE, "}}")),
        buildOgMeta('article:modified_time', "{{".concat(IDs.LAST_MODIFIED_DATE, "}}")),
        buildLink({
            rel: 'amphtml',
            href: "{{".concat(IDs.AMP_URL, "}}"),
            disabled: "{{".concat(IDs.DISABLE_AMP, "}}"),
        }),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.POST_PAGE_URL, "}}") }),
        buildLink({
            rel: 'alternate',
            type: LINK_TAG_TYPES.RSS,
            href: "{{".concat(IDs.BLOG_FEED_ROUTE, "}}"),
            title: getRssTitle("{{".concat(siteIDs.SITE_NAME, "}}")),
        }),
        buildStandardMeta('robots', "{{".concat(IDs.INDEX_POST, "}}"))
    ], false), buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: blogPosting.schema,
            schemaType: blogPosting.type,
            variants: [
                {
                    schema: newsArticle.schema,
                    schemaType: newsArticle.type,
                    disabled: [
                        "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                        '{{options.disable-pattern-schema}}',
                    ],
                },
                {
                    schema: article.schema,
                    schemaType: article.type,
                    disabled: [
                        "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                        '{{options.disable-pattern-schema}}',
                    ],
                },
            ],
            disabled: [
                "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
        buildStructuredData({
            schema: FAQ.schema,
            schemaType: FAQ.type,
            enabledByExperiment: "specs.seo.EnableFaqSD",
            disabled: [
                "{{".concat(IDs.DISABLE_FAQ_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
            removeSchemaIfEmpty: FAQ.requiredSchemaVariables,
        }),
    ], false),
};
