import { updateValueByIdentifier } from '../tags/values/update-by-identifier';
import { getValueByIdentifier } from '../tags/values/get-by-identifier';
import { IDENTIFIERS } from '../types/Identifiers';
import { siteIDs } from '../adapters/utils';
export var resolvePagination = function (tags, context) {
    if (tags === void 0) { tags = []; }
    if (context === void 0) { context = {}; }
    var _a = context, _b = siteIDs.CURRENT_PAGE_NUMBER, currentPageNumber = _a[_b], _c = siteIDs.TOTAL_PAGE_COUNT, totalPageCount = _a[_c];
    if (!currentPageNumber ||
        !totalPageCount ||
        Number(currentPageNumber) === 1) {
        return tags;
    }
    var title = getValueByIdentifier(tags, IDENTIFIERS.TITLE);
    var paginationSuffix = "".concat(currentPageNumber, "/").concat(totalPageCount);
    var legacyBlogPagination = "Page ".concat(currentPageNumber, " of ").concat(totalPageCount);
    if ((title === null || title === void 0 ? void 0 : title.endsWith(paginationSuffix)) ||
        (title === null || title === void 0 ? void 0 : title.endsWith(legacyBlogPagination))) {
        return tags;
    }
    return updateValueByIdentifier(tags, IDENTIFIERS.TITLE, "".concat(title, " ").concat(paginationSuffix));
};
