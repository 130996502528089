var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { siteIDs } from '../../adapters/utils';
import { buildLink, buildOgMeta, buildStandardMeta, buildStructuredData, buildTitle, buildTwitterMetaRefs, } from '../../types/builders';
import adapter from './adapter-portfolio-projects';
import imagesSchema from './schema-presets/project-images';
var IDs = adapter.IDs;
export var PORTFOLIO_PROJECTS = {
    tags: __spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildStandardMeta('description', "{{".concat(IDs.DESCRIPTION, "}}")),
        buildStandardMeta('robots', 'index'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.PROJECT_PAGE_URL, "}}") }),
        buildOgMeta('og:title', "{{".concat(IDs.TITLE, "}}")),
        buildOgMeta('og:description', "{{".concat(IDs.DESCRIPTION, "}}")),
        buildOgMeta('og:url', "{{".concat(IDs.PROJECT_PAGE_URL, "}}")),
        buildOgMeta('og:image', "{{".concat(IDs.COVER_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.COVER_IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.COVER_IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website')
    ], buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: imagesSchema.schema,
            schemaType: imagesSchema.type,
            disabled: [
                "{{".concat(IDs.DISABLE_IMAGES_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ], false),
};
