"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ContextProps_1 = require("../../types/ContextProps");
var get_1 = require("../../utils/get");
var sanitize_url_1 = require("../../utils/sanitize-url");
var utils_1 = require("../../adapters/utils");
var update_by_identifier_1 = require("../../tags/values/update-by-identifier");
var Identifiers_1 = require("../../types/Identifiers");
var consts_1 = require("../../consts");
var IDs = {
    PAGE_NAME: 'page.name',
    PAGE_URL: 'page.url',
    SITE_NAME: 'site.name',
    HOME_PAGE_TITLE: 'site.homePageTitle',
    INDEX_SITE: 'site.index',
    INDEX_PAGE: 'page.index',
    IS_HOME_PAGE: 'page.isHomePage',
    TITLE: 'page.title',
    DESCRIPTION: 'page.description',
    MAIN_IMAGE: 'page.image',
    IMAGE_WIDTH: 'page.imageWidth',
    IMAGE_HEIGHT: 'page.imageHeight',
    FB_ADMINS: 'site.facebookAdminId',
    NEXT: 'site.nextLink',
    PREV: 'site.prevLink',
};
var userVisible = [
    IDs.PAGE_NAME,
    IDs.PAGE_URL,
    IDs.SITE_NAME,
    IDs.TITLE,
    IDs.DESCRIPTION,
];
exports.default = {
    IDs: IDs,
    getData: function (item, options) {
        var _a;
        if (options === void 0) { options = {}; }
        return (_a = {},
            _a[IDs.PAGE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PAGE_NAME)),
            _a[IDs.PAGE_URL] = (0, sanitize_url_1.sanitizeUrl)((0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.DEFAULT_URL))),
            _a[IDs.SITE_NAME] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME)),
            _a[IDs.HOME_PAGE_TITLE] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.HOME_PAGE_TITLE)),
            _a[IDs.INDEX_SITE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_SITE)),
            _a[IDs.INDEX_PAGE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_PAGE)),
            _a[IDs.IS_HOME_PAGE] = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.IS_HOME_PAGE)),
            _a[IDs.TITLE] = getTitle(item, options),
            _a[IDs.DESCRIPTION] = options.ignoreLegacy
                ? ''
                : (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.DESCRIPTION)),
            _a[IDs.MAIN_IMAGE] = getImage(item, options),
            _a[IDs.IMAGE_WIDTH] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.OG_IMAGE_WIDTH)),
            _a[IDs.IMAGE_HEIGHT] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.OG_IMAGE_HEIGHT)),
            _a[IDs.FB_ADMINS] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.FB_ADMINS)),
            _a[IDs.NEXT] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.NEXT)),
            _a[IDs.PREV] = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PREV)),
            _a);
    },
    getSdStatus: function () { return consts_1.SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getLegacySeoBlob: function (item) {
        var tags = (0, update_by_identifier_1.updateValueByIdentifier)([], Identifiers_1.IDENTIFIERS.TITLE, (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.TITLE)));
        tags = (0, update_by_identifier_1.updateValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.DESCRIPTION, (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.DESCRIPTION)));
        tags = (0, update_by_identifier_1.updateValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.OG_IMAGE, (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.OG_IMAGE)));
        tags = (0, update_by_identifier_1.updateValueByIdentifier)(tags, Identifiers_1.IDENTIFIERS.ROBOTS, ['noindex', 'false'].includes((0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.INDEX_PAGE)))
            ? 'noindex'
            : '');
        return { tags: tags };
    },
    getKeys: function () { return (0, utils_1.getKeys)(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
function getImage(item, options) {
    if (options === void 0) { options = {}; }
    if (options.ignoreLegacy) {
        return (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_OG_IMAGE)) || '';
    }
    else {
        return ((0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.OG_IMAGE)) ||
            (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_OG_IMAGE)));
    }
}
function getTitle(item, options) {
    if (options === void 0) { options = {}; }
    if (!options.ignoreLegacy) {
        var title = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.TITLE));
        if (title) {
            return title;
        }
    }
    var isHomePage = (0, get_1.getRawValue)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.IS_HOME_PAGE));
    if (isHomePage) {
        var siteName_1 = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME));
        return siteName_1 || '';
    }
    var homePageTitle = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.HOME_PAGE_TITLE));
    var siteName = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.SITE_NAME));
    var pageName = (0, get_1.get)(item, "context.".concat(ContextProps_1.CONTEXT_PROPS.PAGE_NAME));
    return [pageName, homePageTitle || siteName].filter(function (_) { return !!_; }).join(' | ');
}
