import {
  getSlug,
  getParent,
  isTpaSectionPage,
} from '@wix/seo-page-hierarchy-library';
import { useExperiments } from '@wix/wix-experiments-react';
import {
  useHasUrlHierarchyAndHasParent,
  usePageHierarchyContext,
} from '../../../hooks/page-hierarchy';
import { removeLeadingSlash } from '../../../app/url-hooks';
import React, { useMemo } from 'react';
import { Text } from '@wix/wix-base-ui';
import { pageIdAtom } from '../../../app/atoms';
import { useAtomValue } from 'jotai';

const useGetParentSlug = () => {
  const pageId = useAtomValue(pageIdAtom);
  const { siteStructureGraph } = usePageHierarchyContext();
  return removeLeadingSlash(
    getSlug(siteStructureGraph, getParent(siteStructureGraph, pageId)),
  );
};

const urlPrefixContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '180px',
};

const UrlPrefixWithHierarchy = () => {
  const parentSlug = useGetParentSlug();

  return (
    <div style={urlPrefixContainerStyle}>
      <div
        style={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'auto',
          marginRight: '3px',
        }}
      >
        <Text shouldTranslate={false} skin="placeholder">
          ... /
        </Text>
      </div>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 'auto',
          minWidth: 0,
          marginRight: '3px',
        }}
      >
        <Text shouldTranslate={false} skin="placeholder">
          {parentSlug}
        </Text>
      </div>
      <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}>
        <Text shouldTranslate={false} skin="placeholder">
          /
        </Text>
      </div>
    </div>
  );
};

const UrlPrefixWithoutHierarchy = () => {
  return (
    <>
      <Text shouldTranslate={false} skin="placeholder">
        /
      </Text>
    </>
  );
};

export const UrlPrefix: React.FC<{ isEditorX: boolean }> = ({ isEditorX }) => {
  const hasUrlHierarchy = useHasUrlHierarchyAndHasParent();

  return hasUrlHierarchy ? (
    <UrlPrefixWithHierarchy />
  ) : (
    <UrlPrefixWithoutHierarchy />
  );
};

export const useGetIsCurrentPageTpaSectionPage = (isEditorX: boolean) => {
  const { experiments } = useExperiments();
  const pageId = useAtomValue(pageIdAtom);
  const { siteStructureGraph } = usePageHierarchyContext();

  const isCurrentPageTpaSectionPage = useMemo(
    () => (!isEditorX ? isTpaSectionPage(siteStructureGraph, pageId) : false),
    [siteStructureGraph, pageId, isEditorX],
  );

  return isCurrentPageTpaSectionPage;
};
