import { translate, Interpolate } from 'react-i18next';
import React, { useCallback, useContext } from 'react';
import { RichText } from '@wix/wix-base-ui';
import { RobotsTagContext } from '../../../../core/components/robots-tag/robots-tag-hooks';
import { PROMOTE_SEO_CTA_CLICK } from '../../../../core/bi/action-types';
import { PROMOTE_SEO_LINK_EXPAND_META_TAGS } from '../../../../core/bi/constants';
import { func } from 'prop-types';

export const RobotsTagNoMigration = translate()(
  ({ t, expandMetaTagsAccordionSection }) => {
    const { logBiEvent } = useContext(RobotsTagContext);
    const onClick = useCallback(() => {
      logBiEvent(PROMOTE_SEO_CTA_CLICK, {
        linkName: PROMOTE_SEO_LINK_EXPAND_META_TAGS,
        linkSource: 'panel',
        linkType: 'general',
      });
      expandMetaTagsAccordionSection();
    }, [logBiEvent, expandMetaTagsAccordionSection]);

    return (
      <RichText type="T02 light">
        <Interpolate
          i18nKey="adv-seo.common.robots-tag.no-migration.title"
          additionalTags={
            <a onClick={onClick}>{t('adv-seo.common.additional-tags.title')}</a>
          }
        />
      </RichText>
    );
  },
);

RobotsTagNoMigration.propTypes = {
  expandMetaTagsAccordionSection: func.isRequired,
};
