"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
exports.IDs = {
    INDEX_POST: 'blog-post.index',
    FAQ: 'blog-post.faq',
    TITLE: 'blog-post.title',
    ID: 'blog-post.id',
    HEADLINE: 'blog-post.headline',
    DESCRIPTION: 'blog-post.description',
    POST_PAGE_URL: 'blog-post.url',
    COVER_IMAGE: 'blog-post.cover.image',
    COVER_IMAGE_HEIGHT: 'blog-post.cover.image.height',
    COVER_IMAGE_WIDTH: 'blog-post.cover.image.width',
    AUTHOR: 'blog-post.author',
    AUTHOR_URL: 'blog-post.author.url',
    AUTHOR_IMAGE_URL: 'blog-post.author.image.url',
    AUTHOR_IMAGE_HEIGHT: 'blog-post.author.image.height',
    AUTHOR_IMAGE_WIDTH: 'blog-post.author.image.width',
    PUBLISHER_ID: 'blog-post.publisher.id',
    PUBLISHER_NAME: 'blog-post.publisher.name',
    PUBLISH_DATE: 'blog-post.publish.date',
    LAST_MODIFIED_DATE: 'blog-post.modified.date',
    EXCERPT: 'blog-post.excerpt',
    AMP_URL: 'blog-post.amp.url',
    DISABLE_SCHEMA: 'blog-post.disable-schema',
    DISABLE_AMP: 'blog-post.disable-amp',
    CATEGORIES: 'blog-post.categories',
    MAIN_CATEGORY: 'blog-post.main-category',
    SD_DISABLE_SCHEMA: 'blog-post.disable-schema',
    DISABLE_FAQ_SCHEMA: 'blog-post.disable-faq-schema',
    BLOG_FEED_ROUTE: 'blog-post.feed-route',
    RICH_CONTENT: 'blog-post.rich-content',
    MAIN_IMAGE: 'blog-post.main-image',
};
