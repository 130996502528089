import React from 'react';
import { ROBOTS_DIRECTIVES } from '@wix/advanced-seo-utils';
import {
  useApplyOrRemoveDirective,
  useIsDirectiveExists,
} from '../../../../../core/components/robots-tag/robots-tag-hooks';
import { CheckboxWithInfoIcon } from '../robots-tag-common-components';
import { translate } from 'react-i18next';

export const dataHook = 'no-snippet-directive-checkbox';

export const NoSnippetDirective = translate()(({ t }) => {
  const isNoSnippetDirectiveExists = useIsDirectiveExists(
    ROBOTS_DIRECTIVES.NOSNIPPET,
  );
  const onNoSnippetCheckboxClicked = useApplyOrRemoveDirective(
    ROBOTS_DIRECTIVES.NOSNIPPET,
  );

  return (
    <CheckboxWithInfoIcon
      dataHook={dataHook}
      isChecked={isNoSnippetDirectiveExists}
      onChange={onNoSnippetCheckboxClicked}
      label={t('adv-seo.common.robots-tag.directives.no-snippet.title')}
      infoText={t('adv-seo.common.robots-tag.directives.no-snippet.tooltip')}
      infoTextBiName="adv-seo.common.robots-tag.directives.no-snippet.tooltip"
      helpCenterId={t(
        'adv-seo.common.robots-tag.directives.no-snippet.tooltip.editor-help-center-id',
      )}
    />
  );
});

NoSnippetDirective.propTypes = {};
