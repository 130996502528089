import { siteIDs } from '../../adapters/utils';
import adapter from './adapter-blog-hashtags';
import { buildLink, buildOgMeta, buildStandardMeta, buildTitle, } from '../../types/builders';
import { getRssTitle } from '../utils';
import { LINK_TAG_TYPES } from '../../types/LinkTagTypes';
var IDs = adapter.IDs;
var SITE_NAME = siteIDs.SITE_NAME, PREV = siteIDs.PREV, NEXT = siteIDs.NEXT;
export var BLOG_HASHTAGS = {
    tags: [
        buildTitle("{{".concat(IDs.TITLE, "}}")),
        buildStandardMeta('robots', 'noindex'),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.URL, "}}") }),
        buildLink({ rel: 'prev', href: "{{".concat(PREV, "}}") }),
        buildLink({ rel: 'next', href: "{{".concat(NEXT, "}}") }),
        buildOgMeta('og:title', "{{".concat(IDs.TITLE, "}}")),
        buildOgMeta('og:url', "{{".concat(IDs.URL, "}}")),
        buildOgMeta('og:site_name', "{{".concat(SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website'),
        buildLink({
            rel: 'alternate',
            type: LINK_TAG_TYPES.RSS,
            href: "{{".concat(IDs.BLOG_FEED_ROUTE, "}}"),
            title: getRssTitle("{{".concat(SITE_NAME, "}}")),
        }),
    ],
};
