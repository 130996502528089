var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getKeys } from '../../adapters/utils';
import { get, getRawValue } from '../../utils/get';
import { SD_STATUS } from '../../consts';
import { getStructuredData } from './adapter-utils';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
export var IDs = {
    ID: 'portfolio-project.id',
    TITLE: 'portfolio-project.title',
    DESCRIPTION: 'portfolio-project.description',
    PROJECT_PAGE_URL: 'portfolio-project.url',
    TOTAL_MEDIA_COUNT: 'portfolio-project.total-media',
    MAIN_IMAGE: 'portfolio-project.main-image.url',
    COVER_IMAGE: 'portfolio-project.cover-image.url',
    COVER_IMAGE_HEIGHT: 'portfolio-project.cover-image.height',
    COVER_IMAGE_WIDTH: 'portfolio-project.cover-image.width',
    COVER_IMAGE_ALT: 'portfolio-project.cover-image.alt',
    DISABLE_IMAGES_SCHEMA: 'portfolio-project.images-schema.disable',
    DISABLE_VIDEOS_SCHEMA: 'portfolio-project.videos-search-schema.disable',
    SD_JSON_IMAGES: 'portfolio-project.structured-data.images',
    SD_JSON_VIDEOS: 'portfolio-project.structured-data.videos',
    FULL_DESCRIPTION: 'portfolio-project.full-description',
};
var SchemaTypes = {
    PROJECT_IMAGES: 'portfolio-project-images',
    // PROJECT_VIDEOS: 'portfolio-project-videos',
};
var userVisible = [
    IDs.TITLE,
    IDs.DESCRIPTION,
    IDs.PROJECT_PAGE_URL,
    IDs.TOTAL_MEDIA_COUNT,
    IDs.COVER_IMAGE,
];
var userVisibleSdOnly = [
    IDs.SD_JSON_IMAGES,
    // IDs.SD_JSON_VIDEOS
];
var getCoverImageData = function (item) {
    var _a;
    var hasImageUrl = getRawValue(item, 'project.coverImage.imageInfo.url');
    return _a = {},
        _a[IDs.COVER_IMAGE] = hasImageUrl
            ? get(item, 'project.coverImage.imageInfo.url')
            : '',
        _a[IDs.COVER_IMAGE_HEIGHT] = hasImageUrl
            ? get(item, 'project.coverImage.imageInfo.height')
            : '',
        _a[IDs.COVER_IMAGE_WIDTH] = hasImageUrl
            ? get(item, 'project.coverImage.imageInfo.width')
            : '',
        _a[IDs.COVER_IMAGE_ALT] = hasImageUrl
            ? get(item, 'project.coverImage.imageInfo.altText')
            : '',
        _a;
};
var getSdStatus = function (item, data, schemaType) {
    // eslint-disable-next-line default-case
    switch (schemaType) {
        case SchemaTypes.PROJECT_IMAGES:
            if (data[IDs.SD_JSON_IMAGES] === '') {
                return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
            }
            break;
        case SchemaTypes.PROJECT_VIDEOS:
            if (data[IDs.SD_JSON_VIDEOS] === '') {
                return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
            }
            break;
    }
    return SD_STATUS.ENABLED;
};
var getFullUrl = function (itemData) {
    return resolveItemTypeMultilingualUrl(ITEM_TYPES.PORTFOLIO_PROJECTS, itemData, get(itemData, 'project.url.url'));
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b, _c;
        var _d, _e;
        var projectItems = (_d = getRawValue(item, 'projectItems')) !== null && _d !== void 0 ? _d : [];
        var coverImageData = getCoverImageData(item);
        var data = __assign(__assign(__assign((_a = {}, _a[IDs.ID] = get(item, 'project.id'), _a[IDs.TITLE] = get(item, 'project.title'), _a[IDs.DESCRIPTION] = get(item, 'project.description'), _a[IDs.TOTAL_MEDIA_COUNT] = projectItems.length, _a[IDs.PROJECT_PAGE_URL] = getFullUrl(item), _a[IDs.FULL_DESCRIPTION] = get(item, 'project.description'), _a), coverImageData), (_b = {}, _b[IDs.MAIN_IMAGE] = (_e = get(item, 'project.coverImage.imageInfo.url')) !== null && _e !== void 0 ? _e : '', _b)), getStructuredData(IDs, projectItems, coverImageData));
        var imagesSdStatus = getSdStatus(item, data, SchemaTypes.PROJECT_IMAGES) !== SD_STATUS.ENABLED;
        var videosStatus = getSdStatus(item, data, SchemaTypes.PROJECT_VIDEOS) !== SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_c = {}, _c[IDs.DISABLE_IMAGES_SCHEMA] = imagesSdStatus, _c[IDs.DISABLE_VIDEOS_SCHEMA] = videosStatus, _c));
    },
    getSdStatus: getSdStatus,
    getFullUrl: function (itemData) { return getFullUrl(itemData); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.project.url.url = url;
        return itemData;
    },
    getSlug: function (item) { return get(item, 'project.slug'); },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
};
