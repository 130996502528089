var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get, getRawValue } from '../../utils/get';
import { getKeys, hasMissingSchemaVariables, siteIDs, } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
import { getRssFullURL } from '../utils';
import { RSS_ROUTES, RSS_ROUTE_TYPES } from '../consts';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
export var IDs = {
    ID: 'blog-tag.id',
    TITLE: 'blog-tag.name',
    PAGE_URL: 'blog-tag.fullUrl',
    POST_COUNT: 'blog-tag.postCount',
    CREATED_DATE: 'blog-tag.created.date',
    DESCRIPTION: 'blog-tag.description',
    PREV_LINK: 'blog-tag.prevLink',
    NEXT_LINK: 'blog-tag.nextLink',
    CURRENT_PAGE: 'blog-tag.currentPage',
    TOTAL_PAGE_COUNT: 'blog-tag.totalPageCount',
    SD_DISABLED: 'blog-tag.disable-schema',
    SD_ITEM_LIST_ELEMENT: 'blog-tag.structured-data.item-list-element',
    BLOG_FEED_ROUTE: 'blog-tag.blogFeedRoute',
};
var userVisible = [IDs.TITLE, IDs.POST_COUNT, IDs.PAGE_URL];
var userVisibleSdOnly = [IDs.SD_ITEM_LIST_ELEMENT];
var requiredSchemaVariables = [];
var SchemaTypes = {};
var getListItems = function (item) { return getRawValue(item, 'tag.listItems') || []; };
var getSdStatus = function (item, itemData) {
    if (hasMissingSchemaVariables(requiredSchemaVariables, item, itemData)) {
        return SD_STATUS.DISABLED_DUE_TO_MISSING_INFO;
    }
    if (getListItems(item).length <= 1) {
        return SD_STATUS.DISABLED_BY_VERTICAL_ITEM_DATA;
    }
    return SD_STATUS.ENABLED;
};
var getFullUrl = function (itemData) {
    return resolveItemTypeMultilingualUrl(ITEM_TYPES.BLOG_TAGS, itemData, get(itemData, 'tag.fullUrl'));
};
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a, _b;
        var _c;
        var data = (_a = {},
            _a[IDs.ID] = get(item, 'tag.name'),
            _a[IDs.TITLE] = get(item, 'tag.name'),
            _a[IDs.PAGE_URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BLOG_TAGS, item, get(item, 'tag.fullUrl')),
            _a[IDs.POST_COUNT] = get(item, 'tag.postCount'),
            _a[IDs.CREATED_DATE] = get(item, 'tag.created'),
            _a[IDs.DESCRIPTION] = get(item, 'tag.description'),
            _a[IDs.PREV_LINK] = get(item, 'tag.pagination.prevUrl'),
            _a[IDs.NEXT_LINK] = get(item, 'tag.pagination.nextUrl'),
            _a[IDs.CURRENT_PAGE] = get(item, 'tag.pagination.currentPage'),
            _a[IDs.TOTAL_PAGE_COUNT] = get(item, 'tag.pagination.totalPages'),
            _a[IDs.SD_ITEM_LIST_ELEMENT] = (_c = getListItems(item)) === null || _c === void 0 ? void 0 : _c.map(function (url, position) { return ({
                '@type': 'ListItem',
                position: position + 1,
                url: url,
            }); }),
            _a[IDs.BLOG_FEED_ROUTE] = get(item, 'tag.blogFeedRoute') ||
                getRssFullURL({
                    siteUrl: get(item, 'context.siteUrl'),
                    rssRoute: RSS_ROUTES[RSS_ROUTE_TYPES.BLOG],
                }),
            _a[siteIDs.CURRENT_PAGE_NUMBER] = get(item, 'tag.pagination.currentPage'),
            _a[siteIDs.TOTAL_PAGE_COUNT] = get(item, 'tag.pagination.totalPages'),
            _a[siteIDs.PREV] = get(item, 'tag.pagination.prevUrl'),
            _a[siteIDs.NEXT] = get(item, 'tag.pagination.nextUrl'),
            _a);
        var sdStatus = getSdStatus(item, data) !== SD_STATUS.ENABLED;
        return __assign(__assign({}, data), (_b = {}, _b[IDs.SD_DISABLED] = sdStatus, _b));
    },
    getSdStatus: getSdStatus,
    getSlug: function (item) { return get(item, 'tag.slug'); },
    getFullUrl: function (itemData) { return getFullUrl(itemData); },
    setFullUrl: function (itemData, url) {
        if (url === void 0) { url = ''; }
        itemData = JSON.parse(JSON.stringify(itemData));
        itemData.tag.fullUrl = url;
        return itemData;
    },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return getKeys(IDs, userVisibleSdOnly); },
};
