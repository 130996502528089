import { getKeys, siteIDs } from '../../adapters/utils';
import { SD_STATUS } from '../../consts';
export var IDs = {
    CURRENT_PAGE_URL: 'stores-gallery.current-page-url',
    PREVIOUS_PAGE_URL: 'stores-gallery.previous-page-url',
    NEXT_PAGE_URL: 'stores-gallery.next-page-url',
    TOTAL_ACTIVE_ITEMS_COUNT: 'stores-gallery.total-active-items',
};
var SchemaTypes = {};
var userVisible = [
    IDs.TOTAL_ACTIVE_ITEMS_COUNT,
    siteIDs.CURRENT_PAGE_NUMBER,
    siteIDs.TOTAL_PAGE_COUNT,
];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j;
        return _a = {},
            _a[IDs.CURRENT_PAGE_URL] = (_b = item === null || item === void 0 ? void 0 : item.shop) === null || _b === void 0 ? void 0 : _b.url,
            _a[IDs.PREVIOUS_PAGE_URL] = (_c = item === null || item === void 0 ? void 0 : item.pagination) === null || _c === void 0 ? void 0 : _c.prevUrl,
            _a[IDs.NEXT_PAGE_URL] = (_d = item === null || item === void 0 ? void 0 : item.pagination) === null || _d === void 0 ? void 0 : _d.nextUrl,
            _a[IDs.TOTAL_ACTIVE_ITEMS_COUNT] = (_e = item === null || item === void 0 ? void 0 : item.items) === null || _e === void 0 ? void 0 : _e.numberOfItems,
            _a[siteIDs.PREV] = (_f = item === null || item === void 0 ? void 0 : item.pagination) === null || _f === void 0 ? void 0 : _f.prevUrl,
            _a[siteIDs.NEXT] = (_g = item === null || item === void 0 ? void 0 : item.pagination) === null || _g === void 0 ? void 0 : _g.nextUrl,
            _a[siteIDs.CURRENT_PAGE_NUMBER] = (_h = item === null || item === void 0 ? void 0 : item.pagination) === null || _h === void 0 ? void 0 : _h.currentPage,
            _a[siteIDs.TOTAL_PAGE_COUNT] = (_j = item === null || item === void 0 ? void 0 : item.pagination) === null || _j === void 0 ? void 0 : _j.totalPages,
            _a;
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
