export var QUERY_SEPARATOR = '?';
export var QUERY_PARAM_SEPARATOR = '&';
export var LANG_QUERY_PARAM = 'lang=';
export var LIGHTBOX_QUERY_PARAM = 'lightbox=';
export var QUERY_DEFAULT_ALLOWLIST = [LANG_QUERY_PARAM, LIGHTBOX_QUERY_PARAM];
export var PATTERN_PREFIX = '{{';
export var SELF_REF_PATTERN_PREFIX = '{{@';
export var PATTERN_SUFFIX = '}}';
export var WIX_DOMAINLESS_IDS = [
    'wixsite.com',
    'editor.wix.com',
    'editorx.io',
    'wixstudio.io',
];
export var INDEX = 'index';
export var NO_INDEX = 'noindex';
export var X_DEFAULT = 'x-default';
export var SD_STATUS = {
    ENABLED: 'enabled',
    DISABLED_DUE_TO_MISSING_INFO: 'disabled-due-to-missing-info',
    DISABLED_BY_VERTICAL_ITEM_DATA: 'disabled-by-vertical-item-data',
    DOES_NOT_EXIST: 'does-not-exist',
};
export var RESOLUTION_METHOD = {
    SUBDIRECTORY: 'SUBDIRECTORY',
    SUBDOMAIN: 'SUBDOMAIN',
    QUERY_PARAM: 'QUERY_PARAM', // www.mysite.com?lang=fr
};
