import { IDENTIFIERS } from '@wix/advanced-seo-utils';
import { KEYS } from '../utils/maps';
import {
  createSetter,
  createStructuredDataSetter,
  createIsIndexEnabledSetter,
  createImageSetter,
  createCustomTagsSetter,
  createAdvancedTagsSetter,
  createSetterNoop,
  createCustomTagsArraySetter,
  createPreventAutoRedirectSetter,
} from '../app-base';

export const initSetters = () => {
  return {
    [KEYS.TITLE]: createSetter(IDENTIFIERS.TITLE),
    [KEYS.DESCRIPTION]: createSetter(IDENTIFIERS.DESCRIPTION),
    [KEYS.OG_TITLE]: createSetter(IDENTIFIERS.OG_TITLE),
    [KEYS.OG_DESCRIPTION]: createSetter(IDENTIFIERS.OG_DESCRIPTION),
    [KEYS.OG_IMAGE]: createImageSetter(IDENTIFIERS.OG_IMAGE),
    [KEYS.TWITTER_CARD]: createSetter(IDENTIFIERS.TWITTER_CARD),
    [KEYS.TWITTER_TITLE]: createSetter(IDENTIFIERS.TWITTER_TITLE),
    [KEYS.TWITTER_DESCRIPTION]: createSetter(IDENTIFIERS.TWITTER_DESCRIPTION),
    [KEYS.TWITTER_IMAGE]: createImageSetter(IDENTIFIERS.TWITTER_IMAGE),
    [KEYS.CANONICAL]: createSetter(IDENTIFIERS.CANONICAL),
    [KEYS.CUSTOM_TAGS]: createCustomTagsSetter(),
    [KEYS.CUSTOM_TAGS_ARRAY]: createCustomTagsArraySetter(),
    [KEYS.ADVANCED_TAGS]: createAdvancedTagsSetter(),
    [KEYS.JSON_LD]: createStructuredDataSetter(),
    [KEYS.IS_INDEX_ENABLED]: createIsIndexEnabledSetter(),
    [KEYS.ROBOTS_TAG]: createSetter(IDENTIFIERS.ROBOTS),
    [KEYS.URI]: createSetterNoop(),
    [KEYS.PREVENT_AUTO_REDIRECT]: createPreventAutoRedirectSetter(),
  };
};
