import { identifyTag } from './identify-tag';
import { GENERAL_IDENTIFIERS } from '../../types/Identifiers';
import { renderToStaticMarkup } from '../../renderer/render-to-static-markup';
export var getSimplifiedTag = function (seoTag) {
    var type = identifyTag(seoTag);
    switch (type) {
        case GENERAL_IDENTIFIERS.LINK: {
            var _a = seoTag.props, rel = _a.rel, href = _a.href;
            return {
                label: rel,
                value: href,
                isDisabled: !!seoTag.disabled,
                generalIdentifier: GENERAL_IDENTIFIERS.LINK,
                html: renderToStaticMarkup({ tags: [seoTag] }),
                isCustom: !!seoTag.custom,
            };
        }
        case GENERAL_IDENTIFIERS.OG_TAG: {
            return {
                label: seoTag.props.property,
                value: seoTag.props.content,
                isDisabled: !!seoTag.disabled,
                generalIdentifier: GENERAL_IDENTIFIERS.OG_TAG,
                html: renderToStaticMarkup({ tags: [seoTag] }),
                isCustom: !!seoTag.custom,
            };
        }
        case GENERAL_IDENTIFIERS.SATANDARD_META: {
            return {
                label: seoTag.props.name,
                value: seoTag.props.content,
                isDisabled: !!seoTag.disabled,
                generalIdentifier: GENERAL_IDENTIFIERS.SATANDARD_META,
                html: renderToStaticMarkup({ tags: [seoTag] }),
                isCustom: !!seoTag.custom,
            };
        }
        default: {
            return null;
        }
    }
};
