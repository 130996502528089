"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStockStatus = exports.STOCK_STATUS = exports.EVENT_CLOSED_STATUS = void 0;
var get_1 = require("../../../utils/get");
exports.EVENT_CLOSED_STATUS = ['CLOSED', 'CLOSED_MANUALLY'];
exports.STOCK_STATUS = { SOLD_OUT: 'SoldOut', IN_STOCK: 'InStock' };
var getStockStatus = function (ticket) {
    if (ticket === void 0) { ticket = {}; }
    var limitPerCheckout = parseInt((0, get_1.getRawValue)(ticket, 'limitPerCheckout') || 0, 10);
    return limitPerCheckout ? exports.STOCK_STATUS.IN_STOCK : exports.STOCK_STATUS.SOLD_OUT;
};
exports.getStockStatus = getStockStatus;
