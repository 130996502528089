import { CONTEXT_PROPS } from '../types/ContextProps';
import { get } from '../utils/get';
import { BLOG_TPA_PAGE_ID, RSS_ROUTES, RSS_ROUTE_TYPES } from './consts';
import { BLOG_APP_DEF_ID } from './static-page-v2/consts';
import { getRssFullURL } from './utils';
export var getBlogFeedRoute = function (item) {
    var _a;
    var requiredApps = [BLOG_APP_DEF_ID];
    var isHomePage = get(item, "context.".concat(CONTEXT_PROPS.IS_HOME_PAGE)) === 'true';
    var installedApps = (_a = item === null || item === void 0 ? void 0 : item.context) === null || _a === void 0 ? void 0 : _a[CONTEXT_PROPS.INSTALLED_APPS];
    var shouldAppsRenderTag = requiredApps === null || requiredApps === void 0 ? void 0 : requiredApps.some(function (appDefId) {
        return installedApps === null || installedApps === void 0 ? void 0 : installedApps.find(function (app) {
            return [app.appDefinitionId, app.tpaPageId, app.managingAppDefId].includes(appDefId);
        });
    });
    var isBlogFeedPage = get(item, "context.".concat(CONTEXT_PROPS.TPA_PAGE_ID)) === BLOG_TPA_PAGE_ID;
    if ((isHomePage && shouldAppsRenderTag) || isBlogFeedPage) {
        var siteUrl = get(item, "context.".concat(CONTEXT_PROPS.SITE_URL));
        return getRssFullURL({
            siteUrl: siteUrl,
            rssRoute: RSS_ROUTES[RSS_ROUTE_TYPES.BLOG],
        });
    }
    return '';
};
