import { getKeys, siteIDs } from '../../adapters/utils';
import { get } from '../../utils/get';
import { SD_STATUS } from '../../consts';
import { getRssFullURL } from '../utils';
import { RSS_ROUTES, RSS_ROUTE_TYPES } from '../consts';
import { ITEM_TYPES } from '../../types/ItemTypes';
import { resolveItemTypeMultilingualUrl } from '../../utils/resolve-item-type-multilingual-url';
export var IDs = {
    ID: 'blog-hashtags.id',
    TITLE: 'blog-hashtags.title',
    LABEL: 'blog-hashtags.label',
    URL: 'blog-hashtags.url',
    PREV_URL: 'blog-hashtags.pagination.prevUrl',
    NEXT_URL: 'blog-hashtags.pagination.nextUrl',
    PAGE_COUNT: 'blog-hashtags.pagination.totalPages',
    CURRENT_PAGE: 'blog-hashtags.pagination.currentPage',
    TOTAL_ITEMS: 'blog-hashtags.items.numberOfItems',
    BLOG_FEED_ROUTE: 'blog-hashtags.blogFeedRoute',
};
var SchemaTypes = {
    BLOG_HASHTAGS_ITEM_LIST: 'blog-hashtags-item-list-preset',
};
var userVisible = [
    IDs.TITLE,
    IDs.LABEL,
    IDs.URL,
    IDs.PAGE_COUNT,
    IDs.TOTAL_ITEMS,
    IDs.CURRENT_PAGE,
];
export default {
    IDs: IDs,
    SchemaTypes: SchemaTypes,
    getData: function (item) {
        var _a;
        return _a = {},
            _a[IDs.ID] = get(item, 'hashtag.title'),
            _a[IDs.TITLE] = get(item, 'hashtag.title'),
            _a[IDs.LABEL] = get(item, 'hashtag.label'),
            _a[IDs.URL] = resolveItemTypeMultilingualUrl(ITEM_TYPES.BLOG_HASHTAGS, item, get(item, 'hashtag.url')),
            _a[IDs.PREV_URL] = get(item, 'hashtag.pagination.prevUrl'),
            _a[IDs.NEXT_URL] = get(item, 'hashtag.pagination.nextUrl'),
            _a[IDs.PAGE_COUNT] = get(item, 'hashtag.pagination.totalPages'),
            _a[IDs.CURRENT_PAGE] = get(item, 'hashtag.pagination.currentPage'),
            _a[IDs.TOTAL_ITEMS] = get(item, 'hashtag.items.numberOfItems'),
            _a[IDs.BLOG_FEED_ROUTE] = get(item, 'hashtag.blogFeedRoute') ||
                getRssFullURL({
                    siteUrl: get(item, 'context.siteUrl'),
                    rssRoute: RSS_ROUTES[RSS_ROUTE_TYPES.BLOG],
                }),
            _a[siteIDs.CURRENT_PAGE_NUMBER] = get(item, 'hashtag.pagination.currentPage'),
            _a[siteIDs.TOTAL_PAGE_COUNT] = get(item, 'hashtag.pagination.totalPages'),
            _a[siteIDs.PREV] = get(item, 'hashtag.pagination.prevUrl'),
            _a[siteIDs.NEXT] = get(item, 'hashtag.pagination.nextUrl'),
            _a;
    },
    getSdStatus: function () { return SD_STATUS.DOES_NOT_EXIST; },
    getSlug: function () { return ''; },
    updateItemDataWithSlug: function (item) { return item; },
    getKeys: function () { return getKeys(IDs, userVisible); },
    getSdKeys: function () { return []; },
};
