var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { buildTitle, buildStandardMeta, buildOgMeta, buildLink, buildStructuredData, buildOgMetaRefs, buildTwitterMetaRefs, } from '../../types/builders';
import { siteIDs } from '../../adapters/utils';
import adapter from './adapter-events-page';
import eventsSchema from './schema-presets/events-page';
/**
 Here you can find more details about the implementation
 https://docs.google.com/document/d/1n-QE6LXncd_wqz9eQKufxMWnalE-WYxEzdrZ7rixF2A/edit#heading=h.8n2lir49tnp7
*/
var IDs = adapter.IDs;
export var EVENTS_PAGE = {
    tags: __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        buildTitle("{{".concat(IDs.TITLE, "}} | {{").concat(siteIDs.SITE_NAME, "}}")),
        buildStandardMeta('description', "{{".concat(IDs.CALCULATED_SEO_DESCRIPTION, "}}")),
        buildStandardMeta('robots', 'index')
    ], buildOgMetaRefs(), true), [
        buildOgMeta('og:image', "{{".concat(IDs.MAIN_IMAGE, "}}")),
        buildOgMeta('og:image:width', "{{".concat(IDs.IMAGE_WIDTH, "}}")),
        buildOgMeta('og:image:height', "{{".concat(IDs.IMAGE_HEIGHT, "}}")),
        buildOgMeta('og:site_name', "{{".concat(siteIDs.SITE_NAME, "}}")),
        buildOgMeta('og:type', 'website'),
        buildOgMeta('og:url', "{{".concat(IDs.CALCULATED_EVENT_URL, "}}")),
        buildLink({ rel: 'canonical', href: "{{".concat(IDs.CALCULATED_EVENT_URL, "}}") })
    ], false), buildTwitterMetaRefs(), true), [
        buildStructuredData({
            schema: eventsSchema.schema,
            schemaType: eventsSchema.type,
            disabled: [
                "{{".concat(IDs.SD_DISABLE_SCHEMA, "}}"),
                '{{options.disable-pattern-schema}}',
            ],
        }),
    ], false),
};
